body {
    background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);
}
.classTagContainer {
   background: #F0F0F0 ;
   min-height: 83vh;
   overflow-x: hidden;
   padding: 0rem 2.5rem 1rem 2.5rem;


.ssTop{
    margin-top:10px
}

/* ----------Tag Year Div--------- */
.classBackDiv{
    display: flex;
    padding: .8rem;
    background: #EEEEEE;
    width: 19rem;
    height: 3.1rem;
    cursor: pointer;
}
.TagYearMainDiv{
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
}
.classBackYearText{
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}
/* ----------Class Year Div--------- */

/* -----Tag save Icon------ */
.confirmSaveTag{
    width: 3rem;
    height: 3rem;
    background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
    border-radius: 10rem;
    opacity: 1;
    cursor: pointer;
}
.confirmSaveTagIcon{
    padding: .7rem;
    color: white;
    font-size: 1.7rem;
}
/* -----Tag save Icon------ */



/* ----------Heading Tag Div--------- */
.headingTagMainDiv{
    width: 100%;
    background: #ffff;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    margin-top: 1rem;
    border-radius: .5rem;
    box-shadow: 0px 1px 4px #00000029;
    height: 60px;
    margin-bottom: 20px;
}
.headingTagMainText{
    color:#386CB5;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .speedNoteheading {
        margin-left: 20%;
    }

    .abbreviationheading {
        margin-left: 12%;
    }
}

/* ----------Heading Tag Div--------- */
.tagValueMainDiv .checkBoxColorTag{
    color:#386cb5;
    cursor: pointer;
    opacity: 1;

}

/* ----------Tag Main Div--------- */
.tagMainDiv{
    width: 100%;
    background: transparent;
    display: flex;
    padding: 0 2rem;
    padding-right: 0;
    margin: 0;
    margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
    .tagMainDiv {
        padding: 0;
    }
}

.MuiOutlinedInput-input {
    height: 26px !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font: normal normal 600 14px/20px Open Sans;
    color: #222222;
}
.MuiOutlinedInput-notchedOutline {
    border: none;
}
.inputInputType {
    height: 40px !important;
    background: #F4F6FA 0% 0% no-repeat padding-box;
    border: 0.75px solid #DDDDDD;
}
.tagValueMainDiv{
    width: 100%;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    /* margin-bottom: 1rem; */

    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A;
    border-radius: .5rem;
    height: 60px;
    padding: 10px 0;
}
/* ----------Tag Main Div--------- */


/* ---------TagValueRow-------- */
.tagValueRow{
    width: 100%;
}
.flastTagIcon{
    width: 11px;
    height: 20px;
    cursor: pointer;
}
.centerInRow{
    padding-top: 8px;
}

.inputInputType {
    box-sizing: border-box;
    border-radius: .3rem;
    height: 3.5rem;
    background: #F4F6FA;
    width: 100%;
    margin-top: 0rem !important;
    margin-bottom: 0px !important;
}
/* ---------TagValueRow-------- */

/* ---------TagCloseRow-------- */

.closeTagDiv{
    padding-top: 12px;
}

.radio_button_unchecked {
    color: #000000;
    font-weight: lighter !important;
    cursor: pointer;
    opacity: 1;
}

.closeTagIcon{
    width: 35px;
    height: 35px;
    cursor: pointer;
}


@media only screen and (max-width: 767px) {
    .closeTagDiv {
        padding: 16px 8px;
    }
    .closeTagIcon {
        width: 24px;
        height: 24px;
    }
}
/* ---------TagCloseRow-------- */


/* ------Add Icon --- */
.addTagIconDiv{
    margin-bottom: 3rem;
    margin-top: 1.5rem;
}
.addTagIconImage{
    width: 50%;
    height: 3rem;
    min-width: 35rem;
    cursor: pointer;

}
/* ------Add Icon --- */
.modalTagImport{


.modal-content {
    border-radius: 0;
    border: none;
}
.modal-title{
    font-size: 13px;
}
.modal-header {
    border-bottom-color: #EEEEEE !important;
    background-color: #ffff !important;
    height: 50px !important;
}
.modal-header .close {
    padding: 0;
    margin: -1rem -1rem -1rem auto;
    opacity: 1 !important;
    color: #000 !important;
    outline: none !important;
}
.cancelText{
    font-size: .8rem !important;
    // opacity: 1 !important;
    color: #000 !important;
}
.closeIconInModal{
    width: .6rem;
    margin-top: -.2rem;
}
}

.cloudeIconInModal {
    width: 1rem;
    cursor: pointer;
    margin-top: -8px;
}

.modalSelectTagDiv{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 1rem;
    border-bottom:  1px solid #EEEEEE;
}
.modalSelectTagText{
    text-align: left;
    font: normal normal normal 15px/24px Open Sans;
    letter-spacing: 0.3px;
    color: #222222;
    opacity: 1;
    overflow-wrap: break-word;
}


/* --------------Upload Modal Roster style--------- */
.uploadHeaderTagDiv{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    opacity: 1 !important;
    color: #000 !important;
    outline: none !important;
}
.uploadNoteTagDiv{
    text-align: left;
    background: #F5F5F5;
    padding: 1rem 1rem 3rem 1.7rem;
    width: 100%;
    position: absolute;
    bottom: 0rem;
}
.uploadNoteTagText{
    margin: 0rem;
    font-size: 1rem;
}
.checkbozTagCircle{
    height: 1rem;
    width: 1rem;
    border-radius: 10rem;
    border:.1rem solid #DDDDDD;
    text-align: -webkit-center;
    padding-top: 2px;
    cursor: pointer;
}
.checkbozCircleTagSelected{
    height: .5rem;
    width: .5rem;
    border-radius: 10rem;
    background-color: #386CB5;
}
.selectMainDivforTagUpload{
    display: flex;
    padding: 1rem 0rem 0rem 0rem;
    width: 100%;
    justify-content: center;
}
.selectOptionInTag{
    width: 8.7rem;
    height: 9rem;
    border: 1px solid #DDDDDD;
    text-align: -webkit-center;
    padding: .5rem;
    margin: .5rem;
    border-radius: 8px;

}
.selectoptionUploadImage{
    width: 2.7rem;
    margin: .7rem 0rem .6rem 0rem;
}
.selectcheckTag{
    height: 1rem;
    width: 1rem;
    border-radius: 10rem;
    border:.1rem solid #DDDDDD;
    text-align: -webkit-center;
    padding-top: 2px;
    cursor: pointer;
}
.selectcheckTagSelected{
    height: .55rem;
    width: .5rem;
    border-radius: 10rem;
    background-color: #386CB5;
}

.selectoptionUploadImageforTagDrop{
    width: 2.3rem;
    margin: .7rem 0rem .7rem 0rem;
}
.autharizedTagButton{
    width: 4.5rem !important;
    height: 1.4rem !important;
    border-radius: 2rem !important;
    padding: .2rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .6rem 0rem .1rem 0rem;
}
.autharizedTagText {
    color: white;
    font-size: .65rem;

}

.selectTagText{
    color: #222222;
    font-size: .85rem;
    margin: 0rem;
    font-weight: 600;

}
.selectMultipuleTagDiv{
    padding: 1rem 0rem 1rem 1rem;
    margin: 1rem .5rem 0rem .5rem;
    border-top: 1px solid #DDDDDD;
}
.selectMultipuleTagText{
    color: #222222;
    font-size: .9rem;
    font-weight: 600;
    margin-left: .4rem;
}
}
/* --------------Upload Modal style--------- */


/* ----Back to Class Alert Modal---- */

.TagSaveModalMain {
    background: #FFFFFF;
    overflow: auto;
    border-radius: 2rem !important;
}
.TagSaveModalNoteText{
    color:#000;
    font-size: 1.2rem;
    font-weight: 600;
}
.TagSaveModalWorningText{
    font-weight: 600;
    font-size: .8rem;
}

.permissionButtonsDiv {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0rem 4rem 0rem 4rem;
}
.deleteTagButtondiv {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0rem 3rem 0rem 3rem;
    .modalDeleteTagButton {
        border-radius: 8px!important;
    }
}

.backButtonTagText {
    color: #000;
    font-weight: 600;
    font-size: 1rem;
    padding-top: .5rem;
    cursor: pointer;
}
.opacityProp{
    opacity: 0.5 !important;
    cursor: default !important;
}
.modalSaveTagButton {
    width: 5rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #EC4444 0%, #FA6060 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .1rem 0rem .1rem 0rem;
    border-color: #EC4444 !important;
}
.modalSavveButtonBorderRadius {
    border-radius: 8px!important;
}
.modalSaveTagButtonText {
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

/* ----Back to Class Alert Modal---- */




@media only screen and (min-width: 250px) and (max-width: 590px) {
    .classTagContainer {
        padding: 0rem 0rem 1rem 0rem;
     }
     .tagMainDiv{
        padding: 1rem 1rem 1rem 1.2rem;
    }
    .classBackDiv{
        width: 70%;
    }
}



.selectTag-DriveFile-modal {
    margin-top: 3rem;
  }
  .selectTag-DriveFile-modal .modal-title {
    font: normal normal 600 1rem/1.375rem Open Sans !important;
  }
  .selectTag-DriveFile-modal .importDriveLoader{
    height: 100px;
    width: 190px;
    margin-top: 30%;
  }
  .selectTag-DriveFile-modal .modal-content{
      height: 35rem !important;
  }
  .selectTag-DriveFile-modal .file_listDiv{

    width: 100%;
    height: 40px;
    margin-top: 13px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #EEEEEE;
  }

  .selectTag-DriveFile-modal .modal-header {
    padding: .8rem 1rem 0;
    border-bottom: none;
    border-radius: 0.5rem;
  }

  .selectTag-DriveFile-modal textarea {
    font: normal normal normal 0.875rem/1.188rem Open Sans;
  }
  .selectTag-DriveFile-modal .selectedText{
    color: #41cc3b;
    font-weight: 600;
  }
  .selectTag-DriveFile-modal .unSelectedText{
    font-weight: 600;
    cursor: pointer;
  }
  .selectTag-DriveFile-modal .modal-footer {
    justify-content: center;
    border-top: none;
    padding-top: 0;
  }
  .selectTag-DriveFile-modal .modal-footer button {
    width: 5rem;
    height: 5rem;
    border-radius: 1.25rem;
    padding: 0;
  }

  .selectTag-DriveFile-modal .modal-footer button img {
    margin-top: -0.1875rem;
    margin-left: 0.0625rem;
  }
  .selectTag-DriveFile-modal .modal-content {
    border-radius: 0.5rem;
    background: #fff !important;
  }
  .selectTag-DriveFile-modal .modalcancleTagButton {
    width: 2.3rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #EC4444 0%, #FA6060 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    // margin: .1rem 1rem .1rem 1rem;
    border-color: #EC4444 !important;
}
.selectTag-DriveFile-modal .modalSaveTagButton {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg,  #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: 0rem;
    border-color: #23BF1D !important;
}
.selectTag-DriveFile-modal .checkBoxSelect {
    color: #386cb5;
    cursor: pointer;
    font-size: 1rem;
    margin-top: .5rem;
    margin-right: .2rem;
}
.selectTag-DriveFile-modal .unCheckBoxSelect {
   cursor: pointer;
    font-size: 1rem;
    margin-top: .5rem;
    margin-right: .2rem;
}

.selectTag-DriveFile-modal .selectoptionUploadFolder{
    width: 2rem;
    margin: 0rem .5rem 0rem 0rem;
}
.selectTag-DriveFile-modal .fileName{
    margin-top: .6rem;
}
.selectTag-DriveFile-modal .driveBackIcon{
    font-size: 1.3rem;
    cursor: pointer;
}
.selectTag-DriveFile-modal .cursor{
    cursor: pointer;
}
.selectTag-DriveFile-modal .inline-Path{
    display: inline-flex;
    height: 30px;
}
.selectTag-DriveFile-modal .selectFileCursor{
    display: inline-flex;
    cursor: pointer;
}
.selectTag-DriveFile-modal .modal-list-body{
    height: 25rem !important;
    overflow: auto !important;
}
ul.breadcrumb {
    padding: 0px 0px;
    margin: 0px;
    list-style: none;
    background-color: transparent;
  }
  ul.breadcrumb li {
    display: inline;
    font-size: 14px;
    font-weight: 600;
  }
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
  }
  ul.breadcrumb li a {
    color: #386CB5;
    text-decoration: none;
  }
  ul.breadcrumb li a:hover {
    color:#669FED;
    text-decoration: underline;
    cursor: pointer;
  }