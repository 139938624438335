
.upgradePlanContainer{
    justify-content: center;
    padding: 0px 50px 15px 50px;
    .header-main-container {
        width: 100%;
        height: 80px;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        border-radius: 0px 0px 8px 8px;

        .header-breadcrumb-main-container {
            display: flex;
            align-items: center;
            width: 30%;

            .header-breadcrumb-back-btn-div {
                display: flex;
                background: transparent;
                cursor: pointer;
                align-items: center;
                .header-breadcrumb-back-btn-img {
                    width: 60px;
                    height: 40px;
                    align-self: center;
                    cursor: pointer;
                }
            }
            .header-breadcrumb-inner-div {
                margin-left: 1rem;
                border-left: 1px solid #eeeeee;
                padding: 0.3rem 0rem 0rem 1rem;
                .breadcrumb-inner-flex-div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: default;
                    width: max-content;
                    padding-top: 9px;
                    .breadcrumb-academic-year-text {
                        color: #487FCC;
                        margin-bottom: 15px;
                        margin-right: 10px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        cursor: pointer;
                    }
                    .breadcrumb-arrow-icon {
                        color: #487FCC;
                        margin-bottom: 15px;
                        margin-left: -8px;
                    }
                    .breadcrumb-attendance-text {
                        color: #222222;
                        font-size: 1.25rem;
                        font-weight: 600;
                        text-align: left;
                        cursor: default;
                    }
                }
            }
        }
        
    }
    .refCol{
        padding-left: 15px;
        .refrenceLink{
            color: var(--unnamed-color-222222);
            text-align: left;
            font: normal normal 600 14px/19px Open Sans;
            letter-spacing: 0px;
            color: #222222;
            font-weight: bold;
            opacity: 1;
        }
    }
    .cardOfPlan{
        width: 100%;
        min-height: 300px;
        background: none;
        box-shadow: none;
        margin-top: 20px;
        border:none;
        width: 100%;
        margin-top: 0rem;
        display: flex;
        flex-wrap: wrap;
    }
    .cardRowPadd0{
        padding-right: 0px !important;
        border-radius: 8px;
        filter: drop-shadow(0 3px 66px #00000029);
        max-width: 435px;
    }
    .cardPlanRow{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A;
        border-radius: 6px;
        opacity: 1;
        width: 100%;
        padding: 25px 5px 10px 5px;
        margin: 0px;
        margin-top: 15px;
    }
    .planTypeTextDiv{
        float: left !important;
        text-align: left !important;
        margin-left: 15px;
    }
    .cardOfPlanRules{
        width: 100%;
        min-height: 300px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        // background: #FFFFFF 0% 0% no-repeat padding-box;
        // box-shadow: 0px 1px 4px #2222221A;
        margin-top: 20px;
        padding: 40px;
        border:none;
text-align: left;
font: normal normal normal 12px/21px Open Sans;
letter-spacing: 0px;
color: #222222;
    }

    .planIconDiv{
        width: 100px;
        height: 100px;
        transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
        background: transparent linear-gradient(45deg, #669EEC 0%, #3E73BC 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000029;
        border: 3px solid #FEFEFE;
        opacity: 1;
    }
    .planDetails{
        display: inline-flex;
        float: left
        
    }
    .planType{
        text-align: left;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #0069d9;
        text-transform: uppercase;
        margin-bottom: 10px;
        opacity: 1;
    }  
    .annualPlanType{
        text-align: left;
        font: normal normal 600 12px/14px Open Sans;
        letter-spacing: 0px;
        color: #222222;
        margin-bottom: 10px;
        opacity: 1;
    }
    .selectedPackage{
        color: #000000 !important;
        justify-content: flex-start !important;
    }
    .annualPlanPrice{
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        // color: #3E73BC;
        font-weight: bold;
        cursor: pointer;
    }
    .disAnnualPlanPrice{
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #91b1dd;
        font-weight: bold;
    }
    .opacityProp{
        opacity: 0.4;
        cursor: default !important;
    }
    .selectedAnnualPlanPrice{
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #000000;
        font-weight: bold;
    }
    .planPrice{
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #ffffff !important;
        font-weight: bold;
        margin-top: 6px;
        display: flex;
        justify-content: center !important;
        cursor: pointer;
    }
    .planPriceFree{
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #3E73BC;
        font-weight: bold;
        margin-top: 6px;
        margin-bottom: 81px;
    }
    .freeText{
        pointer-events: none;
      }
    a[target=_blank]{
        margin-bottom: 10px;
    }
    .annualDiv{
        margin-left: -15px;
    }
    .planDetailsPoint{
        color: var(--unnamed-color-222222);
        text-align: left;
        font: normal normal normal 14px/15px Open Sans;
        letter-spacing: 0px;
        color: #222222;
        font-weight: bold;
        margin-left: 15px;
    }
    .planDetailsPointFont {
        font: normal normal normal 14px/20px Open Sans;
letter-spacing: 0px;
color: #222222;
    }
    .planDetailsPointDashboardUpgrade {
        color: var(--unnamed-color-222222);
text-align: left;
font: normal normal normal 14px/21px Open Sans;
letter-spacing: 0px;
color: #222222;
    }
    .dataPlans{
        position: relative;
        line-height: 1.5em;
    }
    .benefitsDiv{
        margin-top: 0px;
        margin-left: -15px;
    }
  
    .upgradePlanButton{
        // width: 115px;
        height: 35px;
        background: transparent linear-gradient(90deg, #669EEC 0%, #3E73BC 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        float: left;
        font-size: 14px;
        margin-bottom: 10px;
        padding: 0 10px;        
    }
    .mTop20{
        margin-top: 20px;
    }
    .mTop30{
        margin-top: 30px;
    }
    .mBot5{
        margin-bottom: 5px;
    }
    .dataPlans{
        position: relative;
        line-height: 1.5em;
    }
    @media only screen and (min-width: 1000px) and (max-width: 1200px) {
        padding: 0px 0px 50px 0px;
        .cardPlanRow{
            width: 100% !important;
            margin-right: 0px;
        }
        .upgradePlanButton{
            width: 95px;
            font-size: 12px;
        }
        .planType{
            font: normal normal 600 12px/16px Open Sans;
            
        }
        .planPriceFree{
            font: normal normal 600 12px/16px Open Sans;
            
        }
        .planPrice{
            font: normal normal 600 12px/16px Open Sans;
                margin-top: 8px;
        }
        .disAnnualPlanPrice{
            font: normal normal 600 12px/16px Open Sans;
        }
        .annualPlanPrice{
            font: normal normal 600 12px/16px Open Sans;
        }
        .selectedAnnualPlanPrice{
            font: normal normal normal 12px/22px Open Sans;
            font-weight: bold;
        }
    }
   
    @media only screen and (min-width: 950px) and (max-width: 1000px) {
        .planPrice{
            font: normal normal 600 12px/16px Open Sans;
                margin-top: 8px;
        }
        .planPriceFree{
            font: normal normal 600 12px/16px Open Sans;
            
        }
        .disAnnualPlanPrice{
            font: normal normal 600 12px/16px Open Sans;
        }
        .annualPlanPrice{
            font: normal normal 600 12px/16px Open Sans;
        }
        .selectedAnnualPlanPrice{
            font: normal normal normal 12px/22px Open Sans;
            font-weight: bold;
        }
        .upgradePlanButton {
            width: 95px;
            font-size: 12px;
        }
    }

    @media only screen and (min-width: 751px) and (max-width: 950px) {
        padding: 0px 0px 50px 0px;
        
        .cardPlanRow{
            width: 100%; 
            margin-right: 0px;
        }
        .upgradePlanButton{
            width: 95px;
            font-size: 12px;
        }
        .planType{
            font: normal normal 600 12px/16px Open Sans;
            
        }
        .planPrice{
            font: normal normal 600 12px/16px Open Sans;
            margin-top: 8px;
        }
        .planPriceFree{
            font: normal normal 600 12px/16px Open Sans;
            
        }
        .disAnnualPlanPrice{
            font: normal normal 600 12px/16px Open Sans;
        }
        .selectedAnnualPlanPrice{
            font: normal normal normal 12px/22px Open Sans;
            font-weight: bold;
        }
        .annualPlanPrice{
            font: normal normal 600 12px/16px Open Sans;
        }
        
    }

    @media only screen and (min-width: 251px) and (max-width: 750px) {
    padding: 0px 0px 50px 0px;
        .cardPlanRow{
            width: 100%;
            margin-right: 0px;
        }
        .upgradePlanButton{
            margin-bottom: 10px;
        }
      
        }
    @media only screen and (min-width: 251px) and (max-width: 770px) {
        .planPriceFree{
            margin-bottom: 15px !important;
        }
        .planTypeTextDiv{
            margin-left: 15px !important;
        }
    }
    @media only screen and (min-width: 807px) and (max-width: 870px) {
        .planPriceFree{
            margin-bottom:135px !important;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 807px) {
        .planPriceFree{
            margin-bottom:115px !important;
        }
    }
    @media only screen and (min-width: 950px) and (max-width: 997px) {
        .planPriceFree{
            margin-bottom: 244px !important;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 885px) {
        .benefitsDiv{
            margin-left: 0px !important;
        }
        .planTypeTextDiv {
            margin-left: 90px !important;
        }
    }
}
.personalPlanPara {
    font-size: 14px;
    color: #F18F1A;
    text-transform: uppercase;
    font-weight: 600;
}
.professionalPara {
    font-size: 14px;
    color: #1AA6B4;
    text-transform: uppercase;
    font-weight: 600;   
}
.premiumPara {
    font-size: 14px;
    color: #E83861;
    text-transform: uppercase;
    font-weight: 600;   
}
.planIconBg {
    background-color: #F9F9F9;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.customAllPadding {
    padding: 25px 5px 10px 5px;   
}
.planFreeBtn {
    background: transparent linear-gradient(78deg, #52C45E 0%, #337735 100%) 0% 0% no-repeat padding-box;
border-radius: 8px !important;
text-transform: capitalize !important;
}
.recommendBox {
    background-color: #E6395D;
    border-radius: 0px 0px 10px 10px;
    font: normal normal 600 13px/18px Open Sans;
letter-spacing: 0px;
color: #FFFFFF;
position: absolute;
top: 0;
left: 15px;
width: 124px;
height: 27px;
display: flex;
align-items: center;
justify-content: center;
}
.planPremiumProBtn {
    width: 115px;
    height: 35px;
    background: transparent linear-gradient(90deg,#669eec,#3e73bc) 0 0 no-repeat padding-box;
    border-radius: 8px;
}
.annualPlanTypeParaSpan {
    span {
        color: #376BB4;
    }
}
.premiumCardBoxShadow {
    box-shadow: 0px 23px 17px #00000024!important;
}