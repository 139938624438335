body {
    /* background: #F0F0F0 !important; */
     background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);
}
.profileMainContainer{
    width: 100%;
    height: 100%;
    background: #F0F0F0;
    border-radius: .5rem;
    margin-top: 0.85rem;
}

/* ----------User Profile Details Section-------*/
.userProfileDetailsMain{
   
    background: #ffff;
    display: flex;
    height: 260px;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 5px #00000026;
border-radius: 8px;
opacity: 1;

}
.ProfileImageDiv{
    width: 20rem;
    position: relative;
    background-size: cover;
    background-position: center;
    width: 260px;
    height: 260px;
    border-radius: 8px;
}
.loaderIconCssUserSelected{
    width: 3.5rem;
}
.NoProfileImageDiv{
    width: 20rem;
    background-color: #F5F5F5 !important;
    text-align: -webkit-center;
    padding-top: 5rem;

}
.NoStudentImageMainDiv{
    width: 7rem;
    height: 7rem;
    border-radius: 10rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
}
.NoImageCameraIcon{
    width: 3rem;
    margin-top: 2rem;
    cursor: pointer;

}
.NoImageLoader{
    width: 6rem;
    margin-top: 2rem;
    cursor: pointer;
}
/* ----------user Image Section-------*/

.userDivProfile{
   width: 100%;
   background: #00000059;
    height: 100%;
    padding: 3rem;
}
.userprofileImage{
    width: 8rem;
    height: 8rem;
   
    border-radius: 50rem;
}
.changeButton{
    width: 7rem;
    height: 2.5rem;
    background: #000000 !important;
    border-radius: 1.5rem !important;
    margin-top: 1rem;
    opacity: .7;
    cursor: pointer !important;
    // background: #222222 0% 0% no-repeat padding-box !important;
    border-radius: 12px !important;
    opacity: 0.6;
}
.changeButtonText{
    margin: -.3rem;
    color: white;
    font-weight: 600;
    cursor: pointer !important;

}

/* ----------user Image Section-------*/

/* ----------User info Details Section-------*/
.InfoDiv{
    width: calc(100% - 20rem);
    padding: 1rem;
    padding-left: 35px;
    .MuiOutlinedInput-input {
        padding: 0;
    }
}
.delete-modal-userProfile {
    .modal-content {
        width: 300px;
        height: 240px;
    }
    .modal-header {
        margin-top: 15px;
    }
    .modal-footer {
        padding-bottom: 20px;
    }
    .cancel-btn {
        background: transparent;
        color: #000;
    }
    .sure-text {
        font: normal normal 600 14px/19px Open Sans!important;
letter-spacing: 0px;
color: #222222;
opacity: 1;
    }
    .permanent-text {
        font: normal normal normal 14px/19px Open Sans!important;
letter-spacing: 0px;
color: #222222;
opacity: 1;
    }
}
.header-title-row-user-profile {
    position: relative;
    top: 6px;
}
.noteTextForgot {
    text-align: left;
    margin-top: 10px;
}
.editProfileText{
    color: #222222;
    font-size: 1.2rem;
    font-weight: bold;    
    text-align: left;
    top: 11px;
    position: relative;
}
.inputInfoField{
    width: 50%; 
    // margin: 1rem 1rem 0rem 0rem
    width: 22rem;
}
.inputpasswordFieldMainDiv{
    display: flex !important;
}
.inputpasswordField{
   
    width: 27%; 
    margin: 1rem 1rem 0rem 0rem;
    margin-top: 0px;
}
.ConfirmPswd {
    width: 20%; 

}
.delete-note {
    width: 140px;
height: 40px;



background: transparent linear-gradient(270deg, #EC4444 0%, #FA6060 100%) 0% 0% no-repeat padding-box;
border-radius: 0.5rem!important;
opacity: 1;
}
.add-note {
    width: 101px;
height: 40px;



background: transparent linear-gradient(270deg, var(--unnamed-color-386cb5) 0%, var(--unnamed-color-669fed) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
border-radius: 8px;
opacity: 1;
}
.inputLableInfo {
    color: #AAAAAA;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0rem;
    text-align: left;
}
.InfoInputType {
    border: 0.75px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: .3rem;
    /* width: 38rem; */
    height: 3.5rem;
    padding-left: .5rem;
    background: #F4F6FA;
    width: 100%;
    
    width: 306px;
    height: 40px;
    float: left;
    padding-top: 9px !important;
    padding-left: 13px !important;
    background: #F4F6FA 0% 0% no-repeat padding-box !important;
    border: 0.75px solid #dddddd !important;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: #222222;
text-align: left;
font: normal normal normal 16px/22px Open Sans;
letter-spacing: 0px;
color: #222222;
opacity: 0.51;
}
/* ----------User Profile Details Section-------*/

/* ----------User Profile Details Section-------*/

/* ----------User Profile Details Section-------*/
.paddingDiv{
    // padding: 2rem;
    margin-top: 18px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 5px #00000026;
border-radius: 8px;
opacity: 1;
.MuiOutlinedInput-input {
    padding: 0;
}
}
.changePassMainDiv{
    background: #ffff;
    padding: 1rem;
     border-radius: .7rem 
}
// .MuiOutlinedInput-input {
//     padding: 0px !important;
// }
.passwordMainDiv{
    width: 100%;
    background: #ffff;
    padding: 1rem;
    border-radius: 1rem;

}
.updateBtn{
    text-align: -webkit-center;
    padding-bottom: 1rem;
}
.updateBtn Button {
    border-radius: 2rem;
    height: 3rem;
    padding: .5rem;
    width: 20rem;
    background-image: linear-gradient(to left , #386cb5, #669FED);
    font-size: 1rem ;
    font-weight: 600;
    margin-bottom: 1rem;
}
/* ----------User Profile Details Section-------*/

    .disableDiv{
        pointer-events: none;
    }
    .disableUpdateDiv{
        pointer-events: none;
        opacity: 0.3;
    }
    .resizeModal{
        .modal-dialog{
            display: flex;
            justify-content: center;
            .modal-content{
                width: 360px!important;
                border-radius: 1.4rem!important;
                margin-top: 100px;
                .modal-body{
                    background-color: #000;
                    border-radius: 20px;
                }
            }
        }
    }   
    
@media only screen and (min-width: 250px) and (max-width: 750px) {
    .userProfileDetailsMain{
        display: block;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #00000026;
        border-radius: 8px;
        opacity: 1;
    
    }
    /* ----------user Image Section-------*/

    .ProfileImageDiv{
        width: 100%;
        border-radius: 8px;
    }
    .NoProfileImageDiv{
        width: 100%;
    }

    /* ----------user Image Section-------*/

    /* ----------User info Details Section-------*/
    .InfoDiv{
        width: 100%;
    }

    /* ----------User Profile Details Section-------*/

    
    .inputInfoField{
        width: 100%;
        margin-top: 1.5rem;
    }
    .inputpasswordFieldMainDiv{
        display: block !important;
    }
    .inputpasswordField{
        width: 100%;
        margin-top: 1.5rem;
    }


    .paddingDiv{
        padding: 1rem 0rem 1rem 0rem;
    }
    .changePassMainDiv{
         border-radius: 0rem 
    }
    


}




.header-CND {
    min-height: 5rem;

    .card-body {
        padding: .8rem 1.25rem .8rem 1.25rem !important;
        padding-bottom: 55px;
    }
}

.header-title-CND {
    text-align: left;
    font: normal normal 600 1rem/38px Open Sans;
    color: #222222;
    margin: auto;
    white-space: nowrap;

    .border-right-CND {
        vertical-align: middle;
        width: 80px;
        display: inline-flex;
        border-right: 1px solid lightgray !important;
    }

    @media only screen and (max-width: 1200px) {
        .border-right-CND {
            width: 180px;
        }
    }



    .sub-title {
        font: normal normal normal 12px/17px Open Sans;
        margin-left: 15px;
        color: #777777;
    }
}

.addButton {
    width: auto;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box !important;
    font: normal normal normal 16px/22px Open Sans !important;
    border-radius: 1.5rem !important;
    float: right;
    margin-left: 8px;
}

.disabledAddButton {
    width: auto;
    cursor: default !important;
    background: transparent linear-gradient(270deg, #aec7ea 0%, #b6cae6 100%) 0% 0% no-repeat padding-box !important;
    font: normal normal normal 16px/22px Open Sans !important;
    border-radius: 1.5rem !important;
    float: right;
    margin-left: 8px;
}

.saveCloseBtn {
    float: right;
    margin-left: 8px;
    padding: 0;
}

.plus-img {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.5rem;
    margin-top: -0.1875rem;
}

.refresh-section-CND {
    min-height: 3.75rem;

    .card-body {
        padding: 0.625rem;
    }
}

@media only screen and (max-width: 767px) {
    .classNotekMainContainer-CND {
        padding: 0;
    }

    .main-section-CND {
        padding: 0.9375rem !important;
    }

    .header-title-CND {
        font: normal normal 600 0.875rem/1.188rem Open Sans !important;
        margin-bottom: 10px;
    }

    .addButton {
        width: auto;
        font-size: 14px !important;
    }

    .header-title-CND {
        font: normal normal 600 0.875rem/1.188rem Open Sans !important;
    }
}
.classNotekMainContainer-CND {
    background: #f0f0f0;
    height: 100%;
    padding: 0rem 3rem 0rem 3rem;

    .filterSubDiv {
        margin-top: 6px;
    }

    a:focus,
    .btn:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .back-image-CND {
        align-self: center;
        cursor: pointer;
    }


    .loadMoreRoster-CND {
        width: 8rem !important;
        height: 2.5rem !important;
        border-radius: 2rem !important;
        padding: .6rem !important;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: .6rem !important;
        margin: .1rem .2rem .1rem .2rem;

        .loaderMoreIconCss-CND {
            width: 5.5rem;
            margin-top: -0.5rem;
        }
    }

    .LoadButtonText-CND {
        color: white;
        font-size: .8rem;
        margin-left: .3rem;
        font-weight: 600;
    }

    @media only screen and (max-width: 1200px) {
        .back-btn {
            width: 165px;
        }
    }

    .assessment-btn {
        font: normal normal 600 13px/16px Open Sans !important;
        background: transparent linear-gradient(270deg, #FFB239 0%, #FFC76E 100%) 0% 0% no-repeat padding-box;
        border-radius: 0.5rem;
        border: none !important;
        width: 100%;
        text-align: center;
        color: #ffffff;
        min-height: 48px;

        .actionIcons {
            width: 1.2rem;
            height: 1.2rem;
            float: left;
            margin-right: 0.55rem;
            margin-top: 5px;
            margin-left: 18px;
        }

        @media only screen and (max-width: 1730px) {
            .actionIcons {
                margin-left: 8px;
            }
        }

        @media only screen and (max-width: 1565px) {
            .actionIcons {
                margin-left: 2px;
                margin-right: 4px;
            }
        }
    }

    @media only screen and (max-width: 1450px) {
        .assessment-btn {
            font-size: 12px !important;
        }
    }

    .note-btn {
        font: normal normal 600 13px/18px Open Sans !important;
        background: transparent linear-gradient(270deg, #747BEB 0%, #989EFF 100%) 0% 0% no-repeat padding-box;
        border-radius: 0.5rem;
        border: none !important;
        width: 100%;
        text-align: center;
        color: #ffffff;
        min-height: 48px;

        .actionIcons {
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 0.55rem;
        }
    }

    .main-section-CND {

        .header-CND {
            min-height: 5rem;

            .card-body {
                padding: .8rem 1.25rem .8rem 1.25rem !important;
            }
        }

        .header-title-CND {
            text-align: left;
            font: normal normal 600 1rem/38px Open Sans;
            color: #222222;
            margin: auto;
            white-space: nowrap;

            .border-right-CND {
                vertical-align: middle;
                width: 80px;
                display: inline-flex;
                border-right: 1px solid lightgray !important;
            }

            @media only screen and (max-width: 1200px) {
                .border-right-CND {
                    width: 180px;
                }
            }



            .sub-title {
                font: normal normal normal 12px/17px Open Sans;
                margin-left: 15px;
                color: #777777;
            }
        }

        .addButton {
            width: auto;
            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box !important;
            font: normal normal normal 16px/22px Open Sans !important;
            border-radius: 1.5rem !important;
            float: right;
            margin-left: 8px;
        }

        .disabledAddButton {
            width: auto;
            cursor: default !important;
            background: transparent linear-gradient(270deg, #aec7ea 0%, #b6cae6 100%) 0% 0% no-repeat padding-box !important;
            font: normal normal normal 16px/22px Open Sans !important;
            border-radius: 1.5rem !important;
            float: right;
            margin-left: 8px;
        }

        .saveCloseBtn {
            float: right;
            margin-left: 8px;
            padding: 0;
        }

        .plus-img {
            width: 0.875rem;
            height: 0.875rem;
            margin-right: 0.5rem;
            margin-top: -0.1875rem;
        }

        .refresh-section-CND {
            min-height: 3.75rem;

            .card-body {
                padding: 0.625rem;
            }
        }

        @media only screen and (max-width: 767px) {
            .classNotekMainContainer-CND {
                padding: 0;
            }

            .main-section-CND {
                padding: 0.9375rem !important;
            }

            .header-title-CND {
                font: normal normal 600 0.875rem/1.188rem Open Sans !important;
                margin-bottom: 10px;
            }

            .addButton {
                width: auto;
                font-size: 14px !important;
            }

            .header-title-CND {
                font: normal normal 600 0.875rem/1.188rem Open Sans !important;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .classNotekMainContainer-CND {
            padding: 0;
        }

        .main-section-CND {
            margin-top: 15px;
            padding: 0 !important;
        }

        .filterSubDiv {
            margin-top: 6px;
        }
    }

    @media only screen and (max-width: 520px) {
        .main-section-CND {
            padding: 0 !important;
        }

        .filterDiv {
            float: left;
        }

        .filterSubDiv {
            width: fit-content;
            margin-top: 10px;
        }
    }


    @media only screen and (max-width: 1300px) {
        .classNotekMainContainer-CND {
            padding: .8rem;
        }

        .filterSubDiv {
            float: left;
        }

        .artifactsNote {
            margin-bottom: unset;
        }

        @media only screen and (max-width: 1220px) {
            .classNotekMainContainer-CND {
                padding: 0rem 1rem;
            }
        }
    }

    .desc-search-col-CND {
        float: left;
    }

    .total-student-CND {
        float: left !important;
        margin-top: 7px;
        margin-left: 8px;
    }

    .totalNoteSearchDiv-CND {
        display: flex;
        width: 76%;
    }

    .searchSearchStylediv-CND {
        width: 60%;
    padding-left: 1.5rem;
    border-left: 1px solid lightgray;
    margin-left: 30px;
    height: 37px;
    top: 0px;
    position: relative;
    }

    .totalNoteSearchDiv-CND .searchSearchdiv-CND {
        width: 95% !important;
        height: 36px !important;
        margin-top: 0.1rem !important;
        padding-left: 0rem !important;
        margin-bottom: 0.1rem !important;
        display: inline-flex;
        float: left;
        background: #F4F6FA 0% 0% no-repeat padding-box !important;
        border: 0.75px solid #DDDDDD !important;
        border-radius: 25px !important;
        opacity: 1;
    }

    .totalNoteSearchDiv-CND .searchSearchdiv-CND input {
        cursor: pointer;
    }

    .totalNoteSearchDiv-CND input.middle:focus {
        outline-width: 0 !important;
    }

    .totalNoteSearchDiv-CND .searchInput {
        border: none !important;
        width: 100%;
    }

    .totalNoteSearchDiv-CND .closeIconSearch {
        width: 1rem;
        cursor: pointer;
        margin: 0.9rem .5rem 0rem 0rem;
    }

    .totalNoteSearchDiv-CND .searchIconDiv-CND {
        padding: 0rem .5rem 0rem .5rem !important;
    }

    .totalNoteSearchDiv-CND input.middle:focus {
        outline-width: 0 !important;
    }

    .totalNoteSearchDiv-CND .searchIcon {
        color: #747373 !important;
        margin-left: 7px;
    }

    .totalNoteSearchDiv-CND .searchStudent-CND {
        background: transparent !important;
        border: none !important;
        width: 100% !important;
        font: italic normal normal 13px/17px Open Sans;
    }

    .totalNoteSearchDiv-CND input {
        outline: none;
    }

    .Button-div-1-CND {
        float: right;
        margin-right: 20px;
        margin-left: 15px;
        padding-left: 7px;
    }

    .Button-div-1-CND .btn {
        height: 32px;
    }

    .Button-div-2-CND {
        float: right;
    }

    .Button-div-2-CND .btn {
        height: 32px;
    }

    .custom-outline-btn-CND {
        border: 1px solid var(--unnamed-color-cccccc) !important;
        border: 1px solid #CCCCCC !important;
        border-radius: 8px !important;
        opacity: 1 !important;
        margin-left: 10px !important;
        text-align: left !important;
        font: normal normal normal 14px/18px Open Sans !important;
        letter-spacing: 0px !important;
        color: #222222 !important;
        opacity: 1 !important;
        outline: 1px solid #CCCCCC !important;
    }

    .custom-outline-btn-CND :hover {
        color: #CCCCCC !important;
    }

    .btn-outline-secondary:hover {
        background-color: #fff !important;
        border-color: #6c757d;
    }

    .btn-outline-secondary:active {
        background-color: #fff !important;
        border-color: #6c757d;
    }

    .custom-button-CND {
        border-radius: 8px !important;
        opacity: 1 !important;
        margin-left: 10px !important;
        text-align: left !important;
        font: normal normal normal 14px/18px Open Sans !important;
        letter-spacing: 0px !important;
        color: #ffffff !important;
        opacity: 1 !important;
        background-color: #386CB5E6 !important;
    }

    .tapToAddText-CND {
        color: #AAAAAA;
        font-size: 2rem;
        font-weight: 600;
        margin-top: 70px;

    }

    // .sort-by-col-CND {
    //     display: flex;
    //     float: right;
    //     direction: row;
    //     justify-content: flex-end;
    //     align-items: center;
    // }

    // .sort-by-CND p {
    //     margin: 0px 0px 0px 0px !important;
    //     padding: 0px;
    //     font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
    //     letter-spacing: var(--unnamed-character-spacing-0);
    //     color: var(--unnamed-color-222222);
    //     text-align: left;
    //     font: normal normal 600 14px/19px Open Sans;
    // }

    .year-CNDD .classForIcon-CND {
        // color: #487FCC;
        color: #000000;
        position: relative;
        margin-top: -4px;
        vertical-align: middle;
    }

    // #Note-CND {
    //     color: #222222 !important;
    //     font-weight: 800;
    //     font-size: large;
    // }

    .year-CNDD {
        position: relative;
        // color: #487FCC;
        color: #000000 !important;
        margin-left: 10px;
        white-space: nowrap;
        top: 2px;
        cursor: pointer;

        .text-italic {
            font-style: italic;
        }
    }

    .note-dashboard-search-image-CND {
        width: 14px;
        height: 14px;
        align-self: center;
        position: absolute;
        top: 8px !important;
        right: 27px !important;
        z-index: 2 !important;
    }

    .filterRow-CND {
        justify-content: space-between;
    }

    // Select Search CSS

    .header-dropdown-main-container-CND {
        width: 30%;

        .dropdown-search-icon-CND {
            width: 14px;
            height: 14px;
            align-self: center;
            position: absolute;
            top: 9px !important;
            right: 27px !important;
            z-index: 2 !important;
        }

        .dropdown-main-container-CND {
            font-family: sans-serif;
            text-align: center;
            width: 50px;
            height: 50px;
            position: relative;
            top: 8px;
            left: -10%;

            /**
           * Main wrapper
           */
           .select-search {
                        width: 240px !important;
                        position: relative;
                        font-family: "Nunito Sans", sans-serif;
                        box-sizing: border-box;
                        bottom: 5px !important;
                        cursor: pointer;
                    }

                    .select-search *,
                    .select-search *::after,
                    .select-search *::before {
                        box-sizing: inherit;
                    }

                    .select-search__value {
                        position: relative;
                    }

                    .select-search__value::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        top: calc(50% - 9px);
                        right: 19px;
                        width: 11px;
                        height: 11px;
                        cursor: pointer;
                    }

                    .select-search__input {
                        margin-top: 5px;
                        display: block;
                        height: 40px;
                        width: 240px;
                        padding: 0 40px 0 30px;
                        background: #fff;
                        border: 1px solid #dddddd;
                        box-shadow: 0px 0px 3px #88c3fae3;
                        border-radius: 8px;
                        outline: none !important;
                        font-family: "Noto Sans", sans-serif;
                        font-size: 14px;
                        color: rgba(56, 108, 181, 1);
                        text-align: left;
                        text-overflow: ellipsis;
                        line-height: 36px;
                        -webkit-appearance: none;
                        opacity: 1;
                    }

                    .select-search__input::placeholder {
                        font: italic normal normal 13px/17px Open Sans;
                        letter-spacing: 0px;
                        color: #c6b6b6 !important;
                    }

                    .select-search__input::-webkit-search-decoration,
                    .select-search__input::-webkit-search-cancel-button,
                    .select-search__input::-webkit-search-results-button,
                    .select-search__input::-webkit-search-results-decoration {
                        -webkit-appearance: none;
                    }

                    .select-search__input:not([readonly]):focus {
                        cursor: initial;
                    }

                    .select-search__select {
                        max-height: 150px !important;
                        box-shadow: 10px 10px 12px #ec090929;
                        border: 1px solid #dddddd;
                    }

                    .select-search__options {
                        list-style: none;
                        padding: 0px 5px;
                        background: #fff;
                        cursor: pointer;
                    }

                    .select-search__row {
                        border-bottom: 1px solid #dddddd;
                        border-width: 0.15px;
                    }

                    .select-search__row:last-child {
                        border-bottom: none;
                    }

                    .select-search__option,
                    .select-search__not-found {
                        display: block;
                        height: 36px;
                        width: 100%;
                        background: #fff;
                        border: none;
                        outline: none;
                        font-family: "Noto Sans", sans-serif;
                        font-size: 14px;
                        text-align: left;
                        cursor: pointer;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .select-search--multiple .select-search__option {
                        height: 48px;
                    }

                    .select-search__option.is-selected {
                        color: #fff;
                    }

                    .select-search__option.is-highlighted.is-selected,
                    .select-search__option.is-selected:hover {
                        color: #fff;
                    }

                    .select-search__group-header {
                        text-transform: uppercase;
                        background: #fff;
                    }

                    .select-search.is-disabled {
                        opacity: 0.5;
                    }

                    .select-search.is-loading .select-search__value::after {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
                        background-size: 11px;
                    }

                    .select-search:not(.is-disabled) .select-search__input {
                        cursor: pointer;
                        letter-spacing: 0px;
                        font-weight: 600;
                    }

                    .select-search--multiple {
                        border-radius: 3px;
                        overflow: hidden;
                    }

                    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
                        transform: rotate(45deg);
                        border-right: 3px solid #000;
                        border-bottom: 3px solid #000;
                        pointer-events: none;
                    }

                    .select-search.has-focus .select-search__value::after {
                        display: none;
                    }

                    .select-search.has-focus .select-search__input:hover {
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }

                    .select-search.has-focus .select-search__input {
                        border-bottom: none;
                        box-shadow: 0px 0px 3px #00000029;
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-color: none;
                        background: #f4f6fa 0% 0% no-repeat padding-box;
                    }

                    .select-search--multiple .select-search__input {
                        cursor: initial;
                    }

                    .select-search--multiple .select-search__input {
                        border-radius: 3px 3px 0 0;
                    }

                    .select-search--multiple:not(.select-search--search) .select-search__input {
                        cursor: default;
                    }

                    .select-search:not(.select-search--multiple) .select-search__select {
                        position: absolute;
                        z-index: 2;
                        top: 40px !important;
                        right: 0;
                        left: 0;
                        border-radius: 8px;
                        overflow: auto;
                        max-height: 360px;
                        background: #fff;
                        border-top-right-radius: 0px;
                        border-top-left-radius: 0px;
                        box-shadow: none;
                    }

                    .select-search--multiple .select-search__select {
                        position: relative;
                        overflow: auto;
                        max-height: auto;
                        border-top: 1px solid #eee;
                        border-radius: 0 0 3px 3px;
                        background: #fff;
                    }

                    .select-search__not-found {
                        height: auto;
                        padding: 8px;
                        text-align: center;
                        color: rgb(244, 245, 247);
                    }


        }
    }

    @media screen and (min-width: 1275px) and (max-width: 1480px) {
        .searchSearchStylediv-CND {
            width: 92%;
        }

        .totalNoteSearchDiv-CND {
            width: 63%;
        }

        .desc-search-col-CND {
            max-width: 378px !important
        }

        // .sort-by-col-CND {
        //     min-width: 680px !important;
        // }

        .note-dashboard-dropdown-container-CND {
            right: 35px;
        }
    }

    @media screen and (min-width: 1300px) and (max-width: 1350px) {
        .searchSearchStylediv-CND {
            width: 92%;
        }

        .totalNoteSearchDiv-CND {
            width: 63%;
        }

        .desc-search-col-CND {
            max-width: 420px !important
        }

        // .sort-by-col-CND {
        //     min-width: 665px !important;
        // }

        .note-dashboard-dropdown-container-CND {
            right: 35px;
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1300px) {
        .classNotekMainContainer-CND {
            background: #f0f0f0;
            height: 100%;
            padding: 0rem;
        }
    }
}


.MuiOutlinedInput-input {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    padding-left: 11px;
    padding-top: 9px;
}

.phoneno {
    margin-top: 8px !important;
}