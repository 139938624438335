.addStudents-grading-container {
    padding-left: -15px !important;
    padding-right: -15px !important;
    .addStudentsGrading {
        background-color: #f0f0f0 !important;
        border: none !important;
        height: 55.6vh;
        .overFlowClass {
            overflow-y: scroll;
            height: 59vh;
            margin-left: -40px;
            margin-right: -40px;
            display: flex;
            flex-wrap: wrap;
        }
        .singleDiv {
            width: 333px;
            // height: 227px;
            height: 203px;
            margin-left: 7.5px;
            margin-right: 7.5px;
            padding: 0px 15px;
            margin-top: 15px;
            background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 4px #2222221a;
            border-radius: 8px;
            .left-section {
                float: left;
                width: 100%;
                margin-bottom: 15px;
                .profile {
                    text-align: left;
                    margin: auto;
                    display: flex;
                    margin-top: 15px;
                    .profile-img {
                        width: 3.75rem !important;
                        height: 3.75rem !important;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat
                            padding-box;
                        border-radius: 50rem;
                    }
                    .imageDumyGradesProfile {
                        width: 3.75rem;
                        height: 3.75rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat
                            padding-box;
                        box-shadow: 1px 2px 3px #0000001a;
                        border-radius: 50rem;
                        margin-top: 0rem;
                        text-align: center;
                        opacity: 0.8;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .gradebookProfileImageText {
                            margin: 0px !important;
                            color: #fff;
                            text-transform: uppercase;
                            cursor: default;
                        }
                    }
                    label {
                        font: normal normal 600 13px/18px Open Sans;
                        color: #222222;
                        font-weight: 600;
                        // margin: auto;
                        margin-left: 8px;

                        .email {
                            font: normal normal normal 13px/18px Open Sans;
                            color: #777777;
                        }
                    }
                }
            }
            .number-arrow {
                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }

                /* Firefox */
                input[type=number] {
                -moz-appearance: textfield;
                }
            }
            .right-section {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 0px !important;
                .inputs {
                    width: 100%;
                    .colorsInput {
                        display: flex;
                        justify-content: space-between;
                        .scoreBoxGrade {
                            display: flex;
                            justify-content: flex-start;
                            .score-input {
                                float: right;
                                width: 77px;
                                height: 38px;
                                background: #f4f6fa 0% 0% no-repeat padding-box;
                                border: 0.75px solid #cccccc;
                                border-radius: 5px;
                                font: normal normal normal 12px/17px Open Sans;
                                color: #222222;
                                margin-top: -7px;
                                font-weight: bold;
                                // padding-top: 2px;
                                display: flex;
                                justify-content: center;
                                .MuiInputBase-input {
                                    padding: 9px 0 7px;
                                    text-align: center;
                                    font: normal normal normal 12px/17px Open Sans;
                                    color: #222;
                                    font-weight: 600;

                                    &::placeholder {
                                        color: #6c757d;
                                        opacity: 1;
                                    }
                                }
                                
                            }
                        }
                        .colorBoxGrade {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }
                    }
                    .commentBoxGrade {
                        .commentBox {
                            width: 100%;
                            height: 60px;
                            background: #f4f6fa 0% 0% no-repeat padding-box;
                            border: 0.75px solid #cccccc;
                            border-radius: 5px;
                            font: normal normal normal 12px/17px Open Sans;
                            color: #222222;
                            margin-top: 10px;
                            font-weight: bold;
                        }
                    }
                }
            }

            @media only screen and (max-width: 1037px) {
                .right-section {
                    margin-top: 15px;
                }
            }

            @media only screen and (max-width: 991px) {
                .right-section {
                    margin-top: 15px;
                    display: flex;
                    width: 100%;
                }
                .inputs {
                    width: 100%;
                }
            }

            @media only screen and (max-width: 767px) {
                .right-section {
                    margin-top: 0;
                }
            }
        }

        @media only screen and (max-width: 1090px) {
            .singleDiv {
                padding: 0px 12px;
            }
        }

        @media only screen and (max-width: 767px) {
            .singleDiv {
                padding: 0px 5px;
            }
        }
    }
}
@media screen and (max-height: 768px) {
    .singleDiv {
        width: 282px !important;
    }
}


@media only screen and (min-width: 1500px) and (max-width: 1920px) {
    .singleDiv {
        width: 316px !important;
    }
}

@media screen and (min-height: 768px) {
    .singleDiv {
        width: 316px !important;
    }
}

@media only screen and (min-width: 1600px) {
    .singleDiv {
        width: 359px !important;
    }
}
@media only screen and (min-width: 1800px) {
    .singleDiv {
        width: 387px !important;
    }
}
@media only screen and (min-width: 2000px) {
    .singleDiv {
        width: 388px !important;
    }
}

@media screen and (max-width: 768px) {
    .singleDiv {
        width: 333px !important;
    }
}
