.table-right-section {
    .w-120 {
        width: 120px;
    }

    .w-240 {
        width: 240px;
    }
    .backgroundEvent {
        background: #386cb51c;
    }
    .data-title {
        text-align: center;
        font: normal normal 600 12px/17px Open Sans;
        letter-spacing: 0px;
        color: #386cb5;

        .main-header {
            height: 40px;
            display: flex;
            border-bottom: 1px solid #cccccc;
            font: normal normal 600 12px/17px Open Sans;
            position: relative;
            border-right: 1px solid #cccccc;
            justify-content: center;
            label {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px !important;
                cursor: pointer;
            }
        }

        .sub-header {
            height: 40px;
            display: flex;
            font: normal normal normal 12px/17px Open Sans !important;
            cursor: pointer;
            justify-content: center;
            label {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px !important;
                cursor: pointer;
            }
        }
    }

    .grade-div {
        display: flex;
        background: #386cb51c;

        .grade-btn {
            text-decoration: underline;
            font: normal normal 600 11px/15px Open Sans;
            color: #386cb5;
        }
    }
    .score {
        margin: auto;
        cursor: pointer;

        .green {
            color: #23bf1d;
        }

        .red {
            color: #ff4444;
        }

        .blue {
            color: #386cb5;
        }

        .yellow {
            color: #fab700;
        }

        label {
            font: normal normal 600 16px/22px Open Sans;
            font-weight: 600;
            margin: auto;
            cursor: pointer;
            max-width: 75px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .comment-img {
            width: 16px;
            height: 15px;
            margin-right: -10px;
            margin-left: 8px;
            margin-top: -11px;
        }
    }
}

.swipeIcon {
    position: sticky;
    top: 10%;
}
.eventSwipeIcon {
    position: absolute;
    right: 6px;
    top: 6px;
    height: 27px;
    cursor: pointer;
}

.header-style {
    height: 80px;
    border-bottom: 1px solid #cccccc;
    display: flex;
}

.data-header-style {
    height: 40px;
}

.data-sub-header-style {
    width: 120px;
    height: 40px;
    border-bottom: 1px solid #cccccc;
}

.data-sub-Avarage-style {
    width: 120px;
    height: 50px;
    margin: auto;
    font: normal normal 600 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    padding: 10px 20px;
}

.data-sub-Avarage-label {
    max-width: 75px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-top: 2px;
}

.data-style {
    justify-content: center;
    display: flex;
    height: 80px;
    border-bottom: 1px solid #cccccc;
}
.data-styles {
    width: 120px;
    display: flex;
    height: 80px;
}
.displayFlex {
    display: flex;
}

.bl-1 {
    border-left: 1px solid #cccccc;
}

.mt-40 {
    margin-top: -40px;
}

.avg-row-data {
    background: #cccccc 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 0px #2222221a;
    height: 50px;
    margin: auto;
    font: normal normal 600 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    padding: 10px 20px;
}
