.payPalContainer {
  justify-content: center;
  text-align: -webkit-center;
  padding: 0px 150px 50px 150px;
  .header-main-container {
    width: 100%;
    height: 80px;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0px 0px 8px 8px;

    .header-breadcrumb-main-container {
      display: flex;
      align-items: center;
      width: 30%;

      .header-breadcrumb-back-btn-div {
        display: flex;
        background: transparent;
        cursor: pointer;
        align-items: center;
        .header-breadcrumb-back-btn-img {
          width: 60px;
          height: 40px;
          align-self: center;
          cursor: pointer;
        }
      }
      .header-breadcrumb-inner-div {
        margin-left: 1rem;
        border-left: 1px solid #eeeeee;
        padding: 0.3rem 0rem 0rem 1rem;
        .breadcrumb-inner-flex-div {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: default;
          width: max-content;
          padding-top: 9px;
          .breadcrumb-academic-year-text {
            color: #487fcc;
            margin-bottom: 15px;
            margin-right: 10px;
            font-size: 1rem;
            font-weight: 600;
            text-align: left;
            cursor: pointer;
          }
          .breadcrumb-arrow-icon {
            color: #487fcc;
            margin-bottom: 15px;
            margin-left: -8px;
          }
          .breadcrumb-attendance-text {
            color: #222222;
            font-size: 1.25rem;
            font-weight: 600;
            text-align: left;
            cursor: default;
          }
        }
      }
    }
  }

  .mainCardRow {
    // width: 80%;
    width: 730px;
    background: white;
    border-radius: 8px;
  }
  .cardPlanRow {
    border-radius: 6px;
    opacity: 1;
    // width: 560px;
    padding: 25px 5px 10px 5px;
    margin: 0px;
    margin-right: 20px;
    margin-top: 20px;
    // height: 200px;

    .planIconBg {
      background: #f9f9f9 0% 0% no-repeat padding-box;
      border-radius: 8px 0px 0px 8px;
    }
  }
  .planTypeTextDiv {
    float: left !important;
    text-align: left !important;
  }
  .cardOfPlanRules {
    width: 100%;
    min-height: 300px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a;
    margin-top: 20px;
    padding: 40px;
    border: none;
  }

  .planIconDiv {
    width: 100px;
    height: 100px;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    background: transparent linear-gradient(45deg, #669eec 0%, #3e73bc 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029;
    border: 3px solid #fefefe;
    opacity: 1;
  }
  .planDetails {
    display: inline-flex;
    float: left;
  }
  .planType {
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    text-transform: uppercase;
    margin-bottom: 10px;
    opacity: 1;
  }
  .planPrice {
    text-align: left;
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #386cb5;
  }
  .planDetailsPoint {
    color: var(--unnamed-color-222222);
    text-align: left;
    // font: normal normal normal 14px/15px Open Sans;
    // letter-spacing: 0px;
    // color: #222222;
    // font-weight: bold;
    font: normal normal normal 14px/20px Open Sans;
    letter-spacing: 0px;
    color: #222222;
  }
  .benefitsDiv {
    margin-top: 0px;
  }

  .goHomeButton {
    width: 231px;
    height: 35px;
    background: transparent linear-gradient(90deg, #669eec 0%, #3e73bc 100%) 0%
      0% no-repeat padding-box;
    border-radius: 20px;
  }
  .paypalButtonDiv {
    display: inline-flex;
  }

  .goBackButton {
    width: 136px;
    height: 35px;
    background: transparent linear-gradient(90deg, gray 100%, #3e73bc 100%) 0%
      0% no-repeat padding-box;
    border-radius: 20px;
    margin-right: 20px;
  }
  .retryPaymentButton {
    width: 196px;
    height: 35px;
    background: transparent linear-gradient(90deg, #01e31c 0%, #00b115 100%) 0%
      0% no-repeat padding-box;
    border-radius: 21px;
    margin-right: 20px;
  }
  #paypal-button-container {
    width: 231px !important;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #386cb566;
  border-radius: 6px;
}
.paypal-button.paypal-button-color-blue {
  border-radius: 8px!important;
  background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box !important;
box-shadow: 0px 0px 5px #386CB566 imo !important;
  }

  .paymentSucessDiv {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #f9f9f9 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a;
    width: 100%;
    min-height: 400px;
    padding: 30px;
    padding-top: 50px;
  }
  .paymentSuccessImage {
    width: 450px;
    height: 200px;
  }
  .mTop20 {
    margin-top: 20px;
  }
  .mTop30 {
    margin-top: 30px;
  }
  .mBot5 {
    margin-bottom: 5px;
  }
  .dataPlans {
    position: relative;
    line-height: 1.5em;
  }
  @media only screen and (min-width: 951px) and (max-width: 1150px) {
    padding: 0px 50px 50px 50px;
    .cardPlanRow {
      width: 100%;
      margin-right: 0px;
    }
  }

  @media only screen and (min-width: 551px) and (max-width: 950px) {
    padding: 0px 20px 50px 20px;

    .cardPlanRow {
      width: 100%;
      margin-right: 0px;
    }

    .paymentSuccessImage {
      width: 350px;
      height: 150px;
    }
  }

  @media only screen and (min-width: 251px) and (max-width: 550px) {
    padding: 0px 20px 50px 20px;
    .cardPlanRow {
      width: 100%;
      margin-right: 0px;
    }
    .upgradePlanButton {
      margin-bottom: 10px;
    }
    .paypalButtonDiv {
      display: block;
      margin-top: 40px;
    }
    .paymentSucessDiv {
      padding: 10px;
      padding-top: 50px;
    }
    .paymentSuccessImage {
      width: 250px;
      height: 150px;
    }
    .goBackButton {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
}
.selectedPlanSuccessPara {
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #111111;
}
.subscriptionSuccessParaStyle {
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #222222;
}
.stripePaymentDiv {
  .btn {
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #386cb566;
    border-radius: 6px;
    opacity: 1;
    width: 356px;
    height: 45px;
  }
}
.zoid-outlet {
  width: 365px!important;
}
.newPaypalBtnClass {
  width: 365px !important;
}