.br-8 {
  border-radius: 0.5rem !important;
}

.mt-15 {
  margin-top: 0.9375rem;
}

.mb-15 {
  margin-bottom: 0.9375rem;
}

.pr-7-5 {
  padding-right: 0.4375rem !important;
}

.pl-7-5 {
  padding-left: 0.4375rem !important;
}

.loaderUploadIcon {
  width: 3.5rem;
}
.cancel-btn-div-note {
  background-color: #ffffff !important;
    font: normal normal 600 14px/19px Open Sans;
    color: #080808 !important;
    width: 101px;
    // color: #fff;
    // height: 38px;
    text-transform: inherit!important;
}
.smartAI_btn {
  background: #669FED21 0% 0% no-repeat padding-box !important;
border: 1px solid #386CB5;
border-radius: 5px;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
height: 30px;
color: #386CB5;
opacity: 1;
position: absolute !important;
top: 0;
right: 13px;
}
.generateAudioModal {
  .modal-content {
    min-width: 600px!important;
  }
  .modal-header .close {
    // margin-top: -19px!important;
    color: #7070707D!important;
  }
  .generateAudioModalHeader {
    p {
      font: normal normal 600 13px/20px Open Sans;
letter-spacing: 0px;
color: #222222;
    }
    .time {
      font: normal normal normal 13px/20px Open Sans;
letter-spacing: 0px;
color: #AAAAAA;
    }
  }
  
}
.transcribeAudioModalClass {
  .modal-header .close {
    margin-top: -19px!important;
    color: #7070707D!important;
  }
}
.summarizeModal {
  .modal-content {
    min-width: 790px!important;
  }
  .modal-header .close {
    margin-top: -19px!important;
    color: #7070707D!important;
  }
  .hint_para {
    background: #FFF4AB 0% 0% no-repeat padding-box;
border-radius: 5px;
opacity: 1;
height: 28px;
text-align: left;
font: normal normal normal 13px/20px Open Sans;
color: #222222;
display: flex;
align-items: center;
padding-left: 13px;
  }
}
.transscribe-audio-modal-footer {
  padding: 0 1rem 0.75rem 1.5rem!important;
  .summarize_btn {
    text-align: center;
font: normal normal 600 14px/19px Open Sans;
letter-spacing: 0px;
color: #386CB5;
text-transform: capitalize;
opacity: 1;
.MuiSvgIcon-root {
  position: relative;
  top: 1px;
  font-size: 15px!important;
}
  }

}
.transcribe_para {
  border: 1px solid #70707066;
  border-radius: 8px;
color: #222222;
text-align: left;
padding: 5px 10px;
height: 150px;
overflow-y: scroll;

h5 {
  font : normal normal 600 14px/19px Open Sans
}
}

.summarize_para {
  border: 1px solid #70707066;
  border-radius: 8px;
color: #222222;
text-align: left;
padding: 0 10px 5px 10px;
height: 150px;
overflow-y: scroll;
width: 370px;
h5 {
  font : normal normal 600 14px/19px Open Sans;
  position: sticky;
  background-color: #fff;
  top: 0;
  height: 30px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
p {
  font: normal normal normal 14px/20px Open Sans;
}
}
.selectAudioModal {
  .modal-content {
    min-width: 700px!important;
  }
  .audioRadioBtn {
    .Mui-checked {
      color : #549F46;
    }
    .MuiButtonBase-root-MuiRadio-root.Mui-checked {
      color: #549F46;
    }
  }
}
.audioNoteTextAreaEdit {
  border: none !important;
  padding: 0 !important;
  font: normal normal normal 14px/20px Open Sans !important;

  &:disabled {
    background-color: #fff!important;
  }
}
.positive_comment_btn {
  background: #B45DD721 0% 0% no-repeat padding-box;
border: 1px solid #B45DD7;
border-radius: 15px;
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #B45DD7;
padding : 3px 10px;
}
.assignment_btn {
  border: 1px solid var(--unnamed-color-ff6a6a);
background: #FF6A6A17 0% 0% no-repeat padding-box;
border: 1px solid #FF6A6A;
border-radius: 15px;
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #F82E2E;
padding : 3px 10px;
}
.teaching_point_btn {
  background: #F89C4821 0% 0% no-repeat padding-box;
border: 1px solid #D27B2C;
border-radius: 15px;
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #D27B2C;
padding : 3px 10px;
}
.instructional_need_btn {
  background: #669FED26 0% 0% no-repeat padding-box;
border: 1px solid #386CB5;
border-radius: 15px;
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #386CB5;
padding : 3px 10px;
}
.comment_one_btn {
  border: 1px solid var(--unnamed-color-11be9f);
background: #11BE9F29 0% 0% no-repeat padding-box;
border: 1px solid #11BE9F;
border-radius: 15px;
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #11BE9F;
padding : 3px 10px;
}
.insertDriveButton {
  .MuiIconButton-root {
    padding: 0 6px 0 0;
  }
  .MuiSvgIcon-root {
    color: #386CB5;
  }
}
.goback-btn-div-note {
  width: 120px;
  // height: 38px;
  border-radius: 8px;
  background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
  font: normal normal 600 14px/19px Open Sans;
  color: #fff!important;
  text-transform: inherit!important;
}
.stdNoteDetailTextDiv {
  border-left: 2px solid #eee;
  margin-left: 15px
}
.radioCardAudio {
   .audio-sub-div {
    background: #fff!important;
    // border: solid 1px #eee;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 0.5rem 0.9375rem 0.5rem 0.5rem !important;
  }
  .audit-sec audio {
    background-color: #fff;
  }
  audio::-webkit-media-controls-enclosure {
    background : #fff;
  }
  .audio-filename {
    font: normal normal 600 14px/19px Open Sans;
    text-align: left;
    margin-top: 3px;
  }

  /* Removes the time stamp */
audio::-webkit-media-controls-time-remaining-display {
  // display: none;
  // position: absolute;
  // right: 0;
}
// audio::-webkit-media-controls-time-remaining-display::before {
//   content: "";
//   display: none;
// }
// audio::-webkit-media-controls-time-remaining-display {
//   color: red;
//  &:first-child {
//   color: red;
//  }
//  &::first-letter {
//   color: red!important;
//  }
//  &::first-line {
//   color: red!important;
//  }
// }
// audio::-webkit-media-controls-current-time-display {
//   display: none;
// }
/* Removes mute-button */
audio::-webkit-media-controls-mute-button {
  display: none;
}

audio::-webkit-media-controls-volume-control-container {
  display: none;
}
}
.student-note-add-date-picker {
  .react-datepicker {
    right: 23px;
    position: relative;
    top: auto;
  }
  .react-datepicker__triangle {
    left: 21px!important;
  }
}
.stdNoteDisplayFlex {
  display: flex;
  cursor: pointer;
  margin-top: 9px;
  margin-left: 15px;
  @media screen and (min-width:1200px) and (max-width:1300px) {
    margin-left: 5px;
  }

  p {
    margin: 0px !important;
    padding: 2px 0px 0px 0px !important;
    font-size: 16px;
  }

  i {
    color: #487FCC;
    font-size: 24px;
    cursor: default;
  }
}

.currentYear {
  color: #487FCC;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  cursor: default;
}

.stdNoteBackDiv {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  color: #222222;
  cursor: pointer;
}

.stdNoteGrade2 {
  color: #222222;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: left;
  cursor: default;
}

.stdNoteGrade1 {
  color: #487FCC;
  cursor: default;
}

.timeline-time-Unsaved {
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  letter-spacing: 0;
  color: #386CB5;
  margin-right: 0.5rem;
  font-weight: 600;
  margin-left: 5px;
}

.timeline-time-cursor {
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  letter-spacing: 0;
  color: #222222;
  margin-right: 0.5rem;
  cursor: pointer;
}



.addNoteMainContainer {
  background: #F0F0F0;
  height: 100%;
  padding: 0rem 3rem 0 3rem;
  overflow-x: hidden;
}

.addNoteMainContainer a:focus,
.addNoteMainContainer .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.addNoteMainContainer .action-btn {
  padding: 0;
  background: transparent;
  border: none;
  margin: 0rem .5rem 0rem .5rem;
}

.addNoteMainContainer .note-header-title {
  display: flex;
  font: normal normal 600 0.875rem/1.188rem Open Sans !important;
}


.addNoteMainContainer .noteDate {
  display: inline-flex;
  font: normal normal normal 0.875rem/1.188rem Open Sans !important;
}

/* css for date picker */
.addNoteMainContainer .noteDate .react-datepicker-wrapper {
  margin: auto 0;
  margin-right: 5px;
}

.addNoteMainContainer .noteDate .react-datepicker__header {
  background-color: #ffffff;
}

.addNoteMainContainer .noteDate .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #ffffff;
}

.react-datepicker-wrapper img {
  margin-right: 5px;
  margin-bottom: 2px;
  color: #BD47E8;
}

/* css for date picker */

.MuiButton-startIcon {
  margin-bottom: 3px;
  margin-right: 4px !important;
}


.addNoteMainContainer .note-header-right-section {
  font: normal normal normal 0.8125rem/2.125rem Open Sans;
  text-align: right;
  white-space: nowrap;
}

.addNoteMainContainer .note-header-right-section .react-datepicker__tab-loop {
  display: inline-flex;
}

.addNoteMainContainer .note-header-right-section .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__triangle {
  margin-left: 1px !important;
}

.addNoteMainContainer .note-header-right-section .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__month-container {
  display: none;
}

.addNoteMainContainer .note-header-right-section .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__navigation {
  display: none;
}

.addNoteMainContainer .note-header-right-section .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__time-container {
  border-left: none;
}

.addNoteMainContainer .reminderIcon {
  margin: 0 0.5rem 0 0;
  margin-top: -3px;
  cursor: pointer;
}

.addNoteMainContainer .divider {
  border: 0.0625rem solid #DDDDDD;
  margin: 0 0.625rem;
}

.addNoteMainContainer .profile-card {
  margin-top: 1.25rem;
  text-align: left;
}

.addNoteMainContainer .profile-card-img {
  width: 3.125rem;
  height: 3.125rem;
  background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001A;
  border-radius: 50rem;
  float: left;
  margin-right: 1rem;
}

.addNoteMainContainer .profile-img {
  width: 3.125rem !important;
  height: 3.125rem !important;
  border-radius: 50% !important;
}

.addNoteMainContainer .profile-name {
  font: normal normal 600 1rem/1.375rem Open Sans;
  color: #222222;
}

.addNoteMainContainer .profile-mail {
  font: normal normal normal 0.875rem/1.188rem Open Sans;
  color: #777777;
}

.addNoteMainContainer .linked-contact-card ol {
  font: normal normal normal 0.875rem/1.188rem Open Sans;
  padding: 0 0 0 0.8125rem;
  text-align: left;
  line-height: 200%;
  margin: 0;
}

.addNoteMainContainer .linked-contact-card ol span {
  float: right;
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  color: #AAAAAA;
}

.add-assignment-sec textarea {
  font: normal normal normal 0.8125rem/1.125rem Open Sans !important;
  background: #F4F6FA 0% 0% no-repeat padding-box;
  border: 0.0469rem solid #CCCCCC;
  min-height: 8.35rem;
}

.add-assignment-sec .title {
  text-align: left;
  font: normal normal 600 0.8125rem/1.875rem Open Sans;
  height: 1.825rem;
}

.add-assignment-sec .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #44CE3E;
  background-color: #44CE3E;
  width: 3.75rem;
  height: 2rem;
  border-radius: 1.25rem;
  margin-left: -1.5rem;
  margin-top: -0.375rem;
}

.add-assignment-sec .custom-switch .custom-control-input:checked~.custom-control-label::after {
  left: calc(-2.25rem + 2px);
  background-color: #fff;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 1.25rem;
  margin-left: -0.5rem;
  margin-top: -0.3125rem;
}

.add-assignment-sec .custom-switch .custom-control-label::before {
  left: -2.25rem;
  pointer-events: all;
  color: #fff;
  width: 3.75rem;
  height: 2rem;
  border-radius: 1.25rem;
  margin-left: -1.5rem;
  margin-top: -0.375rem;
}

.historyIconPink {
  width: 16px;
  height: 18px;
  margin-left: -165px;
  margin-top: 7px;
}

.Point {
  color: Grey;
}

.add-assignment-sec .custom-switch .custom-control-label::after {
  left: calc(-3.2rem + 2px);
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 1.25rem;
  margin-left: -0.5rem;
  margin-top: -0.3125rem;
}

.add-assignment-sec .card-body {
  padding: 1.125rem;
}

.add-Level-Score-sec {
  height: 4.375rem;
}

.add-Level-Score-sec .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.938rem;
}

.add-media-sec {
  min-height: 18.5rem;
}

.add-media-sec .title {
  text-align: left;
  font: normal normal 600 0.8125rem/1.875rem Open Sans;
  margin-bottom: 0.3125rem;
}

.add-media-sec .title .multimediaTitles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3125rem;

  .suggestion {
    text-align: left;
    font: italic normal normal 12px/18px Open Sans;
    letter-spacing: 0px;
    color: #AAAAAA;
    opacity: 1;
    align-self: center;
  }
}

.add-media-sec .hr-divider hr {
  margin-top: .7rem;
  margin-bottom: .7rem;
}

.picture-video-sec {
  display: inline-flex;
  overflow: auto;
  width: 50.63rem;
  margin-top: -0.375rem;
}

.picture-video-sec .note {
  width: 3.75rem;
  min-width: 3.75rem;
  height: 3.75rem;
  background-color: #00000059;
  margin: 0.5rem 0.625rem 0.5rem 0;
  background-size: cover;
  display: inline-flex;
  background-size: cover;
  background-position: center;

}

.picture-video-sec .blackLayer {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.picture-video-sec .img-div {
  padding: .8rem 1.313rem;
  background-color: #0000002E;
}

.picture-video-sec .img-divDoc {
  background-color: #fffefef7;

  .csvIcon {
    width: 60px !important;
    height: 60px !important;
  }
}

.picture-video-sec .img-div-only {
  padding: .8rem 1.913rem;
  background-color: #0000002E;
  cursor: pointer;
}

.picture-video-sec .img-div-2 {
  padding: .8rem 1.313rem;
  background-color: #ffffff7a;
}

.picture-video-sec .deleteBtn {
  margin-left: -0.5rem;
  margin-top: -0.375rem;
  background: red;
  border-radius: 1.25rem;
  cursor: pointer;
}

.picture-video-sec .deleteImgBtn {
  margin-left: -0.5rem;
  margin-top: -0.375rem;
  background: red;
  border-radius: 1.25rem;
  cursor: pointer;
}

.profile-card .timeline-header {
  font: normal normal 600 0.875rem/1.188rem Open Sans;
  color: #777777;
}

.profile-card .timeline-header .btn {
  background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
  border-radius: 0.875rem;
  font: normal normal normal 0.6875rem/0.9375rem Open Sans;
  letter-spacing: 0;
  color: #FFFFFF;
  width: 4.25rem;
  padding: 0.3125rem;
}

.audit-sec {
  display: inline-flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 50.63rem;
}

.audit-sec audio {
  border-radius: 0.375rem;
  width: 15.63rem;
  max-width: 15.63rem;
  margin: 1.375rem 0 0 0;
  min-width: 15.63rem;
  display: inline-flex;
  height: 1.125rem;
}

.audio-filename {
  margin-bottom: 0 !important;
  height: 0.375rem !important;
  margin-left: 2.25rem;;
}

.audit-sec .deleteBtn {
  margin-left: -1.638rem;
  background: red;
  border-radius: 1.25rem;
  cursor: pointer;
  margin-right: 0.375rem;
  margin-top: .1rem;
}

.audit-sec .audio-sub-div {
  background: #f1f3f4;
  height: 3.438rem;
  margin: 0.5rem 0.9375rem 0.5rem 0rem;

  .player {
    outline: none;
  }
}

.audit-sec .audio-div {
  display: inline-flex;
}

.audio-sec {
  display: flex;
}

.add-media {
  float: left;
  margin-top: 1px;
}

.btn-add-media {
  height: 3.938rem;
  padding: 0 !important;
  border: 0 !important;
  background: transparent !important;
  margin-right: 0.75rem !important;
}

.btn-add-media input[type=file] {
  opacity: 0;
  width: 53px;
  position: absolute;
  height: 53px;
  cursor: pointer;
}

.addEditNoteTimeLine .vertical-timeline::before {
  background: #CCCCCC !important;
  top: 0.9375rem;
  left: 1.875rem;
  height: 94%;
}

.vertical-timeline-element--work .vertical-timeline-element-content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.addEditNoteTimeLine .vertical-timeline .vertical-timeline-element--work .vertical-timeline-element-icon {
  width: 0.75rem !important;
  height: 0.75rem !important;
  top: 23px !important;
  left: 24.5px !important;
}


.addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element-icon {
  left: 0;
  background: #CCCCCC !important;
}

.addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element {
  margin-top: 0rem;
  margin-bottom: 0rem;
}



.addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element-content {
  width: auto;
  margin-left: 55px;
  box-shadow: none;
  background: transparent;
  min-height: 0.0625rem;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element-content .vertical-timeline-element-date {
  left: -3.125rem;
  top: -0.55rem;
  width: unset;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.addEditNoteTimeLine .vertical-timeline {
  padding: 1em 0;
  margin: 0px;
  margin-right: 0px;
  margin-left: 25px;
  width: 90% !important;
}


.addEditNoteTimeLine .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  background: #CCCCCC;
}


@media screen and (max-width: 1439px) and (max-width: 1650px) {
  .addEditNoteTimeLine .vertical-timeline {
    padding: 1em 0;
    margin: 0px;
    margin-right: 0px;
    margin-left: 6px;
    width: 98% !important;
  }
}

.addEditNoteTimeLine .attached-tag {
  margin: 0 0 0 0.5rem;
}

.addEditNoteTimeLine .attached-tag {
  background-color: #DDDDDD;
}

.text-right i {
  vertical-align: middle;
}

.addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
  right: unset;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.add-media-modal .modal-title {
  font: normal normal 600 0.875rem/1.0625rem Open Sans !important;
  display: inline-flex;
}

.add-media-modal .modal-title input {
  font: normal normal 600 0.875rem/1.0625rem Open Sans !important;
  padding: 0;
  height: 26px;
  border: none;
  box-shadow: none;
}

.add-media-modal .btn {
  padding: 0 5px 0 0;
}

.add-media-modal .btn:focus {
  box-shadow: none !important;
}

.add-media-modal .fa-pencil {
  color: #AAAAAA;
}

.add-media-modal {
  margin-top: 5rem;
}

.add-media-modal .modal-title {
  font: normal normal 600 1rem/1.375rem Open Sans !important;
  margin-bottom: 0.625rem;
}

.add-media-modal .modal-header {
  padding: .8rem 1rem 0;
  border-radius: 0.5rem;
}

.add-media-modal .modal-body {
  margin-top: 0.375rem;
  justify-content: center;
  min-height: 9.6875rem;
}

.add-media-modal .timer_Div {
  width: 20px;
  position: absolute;
  right: 35%;
  top: 10px;
}

.add-media-modal .modal-footer {
  justify-content: center;
}

.add-media-modal .modal-footer button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0;
}

.add-media-modal .modal-footer .btn-danger {
  background: transparent linear-gradient(270deg, #EA3737 0%, #F05F5F 100%) 0% 0% no-repeat padding-box;
}

.add-media-modal .modal-footer .btn-success {
  background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
}

.add-media-modal .modal-footer button img {
  margin-top: -0.1875rem;
  margin-left: 0.0625rem;
}

.add-media-modal .modal-footer .deleteIcon img {
  margin-top: -0.0625rem;
  margin-left: -0.0625rem;
}

.add-media-modal .modal-content {
  border-radius: 0.5rem;
  background-color: #fff !important;
}

.voice-recorder {
  width: 100%;
  height: 3.875rem;
}

.record-btn {
  position: relative;
  cursor: pointer;
}

.record-btn .btn {
  background: transparent;
  border: none;
}

.record-btn .playAfterPause {
  width: 42px;
  height: 42px;
  border: 1px solid black !important;
  border-radius: 50px;

  .playIconPause {
    margin-left: 2px;
  }
}

.record-info {
  font: normal normal normal 0.625rem/0.875rem Open Sans;
  color: #888888;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.record-btn .btn-primary:not(:disabled):not(.disabled).active,
.record-btn .btn-primary:not(:disabled):not(.disabled):active,
.record-btn .btn-primary:hover,
.record-btn .show>.btn-primary.dropdown-toggle,
.record-btn .btn-primary.focus,
.record-btn .btn-primary:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.display-none {
  display: none !important;
}

.timer {

  top: 2.0625rem;
  font: normal normal 600 0.875rem/1.188rem Open Sans;
  letter-spacing: 0rem;
  color: #222222;
}

.level-score-sec {
  border-left: 0.0625em solid #cccccc;
  margin-bottom: 0;
  text-align: right;
}

.level-score-sec .sub-div {
  display: inline-flex;
  line-height: 2.5em;
}

.level-score-sec .sub-div input {
  width: 100%;
  margin-left: 0.625em;
  background: #F4F6FA 0% 0% no-repeat padding-box;
  border: 0.0469em solid #CCCCCC;
  font: normal normal normal 0.875em/1.1875em Open Sans;
  color: #000 !important;
}

.multimedia-suggestion {
  font: italic normal normal 13px/18px Open Sans !important;
  margin: auto 0 !important;
  color: #AAAAAA !important;
}

.audio-suggestion {
  float: right;
  font: italic normal normal 13px/18px Open Sans;
  color: #AAAAAA;
}

.action-div {
  padding-top: 4px;
  padding-right: 0px !important;
}

.NoteDropdownContainer {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  flex: 0.6;

  .iconDiv {
    position: absolute;
    left: 9px;
    z-index: 2;
    top: 8px;

    .searchFiltersIcon {
      cursor: pointer;
      color: #AAAAAA;
      margin-right: 10px;
    }
  }

  .select-search {
    width: 240px !important;
    position: relative;
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
    bottom: 5px !important;
    cursor: pointer;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

.select-search__value {
    position: relative;
}

.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
    cursor: pointer;
}

.select-search__input {
    margin-top: 5px;
    display: block;
    height: 40px;
    width: 240px;
    padding: 0 40px 0 30px;
    background: #fff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 3px #88c3fae3;
    border-radius: 8px;
    outline: none !important;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    color: rgba(56, 108, 181, 1);
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    opacity: 1;
}

.select-search__input::placeholder {
    font: italic normal normal 13px/18px Open Sans;
    letter-spacing: 0px;
    color: #c6b6b6 !important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

.select-search__select {
    max-height: 150px !important;
    box-shadow: 10px 10px 12px #ec090929;
    border: 1px solid #dddddd;
}

.select-search__options {
    list-style: none;
    padding: 0px 5px;
    background: #fff;
    cursor: pointer;
}

.select-search__row {
    border-bottom: 1px solid #dddddd;
    border-width: 0.15px;
}

.select-search__row:last-child {
    border-bottom: none;
}

.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    color: #fff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    color: #fff;
}

.select-search__group-header {
    text-transform: uppercase;
    background: #fff;
}

.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
    letter-spacing: 0px;
    font-weight: 600;
}

.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 3px solid #000;
    border-bottom: 3px solid #000;
    pointer-events: none;
}

.select-search.has-focus .select-search__value::after {
    display: none;
}

.select-search.has-focus .select-search__input:hover {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.select-search.has-focus .select-search__input {
    border-bottom: none;
    box-shadow: 0px 0px 3px #00000029;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-color: none;
    background: #f4f6fa 0% 0% no-repeat padding-box;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 40px !important;
    right: 0;
    left: 0;
    border-radius: 8px;
    overflow: auto;
    max-height: 360px;
    background: #fff;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    box-shadow: none;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: auto;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
    background: #fff;
}

.select-search__not-found {
    height: auto;
    padding: 8px;
    text-align: center;
    color: rgb(244, 245, 247);
}

}



@media only screen and (max-width: 81.25rem) {
  .addNoteMainContainer .card-body {
    padding: .8rem;
  }

  .CircularProgressbar {
    margin-top: -0.3125rem;
  }

  .action-div {
    margin: auto;
  }

  .add-media-sec {
    min-height: 17.625rem;
  }
}

@media only screen and (max-width: 80rem) {
  .add-assignment-sec .custom-switch .custom-control-label::before {
    width: 3.125rem;
    height: 1.5625rem;
    border-radius: 1.25rem;
    margin-left: -0.875rem;
    margin-top: -0.1875rem;
  }

  .add-assignment-sec .custom-switch .custom-control-label::after {
    width: 1.3125rem;
    height: 1.3125rem;
    margin-left: 0.125rem;
    margin-top: -0.1875rem;
  }

  .add-assignment-sec .custom-control-input:checked~.custom-control-label::before {
    width: 3.125rem;
    height: 1.5625rem;
    border-radius: 1.25rem;
    margin-left: -0.875rem;
    margin-top: -0.1875rem;
  }

  .add-assignment-sec .custom-switch .custom-control-input:checked~.custom-control-label::after {
    width: 1.3125rem;
    height: 1.3125rem;
    border-radius: 1.25rem;
    margin-left: -0.0625rem;
    margin-top: -0.1875rem;
  }
}

@media only screen and (max-width: 1220px) {
  .noteOptionIcons {
    width: 1.375rem;
    height: 1.375rem;
    margin-right: 0.3125rem;
  }

  .addNoteMainContainer {
    padding: 0rem 1rem;
  }

  .note-header-select-add-std .form-group {
    width: unset;
  }

  .NoteFilterSection .note-header-select-add-std {
    margin: auto;
    padding-top: 0px;
  }

  .add-media-sec .hr-divider hr {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}

@media only screen and (max-width: 991px) {
  .action-div img {
    width: 4.6875rem !important;
  }

  .audit-sec,
  .picture-video-sec {
    width: 33.3125rem;
  }

  .addNoteMainContainer .note-header-right-section {
    margin-top: 0.5rem;
  }

  .pr-7-5 {
    padding-right: 0.9375rem !important;
  }

  .pl-7-5 {
    padding-left: 0.9375rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .CircularProgressbar {
    width: 2.875rem;
  }

  .audit-sec,
  .picture-video-sec {
    width: 37.5rem;
  }

  .note-header {
    height: 4.063rem;
  }

  .left-section {
    padding: 0.9375rem !important;
    padding-bottom: 0 !important;
  }

  .right-section {
    padding: 0.9375rem !important;
  }
}

@media only screen and (max-width: 725px) {

  .audit-sec,
  .picture-video-sec {
    width: 31.25rem;
  }
}

@media only screen and (max-width: 631px) {

  .audit-sec,
  .picture-video-sec {
    width: 28.125rem;
  }

  .level-score-sec .sub-div input {
    margin-left: 5px;
    padding: 0 4px;
  }
}

@media only screen and (max-width: 581px) {

  .audit-sec,
  .picture-video-sec {
    width: 25rem;
  }
}

@media only screen and (max-width: 552px) {
  .audit-sec {
    width: 23.125rem;
  }

  .picture-video-sec {
    width: 23.125rem;
  }
}

@media only screen and (max-width: 552px) {

  .audit-sec,
  .picture-video-sec {
    width: 18.75rem;
  }

  .picture-video-sec {
    width: 18.75rem;
  }
}

@media only screen and (max-width: 520px) {
  .left-section {
    padding: 0 !important;
  }

  .right-section {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 450px) {
  .action-div img {
    width: 4.0625rem !important;
  }
}

.blankPowerPointaDic {
  width: 60px;
  height: 60px;
  background: var(--unnamed-color-eeeeee) 0% 0% no-repeat padding-box;
  background: #ffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border: 6px solid #d6d6d6;
  border-radius: 50%;
  margin-top: -6px;
}

.powerText {
  margin: .5rem 0rem 0rem 0rem;
  font-size: .7rem;
  color: #d6d6d6;
  font-weight: 600;
}

.powerPointText {
  margin: 0rem 0rem 0rem 0rem;
  font-size: .7rem;
  color: #d6d6d6;
  font-weight: 600;
}

.powerCenter {
  text-align: -webkit-center;
}

.show-video-modal .modal-dialog {
  max-width: 750px;
  justify-content: center !important;
  display: flex;
}

.show-video-modal .modal-body {
  padding: 0px !important;
}

// .show-video-modal .modal-header {
//   justify-content: flex-end;
//   border: none !important;
// }

.show-video-modal .modal-header {
  border: none !important;
  .mediaHeaderBtns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .delete-btns {
      background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
      width: 2.5rem !important;
      height: 2.5rem;
      border-radius: 1.25rem;
      padding: 0;
      border: none !important;
      min-width: 2.5rem!important;
    }
    .mediaArti {
      font-size: 15px;
      font-weight: 800;
      align-self: center;
      color: white;

      .starSelected {
        cursor: pointer;
        color: #ffa700;
        font-size: 21px;
        display: inline-flex;
        padding-left: 8px;
      }

      .starUnSelected {
        cursor: pointer;
        font-size: 23px;
        display: inline-flex;
        padding-left: 8px;
      }
    }

    .docArti {
      font-size: 15px;
      font-weight: 800;
      align-self: center;
      color: white;

      .starSelected {
        cursor: pointer;
        color: #ffa700;
        font-size: 21px;
        display: inline-flex;
        padding-left: 8px;
      }

      .starUnSelected {
        cursor: pointer;
        font-size: 21px;
        display: inline-flex;
        padding-left: 8px;
      }
    }

    .close {
      padding: 0.5rem 1rem !important;
      margin: -1rem -1rem -1rem auto;
      opacity: 1 !important;
      color: #000 !important;
      outline: none !important;

      .closeIconInModal {
        width: 0.8rem;
        margin-top: 0.3rem;
      }
    }
  }
}
.greyReactPicker {
  cursor: pointer;
}
.mediaArti {
  font-size: 15px;
  font-weight: 800;
  align-self: center;
  color: white;

  .starSelected {
    cursor: pointer;
    color: #ffa700;
    font-size: 21px;
    display: inline-flex;
    padding-left: 8px;
  }

  .starUnSelected {
    cursor: pointer;
    font-size: 23px;
    display: inline-flex;
    padding-left: 8px;
  }
}
.show-video-modal .modal-content {
  border-radius: 0.5rem;
  background-color: #fff0 !important;
  border: none !important;
  width: auto;
  // height: 550px !important;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // margin-left: -375px;
  // margin-top: 45px;
}

.badgeDoNotet {
  position: absolute;
  top: -2px;
  padding: 5px 5px 3px 5px;
  border-radius: 88%;
  background-color: #ffc107 !important;
  color: white !important;
  height: 11px;
  width: 12px;
  border: 1px solid white;
  left: -1px;
}
.show-video-modal .modal-header .delete-btn {
  background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0;
  border: none !important;
}
.delete-btn {
  background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0;
  border: none !important;
}
.doc-modal-warning .modal-dialog {
  min-width: 750px;
  justify-content: center !important;
  display: flex;
}

.doc-modal-warning .modal-title {
  text-align: center;
  font: normal normal 600 18px/24px Open Sans;
}

.doc-modal-warning .modal-content {
  width: 750px;
  margin: auto;
  background-color: #fff0 !important;
  border: none !important
}

.doc-modal-warning .modal-content .modal-header {
  justify-content: flex-end;
  border: none;
  border-radius: 8px;
}

.doc-modal-warning .modal-content .modal-body {
  text-align: center;
  font: normal normal 600 14px/19px Open Sans;
}

.doc-modal-warning .modal-content .modal-body .confirm-text {
  font: normal normal normal 14px/19px Open Sans;
  margin-bottom: 0;
}

.doc-modal-warning .modal-footer {
  border: none;
  justify-content: center;
  padding-top: 0;
}

.doc-modal-warning .cancel-btn {
  width: 101px;
  height: 38px;
  // background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
  font: normal normal 600 14px/19px Open Sans;
}

.doc-modal-warning .delete-btn {
  // background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0;
}

.addNoteMainContainer .headerBar {
  width: 100%;
  height: 80px;
  background: #ffff;
  justify-content: space-between;
  display: flex;
  margin-bottom: 20px;
  padding: 1rem 0.5rem 1rem 0rem;
  border-radius: 0px 0px 8px 8px;
}

.NoteFilterSection {
  min-height: 3.75rem;
}

.NoteFilterSection .card-body {
  padding: 0.625rem 1.25rem;
}

.NoteDropdownContainer .select-search {
  bottom: 0rem !important;
}

@media only screen and (max-width: 1280px) {
  .historyIconPink {
    width: 16px;
    height: 18px;
    margin-left: -125px;
    margin-top: 7px;
  }
}

.level-sub-div {
  padding: 0px 6px !important;
  display: flex;
  direction: row;
  justify-content: left;
}

.score-sub-div {
  padding: 0px 6px !important;
  display: flex;
  direction: row;
  justify-content: left;
}

.level-sec {
  padding: 6px 0px !important;
  margin: 0px !important;
  font-weight: 600;
}

.score-sec {
  padding: 6px 0px !important;
  margin: 0px !important;
  font-weight: 600;
}

.level-row {
  padding: 0px 12px;
}

.level-sub-div .form-control {
  width: 70% !important;
  margin-left: 0.625rem;
  background: var(--unnamed-color-f4f6fa) 0% 0% no-repeat padding-box;
  border: 0.75px solid var(--unnamed-color-cccccc);
  background: #F4F6FA 0% 0% no-repeat padding-box;
  border: 0.75px solid #CCCCCC;
  border-radius: 8px;
  opacity: 1;
}

.score-sub-div .form-control {
  width: 70% !important;
  margin-left: 0.625rem;
  background: var(--unnamed-color-f4f6fa) 0% 0% no-repeat padding-box;
  border: 0.75px solid var(--unnamed-color-cccccc);
  background: #F4F6FA 0% 0% no-repeat padding-box;
  border: 0.75px solid #CCCCCC;
  border-radius: 8px;
  opacity: 1;
}

.level-title {
  padding: 0px !important;
}

.score-title {
  padding: 0px !important;
}

.contactTextAreaTopDist {
  margin-top: 15px;
}

@media screen and (min-width: 1280px) and (max-width: 1370px) {
  .NoteDropdownContainer {
    margin-left: -45px;
  }
}

@media only screen and (min-width: 1745.45px) {
  .NoteDropdownContainer {
    margin-left: 25px;
  }
}

@media only screen and (min-width: 1920px) {
  .NoteDropdownContainer {
    margin-left: 45px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1370px) {
  .vertical-timeline-element-content {
    position: relative;
    margin-left: 45px;
    background: #fff;
    border-radius: 0.25em;
    padding: 1em;
    -webkit-box-shadow: 0 3px 0 #ddd;
    box-shadow: 0 3px 0 #ddd;
  }
}

@media screen and (min-width: 1370px) and (max-width: 1470px) {
  .vertical-timeline-element-content {
    position: relative;
    margin-left: 50px;
    background: #fff;
    border-radius: 0.25em;
    padding: 1em;
    -webkit-box-shadow: 0 3px 0 #ddd;
    box-shadow: 0 3px 0 #ddd;
  }
}