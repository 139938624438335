.CircularProgressbar {
  width: 60px;
  vertical-align: middle;
  margin-top: -12px;
}
.CircularProgressbar .CircularProgressbar-text {
  font-weight: bold;
}


.upload_progress{
  width: 50px;
  vertical-align: middle;
  margin-top: 0px !important;
}