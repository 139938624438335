.subscriptionsContainer{
    justify-content: center;
    padding: 20px 150px 00px 150px;
    .cardOfSubscriptions{
        width: 100%;
        min-height: 500px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A;
       
    }
    .subscriptionsHeading{
        text-align: center;
        font: normal normal normal 35px/40px Roboto;
        letter-spacing: 0.8px;
        color: #222222;
        opacity: 1;
        padding:20px 0px 10px 0px;
    }
    .blueBorder{
        height: 2px;
    background: #446cb0;
    }
    .descriptionText{
        font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0em;
    line-height: 26px;
    margin: 0 20px 25px;
    text-align: justify;

    }
    .cardOfSubscriptionsPlan{
        width: 100%;
        min-height: 500px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: none;
        padding: 20px 60px 20px 60px;
    }
    .cardOfPlan{
        width: 100%;
        min-height: 300px;
        background: none;
        box-shadow: none;
        margin-top: 20px;
        border:none;
        width: 100%;
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
    }
    .planDiv{
        width: 100%;
        min-height: 535px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A;
        margin-top: 20px;
        padding: 10px;
    }
    .planIconDiv{
        width: 100px;
        // height: 100px;
        transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
        background: transparent linear-gradient(45deg, #669EEC 0%, #3E73BC 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000029;
        border: 3px solid #FEFEFE;
        opacity: 1;
    }
    .planDetails{
        display: inline-flex;
        float: left
        
    }
    .planType{
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #222222;
        text-transform: uppercase;
        margin-bottom: 10px;
        opacity: 1;
        margin-top: 10px;
    }
    .planPrice{
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #386CB5;
    }
    .planDetailsPoint{
        text-align: left;
        font: normal normal normal 14px/15px Open Sans;
        color: #222222;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0em;
        line-height: 20px;
        margin: 0 20px 25px;
    }
    .upgradePlanButton{
        width: 120px;
        height: 35px;
        background: transparent linear-gradient(90deg, #669EEC 0%, #3E73BC 100%) 0% 0% no-repeat padding-box;
        border-radius: 20px;
        margin-bottom: 20px;
        
        
    }
    @media only screen and (min-width: 850px) and (max-width: 1250px) {
        padding: 20px 80px 00px 80px;
    
           
    }

    @media only screen and (min-width: 251px) and (max-width: 850px) {
        padding: 20px 40px 00px 40px;
    
           
    }
}