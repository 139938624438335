.addNewLessonContainer {
    background: #f0f0f0;
    height: 100%;
    overflow-x: hidden;
    padding: 0rem 2.5rem 1rem 2.5rem;
    height: 49rem;

    .addLessonPlanMainDiv {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        box-shadow: 0px 3px 4px #2222221a !important;

        .LessonPlanDetailsMain {
            display: flex;
            align-items: center;
            flex: 2;

            .ClassBackDiv {
                display: flex;
                padding: 0.8rem;
                background: #eeeeee;
                width: 13rem;
                height: 3.1rem;
                cursor: pointer;
            }

            .lessonBackIcon {
                font-size: 1.3rem;
                padding-top: 3px;
            }

            .lessonClassBactText {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }

            .attClassDetailTextDiv {
                margin-left: 1rem;
                flex: 1.6;
                border-left: 1px solid #eeeeee;
                padding: 0.3rem 0rem 0rem 1rem;

                .firstDetailsDiv {
                    display: grid;
                }

                .attTitleName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    display: flex;
                    padding-right: 5px;
                    font: normal normal 600 14px/14px Open Sans;
                }

                .head {
                    color: #386cb5;
                    text-transform: capitalize;
                    text-align: left;
                    font: normal normal 600 14px/14px Open Sans;
                    margin: 0px;
                }

                // }
                .displayFlexClassRoster {
                    display: flex;
                    cursor: pointer;

                    .calIcon {
                        align-self: center;
                        cursor: default;
                        cursor: not-allowed;
                    }

                    .attClassGrade {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 8px 0px 0px 0px;
                        cursor: default;
                    }
                }
            }
        }

        .displayFlexInSeletAtt {
            padding-right: 12px;
            display: flex;
            align-items: center;

            .undoSelect {
                width: 2.5rem;
                height: 2.5rem;
                background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
                border-radius: 10rem;
                opacity: 1;
                margin-right: 1rem;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
height: 40px;
width: 100px;
height: 40px;
// font-weight: 600;
color: white;
border-radius: 8px;
            }

            .confirmSlectDiv {
                width: 2.5rem;
                height: 2.5rem;
                background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
                border-radius: 10rem;
                cursor: pointer;

                .confirmIconsLesson {
                    color: white;
                    // padding-top: 6px;
                    // padding-left: 5px;
                    padding-right: 6px;
                }
            }

            .opacityFade {
                opacity: 0.4;
                pointer-events: none;
                cursor: default !important;
            }
        }

        .buttonMarginTop {
            display: flex;
            align-items: center;

            .addNewLesson {
                width: 11rem !important;
                height: 2.5rem !important;
                // border-radius: 2rem !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;
            }

            .addNewLessonFade {
                width: 11rem !important;
                height: 2.5rem !important;
                border-radius: 8px !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #a7e9a5 0%, #a7e9a5 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;
            }
            .addNewLessonFadeAlignItems {
                align-items: first baseline;
                pointer-events: none;

            }

            .lessonIcons {
                width: 1.1rem;
                margin-top: 0.4rem;
            }

            .viewAttText {
                color: white;
                font-size: 0.9rem;
                margin-left: 0.5rem;
            }
        }

        .lessonDetailTextDiv {
            margin-left: 1rem;
            border-left: 1px solid #eeeeee;
            padding: 0.3rem 0rem 0rem 1rem;

            .lessonClassName {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }

            .displayFlexClassLesson {
                display: flex;
                cursor: pointer;

                .calIcon {
                    align-self: center;
                    cursor: default;
                    cursor: not-allowed;
                }

                .lessonClassGrade {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 5px 0px 0px 5px;
                    cursor: default;
                }

                .headingForEdited {
                    display: inline-flex;
                    flex-wrap: wrap;
                    cursor: default;
                    align-items: center;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                    }

                    .head {
                        color: #386cb5;
                        text-transform: capitalize;
                        font: normal normal 600 14px/14px Open Sans;
                        margin: 0px;
                    }
                }

                .attClassGrades {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 1px 0px 0px 5px;
                    cursor: default;
                    font-weight: 600;
                }
            }
        }
    }

    .tabsDiv {
        padding-top: 30px;
        width: 100% !important;

        .mb-3 {
            border: none;

            .nav-item {
                width: 386px;

                .a {
                    color: red;
                }
            }

            .nav-link {
                color: #AAAAAA;
                background-color: #fff;
                flex: 0.33;
            }

            .nav-link.active {}

            .nav-link.active {
                color: #386CB5 !important;
                background-color: #fff;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 3px solid #386CB5;
            }
        }
    }

}

@media only screen and (min-width: 357px) and (max-width: 820px) {

    .addNewLessonContainer {
        .addLessonPlanMainDiv {
            flex-wrap: wrap;
        }
    }
}


.BR-8 {
    border-radius: 8px!important;
}
.goBackBtnLP {
    line-height: 1.5!important;
    border-radius: 0.25rem!important;
    font: inherit!important;
}
.cancelBtnLP {
    font: inherit!important;
}