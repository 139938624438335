.classGradeBookMainContainer {
    background: #f0f0f0;
    height: 100%;
    padding: 0rem 3rem 0rem 3rem;
    .gradeContainersDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 100px;
        padding-left: 25px;

        .gradeContainerTextNoStd {
            color: #aaaaaa;
            font: normal normal 600 20px/14px Open Sans;
            line-height: inherit;
            padding-top: 40px;
        }
    }
    .left-section {
        padding-right: 0.4375rem !important;
    }
    a:focus,
    .btn:focus {
        outline: none !important;
        box-shadow: none !important;
    }
    .back-btn {
        font: normal normal 600 1rem/1.375rem Open Sans;
        background-color: #eeeeee !important;
        border-radius: 0.25rem;
        width: 100%;
        text-align: left !important;
        color: #222222 !important;
        border: none !important;
        font-weight: 600 !important;
        height: 3.125rem;

        .fa-chevron-left {
            margin-right: 0.625rem;
        }
    }
    .assessment-btn {
        font: normal normal 600 13px/16px Open Sans !important;
        background: transparent linear-gradient(270deg, #ffb239 0%, #ffc76e 100%) 0% 0% no-repeat padding-box;
        border-radius: 0.5rem;
        border: none !important;
        width: 100%;
        // width : auto;
        text-align: center;
        color: #ffffff;
        min-height: 48px;

        .actionIcons {
            width: 1.2rem;
            height: 1.2rem;
            float: left;
            margin-right: 0.55rem;
            margin-top: 5px;
            margin-left: 18px;
        }

        @media only screen and (max-width: 1730px) {
            .actionIcons {
                margin-left: 8px;
            }
        }

        @media only screen and (max-width: 1565px) {
            .actionIcons {
                margin-left: 2px;
                margin-right: 4px;
            }
        }
    }

    @media only screen and (max-width: 1450px) {
        .assessment-btn {
            font-size: 12px !important;
        }
    }

    .note-btn {
        font: normal normal 600 13px/18px Open Sans !important;
        background: transparent linear-gradient(270deg, #747beb 0%, #989eff 100%) 0% 0% no-repeat padding-box;
        border-radius: 0.5rem;
        border: none !important;
        width: 100%;
        text-align: center;
        color: #ffffff;
        min-height: 48px;

        .actionIcons {
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 0.55rem;
        }
    }

    .right-section {
        padding-left: 0.4375rem !important;

        .header {
            height: 4.7rem;
            display: flex;
            .headerChild {
                display: flex;
                justify-content: space-between;
                padding: 17px !important;
                .titleMainsDivFlex {
                }
                .titleMainsDiv {
                    display: flex;
                    flex: 30%;
                    .gradebooksBackDiv {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .homeIconDiv {
                            cursor: pointer !important;
                        }
                        .vrLine {
                            width: 0px;
                            height: 100%;
                            margin: 17px;
                            border: 1px solid #2222221a;
                        }
                        .displayFlexGradebooks {
                            display: flex;
                            cursor: pointer;
                            align-items: center;
                            .gradebookGrades {
                                color: #487fcc;
                                font-size: 1rem;
                                text-align: left;
                                cursor: pointer;
                                margin: 0px !important;
                                font-weight: 600;
                            }
                            .gradebooksForIcon1 {
                                color: #487fcc;
                                padding-top: 3.19px;
                            }
                            .gradebooksForIcon2 {
                                color: #487fcc;
                                padding-top: 3.26px;
                            }
                            .gradebooksBreadCrumbTitle {
                                color: #222222;
                                font-size: 1.25rem;
                                font-weight: 600;
                                text-align: left;
                                cursor: default;
                                margin: 0px !important;
                                margin-left: 2px;
                            }
                            .gradebooksGradeTitle {
                                color: #222222;
                                font-size: 1.25rem;
                                font-weight: 600;
                                text-align: left;
                                cursor: default;
                                margin: 0px !important;
                            }
                            .enableBreadCrumb {
                                color: #487fcc !important;
                                font-size: 1rem !important;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .dropdownsGradebook-main-container {
                    font-family: sans-serif;
                    text-align: center;
                    position: relative;
                    flex: 17%;
                    top: 0px !important;
                    left: 0px !important;
                    .iconDiv {
                        position: absolute;
                        left: 9px;
                        z-index: 2 !important;
                        top: 7px;
                        cursor: pointer;
                        .searchFiltersIcon {
                            cursor: pointer;
                            color: #aaaaaa;
                            margin-right: 10px;
                        }
                    }
                    .select-search {
                        width: 240px !important;
                        position: relative;
                        font-family: "Nunito Sans", sans-serif;
                        box-sizing: border-box;
                        bottom: 5px !important;
                        cursor: pointer;
                    }
                    .select-search *,
                    .select-search *::after,
                    .select-search *::before {
                        box-sizing: inherit;
                    }
                    .select-search__value {
                        position: relative;
                    }
                    .select-search__value::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        top: calc(50% - 9px);
                        right: 19px;
                        width: 11px;
                        height: 11px;
                        cursor: pointer;
                    }
                    .select-search__input {
                        margin-top: 5px;
                        display: block;
                        height: 40px;
                        width: 240px;
                        padding: 0 40px 0 30px;
                        background: #fff;
                        border: 1px solid #dddddd;
                        box-shadow: 0px 0px 3px #88c3fae3;
                        border-radius: 8px;
                        outline: none !important;
                        font-family: "Noto Sans", sans-serif;
                        font-size: 14px;
                        color: rgba(56, 108, 181, 1);
                        text-align: left;
                        text-overflow: ellipsis;
                        line-height: 36px;
                        -webkit-appearance: none;
                        opacity: 1;
                    }
                    .select-search__input::placeholder {
                        font: italic normal normal 13px/18px Open Sans;
                        letter-spacing: 0px;
                        color: #222222 !important;
                    }
                    .select-search__input::-webkit-search-decoration,
                    .select-search__input::-webkit-search-cancel-button,
                    .select-search__input::-webkit-search-results-button,
                    .select-search__input::-webkit-search-results-decoration {
                        -webkit-appearance: none;
                    }
                    .select-search__input:not([readonly]):focus {
                        cursor: initial;
                    }
                    .select-search__select {
                        max-height: 150px !important;
                        box-shadow: 10px 10px 12px #ec090929;
                        border: 1px solid #dddddd;
                    }
                    .select-search__options {
                        list-style: none;
                        padding: 0px 5px;
                        background: #fff;
                        cursor: pointer;
                    }
                    .select-search__row {
                        border-bottom: 1px solid #dddddd;
                        border-width: 0.15px;
                    }
                    .select-search__row:last-child {
                        border-bottom: none;
                    }
                    .select-search__option,
                    .select-search__not-found {
                        display: block;
                        height: 36px;
                        width: 100%;
                        background: #fff;
                        border: none;
                        outline: none;
                        font-family: "Noto Sans", sans-serif;
                        font-size: 14px;
                        text-align: left;
                        cursor: pointer;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .select-search--multiple .select-search__option {
                        height: 98px;
                    }
                    .select-search__option.is-selected {
                        color: #fff;
                    }
                    .select-search__option.is-highlighted.is-selected,
                    .select-search__option.is-selected:hover {
                        color: #fff;
                    }
                    .select-search__group-header {
                        text-transform: uppercase;
                        background: #fff;
                    }
                    .select-search.is-disabled {
                        opacity: 0.5;
                    }
                    .select-search.is-loading .select-search__value::after {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
                        background-size: 11px;
                    }

                    .select-search:not(.is-disabled) .select-search__input {
                        cursor: pointer;
                        letter-spacing: 0px;
                        font-weight: 600;
                    }
                    .select-search--multiple {
                        border-radius: 3px;
                        overflow: hidden;
                    }
                    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
                        transform: rotate(45deg);
                        border-right: 3px solid #000;
                        border-bottom: 3px solid #000;
                        pointer-events: none;
                    }
                    .select-search.has-focus .select-search__value::after {
                        display: none;
                    }
                    .select-search.has-focus .select-search__input:hover {
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }
                    .select-search.has-focus .select-search__input {
                        border-bottom: none;
                        box-shadow: 0px 0px 3px #00000029;
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-color: none;
                        background: #f4f6fa 0% 0% no-repeat padding-box;
                    }
                    .select-search--multiple .select-search__input {
                        cursor: initial;
                    }
                    .select-search--multiple .select-search__input {
                        border-radius: 3px 3px 0 0;
                    }
                    .select-search--multiple:not(.select-search--search) .select-search__input {
                        cursor: default;
                    }
                    .select-search:not(.select-search--multiple) .select-search__select {
                        position: absolute;
                        z-index: 2;
                        top: 40px !important;
                        right: 0;
                        left: 0;
                        border-radius: 8px;
                        overflow: auto;
                        max-height: 360px;
                        background: #fff;
                        border-top-right-radius: 0px;
                        border-top-left-radius: 0px;
                        box-shadow: none;
                    }
                    .select-search--multiple .select-search__select {
                        position: relative;
                        overflow: auto;
                        max-height: auto;
                        border-top: 1px solid #eee;
                        border-radius: 0 0 3px 3px;
                        background: #fff;
                    }
                    .select-search__not-found {
                        height: auto;
                        padding: 8px;
                        text-align: center;
                        color: rgb(244, 245, 247);
                    }
                }

                .NoShowDiv {
                    // flex: 15%;
                }
                .gradebook-side-buttons {
                    flex: 15%;
                    display: inline-flex;
                    justify-content: flex-end;
                }
            }
        }
        .header-title {
            text-align: left;
            font: normal normal 600 1rem/1.375rem Open Sans;
            color: #222222;
            margin: auto;

            .sub-title {
                font: normal normal normal 14px/19px Open Sans;
                margin-left: 25px;
                color: #777777;
            }
        }
        .addButton {
            width: 160px;
            height: 40px;
            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box !important;
            font: normal normal normal 16px/22px Open Sans !important;
            border-radius: 8px !important;
            float: right;
        }
        .disabledAddButton {
            width: 160px;
            height: 40px;
            background: transparent linear-gradient(270deg, #afcbf3 0%, #bfd2ec 100%) 0% 0% no-repeat padding-box !important;
            font: normal normal normal 16px/22px Open Sans !important;
            border-radius: 8px !important;
            float: right;
            cursor: default !important;
        }
        .cancelButton {
            background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
            border-radius: 5px;
            padding: 6px;
            width: 100px;
            height: 40px;
            margin-right: 6px;
            align-items: center;
            justify-content: center;
            display: flex;
            img {
                margin-top: -2px;
            }
            .iconTexts {
                padding: 1rem 0rem 0rem 0rem;
                color: white;
                font: normal normal normal 18px/24px Open Sans;
            }
        }
        .deleteCatButton {
            background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
            border-radius: 5px;
            padding: 6px;
            width: 100px;
            height: 40px;
            margin-right: 6px;
            align-items: center;
            justify-content: center;
            display: flex;
            img {
                margin-top: -2px;
            }
            .iconTexts {
                padding: 1rem 0rem 0rem 0.3rem;
                color: white;
                font: normal normal normal 18px/24px Open Sans;
            }
        }

        .flexDisply {
            display: flex;
        }
        .addNewButton {
            background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
            border-radius: 5px !important;
            padding: 6px !important;
            width: 100px !important;
            height: 40px !important;
            align-items: center;
            justify-content: center;
            display: flex;
            .iconTexts {
                padding: 1rem 0rem 0rem 0.3rem;
                color: white;
                font: normal normal normal 18px/24px Open Sans;
            }
        }
        .plus-img {
            width: 0.875rem;
            height: 0.875rem;
            margin-right: 0.5rem;
            margin-top: -0.1875rem;
        }
        .refresh-tags {
            .refresh-btn {
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                border-radius: 8px;
                padding: 0;
                width: 40px;
                height: 40px;
                margin-left: 6px;
            }
            .refresh-icon {
                width: 22px;
                height: 22px;
                margin-top: -4px;
            }
            .reorder-btn {
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                border-radius: 8px;
                padding: 0;
                width: 40px;
                height: 40px;
            }
            .disabledReorder-btn {
                background: transparent linear-gradient(270deg, #afcbf3 0%, #bfd2ec 100%) 0% 0% no-repeat padding-box;
                border-radius: 8px;
                padding: 0;
                width: 40px;
                height: 40px;
                cursor: default !important;
            }
            .reorder-icon {
                width: 22px;
                height: 22px;
                margin-top: -4px;
            }
            .closeWhite-btn {
                background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
                border-radius: 8px;
                padding: 0;
                width: 40px;
                height: 40px;
                .iconText {
                    padding: 1rem 0rem 0rem 0rem;
                    color: white;
                    font-size: 0.9rem;
                }
            }
        }

        .refresh-section {
            min-height: 3.75rem;

            .card-body {
                padding: 9px 1.25rem;
            }
            .refresh-btn {
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                border-radius: 20px;
                padding: 0;
                width: 40px;
                height: 40px;
                margin-left: 6px;
            }
            .refresh-icon {
                width: 22px;
                height: 22px;
                margin-top: -4px;
            }
            .reorder-btn {
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                border-radius: 20px;
                padding: 0;
                width: 40px;
                height: 40px;
            }
            .disabledReorder-btn {
                background: transparent linear-gradient(270deg, #afcbf3 0%, #bfd2ec 100%) 0% 0% no-repeat padding-box;
                border-radius: 20px;
                padding: 0;
                width: 40px;
                height: 40px;
                cursor: default !important;
            }
            .reorder-icon {
                width: 22px;
                height: 22px;
                margin-top: -4px;
            }
            .closeWhite-btn {
                background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
                border-radius: 20px;
                padding: 0;
                width: 40px;
                height: 40px;
                .iconText {
                    padding: 1rem 0rem 0rem 0rem;
                    color: white;
                    font-size: 0.9rem;
                }
            }
        }

        .selection-title {
            display: flex;
            text-align: left;
            font: normal normal normal 14px/19px Open Sans;
            color: #222222;
            margin: auto;
            padding-right: 5px !important;
            padding-left: 5px !important;
            .grade-datepicker {
                display: flex;
                justify-content: flex-start;
                flex: 1;
                //css for date picker
                .react-datepicker-wrapper {
                    margin: auto 0;
                    .react-datepicker__input-container {
                        .date {
                            .calIcon {
                                margin-right: 10px;
                                margin-bottom: 3px;
                            }
                        }
                    }
                }

                .react-datepicker__header {
                    background-color: #ffffff;
                }

                .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
                    border-bottom-color: #ffffff;
                }
                //css for date picker

                .calendarIcon {
                    margin: auto 0;
                }

                .date {
                    margin: auto 0;
                    margin-right: 10px;
                    margin-left: 10px;
                }

                .calendarIcon {
                    margin-right: 10px;
                }
            }
            .grade-events-boxes {
                display: flex;
                justify-content: flex-start;
                .input {
                    width: 380px;
                    height: 40px;
                    background: #f4f6fa 0% 0% no-repeat padding-box;
                    border: 0.75px solid #dddddd;
                    border-radius: 5px;
                    font: normal normal normal 13px/18px Open Sans;
                    color: #5d5d5d;
                    margin: auto 0;
                    margin-right: 10px;
                    font-weight: 600;
                }
                ::placeholder {
                    font: normal normal normal 13px/18px Open Sans;
                    letter-spacing: 0px;
                    color: #bbbbbb;
                    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-13)/var(--unnamed-line-spacing-18) var(--unnamed-font-family-open-sans);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    color: var(--unnamed-color-bbbbbb);
                    text-align: left;
                    font: normal normal normal 13px/18px Open Sans;
                    letter-spacing: 0px;
                    color: #BBBBBB;
                    opacity: 1;
                }
            }
        }

        @media only screen and (max-width: 767px) {
            .left-section {
                padding-right: 15px !important;
            }
            .classGradeBookMainContainer {
                padding: 0;
            }
            .right-section-show {
                padding: 0.9375rem !important;
            }
            .header-title {
                font: normal normal 600 0.875rem/1.188rem Open Sans !important;
            }
            .addButton {
                width: 10.6rem;
                font-size: 14px !important;
            }
            .header-title {
                font: normal normal 600 0.875rem/1.188rem Open Sans !important;
            }
        }
    }
    .MainColTableGradebook {
        padding-left: 7.5px;
    }
    .containersDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 100px;
        padding-left: 25px;

        .gradebookArrowImage {
            width: 14px;
            height: 30px;
            align-self: center;
            margin-bottom: 5px;
        }
        .containerTextDiv {
            width: 232px;
            height: 54px;
            .containerText {
                align-self: center;
                font-size: 20px;
                text-align: center;
                letter-spacing: 0px;
                color: #aaaaaa;
                opacity: 1;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .left-section {
            padding-right: 15px !important;
        }
        .classGradeBookMainContainer {
            padding: 0;
        }
        .right-section-show {
            margin-top: 15px;
            padding: 0.9375rem !important;
        }
    }

    @media only screen and (max-width: 520px) {
        .left-section {
            padding: 0 !important;
        }
        .right-section-show {
            padding: 0 !important;
        }
    }
}
.gradebook-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}
.gradebook-modal-warning .modal-content {
    border-radius: 8px;
    width: 412px;
    height: 329px;
    margin: auto;
    background-color: #fff !important;
}
.gradebook-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}
.gradebook-modal-warning .modal-content .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: normal normal normal 19px/26px Open Sans;
    padding-bottom: 0px;
    .warningIcon {
        width: 60px;
        height: 60px;
    }
    .waitTitle {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 24px/33px Open Sans;
        font-weight: 600;
        color: #ff1f1f;
    }
    .modalText {
        width: 256px;
        height: 52px;
        padding-top: 25px;
    }
}

.gradebook-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}
.gradebook-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}
.gradebook-modal-warning .getReport-btn {
    width: 120px;
    height: 54px;
    color: white;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 18px/24px Open Sans;
}
.gradebook-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal normal 19px/26px Open Sans;
    color: #222222 !important;
    width: 120px;
    height: 54px;
}
.gradebookClass-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}
.gradebookClass-modal-warning .modal-content {
    border-radius: 8px;
    width: 412px;
    height: 329px;
    margin: auto;
    background-color: #fff !important;
}
.gradebookClass-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}
.gradebookClass-modal-warning .modal-content .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: normal normal normal 19px/26px Open Sans;
    padding-bottom: 0px;
    .warningIcon {
        width: 60px;
        height: 60px;
    }
    .waitTitle {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 24px/33px Open Sans;
        font-weight: 600;
        color: #ff1f1f;
    }
    .modalText {
        width: 302px;
        height: 52px;
        padding-top: 0px;
    }
}

.gradebookClass-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}
.gradebookClass-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}
.gradebookClass-modal-warning .getReport-btn {
    width: 120px;
    height: 54px;
    color: white;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 18px/24px Open Sans;
}
.gradebookClass-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal normal 19px/26px Open Sans;
    color: #222222 !important;
    width: 120px;
    height: 54px;
}


.gradebook-event-modal .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}
.gradebook-event-modal .modal-footer {
    border: none;
    justify-content: center;
}
.gradebook-event-modal .deleteEventButton {
    background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 6px;
    width: 100px;
    height: 40px;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff!important;
    font: normal normal 600 14px/19px Open Sans
}
.gradebook-event-modal .cancelAtt-btn {
    background-color: #5a6268 !important;
    font: normal normal normal 19px/26px Open Sans;
    color: #fff !important;
    width: 101px;
    height: 38px;
    font: normal normal 600 14px/19px Open Sans;
    border-radius: 5px;
}
.gradebook-event-modal .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}
.gradebook-event-modal .modal-content {
    border-radius: 8px;
    width: 360px;
    margin: auto;
    background-color: #fff !important;
}
.gradebook-event-modal .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}
.gradebook-event-modal .modal-content .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    padding-bottom: 0px;
    .warningIcon {
        width: 60px;
        height: 60px;
    }
    .waitTitle {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 24px/33px Open Sans;
        font-weight: 600;
        color: #ff1f1f;
    }
    .modalText {
        width: 302px;
        height: 52px;
        padding-top: 0px;
    }
}

@media only screen and (max-width: 1300px) {
    .classGradeBookMainContainer {
        padding: 0.8rem;
    }
}

@media only screen and (max-width: 1220px) {
    .classGradeBookMainContainer {
        padding: 0rem 1rem;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .classGradeBookMainContainer {
        .containersDiv {
            padding-left: 65px;
        }
    }
}
@media only screen and (min-width: 1500px) and (max-width: 1980px) {
    .classGradeBookMainContainer {
        .containersDiv {
            padding-left: 45px;
        }
    }
}
