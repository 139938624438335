.timeline-section-div {
  margin: 0px -7.5px !important;
}

.timeline-section-div .adv-note-div {
  padding: 0px 7.5px !important;
}

.adv-note {
  background: #FFEBA0 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 4px #2222221A !important;
  opacity: 1;
  margin-bottom: 0.9375rem;
}

.adv-note-div .card {
  border: none !important;
  background: var(--unnamed-color-ffeba0) 0% 0% no-repeat padding-box;
  background: #FFEBA0 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #2222221A;
  opacity: 1;
}

.adv-note .card-header {
  padding: 0.5rem;
  text-align: left;
  line-height: 0.875rem;
  background-color: transparent;
  border-bottom: 0.0625rem solid #f0d469;
  width: 100%;
  overflow: hidden;
}

.adv-note .card-header .header-text {
  font: normal normal 600 0.75rem/1.063rem Open Sans;
  color: #222222;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adv-note .card-header button {
  float: right;
  background: transparent;
  border: none;
  padding: 0;
  line-height: 1.125rem;
  padding-right: 0.125rem;
}

.adv-note .card-body {
  padding: 0.625rem;
  min-height: 8.75rem;
  height: 170px;
  overflow: auto;
}

.adv-note .card-text {
  margin: 0;
  text-align: left;
  font: normal normal normal 0.875rem/1.25rem Open Sans;
  color: #222222;
  white-space: normal;
}

.adv-note .btn-primary:not(:disabled):not(.disabled).active,
.adv-note .btn-primary:not(:disabled):not(.disabled):active,
.adv-note .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.adv-note .btn-primary.focus,
.adv-note .btn-primary:focus {
  box-shadow: none;
}

.timeline-section-div .adv-note-ass-div {
  padding: 0px 7.5px !important;
}

.adv-note-ass {
  background: #fac129 !important;
  box-shadow: 0 0.0625rem 0.25rem #2222221a;
  margin-bottom: 0.9375rem;
}

.adv-note-ass .card-header {
  padding: 0.5rem;
  text-align: left;
  line-height: 0.875rem;
  background-color: transparent;
  border-bottom: 0.0625rem solid #f0d469;
}

.adv-note-ass .card-header .header-text {
  font: normal normal 600 0.75rem/1.063rem Open Sans;
  color: #222222;
}

.adv-note-ass .card-header .submit-text {
  font: normal normal 600 0.75rem/1.063rem Open Sans;
  color: #222222;
  text-decoration: underline;
  margin-left: 0.3rem;
  cursor: pointer;
}

.adv-note-ass .card-header button {
  float: right;
  background: transparent;
  border: none;
  padding: 0;
  line-height: 1.125rem;
  padding-right: 0.125rem;
}

.adv-note-ass .card-body {
  padding: 0.625rem;
  min-height: 8.75rem;
  height: 170px;
  overflow: auto;
}

.adv-note-ass .card-text {
  margin: 0;
  text-align: left;
  font: normal normal normal 0.875rem/1.25rem Open Sans;
  color: #222222;
  white-space: normal;
}

.adv-note-ass .btn-primary:not(:disabled):not(.disabled).active,
.adv-note-ass .btn-primary:not(:disabled):not(.disabled):active,
.adv-note-ass .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.adv-note-ass .btn-primary.focus,
.adv-note-ass .btn-primary:focus {
  box-shadow: none;
}

.editTextArea {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  // font-size: 0.8rem !important;
  font-size: 16px!important;
  font-weight: bold!important;
}

.padding0 {
  padding: 0px !important;
}

/* ------------PopOver Header--------- */
.popoverTag .popover {
  width: 20rem;
  padding: 0rem 0rem 1rem 0rem !important;
  border-radius: 11px !important;
  width: 16rem !important;
  border: none !important
}

.popoverTag .popover-header {
  height: 2.5rem !important;
  font-size: 1rem !important;
  background: transparent linear-gradient(180deg, #639ae6 0%, #3d71bb 100%) 0% 0% no-repeat padding-box;
  color: white;
  text-align: center;
  border-top-left-radius: calc(0.6rem - 1px) !important;
  border-top-right-radius: calc(0.6rem - 1px) !important;
}

.popoverTag .bs-popover-auto[x-placement^="left"]>.arrow::after,
.bs-popover-left>.arrow::after {
  border-left-color: #3d71bb !important;
}

.popoverTag .popover-inner {
  width: 16rem !important;
}

.popoverTag .popover-body {
  text-align: center !important;
  padding: 0rem !important;
}

.editYearInputType {
  width: 15rem !important;
}

.adv-note .loaderIconCss {
  width: 5.5rem;
  margin-top: 1.5rem;
}

.popoverTag .MuiOutlinedInput-root {
  height: 37px !important;
}

.popoverTag .searchIconDiv {
  padding: 0rem 0.5rem 0rem 0.5rem !important;
}

.popoverTag .searchTagdiv {
  width: 92% !important;
  height: 29px !important;
  margin-top: 0.5rem !important;
  padding-left: 0rem !important;
  background: #eeeeee 0% 0% no-repeat padding-box;
  margin-bottom: 0.5rem !important;
  border: none !important;
  border-radius: 0.3rem !important;
  font-style: italic !important;
  display: inline-flex;
}

.popoverTag input.middle:focus {
  outline-width: 0 !important;
}

.popoverTag .searchIcon {
  color: gray !important;
}

.popoverTag .searchTagInput {
  background: transparent !important;
  border: none !important;
  width: 100% !important;
}

.popoverTag input {
  outline: none;
}

.popoverTag .errormessageUpdate {
  color: #d80f0f !important;
  float: left !important;
  font-size: 12px !important;
  margin-bottom: 1px !important;
}

.popoverTag .selectTagDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 0.4rem;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}

.popoverTag .selectTagText {
  text-align: left;
  font: normal normal normal 15px/24px Open Sans;
  letter-spacing: 0.3px;
  color: #222222;
  opacity: 1;
}

.popoverTag .tagDiv {
  max-height: 10rem;
  overflow: auto;
}

/* ------------PopOver Header--------- */