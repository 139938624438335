.classRubricsContainer {
    background: #f0f0f0;
    height: 100%;
    // overflow-x: hidden;
    padding: 0rem 2.5rem 1rem 2.5rem;

    .RubricsMainDiv {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        flex-wrap: wrap;

        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A;
        border-radius: 0px 0px 8px 8px;
        opacity: 1;

        .rubBackDivmain {
            display: flex;
            align-items: center;
            flex: 30% 1;

            .rubricsBackDiv {
                display: flex;
                background: transparent;
                cursor: pointer;
                align-items: center;
            }

            .rubricsImportBackDiv {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 0.8rem;
                background: transparent linear-gradient(90deg, #669fed 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                border-radius: 6px;
                opacity: 1;

                .importBackIcon {
                    font-size: 1.3rem;
                    padding-top: 2px;
                    color: white;
                }
            }

            .vrLineRub {
                height: 88%;
                margin: 17px;
                border-left: 2px solid #2222221a;
            }
        }

        .rubricsDetailsDiv {
            margin: 0px !important;

            .displayFlexClassRubrics {
                display: flex;
                align-items: center;
                cursor: pointer;

                .rubricsGrade {
                    color: #487fcc;
                    font-size: 1rem;
                    text-align: left;
                    cursor: pointer;
                    margin: 0px !important;
                    font-weight: 600;
                }

                .rubricsForIcon {
                    color: #487fcc;
                    // padding-top: 2px;
                }

                .rubricsGrade2 {
                    // margin: 0px !important;
                    font: normal normal 600 20px/27px Open Sans !important;
                    color: #222222;
                    text-align: left;
                    cursor: default;
                    margin-bottom: 0px;


                }
            }
        }

        .dropdownsRubrics-main-container {
            font-family: sans-serif;
            text-align: center;
            position: relative;
            flex: 27%;
            top: 3px !important;
            left: 0px !important;

            .iconDiv {
                position: absolute;
                left: 9px;
                z-index: 2 !important;
                top: 7px;
                cursor: pointer;

                .searchFiltersIcon {
                    cursor: pointer;
                    color: #aaaaaa;
                    margin-right: 10px;
                }
            }

            .select-search {
                width: 240px !important;
                position: relative;
                font-family: "Nunito Sans", sans-serif;
                box-sizing: border-box;
                bottom: 5px !important;
                cursor: pointer;
            }

            .select-search *,
            .select-search *::after,
            .select-search *::before {
                box-sizing: inherit;
            }

            .select-search__value {
                position: relative;
            }

            .select-search__value::after {
                content: "";
                display: inline-block;
                position: absolute;
                top: calc(50% - 9px);
                right: 19px;
                width: 11px;
                height: 11px;
                cursor: pointer;
            }

            .select-search__input {
                margin-top: 5px;
                display: block;
                height: 40px;
                width: 240px;
                padding: 0 40px 0 30px;
                background: #fff;
                border: 1px solid #dddddd;
                box-shadow: 0px 0px 3px #88c3fae3;
                border-radius: 8px;
                outline: none !important;
                font-family: "Noto Sans", sans-serif;
                font-size: 14px;
                color: rgba(56, 108, 181, 1);
                text-align: left;
                text-overflow: ellipsis;
                line-height: 36px;
                -webkit-appearance: none;
                opacity: 1;
            }

            .select-search__input::placeholder {
                font: italic normal normal 13px/18px Open Sans;
                letter-spacing: 0px;
                color: #222222 !important;
            }

            .select-search__input::-webkit-search-decoration,
            .select-search__input::-webkit-search-cancel-button,
            .select-search__input::-webkit-search-results-button,
            .select-search__input::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }

            .select-search__input:not([readonly]):focus {
                cursor: initial;
            }

            .select-search__select {
                max-height: 150px !important;
                box-shadow: 10px 10px 12px #ec090929;
                border: 1px solid #dddddd;
            }

            .select-search__options {
                list-style: none;
                padding: 0px 5px;
                background: #fff;
                cursor: pointer;
            }

            .select-search__row {
                border-bottom: 1px solid #dddddd;
                border-width: 0.15px;
            }

            .select-search__row:last-child {
                border-bottom: none;
            }

            .select-search__option,
            .select-search__not-found {
                display: block;
                height: 36px;
                width: 100%;
                background: #fff;
                border: none;
                outline: none;
                font-family: "Noto Sans", sans-serif;
                font-size: 14px;
                text-align: left;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .select-search--multiple .select-search__option {
                height: 98px;
            }

            .select-search__option.is-selected {
                color: #fff;
            }

            .select-search__option.is-highlighted.is-selected,
            .select-search__option.is-selected:hover {
                color: #fff;
            }

            .select-search__group-header {
                text-transform: uppercase;
                background: #fff;
            }

            .select-search.is-disabled {
                opacity: 0.5;
            }

            .select-search.is-loading .select-search__value::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
                background-size: 11px;
            }

            .select-search:not(.is-disabled) .select-search__input {
                cursor: pointer;
                letter-spacing: 0px;
                font-weight: 600;
            }

            .select-search--multiple {
                border-radius: 3px;
                overflow: hidden;
            }

            .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
                transform: rotate(45deg);
                border-right: 3px solid #000;
                border-bottom: 3px solid #000;
                pointer-events: none;
            }

            .select-search.has-focus .select-search__value::after {
                display: none;
            }

            .select-search.has-focus .select-search__input:hover {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }

            .select-search.has-focus .select-search__input {
                border-bottom: none;
                box-shadow: 0px 0px 3px #00000029;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-color: none;
                background: #f4f6fa 0% 0% no-repeat padding-box;
            }

            .select-search--multiple .select-search__input {
                cursor: initial;
            }

            .select-search--multiple .select-search__input {
                border-radius: 3px 3px 0 0;
            }

            .select-search--multiple:not(.select-search--search) .select-search__input {
                cursor: default;
            }

            .select-search:not(.select-search--multiple) .select-search__select {
                position: absolute;
                z-index: 2;
                top: 40px !important;
                right: 0;
                left: 0;
                border-radius: 8px;
                overflow: auto;
                max-height: 360px;
                background: #fff;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                box-shadow: none;
            }

            .select-search--multiple .select-search__select {
                position: relative;
                overflow: auto;
                max-height: auto;
                border-top: 1px solid #eee;
                border-radius: 0 0 3px 3px;
                background: #fff;
            }

            .select-search__not-found {
                height: auto;
                padding: 8px;
                text-align: center;
                color: rgb(244, 245, 247);
            }
        }

        .LessonPlanDetailsMain {
            display: flex;
            align-items: center;

            .ClassBackDiv {
                display: flex;
                padding: 0.8rem;
                background: #eeeeee;
                width: 13rem;
                height: 3.1rem;
                cursor: pointer;
            }

            .lessonBackIcon {
                font-size: 1.3rem;
                padding-top: 2px;
            }

            .lessonClassBactText {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }
        }

        .buttonMarginTop {
            display: flex;
            align-items: center;
            flex: 2;
            justify-content: flex-end;

            .addNewRubricsBtn {
                width: 110px;
                height: 45px;
                border-radius: 8px !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                align-items: center;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;
            }

            .importRubricsBtn {
                width: 110px;
                height: 45px;
                border-radius: 8px !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                align-items: center;
                font-size: 0.6rem !important;
                margin: 0.1rem 1rem 0.1rem 0.3rem;
            }

            .rubBtnIcon {
                width: 0.9rem;
            }

            .lessonFilterIcon {
                width: 1rem;
                margin-top: 0.2rem;
            }

            .lessonBtnText {
                color: white;
                font: normal normal normal 18px/24px Open Sans;
                margin-left: 0.5rem;
            }
        }

        .lessonDetailTextDiv {
            margin-left: 1rem;
            flex: 1.6 1;
            border-left: 2.5px solid #eeeeee;
            padding: 0rem 0rem 0rem 1rem;
            height: 3.1rem;
            display: flex;
            align-items: center;

            .rubricClassName {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
                color: #386cb5;
            }

            .rubricsNumber {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }

            .displayFlexClassLesson {
                display: flex;
                cursor: pointer;

                .calIcon {
                    align-self: center;
                    cursor: default;
                    cursor: not-allowed;
                }

                .lessonClassGrade {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 5px 0px 0px 5px;
                    cursor: default;
                }

                .headingForEdited {
                    display: inline-flex;
                    flex-wrap: wrap;
                    cursor: default;
                    align-items: center;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                    }

                    .head {
                        color: #386cb5;
                        text-transform: capitalize;
                        font: normal normal 600 14px/14px Open Sans;
                        margin: 0px;
                    }
                }

                .attClassGrades {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 1px 0px 0px 5px;
                    cursor: default;
                    font-weight: 600;
                }
            }
        }
    }

    .rubFiltMainDiv {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        flex-wrap: wrap;
        margin-top: 15px;
        border-radius: 8px;

        .lessonDetailTxtDiv {
            display: flex;
            align-items: center;

            .rubricNumb {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
                opacity: 0.4;
            }

            .vrLineNoRub {
                height: 88%;
                margin: 17px;
                border-left: 2px solid #2222221a;
            }
        }

        .wrapperSearchDiv {
            position: relative;

            .iconDiv {
                height: 1.5rem;
                width: 1.5rem;
                padding: 4px;
                position: absolute;
                box-sizing: border-box;
                top: 50%;
                left: 7px;
                transform: translateY(-50%);
                display: flex;
            }

            .inputBoxComp {
                display: flex;
                width: 300px;
                height: 36px;
                background: #f4f6fa 0% 0% no-repeat padding-box;
                border: 0.75px solid #dddddd;
                border-radius: 18px;
                padding-left: 33px;
                outline: none !important;
            }

            .inputBoxComp::placeholder {
                opacity: 0.38;
                font: italic normal normal 12px/17px Open Sans;
                color: #222222;
                padding-left: 5px;
                position: absolute;
                top: 8px;
            }
        }

        .subDivs {
            display: flex;
            align-items: center;

            .calDiv {
                width: 40px;
                height: 32px;
                display: flex;
                align-items: center;
                border: 1px solid #cccccc !important;
                border-radius: 8px;
                display: inline-flex;
                justify-content: center;
                padding: 9px;
                cursor: pointer !important;

                .calclrIcons {
                    cursor: pointer !important;
                }
            }

            .vrLineCalRub {
                height: 88%;
                margin: 17px;
                border-left: 2px solid #2222221a;
            }

            .deSelectBtn {
                background: #ffffff !important;
                border: 1px solid #cccccc !important;
                cursor: pointer !important;

                .filterTimeDiv {
                    .filterText {
                        color: #222222 !important;
                    }
                }
            }

            .allFiltDiv {
                border: 1px solid #222222;
                display: inline-flex;
                justify-content: center;
                padding: 5.5px;
                margin-right: 10px;
                width: 70px;
                height: 32px;
                background: #386cb5e6 0% 0% no-repeat padding-box;
                border: 1px solid #386cb5;
                border-radius: 8px;
                opacity: 1;
                cursor: default;

                .filterTimeDiv {
                    display: flex;
                    align-items: center;

                    .filterText {
                        margin: 0px !important;
                        font: normal normal normal 12px/17px Open Sans;
                        letter-spacing: 0px;
                        color: #ffffff;
                    }
                }
            }

            .drftDiv {
                display: inline-flex;
                justify-content: center;
                padding: 5.5px;
                margin-right: 10px;
                width: 70px;
                height: 32px;
                background: #386cb5e6 0% 0% no-repeat padding-box;
                border: 1px solid #386cb5;
                border-radius: 8px;
                opacity: 1;
                cursor: default;

                .filterTimeDiv {
                    display: flex;
                    align-items: center;

                    .filterText {
                        margin: 0px !important;
                        font: normal normal normal 12px/17px Open Sans;
                        letter-spacing: 0px;
                        color: #ffffff;
                    }
                }
            }

            .cmpltDiv {
                width: 80px;
                height: 32px;
                display: inline-flex;
                justify-content: center;
                padding: 5.5px;
                cursor: default;
                background: #386cb5e6 0% 0% no-repeat padding-box;
                border: 1px solid #386cb5;
                border-radius: 8px;
                opacity: 1;

                .filterTimeDiv {
                    display: flex;
                    align-items: center;

                    .filterText {
                        margin: 0px !important;
                        font: normal normal normal 12px/17px Open Sans;
                        letter-spacing: 0px;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .importRubHeaderTwo {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        flex-wrap: wrap;
        margin-top: 15px;
        border-radius: 8px;

        .lessonDetailTxtDiv {
            display: flex;
            align-items: center;

            .rubricNumb {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
                opacity: 0.4;
            }

            .vrLineNoRub {
                height: 88%;
                margin: 17px;
                border-left: 2px solid #2222221a;
            }
        }

        .wrappersSearchDiv {
            position: relative;

            .iconDiv {
                height: 1.5rem;
                width: 1.5rem;
                padding: 4px;
                position: absolute;
                box-sizing: border-box;
                top: 50%;
                left: 7px;
                transform: translateY(-50%);
                display: flex;
            }

            .inputBoxComp {
                display: flex;
                width: 300px;
                height: 36px;
                background: #f4f6fa 0% 0% no-repeat padding-box;
                border: 0.75px solid #dddddd;
                border-radius: 18px;
                padding-left: 33px;
                outline: none !important;
            }

            .inputBoxComp::placeholder {
                opacity: 0.38;
                font: italic normal normal 12px/17px Open Sans;
                color: #222222;
                padding-left: 5px;
                position: absolute;
                top: 8px;
            }
        }

        .subDivs {
            display: flex;
            align-items: center;
        }
    }

    .spaeBtween {
        justify-content: space-between !important;
    }

    .importClassName {
        display: flex;
        align-items: center;
        margin: 0px;

        .classNameTagline {
            color: #777777;
            font: italic normal normal 13px/18px Open Sans;
            margin: 0px !important;
        }

        .classNameTag {
            color: #386cb5;
            padding-left: 5px;
            padding-right: 5px;
            font: italic normal normal 13px/18px Open Sans;
            margin: 0px !important;
        }
    }

    .rubContainersDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 100px;
        // padding-left: 25px;

        .rubricsArrowImage {
            width: 14px;
            height: 30px;
            align-self: center;
            margin-bottom: 5px;
        }

        .rubContainerTextDiv {
            width: 232px;
            height: 54px;

            .rubContainerText {
                align-self: center;
                font-size: 20px;
                text-align: center;
                letter-spacing: 0px;
                color: #aaaaaa;
                opacity: 1;
            }
        }
    }

    .rubContainerTextNoStd {
        color: #aaaaaa;
        font: normal normal 600 20px/14px Open Sans;
        line-height: inherit;
        padding-top: 40px;
    }

    .mainColRubRow {
        margin-right: -22px !important;
        margin-left: 8px !important;

        .mainColRubrics {
            padding-left: 0px !important;
            padding-right: 0px !important;

            .groupPanels {
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-top: 15px;
                display: flex;
                width: 100%;

                .noPlansDiv {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top: 180px;

                    .noPlantext {
                        color: #aaaaaa;
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                }

                .classCardDist {
                    margin-right: 15px !important;
                }

                .mainCardDivsRub {
                    padding-right: 7px !important;
                    padding-left: 7px !important;

                    .classCardDivs {
                        height: 9rem;
                        margin-bottom: 15px !important;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        box-shadow: 0rem 0.2rem 0.5rem #2724241a;
                        border-radius: 0.5rem;
                        opacity: 1;
                        padding: 0.1rem 0.5rem 0.1rem 0.4rem;

                        .classDetailsCards {
                            width: 100%;
                            height: 6.4rem;
                            padding: 15px;

                            .topDiv {
                                display: flex;

                                .textLeft {
                                    flex: 9;
                                    text-align: left;
                                    cursor: pointer;
                                    word-break: break-all;

                                    .classGradeAndSectionText {
                                        color: #777777;
                                        margin: 0rem;
                                        font-size: 0.85rem;
                                        text-transform: capitalize;
                                    }

                                    .lessonTitle {
                                        margin-bottom: 0.3rem;
                                        font-weight: 600;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        text-transform: capitalize;
                                    }

                                    .headingTitles {
                                        display: inline-flex;
                                        flex-wrap: wrap;
                                        flex: 1.3 1;
                                        justify-content: flex-start;
                                        align-items: end;

                                        .attTitleName {
                                            margin: 0px;
                                            font-size: 1rem;
                                            font-weight: 600;
                                            padding-right: 5px;
                                            font: normal normal 600 13px/13px Open Sans;
                                            color: #121111;
                                        }
                                    }
                                }
                            }

                            .bottomDiv {
                                display: flex;

                                .classTimeline {
                                    display: flex;
                                    align-items: center;
                                    padding: 10px 0px 10px 0px;
                                    width: 100%;

                                    .lessonPlan {
                                        border: 1px solid #4479c4;
                                        border-radius: 15px;
                                        width: 70px;
                                        margin: 6px 0px 6px 0px;

                                        .statusTextPlan {
                                            margin: 0rem;
                                            font-size: 0.85rem;
                                            text-align: center;
                                            color: #4479c4;
                                            text-transform: capitalize;
                                        }
                                    }

                                    .lessonCurrent {
                                        border: 1px solid #4479c4;
                                        border-radius: 15px;
                                        width: 110px;
                                        margin: 6px 0px 6px 0px;

                                        .statusTextPlan {
                                            margin: 0rem;
                                            font-size: 0.85rem;
                                            text-align: center;
                                            color: #4479c4;
                                            text-transform: capitalize;
                                        }
                                    }

                                    .lessonEval {
                                        border: 1px solid #23bf1d;
                                        border-radius: 15px;
                                        width: 83px;
                                        margin: 6px 0px 6px 0px;

                                        .statusTextEval {
                                            color: #23bf1d;
                                            margin: 0rem;
                                            font-size: 0.85rem;
                                            text-align: center;
                                            text-transform: capitalize;
                                        }
                                    }

                                    .lessonExe {
                                        border: 1px solid #ff9c17;
                                        border-radius: 15px;
                                        width: 83px;
                                        margin: 6px 0px 6px 0px;

                                        .statusTextExe {
                                            color: #ff9c17;
                                            margin: 0rem;
                                            font-size: 0.85rem;
                                            text-align: center;
                                            text-transform: capitalize;
                                        }
                                    }

                                    .headingTitles {
                                        display: inline-flex;
                                        flex-wrap: wrap;
                                        flex: 1.6 1;
                                        justify-content: flex-start;
                                        align-items: end;

                                        .attTitleName {
                                            margin: 0px;
                                            font-size: 1rem;
                                            font-weight: 600;
                                            padding-right: 5px;
                                            font: normal normal 600 13px/13px Open Sans;
                                            color: #777777;
                                        }

                                        .head {
                                            color: #9d9797;
                                            text-transform: capitalize;
                                            padding-right: 5px;
                                            font: normal normal 600 13px/13px Open Sans;
                                            margin: 0px;
                                        }
                                    }

                                    .headingTitlesRight {
                                        display: inline-flex;
                                        flex-wrap: wrap;
                                        flex: 1.3 1;
                                        padding-right: 5px;
                                        justify-content: flex-end;
                                        align-items: center;

                                        .attTitleName {
                                            margin: 0px;
                                            font-size: 1rem;
                                            font-weight: 600;
                                            padding-right: 5px;
                                            font: normal normal 600 13px/13px Open Sans;
                                            color: #777777;
                                        }

                                        .head {
                                            color: #9d9797;
                                            text-transform: capitalize;
                                            padding-right: 5px;
                                            font: normal normal 600 13px/13px Open Sans;
                                            margin: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.popoverRubrics {

    /* ----------------PopOver------------------- */
    .popoverOptions {
        display: inline-flex;
        border-bottom: 1px solid gray;
        padding: 0.5rem;
    }

    /* ----------- PopOver---------- */

    /* ----------------PopOver------------------- */
    .editClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        border-bottom: 1px solid #dddddd;
        padding: 0.5rem 0rem 0.5rem 0rem;
        width: 115%;

        .viewLpIcons {
            width: 1rem;
            height: 1.3rem;
            margin-right: 0.3rem;
        }
    }

    .downloadClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        padding: 0.5rem 0rem 0.5rem 0rem;
        width: 115%;

        .viewLpIcons {
            width: 1rem;
            height: 1.3rem;
            margin-right: 0.3rem;
        }
    }

    .disabledEditClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        border-bottom: 1px solid #dddddd;
        padding: 0rem 0rem 0.5rem 0rem;
        width: 90%;
        opacity: 0.5;
        cursor: default;
    }

    .bs-popover-auto[x-placement^="left"]>.arrow::after,
    .bs-popover-left>.arrow::after {
        border-left-color: #fff !important;
    }

    .editTextClassPopOver {
        font-size: 0.9rem;
        margin-left: 0.1rem;
    }

    .deleteClassPopOver {
        display: inline-flex;
        color: #ff4444;
        cursor: pointer;
        padding: 0.5rem 0rem 0rem 0rem;
    }

    .disabledDeleteClassPopOver {
        display: inline-flex;
        color: #ff4444;
        cursor: pointer;
        padding: 0.5rem 0rem 0rem 0rem;
        opacity: 0.5;
        cursor: default;
    }

    .deleteTextClassPopOver {
        font-size: 0.9rem;
        margin-left: 0.1rem;
    }

    .editClassIcon {
        font-size: 1.3rem;
    }

    .popover {
        width: 6.8rem !important;
        padding: 0rem !important;
        height: 5.7rem;
        border-radius: 11px !important;
    }
}

.filterPopOverRubrics {

    .bs-popover-auto[x-placement^="left"]>.arrow::after,
    .bs-popover-left>.arrow::after {
        border-left-color: #fff !important;
    }

    .popover {
        width: 11rem !important;
        padding: 0rem !important;
        height: 8.5rem;
        border-radius: 11px !important;
    }

    .notificationSubBlock {
        .filterTimeDiv {
            display: flex;
            align-items: center;
            margin: 10px 0px 10px 0px;
            border-bottom: 1px solid #dddddd;
            padding-bottom: 10px;

            .filterText {
                margin: 0px;
            }
        }

        .filterTimesDiv {
            display: flex;
            align-items: center;
            margin: 10px 0px 10px 0px;
            padding-bottom: 10px;

            .filterText {
                margin: 0px;
            }
        }
    }
}

/* --------------Upload Modal style--------- */
.lpImportOfClass {
    .modal-content {
        border-radius: 0;
        border: none;
    }

    .modal-title {
        font-size: 13px;
    }

    .modal-header {
        border-bottom-color: #eeeeee !important;
        background-color: #ffff !important;
        height: 50px !important;
    }

    .modal-header .close {
        padding: 0.5rem 1rem !important;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        outline: none !important;
    }

    .cancelText {
        font-size: 0.8rem !important;
        color: #000 !important;
    }

    .closeIconInModal {
        width: 0.6rem;
        margin-top: -0.2rem;
    }

    .uploadDocHeaderRosterDiv {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }

    .uploadNodeRosterDiv {
        text-align: left;
        background: #f5f5f5;
        padding: 1rem;
        width: 100%;
        position: absolute;
        bottom: 0rem;
    }

    .uploadClassNoteText {
        font-weight: 600;
        margin-bottom: 10px;
        margin: 0rem;
        font-size: 0.8rem;
    }

    .modal-header .close {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        outline: none !important;
    }

    .uploadNoteRosterText {
        margin: 0rem;
        font-size: 0.8rem;
    }

    .checkbozRosterCircle {
        height: 1rem;
        width: 1rem;
        border-radius: 10rem;
        border: 0.1rem solid #dddddd;
        text-align: -webkit-center;
        padding-top: 2px;
        cursor: pointer;
    }

    .checkbozCircleRosterSelected {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 10rem;
        background-color: #386cb5;
    }

    .selectMainDivforRosterUpload {
        display: flex;
        padding: 1rem 0rem 0rem 0rem;
        width: 100%;
        justify-content: center;
    }

    .selectOptionInRosterTag {
        width: 8.7rem;
        height: 9rem;
        border: 1px solid #dddddd;
        text-align: -webkit-center;
        padding: 0.5rem;
        margin: 0.5rem;
    }

    .selectoptionUploadRosterImage {
        width: 2.7rem;
        margin: 0.7rem 0rem 0.6rem 0rem;
    }

    .selectoptionUploadImageforRosterDrop {
        width: 2.3rem;
        margin: 0.7rem 0rem 0.7rem 0rem;
    }

    .autharizedRosterButton {
        width: 4.5rem !important;
        height: 1.4rem !important;
        border-radius: 8px !important;
        padding: 0.2rem !important;
        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
        margin: 0.6rem 0rem 0.1rem 0rem;
    }

    .autharizedRosterText {
        color: white;
        font-size: 0.65rem;
    }

    .selectTagRosterText {
        color: #222222;
        font-size: 0.85rem;
        margin: 0rem;
        font-weight: 600;
    }

    .importRosterRosterButton {
        width: 6.3rem !important;
        height: 2.3rem !important;
        border-radius: 2rem !important;
        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
    }

    .importRosterRosterText {
        color: white;
        font-size: 1rem;
    }
}

/* --------------Upload Modal style--------- */

.selectLp-DriveFile-modal {
    margin-top: 3rem;
}

.selectLp-DriveFile-modal .importDriveLoader {
    height: 100px;
    width: 190px;
    margin-top: 30%;
}

.selectLp-DriveFile-modal .modal-title {
    font: normal normal 600 1rem/1.375rem Open Sans !important;
}

.selectLp-DriveFile-modal .modal-content {
    height: 35rem !important;
}

.selectLp-DriveFile-modal .file_listDiv {
    width: 100%;
    height: 40px;
    margin-top: 13px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #eeeeee;
}

.selectLp-DriveFile-modal .modal-header {
    padding: 0.8rem 1rem 0;
    border-bottom: none;
    border-radius: 0.5rem;
}

.selectLp-DriveFile-modal textarea {
    font: normal normal normal 0.875rem/1.188rem Open Sans;
}

.selectLp-DriveFile-modal .selectedText {
    color: #41cc3b;
    font-weight: 600;
}

.selectLp-DriveFile-modal .unSelectedText {
    font-weight: 600;
    cursor: pointer;
}

.selectLp-DriveFile-modal .modal-footer {
    justify-content: center;
    border-top: none;
    padding-top: 0;
}

.selectLp-DriveFile-modal .modal-footer button {
    width: 5rem;
    height: 5rem;
    border-radius: 1.25rem;
    padding: 0;
}

.selectLp-DriveFile-modal .modal-footer button img {
    margin-top: -0.1875rem;
    margin-left: 0.0625rem;
}

.selectLp-DriveFile-modal .modal-content {
    border-radius: 0.5rem;
    background: #fff !important;
}

.selectLp-DriveFile-modal .modalcancleTagButton {
    width: 2.3rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #ec4444 0%, #fa6060 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    border-color: #ec4444 !important;
}

.selectLp-DriveFile-modal .modalSaveTagButton {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0rem;
    border-color: #23bf1d !important;
}

.selectLp-DriveFile-modal .checkBoxSelect {
    color: #386cb5;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-right: 0.2rem;
}

.selectLp-DriveFile-modal .unCheckBoxSelect {
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-right: 0.2rem;
}

.selectLp-DriveFile-modal .selectoptionUploadFolder {
    width: 2rem;
    margin: 0rem 0.5rem 0rem 0rem;
}

.selectLp-DriveFile-modal .fileName {
    margin-top: 0.6rem;
}

.selectLp-DriveFile-modal .driveBackIcon {
    font-size: 1.3rem;
    cursor: pointer;
}

.selectLp-DriveFile-modal .cursor {
    cursor: pointer;
}

.selectLp-DriveFile-modal .inline-Path {
    display: inline-flex;
    height: 30px;
}

.selectLp-DriveFile-modal .selectFileCursor {
    display: inline-flex;
    cursor: pointer;
}

.selectLp-DriveFile-modal .modal-list-body {
    height: 25rem !important;
    overflow: auto !important;
}

ul.breadcrumb {
    padding: 0px 0px;
    margin: 0px;
    list-style: none;
    background-color: transparent;
}

ul.breadcrumb li {
    display: inline;
    font-size: 14px;
    font-weight: 600;
}

ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
}

ul.breadcrumb li a {
    color: #386cb5;
    text-decoration: none;
}

ul.breadcrumb li a:hover {
    color: #669fed;
    text-decoration: underline;
    cursor: pointer;
}

.share-modal-popUp .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.share-modal-popUp .modal-content {
    border-radius: 8px;
    width: 360px;
    margin: auto;
    background-color: #fff !important;
}

.share-modal-popUp .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
}

.share-modal-popUp .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    display: flex;
    width: 100%;

    .bothDivMain {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .imgTextDiv {
            flex: 0.475 1;
            border: 1px solid #eeeeee;
            border-radius: 5px;
            padding: 20px 10px 10px;
            cursor: pointer;

            .textDiv {
                font: normal normal 600 14px/19px Open Sans;
                margin: 15px 0px 0px;
                color: black;
            }
        }
    }
}

.share-modal-popUp .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}

.publishAll-modal-popUp .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.publishAll-modal-popUp .modal-content {
    border-radius: 8px;
    width: 282px;
    margin: auto;
    background-color: #fff !important;
}

.publishAll-modal-popUp .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
}

.publishAll-modal-popUp .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    display: flex;
    width: 100%;

    .bothDivMain {
        width: 100%;
        justify-content: space-between;

        .imgTextDiv {
            border-radius: 5px;
            padding: 20px 10px 10px;
            cursor: pointer;

            .textDiv {
                font: normal normal 600 14px/19px Open Sans;
                margin: 40px 0px 0px;
                color: black;
            }
        }
    }
}

.publishAll-modal-popUp .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}

.rubrics-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.rubrics-modal-warning .modal-content {
    border-radius: 8px;
    width: 302px;
    margin: auto;
    background-color: #fff !important;
}

.rubrics-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}

.rubrics-modal-warning .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;

    .filterDiv {
        display: flex;
        flex-wrap: wrap;

        .listAllClassAttFilter {
            width: 5.28rem;
            height: 1.35rem;
            border: 0.5px solid #aaaaaa;
            cursor: pointer;
            border-radius: 16px;
            margin: 0.15rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .listAllClassFilterAttText {
                font-size: 0.8rem;
                color: #aaaaaa;
                padding: 1rem 0rem 0rem 0.1rem;
            }
        }

        .myClassFilterAttTodayDiv {
            width: 5.28rem;
            height: 1.35rem;
            border: 0.5px solid #aaaaaa;
            border-radius: 16px;
            margin: 0.15rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            .listAllClassFilterAttText {
                font-size: 0.8rem;
                color: #aaaaaa;
                padding: 1rem 0rem 0rem 0.1rem;
            }
        }

        .myClassFilterAttDiv {
            width: 5.28rem;
            height: 1.35rem;
            border: 0.5px solid #aaaaaa;
            border-radius: 16px;
            margin: 0.15rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            .listAllClassFilterAttText {
                font-size: 0.8rem;
                color: #aaaaaa;
                padding: 1rem 0rem 0rem 0.1rem;
            }
        }

        .selectedDivClr {
            border: 0.5px solid #639be7 !important;
        }

        .selectedTextClr {
            color: #639be7 !important;
        }
    }
}

.rubrics-modal-warning .dateBlock {
    padding: 10px 20px 10px 20px;

    .dateSubBlock {
        border: 1px solid #dddddd;
        background: #f4f6fa;
        border-radius: 5px;
        padding: 12px;
        display: flex;
        justify-content: space-between;

        .react-datepicker-wrapper {
            cursor: pointer;
        }

        .dayDateTextDiv {
            width: 250px;

            .dayDateText {
                margin-bottom: 0px !important;
                font: normal normal 600 16px/24px Open Sans;
            }
        }

        .displayFlexAttClassRoster {
            display: flex;
            cursor: pointer;
            padding-bottom: 2px;
            padding-right: 5px;

            .calIcon {
                align-self: center;
                cursor: default;
                cursor: not-allowed;
            }

            .attClassGrade {
                color: #888888;
                margin: 0rem 0.5rem 0rem 0rem;
                font-size: 0.8rem;
                text-align: left;
                padding: 3px 0px 0px 3px;
                cursor: default;
            }
        }

        .calMargin {
            margin: 4px 0px 4px 0px;
        }
    }

    .dateSubBlocks {
        border: 1px solid #dddddd;
        background: #f4f6fa;
        border-radius: 5px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .react-datepicker-wrapper {
            cursor: pointer;
        }

        .dayDateTextDiv {
            width: 250px;

            .dayDateText {
                margin-bottom: 0px !important;
                font: normal normal 600 16px/24px Open Sans;
            }
        }

        .calIcon {
            align-self: center;
            cursor: not-allowed;
        }

        .displayFlexAttClassRoster {
            display: flex;
            cursor: pointer;
            padding-bottom: 2px;
            padding-right: 5px;

            .calIcon {
                align-self: center;
                cursor: not-allowed;
            }

            .attClassGrade {
                color: #888888;
                margin: 0rem 0.5rem 0rem 0rem;
                font-size: 0.8rem;
                text-align: left;
                padding: 3px 0px 0px 3px;
                cursor: default;
            }
        }

        .calMargin {
            margin: 4px 0px 4px 0px;
        }
    }
}

.rubrics-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}

.rubrics-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}

.rubrics-modal-warning .getReport-btn {
    width: 101px;
    height: 38px;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 14px/19px Open Sans;
}

.rubrics-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal 600 14px/19px Open Sans;
    color: #080808 !important;
    width: 101px;
    height: 38px;
}

.rubricBack-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.rubricBack-modal-warning .modal-content {
    border-radius: 8px;
    width: 412px;
    height: 329px;
    margin: auto;
    background-color: #fff !important;
}

.rubricBack-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}

.rubricBack-modal-warning .modal-content .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: normal normal normal 19px/26px Open Sans;
    padding-bottom: 0px;

    .warningIcon {
        width: 60px;
        height: 60px;
    }

    .waitTitle {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 24px/33px Open Sans;
        font-weight: 600;
        color: #ff1f1f;
    }

    .modalText {
        width: 256px;
        height: 52px;
        padding-top: 25px;
    }
}

.rubricBack-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}

.rubricBack-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}

.rubricBack-modal-warning .getReport-btn {
    width: 120px;
    height: 54px;
    color: white;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 18px/24px Open Sans;
}

.rubricBack-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal normal 19px/26px Open Sans;
    color: #222222 !important;
    width: 120px;
    height: 54px;
}

.gradebookClass-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.rubricBackClass-modal-warning .modal-content {
    border-radius: 8px;
    width: 412px;
    height: 329px;
    margin: auto;
    background-color: #fff !important;
}

.rubricBackClass-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}

.rubricBackClass-modal-warning .modal-content .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: normal normal normal 19px/26px Open Sans;
    padding-bottom: 0px;

    .warningIcon {
        width: 60px;
        height: 60px;
    }

    .waitTitle {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 24px/33px Open Sans;
        font-weight: 600;
        color: #ff1f1f;
    }

    .modalText {
        width: 302px;
        height: 52px;
        padding-top: 0px;
    }
}

.rubricBackClass-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}

.rubricBackClass-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}

.rubricBackClass-modal-warning .getReport-btn {
    width: 120px;
    height: 54px;
    color: white;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 18px/24px Open Sans;
}

.rubricBackClass-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal normal 19px/26px Open Sans;
    color: #222222 !important;
    width: 120px;
    height: 54px;
}

@media screen and (min-width: 1280px) and (max-width: 1470px) {
    .rubContainersDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 100px;
        padding-left: 4% !important;
    }
}

@media screen and (min-width: 1471px) and (max-width: 1545px) {
    .rubContainersDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 100px;
        padding-left: 1% !important;
    }
}

@media screen and (min-width: 1546px) and (max-width: 1745px) {
    .rubContainersDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 100px;
        padding-left: 1% !important;
    }
}

@media screen and (min-width: 1745px) and (max-width: 1920px) {
    .rubContainersDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 100px;
        padding-right: 3% !important;
    }
}