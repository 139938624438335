.container-flex {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #f0f0f0;
}

.container-flex .container-sidebar {
    display: flex;
    flex: 1;
}

.container-flex .container-body {
    flex: 5;
    display: flex;
    flex-direction: column;
}

.container-navbar {
    flex: none;
}

.container-children {
    /* display: block; */
    flex:auto;
    height:82vh !important;
    overflow: hidden;
    overflow-y: auto;
}

.container-childrenPage {
    /* display: block; */
    flex:auto;
    min-height:82vh !important;
    width: 100vw;
    /* background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);  */
    background-color: #f0f0f0;
    /* overflow: hidden;
    overflow-y: auto; */
    padding: 0rem 4rem 0rem 4rem;
}

.loadermainFalse{
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;

}

.loadermainTrue{
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    
    
    background: #386cb5;
    opacity: .2;
    height: 100vh;
}
.loaderCSS{
     background: radial-gradient(#386cb5, transparent) !important;  
    width: 85px;
    margin: auto;
    /* margin-top: 30% !important; */

    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media only screen and (min-width: 270px) and (max-width: 970px) {
    .container-childrenPage {
    background-color: #f0f0f0;
        
        padding: 0rem 1rem 0rem 1rem;
    }
}
@media only screen and (min-width: 1360px) {
    .container-childrenPage {
        min-height: 88vh !important;
    }
}


