.viewSingleClassContainer {
    // .MuiOutlinedInput-input {
    //     padding: 0px;
    // }
    .ClassRosterYearMainDiv {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;

        .classRosterDetailsMain {
            display: flex;
            align-items: "center";

            .rosterClassBackDiv {
                display: flex;
                padding: 0.8rem;
                background: #eeeeee;
                width: 19rem;
                height: 3.1rem;
                cursor: pointer;

                .rosterClassBactText {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                }

                .rosterClassBackIcon {
                    font-size: 1.3rem;
                    padding-top: 1px;
                }

                .rosterClassDetailTextDiv {
                    margin-left: 1rem;
                    border-left: 1px solid #eeeeee;
                    padding: 0.3rem 0rem 0rem 1rem;

                    .rosterClassName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                    }

                    .displayFlexClassRoster {
                        display: flex;
                        cursor: pointer;

                        .rosterClassGrade {
                            color: #888888;
                            margin: 0rem 0.5rem 0rem 0rem;
                            font-size: 0.8rem;
                            text-align: left;
                        }
                    }
                }
            }
        }
      
        .buttonMarginTop {
            cursor: pointer;
            color: #fff;
            // width: 1rem;
            margin-top: 0.1rem;

            

            .editSingle {
                width: 6rem !important;
                height: 2.5rem !important;
                border-radius: 8px !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.2rem 0.1rem 0.2rem;

                .editSingleIcon {
                    width: 0.9rem;
                    margin-top: 0.2rem;
                }

                .editSingleText {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                }
            }
            .edit-d-none {
                display: none!important;   
            }
            .deleteSingle { 
                width: 6rem !important;
                height: 2.5rem !important;
                border-radius: 8px !important;
                padding: 0.6rem !important;
                // background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.2rem 0.1rem 0.2rem;
                background-color: white;
                border: 1px solid red !important;
                .deleteSingleIcon {
                    width: 0.9rem;
                    margin-top: 0.2rem;
                }

                .deleteSingleText {
                    // color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                    margin-left: 0.5rem;
                    color: red;
                    font-weight: 600;
                
                }
            }
            .delete-d-none {
                display: none!important;
            }
        }
    }
    .singleClassYearDiv {
        justify-content: flex-start!important;
        margin-left: 15px;
    }

    .buttonsAllMain {
        display: inline-flex;
        width: 100%;
        // justify-content: space-between;
        align-items: center;
        padding: 12px;
        
            
        /* border-bottom: 1px solid #aaaaaa; */
        justify-content: space-between;
        background: white;
        margin-top: 12px;
        border-radius: 8px;


        .singleClassSearchFilter {
            width: 16rem!important;
            height: 2.25rem;
            border: 0.75px solid #DDDDDD;
            border-radius: 18px;
            float: left;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 15px;
            margin-top: 2px;
            background: #f4f6fa 0% 0% no-repeat padding-box;
        
        }

        .opacityProp {
            opacity: 0.5;
            cursor: default;
        }

        .colorBall {
            background: white;
            width: 45px;
            height: 45px;
            border-radius: 8px;
            margin: 0px 5px 0px 10px;

            .groupActiveIcon {
                width: 1.5rem;
                cursor: pointer;
                padding-top: 10px;
            }

            .disabledGroupActiveIcon {
                width: 1.5rem;
                cursor: default;
                opacity: 0.7;
                padding-top: 10px;
            }
        }

        .attDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 7.8rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #ad54d1 0%, #cb7aeb 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
        }
        .NotesDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 5.8rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #747BEB 0%, #989EFF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
        }
        .GrpNotesDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 7.8rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #FF6A6A 0%, #FD8D8D 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
            white-space: nowrap;
        }
        .stdDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 6.8rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #747beb 0%, #989eff 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
        }

        .lessonDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 7.4rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #F48329 0%, #F99A2A 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
            white-space: nowrap;
        }

        .rubDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 6.9rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #C94276 0%, #DD5D93 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
        }
        .FADiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 9.9rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #FFBB02 0%, #FFD650 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
            white-space: nowrap;
        }
        .GradeBookDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 6.9rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #08B596 0%, #27D3B4 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
            white-space: nowrap;
        }
        .tagDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 5px 0px 5px;
            width: 5rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #FFBB02 0%, #FFD650 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
        }

        .shareDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 10px 0px 5px;
            width: 6rem;
            height: 2.7rem;
            background: transparent linear-gradient(270deg, #4479c4 0%, #669fed 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;

            .shareSubDiv {
                display: flex;
            }
        }

        .classOptionIcons {
            width: 1.2rem;
        }

        .classOptionText {
            color: white;
            font-size: 0.7rem;
            font-weight: 600;
            margin: 0rem !important;
            padding-left: 7px;
        }
    }
    .addStudent {
        display: flex;
        justify-content: end;
    }
        .addStudentImg {
            width: 180px !important;
            height: 50px;
            /* background: transparent url(img/Group 177.png) 0% 0% no-repeat padding-box; */
            opacity: 1;
            margin-top: 11px;
            margin-right: 20px;

        }
        .selectStudentImg {
            width: 180px !important;
            height: 50px;
            /* background: transparent url(img/Group 177.png) 0% 0% no-repeat padding-box; */
            opacity: 1;
            margin-top: 11px;
            margin-right: 20px;

        }
        .importImg {
            width: 180px !important;
            height: 50px;
            /* background: transparent url(img/Group 177.png) 0% 0% no-repeat padding-box; */
            opacity: 1;
            margin-top: 11px;
        }
    
    .noStudentTagline {
        color: #aaaaaa;
        font: normal normal 600 20px/14px Open Sans;
        line-height: inherit;
        padding-top: 140px;
    }
}

.displayFlexClassRosters {
    display: flex;
    cursor: pointer;
    position: relative;
// left: -68px;
margin-left: 10px;
    height: 34px;
align-content: center;
align-items: center;
width: 136px;
border-right: 1px solid lightgray;
white-space: nowrap;
    .rosterClassGrade {
        color: #888888;
        // margin: 0rem 0.5rem 0rem 0rem;
        margin: 0 15px 0 0;
        font-size: 0.8rem;
        text-align: left;
    }
}
.singleClassSearchFilterSelectStudent {
    // width: 16rem!important;
    width: 100%;
    height: 2.25rem;
    border: 0.75px solid #DDDDDD;
    border-radius: 18px;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    margin-top: 2px;
    background: #f4f6fa 0% 0% no-repeat padding-box;

}
.singleClassPopoverParentDiv {
    position: relative;
    .singleClassLevelEditField {
        height: 50px;
    }
        
}

.singlClassPopover-RD {
    top : -62px!important;
    left : 43px !important;
    border: none!important;
    width: 260px !important;
}
.singlClassPopover-RD::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 33px;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff; /* adjust color and size as needed */
  }
.addStudent {
    .addStudentImg {
        width: 180px !important;
        height: 50px;
        /* background: transparent url(img/Group 177.png) 0% 0% no-repeat padding-box; */
        opacity: 1;
        margin-top: 11px;
    }
}

.InfoInputTypee {
    width: 45px !important;
    margin-left: 11px;
    margin-bottom: 0!important;
    margin-top: 0!important;
    border: 0.75px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: .3rem;
    /* width: 38rem; */
    height: 3.5rem;
    padding-left: .5rem;
    background: #F4F6FA;
    width: 100%;
    margin-left: 6px !important;
    width: 306px;
    height: 40px;
    float: left;
    padding-top: 9px !important;
    padding-left: 13px !important;
    background: #F4F6FA 0% 0% no-repeat padding-box !important;
    border: 0.75px solid #dddddd !important;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: #222222;
text-align: left;
font: normal normal normal 16px/22px Open Sans;
letter-spacing: 0px;
color: #222222;
opacity: 0.51;

   .MuiOutlinedInput-input {
        padding: 0px;
    }
}

.inputFieldLabelLevelEdittt {
    padding-top: 22px !important;
    padding-right: 11px !important;
}

.classRosterSelectCardDiv {
    margin: 0rem .8rem .8rem 0rem;
    box-shadow: none !important;
    padding: .6rem .3rem .1rem .3rem;
    width: 27rem !important;
    height: 5rem;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    position: relative;
    display: flex;
    border-bottom: 1px solid lightgray;
}
.singleParentCard {
    justify-content: flex-start!important;
    width : auto!important;
    margin-right: 16px;
    // margin-left: 3px;
}
.singleClassRosterCardDiv {
    // margin: 0.7rem!important;
    // max-width: 19.7rem!important;
    // max-width: 403px!important;
    width: auto!important;
    margin-right: auto!important;

    @media screen and (max-width: 1800px) {
        // max-width: 19.4rem!important;
    }

}

.modal.right.fade .modal-dialog {
    right: -350px;
    top: 70px !important; 
    // .modal-content {
    //     height: auto;
    // }
}



.loadMoreRoster {
    width: 8rem !important;
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: .6rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .1rem .2rem .1rem .2rem;
}

.LoadButtonText {
    color: white;
    font-size: .8rem;
    margin-left: .3rem;
    font-weight: 600;
}
.classRosterTextInfoDiv {
    margin-top: 9px;
}



.RosterImageMainDiv {
    width: 100%;
    height: 8rem;
    background-color: #F5F5F5 !important;
    text-align: -webkit-center;
    padding-top: 1.5rem;
    margin-top: 18px;
    /* margin-right: 116px !important; */
    width: 89% !important;
    margin-left: 22px;
    border-radius: 6px;
}

.RosterImageMainSelectedDiv {
    width: 100%;
    height: 9rem;
    background-color: #F5F5F5 !important;
    text-align: -webkit-center;
    padding-top: 1rem;
    background-size: cover;
    background-position: center;
}

.RosterImageWhenSelect {
    width: 100%;
    height: 100%;
    border-radius: 10rem;
}

.addStudentImageDiv {
    width: 5rem;
    height: 5rem;
    border-radius: 10rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
}

.cameraIconCss {
    width: 2rem;
    margin-top: 1.5rem;
    cursor: pointer;
}

.loaderIconCss {
    width: 4.5rem;
    margin-top: 1.5rem;
}

.RosterImageMainSelectedDiv .changeButton {
    width: 4.2rem;
    height: 1.9rem;
    background: #000000 !important;
    border-radius: 1.5rem !important;
    margin-top: .5rem;
    opacity: .7;
    cursor: pointer !important;
    display: inline-grid;
}

.RosterImageMainSelectedDiv .changeButtonText {
    color: white;
    font-size: 10px;
    font-weight: 600;
    cursor: pointer !important;
    margin-top: .5px !important;

}

.loaderIconCssAlreadySelected {
    width: 2.5rem;
}

.allInputMainDiv {
    padding: 0rem 1.5rem 1rem 1.5rem;
}

.studentAddInputDiv {
    width: 100%;
    margin: 1.5rem 1rem 0rem 0rem
}

.studentInputLableInfo {
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0rem;
    text-align: left;
}
.iconhover:hover {
    // visibility: visible !important;
    display: block !important;
    color: #000 !important;
    cursor: pointer !important;
    padding-bottom: 12px;
}
.iconhover:target {
    // visibility: visible !important;
    display: block !important;
    color: #000 !important;
    cursor: pointer !important;
}
.iconhover {
    
    color: white;
}


.closeround {
    border-radius: 31px;
    width: 30px;
    height: 30px;
    background: var(--unnamed-color-eeeeee) 0% 0% no-repeat padding-box;
    background: #EEEEEE 0% 0% no-repeat padding-box !important;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
}

.Addstudentmodal {
    font: normal normal 600 18px/24px Open Sans !important;
}


.importRosterButton {
    position: relative;
    top: 216px;
    z-index: 6;
    justify-content: center;
    left: 42%;
    border-radius: 8px !important;
    width: 101px ;
height: 38px ;
}

.autharizedButton {
    border-radius: 8px !important;
}
.addstudentdiv {
    background-image: repeating-linear-gradient(6deg, #487fcc, #487fcc 7.92px, transparent 8px, transparent 17.9px, #487fcc 18px), repeating-linear-gradient(96deg, #487fcc, #487fcc 7.92px, transparent 8px, transparent 17.9px, #487fcc 18px), repeating-linear-gradient(186deg, #487fcc, #487fcc 7.92px, transparent 8px, transparent 17.9px, #487fcc 18px), repeating-linear-gradient(276deg, #487fcc, #487fcc 7.92px, transparent 8px, transparent 17.9px, #487fcc 18px); background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px; background-position: 0 0, 0 0, 100% 0, 0 100%; background-repeat: no-repeat; 
     display: flex;
     justify-content: start;
     align-items: center;
     margin-right: 24px;
     padding: 0px 10px 0 10px;
     height: 48px;
     margin-top: 12px;
     border-radius: 8px;
     min-width: 180px;
     text-align: left;
    //  font: normal normal 600 19px/34px Open Sans;
     letter-spacing: 0px;
     color: #487FCC;
     opacity: 1;
     cursor: pointer;
    justify-content: center;
    -webkit-text-stroke-width: thin;
    font: normal normal 500 18px/34px Open Sans;

    span {
        margin-left: 10px;
        text-shadow: 0 0 black;
    }
}
.updatebtnsinglecls {
    display: flex !important;
    align-items: center;
   
    color: white !important;
    // width: 2.5rem;
    height: 2.5rem;
    background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 8px !important;
    cursor: pointer;
    position: relative;
    top: -7px;
}

.singleClassPopoverParentDiv-CR {
    top: 26px !important;
    left: -225px !important;
    border: none !important;
    width: 260px !important;

    &::before {
        content: "";
        position: absolute;
        left: 91%;
        top: -10px;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-bottom: 10px solid #fff;
        border-right: 10px solid transparent;
    }
 } 
 
 .modalNW {
    justify-content: space-evenly !important;
    padding-top: 2rem !important;
}
// .studentListContainer {
//     max-height: 80vh;
//     overflow: hidden;
//     transition: overflow 4s ease-in-out; /* Add a smooth transition effect */
// }
// .studentListContainer > .classRosterSelectCardDivScrollbar {
//     max-height: 80vh; /* Adjust the max-height as needed */
//     overflow: hidden; /* Initially hide the scrollbar */
//     transition: all 4s ; /* Add a smooth transition effect */
//   }
  
//   .studentListContainer > .classRosterSelectCardDivScrollbar:hover {
//     overflow-y: scroll; /* Show the scrollbar on hover */
//     transition: all 4s 
//   }
// .classRosterSelectCardDivScrollbar::-webkit-scrollbar {
//     width: 5px;
//     background-color: transparent; /* Set track background color */
//   }

// // .classRosterSelectCardDivScrollbar:hover::-webkit-scrollbar {
// //     width: 5px;
// //     background-color: transparent; /* Set track background color */
// //     transition: overflow 4s ease-in-out; /* Add a smooth transition effect */
// //   }  

// .classRosterSelectCardDivScrollbar::-webkit-scrollbar-thumb {
//     background-color: grey; /* Set thumb color */
//     border-radius: 5px; /* Set thumb border-radius */
//     opacity: 0.5;
//   }


// .studentListContainer {
//     position: relative;
//     scroll-behavior: smooth;
//   }
// .studentListContainer > .classRosterSelectCardDivScrollbar {
//     max-height: 100vh; /* Adjust the max-height as needed */
//     overflow-y: scroll;
//     // transition: all 4s ; /* Add a smooth transition effect */
//   }  
//   .classRosterSelectCardDivScrollbar::-webkit-scrollbar {
//     width: .4em; 
//   }
//   .classRosterSelectCardDivScrollbar::-webkit-scrollbar,
//   .classRosterSelectCardDivScrollbar::-webkit-scrollbar-thumb {
//     overflow:visible;
//     border-radius: 4px;
//   }
//   .classRosterSelectCardDivScrollbar::-webkit-scrollbar-thumb {
//     background: rgba(0,0,0,.2); 
//   }
//   .cover-bar {
//     position: absolute;
//     background: #fff;;
//     height: 100%;  
//     top: 0;
//     right: 0;
//     width: .4em;
//     -webkit-transition: all .5s;
//     opacity: 1;
//   }
  
  .studentListContainer:hover .cover-bar {
    opacity: 0;
   -webkit-transition: all .5s;
 }


 /* Default styles for all screen sizes */
.studentListContainer {
    max-height: 82vh;
    overflow: hidden;
    transition: overflow 4s ease-in-out; /* Add a smooth transition effect */
    // position: absolute;
  }
  
  .studentListContainer > .classRosterSelectCardDivScrollbar {
    display: inherit!important;
    max-height: 82vh; /* Adjust the max-height as needed */
    overflow: hidden; /* Initially hide the scrollbar */
    // transition: all 4s; /* Add a smooth transition effect */
    // width: 93%!important;
  }
  
  .studentListContainer > .classRosterSelectCardDivScrollbar:hover {
    overflow-y: scroll; /* Show the scrollbar on hover */
    transition: all 4s;
  }
  
  .classRosterSelectCardDivScrollbar::-webkit-scrollbar {
    width: 6px;
    background-color: transparent; /* Set track background color */
  }
  
  .classRosterSelectCardDivScrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Set thumb color */
    border-radius: 5px; /* Set thumb border-radius */
    opacity: 0.5;
  }
  .selectRosterCheckBoxDivRight {
    right: 46px!important;
  }

  @media screen and (min-width: 1500px) and (max-width: 1536px) {
    .studentListContainer {
      max-height: 76vh; /* Adjust the max-height for tablets and larger screens */
    }
  
    .studentListContainer > .classRosterSelectCardDivScrollbar {
      max-height: 76vh; /* Adjust the max-height for tablets and larger screens */
    }
  }

  @media screen and (min-width: 1537px) and (max-width: 1750px) {
    .studentListContainer {
      max-height: 79vh; /* Adjust the max-height for tablets and larger screens */
    }
  
    .studentListContainer > .classRosterSelectCardDivScrollbar {
      max-height: 79vh; /* Adjust the max-height for tablets and larger screens */
    }
  }
  
  /* Media query for tablets and larger screens */
//   @media screen and (min-width: 768px) {
//     .studentListContainer {
//       min-height: 60vh; /* Adjust the min-height for tablets and larger screens */
//     }
  
//     .studentListContainer > .classRosterSelectCardDivScrollbar {
//       min-height: 60vh; /* Adjust the min-height for tablets and larger screens */
//     }
//   }

 
 .singleClassScroll::-webkit-scrollbar {
  width: 2px;
  height: 3px;
}