.ClassNoteAddGroupPanelSCSS {
    .CNAGP-panel-div {
        margin-top: 15px;

        .CNAGP-addGroupPanel {
            font: normal normal 600 14px/19px Open Sans;
            color: #222222;
            margin: 35px 0 0;
            text-align: -webkit-center;

            .mt--28 {
                margin-top: -28px;
                width: 150px;
                background: #f0f0f0;
                cursor: pointer;
            }

            hr {
                border-top: 1px solid #AAAAAA;
            }

            .CNAG-addGroupIcon {
                margin-right: 6px;
            }

            label {
                cursor: pointer;
            }
        }

        .CNAG-groupPanel {
            .MuiAccordion-root.Mui-expanded {
                margin-bottom: 0;
            }

            .MuiExpansionPanelSummary-content {
                background: #AAAAAA;
                height: 1px;
            }

            .MuiExpansionPanelSummary-root .Mui-expanded {
                min-height: 1px;
            }

            .MuiPaper-elevation1 {
                box-shadow: none;
            }

            .MuiExpansionPanel-root.Mui-expanded {
                margin: 0;
            }

            .MuiCollapse-container {
                background: #f0f0f0;
            }

            .MuiExpansionPanelSummary-root {
                background-color: #f0f0f0;
                min-height: 48px !important;
            }

            .MuiExpansionPanel-root:before {
                display: none;
            }

            .confirm-text {
                font: normal normal normal 16px/22px Open Sans
            }

            .MuiTypography-body1 {
                // margin: 0 auto;
                width: 100%;
                text-align: -webkit-center;

                .title {
                    margin-top: -10px;
                    background: #f0f0f0;
                    // max-width: 150px;
                    // width: 3%;
                    font: normal normal 600 14px/19px Open Sans;
                    color: #222222;
                    display: inline-flex;
                    position: relative;
                    bottom: 14px;
                    padding: 0 15px;
                    .CNAG-groupName {
                        width: 160px;
                        // margin-top: -19px;
                        padding: 8px;
                        text-align: center;
                        background-color: #F4F6FA;
                    }
                }

                .w-166 {
                    width: 187px;
                }

                .action {
                    float: right;
                    margin-top: -14px;
                    background: #f0f0f0;

                    img {
                        margin-left: 12px;
                    }
                }

                .actionCircle {
                    float: right;
                    margin-top: -22px;
                    background: #f0f0f0;

                    img {
                        margin-left: 0px !important;
                    }
                }

                // .mt--32 {
                //     margin-top: -34px;
                // }
            }

            .MuiExpansionPanelSummary-expandIcon {
                position: absolute;
                left: 0;
                background: #f0f0f0;
            }

            .MuiIconButton-label {

                .MuiSvgIcon-root {
                    border-radius: 20px;
                    color: white;
                    width: 18px;
                    height: 18px;
                    background: #AAAAAA 0% 0% no-repeat padding-box;
                }
            }

            .MuiPaper-root {
                .MuiButtonBase-root {
                    .MuiAccordionSummary-expandIcon {
                        position: absolute;
                        left: 0;
                    }

                    background: #f0f0f0;

                    .MuiAccordionSummary-content {
                        background: #AAAAAA;
                        height: 1px;
                    }
                }
            }

            .MuiAccordion-root:before {
                background-color: #f0f0f0;
            }

            .CNAG-detail-section {
                background: #f0f0f0;
                // margin-top: -25px;
                padding-bottom: 0;
                padding-top: 0;

                .row {
                    width: 100%;
                }
            }

            .CNAGP-StudentProfiles {
                border-radius: 0px 6px 6px 0px;
                padding: 7.5px 7.5px;
                max-width: 105px;
                height: 140px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 6px #2222221a;
    border-radius: 12px;
    margin-right: 20px;

                .card-body {
                    padding: 10px;
                    min-height: 90px;
                    max-height: 90px;
                    height: 100%;
                }

                .CNAGP-StudentProfilesCardDiv {
                    height: 100%;
                    border: none;
                }

                .StudentProfilesCardBodyDiv {}

                .closeMediaIcon {
                    position: absolute;
                    background: red;
                    border-radius: 20px;
                    right: -10px;
                    top: -7px;
                    cursor: pointer;
                }

                .CNAGP-profile {
                    text-align: left;

                    .CNAGP-withImg {
                        width: 4.375rem;
                        height: 4.375rem;
                        box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
                        border-radius: 50rem;
                    }

                    .CNAGP-img {
                        width: 4.375rem;
                        height: 4.375rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
                        border-radius: 50rem;
                        float: left;
                        display: flex;
                        justify-content: center;
                        // margin: 0px 10px 15px 10px;
                        margin: 0px 10px 10px -1px;

                        .profile-img {
                            width: 4.375rem !important;
                            height: 4.375rem !important;
                            border-radius: 50% !important;
                        }

                        .CNAGP-rosterProfileImageText {
                            padding: 16px 0px 0px 0px;
                            color: white;
                            margin: 0px !important;
                            text-transform: uppercase;
                            cursor: default;
                            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-33) var(--unnamed-font-family-open-sans);
                            letter-spacing: var(--unnamed-character-spacing-0);
                            color: var(--unnamed-color-ffffff);
                            text-align: center;
                            font: normal normal normal 24px/33px Open Sans;
                            letter-spacing: 0px;
                            color: #FFFFFF;
                            opacity: 1;
                        }
                    }

                    .CNAGP-details {
                        width: 200%;
                        display: flex;

                        [data-title] {
                            font-size: .7rem;
                            cursor: pointer;
                            margin-bottom: 0px !important;
                        }

                        [data-title]:hover::before {
                            content: attr(data-title);
                            bottom: -40px;
                            padding: 5px;
                            background: #000;
                            color: #fff;
                            font-size: .7rem;
                            word-wrap: break-word;
                            width: 220px;

                        }

                        .CNAGP-nameBigTxt {
                            // font: normal normal 600 1rem/1.375rem Open Sans;
                            // color: #222222;
                            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans) !important;
                            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
                            letter-spacing: var(--unnamed-character-spacing-0);
                            color: var(--unnamed-color-222222);
                            text-align: left;
                            // font: normal normal 600 10px/16px Open Sans;
                            font: normal normal 600 13px/19px Open Sans;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                            display: inline-block;
                            width: 180px;
                            white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                        }

                        .CNAGP-name {
                            // font: normal normal 600 1rem/1.375rem Open Sans;
                            // color: #222222;
                            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
                            letter-spacing: var(--unnamed-character-spacing-0);
                            color: var(--unnamed-color-222222);
                            text-align: left;
                            font: normal normal 600 13px/19px Open Sans;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                            display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
                        }

                        .mail {
                            font: normal normal normal 0.875rem/1.188rem Open Sans;
                            color: #777777;
                        }
                    }
                }
            }

            .CNAG-actionBtn {
                width: 40px;
                height: 40px;
                border-radius: 8px;
                padding: 0;
                margin-right: 10px;

                img {
                    margin-top: -2px;
                    margin-left: 0px !important;
                }
            }

            .cancel {
                background: transparent linear-gradient(270deg, #EA3737 0%, #F05F5F 100%) 0% 0% no-repeat padding-box;
            }

            .save {
                background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
            }

            .CNAGP-marginGroup {
                // margin: 10px 75px auto;
                margin: 0px;
            }
        }
    }
}
.titleTextEllipse {
    // width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding: 0 10px;
}
.noGrpanelbookDataText {
    color: #aaaaaa;
        // font-size: 1.5rem;
        // font-weight: 600;
        margin-top: 6%;
        font : normal normal 600 20px/14px Open Sans;
}

.scroll-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    padding-bottom: 10px;
}

.student-profiles-row {
    display: flex;
    flex-wrap: nowrap; /* Prevent items from wrapping to a new line */
}

.CNAGP-StudentProfiles {
    flex: 0 0 auto; /* Ensure the profiles don't shrink */
    margin-right: 10px; /* Optional: spacing between cards */
}

/* Optional: Customize the scrollbar */
.scroll-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px !important;
    position : 'relative';
    top: 5px;
    display: block !important;
 
 }