.score-input-popover {
    left: 60px !important;
    top: -62px !important;
    min-height: 78px !important;
    border-radius: 0 !important;
    padding: 5px !important;
    box-shadow: 0px 0px 15px #22222233;
    border: white !important;
    width: 240px !important;

    .arrow {
        display: none !important;
    }
    .number-arrow {
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
    }
    .editgradebookpopover {
        .MuiOutlinedInput-input {
            text-align: center;
            padding: 6px 0 7px;
        }
    }
    .popover-body {
        min-height: 68px !important;

        .closeIcon {
            float: right;
            cursor: pointer;
        }

        .input-box {
            width: 80px;
            height: 38px;
            background: #f4f6fa 0% 0% no-repeat padding-box;
            border: 0.75px solid #cccccc;
            border-radius: 5px;
            float: left;
            margin-top: 15px;
            margin-left: 0px;
            text-align: center;
            // padding-top: 2px;
            justify-content: center;
        }

        .addCommentBtn {
            margin-top: 14px;
        }

        .addCommentBtn:active,
        .addCommentBtn:focus {
            outline: none;
            box-shadow: none;
        }

        .saveBtn {
            width: 70px;
            height: 28px;
            background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
            border-radius: 8px;
            margin-top: 15px;
            margin-left: 5px;
            font: normal normal 600 12px/14px Open Sans;
            color: #ffffff;
        }
        .saveBtnWhenComment {
            width: 70px;
            height: 28px;
            background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
            border-radius: 8px;
            margin-top: 15px;
            margin-left: 5px;
            font: normal normal 600 12px/14px Open Sans;
            color: #ffffff;
        }

        .saveBtn:active,
        .saveBtn:focus {
            outline: none;
            box-shadow: none;
        }

        .score-color-popover-positioned {
            margin-top: 0px;
            margin-left: 50px;

            .arrow {
                display: block !important;
                left: 37px !important;
            }
        }

        textarea {
            width: 200px;
            margin: auto;
            margin-bottom: 15px;
            border-radius: 5px;
            background: #f4f6fa 0% 0% no-repeat padding-box;
            border: 0.75px solid #cccccc;
            margin-top: 15px;
            font: normal normal normal 12px/17px Open Sans;
            color: #222222;
        }
    }
}
