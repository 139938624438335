.App {
  text-align: center;
  height: 83vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 83vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* ::placeholder {
  color: #737477;
  opacity: 1; 
 } 

:-ms-input-placeholder { 
 color: #737477;
}

::-ms-input-placeholder { 
 color: #737477;
}*/
::placeholder {
  color: #000;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.errorDiv {
  margin-bottom: 25px !important;
}
.errormessage {
  color: #d80f0f !important;
  float: left !important;
  margin-top: 3px !important;
  font-size: 13px !important;
  margin-bottom: 1px !important;
  font-weight: 600;
}

textarea {
  resize: none !important;
}
.loaderIconCss {
  width: 5.5rem;
  margin-top: 1.5rem;
}
.btn {
  border: none !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.alignCentre {
  display: flex;
  align-self: center;
}

/* -------Scroll Css-------- */
/* width */
/* ::-webkit-scrollbar {
  width: 0.05rem;
  height: 0.05rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.3125rem #dedede;
  border-radius: 0.5rem;
}

/* Handle */
/* ::-webkit-scrollbar-thumb {
     background: #afafaf;
     border-radius: 0.625rem;
 } */

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

/* width */
/* ::-webkit-scrollbar {
    width: 0.3125rem;
     height: 0.3125rem;
 } */

/* Track */
/* ::-webkit-scrollbar-track {
     box-shadow: inset 0 0 0.3125rem #dedede;
     border-radius: 0.5rem;
 } */

/* Handle */
/* ::-webkit-scrollbar-thumb {
     background: #afafaf;
     border-radius: 0.625rem;
 } */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
     background: #7d7d7d;
 } */
/* -------Scroll Css-------- */


.PushPopUp-modal-warnings.modal.show .modal-dialog {
  transform: translate(485px, 101px);
}
.PushPopUp-modal-warnings .modal-content {
  border-radius: 8px;
  width: 75%;
  margin: auto;
  background-color: rgb(255, 255, 255);
}
.PushPopUp-modal-warnings .modal-content .modal-header .modal-title {
  text-align: center;
  color: rgb(0, 0, 0);
  font: normal normal 600 18px/24px Open Sans;
  display: flex;
}
.PushPopUp-modal-warnings .modal-content .modal-header {
  display: flex;
  border: none;
  border-radius: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}
.PushPopUp-modal-warnings .modal-title .titleDiv {
  display: flex;
  padding-left: 20px;
}
.PushPopUp-modal-warnings .modal-title .titleNameDiv {
  display: flex;
  align-self: center;
  padding-left: 10px;
}
.PushPopUp-modal-warnings .modal-title .titleNameDiv .confirmMesg-text {
  font: normal normal 600 18px/22px Open Sans !important;
  color: rgb(0, 0, 0);
  margin-bottom: 0;
  padding-left: 20px;
}
.PushPopUp-modal-warnings .modal-content .modal-body {
  text-align: center;
  font: normal normal 600 14px/19px Open Sans;
  justify-content: center;
  padding-top: 0px !important;
}
.PushPopUp-modal-warnings .modal-content .modal-body .confirmTextData {
  padding: 0px 20px 0px 20px;
}
.PushPopUp-modal-warnings .modal-content .pushProfileForword {
  align-self: center;
}
.PushPopUp-modal-warnings .modal-content .modal-body .confirm-text {
  font: normal normal 600 14px/22px Open Sans !important;
  color: rgb(112 100 100);
  margin-bottom: 0;
}
.PushPopUp-modal-warnings .modal-content .modal-footer {
  border: none;
  justify-content: center;
  padding-top: 10px;
  padding: none !important;
}
.PushPopUp-modal-warnings .modal-content .cancelUpgrade-btn {
  width: 101px;
  height: 38px;
  font: normal normal 600 14px/19px Open Sans;
}

.PushPopUp-modal-warning.modal.show .modal-dialog {
  transform: translate(485px, 226px);
}
.PushPopUp-modal-warning .modal-content {
  border-radius: 8px;
  width: 75%;
  margin: auto;
  background-color: rgb(255, 255, 255);
}
.PushPopUp-modal-warning .modal-content .modal-header .modal-title {
  text-align: center;
  color: rgb(0, 0, 0);
  font: normal normal 600 18px/24px Open Sans;
  display: flex;
}
.PushPopUp-modal-warning .modal-content .modal-header {
  display: flex;
  border: none;
  border-radius: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}
.PushPopUp-modal-warning .modal-title .titleDiv {
  display: flex;
  padding-left: 20px;
}
.PushPopUp-modal-warning .modal-title .titleNameDiv {
  display: flex;
  align-self: center;
  padding-left: 10px;
}
.PushPopUp-modal-warning .modal-title .titleNameDiv .confirmMesg-text {
  font: normal normal 600 18px/22px Open Sans !important;
  color: rgb(0, 0, 0);
  margin-bottom: 0;
  padding-left: 20px;
}
.PushPopUp-modal-warning .modal-content .modal-body {
  text-align: center;
  font: normal normal 600 14px/19px Open Sans;
  justify-content: center;
  padding-top: 0px !important;
}
.PushPopUp-modal-warning .modal-content .modal-body .confirmTextData {
  padding: 0px 20px 0px 20px;
}
.PushPopUp-modal-warning .modal-content .pushProfileForword {
  align-self: center;
}
.PushPopUp-modal-warning .modal-content .modal-body .confirm-text {
  font: normal normal 600 14px/22px Open Sans !important;
  color: rgb(112 100 100);
  margin-bottom: 0;
}
.PushPopUp-modal-warning .modal-content .modal-footer {
  border: none;
  justify-content: center;
  padding-top: 0;
  padding: none !important;
}
.PushPopUp-modal-warning .modal-content .cancelUpgrade-btn {
  width: 101px;
  height: 38px;
  font: normal normal 600 14px/19px Open Sans;
}
