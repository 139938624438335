.contactContainer{
    justify-content: center;
    padding: 20px 80px 0px 80px;
    .cardOfContact{
        width: 100%;
        min-height: 500px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A;
        border: none;
        padding: 40px;
    }
    .aboutHeading{
        text-align: center;
        font: normal normal normal 35px/40px Roboto;
        letter-spacing: 0.8px;
        color: #222222;
        margin-bottom: 30px;
    }
    .descriptionText{
        font-weight: 300;
        line-height: 26px;
        text-align: justify;
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0.32px;
        color: #222222;
    }
    .addressMainDiv{
        padding: 0px 0px 0px 20px;
    }
    .addressDiv{
        text-align: left;
    }
    .addressIconCol{
        padding-right: 10px;
    }
    .formDiv{
        width: 100%;
        height: 429px;
        background: #F0F0F0 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 10px 25px 10px 25px;
    }
    .fromInput{
        height: 45px;
        background: transparent !important;
        border: none;
        border-bottom: 1px solid #d6d3d3;
        margin-bottom: 2px;
        margin-top: 20px;
        border-radius: 0px;
        padding-left: 0px;
    }
    .form-control:focus{
        box-shadow: none !important;
        border-bottom: 1px solid #d6d3d3;
        margin-bottom: 2px;

    }
    .fromInputArea{
        height: 95px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 5px;
        margin-top: 20px;

    }
    .sendButton{
        float: right;
        width: 87px;
        height: 40px;
        background: var(--unnamed-color-386cb5) 0% 0% no-repeat padding-box;
        background: #386CB5 0% 0% no-repeat padding-box;
        border-radius: 5px;
        margin-top: 20px;
    }
    
    
    .sendText{
        
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        text-align: center;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        margin: 0px;
        font-weight: bold;
    }
    .errormessage{
        color: #d80f0f !important;
        float: left !important;
        margin-top: 3px !important; 
        font-size: 13px !important;
        margin-bottom: 1px !important;
        font-weight: 600;
      }
    @media only screen and (min-width: 851px) and (max-width: 1250px) {
        padding: 20px 40px 0px 40px;
    
           
    }
    @media only screen and (min-width: 251px) and (max-width: 850px) {
        padding: 20px 00px 0px 00px;
        .addressIconCol{
            // padding-left: 10px;
            margin-bottom: 10px;
            text-align: left;
            padding-right: 0px;
        }
    
           
    }
}