.assessmentMainContainer {
    background: #f0f0f0;
    height: 49rem;
    padding: 0rem 3rem 2rem 3rem;

    .noStudentinAssessment {
        color: #aaaaaa;
        font-size: 2rem;
        margin-top: 40px;
        font-weight: 600;
    }

    a:focus,
    .btn:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .right-sections {
        padding-left: 0.4375rem !important;

        .header {
            height: 4.7rem;
            display: flex;
            width: 100%;

            .headerCard {
                display: flex;
                width: 100%;

                .classAssessmentDetailsMain {
                    display: flex;
                    align-items: center;
                    flex: 0.6;

                    .assessmentBackDiv {
                        display: flex;
                        background: transparent;
                        cursor: pointer;
                        align-items: center;
                    }

                    .vrLine {
                        width: 0px;
                        height: 100%;
                        margin: 17px;
                        border: 1px solid #2222221a;
                    }

                    .assessmentDetailsDiv {
                        margin: 0px !important;

                        .displayFlexClassAssessment {
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            .formAssessGrade {
                                color: #487fcc;
                                font-size: 1rem;
                                text-align: left;
                                cursor: pointer;
                                margin: 0px !important;
                                font-weight: 600;
                            }

                            .assessmentClassForIcon {
                                color: #487fcc;
                            }

                            .formAssessGrade2 {
                                margin: 0px !important;
                                font: normal normal 600 20px/27px Open Sans !important;
                                color: #222222;
                                text-align: left;
                                cursor: default;
                            }
                        }
                    }

                    .attClassBackIcon {
                        font-size: 1.3rem;
                        padding-top: 3px;
                    }

                    .attClassBactText {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                    }
                }

                .dropdowns-assessment-main-container {
                    font-family: sans-serif;
                    text-align: center;
                    position: relative;
                    flex: 0.6;
                    top: 0px !important;
                    left: 0px !important;

                    .iconDiv {
                        position: absolute;
                        left: 9px;
                        z-index: 2 !important;
                        top: 7px;
                        cursor: pointer;

                        .searchFiltersIcon {
                            cursor: pointer;
                            color: #aaaaaa;
                            margin-right: 10px;
                        }
                    }

                    .select-search {
                        width: 240px !important;
                        position: relative;
                        font-family: "Nunito Sans", sans-serif;
                        box-sizing: border-box;
                        bottom: 5px !important;
                        cursor: pointer;
                    }

                    .select-search *,
                    .select-search *::after,
                    .select-search *::before {
                        box-sizing: inherit;
                    }

                    .select-search__value {
                        position: relative;
                    }

                    .select-search__value::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        top: calc(50% - 9px);
                        right: 19px;
                        width: 11px;
                        height: 11px;
                        cursor: pointer;
                    }

                    .select-search__input {
                        margin-top: 5px;
                        display: block;
                        height: 40px;
                        width: 240px;
                        padding: 0 40px 0 30px;
                        background: #fff;
                        border: 1px solid #dddddd;
                        box-shadow: 0px 0px 3px #88c3fae3;
                        border-radius: 8px;
                        outline: none !important;
                        font-family: "Noto Sans", sans-serif;
                        font-size: 14px;
                        color: rgba(56, 108, 181, 1);
                        text-align: left;
                        text-overflow: ellipsis;
                        line-height: 36px;
                        -webkit-appearance: none;
                        opacity: 1;
                    }

                    .select-search__input::placeholder {
                        font: italic normal normal 13px/18px Open Sans;
                        letter-spacing: 0px;
                        color: #222222!important;
                    }

                    .select-search__input::-webkit-search-decoration,
                    .select-search__input::-webkit-search-cancel-button,
                    .select-search__input::-webkit-search-results-button,
                    .select-search__input::-webkit-search-results-decoration {
                        -webkit-appearance: none;
                    }

                    .select-search__input:not([readonly]):focus {
                        cursor: initial;
                    }

                    .select-search__select {
                        max-height: 150px !important;
                        box-shadow: 10px 10px 12px #ec090929;
                        border: 1px solid #dddddd;
                    }

                    .select-search__options {
                        list-style: none;
                        padding: 0px 5px;
                        background: #fff;
                        cursor: pointer;
                    }

                    .select-search__row {
                        border-bottom: 1px solid #dddddd;
                        border-width: 0.15px;
                    }

                    .select-search__row:last-child {
                        border-bottom: none;
                    }

                    .select-search__option,
                    .select-search__not-found {
                        display: block;
                        height: 36px;
                        width: 100%;
                        background: #fff;
                        border: none;
                        outline: none;
                        font-family: "Noto Sans", sans-serif;
                        font-size: 14px;
                        text-align: left;
                        cursor: pointer;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .select-search--multiple .select-search__option {
                        height: 48px;
                    }

                    .select-search__option.is-selected {
                        color: #fff;
                    }

                    .select-search__option.is-highlighted.is-selected,
                    .select-search__option.is-selected:hover {
                        color: #fff;
                    }

                    .select-search__group-header {
                        text-transform: uppercase;
                        background: #fff;
                    }

                    .select-search.is-disabled {
                        opacity: 0.5;
                    }

                    .select-search.is-loading .select-search__value::after {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
                        background-size: 11px;
                    }

                    .select-search:not(.is-disabled) .select-search__input {
                        cursor: pointer;
                        letter-spacing: 0px;
                        font-weight: 600;
                    }

                    .select-search--multiple {
                        border-radius: 3px;
                        overflow: hidden;
                    }

                    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
                        transform: rotate(45deg);
                        border-right: 3px solid #000;
                        border-bottom: 3px solid #000;
                        pointer-events: none;
                    }

                    .select-search.has-focus .select-search__value::after {
                        display: none;
                    }

                    .select-search.has-focus .select-search__input:hover {
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }

                    .select-search.has-focus .select-search__input {
                        border-bottom: none;
                        box-shadow: 0px 0px 3px #00000029;
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-color: none;
                        background: #f4f6fa 0% 0% no-repeat padding-box;
                    }

                    .select-search--multiple .select-search__input {
                        cursor: initial;
                    }

                    .select-search--multiple .select-search__input {
                        border-radius: 3px 3px 0 0;
                    }

                    .select-search--multiple:not(.select-search--search) .select-search__input {
                        cursor: default;
                    }

                    .select-search:not(.select-search--multiple) .select-search__select {
                        position: absolute;
                        z-index: 2;
                        top: 40px !important;
                        right: 0;
                        left: 0;
                        border-radius: 8px;
                        overflow: auto;
                        max-height: 360px;
                        background: #fff;
                        border-top-right-radius: 0px;
                        border-top-left-radius: 0px;
                        box-shadow: none;
                    }

                    .select-search--multiple .select-search__select {
                        position: relative;
                        overflow: auto;
                        max-height: auto;
                        border-top: 1px solid #eee;
                        border-radius: 0 0 3px 3px;
                        background: #fff;
                    }

                    .select-search__not-found {
                        height: auto;
                        padding: 8px;
                        text-align: center;
                        color: rgb(244, 245, 247);
                    }
                }

                .assessBtns {
                    display: flex;
                    align-items: center;
                    flex: 0.3;
                    justify-content: flex-end;

                    .text-right {
                        display: flex;
                        text-align: right !important;

                        .saveCancTitle {
                            margin: 0px !important;
                            padding-left: 5px;
                        }

                        .actionBtn {
                            width: 100px;
                            height: 40px;
                            border-radius: 8px;
                            padding: 0;
                            margin-right: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                margin-top: -2px;
                            }
                        }

                        .cancel {
                            background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;

                            img {
                                margin-top: 0px;
                            }
                        }

                        .save {
                            background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
                            padding: 10px;

                            .saveTitle {
                                margin: 0px !important;
                                padding-left: 7px;
                            }

                            img {
                                margin-top: 0px;
                            }
                        }
                    }
                }
            }

            .card-body {
                padding: 17px !important;
            }

            @media only screen and (max-width: 991px) {
                .card-body {
                    padding: 12px !important;
                }
            }
        }
        .formContainersDiv {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-self: center;
            align-items: center;
            margin-top: 100px;
            padding-left: 25px;

        .formContainerTextNoStd {
            color: #aaaaaa;
            font: normal normal 600 20px/14px Open Sans;
            line-height: inherit;
            padding-top: 40px;
            }
            }
        .containerDiv {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-self: center;
            align-items: center;
            margin-top: 100px;

            .assessArrowImage {
                width: 14px;
                height: 30px;
                align-self: center;
                margin-bottom: 5px;
            }

            .containerTextDiv {
                width: 232px;
                align-self: center;

                .containerText {
                    align-self: center;
                    font-size: 20px;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #aaaaaa;
                    opacity: 1;
                }
            }

        }

        .nullTextContainerDiv {
            width: 100%;
            display: flex;
            padding-right: 310px;
            margin-top: 220px;
            justify-content: center;

            .assessArrowImageNull {
                width: 30px;
                height: 14px;
                align-self: center;
                margin-right: 10px;
            }

            .containerTextDiv {
                width: 232px;
                align-self: center;

                .containerText {
                    align-self: center;
                    font-size: 20px;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #aaaaaa;
                    opacity: 1;
                }
            }
        }

        @media only screen and (max-width: 991px) {
            .header {
                height: unset;
            }
        }

        .add {
            width: 9rem;
            height: 2.5rem;
            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box !important;
            border-radius: 8px !important;
            float: right;
        }

        .disabledAdd {
            width: 9rem;
            height: 2.5rem;
            background: transparent linear-gradient(270deg, #aec7ea 0%, #b6cae6 100%) 0% 0% no-repeat padding-box !important;
            border-radius: 8px !important;
            float: right;
            cursor: default;
        }

        .addIcon {
            width: 0.875rem;
            height: 0.875rem;
            margin-right: 0.5rem;
            margin-top: -0.1875rem;
        }

        .sub-header {
            min-height: 3.75rem;

            .card-body {
                padding: 12px;
            }

            .title {
                display: flex;
                text-align: left;
                font: normal normal normal 14px/19px Open Sans;
                color: #222222;
                margin: auto;

                //css for date picker
                .react-datepicker-wrapper {
                    margin: auto 0;
                    cursor: pointer;
                }

                .react-datepicker__header {
                    background-color: #ffffff;
                }

                .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
                    border-bottom-color: #ffffff;
                }

                //css for date picker

                .calendarIcon {
                    margin: auto 0;
                }

                .date {
                    margin: auto 0;
                    margin-right: 18px;
                    margin-left: 10px !important;
                }

                .calendarIcon {
                    margin-right: 10px;
                }

                .thumbIcon {
                    position: absolute;
                    right: 12px;
                    top: 8px;
                    cursor: pointer;
                }

                .input {
                    width: 574px;
                    height: 40px;
                    background: #f4f6fa 0% 0% no-repeat padding-box;
                    border: 0.75px solid #dddddd;
                    border-radius: 5px;
                    font: normal normal normal 13px/18px Open Sans;
                    color: #525252 !important;
                    margin: auto 0;
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    position: relative;
                    padding-right: 43px;
                    input[type="text"] {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }
                      
                }

                @media only screen and (max-width: 1220px) {
                    .input {
                        width: 574px;
                    }
                }

                @media only screen and (max-width: 991px) {
                    .input {
                        width: 574px;
                    }

                    .thumbIcon {
                        right: 12px
                    }
                }
            }
        }

        .studentsDivAssessment {
            height: 44.25rem;
            overflow-y: scroll;
            
        }
    }

    .blockSection {
        height: 26.5rem;

        .left-section {
            padding-right: 0.4375rem !important;
            padding-left: 0.4375rem !important;
            width: 356px !important;

            .leftCol {
                height: 24.9rem;
            }

            .back-btn {
                background-color: #eeeeee !important;
                border-radius: 0.25rem;
                width: 100%;
                text-align: left !important;
                color: #222222 !important;
                border: none !important;
                font-weight: 600 !important;
                font: normal normal 600 1rem/1.375rem Open Sans;
                height: 3.125rem;

                .fa-chevron-left {
                    margin-right: 0.625rem;
                }
            }

            .action-btn {
                border-radius: 0.5rem;
                border: none !important;
                width: 100%;
                text-align: center;
                font: normal normal 600 0.875rem/1.188rem Open Sans !important;
                letter-spacing: 0;
                color: #ffffff;
                min-height: 2.813rem;

                .noteOptionIcons {
                    width: 1.2rem;
                    height: 1.2rem;
                    margin-right: 0.55rem;
                }

                @media only screen and (max-width: 1500px) {
                    .noteOptionIcons {
                        width: 1.125rem;
                        height: 1.125rem;
                        margin-right: 0.5rem;
                    }
                }

                @media only screen and (max-width: 1220px) {
                    .noteOptionIcons {
                        width: 1.375rem;
                        height: 1.375rem;
                        margin-right: 0.3125rem;
                    }
                }

                @media only screen and (max-width: 767px) {
                    .noteOptionIcons {
                        margin-right: 0.75rem;
                    }
                }
            }

            .class-btn {
                background: transparent linear-gradient(270deg, #ffb239 0%, #ffc76e 100%) 0% 0% no-repeat padding-box;
            }

            .gradebook-btn {
                background: transparent linear-gradient(270deg, #08b596 0%, #27d3b4 100%) 0% 0% no-repeat padding-box;
            }

            .attchement-card {
                font: normal normal 600 16px/22px Open Sans;
                color: #a7a7a7;
                text-align: left;
            }

            .tooltip {
                position: relative !important;
                display: inline-block !important;
                opacity: 1 !important;
                padding-top: 5px !important;
                padding-left: 16px !important;
            }

            .tooltip .tooltiptext {
                visibility: hidden;
                width: 180px;
                max-height: 100px;
                background-color: black;
                color: #fff;
                text-align: center;
                padding: 10px;
                border-radius: 6px;
                position: absolute;
                z-index: 1;
                bottom: 100%;
                left: 10%;
                margin-left: -60px;
                overflow: auto;
            }

            .tooltip:hover .tooltiptext {
                visibility: visible;
            }

            .tooltip .tooltiptext::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: black transparent transparent transparent;
            }

            .tooltip:hover .tooltiptext {
                visibility: visible;
            }

            @media only screen and (max-width: 1500px) {
                .gradebook-btn {
                    font: normal normal 600 0.8125rem/1.125rem Open Sans !important;
                    padding: 0.375rem !important;
                }
            }

            @media only screen and (max-width: 767px) {

                .class-btn,
                .gradebook-btn {
                    min-height: 2.813rem !important;
                }
            }

            .tagsFormDiv {
                overflow-y: scroll;
                height: 43.8rem;
                -ms-overflow-style: none !important;
                scrollbar-width: none !important;

                .single-attchement {
                    text-align: left;
                    border-bottom: 1px solid #f4f4f4;
                    cursor: pointer;

                    .title {
                        font: normal normal 600 0.875rem/1.188rem Open Sans !important;
                        color: #222222;
                        cursor: pointer;
                        overflow: hidden;
                        width: 125px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .action {
                        float: right;
                        margin-right: 16px !important;

                        .img {
                            width: 0.875rem;
                            height: 0.875rem;
                        }

                        .edit-btn {
                            background: #ffffff !important;
                            border: none !important;
                            font: normal normal normal 0.75rem/1.125rem Open Sans !important;
                            color: #222 !important;
                            cursor: pointer;
                            margin-right: 10px;
                            width: 32px !important;
                            height: 32px !important;

                        }

                        .disabledEdit-btn {
                            background: transparent !important;
                            border: none !important;
                            font: normal normal normal 0.75rem/1.125rem Open Sans !important;
                            color: #222 !important;
                            cursor: pointer;
                            cursor: default !important;
                            filter: brightness(4.5);
                        }

                        .trash-btn {
                            background: #ffffff !important;
                            border: none !important;
                            font: normal normal normal 0.75rem/1.125rem Open Sans !important;
                            color: #ff4444 !important;
                            cursor: pointer;
                            width: 32px !important;
                            height: 32px !important;
                        }

                        .disabledTrash-btn {
                            background: transparent !important;
                            border: none !important;
                            font: normal normal normal 0.75rem/1.125rem Open Sans !important;
                            color: #ff4444 !important;
                            cursor: default !important;
                        }
                    }
                }
            }

            .tagsFormDiv::-webkit-scrollbar {
                display: none !important;
            }
        }

        .right-section {
            padding-left: 0.4375rem !important;
            height: 24.9rem;
            -ms-overflow-style: none !important;
            scrollbar-width: none !important;

            .header {
                height: 4.7rem;
                display: flex;
                width: 100%;

                .headerCard {
                    display: flex;
                    width: 100%;

                    .classAssessmentDetailsMain {
                        display: flex;
                        align-items: center;
                        flex: 0.6;

                        .assessmentBackDiv {
                            display: flex;
                            background: transparent;
                            cursor: pointer;
                            align-items: center;
                        }

                        .vrLine {
                            width: 0px;
                            height: 100%;
                            margin: 17px;
                            border: 1px solid #2222221a;
                        }

                        .assessmentDetailsDiv {
                            margin: 0px !important;

                            .displayFlexClassAssessment {
                                display: flex;
                                align-items: center;
                                cursor: pointer;

                                .attClassGrade {
                                    margin: 0px !important;
                                    font-weight: 600;
                                }

                                .assessmentClassForIcon {
                                    color: #487fcc;
                                }

                                .attClassGrade2 {
                                    font: normal normal 600 20px/27px Open Sans !important;
                                    margin: 0px !important;
                                }
                            }
                        }

                        .attClassBackIcon {
                            font-size: 1.3rem;
                            padding-top: 3px;
                        }

                        .attClassBactText {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            text-align: left;
                        }
                    }

                    .dropdown-main-container {
                        font-family: sans-serif;
                        text-align: center;
                        position: relative;
                        flex: 0.6;

                        .iconDiv {
                            position: absolute;
                            left: 9px;
                            z-index: 1;
                            top: 7px;

                            .searchFiltersIcon {
                                cursor: pointer;
                                color: #aaaaaa;
                                margin-right: 10px;
                            }
                        }

                        .select-search {
                            width: 240px !important;
                            position: relative;
                            font-family: "Nunito Sans", sans-serif;
                            box-sizing: border-box;
                            bottom: 5px !important;
                        }

                        .select-search *,
                        .select-search *::after,
                        .select-search *::before {
                            box-sizing: inherit;
                        }

                        .select-search__value {
                            position: relative;
                        }

                        .select-search__value::after {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            top: calc(50% - 9px);
                            right: 19px;
                            width: 11px;
                            height: 11px;
                        }

                        .select-search__input {
                            margin-top: 5px;
                            display: block;
                            height: 40px;
                            width: 240px;
                            padding: 0 40px 0 30px;
                            background: #fff;
                            border: 1px solid #dddddd;
                            box-shadow: 0px 0px 3px #88c3fae3;
                            border-radius: 8px;
                            outline: none !important;
                            font-family: "Noto Sans", sans-serif;
                            font-size: 14px;
                            color: rgba(56, 108, 181, 1);
                            text-align: left;
                            text-overflow: ellipsis;
                            line-height: 36px;
                            -webkit-appearance: none;
                            opacity: 1;
                        }

                        .select-search__input::placeholder {
                            font: italic normal normal 13px/18px Open Sans;
                            letter-spacing: 0px;
                            color: #c6b6b6 !important;
                        }

                        .select-search__input::-webkit-search-decoration,
                        .select-search__input::-webkit-search-cancel-button,
                        .select-search__input::-webkit-search-results-button,
                        .select-search__input::-webkit-search-results-decoration {
                            -webkit-appearance: none;
                        }

                        .select-search__input:not([readonly]):focus {
                            cursor: initial;
                        }

                        .select-search__select {
                            box-shadow: 0 0.0625rem 0.125rem rgba(118, 167, 231, 0.966);
                            max-height: 150px !important;
                        }

                        .select-search__options {
                            list-style: none;
                            padding: 0px 5px;
                            background: #fff;
                        }

                        .select-search__row {
                            border-bottom: 1px solid #dddddd;
                            border-width: 2px;
                        }

                        .select-search__option,
                        .select-search__not-found {
                            display: block;
                            height: 36px;
                            width: 100%;
                            background: #fff;
                            border: none;
                            outline: none;
                            font-family: "Noto Sans", sans-serif;
                            font-size: 14px;
                            text-align: left;
                            cursor: pointer;
                        }

                        .select-search--multiple .select-search__option {
                            height: 48px;
                        }

                        .select-search__option.is-selected {
                            background: #7399df;
                            color: #fff;
                        }

                        .select-search__option.is-highlighted.is-selected,
                        .select-search__option.is-selected:hover {
                            background: #528edd;
                            color: #fff;
                        }

                        .select-search__group-header {
                            text-transform: uppercase;
                            background: #fff;
                        }

                        .select-search.is-disabled {
                            opacity: 0.5;
                        }

                        .select-search.is-loading .select-search__value::after {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
                            background-size: 11px;
                        }

                        .select-search:not(.is-disabled) .select-search__input {
                            cursor: pointer;
                            letter-spacing: 0px;
                            font-weight: 600;
                        }

                        .select-search--multiple {
                            border-radius: 3px;
                            overflow: hidden;
                        }

                        .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
                            transform: rotate(45deg);
                            border-right: 3px solid #000;
                            border-bottom: 3px solid #000;
                            pointer-events: none;
                        }

                        .select-search--multiple .select-search__input {
                            cursor: initial;
                        }

                        .select-search--multiple .select-search__input {
                            border-radius: 3px 3px 0 0;
                        }

                        .select-search--multiple:not(.select-search--search) .select-search__input {
                            cursor: default;
                        }

                        .select-search:not(.select-search--multiple) .select-search__input:hover {
                            border-color: rgb(74, 127, 201);
                        }

                        .select-search:not(.select-search--multiple) .select-search__select {
                            position: absolute;
                            z-index: 2;
                            top: 44px;
                            right: 0;
                            left: 0;
                            border-radius: 8px;
                            overflow: auto;
                            max-height: 360px;
                            background: #fff;
                        }

                        .select-search--multiple .select-search__select {
                            position: relative;
                            overflow: auto;
                            max-height: auto;
                            border-top: 1px solid #eee;
                            border-radius: 0 0 3px 3px;
                            background: #fff;
                        }

                        .select-search__not-found {
                            height: auto;
                            padding: 8px;
                            text-align: center;
                            color: rgb(244, 245, 247);
                        }
                    }

                    .assessBtns {
                        display: flex;
                        align-items: center;
                        flex: 0.3;
                        justify-content: flex-end;

                        .text-right {
                            display: flex;
                            text-align: right !important;

                            .saveCancTitle {
                                margin: 0px !important;
                                padding-left: 5px;
                            }
                        }
                    }
                }

                .card-body {
                    padding: 17px !important;
                }

                @media only screen and (max-width: 991px) {
                    .card-body {
                        padding: 12px !important;
                    }
                }
            }

            .containerDiv {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-self: center;
                align-items: center;
                margin-top: 100px;

                .assessArrowImage {
                    width: 14px;
                    height: 30px;
                    align-self: center;
                    margin-bottom: 5px;
                }

                .containerTextDiv {
                    width: 232px;
                    align-self: center;

                    .containerText {
                        align-self: center;
                        font-size: 20px;
                        text-align: center;
                        letter-spacing: 0px;
                        color: #aaaaaa;
                        opacity: 1;
                    }
                }
            }

            .nullTextContainerDiv {
                width: 100%;
                display: flex;
                padding-right: 272px;
                margin-top: 220px;
                justify-content: center;

                .assessArrowImageNull {
                    width: 30px;
                    height: 14px;
                    align-self: center;
                    margin-right: 10px;
                }

                .containerTextDiv {
                    width: 232px;
                    align-self: center;

                    .containerText {
                        align-self: center;
                        font-size: 20px;
                        text-align: center;
                        letter-spacing: 0px;
                        color: #aaaaaa;
                        opacity: 1;
                    }
                }
            }

            @media only screen and (max-width: 991px) {
                .header {
                    height: unset;
                }
            }

            .add {
                width: 9rem;
                height: 2.5rem;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box !important;
                border-radius: 8px !important;
                float: right;
            }

            .disabledAdd {
                width: 11rem;
                height: 2.5rem;
                background: transparent linear-gradient(270deg, #aec7ea 0%, #b6cae6 100%) 0% 0% no-repeat padding-box !important;
                border-radius: 8px !important;
                float: right;
                cursor: default;
            }

            .addIcon {
                width: 0.875rem;
                height: 0.875rem;
                margin-right: 0.5rem;
                margin-top: -0.1875rem;
            }

            .sub-header {
                min-height: 3.75rem;

                .card-body {
                    padding: 12px;
                }

                .title {
                    display: flex;
                    text-align: left;
                    font: normal normal normal 14px/19px Open Sans;
                    color: #222222;
                    margin-left: 10px !important;
                    width: 100%;

                    //css for date picker
                    .react-datepicker-wrapper {
                        margin-top: 9px !important;
                        cursor: pointer;
                    }

                    .react-datepicker__header {
                        background-color: #ffffff;
                    }

                    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
                        border-bottom-color: #ffffff;
                    }

                    //css for date picker

                    .calendarIcon {
                        margin: auto 0;
                    }

                    .date {
                        margin: 11px 11px 10px 10px !important;
                        // display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 13%;
                    }

                    .calendarIcon {
                        margin-right: 10px;
                    }

                    .thumbIcon {
                        position: absolute;
                        right: 12px;
                        cursor: pointer;
                    }

                    .input {
                        width: 574px;
                        height: 40px;
                        background: #f4f6fa 0% 0% no-repeat padding-box;
                        border: 0.75px solid #dddddd;
                        border-radius: 5px;
                        font: normal normal 600 14px/19px Open Sans;
                        letter-spacing: 0px;
                        color: #222222;
                        font-weight: 600;
                        margin: auto 0;
                        margin-right: 10px;
                        outline: none !important;
                        position: relative;
                        padding-right: 43px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    input::placeholder {
                        font: italic normal normal 13px/18px Open Sans;
                        letter-spacing: 0px;
                        color: #c6b6b6 !important;
                    }

                    @media only screen and (max-width: 1220px) {
                        .input {
                            width: 574px;
                        }
                    }

                    @media only screen and (max-width: 991px) {
                        .input {
                            width: 574px;
                        }

                        .thumbIcon {
                            right: 12px;
                        }
                    }
                }
            }
        }

        .right-section ::-webkit-scrollbar {
            display: none !important;
        }
    }

    @media only screen and (max-width: 520px) {
        .left-section {
            padding: 0 !important;
        }

        .right-section {
            padding: 0 !important;
        }
    }

    @media only screen and (max-width: 767px) {
        .left-section {
            padding: 0.9375rem !important;
        }

        .right-section {
            padding: 0.9375rem !important;
        }
    }
}

.formative-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.formative-modal-warning .modal-content {
    border-radius: 8px;
    width: 412px;
    height: 329px;
    margin: auto;
    background-color: #fff !important;
}

.formative-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}

.formative-modal-warning .modal-content .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: normal normal normal 19px/26px Open Sans;
    padding-bottom: 0px;

    .warningIcon {
        width: 60px;
        height: 60px;
    }

    .waitTitle {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 24px/33px Open Sans;
        font-weight: 600;
        color: #ff1f1f;
    }

    .modalText {
        width: 256px;
        height: 52px;
        padding-top: 25px;
    }
}

.formative-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}

.formative-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}

.formative-modal-warning .getReport-btn {
    width: 120px;
    height: 54px;
    color: white;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 18px/24px Open Sans;
}

.formative-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal normal 19px/26px Open Sans;
    color: #222222 !important;
    width: 120px;
    height: 54px;
}

.formativeClass-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.formativeClass-modal-warning .modal-content {
    border-radius: 8px;
    width: 412px;
    height: 329px;
    margin: auto;
    background-color: #fff !important;
}

.formativeClass-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}

.formativeClass-modal-warning .modal-content .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: normal normal normal 19px/26px Open Sans;
    padding-bottom: 0px;

    .warningIcon {
        width: 60px;
        height: 60px;
    }

    .waitTitle {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 24px/33px Open Sans;
        font-weight: 600;
        color: #ff1f1f;
    }

    .modalText {
        width: 302px;
        height: 52px;
        padding-top: 0px;
    }
}

.formativeClass-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}

.formativeClass-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}

.formativeClass-modal-warning .getReport-btn {
    width: 120px;
    height: 54px;
    color: white;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 18px/24px Open Sans;
}

.formativeClass-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal normal 19px/26px Open Sans;
    color: #222222 !important;
    width: 120px;
    height: 54px;
}

@media only screen and (max-width: 1300px) {
    .assessmentMainContainer .card-body {
        padding: 0.8rem;
    }
}

@media only screen and (max-width: 1220px) {
    .assessmentMainContainer {
        padding: 0rem 1rem;
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1920px) {
    .assessmentMainContainer {
        .blockSection {
            .right-section {
                .sub-header {
                    .title {
                        .date {
                            width: 9%;
                        }

                        .thumbIcon {
                            right: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1499px) {
    .assessmentMainContainer {
        .blockSection {
            .right-section {
                .sub-header {
                    .title {
                        .date {
                            width: 12%;
                        }

                        .thumbIcon {
                            right: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 770px) and (max-width: 996px) {
    .assessmentMainContainer {
        .blockSection {
            .right-section {
                .sub-header {
                    .title {
                        .date {
                            width: 22%;
                        }

                        .thumbIcon {
                            right: 12px;
                            top: 20%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1920px) {
    .assessmentMainContainer {
        height: 50rem;

        .blockSection {
            height: 50rem;

            .left-section {
                .leftCol {
                    height: 48.5rem;
                }
            }

            .right-section {
                height: 49.7rem;

                .studentsDivAssessment {
                    height: 49.25rem;
                    overflow-y: scroll;
                }

                .nullTextContainerDiv {
                    padding-right: 54px !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1499px) {
    .assessmentMainContainer {
        height: 55rem;

        .blockSection {
            height: 50rem;

            .left-section {
                .leftCol {
                    height: 49rem;
                }
            }

            .right-section {
                height: 50rem;

                .studentsDivAssessment {
                    height: 49.55rem;
                    overflow-y: scroll;
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1299px) {
    .assessmentMainContainer {
        height: 29rem;

        .blockSection {
            height: 50rem;

            .left-section {
                .leftCol {
                    height: 48.8rem;
                }
            }

            .right-section {
                height: 49.9rem;

                .studentsDivAssessment {
                    height: 49.55rem;
                    overflow-y: scroll;
                }
            }
        }
    }
}

@media screen and (max-height: 768px) {
    .assessmentMainContainer {
        height: 39.3rem !important;

        .blockSection {
            height: 34.5rem;

            .left-section {
                .leftCol {
                    height: 33.1rem;
                }

                .tagsFormDiv {
                    overflow-y: scroll;
                    height: 28.8rem;
                }
            }

            .right-section {
                height: 33.9rem;

                .studentsDivAssessment {
                    height: 33.8rem;
                    overflow-y: scroll;
                }
            }
        }
    }
}
.studentsDivAssessment {
    overflow-x: hidden;
}

.addpg {
    
}

.nrmlpg {
    height: 30rem;
}
.addAssessmentGreenBgColor {
    background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 8px!important;
    width: 5rem;
    height: 2.3rem !important;
    // border-radius: 2rem !important;
    // background: transparent linear-gradient(270deg, #EC4444 0%, #FA6060 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: 0.1rem 0rem 0.1rem 0rem;
}
.modalSaveTagButton {
    border-radius: 8px!important;
}
@media only screen and (min-width: 1500px) and (max-width: 1920px) {
    .addpg {
        height: 44rem !important;
    }
}

@media screen and (max-height: 768px) {
    .addpg {
        height: 29rem !important;
    }
}

@media only screen and (min-width: 1600px) {
    .addpg {
        height: 44rem !important;
    }
}

@media only screen and (min-width: 2000px) {
    .addpg {
        height: 42rem !important;
    }
    .leftCol {
        height: 46.2rem !important;
    }

    .nrmlpg {
        height: 47rem !important;
    }
}