.executionContainer {
    overflow-y: scroll;
    min-height: 600px;

    .planTitleDiv {
        width: 100%;
        min-height: 85px;
        display: flex;
        background-color: white;
        border-radius: 20px;

        .algnDivs {
            flex: 0.5;
            padding: 10px;

            .planTitle {
                flex: 0.45;
                padding: 10px;
                display: flex;

                .headTitlesPlan {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                }

                .headTitles {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                    padding-left: 5px;
                }
            }
        }
    }

    .allSubDivs {
        height: 256px;

        .rationalDiv {
            padding-top: 10px;

            .rationalTitleBox {
                background-color: #669fed;
                height: 42px;
                border-radius: 10px;
                display: flex;
                align-items: center;

                .rationalTitle {
                    padding-left: 10px;
                    margin-bottom: 0px;
                    color: white;
                    font-size: 0.98rem;
                    font-weight: 600;
                }
                .backendQuestionCaps {
                    text-transform: uppercase;
                }
            }

            .rationalInputBox {
                margin: 0rem 0.9rem;
                border: none;
                border-radius: 10px;
                min-height: 70px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                padding-left: 10px;
                background-color: white;
                display: flex;
                outline: none;

                .rationalTextData {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                    outline: none;
                }

                .rationalInputText {
                    width: 100%;
                    border: none;
                    border-radius: 10px;
                    min-height: 70px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    padding-left: 10px;
                    outline: none;
                }

                textarea::placeholder {
                    font-style: italic;
                    font-size: 0.95rem;
                }
            }
        }

        .rationalDiv:last-child {
            margin-bottom: 10px !important;
        }
    }
}

@media only screen and (min-width: 357px) and (max-width: 820px) {
    .planningContainer {
        .planTitleDiv {
            flex-wrap: wrap;
        }
    }
}

@media only screen and (min-width: 357px) and (max-width: 577px) {
    .planningContainer {
        .planScheduleDiv {
            flex-wrap: wrap;
        }
    }
}

.executionContainer::-webkit-scrollbar {
    width: 22px;
    height: 50px;
    border-radius: 2px !important;
    position : 'relative';
    top: 5px;
    display: block !important;
    width: 3px;
    height: 10px !important;
    // background: transparent;
 
 }
.executionContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    height: 10px;
  }