.MuiTabs-flexContainer {
  width: 100px !important;
}
.MuiTab-root {
  min-width: 250px !important;
  margin: 0 48px !important;
  text-transform: capitalize !important;
}
.MuiTab-root:focus {
  outline: none !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #386CB5 !important;
}
.PrivateTabIndicator-colorPrimary-2 {
  background-color: #386CB5 !important;
}
.MuiPaper-elevation4 {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 4px #2222221A !important;
}


.noCat{
  margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: gray;
}

@media only screen and (max-width: 767px) {
  .MuiTab-root {
    min-width: 152px !important;
  }
}
