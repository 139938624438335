body {
    background-image: linear-gradient(to bottom, #ecf3fc, #FAFAFA);
}

.classRosterContainer {
    background: #F0F0F0;
    min-height: 83vh;
    overflow-x: hidden;
    padding: 0rem 2.5rem 1rem 2.5rem;
}

.displayFlexClassRoster {
    display: flex;
    cursor: pointer;
}
.classRostersingleClassYearDiv {
    width: auto!important;
    justify-content: inherit!important;
    margin-left: 15px;
}
.totalStudentCardMarginTop {
    margin-top: 15px;
}
.classRosterSearchFilter {
    margin-left: 20px;
}
.singleClassRosterCardDiv {
    max-width: 405px;
}
.rosterFileter-CR {
    padding: 0;
    margin-right: 0;
    border-right: none;
}
.singleClassPopoverParentDiv {
    position: relative;
    .singleClassLevelEditField {
        height: 50px;
    }
        
}

.singlClassPopover-RD {
    top : -62px!important;
    left : 43px !important;
    border: none!important;
    width: 260px !important;
}
.singlClassPopover-RD::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 33px;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff; /* adjust color and size as needed */
  }
 .singleClassPopoverParentDiv-CR {
    top: 26px !important;
    left: -225px !important;
    border: none !important;
    width: 260px !important;

    &::before {
        content: "";
        position: absolute;
        left: 91%;
        top: -10px;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-bottom: 10px solid #fff;
        border-right: 10px solid transparent;
    }
 } 
 .sectionGradeHeader {
    font: normal normal 600 15px/22px Open Sans!important;
    color: #222!important;
    opacity: 1!important;
 }
 .sortByText-CR {
    color: #222222;
    opacity: 0.4;
    font: normal normal 600 15px/22px Open Sans;
 }
.loaderNewRoster {
    width: 8rem !important;
    height: 2.5rem !important;
    border-radius: 2rem !important;
    padding: .6rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .1rem .2rem .1rem .2rem;
}
.loaderNewRoster-CR {
    border-radius: 8px!important;
 }

/* -------ClassRoster Year MainDiv------- */
.ClassRosterYearMainDiv {
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
}

.classRosterDetailsMain {
    display: flex;
    align-items: 'center';
}

.rosterClassBackDiv {
    display: flex;
    padding: .8rem;
    background: #EEEEEE;
    width: 19rem;
    height: 3.1rem;
    cursor: pointer;
}

.rosterClassBactText {
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.rosterClassDetailTextDiv {
    margin-left: 1rem;
    border-left: 1px solid #eeeeee;
    padding: .3rem 0rem 0rem 1rem;
}

.rosterClassName {
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.rosterClassGrade {
    color: #888888;
    margin: 0rem .5rem 0rem 0rem;
    font-size: .8rem;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-222222);
text-align: left;
font: normal normal 600 13px/22px Open Sans;
letter-spacing: 0px;
color: #222222;
opacity: 0.4;
}


/* --------add Buttons--------- */
.addNewClassRoster {
    width: 7rem !important;
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: .6rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .1rem .3rem .1rem .3rem;
}

.opacityProp {
    cursor: default !important;
    opacity: 0.5 !important;
}

.addNewClassRosterIcon {
    width: .9rem;
    margin-top: .2rem;
}

.buttonClassRosterText {
    color: white;
    font-size: .9rem;
    margin-left: .5rem;
}

/* --------add Buttons--------- */

.studentProfileImage {
    width: 3.75rem;
    height: 3.75rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
}

/* --------Select Buttons--------- */
.selectedRosterButton {
    width: 9rem !important;
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: .6rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .1rem .3rem .1rem .3rem;
}

.selectedRosterIcon {
    width: .9rem;
    margin-top: .2rem;
}

.selectedRosterbuttonText {
    color: white;
    font-size: .9rem;
    margin-left: .5rem;
}

/* --------Select Buttons--------- */

/* --------Import Buttons--------- */
.importNewClassRoster {
    width: 7rem !important;
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: .6rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .1rem .3rem .1rem .3rem;
}

.importNewClassRosterIcon {
    width: .9rem;
    margin-top: .2rem;
}

.importButtonClassRosterText {
    color: white;
    font-size: .9rem;
    margin-left: .5rem;
}

/* --------Import Buttons--------- */

/* -------ClassRoster Year MainDiv------- */

// -------Class Roster Search-----

.searchButtonFilter {
    width: 3rem;
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: .6rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 1rem !important;
    margin: .1rem .3rem .1rem .3rem;
}

.buttonMarginTop .searchFilterIcon {
    cursor: pointer;
    color: #fff;
    width: 1rem;
    margin-top: .1rem;
}

.totalClassSearchDiv {
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: .5rem .5rem .5rem .5rem;
    margin-top: 1rem;
    border-radius: .5rem;
}

.totalClassSearchDiv .searchInput {
    border: none !important;
    width: 100%;
}

.totalClassSearchDiv .closeIconSearch {
    width: 1rem;
    cursor: pointer;
    margin: 1rem .5rem 0rem 0rem;
}

.totalClassSearchDiv .searchIconDiv {
    padding: 0rem .5rem 0rem .5rem !important;
}

.totalClassSearchDiv .searchStudentdiv {
    width: 92% !important;
    height: 29px !important;
    margin-top: .5rem !important;
    padding-left: 0rem !important;
    background: transparent;
    margin-bottom: .5rem !important;
    border: none !important;
    border-radius: .3rem !important;
    font-style: italic !important;
    display: inline-flex;
    float: left;
}

.totalClassSearchDiv input.middle:focus {
    outline-width: 0 !important;
}

.totalClassSearchDiv .searchIcon {
    color: gray !important;
}

.totalClassSearchDiv .searchStudent {
    background: transparent !important;
    border: none !important;
    width: 100% !important;
}

.totalClassSearchDiv input {
    outline: none;
}

// -------Class Roster Search-----


/* --------ClassRosterCard-------- */
.classRosterCardMainDiv {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.classRosterCardDiv {
    width: 17.3rem;
    height: 9.8rem;
    margin: 0rem .8rem .8rem 0rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.2rem 0.5rem #0000001A;
    border-radius: .5rem;
    opacity: 1;
    padding: .1rem .3rem .1rem .3rem;
}

/* --------ClassRosterCard-------- */

/* ---------Class Roster Info Cards------------ */
.classRosterDetailsCard {
    width: 100%;
    border-bottom: 1px solid #DDDDDD;
    height: 4.8rem;
    display: inline-flex;
    padding: .4rem 0rem 0rem 0rem;
    position: relative;
}

.classRosterProfileImageDiv {
    width: 4rem;
}

.DummyClassRosterProfileDiv {
    width: 4rem;
    height: 4rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    margin-top: 0rem;
    text-align: center;
    margin-left: .4rem;
}

.profileClassRosterDummyText {
    color: white;
    padding-top: 30%;
    text-transform: uppercase;
}

.imageDummyDivProfile-CR {
    width: 3.75rem;
    height: 3.75rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    text-align: center;
    opacity: .8;
}

.profileImageDummyText-CR {
    color: white;
    // padding-top: 26%;
    text-transform: uppercase;
    font-weight: 600;
}
.selectRosterH5 {
    margin-bottom: 0;
}


.classRosterTextInfoDiv {
    text-align: left;
    margin-left: 5px;

    [data-title] {
        font-size: .7rem;
        position: relative;
        cursor: pointer;
        margin-bottom: 0px !important;
    }

    [data-title]:hover::before {
        content: attr(data-title);
        position: absolute;
        bottom: -40px;
        padding: 5px;
        background: #000;
        color: #fff;
        font-size: .7rem;
        word-wrap: break-word;
        width: 220px;

    }
}

.classRosterName {
    margin: 0px;
    font-size: .9rem;
    font-weight: 600;
    color: #222222
}

.classRosterEmail {
    margin: .1rem;
    font-size: .7rem;
}

.classRosterEditicon {
    font-size: .9rem;
    margin-top: 2px;

}

/* ---------Class Roster Info Cards------------ */


/* ---------Class Roster options Cards------------ */
.classRosterAllOptionDiv {
    padding: .5rem 0rem 0rem 0rem;
    display: inline-flex
}

.classRosterOptionRemove {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #EC4444 0%, #FE6969 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
    cursor: pointer;
}

.classRosterOptionNote {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #747BEB 0%, #989EFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
    cursor: pointer;
}

.classRosterOptionGradebook {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #08B596 0%, #27D3B4 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
    cursor: pointer;
}

.classRosterOptionShare {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #4479C4 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
    cursor: pointer;
}

.classRosterOptionIcons {
    width: 1.2rem;
}

.classRosterOptionText {
    color: white;
    font-size: .6rem;
    font-weight: 600;
    margin-top: 0.3rem;
}

/* ---------Class Roster options Cards------------ */

/* ----Class Roster Edit level&Score Modal---- */
.levelModalMain {
    background: #FFFFFF;
    overflow: auto;
    border-radius: 2rem !important;
    padding: 2rem !important;
}

.levelEditModalHeader {
    width: 100%;
    text-align: center;
}

.levelEditModalHeadind {
    font-size: 1.2rem;
    font-weight: 600;
}

.levelEditmainFieldDiv {
    width: 100%;
    justify-content: space-between;
    display: inline-flex;
    padding-bottom: .5rem;
    border-bottom: 1px solid #DDDDDD;
}

.inputFieldLabelLevelEdit {
    padding-top: 1.9rem;
}

.inputFieldLabelLevelEditText {
    margin: 0rem;
    font-weight: 600;
    font-size: 1rem;
}

.levelMainDivForCenter {
    width: 100%;
    text-align: -webkit-center;
    margin-top: 1rem;
}

.levelModalCancelButtonDiv {
    width: 11rem;
    justify-content: space-between;
    display: flex;
}

.levelModalUpdateButton {
    width: 6rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .1rem 0rem .1rem 0rem;
    border-color: #386CB5 !important;
    cursor: pointer;
}

.levelEditUpdateButtonText {
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

/* ----Class Roster Edit level&Score Modal---- */

/* --------- Roster Student Delete options Cards------------ */
.deleteClassRosterCard {
    width: 17.3rem;
    height: 9.8rem;
    margin: 0rem .8rem .8rem 0rem;
    background: #000 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.2rem 0.5rem #0000001A;
    border-radius: .5rem;
    float: left;
    padding: 1rem 1rem 1rem 1rem;
    text-align: -webkit-center;
    opacity: .9;
}

.deleteClassRosterText {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1rem;
}

.deleteWorningClassRosterText {
    color: #FFFFFF;
    font-weight: 600;
    font-size: .8rem;
}

.deleteButtonsClassRosterDiv {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0rem 3rem 0rem 3rem;

}

.cancelButtonClassRosterText {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1rem;
    padding-top: .5rem;
    cursor: pointer;

}

.deleteButtonClassRoster {
    width: 5rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #EC4444 0%, #FA6060 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: .1rem 0rem .1rem 0rem;
    border-color: #EC4444 !important;
}

.deleteButtonClassRosterText {
    color: white;
    font-size: 1rem;
    font-weight: 600;

}

/* ---------Class Student Delete options Cards------------ */



/* --------------Add New Roster Modal--------- */
.rosterOfClass {

    .modal-content {
        border-radius: 0;
        border: none;
    }

    .modal-title {
        font-size: 13px;
    }

    .modal-header {
        border-bottom-color: #EEEEEE !important;
        background-color: #ffff !important;
        height: 56px !important;
        // height: 59px !important;
        // display: flex;
        // align-items: center;
    }

    .modal-header .close {
        padding: .5rem 1rem;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        outline: none !important;
    }

    .cancelText {
        font-size: .8rem !important;
        color: #000 !important;
    }

    .closeIconInModal {
        width: .6rem;
        margin-top: -.2rem;
    }

    .RosterImageMainDiv {
        width: 100%;
        height: 8rem;
        background-color: #F5F5F5 !important;
        text-align: -webkit-center;
        padding-top: 1.5rem;
        border-radius: 6px;
    }

    .RosterImageMainSelectedDiv {
        width: 100%;
        height: 9rem;
        background-color: #F5F5F5 !important;
        text-align: -webkit-center;
        padding-top: 1rem;
        background-size: cover;
        background-position: center;
    }

    .RosterImageWhenSelect {
        width: 100%;
        height: 100%;
        border-radius: 10rem;
    }

    .addStudentImageDiv {
        width: 5rem;
        height: 5rem;
        border-radius: 10rem;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DDDDDD;
    }

    .cameraIconCss {
        width: 2rem;
        margin-top: 1.5rem;
        cursor: pointer;
    }

    .loaderIconCss {
        width: 4.5rem;
        margin-top: 1.5rem;
    }

    .RosterImageMainSelectedDiv .changeButton {
        width: 4.2rem;
        height: 1.9rem;
        background: #000000 !important;
        border-radius: 1.5rem !important;
        margin-top: .5rem;
        opacity: .7;
        cursor: pointer !important;
        display: inline-grid;
    }

    .RosterImageMainSelectedDiv .changeButtonText {
        color: white;
        font-size: 10px;
        font-weight: 600;
        cursor: pointer !important;
        margin-top: .5px !important;

    }

    .loaderIconCssAlreadySelected {
        width: 2.5rem;
    }

    .allInputMainDiv {
        padding: 0rem 1.5rem 1rem 1.5rem;
    }

    .studentAddInputDiv {
        width: 100%;
        margin: 1.5rem 1rem 0rem 0rem
    }

    .studentAddInputDiv .MuiOutlinedInput-input {
        height: 32px !important;
        padding-top: 7px;
        padding-bottom: 7px;
        font: normal normal 600 14px/20px Open Sans;
        color: #222222;
        margin-bottom: 0px !important;
    }

    .studentInputLableInfo {
        color: #000;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0rem;
        text-align: left;
    }

    .studentAddInput {
        border: 0.75px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: .3rem;
        height: 3rem;
        background: #F4F6FA;
        width: 100%;
        margin-bottom: 2px;
    }

    .modalButtonClass {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 0rem 1rem 0rem;

    }



    .saveButton {
        width: 5rem !important;
        height: 2.4rem !important;
        border-radius: 2rem !important;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: .6rem !important;
        margin: .1rem 0rem .1rem 0rem;
    }

    .saveText {
        color: white;
        font-size: 1rem;

    }

    .addAnotherButton {
        width: 12rem !important;
        height: 2.4rem !important;
        border-radius: 2rem !important;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: .6rem !important;
        margin: .1rem 0rem .1rem 0rem;
    }
}

.classAddRosterButtonDiv {
    .classAddRosterButtonDiv-saveButton {
        border-radius: 8px!important;
    }
}

/* --------------Add New Roster Modal--------- */




/* --------------Upload Modal Roster style--------- */
.rosterImportOfClass {
    .modal-content {
        border-radius: 0;
        border: none;
    }

    .modal-title {
        font-size: 13px;
    }

    .modal-header {
        border-bottom-color: #EEEEEE !important;
        background-color: #ffff !important;
        height: 50px !important;
    }

    .modal-header .close {
        padding: .5rem 1rem !important;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        outline: none !important;
    }

    .cancelText {
        font-size: .8rem !important;
        color: #000 !important;
    }

    .closeIconInModal {
        width: .6rem;
        margin-top: -.2rem;
    }

    .uploadDocHeaderRosterDiv {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }

    .uploadNodeRosterDiv {
        text-align: left;
        background: #F5F5F5;
        padding: 1rem;
        width: 100%;
        position: absolute;
        bottom: 0rem;


    }

    .uploadClassNoteText {
        font-weight: 600;
        margin-bottom: 10px;
        margin: 0rem;
        font-size: .8rem;
    }

    .modal-header .close {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        outline: none !important;
    }

    .uploadNoteRosterText {
        margin: 0rem;
        font-size: .8rem;
    }

    .checkbozRosterCircle {
        height: 1rem;
        width: 1rem;
        border-radius: 10rem;
        border: .1rem solid #DDDDDD;
        text-align: -webkit-center;
        padding-top: 3px;
        cursor: pointer;
    }

    .checkbozCircleRosterSelected {
        height: .5rem;
        width: .5rem;
        border-radius: 10rem;
        background-color: #386CB5;
        padding-top: 3px;
    }

    .selectMainDivforRosterUpload {
        display: flex;
        padding: 1rem 0rem 0rem 0rem;
        width: 100%;
        justify-content: center;
    }

    .selectOptionInRosterTag {
        width: 8.7rem;
        height: 9rem;
        border: 1px solid #DDDDDD;
        text-align: -webkit-center;
        padding: .5rem;
        margin: .5rem;
    }

    .selectoptionUploadRosterImage {
        width: 2.7rem;
        margin: .7rem 0rem .6rem 0rem;
    }

    .selectoptionUploadImageforRosterDrop {
        width: 2.3rem;
        margin: .7rem 0rem .7rem 0rem;
    }

    .autharizedRosterButton {
        width: 4.5rem !important;
        height: 1.4rem !important;
        border-radius: 8px !important;
        padding: .2rem !important;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: .6rem !important;
        margin: .6rem 0rem .1rem 0rem;
    }
    .loaderNewRoster-CR {
        border-radius: 8px!important;
     }

    .autharizedRosterText {
        color: white;
        font-size: .65rem;

    }

    .selectTagRosterText {
        color: #222222;
        font-size: .85rem;
        margin: 0rem;
        font-weight: 600;
    }

    .importRosterRosterButton {
        width: 6.3rem !important;
        height: 2.3rem !important;
        border-radius: 2rem !important;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: .6rem !important;
    }

    .importRosterRosterText {
        color: white;
        font-size: 1rem;

    }
}

/* --------------Upload Modal style--------- */




/* -----------------------------------Side Menu--------------------------- */




@media only screen and (min-width: 520px) and (max-width: 890px) {
    .classRosterContainer {
        padding: 0rem .5rem 1rem .5rem;
    }

    .ClassRosterYearMainDiv {
        flex-wrap: wrap;
        justify-content: center;
    }

    .buttonMarginTop {
        margin-top: 1rem;
    }




}

@media only screen and (min-width: 250px) and (max-width: 520px) {
    .classRosterContainer {
        padding: 0rem
    }

    .ClassRosterYearMainDiv {
        flex-wrap: wrap;
        justify-content: center;
    }

    .buttonMarginTop {
        margin-top: 1rem;
    }

    .classRosterDetailsMain {
        display: block;
    }

    .classRosterDetailsMain {
        width: 100%;
    }

    .rosterClassBackDiv {
        width: 100%;
    }

    .rosterClassDetailTextDiv {
        margin-left: 1rem;
        border-left: none;
        padding: 1rem 0rem 0rem 0rem;
    }


}

.selectClass-DriveFile-modal {
    margin-top: 3rem;
}

.selectClass-DriveFile-modal .importDriveLoader {
    height: 100px;
    width: 190px;
    margin-top: 30%;
}

.selectClass-DriveFile-modal .modal-title {
    font: normal normal 600 1rem/1.375rem Open Sans !important;
}

.selectClass-DriveFile-modal .modal-content {
    height: 35rem !important;
}

.selectClass-DriveFile-modal .file_listDiv {

    width: 100%;
    height: 40px;
    margin-top: 13px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #EEEEEE;
}

.selectClass-DriveFile-modal .modal-header {
    padding: .8rem 1rem 0;
    border-bottom: none;
    border-radius: 0.5rem;
}

.selectClass-DriveFile-modal textarea {
    font: normal normal normal 0.875rem/1.188rem Open Sans;
}

.selectClass-DriveFile-modal .selectedText {
    color: #41cc3b;
    font-weight: 600;
}

.selectClass-DriveFile-modal .unSelectedText {
    font-weight: 600;
    cursor: pointer;
}

.selectClass-DriveFile-modal .modal-footer {
    justify-content: center;
    border-top: none;
    padding-top: 0;
}

.selectClass-DriveFile-modal .modal-footer button {
    width: 5rem;
    height: 5rem;
    border-radius: 1.25rem;
    padding: 0;
}

.selectClass-DriveFile-modal .modal-footer button img {
    margin-top: -0.1875rem;
    margin-left: 0.0625rem;
}

.selectClass-DriveFile-modal .modal-content {
    border-radius: 0.5rem;
    background: #fff !important;
}

.selectClass-DriveFile-modal .modalcancleTagButton {
    width: 2.3rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #EC4444 0%, #FA6060 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    // margin: .1rem 1rem .1rem 1rem;
    border-color: #EC4444 !important;
}

.selectClass-DriveFile-modal .modalSaveTagButton {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: 0rem;
    border-color: #23BF1D !important;
}

.selectClass-DriveFile-modal .checkBoxSelect {
    color: #386cb5;
    cursor: pointer;
    font-size: 1rem;
    margin-top: .5rem;
    margin-right: .2rem;
}

.selectClass-DriveFile-modal .unCheckBoxSelect {
    cursor: pointer;
    font-size: 1rem;
    margin-top: .5rem;
    margin-right: .2rem;
}

.selectClass-DriveFile-modal .selectoptionUploadFolder {
    width: 2rem;
    margin: 0rem .5rem 0rem 0rem;
}

.selectClass-DriveFile-modal .fileName {
    margin-top: .6rem;
}

.selectClass-DriveFile-modal .driveBackIcon {
    font-size: 1.3rem;
    cursor: pointer;
}

.selectClass-DriveFile-modal .cursor {
    cursor: pointer;
}

.selectClass-DriveFile-modal .inline-Path {
    display: inline-flex;
    height: 30px;
}

.selectClass-DriveFile-modal .selectFileCursor {
    display: inline-flex;
    cursor: pointer
}

.selectClass-DriveFile-modal .modal-list-body {
    height: 25rem !important;
    overflow: auto !important;
}

ul.breadcrumb {
    padding: 0px 0px;
    margin: 0px;
    list-style: none;
    background-color: transparent;
}

ul.breadcrumb li {
    display: inline;
    font-size: 14px;
    font-weight: 600;
}

ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
}

ul.breadcrumb li a {
    color: #386CB5;
    text-decoration: none;
}

ul.breadcrumb li a:hover {
    color: #669FED;
    text-decoration: underline;
    cursor: pointer;
}

.schltchroption {
    display: none !important;
}


.modal-header-add-student {
   
    border-bottom-color: #EEEEEE !important;
    background-color: #ffff !important;
    height: 59px !important;
    display: flex;
    align-items: center;



// .modal-header .close {
//     padding: .5rem 1rem;
//     margin: -1rem -1rem -1rem auto;
//     opacity: 1 !important;
//     color: #000 !important;
//     outline: none !important;
    .close {
        padding: 1rem 1rem;
        margin: 5rem 0rem 0rem auto !important;
        opacity: 1 !important;
        color: #000 !important;
        margin-top: -2px !important;
        outline: none;
    }
// }
}
.disShareOptionDiv-CRD {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #4479C4 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
}