.planningContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  // min-height: 600px;

  .planTitleDiv {
    flex-wrap: wrap;
    width: 100%;
    min-height: 115px;
    display: flex;
    background-color: white;
    border-radius: 20px;
    height: 120px;
    left: -4px;

    .planTitlePlanning {
      // flex: 0.45;
      padding: 20px;

      .headTitlesPlan {
        text-align: left;
        margin: 0px;
        font-size: 0.85rem;
        font-weight: 600;
        text-align: left;
        .headTitlesPlan__before {
          text-align: left;
          font: normal normal normal 11px/15px Open Sans;
          letter-spacing: 0px;
          color: #aaaaaa;
        }
      }

      .mailDiv {
        height: 40px;
        border-radius: 5px;
        border: 1px solid #cfcbcb;
        background-color: #f4f6fa;
        font-size: 0.95rem;
        padding-left: 10px;
        display: flex;
        margin-top: 15px;
        max-width: 180px;
        outline: none;
      }
    }

    .planTopic {
      flex: 0.45;
      padding: 20px;

      .headTitlesPlan {
        text-align: left;
        margin: 0px;
        font-size: 0.85rem;
        font-weight: 600;
        text-align: left;
      }

      .mailDiv {
        height: 40px;
        border-radius: 5px;
        border: 1px solid #cfcbcb;
        background-color: #f4f6fa;
        font-size: 0.95rem;
        padding-left: 10px;
        display: flex;
        margin-top: 15px;
        max-width: 180px;
        outline: none;
      }
    }

    .planStandards {
      display: flex;
      align-items: end;
      flex: 0.4;
      padding: 20px;

      @media screen and (max-width: 1620px) {
          display: block;
      }

      .headTitlesPlan {
        text-align: left;
        margin: 0px;
        font-size: 0.85rem;
        font-weight: 600;
        text-align: left;
      }
      .generateAIBtn {
        background: transparent
          linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat
          padding-box !important;
        border-radius: 8px !important;
        width: 140px;
        font: normal normal normal 14px/19px Open Sans !important;
        height: 2.5rem !important;
        border-radius: 8px !important;
        padding: 0.6rem !important;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: flex !important;
        justify-content: center !important;
        margin: 0.1rem 0.3rem 0.1rem 0rem;
        margin-top: 15px;
      }
      .addNewLesson {
        width: 9rem !important;
        height: 2.5rem !important;
        border-radius: 8px !important;
        padding: 0.6rem !important;
        background: transparent
          linear-gradient(270deg, #747beb 0%, #989eff 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: flex !important;
        justify-content: center !important;
        font: normal normal normal 14px/19px Open Sans !important;
        margin: 0.1rem 0.3rem 0.1rem 0rem;
        margin-top: 15px;
      }

      .viewAttText {
        color: white;
        font-size: 0.9rem;
        margin-left: 0.5rem;
      }

      .lessonIcon {
        width: 0.9rem;
        margin-top: 0.2rem;
      }
    }
  }

  .planScheduleDiv {
    flex-wrap: wrap;
    width: 100%;
    min-height: 115px;
    display: flex;
    background-color: white;
    border-radius: 20px;
    // margin: 0px 0px 5px;
    min-height: 120px;
    right: -4px;
    .planDateTitle {
      flex: 0.45;
      padding: 20px;

      .headTitlesPlan {
        text-align: left;
        margin: 0px;
        font-size: 0.85rem;
        font-weight: 600;
        text-align: left;
      }

      .dateSubBlock {
        border: 1px solid #dddddd;
        background: #f4f6fa;
        border-radius: 5px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #cfcbcb;
        background-color: #f4f6fa;
        font-size: 0.95rem;
        padding-left: 10px;
        display: flex;
        margin-top: 15px;
        max-width: 186px;
        outline: none;
        align-items: center;

        .react-datepicker-wrapper {
          cursor: pointer;
        }

        .dayDateTextDiv {
          width: 239px;

          .dayDateText {
            text-align: left;
            margin-bottom: 0px !important;
            font: normal normal 600 16px/24px Open Sans;
          }
        }

        .calMargin {
          margin: 4px 0px 4px 0px;
        }
      }
    }

    .planDateTitlePlanning {
      flex: 0.45;
      padding: 20px;
      max-width: 250px;

      @media screen and (max-width: 1620px) {
        max-width: 25%
    }

      .headTitlesPlan {
        text-align: left;
        margin: 0px;
        font-size: 0.85rem;
        font-weight: 600;
        text-align: left;
      }

      .dateSubBlock {
        border: 1px solid #dddddd;
        background: #f4f6fa;
        border-radius: 5px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #cfcbcb;
        background-color: #f4f6fa;
        font-size: 0.95rem;
        padding-left: 10px;
        display: flex;
        margin-top: 15px;
        max-width: 186px;
        outline: none;
        align-items: center;

        .react-datepicker-wrapper {
          cursor: pointer;
        }

        .dayDateTextDiv {
          width: 239px;

          .dayDateText {
            text-align: left;
            margin-bottom: 0px !important;
            font: normal normal 600 16px/24px Open Sans;
          }
        }

        .calMargin {
          margin: 4px 0px 4px 0px;
        }
      }
    }

    .planNotification {
    //  max-width: 33.333333%;
    max-width: 41.666667%;;
      flex: 0.75;
      // padding: 20px;
      padding: 20px 0;
      display: grid;

      
      @media screen and (max-width: 1680px) {
        max-width: 41.666667%;
    }

      .headTitlesPlan {
        text-align: left;
        margin: 0px;
        font-size: 0.85rem;
        font-weight: 600;
        text-align: left;
        .headTitlesPlan__before {
          text-align: left;
          font: normal normal normal 12px/15px Open Sans;
          letter-spacing: 0px;
          color: #aaaaaa;
        }
      }

      .notificationSubBlock {
        // padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: baseline;
        @media screen and (max-width : 1537px) {
          padding-top: 0;
        }

        .filterTimeDiv {
          // float: left;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // margin: auto 0.3rem;
          // padding: 0 0.5rem;
          // width: 85px;

          .filterTimeDiv__filterText {
            border: solid 1px #444444;
            border-radius: 22px;
            width: 60px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            font: normal normal 500 12px/17px Open Sans;
            color: #444444;
          }
          .filterTimeDiv__filterText_fill {
            border: solid 1px #386CB5 ;
            background: #588FDC1A 0% 0% no-repeat padding-box;
            border-radius: 22px;
            width: 60px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            font: normal normal 600 12px/17px Open Sans;
            color : #386CB5
          }

          .filterText {
            font-size: 0.85rem;
            color: #000;
            margin: auto 0.375rem;
          }
        }

        .checkBoxfilter {
          color: #386cb5;
          cursor: pointer;
          font-size: 21px;
          margin-right: 0.2rem;
        }

        .unCheckBoxfilter {
          cursor: pointer;
          font-size: 21px;
          margin-right: 0.2rem;
        }

        .fixedTimeDiv {
          .filterTimeDiv {
            float: left;
            display: flex;
            // align-items: center;
            // justify-content: center;
            // margin: auto 0.3rem;
            // padding: 0 0.5rem;
            width: 85px;
            .filterText {
              font-size: 0.85rem;
              color: #000;
              margin: auto 0.375rem;
            }
          }
        }
      }

      .fixedTimeDiv {
        display: flex;
        padding-left: 12px;
        padding-top: 15px;

        .filterFixedTimeDiv {
          display: flex;

          .filterText {
            font-size: 0.85rem;
            color: #000;
            margin: auto 0.575rem;
          }

          .filterTime {
            color: #386cb5;
            font-size: 0.85rem;
            margin: auto 0.175rem;
          }

          .react-datepicker-wrapper {
            .react-datepicker__input-container {
              .remIcon {
                margin-bottom: 2px;
                margin-left: 5px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .allSubDivs {
    height: 256px;

    .rationalDiv {
      padding-top: 10px;

      .rationalTitleBox {
        background-color: #669fed;
        height: 42px;
        border-radius: 10px;
        display: flex;
        align-items: center;

        .rationalTitle {
          padding-left: 10px;
          margin-bottom: 0px;
          color: white;
          font-size: 0.98rem;
          font-weight: 600;
        }
        .backendQuestionCaps {
          text-transform: uppercase;
        }
      }

      .rationalInputBox {
        margin: 0rem 0.9rem;

        .rationalInputText {
          width: 100%;
          border: none;
          border-radius: 10px;
          min-height: 70px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          padding-left: 10px;
          outline: none;
        }

        textarea::placeholder {
          font-style: italic;
          font-size: 0.95rem;
        }

        .audio-sec {
          display: flex;
          padding-top: 10px;
          padding-bottom: 5px;
          background-color: white;
          width: 100%;
          border: none;
          border-radius: 10px;
          min-height: 70px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          padding-left: 10px;
        }

        .add-media {
          float: left;
          margin-top: 1px;
        }

        .btn-add-media {
          height: 3.938rem;
          padding: 0 !important;
          border: 0 !important;
          background: transparent !important;
          margin-right: 0.75rem !important;
          outline: none;
          width: 60px;
        }

        .btn-add-media input[type="file"] {
          opacity: 0;
          outline: none;
          // left: -55px;
          position: relative !important;
          cursor: pointer;
          top: -52px;
          // width: 0;
        }
      }
    }

    .rationalDivUrls {
      padding-top: 10px;

      .rationalTitleBox {
        background-color: #669fed;
        height: 42px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .rationalTitle {
          padding-left: 10px;
          margin-bottom: 0px;
          color: white;
          font-size: 0.98rem;
          font-weight: 600;
        }

        .rationalSubTitle {
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 0px;
          color: white;
          font-size: 0.88rem;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .rationalInputBox {
        margin: 0rem 0.9rem;
        height: auto;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        .rationalInputTextDiv {
          display: flex;
          justify-content: space-between;
          padding: 10px;

          .rationalInputText {
            width: 80%;
            border: none;
            border: 1px solid #dddddd;
            height: 30px;
            padding-left: 10px;

            background: #f4f6fa;
            border-radius: 5px;
            color: black;
            font-size: 0.88rem;
            font-weight: 600;
          }
        }

        input::placeholder {
          font-size: 0.95rem;
          font-weight: 200;
          color: rgb(206, 198, 198);
        }

        input[type="text"]:focus,
        textarea:focus {
          outline: none;
        }
      }
    }
  }
}

.add-medias-modal .modal-title input {
  font: normal normal 600 0.875rem/1.0625rem Open Sans !important;
  padding: 0;
  height: 26px;
  border: none;
  box-shadow: none;
  width: 100%;
}

.add-medias-modal .btn {
  padding: 0 5px 0 0;
}

.add-medias-modal .btn:focus {
  box-shadow: none !important;
}

.add-medias-modal .fa-pencil {
  color: #aaaaaa;
  background-color: white;
}

.add-medias-modal {
  margin-top: 5rem;
}

.add-medias-modal .modal-title .pencilBtn {
  background-color: white;
  outline: none;
}

.add-medias-modal .modal-title {
  font: normal normal 600 1rem/1.375rem Open Sans !important;
  margin-bottom: 0.625rem;
  width: 100%;
  display: inline-flex;
}

.add-medias-modal .modal-header {
  padding: 0.8rem 1rem 0;
  border-radius: 0.5rem;
}

.add-medias-modal .modal-body {
  margin-top: 0.375rem;
  justify-content: center;
  min-height: 9.6875rem;
}

.add-medias-modal .timer_Div {
  width: 20px;
  position: absolute;
  right: 50%;
  // top: 10px;
}

.add-medias-modal .modal-footer {
  justify-content: center;
}

.add-medias-modal .modal-footer button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0;
}

.add-medias-modal .modal-footer .dangerCrossBtn {
  /* margin-right: 1.5rem; */
  background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0%
    0% no-repeat padding-box;
}

.add-medias-modal .modal-footer .btn-danger {
  /* margin-right: 1.5rem; */
  background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0%
    0% no-repeat padding-box;
}

.add-medias-modal .modal-footer .sucessBtn {
  background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0%
    0% no-repeat padding-box;
}

.add-medias-modal .modal-footer button img {
  margin-top: -0.1875rem;
  margin-left: 0.0625rem;
}

.add-medias-modal .modal-footer .deleteIcon img {
  margin-top: -0.0625rem;
  margin-left: -0.0625rem;
}

.add-medias-modal .modal-content {
  border-radius: 0.5rem;
  background-color: #fff !important;
}

.voice-recorder {
  width: 100%;
  height: 3.875rem;
}

.record-btn {
  position: relative;
  cursor: pointer;
}

.record-btn .btn {
  background: transparent;
  border: none;
}

.record-info {
  font: normal normal normal 0.625rem/0.875rem Open Sans;
  color: #888888;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.record-btn .btn-primary:not(:disabled):not(.disabled).active,
.record-btn .btn-primary:not(:disabled):not(.disabled):active,
.record-btn .btn-primary:hover,
.record-btn .show > .btn-primary.dropdown-toggle,
.record-btn .btn-primary.focus,
.record-btn .btn-primary:focus {
  color: #fff;
  background-color: none;
  border-color: none;
  box-shadow: none;
}

.record-btn .stopIcon:hover {
  background: none !important;
}

.record-btn .playAfterPause {
  width: 42px;
  height: 42px;
  border: 1px solid black !important;
  border-radius: 50px;

  .playIconPause {
    margin-left: 2px;
  }
}

.record-btn .recordOnOff:hover {
  background: none !important;
}

.display-none {
  display: none !important;
}

.timer {
  top: 2.0625rem;
  font: normal normal 600 0.875rem/1.188rem Open Sans;
  letter-spacing: 0rem;
  color: #222222;
}

.level-score-sec {
  border-left: 0.0625em solid #cccccc;
  margin-bottom: 0;
  text-align: right;
}

.level-score-sec .sub-div {
  display: inline-flex;
  line-height: 2.5em;
}

.level-score-sec .sub-div input {
  width: 100%;
  margin-left: 0.625em;
  background: #f4f6fa 0% 0% no-repeat padding-box;
  border: 0.0469em solid #cccccc;
  font: normal normal normal 0.875em/1.1875em Open Sans;
  color: #000 !important;
}

.multimedia-suggestion {
  font: italic normal normal 13px/18px Open Sans !important;
  margin: auto 0 !important;
  color: #aaaaaa !important;
}

.audio-suggestion {
  float: right;
  font: italic normal normal 13px/18px Open Sans;
  color: #aaaaaa;
}

.played-video-modal .modal-header {
  justify-content: flex-end;
  border: none !important;

  .delete-btn {
    background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0%
      0% no-repeat padding-box;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;
    padding: 0;
    border: none !important;
  }
}

.played-video-modal .modal-footer {
  justify-content: center;
  border: none !important;
}

.played-video-modal .modal-dialog {
  justify-content: center !important;
  display: flex;
}

.played-video-modal .modal-dialog {
  max-width: 750px;
  justify-content: center !important;
  display: flex;
}

.played-video-modal .modal-footer button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0;
}

.played-video-modal .modal-content {
  border-radius: 0.5rem;
  background-color: #fff0 !important;
  border: none !important;
  background-color: #fff0 !important;
  // width: 750px;
  // height: 550px !important;
}

.played-video-modal .modal-footer .btn-danger {
  background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0%
    0% no-repeat padding-box;
}

.played-video-modal .modal-footer button img {
  margin-top: -0.1875rem;
  margin-left: 0.0625rem;
}

.played-video-modal .modal-body video {
  width: 100%;
}

.played-video-modal .modal-body {
  border: 12px solid #e9e9e9;
  padding: 0px !important;
}

.playIcon {
  cursor: pointer;
}

.starSelected {
  cursor: pointer;
  color: #ffa700;
  font-size: 20px;
}

.starUnSelected {
  cursor: pointer;
}

.audio-style {
  float: left;
  margin-bottom: 10px;
  background: #f1f3f4;
  box-shadow: 0 0 0.1875rem #0000004a;
  border-radius: 4px;
  margin-right: 10px;
}

#assign-popover-box {
  background: black;
  padding-bottom: 0 !important;
  max-width: 151px;
  min-width: 101px;
  width: 100% !important;
}

#assign-popover-box .arrow::after {
  border-right-color: #000;
}

#assign-popover-box .popover-body {
  color: white;
}

@media only screen and (min-width: 357px) and (max-width: 820px) {
  .planningContainer {
    .planTitleDiv {
      flex-wrap: wrap;
    }
  }
}

@media only screen and (min-width: 357px) and (max-width: 577px) {
  .planningContainer {
    .planScheduleDiv {
      flex-wrap: wrap;
    }
  }
}

.abc {
  justify-content: unset;
  width: 101%;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: flex;
  position: absolute;
  top: 20px;
  left: -72px;
}
.react-datepicker__triangle {
  left: 29px !important;
}
.doc-modal-warning {
  .Planning-header {
    justify-content: flex-end !important;
  }
}
.played-video-modal {
  .Planning-header {
    justify-content: flex-end !important;
  }
}
.add-medias-modal {
  .Planning-header {
    justify-content: flex-end !important;
  }
}

.planningContainer::-webkit-scrollbar {
  overflow-y: scroll;
  width: 22px;
  height: 50px;
  border-radius: 2px !important;
  position: "relative";
  top: 5px;
  display: block !important;
  width: 3px;
  // background: transparent;
}
.planningContainer::-webkit-scrollbar-thumb {
  background-color: #888;
}
.generateDataModal {
  .modal-content {
    // height: 360px;
  }
}
.pleaseWaitText {
font: italic normal normal 13px/18px Open Sans;
letter-spacing: 0px;
color: #386CB5;
opacity: 1;
}
.prepareText {
  font: normal normal 600 16px/22px Open Sans;
letter-spacing: 0px;
color: #222222;
opacity: 1;
}
.dataPara {
  font: normal normal normal 14px/19px Open Sans;
letter-spacing: 0px;
color: #AAAAAA;
span {
  font: normal normal 600 14px/19px Open Sans;
letter-spacing: 0px;
color: #386CB5;
}
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #444444;
  color: #444444;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #444444 ;
  color: #444444 ;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #444444 ;
  color: #444444 ;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #444444 ;
  }
  50%, 100% {
    background-color: #DADADA 
  }
}


.sk-fading-circle {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}