.addNewRubricsContainer {
  background: #f0f0f0;
  height: 100%;
  overflow-x: hidden;
  padding: 0rem 2.5rem 0rem 2.5rem;

  .addRubricsMainDiv {
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a;
    border-radius: 0px 0px 8px 8px;
    opacity: 1;
    height: 80px;

    .RubricsDetailsMain {
      display: flex;
      align-items: center;
      flex: 2;

      .ClassBackDiv {
        // display: flex;
        // // padding: 5px 7px;
        // width: 40px;
        // height: 40px;
        // cursor: pointer;
        // // background: transparent linear-gradient(90deg, #669FED 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
        // border-radius: 6px;
        // opacity: 1;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem;
        background: transparent linear-gradient(90deg, #669fed 0%, #386cb5 100%)
          0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        cursor: pointer;
      }

      .rubricSeperator {
        width: 1px;
        height: 40px;
        border-left: 2px solid #ddd;
        align-self: center;
        margin-left: 20px;
        margin-right: 20px;
      }

      .rubricBackDiv {
        display: flex;
        // cursor: pointer;
        // margin-left: 20px;
        margin-top: 20px;

        .rubricGrade1 {
          margin-bottom: 0px;
          color: #487fcc;
          margin-bottom: 20px;
          font-size: 1rem;
          font-weight: 600;
          text-align: left;
          cursor: pointer;
        }

        .rubricGrade2 {
          margin-bottom: 0px;
          color: #487fcc;
          margin-bottom: 20px;
          font-size: 1rem;
          font-weight: 600;
          text-align: left;
          cursor: pointer;
        }

        .rubricForIcon {
          color: #487fcc;
          font-weight: 500;
          cursor: default;
        }

        .rubricGrade3 {
          margin-bottom: 0px;
          color: #222222;
          margin-bottom: 20px;
          font-size: 1rem;
          font-weight: 600;
          text-align: left;
        }
      }

      .importClassBackDiv {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem;
        background: transparent linear-gradient(90deg, #669fed 0%, #386cb5 100%)
          0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        cursor: pointer;
      }

      .lessonBackIcon {
        font-size: 1.3rem;
        padding-top: 2px;
        color: white;
      }

      .importBackIcon {
        font-size: 1.3rem;
        padding-top: 2px;
        color: white;
      }

      .lessonClassBactText {
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
      }

      .attClassDetailTextDiv {
        // flex: 1.6 1;
        // padding: 0rem 0rem 0rem 0px;
        // height: 2.5rem;
        // display: inline-grid;
        // align-items: center;
        margin-left: 1rem;
        flex: 1.6 1;
        border-left: 2.5px solid #eee;
        padding: 0 0 0 1rem;
        height: 50px;
        display: inline-grid;
        align-items: center;

        .firstDetailsDiv {
          display: grid;
        }

        .attTitleName {
          margin: 0px;
          font-size: 1rem;
          font-weight: 600;
          text-align: left;
          display: flex;
          padding-right: 5px;
          font: normal normal 600 14px/14px Open Sans;
        }

        .head {
          margin-bottom: 0px;
          color: #222222;
          margin-bottom: 0px;
          font-size: 1rem;
          font-weight: 600;
          text-align: left;
        }

        .head2 {
          // margin-top: -5px;
          color: #222222;
          margin-bottom: 0px;
          font-size: 1rem;
          font-weight: 600;
          text-align: left;
        }

        .importHead {
          font: normal normal 600 20px/27px Open Sans;
          letter-spacing: 0px;
          color: #222222;
          opacity: 1;
          text-transform: capitalize;
          text-align: left;
          margin: 0px;
        }

        .importHead2 {
          font-size: 16px;
          letter-spacing: 0px;
          color: #222222;
          opacity: 1;
          text-transform: capitalize;
          text-align: left;
          margin: 0px;
          margin-top: -4px;
          font-weight: 600;
        }

        .displayFlexClassRoster {
          display: flex;
          cursor: pointer;

          .calIcon {
            align-self: center;
            cursor: default;
            cursor: not-allowed;
          }

          .attClassGrade {
            color: #888888;
            margin: 0rem 0.5rem 0rem 0rem;
            font-size: 16px;
            text-align: left;
            padding: 8px 0px 0px 0px;
            cursor: default;
            font-weight: 600;
          }

          .topicHead {
            color: #070707;
            margin: 0rem 0.5rem 0rem 0rem;
            font-size: 16px;
            text-align: left;
            padding: 0px 0px 0px 0px;
            cursor: default;
            font-weight: 600;
          }

          .topicTextName {
            color: #2e4aeb;
            margin: 0rem 0.5rem 0rem 0rem;
            font-size: 16px;
            text-align: left;
            padding: 0px 0px 0px 0px;
            cursor: default;
            font-weight: 600;
          }

          .topicDate {
            color: #888888;
            margin: 0rem 0.5rem 0rem 0rem;
            font-size: 16px;
            text-align: left;
            padding: 0px 0px 0px 0px;
            cursor: default;
            font-weight: 600;
          }
        }
      }
    }

    .displayFlexInSeletAtt {
      padding-right: 12px;
      display: flex;
      align-items: center;

      .undoSelects {
        width: 100px;
        height: 40px;
        border-radius: 8px;
        background: transparent
          linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat
          padding-box;
        opacity: 1;
        margin-right: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 2px;

        .iconText {
          padding: 1rem 0rem 0rem 0rem;
          color: white;
          font-size: 0.9rem;
        }
      }

      .opacityFadeUndo {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
      }

      .confirmSlectDiv {
        width: 100px;
        height: 40px;
        border-radius: 8px;
        background: transparent
          linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat
          padding-box;
        cursor: pointer;
        justify-content: center;
        display: flex;
        align-items: center;

        .confirmedIcon {
          color: white;
          font-size: 1.2rem;
          padding-right: 2px;
        }

        .iconText {
          color: white;
          font-size: 0.9rem;
          margin: 0px 0px 0px 5px !important;
        }
      }

      .opacityFade {
        opacity: 0.4;
        pointer-events: none;
        cursor: default !important;
      }
    }

    .buttonMarginTop {
      display: flex;
      align-items: center;

      .addNewLesson {
        width: 140px;
        height: 40px;
        border-radius: 8px;
        padding: 0.6rem !important;
        background: transparent
          linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
        margin: 0.1rem 0.3rem 0.1rem 0.3rem;
      }

      .addNewLessonFade {
        width: 140px;
        height: 40px;
        border-radius: 8px;
        padding: 0.6rem !important;
        background: transparent
          linear-gradient(270deg, #a7e9a5 0%, #a7e9a5 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
        margin: 0.1rem 0.3rem 0.1rem 0.3rem;
        pointer-events: none;
      }

      .editBtnAsses {
        width: 130px;
        height: 40px;
        border-radius: 8px;
        padding: 0.6rem !important;
        background: transparent
          linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
        margin: 0.1rem 1.3rem 0.1rem 0.3rem;
      }

      .editFade {
        width: 11rem !important;
        height: 2.5rem !important;
        border-radius: 2rem !important;
        padding: 0.6rem !important;
        background: transparent
          linear-gradient(270deg, #709cda 0%, #94a7c2 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
        margin: 0.1rem 0.3rem 0.1rem 0.3rem;
      }

      .pencilIcon {
        width: 1.1rem;
        margin-top: 0.2rem;
      }

      .lessonIcons {
        width: 1.1rem;
        margin-top: 0.4rem;
      }

      .viewAttText {
        color: white;
        font-size: 0.9rem;
        margin-left: 0.5rem;
      }
    }

    .lessonDetailTextDiv {
      margin-left: 1rem;
      border-left: 1px solid #eeeeee;
      padding: 0.3rem 0rem 0rem 1rem;

      .lessonClassName {
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
      }

      .displayFlexClassLesson {
        display: flex;
        cursor: pointer;

        .calIcon {
          align-self: center;
          cursor: default;
          cursor: not-allowed;
        }

        .lessonClassGrade {
          color: #888888;
          margin: 0rem 0.5rem 0rem 0rem;
          font-size: 0.8rem;
          text-align: left;
          padding: 5px 0px 0px 5px;
          cursor: default;
        }

        .headingForEdited {
          display: inline-flex;
          flex-wrap: wrap;
          cursor: default;
          align-items: center;

          .attTitleName {
            margin: 0px;
            font-size: 1rem;
            font-weight: 600;
            text-align: left;
            display: flex;
            padding-right: 5px;
            font: normal normal 600 14px/14px Open Sans;
          }

          .head {
            color: #386cb5;
            text-transform: capitalize;
            font: normal normal 600 14px/14px Open Sans;
            margin: 0px;
          }
        }

        .attClassGrades {
          color: #888888;
          margin: 0rem 0.5rem 0rem 0rem;
          font-size: 0.8rem;
          text-align: left;
          padding: 1px 0px 0px 5px;
          cursor: default;
          font-weight: 600;
        }
      }
    }
  }

  .planTitleMainDiv {
    background-color: white;
    border-radius: 8px;
    margin: 15px 0px 15px;

    .planTitleDiv {
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .planTitle {
        flex: 0.25;
        padding: 15px 20px 15px;

        .headTitlesPlan {
          text-align: left;
          margin: 0px;
          font-size: 0.85rem;
          font-weight: 600;
          text-align: left;
        }

        .mailDiv {
          height: 40px;
          border-radius: 5px;
          border: 0.75px solid #dddddd;
          background-color: #f4f6fa;
          font-size: 0.95rem;
          padding-left: 10px;
          display: flex;
          margin-top: 15px;
          width: 100%;
          outline: none;
        }

        input::placeholder {
          font-size: 0.95rem;
          font-weight: 200;
          color: rgb(206, 198, 198);
        }
      }

      .planDateTitle {
        flex: 0.15;
        padding: 15px 20px 15px;

        .headTitlesPlan {
          text-align: left;
          margin: 0px;
          font-size: 0.85rem;
          font-weight: 600;
          text-align: left;
        }

        .dateSubBlock {
          border: 0.75px solid #dddddd;
          background: #f4f6fa;
          border-radius: 5px;
          padding: 7px;
          display: flex;
          justify-content: space-between;
          margin-top: 14px;
          width: 67%;

          .react-datepicker-wrapper {
            cursor: pointer;
          }

          .dayDateTextDiv {
            width: 206px;

            .dayDateText {
              text-align: left;
              margin-bottom: 0px !important;
              font: normal normal 600 16px/24px Open Sans;
            }
          }

          .calMargin {
            margin: 4px 0px 4px 0px;
          }
        }
      }
    }

    .importClassDiv {
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;
      padding-bottom: 10px;

      .importClassText {
        font: normal normal normal 12px/17px Open Sans;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
        margin-bottom: 0px !important;
      }
    }
  }

  .criteriaMainDiv {
    flex-wrap: wrap;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin: 15px 0px 15px;

    .criteriaTitleDiv {
      display: flex;
      align-items: center;
      padding: 20px;
      padding-bottom: 0px;
      .criteriaTitleText {
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
      }

      .limitationText {
        font-size: 0.79rem;
        font-weight: 600;
        text-align: left;
        padding-left: 5px;
        color: #aaaaaa;
        margin: 0px;
        margin-top: 2px;
      }
    }

    .criteriaInputsMainsDiv {
      .rationalInputsBox {
        height: auto;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        display: flex;
        flex-wrap: wrap;

        .rationalInputTextsDiv {
          display: flex;
          padding: 10px 10px 10px 3px;
          width: 495px;
          margin-right: 60px;
          align-items: center;

          .indexingNo {
            flex: 0.07;
            color: #aaaaaa;
            font-weight: 600;
            padding-right: 5px;
            margin: 0px !important;
          }

          .rationalInputTexts {
            width: 80%;
            border: none;
            border: 0.75px solid #dddddd;
            height: 40px;
            padding-left: 10px;
            flex: 0.8;
            background: #f4f6fa;
            border-radius: 5px;
            color: black;
            font-size: 0.88rem;
            font-weight: 600;
          }
        }

        input::placeholder {
          font-size: 0.95rem;
          font-weight: 200;
          color: rgb(206, 198, 198);
        }

        input[type="text"]:focus,
        textarea:focus {
          outline: none;
        }

        .rationalSubTitle {
          display: flex;
          margin-bottom: 0px;
          color: #386cb5;
          font-size: 0.88rem;
          font-weight: 600;
          cursor: pointer;
          padding: 0px 0px 0px 15px;
        }
      }
    }

    .criteriaInputsMainDiv {
      .rationalInputBox {
        height: auto;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        display: flex;
        flex-wrap: wrap;

        .rationalInputTextDiv {
          display: flex;
          padding: 10px 10px 10px 3px;
          width: 433px;
          margin-right: 199px;
          align-items: center;

          .indexingNo {
            flex: 0.07;
            color: #aaaaaa;
            font-weight: 600;
            padding-right: 5px;
            margin: 0px !important;
          }

          .rationalInputText {
            width: 80%;
            border: none;
            border: 0.75px solid #dddddd;
            height: 40px;
            padding-left: 10px;
            flex: 0.8;
            background: #f4f6fa;
            border-radius: 5px;
            color: black;
            font-size: 0.88rem;
            font-weight: 600;
          }

          .removeDiv {
            display: flex;
            padding-left: 15px;
            cursor: pointer;

            .delIcon {
              // margin-top: 5px;
            }

            .removeText {
              font-size: 0.89rem;
              font-weight: 600;
              color: red;
              margin: 2px 0px 0px 5px;
            }
          }
        }

        .rationalInputTextDiv:nth-of-type(1) {
          width: 402px;
          margin-right: 229px;
        }

        input::placeholder {
          font-size: 0.95rem;
          font-weight: 200;
          color: rgb(206, 198, 198);
        }

        input[type="text"]:focus,
        textarea:focus {
          outline: none;
        }

        .rationalSubTitle {
          display: flex;
          margin-bottom: 0px;
          color: #386cb5;
          font-size: 0.88rem;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .rationalTitleBox {
        background-color: #669fed;
        height: 42px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .rationalTitle {
          padding-left: 10px;
          margin-bottom: 0px;
          color: white;
          font-size: 0.98rem;
          font-weight: 600;
        }

        .rationalSubTitle {
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 0px;
          color: white;
          font-size: 0.88rem;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .rationalSubTitle {
        display: flex;
        margin-bottom: 0px;
        color: #386cb5;
        font-size: 0.88rem;
        font-weight: 600;
        cursor: pointer;
        padding: 0px 0px 20px 40px;
      }
    }
  }
}

@media only screen and (min-width: 357px) and (max-width: 820px) {
  .addNewRubricsContainer {
    .addRubricsMainDiv {
      flex-wrap: wrap;
    }
  }
}
.generateAIBtnRurbicsDiv {
  .generateAIBtnRurbicsDiv_button {
    background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0%
      0% no-repeat padding-box !important;
    border-radius: 8px !important;
    width: 10.75rem;
    font: normal normal normal 14px/19px Open Sans !important;
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: 0.6rem !important;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 20px;
  }
}
.rubricsModalHeader {
  p {
    text-align: center;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #222222;
  }
}
.criteriamodalbody {
  p {
    text-align: left;
    font: normal normal 600 13px/18px Open Sans;
    letter-spacing: 0px;
    color: #aaaaaa;
    opacity: 1;
  }
}
.customDiscreetSlider {
  .MuiSlider-root {
    color: #e5e5e5;
  }
  .MuiSlider-track {
    background-color: #669fed;
  }
  .MuiSlider-rail {
    background-color: #e5e5e5;
    opacity: 1;
  }
  .MuiSlider-thumb {
    color: #fff;
    border-color: #070707;
  }
  .MuiSlider-mark {
    top: 63%;
    height: 5px;
    color: #cccccc;
    // &:last-of-type {
    //     display: none!important;
    // }
  }
  // .MuiSlider-mark .css-1tfve6q-MuiSlider-mark:last-of-type {
  //     display: none!important;
  // }
  .MuiSlider-root {
    span[data-index="4"].MuiSlider-mark {
      display: none;
    }
  }
}

.customDiscreetScoreSlider {
    .MuiSlider-root {
        color: #e5e5e5 !important;
      }
      .MuiSlider-track {
        background-color: #669fed !important;
      }
      .MuiSlider-rail {
        background-color: #e5e5e5 !important;
        opacity: 1;
      }
      .MuiSlider-thumb {
        color: #fff !important;
        border-color: #070707 !important;
      }
      .MuiSlider-mark {
        top: 63%;
        height: 5px;
        color: #cccccc;
        // &:last-of-type {
        //     display: none!important;
        // }
      }
      // .MuiSlider-mark .css-1tfve6q-MuiSlider-mark:last-of-type {
      //     display: none!important;
      // }
      .css-1aznpnh-MuiSlider-root {
        span[data-index="9"].MuiSlider-mark {
          display: none !important;
        }
      }
}
.RubricsgenerateModal {
  .modal-content {
    padding: 20px;
  }
  .proceed-btn {
    background: transparent linear-gradient(270deg, #747beb 0%, #989eff 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    width: 107px;
    height: 40px;
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}
.generateBtnContainer {
  width: 6.25rem !important;
  height: 2.5rem !important;
  border-radius: 0.5rem !important;
  padding: 0.6rem !important;
  background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #2222221a !important;
  display: inline-flex !important;
  justify-content: center !important;

}
//     {

//     "user_id": "538",
//     "class_id": 667,
//     "rubric_id": 104,
//     "student_id": 705,
//     "assessment_details_array": [ {
//         "assessment_comment": "",
//             "assessment_point": "",
//             "availability_comment": "d",
//             "availability_status": "absent",
//             "average_score": "",
//             "criteria_id": "",
//             "id": "",
//     }

//     ],
//     "platform": "web"
// }
