.classAttendanceContainer {
    .PreviewAttContainer {
        .reportPreView {
            .csvContainer {
                background-color: white;
                box-shadow: 22px 7px 26px #2222221a !important;
                overflow: scroll;
                height: 405px;
                .reportTitle {
                    font: normal normal 600 17px/37px Open Sans;
                }
                table {
                    border-collapse: collapse;
                    width: 100%;
                }

                td,
                th {
                    border: 1px solid #dddddd;
                    text-align: center;
                    padding: 8px;
                }

                tr {
                    background-color: #fffffd;
                }

                tr:nth-child(1) {
                    background-color: #555555;
                    color: white;
                }
                p {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}
