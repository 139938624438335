.NoteSelectClassSCSS-NSC {
    .classAttendanceContainer {
        background: #f0f0f0;
        height: 100%;
        overflow-x: hidden;
        padding: 0rem 2.5rem 1rem 2.5rem;

        .noneStudents {
            color: #aaaaaa;
            font: normal normal 600 20px/14px Open Sans;
            line-height: inherit;
            padding-top: 240px;
        }

        .ClassAttendanceMainDiv-NSC {
            width: 100%;
            height: 80px;
            background: #ffff;
            justify-content: space-between;
            display: flex;
            padding: 1rem 1rem 1rem 1rem;
            border-radius: 0px 0px 8px 8px;

            .classAttendaceDetailsMain-NSC {
                display: flex;
                align-items: center;
                width: 60px;

                .attClassBackDiv-NSC {
                    display: flex;
                    padding: 0.8rem;
                    background: transparent;
                    cursor: pointer;
                    align-items: center;
                }

                .attClassBackIcon {
                    font-size: 1.3rem;
                    padding-top: 3px;
                }

                .attClassBactText {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                }
            }

            .buttonMarginTop-NSC {
                display: flex;
                align-items: center;

                .editAttBtn {
                    width: 5.3rem !important;
                    height: 2.5rem !important;
                    border-radius: 2rem !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: none !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                }

                .viewNoteDiv-NSC {
                    width: 6.875rem !important;
                    height: 2.5rem !important;
                    border-radius: 0.5rem !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: none !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                }

                .pencilAttIcon {
                    width: 1rem;
                    margin-top: 0.2rem;
                }

                .viewAttIcon-NSC {
                    width: 0.9rem;
                    margin-top: 0.4rem;
                }

                .viewAttText-NSC {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                }

                .displayFlexInSeletAtt {
                    padding-right: 10px;
                    display: flex;

                    .undoSelect {
                        width: 6.5rem;
                        height: 2.5rem;
                        background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
                        border-radius: 0.5rem;
                        opacity: 1;
                        margin-right: 1rem;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .confirmSlectedDiv {
                        width: 6.25rem;
                        height: 2.5rem;
                        background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
                        border-radius: 0.5rem;
                        opacity: 1;
                        cursor: pointer;

                        .confirmIcon {
                            padding: .45rem 0rem 0rem 0rem;
                            color: white;
                            font-size: 1.7rem;
                        }
                    }



                }
            }

            .attClassDetailTextDiv-NSC {
                margin-left: 1rem;
                border-left: 1px solid #eeeeee;
                padding: 0.3rem 0rem 0rem 1rem;

                .attClassName-NSC {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    color: #386cb5;
                }

                .displayFlexClassRoster-NSC {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    width: 250px;
                    padding-top: 10px;

                    .calIcon-NSC {
                        align-self: center;
                        cursor: default;
                        cursor: not-allowed;
                        margin-bottom: 10px;
                    }

                    .classForIcon-NSC {
                        color: #487FCC;
                        margin-bottom: 19px;
                        margin-left: -2px;
                    }

                    .attClassGrade-NSC {
                        color: #487FCC;
                        margin-bottom: 20px;
                        font-size: 1rem;
                        font-weight: 400;
                        text-align: left;

                        cursor: pointer;
                    }

                    .attClassGrade2-NSC {
                        color: #222222;
                        margin-bottom: 21px;
                        font-size: 1.25rem;
                        font-weight: 600;
                        text-align: left;
                        cursor: default;
                    }

                    .attClassGrade3 {
                        color: #487FCC;
                        margin-top: 8px;
                        margin-left: 5px;
                        font-size: 1rem;
                        font-weight: 400;
                        text-align: left;
                        cursor: default;
                    }

                    .headingForEdited {
                        display: inline-flex;
                        flex-wrap: wrap;
                        cursor: default;
                        align-items: center;

                        .attTitleName {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            text-align: left;
                            display: flex;
                            padding-right: 5px;
                            font: normal normal 600 14px/14px Open Sans;
                        }

                        .head {
                            color: #386cb5;
                            text-transform: capitalize;
                            font: normal normal 600 14px/14px Open Sans;
                            margin: 0px;
                        }
                    }

                    .attClassGrades {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 1px 0px 0px 5px;
                        cursor: default;
                        font-weight: 600;
                    }
                }
            }
        }

        .viewAttTitleDiv {
            width: 100%;
            background: #ffff;
            justify-content: space-between;
            display: flex;
            padding: 1rem 1rem 1rem 1rem;
            flex-wrap: wrap;
            align-items: center;

            .classAttendaceDetailsMain-NSC {
                display: flex;
                flex: 0.2;
                align-items: "center";

                .attClassBackDiv-NSC {
                    display: flex;
                    padding: 0.8rem;
                    background: transparent;
                    cursor: pointer;
                    align-items: center;
                }

                .attClassBackIcon {
                    font-size: 1.3rem;
                    padding-top: 3px;
                }

                .attClassBactText {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                }
            }

            .attClassDetailTextDiv-NSC {
                margin-left: 1rem;
                flex: 0.6;
                border-left: 1px solid #eeeeee;
                padding: 0.3rem 0rem 0rem 1rem;

                .firstDetailsDiv {
                    display: grid;
                }

                .headingTitles {
                    display: inline-flex;
                    flex-wrap: wrap;
                    padding-bottom: 10px;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                    }

                    .head {
                        color: #386cb5;
                        text-transform: capitalize;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                        margin: 0px;
                    }
                }

                .displayFlexClassRoster-NSC {
                    display: flex;
                    cursor: pointer;

                    .calIcon-NSC {
                        align-self: center;
                        cursor: default;
                        cursor: not-allowed;
                    }

                    .attClassGrade-NSC {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 5px 0px 0px 5px;
                        cursor: default;
                    }
                }
            }

            .attClassDetailTextDivTwo {
                margin-left: 1rem;
                flex: 0.4 1;
                padding: 0.3rem 0rem 0rem 1rem;

                .firstDetailsDiv {
                    display: grid;
                }

                .headingTitles {
                    display: inline-flex;
                    flex-wrap: wrap;
                    padding-bottom: 10px;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                    }

                    .head {
                        color: #386cb5;
                        text-transform: capitalize;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                        margin: 0px;
                    }
                }

                .displayFlexClassRoster-NSC {
                    display: flex;
                    cursor: pointer;

                    .calIcon-NSC {
                        align-self: center;
                        cursor: default;
                        cursor: not-allowed;
                    }

                    .attClassGrade-NSC {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 5px 0px 0px 5px;
                        cursor: default;
                    }
                }
            }

            .classOptionGet {
                width: 4.7rem;
                height: 3.7rem;
                background: transparent linear-gradient(270deg, #4479c4 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                border-radius: 8px;
                margin: 0rem 0.1rem 0rem 0.1rem;
                padding-top: 0.6rem;
                cursor: pointer;
            }
        }

        .classAttParentContainer {
            display: flex;

            .commentCardDiv {
                width: 24%;
                height: 5rem;
                margin: 1rem 0rem 0rem 0rem;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0rem 0.2rem 0.5rem #0000001a;
                border-radius: 0.5rem;
                opacity: 1;
                margin-left: 1%;
            }

            .commentTextBox {
                width: 100%;
                height: 80px;
                background: #F4F6FA 0% 0% no-repeat padding-box;
                font: normal normal 600 13px/14px Open Sans;
            }

            .classAttCardDiv {
                width: 75%;
                height: 5rem;
                margin: 1rem 0rem 0rem 0rem;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0rem 0.2rem 0.5rem #0000001a;
                border-radius: 0.5rem;
                opacity: 1;
                padding: 0.1rem 0.3rem 0.1rem 0.3rem;

                /* --------ClassAttCard-------- */

                /* ---------Class Att Info Cards------------ */
                .classAttDetailsCard {
                    width: 100%;
                    height: 4.8rem;
                    display: inline-flex;
                    position: relative;
                    bottom: 4px;

                    .classAttProfileImageDiv {
                        width: 4rem;
                        padding: 5px 0px 5px 10px;

                        .studentAttProfileImage {
                            width: 3.75rem;
                            height: 3.75rem;
                            background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                            box-shadow: 1px 2px 3px #0000001a;
                            border-radius: 50rem;
                        }

                        .DummyClassAttProfileDiv {
                            width: 4rem;
                            height: 4rem;
                            background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                            box-shadow: 1px 2px 3px #0000001a;
                            border-radius: 50rem;
                            text-align: center;
                        }

                        .imageDummyDivProfileAtt {
                            width: 4rem;
                            height: 4rem;
                            background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                            box-shadow: 1px 2px 3px #0000001a;
                            border-radius: 50rem;
                            text-align: center;
                            opacity: 0.8;

                            .profileImageAttDummyText {
                                color: white;
                                padding-top: 30%;
                                text-transform: uppercase;
                            }
                        }

                        .profileImageDummyAttText {
                            color: white;
                            padding-top: 30%;
                            text-transform: uppercase;
                        }

                        .profileClassAttDummyText {
                            color: white;
                            padding-top: 30%;
                            text-transform: uppercase;
                        }
                    }

                    .classAttTextInfoDiv {
                        flex: 1;
                        text-align: left;
                        padding: 12px 0px 0px 18px;
                        min-width: 235px;

                        .classAttName {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            color: #222222;
                        }

                        .classAttEmail {
                            margin: 0px;
                            font-size: 0.9rem;
                            color: #888888;
                        }
                    }

                    .emojiCommentDiv {
                        padding: 22px 7px 0px 0px;
                        cursor: pointer;

                        .commentDiv {
                            .commentData-style {
                                .attComment-img {
                                    width: 30px;
                                    height: 20px;
                                }
                            }
                        }
                    }

                    .emojiCommentsDiv {
                        padding: 12px 7px 0px 0px;
                        cursor: default;

                        .commentDiv {
                            .commentData-style {
                                .attComment-img {
                                    width: 30px;
                                    height: 20px;
                                    margin-bottom: 10px;
                                }

                                .commentsDataRange {
                                    font: normal normal 600 12px/14px Open Sans;
                                    color: #386cb5;
                                }
                            }
                        }
                    }

                    .emojiDiv {
                        flex: 0.1;
                        display: inline-flex;
                        align-items: center;
                        padding-top: 15px;

                        .emojiIcons {
                            height: 60px;
                            cursor: pointer;
                            width: 70px;
                        }

                        .emojiIconsFair {
                            height: 60px;
                            cursor: pointer;
                            width: 70px;
                            padding-right: 5px;
                        }

                        .vertical-line {
                            display: inline-block;
                            border-left: 2px solid #d5cfcf;
                            height: 45px;
                            padding-right: 5px;
                            margin-bottom: 10px;
                        }
                    }

                    .emojiRangeDiv {
                        flex: 0.1;
                        display: inline-flex;
                        align-items: center;
                        padding-top: 0px;

                        .emojiIcons {
                            height: 60px;
                            cursor: pointer;
                            width: 70px;
                        }

                        .emojiIconsFair {
                            height: 60px;
                            cursor: pointer;
                            width: 70px;
                            padding-right: 5px;
                        }

                        .vertical-line {
                            display: inline-block;
                            border-left: 2px solid #d5cfcf;
                            height: 45px;
                            padding-right: 5px;
                            margin-bottom: 10px;
                        }

                        .digitsRangeHappy {
                            font: normal normal 600 13px/14px Open Sans;
                            color: rgb(60, 255, 0);
                            padding-top: 5px;
                        }

                        .digitsRangeFair {
                            font: normal normal 600 13px/14px Open Sans;
                            color: #fde432;
                            padding-top: 5px;
                        }

                        .digitsRangeSad {
                            font: normal normal 600 13px/14px Open Sans;
                            color: #f7830b;
                            padding-top: 5px;
                        }

                        .digitsRangeAbsent {
                            font: normal normal 600 13px/14px Open Sans;
                            color: red;
                            padding-top: 5px;
                        }
                    }

                    .showViewAttEmoji {
                        cursor: default !important;
                    }
                }
            }

            .showParticularComtBox {
                width: 24%;
                height: 5rem;
                margin: 1rem 0rem 0rem 0rem;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0rem 0.2rem 0.5rem #0000001a;
                border-radius: 0.5rem;
                opacity: 1;
                margin-left: 1%;

                .showPartiCmtText {
                    font: normal normal 600 13px/14px Open Sans;
                }

                .showNonePartiCmtText {
                    color: #777777;
                    font: normal normal 600 13px/14px Open Sans;
                }
            }

            .showParticularEditComtBox {
                width: 24%;
                height: 5rem;
                margin: 1rem 0rem 0rem 0rem;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0rem 0.2rem 0.5rem #0000001a;
                border-radius: 0.5rem;
                opacity: 1;
                margin-left: 1%;

                .inputBox {
                    width: 100%;
                    height: 80px;
                    background: #F4F6FA 0% 0% no-repeat padding-box;
                    font: normal normal 600 13px/14px Open Sans;
                }

                .showPartiCmtText {
                    font: normal normal 600 13px/14px Open Sans;
                }

                .showNonePartiCmtText {
                    color: #777777;
                    font: normal normal 600 13px/14px Open Sans;
                }
            }

            .showAttScoreBox {
                min-height: 5.9rem;
                margin: 1rem 0rem 0.8rem 0.8rem;
                border-radius: 0.5rem;
                padding: 10px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #cccccc;
                min-width: 120px;
                max-width: 120px;

                .showPartiCmtText {
                    font: normal normal 600 13px/14px Open Sans;
                }

                .showNonePartiCmtText {
                    color: #aaaaaa;
                    font: normal normal 600 13px/14px Open Sans;
                }

                .showScoreText {
                    color: #386cb5;
                    font: normal normal 600 20px/25px Open Sans;
                }
            }
        }

        .classAttRangeParentContainer {
            display: flex;

            .classAttCardRangeDiv {
                width: 100%;
                height: 5.8rem;
                margin: 1rem 0rem 0.8rem 0rem;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0rem 0.2rem 0.5rem #0000001a;
                border-radius: 0.5rem;
                opacity: 1;
                padding: 0.1rem 0.3rem 0.1rem 0.3rem;

                /* --------ClassAttCard-------- */

                /* ---------Class Att Info Cards------------ */
                .classAttDetailsCard {
                    width: 100%;
                    height: 4.8rem;
                    display: inline-flex;
                    position: relative;

                    .classAttProfileImageDiv {
                        width: 4rem;
                        padding: 5px 0px 5px 10px;

                        .studentAttProfileImage {
                            width: 3.75rem;
                            height: 3.75rem;
                            background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                            box-shadow: 1px 2px 3px #0000001a;
                            border-radius: 50rem;
                        }

                        .DummyClassAttProfileDiv {
                            width: 4rem;
                            height: 4rem;
                            background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                            box-shadow: 1px 2px 3px #0000001a;
                            border-radius: 50rem;
                            text-align: center;
                        }

                        .imageDummyDivProfileAtt {
                            width: 4rem;
                            height: 4rem;
                            background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                            box-shadow: 1px 2px 3px #0000001a;
                            border-radius: 50rem;
                            text-align: center;
                            opacity: 0.8;

                            .profileImageAttDummyText {
                                color: white;
                                padding-top: 30%;
                                text-transform: uppercase;
                            }
                        }

                        .profileImageDummyAttText {
                            color: white;
                            padding-top: 30%;
                            text-transform: uppercase;
                        }

                        .profileClassAttDummyText {
                            color: white;
                            padding-top: 30%;
                            text-transform: uppercase;
                        }
                    }

                    .classAttTextInfoDiv {
                        flex: 1;
                        text-align: left;
                        padding: 12px 0px 0px 18px;
                        min-width: 235px;

                        .classAttName {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            color: #222222;
                        }

                        .classAttEmail {
                            margin: 0px;
                            font-size: 0.9rem;
                            color: #888888;
                        }
                    }

                    .emojiCommentDiv {
                        padding: 22px 7px 0px 0px;
                        cursor: pointer;

                        .commentDiv {
                            .commentData-style {
                                .attComment-img {
                                    width: 30px;
                                    height: 20px;
                                }
                            }
                        }
                    }

                    .emojiCommentsDiv {
                        padding: 12px 7px 0px 0px;
                        cursor: default;

                        .commentDiv {
                            .commentData-style {
                                .attComment-img {
                                    width: 30px;
                                    height: 20px;
                                    margin-bottom: 10px;
                                }

                                .commentsDataRange {
                                    font: normal normal 600 12px/14px Open Sans;
                                    color: #386cb5;
                                }
                            }
                        }
                    }

                    .emojiDiv {
                        flex: 0.1;
                        display: inline-flex;
                        align-items: center;
                        padding-top: 15px;

                        .emojiIcons {
                            height: 60px;
                            cursor: pointer;
                            width: 70px;
                        }

                        .emojiIconsFair {
                            height: 60px;
                            cursor: pointer;
                            width: 70px;
                            padding-right: 5px;
                        }

                        .vertical-line {
                            display: inline-block;
                            border-left: 2px solid #d5cfcf;
                            height: 45px;
                            padding-right: 5px;
                            margin-bottom: 10px;
                        }
                    }

                    .emojiRangeDiv {
                        flex: 0.1;
                        display: inline-flex;
                        align-items: center;
                        padding-top: 0px;

                        .emojiIcons {
                            height: 60px;
                            cursor: pointer;
                            width: 70px;
                        }

                        .emojiIconsFair {
                            height: 60px;
                            cursor: pointer;
                            width: 70px;
                            padding-right: 5px;
                        }

                        .vertical-line {
                            display: inline-block;
                            border-left: 2px solid #d5cfcf;
                            height: 45px;
                            padding-right: 5px;
                            margin-bottom: 10px;
                        }

                        .digitsRangeHappy {
                            font: normal normal 600 13px/14px Open Sans;
                            color: rgb(60, 255, 0);
                            padding-top: 5px;
                        }

                        .digitsRangeFair {
                            font: normal normal 600 13px/14px Open Sans;
                            color: #fde432;
                            padding-top: 5px;
                        }

                        .digitsRangeSad {
                            font: normal normal 600 13px/14px Open Sans;
                            color: #f7830b;
                            padding-top: 5px;
                        }

                        .digitsRangeAbsent {
                            font: normal normal 600 13px/14px Open Sans;
                            color: red;
                            padding-top: 5px;
                        }
                    }

                    .showViewAttEmoji {
                        cursor: default !important;
                    }
                }
            }

            .showParticularComtBox {
                width: 24%;
                height: 5rem;
                margin: 1rem 0rem 0rem 0rem;
                background: #ffffff 0% 0% no-repeat padding-box;
                box-shadow: 0rem 0.2rem 0.5rem #0000001a;
                border-radius: 0.5rem;
                opacity: 1;
                margin-left: 1%;

                .showPartiCmtText {
                    font: normal normal 600 13px/14px Open Sans;
                }

                .showNonePartiCmtText {
                    color: #777777;
                    font: normal normal 600 13px/14px Open Sans;
                }
            }

            .showAttScoreBox {
                min-height: 5.9rem;
                margin: 1rem 0rem 0.8rem 0.8rem;
                border-radius: 0.5rem;
                padding: 10px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #cccccc;
                min-width: 120px;
                max-width: 120px;
                display: flex;
                justify-content: center;
                align-items: center;

                .showPartiCmtText {
                    font: normal normal 600 13px/14px Open Sans;
                }

                .showNonePartiCmtText {
                    color: #aaaaaa;
                    font: normal normal 600 13px/14px Open Sans;
                }

                .showScoreText {
                    color: #386cb5;
                    font: normal normal 600 20px/25px Open Sans;
                }
            }
        }
    }

    .eventNone {
        pointer-events: none;
    }

    .attendance-popContainer {
        left: 60px !important;
        top: -62px !important;
        min-height: 78px !important;
        border-radius: 0 !important;
        padding: 5px !important;
        box-shadow: 0px 0px 15px #22222233;
        border: white !important;
        width: 240px !important;

        .popover-inner {
            box-shadow: 0px 0px 15px #22222233;
            border: white !important;
            width: 245px !important;
            background-color: white;
            border-radius: 5px;

            .popUpContainer {
                box-shadow: #0d0d0d1a 1px 0.35rem 3.475rem 14px;

                .bs-popover-auto[x-placement^="left"]>.arrow::after,
                .bs-popover-left>.arrow::after {
                    border-left-color: #fff !important;
                }

                .titleCloseDiv {
                    display: flex;

                    .addTitle {
                        flex: 1 1;
                        padding-top: 3px;
                        margin-bottom: 0px !important;
                        font: normal normal 600 14px/17px Open Sans;
                        color: #0f0e0e;
                    }

                    .closeIcon {
                        float: right;
                        cursor: pointer;
                    }

                    .attcloseIcon {
                        margin-right: 10px;
                    }
                }

                .textBoxBtnDiv {
                    .input-box {
                        width: 222px;
                        height: 160px;
                        background: #f4f6fa 0% 0% no-repeat padding-box;
                        border: 0.75px solid #cccccc;
                        border-radius: 5px;
                        float: left;
                        margin-top: 15px;
                        margin-left: 0px;
                        color: #685f5f;
                        font: normal normal 600 13px/14px Open Sans;
                    }

                    .saveBtn {
                        width: 70px;
                        height: 28px;
                        background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
                        border-radius: 14px;
                        margin-top: 15px;
                        margin-left: 74px;
                        font: normal normal 600 12px/14px Open Sans;
                        color: #ffffff;
                    }

                    .saveBtn:active,
                    .saveBtn:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .att-modal-warning .modal-title {
        text-align: center;
        font: normal normal 600 18px/24px Open Sans;
    }

    .att-modal-warning .modal-content {
        border-radius: 8px;
        width: 302px;
        margin: auto;
        background-color: #fff !important;
    }

    .att-modal-warning .modal-content .modal-header {
        justify-content: center;
        border: none;
        border-radius: 8px;
        padding: 20px 20px 0px 20px !important;
    }

    .att-modal-warning .modal-content .modal-body {
        text-align: center;
        font: normal normal 600 14px/19px Open Sans;

        .filterDiv {
            display: flex;
            flex-wrap: wrap;

            .listAllClassAttFilter {
                width: 5.28rem;
                height: 1.35rem;
                border: 0.5px solid #aaaaaa;
                cursor: pointer;
                border-radius: 16px;
                margin: 0.15rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .listAllClassFilterAttText {
                    font-size: 0.8rem;
                    color: #aaaaaa;
                    padding: 1rem 0rem 0rem 0.1rem;
                }
            }

            .myClassFilterAttTodayDiv {
                width: 5.28rem;
                height: 1.35rem;
                border: 0.5px solid #aaaaaa;
                border-radius: 16px;
                margin: 0.15rem;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                .listAllClassFilterAttText {
                    font-size: 0.8rem;
                    color: #aaaaaa;
                    padding: 1rem 0rem 0rem 0.1rem;
                }
            }

            .myClassFilterAttDiv {
                width: 5.28rem;
                height: 1.35rem;
                border: 0.5px solid #aaaaaa;
                border-radius: 16px;
                margin: 0.15rem;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                .listAllClassFilterAttText {
                    font-size: 0.8rem;
                    color: #aaaaaa;
                    padding: 1rem 0rem 0rem 0.1rem;
                }
            }

            .selectedDivClr {
                border: 0.5px solid #639be7 !important;
            }

            .selectedTextClr {
                color: #639be7 !important;
            }
        }
    }

    .att-modal-warning .dateBlock {
        padding: 10px 20px 10px 20px;

        .dateSubBlock {
            border: 1px solid #dddddd;
            background: #f4f6fa;
            border-radius: 5px;
            padding: 12px;
            display: flex;
            justify-content: space-between;

            .react-datepicker-wrapper {
                cursor: pointer;
            }

            .dayDateTextDiv {
                width: 250px;

                .dayDateText {
                    margin-bottom: 0px !important;
                    font: normal normal 600 16px/24px Open Sans;
                }
            }

            .displayFlexAttClassRoster {
                display: flex;
                cursor: pointer;
                padding-bottom: 2px;
                padding-right: 5px;

                .calIcon-NSC {
                    align-self: center;
                    cursor: default;
                    cursor: not-allowed;
                }

                .attClassGrade-NSC {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 3px 0px 0px 3px;
                    cursor: default;
                }
            }

            .calMargin {
                margin: 4px 0px 4px 0px;
            }
        }

        .dateSubBlocks {
            border: 1px solid #dddddd;
            background: #f4f6fa;
            border-radius: 5px;
            padding: 12px;
            display: flex;
            justify-content: space-between;
            margin-top: 15px;

            .react-datepicker-wrapper {
                cursor: pointer;
            }

            .dayDateTextDiv {
                width: 250px;

                .dayDateText {
                    margin-bottom: 0px !important;
                    font: normal normal 600 16px/24px Open Sans;
                }

            }

            .calIcon-NSC {
                align-self: center;
                cursor: not-allowed;
            }

            .displayFlexAttClassRoster {
                display: flex;
                cursor: pointer;
                padding-bottom: 2px;
                padding-right: 5px;

                .calIcon-NSC {
                    align-self: center;
                    cursor: not-allowed;
                }

                .attClassGrade-NSC {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 3px 0px 0px 3px;
                    cursor: default;
                }
            }

            .calMargin {
                margin: 4px 0px 4px 0px;
            }
        }
    }

    .att-modal-warning .modal-content .modal-body .confirm-text {
        font: normal normal normal 14px/19px Open Sans;
        margin-bottom: 0;
    }

    .att-modal-warning .modal-footer {
        border: none;
        justify-content: center;
    }

    .att-modal-warning .getReport-btn {
        width: 101px;
        height: 38px;
        border-radius: 20px;
        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
        font: normal normal 600 14px/19px Open Sans;
    }

    .att-modal-warning .cancelAtt-btn {
        background-color: #ffffff !important;
        font: normal normal 600 14px/19px Open Sans;
        color: #080808 !important;
        width: 101px;
        height: 38px;
    }

    .viewPdf-modal-warning .modal-content {
        border-radius: 8px;
        width: 220px;
        height: 175px;
        margin: auto;
        background-color: #fff !important;
    }

    .viewPdf-modal-warning .modal-content .modal-header {
        border: none;
        display: block;
    }

    .viewPdf-modal-warning .modal-content .modal-title {
        text-align: center;
        font: normal normal 600 18px/24px Open Sans;
        border: none;
        align-items: center;
        display: block;
    }

    .viewPdf-modal-warning .modal-content .modal-body {
        display: flex;
        justify-content: center;
        padding: 0px !important;
    }

    .viewPdf-modal-warning .modal-content .modal-body .pdfBlock {
        padding: 20px;
        cursor: pointer;
    }

    .viewPdf-modal-warning .modal-content .modal-body .pdfBlock .reportName {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 15px/24px Open Sans;

    }

    .viewPdf-modal-warning .modal-content .modal-body .csvBlock {
        padding: 20px;
        cursor: pointer;
    }

    .viewPdf-modal-warning .modal-content .modal-body .csvBlock .reportName {
        text-align: center;
        padding-top: 10px;
        font: normal normal 600 15px/24px Open Sans;
    }

    .containerDiv-NSC {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 150px;
    }

    .arrowImage-NSC {
        width: 14px;
        height: 24px;
        align-self: center;
    }

    .searchImage {
        width: 14px;
        height: 14px;
        align-self: center;
        position: absolute;
        top: 13px !important;
        right: 27px !important;
        z-index: 2 !important;
    }

    .noteContainerTextDiv-NSC {
        width: 246px;
        align-self: center;
    }

    .containerText-NSC {
        align-self: center;
        font-size: 20px;
        text-align: center;
        letter-spacing: 0px;
        color: #AAAAAA;
        opacity: 1;
    }

    .note-select-dropdown-main-container-NSC {
        font-family: sans-serif;
        text-align: center;
        width: 50px;
        height: 50px;
        position: relative;
        top: 10px;
        left: -120px !important;
    }

    /**
   * Main wrapper
   */
   .select-search {
    width: 240px !important;
    position: relative;
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
    bottom: 5px !important;
    cursor: pointer;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

.select-search__value {
    position: relative;
}

.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
    cursor: pointer;
}

.select-search__input {
    margin-top: 5px;
    display: block;
    height: 40px;
    width: 240px;
    padding: 0 40px 0 30px;
    background: #fff;
    border: 1px solid #dddddd;
    // box-shadow: 0px 0px 3px #88c3fae3;
    border-radius: 8px;
    outline: none !important;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    color: rgba(56, 108, 181, 1);
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    opacity: 1;
    box-shadow: 0px 0px 3px #00000029;
border: 0.75px solid #DDDDDD;
border-radius: 8px;
opacity: 1;
}

.select-search__input::placeholder {
    font: italic normal normal 13px/18px Open Sans;
    letter-spacing: 0px;
    color: #c6b6b6 !important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

.select-search__select {
    max-height: 150px !important;
    box-shadow: 10px 10px 12px #ec090929;
    border: 1px solid #dddddd;
}

.select-search__options {
    list-style: none;
    padding: 0px 5px;
    background: #fff;
    cursor: pointer;
}

.select-search__row {
    border-bottom: 1px solid #dddddd;
    border-width: 0.15px;
}

.select-search__row:last-child {
    border-bottom: none;
}

.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    color: #fff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    color: #fff;
}

.select-search__group-header {
    text-transform: uppercase;
    background: #fff;
}

.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
    letter-spacing: 0px;
    font-weight: 600;
}

.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 3px solid #000;
    border-bottom: 3px solid #000;
    pointer-events: none;
}

.select-search.has-focus .select-search__value::after {
    display: none;
}

.select-search.has-focus .select-search__input:hover {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.select-search.has-focus .select-search__input {
    border-bottom: none;
    box-shadow: 0px 0px 3px #00000029;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-color: none;
    background: #f4f6fa 0% 0% no-repeat padding-box;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 40px !important;
    right: 0;
    left: 0;
    border-radius: 8px;
    overflow: auto;
    max-height: 360px;
    background: #fff;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    box-shadow: none;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: auto;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
    background: #fff;
}

.select-search__not-found {
    height: auto;
    padding: 8px;
    text-align: center;
    color: rgb(244, 245, 247);
}


    @media only screen and (min-width: 520px) and (max-width: 890px) {
        .classAttendanceContainer {
            padding: 0rem 0.5rem 1rem 0.5rem;
        }

        .ClassAttendanceMainDiv-NSC {
            flex-wrap: wrap;
            justify-content: center;
        }

    }

    @media only screen and (min-width: 250px) and (max-width: 852px) {
        .classAttendanceContainer {
            padding: 0rem 0.5rem 1rem 0.5rem;

            .classAttCardDiv {
                height: 14.8rem !important;

                .classAttDetailsCard {
                    flex-wrap: wrap;

                    .emojiCommentDiv {
                        padding-right: 5px;
                    }
                }
            }
        }

        .ClassAttendanceMainDiv-NSC {
            flex-wrap: wrap;
            justify-content: center;
        }

    }

    @media only screen and (min-width: 250px) and (max-width: 375px) {
        .classAttParentContainer {
            flex-wrap: wrap;
        }

        .classAttendanceContainer {
            padding: 0rem 0.5rem 1rem 0.5rem;

            .classAttCardDiv {
                height: 14rem !important;

                .classAttDetailsCard {
                    flex-wrap: wrap;

                    .emojiCommentDiv {
                        padding-right: 5px;
                    }
                }
            }
        }

        .ClassAttendanceMainDiv-NSC {
            flex-wrap: wrap;
            justify-content: center;
        }

        .buttonMarginTop-NSC {
            margin-top: 1rem;
        }
    }

    @media only screen and (min-width: 539px) and (max-width: 837px) {
        .classAttendanceContainer {
            padding: 0rem 0.5rem 1rem 0.5rem;

            .classAttCardRangeDiv {
                height: 10.8rem !important;
                flex-wrap: wrap;

                .classAttDetailsCard {
                    flex-wrap: wrap;

                    .emojiCommentDiv {
                        padding-right: 5px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 406px) and (max-width: 538px) {
        .classAttendanceContainer {
            padding: 0rem 0.5rem 1rem 0.5rem;

            .classAttRangeParentContainer {
                flex-wrap: wrap;

                .classAttCardRangeDiv {
                    height: 10.8rem !important;
                    flex-wrap: wrap;

                    .classAttDetailsCard {
                        flex-wrap: wrap;

                        .emojiCommentDiv {
                            padding-right: 5px;
                        }
                    }
                }

                .showAttScoreBox {
                    margin: 0.3rem 0rem 0.8rem 0rem;
                }
            }
        }
    }

    @media only screen and (min-width: 357px) and (max-width: 406px) {
        .classAttendanceContainer {
            padding: 0rem 0.5rem 1rem 0.5rem;

            .classAttRangeParentContainer {
                flex-wrap: wrap;

                .classAttCardRangeDiv {
                    height: 16.8rem !important;
                    flex-wrap: wrap;

                    .classAttDetailsCard {
                        flex-wrap: wrap;

                        .emojiRangeDiv {
                            padding-left: 0px;
                        }

                        .emojiCommentDiv {
                            padding-right: 5px;
                        }
                    }
                }

                .showAttScoreBox {
                    margin: 0.3rem 0rem 0.8rem 0rem;
                }
            }
        }
    }

    @media only screen and (min-width: 250px) and (max-width: 357px) {
        .classAttendanceContainer {
            padding: 0rem 0.5rem 1rem 0.5rem;

            .classAttRangeParentContainer {
                flex-wrap: wrap;

                .classAttCardRangeDiv {
                    height: 33rem !important;
                    flex-wrap: wrap;

                    .classAttDetailsCard {
                        flex-wrap: wrap;

                        .emojiCommentDiv {
                            padding-right: 5px;
                        }

                        .emojiRangeDiv {
                            flex-wrap: wrap;
                            padding-left: 100px;

                            .emojiIcons {
                                height: 75px;
                            }

                            .emojiIconsFair {
                                height: 75px;
                            }
                        }
                    }

                    .emojiCommentsDiv {
                        width: 100%;
                    }
                }

                .showAttScoreBox {
                    margin: 0.3rem 0rem 0.8rem 0rem;
                }
            }
        }
    }
}