.bottom-color-panel {
    width: 102%;
    position: relative;
    // bottom: -20px;
    bottom: 0;

    .grade-bottom-panel {
        padding: 0px !important;
        .card-body {
            height: 58px;
            padding: 10px 25px;
            box-shadow: 0px -2px 4px #2222221a;
        }
        .text {
            font: normal normal 600 16px/22px Open Sans;
            margin-right: 20px;
        }
        .text-sec {
            display: inline-flex;
            margin-top: 8px;
        }
        .grade-bottom-panel-bottom {
            margin-top: 65px;
        }
    }
}

@media only screen and (max-width: 1300px) {
    .bottom-color-panel {
        width: 100%;
        position: relative;
        bottom: -10px;
    }
}

@media only screen and (max-width: 1040px) {
    .bottom-color-panel {
        width: 99%;
    //     position: relative;
    // bottom: -80px;
    }
}

@media only screen and (max-width: 1300px) {
    .classGradeBookAddContainer {
        padding: 0.8rem;
    }
    .bottom-color-panel {
        width: 100%;
        position: relative;
        bottom: -10px;
    }
}
