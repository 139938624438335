body {
    background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);
}
.studentMainContainer {
   background: #F0F0F0 ;
   height: 100%;
   overflow-x: hidden;
}
.NoValueDiv{
   height: 5rem;
   padding: 2rem;
}
.NotAvaliable{
   font-size: 1.5rem;
   font-weight: 600;
   color: #BBBB;
}

/* ------------Profile Div----------- */
.studentProfileSectionDiv{
   width: 100%;
   background: #ffff;
   justify-content: space-between;
   display: flex;
   padding: 1rem 3rem 1rem 3rem;

}
.studentProfileInputTypeAdContact {
   .MuiOutlinedInput-input {
      padding: 0;
   }
}
.studentProfileDiv{
   text-align: left
}
.studentDummyDivProfile {
   width: 7rem;
   height: 7rem;
   background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
   box-shadow: 1px 2px 3px #0000001A;
   border-radius: 50rem;
   margin-top: 0rem;
   text-align: center;
}
.studentImageDummyText {
   /* padding: 1.2rem 0rem 0rem 1.2rem; */
   color: white;
   padding-top: 35%;
   font-size: 2rem;
   text-transform: uppercase;
}
.studentProfileimage{
   width: 7rem;
   height: 7rem;
   border-radius: 10rem;
}
.studentEditButton{
   width: 7rem !important;
   height: 2rem !important;
   border-radius: 2rem !important;
   background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 4px #2222221A !important;
   display: inline-flex !important;
   justify-content: center !important;
   font-size: .6rem !important;
   margin: .5rem 1rem .5rem 1rem;
}
.studentButtonBorderRadius{
   border-radius: 8px!important;
}
.objectFitCover {
   object-fit: cover;
}
.studentEditIcon{
   font-size: 1rem
}
.studentEditText{
       color: white;
       font-size: .8rem;
   
}
.studentProfileName{
   color: #222222;
   font-size: 1.2rem;
   font-weight: 600;
   margin: 0rem;
   margin-top: 1rem;
}
.studentProfileEmail{
   font-size: .8rem;
}
.linkWithText{
   color: #386cb5;
   text-decoration-line: underline;
   cursor: pointer;
}
/* ------------Profile Div----------- */

/* ------------OR Div----------- */
.mainQRDiv{
   text-align: center;
}
.QRImage{
   height: 8rem;
}
.studentProfileMainNote{
   color: #000;
   font-size: 1rem;
   font-weight: 600;
   margin-bottom: .3rem;
}

.studentProfileNote{
   font-size: .8rem;

}
/* ------------OR Div----------- */



/* ------------Additional Contacts------ */
.additionalContactMainDiv{
   width: 100%;
   padding: 1rem;
   .studentButtonBorderRadius {
       border-radius: 8px!important;
   }
}
.additionalContactMainTextDiv{
   width: 100%;
   justify-content: space-between;
   display: flex;

}
.additionalContactText{
   color: #222222;
   font-weight: 600;
   font-size: 1.3rem;
   padding-top: .8rem;
}
.addButtonforContacts{
   width: 8rem;
   height: 2.4rem;
   border-radius: 2rem !important;
   background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 4px #2222221A !important;
   display: inline-flex !important;
   justify-content: center !important;
   font-size: .6rem !important;
   margin: .5rem 1rem .5rem 1rem;
}
.addContactButtonIcon{
   font-size: 1.5rem;
}
.addContactsText{
   font-size: 1rem;
   font-weight: 600;
}
/* ------------Additional Contacts------ */

/* ----------------Additional Card----------- */
.additionalCOntactCardMainDiv{
   width: 100%;
   padding: 1rem;
   overflow-y: hidden;
   overflow: auto;
   border-radius: .3rem;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}
.additionalCard{
   width: 25rem;
   height: 9rem;
   margin: 0rem 1rem 1rem 0rem;
   /* min-width: 335px !important; */
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0rem 0.2rem 0.5rem #0000001A;
   border-radius: .5rem;
   opacity: 1;
   float: left;
   padding: .1rem .3rem .1rem .3rem;
}
.additionalCardInfo{
   width: 100%;
   justify-content: space-between;
   border-bottom: 1px solid #DDDDDD;
   height: 5.5rem;
   display: inline-flex;
   padding: 1rem .5rem 0rem .5rem;
}
.additionalNameInfoText{
   color: #000;
   font-size: 1.2rem;
   font-weight: 600;
   margin-bottom: .3rem;
}
.additionalEmailInfoText{
   color: #AAAAAA;
   font-size: 1rem;
}
.additionalRelationInfoText{
   color: #AAAAAA;
   font-size: 1rem;
}
.sharedStatusButton{
   width: 7rem;
   height: 2rem;
   border: 0.5px solid #4479C4;
   border-radius: 16px;
   margin: .2rem 0rem 0rem 0rem;
   align-items: center;
   justify-content: center;
   text-align: center;
   cursor: pointer;
   background: #588FDC1A 0% 0% no-repeat padding-box;
   margin-top: -.2rem;
}
.sharedStatusText{
   margin-top: .2rem;
   font-size: .9rem;
   color: #4479C4;
   
}
.activeStatusButton{
   width: 7rem;
   height: 2rem;
   border: 0.5px solid #FAB700;
   border-radius: 16px;
   margin: .2rem 0rem 0rem 0rem;
   align-items: center;
   justify-content: center;
   text-align: center;
   cursor: pointer;
   background: #FAB7001A 0% 0% no-repeat padding-box;
   margin-top: -.2rem;
}
.activeStatusText{
   margin-top: .2rem;
   font-size: .9rem;
   color: #FAB700;
   
}
.linkedStatusButton{
   width: 7rem;
   height: 2rem;
   border: 0.5px solid  #23BF1D;
   border-radius: 16px;
   margin: .2rem 0rem 0rem 0rem;
   align-items: center;
   justify-content: center;
   text-align: center;
   cursor: pointer;
   background: #e8f4e8 0% 0% no-repeat padding-box;
   margin-top: -.2rem;
}
.linkedStatusText{
   margin-top: .2rem;
   font-size: .9rem;
   color:  #23BF1D;
}
.additionalCardAction{
   width: 100%;
   justify-content: space-between;
   display: inline-flex;
   padding: 1rem .5rem 0rem .5rem;
}

.editDivBlackAdditional{
   display: inline-flex;
    color: #222222;
    margin: 0rem 1rem 0rem 1rem;
    cursor: pointer;
}
.deleteDivRedAdditional{
   display: inline-flex;
    color: #FF4444;
    cursor: pointer;
}
.editIconAdditional{
   font-size: 1.4rem;
}
/* ----------------Additional Card----------- */


/* ----------------Additional Info Card----------- */
.additionalInfoMainDiv{
   width: 100%;
   padding: 1rem;
   .studentButtonBorderRadius {
       border-radius: 8px!important;
   }
}
.additionalInfoCardMainDiv{
   width: 100%;
   /* height: 20rem; */
   padding: 1rem;
   background: #ffff;
   overflow-y: hidden;
   overflow: auto;
   border-radius: .3rem;

   /* width: 100%;
   margin-top: 1rem; */
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}
.yellowCard{
   width: 24rem;
   height: 9rem;
   margin: 0rem 1rem 1rem 0rem;
   background: transparent linear-gradient(270deg, #FFE996 0%, #F8DB6E 100%) 0% 0% no-repeat padding-box;
   box-shadow: 0rem 0.2rem 0.5rem #0000001A;
   border-radius: .5rem;
   opacity: 1;
   /* float: left; */
   padding: .1rem .3rem .1rem .3rem;
}
.yellowCardAction{
   width: 100%;
   justify-content: space-between;
   border-bottom: 1px solid #f9dc70;
   height: 3rem;
   display: inline-flex;
   padding: 1rem .5rem 0rem .5rem;
}
.yellowCardTextDiv{
   padding: 5px;
   overflow-y: hidden;
   overflow: auto;
   height: 5rem;
}
.yelloCardText{
   color: #222222;
   font-size: .9rem;
   font-weight: 600;
   text-align: left;
   word-wrap: break-word;
}
.modalNW-justify-center {
   justify-content: center!important;
   padding-top: 2rem!important;
}
/* ----------------Additional Info Card----------- */


/* ----------------Modal Card----------- */

.linkModalMain{
   background: #FFFFFF;
   /* min-height: 400px;
   max-height: calc(100vh - 150px); */
   overflow: auto;
   border-radius: 1rem !important;
}
.linkModalHeader{
   width: 100%;
   text-align: center;
}
.linkModalHeadind{
   font-size: 1.5rem;
   font-weight: 600;
}
.linkRelationShipText{
   font-size: 1.2rem;
   font-weight: 600;
}
.linkRelationShipDiv{
   width: 100%;
   text-align: center;
}
.linkButtonforSelect{
   width: 6rem;
   height: 2.4rem;
   border-radius: 2rem !important;
   background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 4px #2222221A !important;
   display: inline-flex !important;
   justify-content: center !important;
   font-size: .6rem !important;
   margin: .5rem 1rem .5rem 1rem;
}
.linkSelectedText{
   font-size: 1rem;
   font-weight: 600;
}
.linkButtonforUnSelect{
   width: 6rem;
   height: 2.4rem;
   border-radius: 2rem !important;
   background: transparent  !important;
   box-shadow: 0px 1px 4px #2222221A !important;
   display: inline-flex !important;
   justify-content: center !important;
   font-size: .6rem !important;
   margin: .5rem 1rem .5rem 1rem;
}
.linkUnSelectedText{
   font-size: 1rem;
   font-weight: 600;
   color:#386CB5
}
.linkChechBoxDiv{
   display: inline-flex;
   margin-top: 1rem;
}
.linkCheckBoxColor {
   color: #386cb5;
   cursor: pointer;
   margin-right: .5rem;
}
.linkMainDivForCenter{
   width: 100%;
   text-align: -webkit-center;
   margin-top: 1rem;
}
.linkCancelButtonDiv{
   width: 20rem;
   justify-content: space-between;
   display: flex;
   padding: 0rem 4rem 0rem 4rem;
}
.linkCancelButton{
   color: #386cb5;
   font-weight: 600;
   font-size: 1rem;
   padding-top: .5rem;
   cursor: pointer;
}
.linkSubmitButton{
   width: 6rem !important;
   height: 2.3rem !important;
   border-radius: 2rem !important;
   background: transparent linear-gradient(270deg,#386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 4px #2222221A !important;
   display: inline-flex !important;
   justify-content: center !important;
   font-size: .6rem !important;
   margin: .1rem 0rem .1rem 0rem;
   border-color: #386CB5 !important;
   cursor: pointer;
}
.linkSubmitButtonText{
       color: white;
       font-size: 1rem;
       font-weight: 600;
   
}
/* ----------------Modal Card----------- */


/* ---------additional Modal--------- */
.mainFieldDivAdditionalModal{
   width: 100%;
   justify-content: space-between;
   display: inline-flex;
   padding-bottom: .5rem;
   border-bottom: 1px solid #DDDDDD;

}
.inputFieldLabelDiv{
   padding-top: 1.9rem;
}
.inputFieldLabelText{
   margin: 0rem;
   font-weight: 600;
   font-size: 1rem;
}
.inputFieldAdditionalModal{
   margin: 0rem;
   width: 70%;
}

.textAreaDiv{
   width: 100%;
   text-align: center;
   margin: 0rem 0rem 2rem 0rem;
}
.textAreaCss{
   border: 0.75px solid #DDDDDD;
   box-sizing: border-box;
   border-radius: .3rem;
   height: 10rem; 
   padding-left: .5rem;
   background: #F4F6FA;
   width: 100%;
}


.studentEditSaveButtonDiv {
   .studentButtonBorderRadius {
       border-radius: 8px!important;
   }
}

@media only screen and (min-width: 250px) and (max-width: 590px) {
   .studentProfileSectionDiv{
       padding: 1rem .5rem 1rem .5rem;
       display: block;
   
   }
   .studentProfileDiv{
       text-align: center;
   }
   .mainQRDiv{
       text-align: -webkit-center;
       margin-top: 2rem !important;
   }
   .additionalContactMainDiv{
       padding: .1rem;
   }
   .addButtonforContacts {
       width: 7rem;
       height: 2.2rem;
   }
   .addContactButtonIcon{
       font-size: 1.2rem;
   }
   .addContactsText {
       font-size: .9rem;
   }
   .additionalContactText{
       font-size: .9rem;
       margin-left: .5rem;
   }
   .NotAvaliable{
       font-size: 1rem;
   }
   .additionalCard{
       width: 100%;
   }

   .additionalInfoMainDiv{
       padding: .1rem;
   }
   
   .yellowCard{
       width: 100%;
   }


   .inputFieldLabelDiv{
       padding-top: 1.9rem;
   }
   .inputFieldAdditionalModal{
       margin: 0rem;
       width: 60%;
   }
   .inputFieldLabelText{
       margin: 0rem;
       font-size: .8rem;
   }

   
   .textAreaCss{
       width: 100%;
   }


}
