body {
    background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);
}
.forgotMainContainer {
    height: 100%;
    overflow-x: hidden;
    background-image: url('../../../Assets/LoginModule/background.png') !important;
    background-size: contain;
    background-position: center;
}
.mTop20{
    margin-top: 20px;
}
.mBot20{
    margin-bottom: 3rem;
}

.width300{
    width: 300px;
}


/* ==========main Card Login ==========*/
.verifyCard {
    width: 25rem;
    border-radius: 1rem;
    padding: 2rem 3rem 2rem 3rem;
    margin: auto;
    text-align: center;
    margin-top: 3rem;
    border-color: #BBBBBB;
    background: transparent;
}

.mainIcon{
    max-width: 260px;
}

.proceedTextDivVerify {
    padding: 0px;
    align-items: left;
    text-align: left;
    margin-top: 70px;
}
.proceedTextVerify{
    margin: 0px;
    margin-bottom: .2rem;
    font-weight: 500;
}
.loginInput p {
    margin: 0;
    font-size: 17px;
}

.verifyText {
    font-size: 1.3rem;
    color: #386cb5;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    /* padding-bottom: 0.5rem; */
    /* border-bottom-style: solid;
    width: fit-content; */
}


/* ==========Input Div ==========*/
.inputLableDiv{
    margin-top: 1rem;
    text-align: left;
}
.labelTextForgot{
    margin: 0px
}

.inputIcon {
    width: 1.2rem;
    height: 1.1rem;
}

.inputTypeVerify{
    border: 00.75px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 0.5rem;
    width: 19rem;
    height: 3rem;
    padding-left: 2.2rem;
}


.inputTypeDivlForgot {
    margin-top: .5rem;
    position: relative;
    display: inline-block;
}

.imageICon{
    position: absolute;
    left: 0.5rem;
    top: 0.7rem;
}
.textResend{
    font-size: 1rem;
    font-weight: bold;
    color: #386CB5;
    cursor: pointer;

}
/* ==========Input Div ==========*/

/* ==========Keep me Forgot Div ==========*/
.checkIcon{
    width: 1rem;
    height: 1rem;
    margin-right: 0rem;
 }
 .keepMeText{
    font-size: 0.9rem;
 }
/* ==========Keep me Forgot Div ==========*/


/* ==========Button Div ==========*/

.verifyBtn Button {
    border-radius: 1.9rem;
    height: 2.4rem;
    padding: .4rem;
    width: 19rem;
    background-image: linear-gradient(to left , #386cb5, #669FED);
    font-size: 1rem;
    font-weight: 600;
}

.verifyBtn {
    padding: 1.2rem 0 0 0;
}
.backText{
    font-size: 0.9rem;
    /* margin-left: 20px */
 }
 .backTextColour{
    color: #386CB5;
    cursor: pointer;
    font-weight: 600;
 }
/* ==========Button Div ==========*/


@media only screen and (min-width: 250px) and (max-width: 420px) {
    .verifyCard {
        padding: 2rem 4rem 2rem 3rem;
    }

/* ==========Input Div ==========*/

}
.maininputTypeVerify{
    margin-top: .5rem;
    position: relative;
}

.inputStyle{
    width: 3rem !important;
    text-align: center;
    border: none;
    border: 00.75px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 0.5rem;
    height: 3rem;
}

