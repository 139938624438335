.br-8 {
    border-radius: 0.5rem !important;
}

.mt-15 {
    margin-top: 0.9375rem;
}

.mb-15 {
    margin-bottom: 0.9375rem;
}

.pr-7-5 {
    padding-right: 0.4375rem !important;
}

.pl-7-5 {
    padding-left: 0.4375rem !important;
}
.class-note-classname {
    // display: inline-block;
    width: 134px;
    // white-space: nowrap;
    // overflow: hidden !important;
    // text-overflow: ellipsis;
}
.CNA-picture-video-sec .deleteImgBtn {
    margin-left: -0.5rem;
    margin-top: -0.375rem;
    background: red;
    border-radius: 1.25rem;
    cursor: pointer;
    
  }
  .CNA-picture-video-sec {
    .img-div-only {
        padding: 0.8rem 1.913rem;
    background-color: #0000002E;
    cursor: pointer;
    }
  }
  
.CNA-picture-video-modal {
    .modal-dialog {
        display: flex!important;
        max-width: 750px;
    }
    .delete-btn {
        min-width: auto;
    }
    .modal-header {
        justify-content: space-between;
    }
}
.doc-modal-warning {
    .modal-header {
        justify-content: space-between!important;
    }
}
.addClassNoteMainContainer {
    background: #f0f0f0;
    height: 100%;
    padding: 0rem 3rem 0 3rem;
    overflow-x: hidden;

    a:focus,
    .btn:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .action-btn {
        padding: 0;
        background: transparent;
        border: none;
        margin: 0rem .5rem 0rem .5rem;

    }

    .note-header {
        .card-body {
            padding: .8rem
        }
    }

    .note-header-title {
        font: normal normal 600 0.875rem/1.188rem Open Sans !important;
    }

    .noteDate {
        display: inline-flex;
        font: normal normal normal 0.875rem/1.188rem Open Sans !important;
    }

    .left-section {

        .card-body {
            min-height: 60vh;
        }
    }

    /* css for date picker */
    .noteDate .react-datepicker-wrapper {
        margin: auto 0;
        margin-right: 5px;
        display: inline-block;
    }

    .noteDate .react-datepicker__header {
        background-color: #ffffff;
    }

    .addClassNoteMainContainer .noteDate .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        border-bottom-color: #ffffff;

    }

    /* css for date picker */

    .addClassNoteMainContainer .note-header-title img {
        margin-right: 0.3125rem;
        margin-top: -0.1875rem;
    }

    .CNA-note-header-title {
        text-align: left;
        font: normal normal 600 1rem/1.375rem Open Sans;
        color: #222222;
        margin: auto 0px;
    }


    .CNA-note-header-title-date {
        margin: 1px 0px 0px 0px;
    }
    .CNA-datepicker {
        .react-datepicker {
            position: relative;
            right: 15px;
        }
        .react-datepicker__triangle {
            left: 22px!important;
        }
    }

    .CNA-note-header-right-section {
        font: normal normal normal 0.8125rem/2.125rem Open Sans;
        text-align: right;
        white-space: nowrap;

        .react-datepicker__tab-loop {
            display: inline-flex;


            .react-datepicker-popper {
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(196px, 34px, 0px);

                .react-datepicker__month-container {
                    display: none;
                }

                .react-datepicker__navigation {
                    display: none;
                }

                .react-datepicker__time-container {
                    border-left: none;
                }
            }
        }
    }

    .reminderIcon {
        margin: 0 0.5rem 0 0;
        margin-top: -3px;
    }

    .divider {
        border: 0.0625rem solid #dddddd;
        margin: 0 0.625rem;
    }

    .profile-card {
        margin-top: 1rem;
        text-align: left;
        display: flex;

        .profile-col {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;

            .imageDummyRosterProfile {
                width: 3rem;
                height: 3rem;
                background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
                box-shadow: 1px 2px 3px #0000001A;
                border-radius: 50rem;
                margin: 0rem 0.4rem 0.4rem 0rem;
                text-align: center;
                opacity: .8;

                .rosterProfileImageText {
                    padding: 10px 0px 0px 0px;
                    color: white;
                    margin: 0px !important;
                    text-transform: uppercase;
                    cursor: default;

                }
                .rosterProfileImageTextPadding {
                    padding: 11px 0px 0px 0px;
                }
            }
        }
    }

    .CNA-left-panel-card {
        padding: 1rem;
    }

    .profile-card-img {
        width: 3.125rem;
        height: 3.125rem;
        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
        border-radius: 50rem;
        float: left;
        margin: .6rem;
    }

    .profile-img {
        width: 3rem !important;
        height: 3rem !important;
        border-radius: 50% !important;
        margin-right: 0.4rem;

    }

    .profile-name {
        font: normal normal 600 1rem/1.375rem Open Sans;
        color: #222222;
    }

    .profile-mail {
        font: normal normal normal 0.875rem/1.188rem Open Sans;
        color: #777777;
    }

    .linked-contact-card ol {
        font: normal normal normal 0.875rem/1.188rem Open Sans;
        padding: 0 0 0 0.8125rem;
        text-align: left;
        line-height: 200%;
        margin: 0;
    }

    .linked-contact-card ol span {
        float: right;
        font: normal normal normal 0.75rem/1.063rem Open Sans;
        color: #aaaaaa;
    }

    .CNA-add-assignment-sec textarea {
        font: normal normal normal 0.8125rem/1.125rem Open Sans !important;
        background: #f4f6fa 0% 0% no-repeat padding-box;
        border: 0.0469rem solid #cccccc;
        min-height: 7.3rem;
    }

    .CNA-add-assignment-sec .title {
        text-align: left;
        font: normal normal 600 0.8125rem/1.875rem Open Sans;

    }

    .CNA-add-assignment-sec .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: #44ce3e;
        background-color: #44ce3e;
        width: 3.75rem;
        height: 2rem;
        border-radius: 1.25rem;
        margin-left: -1.5rem;
        margin-top: -0.375rem;
    }

    .CNA-add-assignment-sec .custom-switch .custom-control-input:checked~.custom-control-label::after {
        left: calc(-2.25rem + 2px);
        background-color: #fff;
        width: 1.625rem;
        height: 1.625rem;
        border-radius: 1.25rem;
        margin-left: -0.5rem;
        margin-top: -0.3125rem;
    }

    .CNA-add-assignment-sec .custom-switch .custom-control-label::before {
        left: -2.25rem;
        pointer-events: all;
        color: #fff;
        width: 3.75rem;
        height: 2rem;
        border-radius: 1.25rem;
        margin-left: -1.5rem;
        margin-top: -0.375rem;
    }

    .CNA-add-assignment-sec .custom-switch .custom-control-label::after {
        left: calc(-3.2rem + 2px);
        width: 1.625rem;
        height: 1.625rem;
        border-radius: 1.25rem;
        margin-left: -0.5rem;
        margin-top: -0.3125rem;
    }

    .CNA-add-media-sec {
        min-height: 18.5rem;
    }

    .CNA-left-panel-grp-txt {
        float: left;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-222222);
        text-align: left;
        font: normal normal 600 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
    }

    .CNA-left-panel-total-std-txt {
        float: right;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-aaaaaa);
        text-align: left;
        font: normal normal normal 12px/17px Open Sans;
        letter-spacing: 0px;
        color: #AAAAAA;
        opacity: 1;
    }

    .CNA-add-media-sec .title {
        text-align: left;
        font: normal normal 600 0.8125rem/1.875rem Open Sans;
        margin-bottom: 0.3125rem;
    }

    .CNA-add-media-sec .title .multimediaTitles {
        display: flex;
        justify-content: space-between;

        .suggestion {
            text-align: left;
            font: italic normal normal 12px/18px Open Sans;
            letter-spacing: 0px;
            color: #AAAAAA;
            opacity: 1;
            align-self: center;
        }
    }

    .CNA-add-media-sec .title p {
        margin-bottom: 0.3125rem;
    }

    .CNA-add-media-sec .hr-divider hr {
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
    }

    .CNA-picture-video-sec {
        display: inline-flex;
        overflow: auto;
        width: 50.63rem;
        margin-top: -0.375rem;
    }

    .CNA-picture-video-sec .note {
        width: 3.75rem;
        min-width: 3.75rem;
        height: 3.75rem;
        // background-color: #00000059;
        margin: 0.5rem 0.625rem 0.5rem 0;
        background-size: cover;
        display: inline-flex;
    }

    .CNA-picture-video-sec .blackLayer {
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
    }

    .CNA-picture-video-sec .img-div {
        padding: 0.8rem 1.313rem;
        background-color: #0000002e;
    }

    .CNA-picture-video-sec .img-div-2 {
        padding: 0.8rem 1.313rem;
        background-color: #ffffff7a;
    }

    .CNA-picture-video-sec .deleteBtn {
        margin-left: -0.5rem;
        margin-top: -0.375rem;
        background: red;
        border-radius: 1.25rem;
        cursor: pointer;
    }

    .profile-card .timeline-header {
        font: normal normal 600 0.875rem/1.188rem Open Sans;
        color: #777777;
    }

    .profile-card .timeline-header .btn {
        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
        border-radius: 0.875rem;
        font: normal normal normal 0.6875rem/0.9375rem Open Sans;
        letter-spacing: 0;
        color: #ffffff;
        width: 4.25rem;
        padding: 0.3125rem;
    }

    .audit-sec {
        display: inline-flex;
        overflow-x: auto;
        overflow-y: hidden;
        width: 50.63rem;
    }

    .audit-sec audio {
        border-radius: 0.375rem;
        width: 15.63rem;
        max-width: 15.63rem;
        margin: 1.375rem 0 0 0;
        min-width: 15.63rem;
        display: inline-flex;
        height: 1.125rem;
        outline: none !important;
    }

    .audio-filename {
        margin-bottom: 0 !important;
        height: 0.375rem !important;
        margin-left: 2.25rem;
    }

    .audit-sec .deleteBtn {
        margin-left: -1.638rem;
        background: red;
        border-radius: 1.25rem;
        cursor: pointer;
        margin-right: 0.375rem;
        margin-top: .1rem;
    }

    .audit-sec .audio-sub-div {
        background: #f1f3f4;
        height: 3.438rem;
        margin: 0.5rem 0.9375rem 0.5rem 0rem;

        .player {
            outline: none;
        }
    }

    .audit-sec .audio-div {
        display: inline-flex;
    }

    .audio-sec {
        display: flex;
    }

    .add-media {
        float: left;
        margin-top: 1px;
    }

    .btn-add-media {
        height: 3.938rem;
        padding: 0 !important;
        border: 0 !important;
        background: transparent !important;
        margin-right: 0.75rem !important;
    }

    .btn-add-media input[type="file"] {
        opacity: 0;
        width: 53px;
        position: absolute;
        height: 53px;
        cursor: pointer;
    }

    .addEditNoteTimeLine .vertical-timeline::before {
        background: #cccccc !important;
        top: 0.9375rem;
        left: 1.875rem;
        height: 94%;
    }

    .addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element-icon {
        left: 0;
        background: #cccccc !important;
    }

    .addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }

    .addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element-content {
        width: auto;
        margin-left: 3.125rem;
        box-shadow: none;
        background: transparent;
        min-height: 0.0625rem;
    }

    .addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element-content .vertical-timeline-element-date {
        left: -3.125rem;
        top: 0.125rem;
        width: unset;
    }

    .addEditNoteTimeLine .vertical-timeline {
        padding: 1em 0;
    }

    .addEditNoteTimeLine .attached-tag {
        margin: 0 0 0 0.5rem;
    }

    .addEditNoteTimeLine .vertical-timeline--one-column .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
        right: unset;
        top: -0.55rem;
    }

    .add-media-modal .modal-title {
        font: normal normal 600 0.875rem/1.0625rem Open Sans !important;
        display: inline-flex;
    }

    .add-media-modal .modal-title input {
        font: normal normal 600 0.875rem/1.0625rem Open Sans !important;
        padding: 0;
        height: 26px;
        border: none;
        box-shadow: none;
    }

    .add-media-modal .btn {
        padding: 0 5px 0 0;
    }

    .add-media-modal .btn:focus {
        box-shadow: none !important;
    }

    .add-media-modal .fa-pencil {
        color: #aaaaaa;
    }

    .add-media-modal {
        margin-top: 5rem;
    }

    .add-media-modal .modal-title {
        font: normal normal 600 1rem/1.375rem Open Sans !important;
        margin-bottom: 0.625rem;
    }

    .add-media-modal .modal-header {
        padding: 0.8rem 1rem 0;
        border-radius: 0.5rem;
    }

    .add-media-modal .modal-body {
        margin-top: 0.375rem;
        justify-content: center;
        min-height: 9.6875rem;
    }

    .add-media-modal .modal-footer {
        justify-content: center;
    }

    .add-media-modal .modal-footer button {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        padding: 0;
    }

    .add-media-modal .modal-footer .btn-danger {
        background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
    }

    .add-media-modal .modal-footer .btn-success {
        background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
    }

    .add-media-modal .modal-footer button img {
        margin-top: -0.1875rem;
        margin-left: 0.0625rem;
    }

    .add-media-modal .modal-footer .deleteIcon img {
        margin-top: -0.0625rem;
        margin-left: -0.0625rem;
    }

    .add-media-modal .modal-content {
        border-radius: 0.5rem;
    }

    .voice-recorder {
        width: 100%;
        height: 3.875rem;
    }

    .record-btn {
        cursor: pointer;
    }

    .record-btn .btn {
        background: transparent;
        border: none;
    }

    .record-btn .playAfterPause {
        width: 42px;
        height: 42px;
        border: 1px solid black !important;
        border-radius: 50px;

        .playIconPause {
            margin-left: 2px;
        }
    }

    .record-info {
        font: normal normal normal 0.625rem/0.875rem Open Sans;
        color: #888888;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .record-btn .btn-primary:not(:disabled):not(.disabled).active,
    .record-btn .btn-primary:not(:disabled):not(.disabled):active,
    .record-btn .btn-primary:hover,
    .record-btn .show>.btn-primary.dropdown-toggle,
    .record-btn .btn-primary.focus,
    .record-btn .btn-primary:focus {
        color: #fff;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }

    .display-none {
        display: none !important;
    }

    .timer {
        position: absolute;
        top: 2.0625rem;
        font: normal normal 600 0.875rem/1.188rem Open Sans;
        letter-spacing: 0rem;
        color: #222222;
        margin-left: 3.75rem;
    }

    .level-score-sec {
        border-left: 0.0625em solid #cccccc;
        margin-bottom: 0;
        text-align: right;
    }

    .level-score-sec .sub-div {
        display: inline-flex;
        line-height: 2.5em;
    }

    .level-score-sec .sub-div input {
        width: 100%;
        margin-left: 0.625em;
        background: #f4f6fa 0% 0% no-repeat padding-box;
        border: 0.0469em solid #cccccc;
        font: normal normal normal 0.875em/1.1875em Open Sans;
        color: #bbbbbb;
        color: #000 !important;
    }

    .multimedia-suggestion {
        font: italic normal normal 13px/18px Open Sans !important;
        margin: auto 8px !important;
        color: #aaaaaa !important;
    }

    .audio-suggestion {
        float: right;
        font: italic normal normal 13px/18px Open Sans;
        color: #aaaaaa;
    }

    .headerBar {
        width: 100%;
        height: 80px;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        margin-bottom: 20px;
        padding: 1rem 0.5rem 1rem 0rem;
        border-radius: 0px 0px 8px 8px;
    }

    .note-header-title {
        display: flex;
        font: normal normal 600 0.875rem/1.188rem Open Sans !important;
    }

    .CNA-stdNoteBackDiv {
        text-align: left;
        font: normal normal 600 1rem/1.375rem Open Sans;
        color: #222222;
        cursor: pointer;
    }

    .CNA-stdNoteDetailTextDiv {
        border-left: 2px solid #eee;
        margin-left: 15px
    }

    .CNA-stdNoteDisplayFlex {
        display: flex;
        cursor: pointer;
        margin-top: 9px;
        margin-left: 15px;

        p {
            margin: 0px !important;
            padding: 2px 0px 0px 0px !important;
            font-size: 16px;
        }

        i {
            color: #487FCC;
            font-size: 24px;
            cursor: default;
        }
    }


    .CNA-currentYear {
        color: #487FCC;
        margin-bottom: 20px;
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
        cursor: pointer;
    }

    .CNA-stdNoteGrade1 {
        color: #487FCC;
        cursor: pointer;
    }

    .CNA-stdNoteGrade2 {
        color: #222222;
        font-size: 1.25rem;
        font-weight: 600;
        text-align: left;
        cursor: default;
    }



    .CNA-class-txt {
        display: flex;
        cursor: pointer;
        margin-left: 15px;

        p {
            margin: 0px !important;
            padding: 0px 0px 0px 0px !important;
            font-size: 16px;
        }
    }

    .CNA-class-txt-2 {
        color: #487FCC;
        cursor: default;
        font-weight: 600;
    }

    .CNA-class-txt-1 {
        color: #222222;
        font-weight: 600;
        text-align: left;
        cursor: default;
    }

    .powerCenter {
        text-align: -webkit-center;
    }

    .CNA-NoteFilterSection {
        min-height: 3.75rem;
    }

    .CNA-NoteFilterSection .card-body {
        padding: 0.625rem 1.25rem;
    }

    .CNA-NoteFilterSection .CNA-addClassNoteMainContainer {
        margin: auto;
        padding-top: 0px;
    }

    .CNA-addClassNoteMainContainer .form-group {
        width: unset;
    }

    .CNA-addClassNoteMainContainer .noteDate {
        display: inline-flex;
        font: normal normal normal 0.875rem/1.188rem Open Sans !important;
    }

    /* css for date picker */
    .CNA-addClassNoteMainContainer .noteDate .react-datepicker-wrapper {
        margin: auto 0;
        margin-right: 5px;
    }

    .CNA-addClassNoteMainContainer .noteDate .react-datepicker__header {
        background-color: #ffffff;
    }

    .CNA-addClassNoteMainContainer .noteDate .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        border-bottom-color: #ffffff;
    }

    .CNA-add-Level-Score-sec {
        height: 4.375rem;
    }

    .CNA-add-Level-Score-sec .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0.938rem;
    }

    .level-row {
        padding: 0px 12px;
    }

    .level-title {
        padding: 0px !important;
    }

    .level-sub-div .form-control {
        width: 70% !important;
        margin-left: 0.625rem;
        background: var(--unnamed-color-f4f6fa) 0% 0% no-repeat padding-box;
        border: 0.75px solid var(--unnamed-color-cccccc);
        background: #F4F6FA 0% 0% no-repeat padding-box;
        border: 0.75px solid #CCCCCC;
        border-radius: 8px;
        opacity: 1;
    }

    .score-sub-div .form-control {
        width: 70% !important;
        margin-left: 0.625rem;
        background: var(--unnamed-color-f4f6fa) 0% 0% no-repeat padding-box;
        border: 0.75px solid var(--unnamed-color-cccccc);
        background: #F4F6FA 0% 0% no-repeat padding-box;
        border: 0.75px solid #CCCCCC;
        border-radius: 8px;
        opacity: 1;
    }

    .level-sec {
        padding: 6px 0px !important;
        margin: 0px !important;
        font-weight: 600;
    }

    .score-title {
        padding: 0px !important;
    }

    .score-sub-div {
        padding: 0px 6px !important;
        display: flex;
        direction: row;
        justify-content: left;
    }

    .score-sec {
        padding: 6px 0px !important;
        margin: 0px !important;
        font-weight: 600;
    }

    .contactTextAreaTopDist {
        margin-top: 12px;
    }


    @media screen and (min-width: 1275px) and (max-width: 1470px) {
        .CNA-class-txt {
            display: flex;
            cursor: pointer;
            margin-left: 0px;

            p {
                margin: 0px !important;
                padding: 0px 0px 0px 0px !important;
                font-size: 12px;
            }
        }

        .CNA-note-header-title {
            text-align: left;
            font: normal normal 600 1rem/1.375rem Open Sans;
            color: #222222;
            margin: auto 0px;
            max-width: 140px;
        }

        .CNA-addClassNoteMainContainer .form-group {
            width: 230px;
        }

        .CNA-note-header-right-section {
            width: 230px;
        }

        .CNA-left-panel-total-std-txt {
            float: right;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-aaaaaa);
            text-align: left;
            font: normal normal normal 11px/17px Open Sans;
            letter-spacing: 0px;
            color: #AAAAAA;
            opacity: 1;
            padding-top: 1px;
        }

        .CNA-stdNoteDisplayFlex {
            display: flex;
            cursor: pointer;
            margin-top: 9px;
            margin-left: 15px;

            p {
                margin: 0px !important;
                padding: 0px 0px 0px 0px !important;
                font-size: 13px;
            }

            i {
                color: #487FCC;
                font-size: 20px;
                cursor: default;
            }
        }

    }

    @media only screen and (max-width: 81.25rem) {
        .card-body {
            padding: 0.8rem;
        }

        .CircularProgressbar {
            margin-top: -0.3125rem;
        }

        .action-div {
            margin: auto;
        }

        .CNA-add-media-sec {
            min-height: 17.625rem;
        }
    }

    @media only screen and (max-width: 80rem) {
        .CNA-add-assignment-sec .custom-switch .custom-control-label::before {
            width: 3.125rem;
            height: 1.5625rem;
            border-radius: 1.25rem;
            margin-left: -0.875rem;
            margin-top: -0.1875rem;
        }

        .CNA-add-assignment-sec .custom-switch .custom-control-label::after {
            width: 1.3125rem;
            height: 1.3125rem;
            margin-left: 0.125rem;
            margin-top: -0.1875rem;
        }

        .CNA-add-assignment-sec .custom-control-input:checked~.custom-control-label::before {
            width: 3.125rem;
            height: 1.5625rem;
            border-radius: 1.25rem;
            margin-left: -0.875rem;
            margin-top: -0.1875rem;
        }

        .CNA-add-assignment-sec .custom-switch .custom-control-input:checked~.custom-control-label::after {
            width: 1.3125rem;
            height: 1.3125rem;
            border-radius: 1.25rem;
            margin-left: -0.0625rem;
            margin-top: -0.1875rem;
        }
    }

    @media only screen and (max-width: 1220px) {
        .noteOptionIcons {
            width: 1.375rem;
            height: 1.375rem;
            margin-right: 0.3125rem;
        }

        .addClassNoteMainContainer {
            padding: 0rem 1rem;
        }

        .note-header-select .form-group {
            width: unset;
        }

        .CNA-add-media-sec .hr-divider hr {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }

    @media only screen and (max-width: 991px) {
        .action-div img {
            width: 4.6875rem !important;
        }

        .audit-sec,
        .CNA-picture-video-sec {
            width: 33.3125rem;
        }

        .CNA-note-header-right-section {
            margin-top: 0.5rem;
        }

        .pr-7-5 {
            padding-right: 0.9375rem !important;
        }

        .pl-7-5 {
            padding-left: 0.9375rem !important;
        }
    }

    @media only screen and (max-width: 767px) {
        .CircularProgressbar {
            width: 2.875rem;
        }

        .audit-sec,
        .CNA-picture-video-sec {
            width: 37.5rem;
        }

        .note-header {
            height: 4.063rem;
        }

        .left-section {
            padding: 0.9375rem !important;
            padding-bottom: 0 !important;
        }

        .right-section {
            padding: 0.9375rem !important;
        }
    }

    @media only screen and (max-width: 725px) {

        .audit-sec,
        .CNA-picture-video-sec {
            width: 31.25rem;
        }
    }

    @media only screen and (max-width: 631px) {

        .audit-sec,
        .CNA-picture-video-sec {
            width: 28.125rem;
        }

        .level-score-sec .sub-div input {
            margin-left: 5px;
            padding: 0 4px;
        }
    }

    @media only screen and (max-width: 581px) {

        .audit-sec,
        .CNA-picture-video-sec {
            width: 25rem;
        }
    }

    @media only screen and (max-width: 552px) {
        .audit-sec {
            width: 23.125rem;
        }

        .CNA-picture-video-sec {
            width: 23.125rem;
        }
    }

    @media only screen and (max-width: 552px) {

        .audit-sec,
        .CNA-picture-video-sec {
            width: 18.75rem;
        }

        .CNA-picture-video-sec {
            width: 18.75rem;
        }
    }

    @media only screen and (max-width: 520px) {
        .left-section {
            padding: 0 !important;
        }

        .right-section {
            padding: 0 !important;
        }
    }

    @media only screen and (max-width: 450px) {
        .action-div img {
            width: 4.0625rem !important;
        }
    }
}