.standardBtnContainer {
  width: auto !important;
  .btn {
    border: solid 1px #669fed !important;
  }
}

.template-button-container {
  height: 2.5rem !important;
  border-radius: 0.5rem !important;
  border-color: #669fed !important;
  padding: 0.6rem !important;
  background: #fff !important;
  // box-shadow: 0px 1px 4px #2222221a !important;
  display: inline-flex !important;
  justify-content: center !important;
  // font-size: 0.6rem !important;
  // margin: 0.1rem 0.3rem 0.1rem 0.3rem;
  // border : solid 1px #669FED !important;
  // color : #669FED !important;

  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #386cb5;
  opacity: 1;

  .report-btn-icon {
    width: 0.9rem;
  }
  .report-btn-text {
    //     color: #669FED;
    // font-size: 0.9rem;
    margin-left: 0.5rem;
    // font-weight: 600;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #669fed;
    opacity: 1;
  }
}
.allStandardSidebarCard {
  border-radius: 10px 10px 0px 0px !important;
  //   max-width: 260px;
  .list-group-item {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    text-transform: capitalize;
    opacity: 1;
  }

  .allStandardsHeader {
    height: 43px;
    background: #f1f1ff 0% 0% no-repeat padding-box !important;
    border-radius: 10px 10px 0px 0px !important;
    opacity: 1;
  }
  .card-body {
    font: 14px / 19px "Open Sans";
    letter-spacing: 0px;
    color: rgb(34, 34, 34);
    text-transform: capitalize;
    opacity: 0.5;
    padding: 3rem;
  }
}
.allStandardDefaultSidebarCard {
  border-radius: 0 !important;

  .list-group-item {
    border-left: none !important;
    border-right: none !important;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    text-transform: capitalize;
    opacity: 1;
  }
  .allStandardsDefaultHeader {
    height: 43px;
    background: #f1f1ff 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border-radius: 0 !important;
  }
}
.subStandardsCard {
  .list-group-item {
    border-left: none !important;
    border-right: none !important;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    text-transform: capitalize;
    opacity: 1;
  }
  .backToSatndardHeader {
    background: #f0f0f0 0% 0% no-repeat padding-box;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    opacity: 1;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #3587f7;
    opacity: 1;
  }
}
.standardContentCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px !important;
  opacity: 1;
  .card-header {
    text-align: left;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    // max-height: 35px;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // display: -webkit-box;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    padding-top: 9px;
  }
  .card-body {
    text-align: left;
    font: normal normal normal 14px/22px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }
}

.delete-custom-standard-btn {
  background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0%
    0% no-repeat padding-box;
  width: 100px;
  height: 40px;
  border-radius: 8px !important;
}

.edit-standard-btn-container {
  background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
    0% no-repeat padding-box;
  width: 100px;
  .report-btn-icon {
    width: 0.9rem;
    margin-top: 0.4rem;
  }

  .report-btn-text {
    color: white;
    margin-left: 0.5rem;
  }
}
.closeImport {
  background-color: #fff;
  border: none;
}

.standarddeletepara {
  text-align: center;
  font: normal normal normal 16px/22px Open Sans !important;
  letter-spacing: 0px;
  color: #222222 !important;
  span {
    text-align: center;
    font: normal normal 600 16px/22px Open Sans !important;
    letter-spacing: 0px;
    color: #222222 !important;
  }
}
.save-standard-btn {
width: 107px;
height: 40px;
/* UI Properties */
background: transparent linear-gradient(270deg, #747BEB 0%, #989EFF 100%) 0% 0% no-repeat padding-box;
border-radius: 8px;
opacity: 1;
}
.edit-standard-modal {
  .modal-content {
    height: 210px;
  }
  .MuiOutlinedInput-input {
    height: 32px !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font: normal normal 600 14px/20px Open Sans;
    color: #222222;
    margin-bottom: 0px !important;
    border: 0.75px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 0.3rem;
    height: 3rem;
    background: #F4F6FA;
    width: 100%;
    margin-bottom: 0px !important;
  }
}
.appendstandardModal {
  .modal-content {
    width: 300px!important;
  }
  .MuiTypography-root {
    font : normal normal 500 14px/19px Open Sans !important
  }
  .MuiSvgIcon-root {
    font-size: 1.3rem !important;
  }
  .css-1hbvpl3-MuiSvgIcon-root {
    font-size: 1.3rem !important;
    fill: #707070;
  }
  .Mui-checked {
    color: #3EAB2F !important;
  }
}