body {
  background-image: linear-gradient(to bottom, #ecf3fc, #fafafa);
}

/* .background{
    background-image: url('../../../Assets/LoginModule/back.png') !important;
    background-size: cover;
    background-position: center;
} */
.LoginMainContainer {
  height: 100%;
  overflow: hidden;
  background-image: url("../../../Assets/LoginModule/background.png") !important;
  background-size: contain;
  background-position: center;
  display: flex;
}

.LoginSubContainer {
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .img-div {
    align-self: center;
    max-width: 350px;
    margin-bottom: 30px;
  }
}

.LoginCardDiv {
  width: 500px;
  height: 560px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 3.31rem 2.5rem 3.31rem 2.5rem;

  .LoginTextDiv {
    padding: 0px;
    align-items: left;
    text-align: left;
  }

  .LoginText {
    font-size: 24px;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }

  .ProcessText {
    text-align: left;
    font: normal normal normal 18px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
  }
}

.LoginInputDiv {
  width: 420px;
  height: 48px;
  background: #f7faff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin-top: 20px;
  opacity: 1;
}

.RegistrationBtnDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 2rem;

  .RegistrationBtn {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fec006 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px #0000001a;
    border-radius: 6px;
    opacity: 1;
    cursor: pointer;
  }

  .RegistrationBtnText {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }
}

.bottomDiv {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .text-1 {
    text-align: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 0px;
  }

  .text-2 {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 0px;
  }
}

.mTop20 {
  margin-top: 1rem;
}

.mTop40 {
  margin-top: 4rem;
}

.fLeft {
  float: left;
}

.fRight {
  float: right;
  margin-top: 2px;
}

.width300 {
  width: 300px;
}

.checkBoxColorLogin {
  // color: #33D152;
  // cursor: default !important;
  margin-top: 4.1px;
  font-size: 20px !important;
  // color: #000000 !important;
}

/* ==========main Card Login ==========*/
.logincard {
  width: 25rem;
  border-radius: 1rem;
  padding: 0rem 3rem 2rem 3rem;
  margin: auto;
  text-align: center;
  margin-top: 3rem;
  border-color: #bbbbbb;
  background: transparent;
}

.mainIcon {
  max-width: 260px;
}

.proceedTextDiv {
  padding: 0px;
  align-items: left;
  text-align: left;
  margin-top: 40px;
}

.proceedText {
  margin: 0;
  font-weight: 500;
}

.loginInput p {
  margin: 0;
  font-size: 17px;
}

.login_text {
  font-size: 1.3rem;
  color: #386cb5;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 0.5px;
  /* border-bottom-style: solid;
    border-bottom-width: 0.2rem;
    width: -moz-fit-content;
    width: fit-content; */
}

/* ==========Input Div ==========*/
.inputLableDiv {
  margin-top: 15px;
  text-align: left;
}

.labelTextLogin {
  margin: 0;
  font-weight: 600;
}

.inputIcon {
  width: 1.2rem;
  height: 1.1rem;
  color: #909294;
}

.inputTypeLogin {
  border: 00.75px solid #dddddd;
  box-sizing: border-box;
  border-radius: 0.5rem;
  width: 19rem;
  height: 3rem;
  padding-left: 2.2rem;
}

.inputTypeDivlLogin {
  margin-top: 0.5rem;
  position: relative;
}

.imageICon {
  position: absolute;
  left: 0.5rem;
  top: 0.7rem;
}

.eyeIcon {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  cursor: pointer;
}

/* ==========Input Div ==========*/

/* ==========Keep me Login Div ==========*/
.checkIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
  margin-top: -0.2rem;
}

.keepMeTextLogin {
  font-size: 16px;
  // margin-left: .2rem;
  margin-top: 0px;
}

.Textforgot {
  font-size: 0.9rem;
  color: #0974dd;
  font-weight: 600;
  cursor: pointer;
}

/* ==========Keep me Login Div ==========*/

/* ==========Button Div ==========*/

.SigninBtn Button {
  border-radius: 0.375rem;
  height: 3.125rem;
  padding: 0.5rem;
  width: 100%;
  background-image: linear-gradient(to left, #386cb5, #669fed);
  font-size: 1rem;
  font-weight: 600;
  margin-top: 3rem;
}

.buttonTop {
  margin-top: 10px;
}

.SigninBtn {
  padding: 0.8rem 0 0 0;
}

.registerText {
  font-size: 0.9rem;
  color: #000000;
  font-weight: 600;
}

.registerTextColour {
}

/* ==========Button Div ==========*/

.MuiFormLabel-root-MuiInputLabel-root {
  top: -8px;
}

.MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #1976d2;
  top: -8px;
}

// css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root
// .MuiInputLabel-root > label {
//     background-color:yellow;
//     color: red;
// }

// .MuiTextField-root > .MuiFormLabel-root.Mui-focused {
//     color: green;
// }

@media only screen and (min-width: 250px) and (max-width: 420px) {
  .logincard {
    padding: 2rem 4rem 2rem 3rem;
  }

  .eyeIcon {
    right: 0.5rem;
  }

  /* ==========main Card Login ==========*/

  /* ==========Input Div ==========*/
}
.usersubsplanheight {
  width: 575px;
}
.planContainerDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 46px #00000029;
  border-radius: 8px;
  /* width: 538px; */
  height: 202px;
  .planIconBg {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    border-radius: 8px 0px 0px 8px;
    width: 166px;
    height: 202px;
  }
  .dataPlans {
    text-align: left;
    font: normal normal normal 12px/20px Open Sans;
    letter-spacing: 0px;
    color: #222222;
  }
  .upgradePlanButtonRegsiter {
    height: 35px;
    background: transparent linear-gradient(90deg, #669eec 0%, #3e73bc 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    float: left;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 0 10px;

    .planPrice {
      text-align: left;
      font: normal normal normal 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #ffffff !important;
      font-weight: bold;
      margin-top: 6px;
      display: flex;
      justify-content: center !important;
      cursor: pointer;
    }
  }
  .maxDetailsParaRegister {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    height: 35px;
    justify-content: start;
    padding-left: 15px;
    .annualPlanTypeParaRegister {
      text-align: left;
      font: normal normal 600 12px/14px Open Sans;
      letter-spacing: 0px;
      color: #222222;
      margin-bottom: 10px;
      opacity: 1;
      span {
        color: #376bb4;
      }
    }
    .selectedAnnualPlanPriceRegsiter {
      text-align: left;
      font: normal normal 600 12px/14px Open Sans;
      letter-spacing: 0px;
      color: #376bb4;
      font-weight: bold;
    }
  }
  .recommendBoxRegister {
    background-color: #e6395d;
    border-radius: 0px 0px 10px 10px;
    font: normal normal 600 11px/17px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    position: absolute;
    top: -10px;
    width: 100px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 40px;
  }
}
.registerpaypalParentDiv {
  .cardOfPlanRules {
    width: 100%;
    min-height: 300px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    // background: #FFFFFF 0% 0% no-repeat padding-box;
    // box-shadow: 0px 1px 4px #2222221A;
    border: none;
    text-align: left;
    // font: normal normal normal 12px/21px Open Sans;
    font: normal normal normal 13px/26px Open Sans;
    letter-spacing: 0px;
    color: #222222;

    .dataPlans {
      margin-bottom: 0;
    }
    .refrenceLink {
      color: var(--unnamed-color-222222);
      text-align: left;
      font: normal normal 600 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #222222;
      font-weight: bold;
      opacity: 1;
    }
  }
}
.loginRedirectPara {
  text-align: left;
  font: italic normal normal 14px/20px Open Sans;
  letter-spacing: 0px;
  color: #757171;
  text-align: center;
}
.continue-login-free-btn {
  width: 107px;
  height: 40px;
  // background: transparent linear-gradient(270deg, #747BEB 0%, #989EFF 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(90deg, #669eec 0%, #3e73bc 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.paypalRegisterButtonDiv {
  .zoid-outlet {
    width: 250px !important;
  }
}
.newPaypalBtnClassRegister {
  width: 250px !important;
  // background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box !important;
  .paypal-button {
    background: transparent !important;
    border: none!important;
    color: #fff !important;
  }
}
