.classLessonContainer {
    background: #f0f0f0;
    height: 100%;
    overflow-x: hidden;
    padding: 0rem 2.5rem 1rem 2.5rem;
    .LessonPlanMainDiv {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        flex-wrap: wrap;
        .LessonPlanDetailsMain {
            display: flex;
            align-items: center;
            .ClassBackDiv {
                display: flex;
                padding: 0.8rem;
                background: #eeeeee;
                width: 13rem;
                height: 3.1rem;
                cursor: pointer;
            }
            .lessonBackIcon {
                font-size: 1.3rem;
                padding-top: 3px;
            }
            .lessonClassBactText {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }
        }
        .buttonMarginTop {
            display: flex;
            align-items: center;
            .filterPlan {
                width: 2.5rem !important;
                height: 2.5rem !important;
                border-radius: 2rem !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;
            }
            .addNewLesson {
                // width: 8rem !important;
                // height: 2.5rem !important;
                width: 110px;
height: 45px;
                border-radius: 8px !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;
            }
            .lessonIcon {
                width: 0.9rem;
                margin-top: 0.2rem;
                width: 16px;
height: 16px;
            }
            .lessonFilterIcon {
                width: 1rem;
                margin-top: 0.2rem;
            }
            .viewAttText {
                color: white;
                font-size: 0.9rem;
                margin-left: 0.5rem;
                font: normal normal normal 17px/24px Open Sans;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
            }
        }
        .lessonDetailTextDiv {
            margin-left: 1rem;
            border-left: 1px solid #eeeeee;
            padding: 0.3rem 0rem 0rem 1rem;
            .lessonClassName {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }
            .displayFlexClassLesson {
                display: flex;
                cursor: pointer;
                .calIcon {
                    align-self: center;
                    cursor: default;
                    cursor: not-allowed;
                }
                .lessonClassGrade {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 5px 0px 0px 5px;
                    cursor: default;
                }
                .headingForEdited {
                    display: inline-flex;
                    flex-wrap: wrap;
                    cursor: default;
                    align-items: center;
                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                    }

                    .head {
                        color: #386cb5;
                        text-transform: capitalize;
                        font: normal normal 600 14px/14px Open Sans;
                        margin: 0px;
                    }
                }
                .attClassGrades {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 1px 0px 0px 5px;
                    cursor: default;
                    font-weight: 600;
                }
            }
        }
    }
   
    .groupPanels {
        .MuiCollapse-wrapper {
            background-color: #f0f0f0;
        }
        .MuiExpansionPanelSummary-content {
            background: #aaaaaa;
            height: 1px;
        }

        .MuiExpansionPanelSummary-root .Mui-expanded {
            min-height: 1px;
        }

        .MuiPaper-elevation1 {
            box-shadow: none;
        }

        .MuiExpansionPanel-root.Mui-expanded {
            margin: 0;
        }

        .MuiCollapse-container {
            background: #f0f0f0;
        }

        .MuiExpansionPanelSummary-root {
            background-color: #f0f0f0;
            min-height: 48px !important;
        }
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: 45px !important;
        }
        .MuiExpansionPanel-root:before {
            display: none;
        }

        .confirm-text {
            font: normal normal normal 16px/22px Open Sans;
        }

        .MuiTypography-body1 {
            width: 100%;
            text-align: -webkit-center;

            .title {
                margin-top: -10px;
                background: #f0f0f0;
                width: 150px;
                font: normal normal 600 14px/19px Open Sans;
                color: #222222;

                .groupName {
                    width: 160px;
                    margin-top: -19px;
                    padding: 8px;
                }
            }

            .w-166 {
                width: 165px;
            }

            .action {
                float: right;
                margin-top: -24px;
                background: #f0f0f0;

                img {
                    margin-left: 12px;
                }
            }
            .actionCircle {
                float: right;
                margin-top: -40px;
                background: #f0f0f0;

                img {
                    margin-left: 0px !important;
                }
            }
        }

        .MuiExpansionPanelSummary-expandIcon {
            position: absolute;
            left: 0;
            background: #f0f0f0;
        }

        .MuiIconButton-label {
            .MuiSvgIcon-root {
                border-radius: 20px;
                color: white;
                width: 18px;
                height: 18px;
                background: #aaaaaa 0% 0% no-repeat padding-box;
            }
        }
        .MuiPaper-root {
            .MuiButtonBase-root {
                .MuiAccordionSummary-expandIcon {
                    position: absolute;
                    left: 0;
                }

                background: #f0f0f0;
                .MuiAccordionSummary-content {
                    background: #aaaaaa;
                    height: 1px;
                }
            }
        }
        .MuiAccordion-root:before {
            background-color: #f0f0f0;
        }
        .detail-section {
            .row {
                width: 100%;
            }
        }
        .MuiAccordionDetails-root {
            flex-wrap: wrap;
            padding: 8px 0px 16px 16px;
            .noPlansDiv {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 25px;
                .noPlantext {
                    color: #aaaaaa;
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }
        }
        .classCardDiv {
            width: 24.5rem;
            height: 9rem;
            margin: 0rem 1.1rem 1.1rem 0rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0rem 0.2rem 0.5rem #0000001a;
            border-radius: 0.5rem;
            opacity: 1;
            padding: 0.1rem 0.5rem 0.1rem 0.4rem;

            .classDetailsCard {
                width: 100%;
                border-bottom: 1px solid #dddddd;
                height: 6.4rem;
                justify-content: space-between;
                padding: 0.4rem 0.4rem 0.4rem 0.4rem;
                justify-content: space-between;
                display: flex;
                cursor: pointer;

                .textLeft {
                    text-align: left;
                    .classGradeAndSectionText {
                        color: #777777;
                        margin: 0rem;
                        font-size: 0.85rem;
                        text-transform: capitalize;
                    }
                    .lessonTitle {
                        margin-bottom: 0.3rem;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-transform: capitalize;
                    }
                    .lessonPlan {
                        border: 1px solid #4479c4;
                        border-radius: 15px;
                        width: 70px;
                        margin: 6px 0px 6px 0px;
                        .statusTextPlan {
                            margin: 0rem;
                            font-size: 0.85rem;
                            text-align: center;
                            color: #4479c4;
                            text-transform: capitalize;
                        }
                    }
                    .lessonCurrent {
                        border: 1px solid #4479c4;
                        border-radius: 15px;
                        width: 110px;
                        margin: 6px 0px 6px 0px;
                        .statusTextPlan {
                            margin: 0rem;
                            font-size: 0.85rem;
                            text-align: center;
                            color: #4479c4;
                            text-transform: capitalize;
                        }
                    }
                    .lessonEval {
                        border: 1px solid #23bf1d;
                        border-radius: 15px;
                        width: 83px;
                        margin: 6px 0px 6px 0px;
                        .statusTextEval {
                            color: #23bf1d;
                            margin: 0rem;
                            font-size: 0.85rem;
                            text-align: center;
                            text-transform: capitalize;
                        }
                    }
                    .lessonExe {
                        border: 1px solid #ff9c17;
                        border-radius: 15px;
                        width: 83px;
                        margin: 6px 0px 6px 0px;
                        .statusTextExe {
                            color: #ff9c17;
                            margin: 0rem;
                            font-size: 0.85rem;
                            text-align: center;
                            text-transform: capitalize;
                        }
                    }
                }
            }
            .classTimeline {
                display: flex;
                align-items: center;
                padding: 10px 2px 10px 2px;
                .headingTitles {
                    display: inline-flex;
                    flex-wrap: wrap;
                    flex: 1.3 1;
                    padding-left: 5px;
                    justify-content: flex-start;
                    align-items: end;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        padding-right: 5px;
                        font: normal normal 600 13px/13px Open Sans;
                        color: #777777;
                    }
                    .head {
                        color: #9d9797;
                        text-transform: capitalize;
                        padding-right: 5px;
                        font: normal normal 600 13px/13px Open Sans;
                        margin: 0px;
                    }
                }
                .headingTitlesRight {
                    display: inline-flex;
                    flex-wrap: wrap;
                    flex: 1.3 1;
                    padding-right: 5px;
                    justify-content: flex-end;
                    align-items: center;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        padding-right: 5px;
                        font: normal normal 600 13px/13px Open Sans;
                        color: #777777;
                    }
                    .head {
                        color: #9d9797;
                        text-transform: capitalize;
                        padding-right: 5px;
                        font: normal normal 600 13px/13px Open Sans;
                        margin: 0px;
                    }
                }
            }
        }
    }
    .lessonPlanPanels {
        .MuiAccordion-root {
            margin-bottom: 0;
        }
        .MuiAccordionDetails-root {
            padding-bottom: 0;
        }
    }
}
.popoverLessonPlan {
    /* ----------------PopOver------------------- */
    .popoverOptions {
        display: inline-flex;
        border-bottom: 1px solid gray;
        padding: 0.5rem;
    }
    /* ----------- PopOver---------- */

    /* ----------------PopOver------------------- */
    .editClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        border-bottom: 1px solid #dddddd;
        padding: 0.5rem 0rem 0.5rem 0rem;
        width: 115%;
        .viewLpIcons {
            width: 1rem;
            height: 1.3rem;
            margin-right: 0.3rem;
        }
    }
    .disabledEditClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        border-bottom: 1px solid #dddddd;
        padding: 0rem 0rem 0.5rem 0rem;
        width: 90%;
        opacity: 0.5;
        cursor: default;
    }
    .bs-popover-auto[x-placement^="left"] > .arrow::after,
    .bs-popover-left > .arrow::after {
        border-left-color: #fff !important;
    }
    .editTextClassPopOver {
        font-size: 0.9rem;
        margin-left: 0.1rem;
    }
    .deleteClassPopOver {
        display: inline-flex;
        color: #ff4444;
        cursor: pointer;
        padding: 0.5rem 0rem 0rem 0rem;
    }
    .disabledDeleteClassPopOver {
        display: inline-flex;
        color: #ff4444;
        cursor: pointer;
        padding: 0.5rem 0rem 0rem 0rem;
        opacity: 0.5;
        cursor: default;
    }
    .deleteTextClassPopOver {
        font-size: 0.9rem;
        margin-left: 0.1rem;
    }
    .editClassIcon {
        font-size: 1.3rem;
    }

    .popover {
        width: 7rem !important;
        padding: 0rem !important;
        height: 10.4rem;
        border-radius: 11px !important;
    }
}
.filterPopOver {
    .bs-popover-auto[x-placement^="left"] > .arrow::after,
    .bs-popover-left > .arrow::after {
        border-left-color: #fff !important;
    }
    .popover {
        width: 11rem !important;
        padding: 0rem !important;
        height: 11.5rem;
        border-radius: 11px !important;
    }
    .notificationSubBlock {
        .filterTimeDiv {
            display: flex;
            align-items: center;
            margin: 10px 0px 10px 0px;
            border-bottom: 1px solid #dddddd;
            padding-bottom: 10px;
            .filterText {
                margin: 0px;
            }
        }
        .filterTimesDiv {
            display: flex;
            align-items: center;
            margin: 10px 0px 10px 0px;
            padding-bottom: 10px;
            .filterText {
                margin: 0px;
            }
        }
    }
}

/* --------------Upload Modal style--------- */
.lpImportOfClass {
    .modal-content {
        border-radius: 0;
        border: none;
    }
    .modal-title {
        font-size: 13px;
    }
    .modal-header {
        border-bottom-color: #eeeeee !important;
        background-color: #ffff !important;
        height: 50px !important;
    }
    .modal-header .close {
        padding: 0.5rem 1rem !important;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        outline: none !important;
    }
    .cancelText {
        font-size: 0.8rem !important;
        color: #000 !important;
    }
    .closeIconInModal {
        width: 0.6rem;
        margin-top: -0.2rem;
    }
    .uploadDocHeaderRosterDiv {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }
    .uploadNodeRosterDiv {
        text-align: left;
        background: #f5f5f5;
        padding: 1rem;
        width: 100%;
        position: absolute;
        bottom: 0rem;
    }

    .uploadClassNoteText {
        font-weight: 600;
        margin-bottom: 10px;
        margin: 0rem;
        font-size: 0.8rem;
    }
    .modal-header .close {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        outline: none !important;
    }
    .uploadNoteRosterText {
        margin: 0rem;
        font-size: 0.8rem;
    }
    .checkbozRosterCircle {
        height: 1rem;
        width: 1rem;
        border-radius: 10rem;
        border: 0.1rem solid #dddddd;
        text-align: -webkit-center;
        padding-top: 2px;
        cursor: pointer;
    }
    .checkbozCircleRosterSelected {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 10rem;
        background-color: #386cb5;
    }
    .selectMainDivforRosterUpload {
        display: flex;
        padding: 1rem 0rem 0rem 0rem;
        width: 100%;
        justify-content: center;
    }
    .selectOptionInRosterTag {
        width: 8.7rem;
        height: 9rem;
        border: 1px solid #dddddd;
        text-align: -webkit-center;
        padding: 0.5rem;
        margin: 0.5rem;
    }
    .selectoptionUploadRosterImage {
        width: 2.7rem;
        margin: 0.7rem 0rem 0.6rem 0rem;
    }
    .selectoptionUploadImageforRosterDrop {
        width: 2.3rem;
        margin: 0.7rem 0rem 0.7rem 0rem;
    }
    .autharizedRosterButton {
        width: 4.5rem !important;
        height: 1.4rem !important;
        border-radius: 8px !important;
        padding: 0.2rem !important;
        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
        margin: 0.6rem 0rem 0.1rem 0rem;
    }
    .autharizedRosterText {
        color: white;
        font-size: 0.65rem;
    }

    .selectTagRosterText {
        color: #222222;
        font-size: 0.85rem;
        margin: 0rem;
        font-weight: 600;
    }
    .importRosterRosterButton {
        width: 6.3rem !important;
        height: 2.3rem !important;
        border-radius: 2rem !important;
        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
    }
    .importRosterRosterText {
        color: white;
        font-size: 1rem;
    }
}
/* --------------Upload Modal style--------- */

.selectLp-DriveFile-modal {
    margin-top: 3rem;
}
.selectLp-DriveFile-modal .importDriveLoader {
    height: 100px;
    width: 190px;
    margin-top: 30%;
}
.selectLp-DriveFile-modal .modal-title {
    font: normal normal 600 1rem/1.375rem Open Sans !important;
}
.selectLp-DriveFile-modal .modal-content {
    height: 35rem !important;
}
.selectLp-DriveFile-modal .file_listDiv {
    width: 100%;
    height: 40px;
    margin-top: 13px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #eeeeee;
}

.selectLp-DriveFile-modal .modal-header {
    padding: 0.8rem 1rem 0;
    border-bottom: none;
    border-radius: 0.5rem;
}

.selectLp-DriveFile-modal textarea {
    font: normal normal normal 0.875rem/1.188rem Open Sans;
}
.selectLp-DriveFile-modal .selectedText {
    color: #41cc3b;
    font-weight: 600;
}
.selectLp-DriveFile-modal .unSelectedText {
    font-weight: 600;
    cursor: pointer;
}
.selectLp-DriveFile-modal .modal-footer {
    justify-content: center;
    border-top: none;
    padding-top: 0;
}
.selectLp-DriveFile-modal .modal-footer button {
    width: 5rem;
    height: 5rem;
    border-radius: 1.25rem;
    padding: 0;
}

.selectLp-DriveFile-modal .modal-footer button img {
    margin-top: -0.1875rem;
    margin-left: 0.0625rem;
}
.selectLp-DriveFile-modal .modal-content {
    border-radius: 0.5rem;
    background: #fff !important;
}
.selectLp-DriveFile-modal .modalcancleTagButton {
    width: 2.3rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #ec4444 0%, #fa6060 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    border-color: #ec4444 !important;
}
.selectLp-DriveFile-modal .modalSaveTagButton {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0rem;
    border-color: #23bf1d !important;
}
.selectLp-DriveFile-modal .checkBoxSelect {
    color: #386cb5;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-right: 0.2rem;
}
.selectLp-DriveFile-modal .unCheckBoxSelect {
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-right: 0.2rem;
}

.selectLp-DriveFile-modal .selectoptionUploadFolder {
    width: 2rem;
    margin: 0rem 0.5rem 0rem 0rem;
}
.selectLp-DriveFile-modal .fileName {
    margin-top: 0.6rem;
}
.selectLp-DriveFile-modal .driveBackIcon {
    font-size: 1.3rem;
    cursor: pointer;
}
.selectLp-DriveFile-modal .cursor {
    cursor: pointer;
}
.selectLp-DriveFile-modal .inline-Path {
    display: inline-flex;
    height: 30px;
}
.selectLp-DriveFile-modal .selectFileCursor {
    display: inline-flex;
    cursor: pointer;
}
.selectLp-DriveFile-modal .modal-list-body {
    height: 25rem !important;
    overflow: auto !important;
}
ul.breadcrumb {
    padding: 0px 0px;
    margin: 0px;
    list-style: none;
    background-color: transparent;
}
ul.breadcrumb li {
    display: inline;
    font-size: 14px;
    font-weight: 600;
}
ul.breadcrumb li + li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
}
ul.breadcrumb li a {
    color: #386cb5;
    text-decoration: none;
}
ul.breadcrumb li a:hover {
    color: #669fed;
    text-decoration: underline;
    cursor: pointer;
}

.share-modal-popUp .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}
.share-modal-popUp .modal-content {
    border-radius: 8px;
    width: 360px;
    margin: auto;
    background-color: #fff !important;
}
.share-modal-popUp .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
}
.share-modal-popUp .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    display: flex;
    width: 100%;
    .bothDivMain {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .imgTextDiv {
            flex: 0.475 1;
            border: 1px solid #eeeeee;
            border-radius: 5px;
            padding: 20px 10px 10px;
            cursor: pointer;
            .textDiv {
                font: normal normal 600 14px/19px Open Sans;
                margin: 15px 0px 0px;
                color: black;
            }
        }
    }
}
.share-modal-popUp .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}


.publishAll-modal-popUp .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}
.publishAll-modal-popUp .modal-content {
    border-radius: 8px;
    width: 282px;
    margin: auto;
    background-color: #fff !important;
}
.publishAll-modal-popUp .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
}
.publishAll-modal-popUp .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    display: flex;
    width: 100%;
    .bothDivMain {
        // display: flex;
        width: 100%;
        justify-content: space-between;
        .imgTextDiv {
            border-radius: 5px;
            padding: 20px 10px 10px;
            cursor: pointer;
            .textDiv {
                font: normal normal 600 14px/19px Open Sans;
                margin: 40px 0px 0px;
                color: black;
            }
        }
    }
}
.publishAll-modal-popUp .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}





// HEADER PART FROM Class note dashboard


    .filterSubDiv {
        margin-top: 6px;
    }

    a:focus,
    .btn:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .back-image-CND {
        align-self: center;
        cursor: pointer;
    }


    .loadMoreRoster-CND {
        width: 8rem !important;
        height: 2.5rem !important;
        border-radius: 8px !important;
        padding: .6rem !important;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: .6rem !important;
        margin: .1rem .2rem .1rem .2rem;

        .loaderMoreIconCss-CND {
            width: 5.5rem;
            margin-top: -0.5rem;
        }
    }

    .LoadButtonText-CND {
        color: white;
        font-size: .8rem;
        margin-left: .3rem;
        font-weight: 600;
    }

    @media only screen and (max-width: 1200px) {
        .back-btn {
            width: 165px;
        }
    }

    .assessment-btn {
        font: normal normal 600 13px/16px Open Sans !important;
        background: transparent linear-gradient(270deg, #FFB239 0%, #FFC76E 100%) 0% 0% no-repeat padding-box;
        border-radius: 0.5rem;
        border: none !important;
        width: 100%;
        text-align: center;
        color: #ffffff;
        min-height: 48px;

        .actionIcons {
            width: 1.2rem;
            height: 1.2rem;
            float: left;
            margin-right: 0.55rem;
            margin-top: 5px;
            margin-left: 18px;
        }

        @media only screen and (max-width: 1730px) {
            .actionIcons {
                margin-left: 8px;
            }
        }

        @media only screen and (max-width: 1565px) {
            .actionIcons {
                margin-left: 2px;
                margin-right: 4px;
            }
        }
    }

    @media only screen and (max-width: 1450px) {
        .assessment-btn {
            font-size: 12px !important;
        }
    }

    .note-btn {
        font: normal normal 600 13px/18px Open Sans !important;
        background: transparent linear-gradient(270deg, #747BEB 0%, #989EFF 100%) 0% 0% no-repeat padding-box;
        border-radius: 0.5rem;
        border: none !important;
        width: 100%;
        text-align: center;
        color: #ffffff;
        min-height: 48px;
    }
        .actionIcons {
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 0.55rem;
        }
    

    .main-section-CND {

        .header-CND {
            min-height: 5rem;

            .card-body {
                padding: .8rem 1.25rem .8rem 1.25rem !important;
            }
        }

        .header-title-CND {
            text-align: left;
            font: normal normal 600 1rem/38px Open Sans;
            color: #222222;
            margin: auto;
            white-space: nowrap;

            .border-right-CND {
                vertical-align: middle;
                width: 80px;
                display: inline-flex;
                border-right: 1px solid lightgray !important;
            }

            @media only screen and (max-width: 1200px) {
                .border-right-CND {
                    width: 180px;
                }
            }



            .sub-title {
                font: normal normal normal 12px/17px Open Sans;
                margin-left: 15px;
                color: #777777;
            }
        }

        .addButton {
            width: auto;
            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box !important;
            font: normal normal normal 16px/22px Open Sans !important;
            border-radius: 1.5rem !important;
            float: right;
            margin-left: 8px;
        }

        .disabledAddButton {
            width: auto;
            cursor: default !important;
            background: transparent linear-gradient(270deg, #aec7ea 0%, #b6cae6 100%) 0% 0% no-repeat padding-box !important;
            font: normal normal normal 16px/22px Open Sans !important;
            border-radius: 1.5rem !important;
            float: right;
            margin-left: 8px;
        }

        .saveCloseBtn {
            float: right;
            margin-left: 8px;
            padding: 0;
        }

        .plus-img {
            width: 0.875rem;
            height: 0.875rem;
            margin-right: 0.5rem;
            margin-top: -0.1875rem;
        }

        .refresh-section-CND {
            min-height: 3.75rem;

            .card-body {
                padding: 0.625rem;
            }
        }

        @media only screen and (max-width: 767px) {
            .classNotekMainContainer-CND {
                padding: 0;
            }

            .main-section-CND {
                padding: 0.9375rem !important;
            }

            .header-title-CND {
                font: normal normal 600 0.875rem/1.188rem Open Sans !important;
                margin-bottom: 10px;
            }

            .addButton {
                width: auto;
                font-size: 14px !important;
            }

            .header-title-CND {
                font: normal normal 600 0.875rem/1.188rem Open Sans !important;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .classNotekMainContainer-CND {
            padding: 0;
        }

        .main-section-CND {
            margin-top: 15px;
            padding: 0 !important;
        }

        .filterSubDiv {
            margin-top: 6px;
        }
    }

    @media only screen and (max-width: 520px) {
        .main-section-CND {
            padding: 0 !important;
        }

        .filterDiv {
            float: left;
        }

        .filterSubDiv {
            width: fit-content;
            margin-top: 10px;
        }
    }


    @media only screen and (max-width: 1300px) {
        .classNotekMainContainer-CND {
            padding: .8rem;
        }

        .filterSubDiv {
            float: left;
        }

        .artifactsNote {
            margin-bottom: unset;
        }

        @media only screen and (max-width: 1220px) {
            .classNotekMainContainer-CND {
                padding: 0rem 1rem;
            }
        }
    }

    .desc-search-col-CND {
        float: left;
    }

    .total-student-CND {
        float: left !important;
        margin-top: 7px;
        margin-left: 8px;
    }
    .total-student-CLPD {
        font-weight: 600;
        color: #ccc;
    }

    .totalNoteSearchDiv-CND {
        display: flex;
        width: 76%;
    }

    .searchSearchStylediv-CND {
        width: 60%;
    padding-left: 1.5rem;
    // border-left: 1px solid lightgray;
    margin-left: 30px;
    height: 37px;
    top: 0px;
    position: relative;
    }
    .searchSearchStylediv-CLPD {
        border-left: 1px solid #eee;
    }

    .totalNoteSearchDiv-CND .searchSearchdiv-CND {
        width: 95% !important;
        height: 36px !important;
        margin-top: 0.1rem !important;
        padding-left: 0rem !important;
        margin-bottom: 0.1rem !important;
        display: inline-flex;
        float: left;
        background: #F4F6FA 0% 0% no-repeat padding-box !important;
        border: 0.75px solid #DDDDDD !important;
        border-radius: 25px !important;
        opacity: 1;
        width: 300px !important;
    height: 36px;
    }

    .totalNoteSearchDiv-CND .searchSearchdiv-CND input {
        cursor: pointer;
    }

    .totalNoteSearchDiv-CND input.middle:focus {
        outline-width: 0 !important;
    }

    .totalNoteSearchDiv-CND .searchInput {
        border: none !important;
        width: 100%;
    }

    .totalNoteSearchDiv-CND .closeIconSearch {
        width: 1rem;
        cursor: pointer;
        margin: 0.9rem .5rem 0rem 0rem;
    }

    .totalNoteSearchDiv-CND .searchIconDiv-CND {
        padding: 0rem .5rem 0rem .5rem !important;
    }

    .totalNoteSearchDiv-CND input.middle:focus {
        outline-width: 0 !important;
    }

    .totalNoteSearchDiv-CND .searchIcon {
        color: #747373 !important;
        margin-left: 7px;
    }

    .totalNoteSearchDiv-CND .searchStudent-CND {
        background: transparent !important;
        border: none !important;
        width: 100% !important;
        font: italic normal normal 13px/17px Open Sans;
    }

    .totalNoteSearchDiv-CND input {
        outline: none;
    }

    .Button-div-1-CND {
        float: right;
        margin-right: 20px;
        margin-left: 15px;
        padding-left: 7px;
    }

    .Button-div-1-CND .btn {
        height: 32px;
    }

    .Button-div-2-CND {
        float: right;
        border-left: 1px solid;
        border-color: lightgray;
        padding-left: 14px;
        
    }
    .Button-div-2-CND-PaddingTop0 {
        padding-top: 0 !important;
        border-left: 2px solid!important;
        border-color: #eee!important;
        padding-left: 14px!important;
    }

    .Button-div-2-CND .btn {
        height: 32px;
    }
    .CLPD-threedots {
        display: flex;
    }
// .classCardDiv:hover {
//     .CLPD-threedots {
//         display: flex;
//     }
// }
    .custom-outline-btn-CND {
        border: 1px solid var(--unnamed-color-cccccc) !important;
        border: 1px solid #CCCCCC !important;
        border-radius: 8px !important;
        opacity: 1 !important;
        margin-left: 10px !important;
        text-align: left !important;
        font: normal normal normal 14px/18px Open Sans !important;
        letter-spacing: 0px !important;
        color: #222222 !important;
        opacity: 1 !important;
        outline: 1px solid #CCCCCC !important;
    }

    .custom-outline-btn-CND :hover {
        color: #CCCCCC !important;
    }

    .btn-outline-secondary:hover {
        background-color: #fff !important;
        border-color: #6c757d;
    }

    .btn-outline-secondary:active {
        background-color: #fff !important;
        border-color: #6c757d;
    }

    
    .custom-button-CND {
        border-radius: 8px !important;
        opacity: 1 !important;
        margin-left: 10px !important;
        text-align: left !important;
        font: normal normal normal 14px/18px Open Sans !important;
        letter-spacing: 0px !important;
        color: #ffffff !important;
        opacity: 1 !important;
        background-color: #386CB5E6 !important;
    }

    .tapToAddText-CND {
        color: #AAAAAA;
        font-size: 2rem;
        font-weight: 600;
        margin-top: 70px;

    }

    .sort-by-col-CND {
        display: flex;
        float: right;
        direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .sort-by-CND p {
        margin: 0px 0px 0px 0px !important;
        padding: 0px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-222222);
        text-align: left;
        font: normal normal 600 14px/19px Open Sans;
    }

    .year-CND .classForIcon-CND {
        color: #487FCC;
        position: relative;
        margin-top: -4px;
        vertical-align: middle;
    }

    #Note-CND {
        color: #222222 !important;
        font-weight: 800;
        font-size: large;
    }

    .year-CND {
        position: relative;
        color: #487FCC;
        margin-left: 10px;
        white-space: nowrap;
        top: 2px;
        cursor: pointer;

        .text-italic {
            font-style: italic;
        }
    }

    .note-dashboard-search-image-CND {
        width: 14px;
        height: 14px;
        align-self: center;
        position: absolute;
        top: 8px !important;
        right: 27px !important;
        z-index: 2 !important;
    }

    .filterRow-CND {
        justify-content: space-between;
    }

.currentYear-p0 {
    padding: 0!important;
}
.stdNoteDisplayFlex-p0 {
    align-items: center;
    p {
        padding : 0!important
    }
}

.select-search {
    width: 240px !important;
    position: relative;
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
    bottom: 5px !important;
    cursor: pointer;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

.select-search__value {
    position: relative;
}

.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
    cursor: pointer;
}

.select-search__input {
    margin-top: 5px;
    display: block;
    height: 40px;
    width: 240px;
    padding: 0 40px 0 30px;
    background: #fff;
    border: 1px solid #dddddd;
    // box-shadow: 0px 0px 3px #88c3fae3;
    border-radius: 8px;
    outline: none !important;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    color: rgba(56, 108, 181, 1);
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    opacity: 1;
    box-shadow: 0px 0px 3px #00000029;
border: 0.75px solid #DDDDDD;
border-radius: 8px;
opacity: 1;
}

.select-search__input::placeholder {
    font: italic normal normal 13px/18px Open Sans;
    letter-spacing: 0px;
    color: #c6b6b6 !important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

.select-search__select {
    max-height: 150px !important;
    box-shadow: 10px 10px 12px #ec090929;
    border: 1px solid #dddddd;
}

.select-search__options {
    list-style: none;
    padding: 0px 5px;
    background: #fff;
    cursor: pointer;
}

.select-search__row {
    border-bottom: 1px solid #dddddd;
    border-width: 0.15px;
}

.select-search__row:last-child {
    border-bottom: none;
}

.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    color: #fff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    color: #fff;
}

.select-search__group-header {
    text-transform: uppercase;
    background: #fff;
}

.select-search.is-disabled {
    // opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
    letter-spacing: 0px;
    font-weight: 600;
}

.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 3px solid #000;
    border-bottom: 3px solid #000;
    pointer-events: none;
}

.select-search.has-focus .select-search__value::after {
    display: none;
}

.select-search.has-focus .select-search__input:hover {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.select-search.has-focus .select-search__input {
    border-bottom: none;
    box-shadow: 0px 0px 3px #00000029;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-color: none;
    background: #f4f6fa 0% 0% no-repeat padding-box;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 40px !important;
    right: 0;
    left: 0;
    border-radius: 8px;
    overflow: auto;
    max-height: 360px;
    background: #fff;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    box-shadow: none;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: auto;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
    background: #fff;
}

.select-search__not-found {
    height: auto;
    padding: 8px;
    text-align: center;
    color: rgb(244, 245, 247);
}


.searchImage {
    width: 14px;
    height: 14px;
    align-self: center;
    position: absolute;
    top: 13px !important;
    right: 27px !important;
    z-index: 2 !important;
}
.note-select-dropdown-main-container-NSC {
    font-family: sans-serif;
    text-align: center;
    width: 50px;
    height: 50px;
    position: relative;
    top: 10px;
    left: -12% !important;
}

.note-select-dropdown-main-container-NSCC {
    font-family: sans-serif;
    text-align: center;
    width: 50px;
    height: 50px;
    position: relative;
    top: 10px;
    left: -8% !important;
}
@media screen and (min-width: 1280px) and (max-width: 1470px) {
    .note-select-dropdown-main-container-NSC {
        font-family: sans-serif;
        text-align: center;
        width: 50px;
        height: 50px;
        position: relative;
        top: 10px;
        left: -18% !important;
    }
}
@media screen and (min-width: 1280px) and (max-width: 1470px) {
.rubContainersDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin-top: 100px;
    padding-left: 4% !important;
}
}

@media screen and (min-width: 1471px) and (max-width: 1545px) {
.rubContainersDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin-top: 100px;
    padding-left: 1% !important;
}
}

@media screen and (min-width: 1546px) and (max-width: 1745px) {
.rubContainersDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin-top: 100px;
    padding-left: 1% !important;
}
}

@media screen and (min-width: 1745px) and (max-width: 1920px) {
.rubContainersDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin-top: 100px;
    padding-right: 3% !important;
}
}



.rubContainersDiv {
width: 100%;
display: flex;
flex-direction: column;
align-self: center;
align-items: center;
margin-top: 100px;
// padding-left: 25px;

.rubricsArrowImage {
    width: 14px;
    height: 30px;
    align-self: center;
    margin-bottom: 5px;
}

.rubContainerTextDiv {
    width: 232px;
    height: 54px;

    .rubContainerText {
        align-self: center;
        font-size: 20px;
        text-align: center;
        letter-spacing: 0px;
        color: #aaaaaa;
        opacity: 1;
    }
}
}