.classAttendanceContainer {
    .PreviewAttContainer {
        background: #f0f0f0;
        height: 100%;
        overflow-x: hidden;
        padding: 0rem 2.5rem 1rem 2.5rem;
        .ClassAttendanceMainDiv {
            width: 100%;
            background: #ffff;
            justify-content: space-between;
            display: flex;
            padding: 1rem 1rem 1rem 1rem;
            .classAttendaceDetailsMain {
                display: flex;
                align-items: inherit;
                .attClassBackDiv {
                    display: flex;
                    width: 4rem;
                    height: 3.1rem;
                    padding-left: 0rem;
                    cursor: pointer;
                }
                .attClassBackIcon {
                    font-size: 1.3rem;
                    padding-top: 3px;
                }
                .attClassBactText {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                }
            }
            .buttonMarginTop {
                display: flex;
                align-items: inherit;
                .editAttBtn {
                    width: 5.3rem !important;
                    height: 2.5rem !important;
                    border-radius: 2rem !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
                        padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                }
                .opacityBtn {
                    opacity: 0.4;
                    cursor: default;
                }
                .viewAttPreDiv {
                    width: 6rem !important;
                    height: 2.5rem !important;
                    border-radius: 8px !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
                        padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                    .viewAttIcons {
                        width: 1rem;
                        margin-top: 0.3rem;
                    }
                }
                .downloadPreDiv {
                    width: 7.5rem !important;
                    height: 2.5rem !important;
                    border-radius: 8px !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
                        padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                    .viewAttIcons {
                        width: 1rem;
                        margin-top: 0.3rem;
                    }
                }
                .pencilAttIcon {
                    width: 1rem;
                    margin-top: 0.2rem;
                }
                .viewAttIcon {
                    width: 1rem;
                    margin-top: 0.2rem;
                }
                .viewAttText {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                }
                .displayFlexInSeletAtt {
                    padding-right: 20px;
                    display: flex;
                    .undoSelect {
                        width: 2.5rem;
                        height: 2.5rem;
                        background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat
                            padding-box;
                        border-radius: 10rem;
                        opacity: 1;
                        margin-right: 1rem;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .attClassDetailTextDiv {
                margin-left: 1rem;
                border-left: 1px solid #eeeeee;
                padding: 0.3rem 0rem 0rem 1rem;
                .attClassName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    color: #386cb5;
                }
                .displayFlexClassRoster {
                    display: flex;
                    cursor: pointer;
                    .calIcon {
                        align-self: center;
                        cursor: default;
                        cursor: not-allowed;
                    }
                    .attClassGrade {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 5px 0px 0px 5px;
                        cursor: default;
                    }
                    .headingForEdited {
                        display: inline-flex;
                        flex-wrap: wrap;
                        cursor: default;
                        align-items: center;
                        .attTitleName {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            text-align: left;
                            display: flex;
                            padding-right: 5px;
                            font: normal normal 600 14px/14px Open Sans;
                        }

                        .head {
                            color: #386cb5;
                            text-transform: capitalize;
                            font: normal normal 600 14px/14px Open Sans;
                            margin: 0px;
                        }
                    }
                    .attClassPreGrades {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 3px 0px 0px 11px;
                        cursor: default;
                        font-weight: 600;
                        font-style: italic;
                    }
                }
            }
            .PreviewAttClassDetailTextDiv {
                margin-left: 1rem;
                border-left: 1px solid #eeeeee;
                padding: 0.5rem 0rem 0.5rem 1rem;
                .attClassName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    color: #386cb5;
                }
                .displayFlexClassRoster {
                    display: flex;
                    cursor: pointer;
                    .calIcon {
                        align-self: center;
                        cursor: default;
                        cursor: not-allowed;
                    }
                    .attClassGrade {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 5px 0px 0px 5px;
                        cursor: default;
                    }
                    .headingForEdited {
                        display: inline-flex;
                        flex-wrap: wrap;
                        cursor: default;
                        align-items: center;
                        .attTitleName {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            text-align: left;
                            display: flex;
                            padding-right: 5px;
                            font: normal normal 600 14px/14px Open Sans;
                        }

                        .head {
                            color: #386cb5;
                            text-transform: capitalize;
                            font: normal normal 600 14px/14px Open Sans;
                            margin: 0px;
                        }
                    }
                    .attClassPreGrades {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 3px 0px 0px 11px;
                        cursor: default;
                        font-weight: 600;
                        font-style: italic;
                    }
                }
            }
        }
        .showFlex {
            display: flex;
        }
        .reportMailDiv {
            .noneStudents {
                color: #aaaaaa;
                font: normal normal 600 20px/14px Open Sans;
                line-height: inherit;
                padding-top: 140px;
            }
            .reportPreView {
                margin-top: 20px;
                .main {
                    height: 400px !important;
                    .react-pdf__Document {
                        height: 352px;
                        overflow: scroll;
                        .react-pdf__Page {
                            .react-pdf__Page__canvas {
                                display: inline !important;
                                // width: 550px !important;
                                // height: 841px !important;
                                box-shadow: 22px 7px 26px #2222221a !important;
                            }
                        }
                    }
                    .pagec {
                        font: normal normal 600 14px/28px Open Sans;
                    }
                    .buttonc {
                        .prePage {
                            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
                                padding-box;
                            margin: 0px 10px 0px 5px;
                            border-radius: 8px;
                            color: white;
                            border: none;
                            width: 88px;
                            height: 33px;
                            cursor: pointer;
                            outline: none;
                        }
                        .nextPage {
                            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
                                padding-box;
                            margin: 0px 10px 0px 5px;
                            border-radius: 8px;
                            color: white;
                            border: none;
                            width: 88px;
                            height: 33px;
                            cursor: pointer;
                            outline: none;
                        }
                    }
                }
            }
            .emailComposer {
                width: 500px;
                height: 407px;
                background-color: white;
                margin: 20px 0px 0px 20px;
                border-radius: 8px;
                box-shadow: 0px 1px 4px #2222221a !important;
                font: normal normal 600 14px/28px Open Sans;
                .titleDiv {
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
                        padding-box;
                    align-items: center;
                    display: flex;
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                    color: white;
                    height: 40px;
                    .composeTitle {
                        padding-left: 18px;
                        margin-bottom: 0px !important;
                    }
                }
                .emailForm {
                    padding: 0px 18px 0px 18px;
                    .errormessageRegisters {
                        color: #d80f0f !important;
                        float: left !important;
                        font-size: 13px !important;
                        margin-bottom: 1px !important;
                        font-weight: 600;
                        margin-top: -1px;
                        line-height: 10px;
                        padding-left: 2px;
                    }
                    .field {
                        display: table;
                        border-bottom: 1px solid #cccccc;
                        padding: 8px 0px 8px 0px;
                        width: 100%;
                        .mailDiv {
                            border: none;
                            outline: none;
                            width: 100%;
                        }
                        .messageText {
                            border: none;
                            outline: none;
                            width: 100%;
                            height: 193px;
                        }
                        .subjectText {
                            border: none;
                            outline: none;
                            width: 100%;
                            height: 30px;
                        }
                    }
                }
                .actions {
                    padding: 10px 16px;
                    display: flex;
                    background: #f2f2f2;
                    justify-content: flex-end;
                    .emailBtn {
                        width: 6rem !important;
                        height: 2.5rem !important;
                        border-radius: 8px !important;
                        padding: 0.6rem !important;
                        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
                            padding-box;
                        box-shadow: 0px 1px 4px #2222221a !important;
                        display: inline-flex !important;
                        justify-content: center !important;
                        font-size: 0.6rem !important;
                        margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                        .viewAttIcons {
                            width: 1rem;
                            margin-top: 0.3rem;
                        }
                        .viewAttText {
                            color: white;
                            font-size: 0.9rem;
                            margin-left: 0.5rem;
                        }
                    }
                    .cancelBtn {
                        background: none;
                        outline: none;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 539px) and (max-width: 768px) {
    .reportMailDiv {
        .reportPreView {
            margin-top: 20px;
            .main {
                height: 400px !important;
                .react-pdf__Document {
                    height: 352px;
                    overflow: scroll;
                    .react-pdf__Page {
                        .react-pdf__Page__canvas {
                            display: inline !important;
                            width: 349px !important;
                            height: 841px !important;
                            box-shadow: 22px 7px 26px #2222221a !important;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .reportMailDiv {
        .reportPreView {
            margin-top: 20px;
            .main {
                height: 400px !important;
                .react-pdf__Document {
                    height: 352px;
                    overflow: scroll;
                    .react-pdf__Page {
                        .react-pdf__Page__canvas {
                            display: inline !important;
                            width: 420px !important;
                            height: 841px !important;
                            box-shadow: 22px 7px 26px #2222221a !important;
                        }
                    }
                }
            }
        }
    }
}
