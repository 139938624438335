.settingsContainer{
    justify-content: center;
    padding:10px 150px 0px 150px;
    .cardOfRange{
        width: 100%;
        min-height: 254px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A;
        margin-top: 20px;
        padding: 20px;
        text-align: left;
        border: none;
    }
    .cardHeading{
        text-align: left;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #222222;
    }

    .cardRangeTitle{
        text-align: left;
        font: normal normal normal 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
    }
    .cardOfDate{
        width: 100%;
        min-height: 360px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A;
        margin-top: 20px;
        padding: 20px;
        border: none;

        text-align: left;
    }
    .cardDateFormatTitle{
        text-align: left;
        font: normal normal 600 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #AAAAAA;
        margin-bottom: 5px;
    }
    .mainDivForFormat{
        min-height: 70px;
    }
    .dateFormatList{
        width: 165px;
        height: 1.75rem;
        border-radius: 16px;
        margin: .3rem;
        float: left;
        display: flex;
     align-items: center;
    // justify-content: center;
    }
    .dateFormatListText {
        text-align: left;
        font: normal normal normal 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
        padding: 1rem 0rem 0rem .1rem;
    }
    .saveCloseBtn {
        float: right;
        margin-left: 8px;
        padding: 0;
        outline: none;
    }
    
    @media only screen and (min-width: 951px) and (max-width: 1280px) {
        padding: 10px 20px 0px 20px;
        
    }    

    @media only screen and (min-width: 551px) and (max-width: 951px) {
        padding: 10px 20px 0px 20px;
        .mainDivForFormat{
            min-height: 90px;
        }
    }
    @media only screen and (min-width: 251px) and (max-width: 550px) {
        padding: 10px 20px 0px 20px;
        .mainDivForFormat{
            min-height: 165px;
        }

    }
    .rangeslider-horizontal {
        width: 300px;
        height: 4px;
        border-radius: 10px;
    }
    .green .rangeslider-horizontal .rangeslider__fill{
        background-color: #23BF1D !important;
    }
    .yellow .rangeslider-horizontal .rangeslider__fill{
        background-color: #FFC424 !important;
    }
    .rangeslider-horizontal .rangeslider__handle{
        height: 16px !important;
        width: 16px !important;
        border-radius: 100%;
        background-color: #ffff !important;
    }
    .rangeslider-horizontal .rangeslider__handle:after{
        display: none !important;
    }
    .rangeslider__labels{
        margin: 0px !important;
    }
    
}