body {
    background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);
}
.TeacherShareContainer {
   background: #F0F0F0 ;
   height: 100%;
   overflow-x: hidden;
   
}
.teacherMainDiv-ShareRoster {
    max-width: 352px!important;
}
/* ------------Heading Css------- */
.mainHeading{
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;

}
.headingSchoolName{
   font-size: 1rem;
   font-weight: 600;
   margin: 0rem;
}
.headingSelectTeacher{
    font-size: .8rem;
    font-weight: 600;
    color:#AAAAAA;
   margin: 0rem;

}
.openPermissionModalButton{
    width: 10rem !important;
    height: 2rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
}
.checkPermissionText {
    color: white;
    font-size: .7rem;
    font-weight: 600;
   
}
/* ------------Heading Css------- */



/* ------------Teacher Card Css------- */
.teacherMainDiv{
    width: 22rem !important;
    height: 5rem !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000001A;
    border-radius: 1rem;
    opacity: 1;
    display: inline-flex;
    padding: .5rem;
    margin: 0.85rem 1rem 0.25rem 0rem;
    min-width: 270px !important; 
    opacity: 1;
    position: relative;
    align-items: center;
}
.teacherProfileImageDiv{
    width: 3.5rem;
    height: 3.4rem;
    color: red;
    background: #386CB5;
    border-radius: 50%;
}
.teacherDummyDivProfile{
    width: 5rem;
    height: 5rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    margin-top: 0rem;
    text-align: center;
    margin-left: .5rem;
}
.teacherImageDummyText{
    color: white;
    padding-top: 30%;
    text-transform: uppercase;
}
.teacherShareProfileImage {
    width: 3.125rem;
    height: 3.125rem;
    background: transparent linear-gradient(180deg, #647183 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
}
.teacherShareTextInfoDiv{
    text-align: left;
    margin: 1rem;
}
.teacherShareName{
    margin: 2px;
    font-size: 1rem;
    font-weight: 600;
    width: 10rem;
}
.teacherShareEmail{
    margin: 0px;
    font-size: .9rem;
}
.teacherShareCheckBoxDiv{
    position: absolute;
    right: 0.7rem;
    top: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
}
.checkBoxColorTeacherShare{
    color:#386cb5;
    cursor: pointer;
    
}
.checkbozCircleTeacherShare{
    height: 1.35rem;
    width: 1.35rem;
    border-radius: 10rem;
    border:.1rem solid #DDDDDD;
    text-align: -webkit-center;
    padding-top: .2rem;
    cursor: pointer;
}
.checkbozCircleSelectedTeacherShare{
    height: .75rem;
    width: .75rem;
    border-radius: 10rem;
    background-color: #386CB5;
}
/* ------------Teacher Card  Css------- */



/* ------------Modal---------- */
.headerDiv{
    background: #fff;
}
.permissionHeaderDiv{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    .close{
        display: flex;
        outline: none;
    }
}

.closeIconPermissionModal{
    width: 12px;
    height: 12px;
    opacity: 1;
}
.cancelText{
    color: var(--unnamed-color-222222);
    text-align: left;
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}
.permissionText{
    color: var(--unnamed-color-222222);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    margin-bottom: 0rem;
}
.selectMainDivforShareTeacher{
    display: grid;
    padding: 1rem 0rem 0rem 0rem;
    width: 100%;
    justify-content: center;
    .subDivForSelecType{
        display: inline-flex;
        flex-flow: wrap;
        justify-content: center;
    }
}
.selectOptionInTeacherShareType{
    width: 8.75rem;
    height: 155px;
    text-align: -webkit-center;
    padding: .5rem;
    margin: .5rem;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    display: grid;
    align-items: center;
    justify-content: center;
}
.selectoptionShareTeacherImage{
    width: 3rem;
    margin: 1rem 0rem 1rem 0.8rem;
}
.selectOptionShareTeacherText{
    color: #222222;
    font-size: .8rem;
    margin: 0rem;
}
.sendPermissionButton{
    width: 8rem !important;
    height: 2.4rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: 1rem 0rem 1rem 0rem;
}
.sendPermissionText {
    color: white;
    font-size: 1rem;
}

@media only screen and (min-width: 250px) and (max-width: 790px) {
    .teacherCard{
        height: 6rem;
    width: 19rem;
    }
    .teacherShareProfileImage {
        width: 4rem;
        height: 4rem;
    }
    .teacherShareTextInfoDiv {
        text-align: left;
        margin: .4rem;
    }
    .teacherProfileImageDiv {
        width: 5rem;
    }
    .teacherShareCheckBoxDiv {
        top: 2.1rem;
    }
    .teacherShareName {
        font-size: .9rem;
    }
    .teacherShareEmail {
        font-size: .7rem;
    }
}
