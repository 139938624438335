.studentGradebookPopover {
    width: 183px; 
    height: 36px;
    display: flex !important; 
    align-items : center; 
    top: 43px !important;
    left: -69px !important;
}
.studentGradebookPopover::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 33px;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff; /* adjust color and size as needed */
  }

.sutdentgradebbokpopoverparentdiv {
  .wrap {
    // margin: 100px auto;
    width: 190px;  
    // height: 150px;	
    // position: relative;
        /* margin: 100px auto; */
    /* width: 300px; */
    /* height: 150px; */
    position: relative;
    top: 53px;
    left: -81px;
  }
  .arrow {
    // width: 50px;
    // height: 50px;
    // border: 3px solid gray;
    // -webkit-transform: rotate(45deg);
    // -moz-transform: rotate(45deg);
    // -o-transform: rotate(45deg);
    // -ms-transform: rotate(45deg);
    // transform: rotate(45deg);
    // position: absolute;
    // top: 50%;
    // margin-top: -25px;
    // left: -25px;
    // z-index: 10;
    // border-radius: 10px;  
    width: 19px;
    height: 18px;
    border: 1px solid gray;
    transform: rotate(45deg);
    position: absolute;
    top: -9px;
    left: 106px;
    z-index: 10;
    border-right: none;
    border-bottom: none;
  }
  .arrow-cover {
    // top: 50%;
    // margin-top: -25px;
    // left: -21px;
    // width: 50px;
    // height: 50px;
    // border: 3px solid transparent;
    // position: absolute;
    // background: white;
    // z-index: 30;
    // -webkit-transform: rotate(45deg);
    // -moz-transform: rotate(45deg);
    // -o-transform: rotate(45deg);
    // -ms-transform: rotate(45deg);
    // transform: rotate(45deg);
    // border-radius: 10px;

    top: -8px;
    left: 106px;
    width: 18px;
    height: 19px;
    position: absolute;
    background: #fff;
    z-index: 22;
    transform: rotate(45deg);
    border-right: none;
    border-bottom: none;
  }
  .box {
    // width: 300px;  
    // height: 150px;	
    // background: white;
    // z-index: 20;
    // position: relative;
    // border: 3px solid gray;
    // background: white;
    // border-radius: 10px;
    // padding: 10px
    background: white;
    z-index: 20;
    position: relative;
    border: 1px solid gray;
    background: white;
    border-radius: 10px;
    padding: 10px;
  }
}