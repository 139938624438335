body {
    background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);
    min-height: 83vh;
}
.classCoreStandardsContainer {
   background: #F0F0F0 ;
   overflow-x: hidden;
   padding: 0rem 2.5rem 1rem 2.5rem;
    .CoreStandardsMainDiv{
        width: 100%;
        background: #ffff;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        .selectAllDiv{
            display: flex;
            height: 3.1rem;
            padding: 0.8rem;
            width: 100%;
            align-items: center;


            .selectAllTextDiv{
            height: 3.1rem;
            flex: 2 0;
            justify-content: flex-start;
            align-items: center;
            padding: 0.8rem;
            display: flex;
            width: 100%;
            .selectAllText{
                margin: 0px;
                font-size: 0.9rem;
                font-weight: 600;
                text-align: left;
                margin-bottom: 0rem!important;
                min-width: 200px; 
                max-width: 200px;
            }
        } 
        .checkBoxfilters {
            font-size: 23px;
            color: #386cb5;
            cursor: pointer;
            margin-right: .2rem;
        }
        .actionBtn {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            padding: 0;
            margin-right: 10px;

            img {
                margin-top: -2px;
                margin-left: 0px !important;
            }
        }
        .cancel {
            background: transparent linear-gradient(270deg, #EA3737 0%, #F05F5F 100%) 0% 0% no-repeat padding-box;                
        }

        .save {
            background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;                
        }
        .disabledSave {
            background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;                
            opacity: 0.5;
            cursor: default !important;
        }
    } 
        .rightBorder{
            border-right: 1px solid #CCCCCC;
            width: 180px;
        } 
    }
    
    .classBackDiv{
        display: flex;
        padding: .8rem;
        background: #EEEEEE;
        width: 19rem;
        height: 3.1rem;
        cursor: pointer;
        width: 165px;


        .classBackYearText{
            margin: 0px;
            font-size: 1rem;
            font-weight: 600;
            text-align: left;
            margin-bottom: 0rem!important;
        }
        .rightBoxTitle{
            flex: 1 1;
        }
    }
    .standardsTitle{
        display: flex;
        padding: .8rem;
        margin-bottom: 0px!important;
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        text-align: left; 
    }
}
.standardsSubDiv{
    min-height: 473px;
    overflow-x: hidden;
    background: #F0F0F0;
    padding: 0rem 2.5rem 0rem 2.5rem;
    .standardsBaseDiv{
        display: flex;
        width: 100%;
        background: #ffff;
        padding: 1rem 1rem 1rem 1rem;
        max-height: auto;
        min-height: 460px;
        .leftStandard{
            flex: 0.65 0 ;
            border: 1px solid #CCCCCC;
            height: 428px;
            overflow-x: hidden;
            overflow-y: auto;
            .backToStdDiv{
                background:#F0F0F0;
                display: flex;
                width: 100%;
                padding: 0.8rem 0rem 0.8rem 0.8rem;
                border-bottom: 1px solid #CCCCCC; 
                cursor: pointer;
                .backToStd{
                    color:blue;
                    margin-bottom: 0px!important;
                    font-size: 0.9rem;
                    font-weight: 600;
                }
                .classBackStdIcon{
                    color:blue;
                }
            }
             .classBackDiv{
                cursor: pointer;
                display: flex;
                width: 100%;
                padding: 0.8rem 0rem 0.8rem 0.8rem;
                border-bottom: 1px solid #CCCCCC; 
                .classForIcon{
                    padding-left:15px;
                    color: #CCCCCC;
                }
            }
            .standardsTextHead{
                margin: 0px;
                font-size: 0.9rem;
                font-weight: 600;
                text-align: left;
                margin-bottom: 0rem!important;
                min-width: 200px; 
                max-width: 200px;
            }
        }
        .justyCentre{
            justify-content: center;
        }
        .rightStandard{
            background-color: #EEEEEE;
            border: 1px solid #CCCCCC;
            display: flex;
            flex: 2 0 ;
            border-left: none;
            .standardsDetails{
                padding: .8rem;
                align-items: center;
                display: flex;
            }
            .noStandardsTitle{
                color: #CCCCCC;
            }
        }
        .stdDetailsData{
            background: #EEEEEE;
            border: 1px solid #CCCCCC;
            flex: 2 0 ;
            border-left: none;
            width: 500px;
            height: 428px;
            overflow-x: hidden;
            overflow-y: auto;
            .stdDetailsDatadiv{
                border-radius: 8px;
                background: white;;
                margin: 0.8rem;
                height: auto;
                .mainPageForIcon{
                    display: flex;
                    cursor: pointer;
                }
                .stdTitleDiv{
                    border-bottom: 1px solid #CCCCCC;
                    padding: 0.8rem;
                    display: flex;
                    flex:1;
                    .divTickStd{
                        display: flex;
                        align-items: center;
                        flex:3;
                    }
                   .stdTitle{
                        font-size: 0.9rem;
                        font-weight: 600;
                        padding-left: 10px;
                        margin-bottom: 0px;
                        align-items: center;
                   }   
                }
                .description{
                    align-items: center;
                    padding: 0.8rem;
                    .stdDetails{
                        flex: 0.985;
                        text-align: justify;
                        font-size: 0.9rem;
                        padding: 0rem 0.8rem;
                        margin-bottom: 0px;
                        color:#635959;
                        cursor: default;
                        }
                }
                .tickMarkImg {    
                    background: transparent linear-gradient(325deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
                    width: 16px !important;
                    height: 16px !important;
                    border-radius: 20px;
                    padding: 4px;
                    cursor: pointer;

                }
                .notickMarkImg {    
                    background: transparent linear-gradient(341deg, #ffffff 0%, #dbdee0 0%) 0% 50% no-repeat padding-box;
                    width: 16px !important;
                    height: 16px !important;
                    border-radius: 20px;
                    padding: 4px;
                    cursor: pointer;
                }
            }
    }
}
}
@media only screen and (max-width: 615px) {
    .classCoreStandardsContainer .CoreStandardsMainDiv {
        width: 100%;
        background: #ffff;
        padding: 1rem 1rem 1rem 1rem;
    }
    .CoreStandardsMainDiv{
        display: block !important;
        .saveTickDiv{
            position: absolute;
            left: 19rem;
            top: 10rem;
        }
    }
}
@media only screen and (max-width: 410px) {
    .classCoreStandardsContainer .CoreStandardsMainDiv {
        width: 100%;
        background: #ffff;
        padding: 1rem 1rem 1rem 1rem;
    }
    .CoreStandardsMainDiv{
        display: block !important;
        .saveTickDiv{
            position: absolute;
            left: 16.5rem;
            top: 10rem;
        }
    }
}
