body {
   background-image: linear-gradient(to bottom, #ecf3fc, #FAFAFA);

}

::-webkit-scrollbar {
   width: 2px;
   height: 2px;
   border-radius: 2px !important;
   position : 'relative';
   top: 5px;
   // display: none !important;
   width: 0;
   background: transparent;

}

/* .background{
   background-image: url('../../../Assets/LoginModule/back.png') !important;
   background-size: cover;
   background-position: center;
} */
.RegistrationMainContainer {
   height: 100%;
   overflow: hidden;
   background-image: url('../../../Assets/LoginModule/background.png') !important;
   background-size: contain;
   background-position: center;
   display: flex;
}

.RegistrationSubContainer {
   width: 50%;
   height: 100%;
   align-items: center;
   justify-content: center;
   display: flex;
   flex-direction: column;

   .img-div {
      align-self: center;
      max-width: 350px;
   }
}

.RegistrationCardDiv1 {
   width: 500px;
   height: 85%;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 6px #00000029;
   border-radius: 20px;
   opacity: 1;
   padding: 32px 32px 32px 32px;

   .RegistrationTextDiv {
      padding: 0px;
      align-items: left;
      text-align: left;
   }

   .RegistrationText {
      font-size: 22px;
      color: #000000;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
   }

   .divForRegScrollFirst {
      // display: flex;
      // overflow-y: auto;
      &:hover {
         // overflow-y: scroll;
         overflow-x: hidden;
         white-space: nowrap;
         ::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            border-radius: 2px !important;
            display: none !important;
   
         }
      }
     
      // padding-right: 6px;
      scrollbar-width: 15px !important;
      height: 100%;

      // padding-right: 1rem;
      ::-webkit-scrollbar {
         width: 0px;
         height: 0px;
         border-radius: 2px !important;
         display: none !important;

      }

      .divForRegScrollSec {
         // display: flex;
         // overflow-y: auto;
         overflow-y: scroll;
         overflow-x: hidden;
         white-space: nowrap;
         // padding-right: 6px;
         scrollbar-width: 15px !important;
         height: 95%;

         ::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            border-radius: 2px !important;
            display: none !important;

         }


         .RegistrationProcessText {
            text-align: left;
            font: normal normal normal 18px Open Sans;
            letter-spacing: 0px;
            color: #000000;
            opacity: 0.5;
         }

         .stepMainDiv {
            display: flex;
            justify-content: space-between;
            margin-top: 0px;
         }

         .stepSubDiv {
            display: flex;
            flex-direction: column;
            width: 47%;
            justify-content: flex-end;
            align-items: center;
            border-width: 2px;
            margin-bottom: 10px;

         }

         .stepText {
            font: normal normal 600 18px/24px Open Sans;
            letter-spacing: 0px;
            text-transform: uppercase;
            opacity: 1;
            margin-top: 5px;
         }

         .bottomLine {
            width: 100%;
            height: 4px;
            border-width: 4px;
            // background: #386CB5 0% 0% no-repeat padding-box;
            border-radius: 2px;
            margin-bottom: 5px !important;
            margin-top: -8px !important;
         }
      }
   }
}

.RegistrationCardDiv {
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 6px #00000029;
   border-radius: 20px;
   opacity: 1;
   padding: 2rem 2rem 2rem 2rem;

   .RegistrationTextDiv {
      padding: 0px;
      align-items: left;
      text-align: left;
   }

   .RegistrationText {
      font-size: 22px;
      color: #000000;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
   }

   .RegistrationProcessText {
      text-align: left;
      font: normal normal normal 18px Open Sans;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.5;
   }

   .stepMainDiv {
      display: flex;
      justify-content: space-between;
      margin-top: 0px;
   }

   .stepSubDiv {
      display: flex;
      flex-direction: column;
      width: 47%;
      justify-content: flex-end;
      align-items: center;
      border-width: 2px;
      margin-bottom: 10px;

   }

   .stepText {
      font: normal normal 600 18px/24px Open Sans;
      letter-spacing: 0px;
      text-transform: uppercase;
      opacity: 1;
      margin-top: 5px;
   }

   .bottomLine {
      width: 100%;
      height: 4px;
      border-width: 4px;
      // background: #386CB5 0% 0% no-repeat padding-box;
      border-radius: 2px;
      margin-bottom: 5px !important;
      margin-top: -8px !important;
   }
}

.FirstNameDiv {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 0px;
}

.FirstNameInputDiv {
   // width: 45%;
   height: 48px;
   background: #F7FAFF 0% 0% no-repeat padding-box;
   border: 1px solid #DDDDDD;
   border-radius: 6px;
   opacity: 1;
}

.RegistrationInputDiv {
   width: 100%;
   height: 48px;
   background: #F7FAFF 0% 0% no-repeat padding-box;
   border: 1px solid #DDDDDD;
   border-radius: 6px;
   margin-top: 20px;
   opacity: 1;
}

.CreatePasswordText {
   text-align: left;
   font: normal normal 600 16px/22px Open Sans;
   letter-spacing: 0px;
   color: #000000;
   margin-bottom: 5px;
   opacity: 1;
}

.CreatePasswordSubText {
   text-align: left;
   font: normal normal normal 16px/22px Open Sans;
   letter-spacing: 0px;
   color: #000000;
   opacity: 0.5;
}

.RegistrationLoginBtnDiv {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: baseline;
   margin-top: 22px;

   .RegistrationLoginBtn {
      width: 160px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FEC006 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 10px #0000001A;
      border-radius: 6px;
      opacity: 1;
      cursor: pointer;
   }

   .RegistrationLoginBtnText {
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      text-transform: uppercase;
      opacity: 1;
   }
}

.NextStepBtnDiv {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: baseline;
   margin-top: 22px;

   .NextStepBtn {
      width: 160px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 5px #386CB566;
      border-radius: 6px;
      opacity: 1;
      padding: 20px;

   }

   .NextStepText {
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      text-transform: uppercase;
      opacity: 1;
      margin-top: 15px;
   }

   .NextBtnImg {
      width: 20px;
      height: 12.83px;
   }
}

.BackBtnImg {
   width: 20px;
   height: 12.83px;
   margin-right: 5px;
}

.CreateStepBtnDiv {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-top: 1rem;

   .CreateStepBtn {
      width: 160px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 5px #386CB566;
      border-radius: 6px;
      opacity: 1;
      padding: 20px;
   }

   .CreateStepText {
      font-size: 13px;
      font-weight: 600;
      color: #FFFFFF;
      // text-transform: uppercase;
      opacity: 1;
      margin-top: 18px;
   }

   .BackStepBtn {
      height: 20px;
      margin-left: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      opacity: 1;
      cursor: pointer;

   }

   .BackStepText {
      font-size: 13px;
      font-weight: 600;
      color: #222222;
      // text-transform: uppercase;
      opacity: 1;
      margin-top: 18px;
   }
}

.LineSeparator {
   width: 435px;
   height: 0px;
   border-bottom: 1px solid #DDDDDD;
   opacity: 1;
   margin-top: 20px;
}

.bottomDiv {
   display: flex;
   flex-direction: column;
   margin-top: 1rem;

   .text-1 {
      text-align: center;
      font: normal normal normal 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 0px;
   }

   .text-2 {
      text-align: center;
      font: normal normal 600 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #000000;
      margin-top: 0px;
   }
}

.mTop20 {
   margin-top: 1rem;
}

.mTop40 {
   margin-top: 4rem;
}

.fLeft {
   float: left;
}

.fRight {
   float: right;
}

.width300 {
   width: 300px;
}

.checkBoxColorLogin {
   // color: #33D152;
   color: #000000;
   cursor: pointer !important;

}


/* ==========main Card Login ==========*/
.logincard {
   width: 25rem;
   border-radius: 1rem;
   padding: 0rem 3rem 2rem 3rem;
   margin: auto;
   text-align: center;
   margin-top: 3rem;
   border-color: #BBBBBB;
   background: transparent;
}

.mainIcon {
   max-width: 260px;
}

.proceedTextDiv {
   padding: 0px;
   align-items: left;
   text-align: left;
   margin-top: 40px;
}

.proceedText {
   margin: 0;
   font-weight: 500;
}

.loginInput p {
   margin: 0;
   font-size: 17px;
}

.login_text {
   font-size: 1.3rem;
   color: #386cb5;
   font-weight: 700;
   text-transform: uppercase;
   text-align: left;
   padding-bottom: 0.5px;
   /* border-bottom-style: solid;
   border-bottom-width: 0.2rem;
   width: -moz-fit-content;
   width: fit-content; */
}


/* ==========Input Div ==========*/
.inputLableDiv {
   margin-top: 15px;
   text-align: left;
}

.labelTextLogin {
   margin: 0;
   font-weight: 600;
}

.inputIcon {
   width: 1.2rem;
   height: 1.1rem;
   color: #909294;
}

.inputTypeLogin {
   border: 00.75px solid #DDDDDD;
   box-sizing: border-box;
   border-radius: 0.5rem;
   width: 19rem;
   height: 3rem;
   padding-left: 2.2rem;
}

.inputTypeDivlLogin {
   margin-top: .5rem;
   position: relative;
}

.imageICon {
   position: absolute;
   left: 0.5rem;
   top: 0.7rem;
}

.eyeIcon {
   position: absolute;
   right: 1rem;
   top: 0.8rem;
   cursor: pointer;

}

/* ==========Input Div ==========*/

/* ==========Keep me Login Div ==========*/
.checkIcon {
   width: 1rem;
   height: 1rem;
   margin-right: 0.2rem;
   margin-top: -.2rem;
}

.keepMeTextLogin {
   font-size: 0.9rem;
   margin-left: .2rem;
   margin-top: .2rem;
}

.Textforgot {
   font-size: 0.9rem;
   color: #0974DD;
   font-weight: 600;
   cursor: pointer;
}

/* ==========Keep me Login Div ==========*/


/* ==========Button Div ==========*/

.RegistrationBtn Button {
   border-radius: 0.375rem;
   height: 3.125rem;
   padding: .5rem;
   width: 100%;
   background-image: linear-gradient(to left, #386cb5, #669FED);
   font-size: 1rem;
   font-weight: 600;
   margin-top: 0.5rem;
   cursor: pointer;
}

.buttonTop {
   margin-top: 10px;
}

.SigninBtn {
   padding: .8rem 0 0 0;
}

.registerText {
   font-size: 0.9rem;
   color: #000000;
   font-weight: 600;

}

.registerErrorMessageDiv {
   margin-top: 1px;
   height: 6px;
   width: 100%;
}

.registerErrorMessage {
   color: #d80f0f !important;
   float: left !important;
   margin-top: -5px !important;
   margin-left: 30px;
   font-size: 12px !important;
   margin-bottom: 1px !important;
   font-weight: 600;
}

/* ==========Button Div ==========*/
.flag-dropdown {
   background-color: #FFFFFF !important;
   text-align: left;
}

.country-list {
   width: 198px !important;
}

.registerInputType {
   width: 100%;
   height: 45px;
   background: rgb(247, 250, 255);
   margin-bottom: 30px !important;
   margin-top: 20px !important;
   border-radius: 8px !important;
   border-color: #DDDDDD !important;
   // border: 0.5px solid #DDDDDD !important;
}
.registerInputTypeMarginBottm0 {
   width: 100%;
   height: 45px;
   background: rgb(247, 250, 255);
   margin-bottom: 0px;
   margin-top: 20px !important;
   border-radius: 8px !important;
   border-color: #DDDDDD !important;
   // border: 0.5px solid #DDDDDD !impor
}

.termsModalHeader {
   background: transparent linear-gradient(180deg, #639ae6, #3d71bb) 0 0 no-repeat padding-box;
   color: #fff;
   text-align: center;
   border-radius: 5px 5px 0 0 !important;
}

.closeModalIcon {
   position: absolute;
   right: 1rem;
   top: 1.2rem;
   color: #fff;
   cursor: pointer;
}

@media only screen and (min-width: 250px) and (max-width: 420px) {

   .logincard {
      padding: 2rem 4rem 2rem 3rem;
   }

   .eyeIcon {
      right: .5rem;
   }
}

@media only screen and (min-height: 960px) and (max-height: 1080px) {
   .RegistrationCardDiv1 {
      width: 500px;
      height: 75%;
      align-self: center;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 20px;
      opacity: 1;
      padding: 32px 32px 32px 32px;
   }
}

@media only screen and (min-height: 930px) and (max-height: 959px) {
   .RegistrationCardDiv1 {
      width: 500px;
      height: 68%;
      align-self: center;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 20px;
      opacity: 1;
      padding: 32px 32px 32px 32px;
   }
}

@media only screen and (min-height: 845px) and (max-height: 929px) {
   .RegistrationCardDiv1 {
      width: 500px;
      height: 78%;
      align-self: center;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 20px;
      opacity: 1;
      padding: 2rem 2rem 2rem 2rem;
   }
}

@media only screen and (min-height: 745px) and (max-height: 844px) {
   .RegistrationCardDiv1 {
      width: 500px;
      height: 85%;
      align-self: center;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 20px;
      opacity: 1;
      padding: 2rem 2rem 2rem 2rem;
   }
}

@media only screen and (min-height: 730px) and (max-height: 744px) {
   .RegistrationCardDiv1 {
      width: 500px;
      height: 89%;
      align-self: center;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 20px;
      opacity: 1;
      padding: 2rem 2rem 2rem 2rem;
   }

   .LineSeparator {
      width: 435px;
      height: 0px;
      border-bottom: 1px solid #DDDDDD;
      opacity: 1;
      margin-top: 12px;
   }

   .RegistrationLoginBtnDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 12px;
   }

   .registerInputType {
      width: 100%;
      height: 45px;
      background: #f7faff;
      margin-bottom: 22px !important;
      margin-top: 20px !important;
      border-radius: 8px !important;
      border-color: #DDDDDD !important;
   }

   .RegistrationCardDiv1 {
      width: 500px;
      height: 90%;
      align-self: center;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 20px;
      opacity: 1;
      padding: 1rem 2rem 1rem 2rem;
   }

   .RegistrationCardDiv1 .RegistrationText {
      font-size: 22px;
      color: #000000;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 5px !important;
   }

   .bottomDiv {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      margin-bottom: -20px !important;
   }
}

@media only screen and (min-height: 618px) and (max-height: 729px) {
   .RegistrationCardDiv {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 20px;
      opacity: 1;
      padding: 2rem 2rem 2rem 2rem;
      height: 85% !important;
   }

   .CreateStepBtnDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
   }
}