body {
    background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);
}
.RosterEnrolledContainer {
   background: #F0F0F0 ;
   height: 100%;
   overflow-x: hidden;
   padding: 0rem 4rem 1rem 4rem;
}
.RosterEnrolledHeading{
    font-size: 1.2rem;
    font-weight: 700;
}

/* ----------Back to class--------- */
.enrolledToRosterMainDiv{
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
}
.enrolledToRosterBackDiv{
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    background: #EEEEEE;
    width: 20rem;
    cursor: pointer;
}

.enrolledToRosterText{
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}
.enrolledBackIcon {
    font-size: 1.5rem;
}
/* ----------Back to class-------- */

/* ---------Student options Cards------------ */
.studentOptionDivEnrolled{
    /* padding: .5rem .2rem 0rem .2rem; */
    display:inline-flex
}
.opacityProp{
    cursor: default;
    opacity: 0.5;
}
.classOptionDivEnrolled{
    width: 3.9rem;
    height: 3.5rem;
    background: transparent linear-gradient(270deg, #FFB239 0%, #FFC76E 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
    opacity: .5;
}
.noteOptionDivEnrolled{
    width: 3.9rem;
    height: 3.5rem;
    background: transparent linear-gradient(270deg, #747BEB 0%, #989EFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
    cursor: pointer;
}
.gradebookOptionDivEnrolled{
    width: 3.9rem;
    height: 3.5rem;
    background: transparent linear-gradient(270deg, #08B596 0%, #27D3B4 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
    cursor: pointer;
}
.shareOptionDivEnrolled{
    width: 3.9rem;
    height: 3.5rem;
    background: transparent linear-gradient(270deg, #4479C4 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem .1rem 0rem .1rem;
    padding-top: .6rem;
    cursor: pointer;
}
.optionIconsEnrolled{
    width: 1.5rem;
}
.optionTextEnrolled{
    color: white;
    font-size: .7rem;
    font-weight: 600;
    margin-top: 0.3rem;
}
.optionGreadeBookIconsEnrolled{
    width: 2rem;
}
.optionGreadeBookTextEnrolled{
    color: white;
    font-size: .6rem;
    font-weight: 600;
    margin-top: 0.4rem;
}
/* ---------Student options Cards------------ */

/* ------------Roster Class Enrolled---------- */
.rosterClassListMain{
    width: 100%;
    margin-top: 1rem;
}

/* -------------Heading Div---------- */
.rosterClassHeadingDiv{
    width: 100%;
    height: 3rem;
    background-color: #669FED;
    justify-content: space-between;
    display: flex;
    padding: .5rem 1rem .5rem 1rem;
    border-radius: .3rem;
}
.rosterClassText{
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    margin-top: .3rem;
}
.rosterClassEditIcon{
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
}
.rosterClassEditText{
    color: white;
    font-size: 1rem;
    margin-left: .5rem;
}
.rosterUpdateButton{
    width: 4rem !important;
    height: 2rem !important;
    border-radius: 2rem !important;
    /* padding: .6rem !important; */
    background: #fff !important;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
}
.rosterButtonText{
    color: #386CB5;
    font-weight: 600;
    font-size: .8rem;
}


.rosterClassCancelText{
    color: white;
    font-size: .8rem;
    margin-left: .5rem;
    cursor: pointer;
}
/* -------------Heading Div---------- */


/* -------------Details Div---------- */
.rosterClassDetails{
    width: 100%;
    padding: 0rem 1rem 0rem 1rem;
}
.rosterClassDetailsParent{
    width: 100%;
    background-color: #fff;
    padding: .5rem 1rem .5rem 1rem;
}
.rosterClassDetailsListMain{
    width: 100%;
    justify-content: space-between;
    display: flex;
}
.rosterClassDateText{
    color: #000;
    font-weight: 600;
    font-size: 1rem;
}
.rosterClassDetailsMain{
    width: 100%;
    border-bottom: 1px solid #DDDDDD;
}
.rosterClassDetailsLastMain{
    width: 100%;
}
.rosterClassDetailsText{
    color: #888888;
    font-size: .8rem;
    text-align: left
}

.inputMainDivFOrInline{
    display: inline-flex
}

.levelTextWithINput{
    margin-top: 3px;
    margin-left: 1px;
    margin-right: 10px;
    color: #000;
    font-weight: 600;
    font-size: 1rem;
}
.horizantalLine{
    margin-top: 3px;
    margin-left: 1px;
    margin-right: 10px;
    color: #DDDDDD;
    font-weight: 600;
    font-size: 1rem;
}
.levelInput{
    border: 0.75px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: .3rem;
    height: 2rem;
    padding-left: .5rem;
    background: #F4F6FA;
    width: 6rem;
}
/* -------------Details Div---------- */



@media only screen and (min-width: 250px) and (max-width: 650px) {
    .RosterEnrolledContainer {
        padding: 0rem .5rem 0rem .5rem;
     }
     .inputMainDivFOrInline{
        display: block
    }
    .enrolledToRosterMainDiv{
        display: block;
    }
    .enrolledToRosterBackDiv {
        padding: 1rem 1rem 1rem 1rem;
        background: #EEEEEE;
        width: 100%;
    }
    .horizantalLine{
        display: none;
    }
    .rosterClassDetailsParent {
        padding: .5rem .5rem .5rem .5rem;
    }
}
