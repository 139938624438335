.notificationDashboard{
    .modal-content{
        border-radius: 8px;
    }
    .modal-dialog{
        top: 67px !important;
        right: 50px!important;
    }
    .modal-title{
        font: normal normal 600 16px/22px Open Sans;
    }
    .closeHeader{
        background: none;
        outline: none;
        border: none;
    }
    .notificationModalBody{
        padding: 0px 20px 20px 20px !important;
            max-height: 542px;
            overflow-y: auto;
    }
    .notificationCard{
        padding-top: 30px;
        padding-bottom: 15px;
        border: none;
        border-bottom: .1px solid #DDDDDD;
        border-radius: 0px;
        position: relative;
    }
    .circleCentre{
        display: flex;
        justify-content: center;
    }
    .notificationProfileText{
        color: white;
        padding-top: 20%;
        text-transform: uppercase;
        width: 3.3rem;
        height: 3.3rem;
        background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
        box-shadow: 1px 2px 3px #0000001A;
        border-radius: 50rem;
        margin-top: 0rem;
        text-align: center;
        opacity: .8;
    }

    .closeButtonInNotification{
        outline: none;
        right: 2px;
        top: 15px;
        position: absolute;
        border-radius: 100%;
        margin-bottom: 4px;
    }
    .closeIconInNotification{
        height: 10px;
        width: 10px;
        margin-top: -5px;
    }
    .notificationProfile{
        border-radius: 100%;
        height: 50px;
        width: 50px;
        margin-bottom: 10px;
    }
    .unCheckBoxfilter {
        cursor: pointer;
        font-size: 21px;
        margin-right: .2rem;
    }
    .checkBoxfilter {
        color: #386cb5;
        cursor: pointer;
        font-size: 21px;
        margin-right: .2rem;
    }
    .notificationTextCol{
        padding-left: 0px !important;
    }

    .notificationText{
        color: var(--unnamed-color-222222);
        text-align: left;
        font: normal normal normal 13px/18px Open Sans;
        letter-spacing: 0px;
        color: #222222;
    }
    .noNotificationText{
        color: var(--unnamed-color-222222);
        text-align: left;
        font: normal normal normal 13px/18px Open Sans;
        letter-spacing: 0px;
        color: #222222;
        display: flex;
        justify-content: center;
        padding-top: 25px;
    }
    .alignEnd{
        display: flex;
        justify-content: flex-end;
    }
    .notificationAcceptButton{
        width: 70px;
        height: 28px;
        background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
    }
    .notificationRejectButton{
        width: 70px;
        height: 28px;
        background: transparent linear-gradient(270deg, #EA3838 0%, #F05E5E 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        margin-left: 5px;
        opacity: 1;
    }
    .notificationButtonText{
        color: var(--unnamed-color-ffffff);
        margin-left: 0rem;
        font: normal normal 600 12px/17px Open Sans;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        position: relative;
        bottom: 1px;
    }
    .notificationTime{
        font: normal normal normal 11px/15px Open Sans;
        letter-spacing: 0px;
        color: #AAAAAA;
        opacity: 1;
        margin-top: 7px;
        margin-bottom: 0px;
    }
    .notificationButtonCol{
        text-align: left;
    }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
    .notificationDashboard{
        margin-right: 12px!important;
        width: 311px;
    
        .notificationModalBody{
            padding: 0px 20px 20px 20px !important;
            max-height: 542px;
            overflow-y: auto;
        }
        .notificationCard{
            padding-top: 30px;
            padding-bottom: 15px;
            border: none;
            border-bottom: .1px solid #DDDDDD;
            border-radius: 0px;
            position: relative;
            padding-left: 20px;
        }
        .notificationProfileText{
            color: white;
            padding-top: 5%;
            text-transform: uppercase;
            width: 3.3rem;
            height: 3.3rem;
            background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
            box-shadow: 1px 2px 3px #0000001A;
            border-radius: 50rem;
            margin-top: 0rem;
            text-align: center;
            opacity: .8;
        }
    }
}
@media only screen and (min-width: 451px) and (max-width: 1600px) {
    .notificationDashboard{
        margin-right: 12px!important;
        width: 311px;
    }
}
@media only screen and (min-width: 150px) and (max-width: 450px) {
    .notificationDashboard{
        margin-right: 12px!important;
        width: 311px!important;
        .notificationModalBody{
            padding: 0px 20px 20px 20px !important;
            max-height: 542px;
            overflow-y: auto;
        }
        .notificationCard{
            padding-top: 30px;
            padding-bottom: 15px;
            border: none;
            border-bottom: .1px solid #DDDDDD;
            border-radius: 0px;
            position: relative;
            padding-left: 20px;
        }
        .notificationProfileText{
            color: white;
            padding-top: 5%;
            text-transform: uppercase;
            width: 3.3rem;
            height: 3.3rem;
            background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
            box-shadow: 1px 2px 3px #0000001A;
            border-radius: 50rem;
            margin-top: 0rem;
            text-align: center;
            opacity: .8;
        }
    }
}
