.evalutionContainer {
    overflow-y: scroll;

    .planTitleDiv {
        width: 100%;
        min-height: 85px;
        display: flex;
        background-color: white;
        border-radius: 20px;

        .algnDivs {
            flex: 0.5;
            padding: 10px;

            .planTitle {
                flex: 0.45;
                padding: 10px;
                display: flex;

                .headTitlesPlan {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                }

                .headTitles {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                    padding-left: 5px;
                }
            }
        }
    }

    .emojiDiv {
        width: 100%;
        min-height: 85px;

        background-color: white;
        border-radius: 20px;
        margin-top: 10px;
        padding: 15px;

        .titleDiv {
            display: flex;
            width: 100%;

            .analyticsTitle {
                flex: 1;
                text-align: left;
                font-size: 1rem;
                font-weight: 600;
                margin-bottom: 0px;
            }

            .emojiColHead {
                flex: 0.06;
                font-size: 0.85rem;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .emojiIconsDetails {
            display: flex;

            .questionTitle {
                align-items: center;
                display: flex;
                flex: 1;
                font-size: 0.85rem;
                font-weight: 600;
                text-align: left;
                overflow-wrap: anywhere;
            }

            .emojiIcons-img {
                flex: 0.06;
            }
        }

        .addMoreInputDiv {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0px 15px 0px;

            .rationalInputText {
                width: 80%;
                border: none;
                border: 1px solid #dddddd;
                padding-left: 10px;
                height: 40px;
                outline: none;
                background: #f4f6fa;
                border-radius: 5px;

                color: black;
                font-size: 0.88rem;
                font-weight: 600;
            }

            input::placeholder {
                font-style: italic;
                font-size: 0.95rem;
                color: #dddddd;
            }

            input[type="text"]:focus,
            textarea:focus {
                outline: none;
            }

            .displayFlexInSeletAtt {
                padding-right: 10px;
                display: flex;
                align-items: center;

                .undoSelect {
                    width: 2.5rem;
                    height: 2.5rem;
                    background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10rem;
                    opacity: 1;
                    margin-right: 1rem;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .CrossAndConfirmIcon {
                        color: white;
                        padding-top: 7px;
                    }
                }
            }
        }

        .addMoreBtn {
            color: #386cb5;
            font-weight: 600;
            font-size: 0.89rem;
            cursor: pointer;
            outline: none;
            border: none;
            background: none;
            display: flex;
        }
    }

    .allSubDivs {
        height: 256px;

        .rationalDiv {
            padding-top: 10px;

            .rationalTitleBox {
                background-color: #669fed;
                height: 42px;
                border-radius: 10px;
                display: flex;
                align-items: center;

                .rationalTitle {
                    padding-left: 10px;
                    margin-bottom: 0px;
                    color: white;
                    font-size: 0.98rem;
                    font-weight: 600;
                }
                .backendQuestionCaps {
                    text-transform: uppercase;
                }

                .addMoreBtns {
                    font-size: 0.98rem;
                    font-weight: 600;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    background: none;
                    display: flex;
                    color: white;

                    .lpOptionIcons {
                        width: 1.1rem;
                        margin-right: 5px;
                    }
                }

                .rationalInputsText {
                    width: 91%;
                    border: none;
                    border-radius: 10px;
                    padding-left: 10px;
                    color: white;
                    background-color: #669fed;
                    height: 42px;
                    padding-top: 10px;
                    outline: none;
                }

                textarea::placeholder {
                    font-style: italic;
                    font-size: 0.95rem;
                    color: white;
                }
            }

            .rationalInputBox {
                margin: 0rem 0.9rem;
                border: none;
                border-radius: 10px;
                min-height: 70px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                padding-left: 10px;
                background-color: white;
                display: flex;

                .rationalTextData {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                }

                .rationalInputText {
                    width: 100%;
                    border: none;
                    border-radius: 10px;
                    min-height: 70px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    padding-left: 10px;
                    outline: none;
                }

                textarea::placeholder {
                    font-style: italic;
                    font-size: 0.95rem;
                    color: #9fa0a3;
                }
            }
        }
    }
}

@media only screen and (min-width: 357px) and (max-width: 820px) {
    .planningContainer {
        .planTitleDiv {
            flex-wrap: wrap;
        }
    }
}

@media only screen and (min-width: 357px) and (max-width: 577px) {
    .planningContainer {
        .planScheduleDiv {
            flex-wrap: wrap;
        }
    }
}

@media only screen and (min-width: 251px) and (max-width: 768px) {
    .evalutionContainer {
        .emojiDiv {
            .titleDiv {
                .analyticsTitle {
                    flex: 0.7;
                }

                .emojiColHead {
                    flex: 0.108 1;
                }
            }
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 820px) {
    .evalutionContainer {
        .emojiDiv {
            .titleDiv {
                .analyticsTitle {
                    flex: 0.8;
                }

                .emojiColHead {
                    flex: 0.108 1;
                }
            }
        }
    }
}

.evalutionContainer::-webkit-scrollbar {
    overflow-y: scroll;
    width: 22px;
    height: 50px;
    border-radius: 2px !important;
    position : 'relative';
    top: 5px;
    display: block !important;
    width: 3px;
    // background: transparent;
  
  }
  .evalutionContainer::-webkit-scrollbar-thumb {
    background-color: #888;
  }