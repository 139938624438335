.table-Fix-Size {
    height: 74.6vh;
    background: white;
    overflow: auto;
}
.table-gradebook {
    display: flex;
    flex-direction: column;
    margin: 15px 0px 0px 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0px 1px 20px #2222221a;
    .noGradebookDataText {
        color: #aaaaaa;
        // font-size: 1.5rem;
        // font-weight: 600;
        margin-top: 28%;
        font: normal normal 600 20px/14px Open Sans;
    }
    .table-gradebook-table {
        display: flex;
        flex-direction: row;
        height: 70vh;
        overflow: scroll;
    }
    .table-gradebooks {
        flex-direction: row;
        display: flex;
    }
    .table-Average-section {
        display: flex;
        overflow: hidden;
        overflow-y: scroll;
        overflow-x: auto;
        width: 100%;
        background: #EEEEEE;
        .data-title {
            width: 120px;
            font: normal normal 600 20px/27px Open Sans;
        }
    }
    .table-left-section-Average {
        min-width: 319px;
        background-color: transparent;
        border: none;
        border-bottom: none;
    }
    .table-left-section {
        // width: 220px;
        min-width: 317px;
        background-color: white;
        border-bottom: none;
        border-top-left-radius: 8px;
        .header-title {
            label {
                font: normal normal 12px/17px Open Sans;
                color: #777777;
                margin: 12px;
                text-align: left;
            }
        }
        .header-style-top-title {
            height: 80px;
            border-bottom: 1px solid #cccccc;
            display: flex;
            cursor: pointer;
            border-right: 1px solid #cccccc;
        }
        .profile {
            text-align: left;
            margin: auto;
            display: flex;
            margin-left: 12px;

            .profileDummyRosterProfile {
                width: 3.3rem;
                height: 3.3rem;
                background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                box-shadow: 1px 2px 3px #0000001a;
                border-radius: 50rem;
                margin-top: 0rem;
                text-align: center;
                opacity: 0.8;

                .rosterProfileImageText {
                    color: white;
                    padding-top: 28%;
                    text-transform: uppercase;
                }
            }

            .profile-img {
                width: 50px;
                height: 50px;
                background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                border-radius: 50rem;
            }

            label {
                font: normal normal 600 13px/18px Open Sans;
                color: #222222;
                font-weight: 600;
                margin: auto;
                margin-left: 8px;
            }
        }
    }
    .table-left-scroll-custom {
        height: 70vh;
        overflow: scroll;
    }

    .table-middle-section {
        width: 118px;
        background-color: white;
        border-bottom: none;
        .header-style {
            height: 80px;
            display: contents;
            cursor: pointer;
            .profile {
                height: 80px;
                border-bottom: 1px solid #cccccc;
                display: flex;
            }
            .profileScore {
                height: 80px;
                border-bottom: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #cccccc;
                font: normal normal 600 16px/22px Open Sans;
                letter-spacing: 0px;
                color: #222222;
            }
            .eventNameDiv {
                border-bottom: 1px solid #cccccc;
                height: 40px;
                text-align: center;
                font: normal normal 600 12px/17px Open Sans;
                letter-spacing: 0px;
                color: #386cb5;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #cccccc;
                .labelTitle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0px !important;
                }
            }
            .categoryNameDiv {
                border-bottom: 1px solid #cccccc;
                height: 40px;
                text-align: center;
                letter-spacing: 0px;
                color: #386cb5;
                display: flex;
                justify-content: center;
                align-items: center;
                font: normal normal normal 12px/17px Open Sans;
                border-right: 1px solid #cccccc;
                .labelTitle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0px !important;
                }
            }
        }
    }
    .table-right-section {
        width: calc(87vw - 320px);
        background-color: white;
    }

    .header-style {
        height: 80px;
        border-bottom: 1px solid #cccccc;
        display: flex;
        cursor: pointer;
        border-right: 1px solid #cccccc;
    }

    .avg-row-header {
        background: #EEEEEE;
        border: 1px #cccccc;
        height: 50px;
        min-width: 317px;
        margin: auto;
        font: normal normal 600 16px/22px Open Sans !important;
        text-align: left;
        padding: 12px 20px;
        display: flex;
        .avgTitle {
            width: 100%;
            flex: 0.8;
            font: normal normal 600 16px/22px Open Sans;
            letter-spacing: 0px;
            color: #aaaaaa;
        }
        .avgScoreNull {
            display: flex;
            justify-content: center;
            width: 100%;
            flex: 0.3;
            font: normal normal 600 20px/27px Open Sans;
        }
    }
}
