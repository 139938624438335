body {
    // background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);

}

.academicMainContainer {
    background: #F0F0F0;
    min-height: 83vh;
    // overflow-x: hidden;
    padding: 0rem 3rem 0rem 3rem !important;
}

.addAcademicStart {
    margin-top: 5%;
}

.addAcademicImage {
    width: 5rem;
    cursor: pointer;
}

.tapToAddText {
    color: #AAAAAA;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 70px;

}

/* ----------Dummy Image Section------- */

.imageDummyDivProfile {
    width: 3rem;
    height: 3rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    margin-top: .5rem;
    text-align: center;
    margin-left: .4rem;
    opacity: .8;
}

.profileImageDummyText {
    /* padding: 1.2rem 0rem 0rem 1.2rem; */
    color: white;
    padding-top: 26%;
    text-transform: uppercase;
}

/* ----------Dummy Image Section-------*/



/* ----------Profiler Section-------- */
.profileSectionCol {
    padding: 0rem 0rem 0rem .4rem;
    width: 33.33%;
    float: left !important;
    text-align: left;
}

.profileDiv {
    width: 354px;
    display: inline-flex;

}

.profileImage {
    width: 3rem;
    height: 3rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    margin-top: .7rem;
}

.profileNameDiv {
    // padding: 1rem 0rem 0rem .5rem;
    padding: 20.5px 0px 0px 16px;
}

.profileName {
    // font-size: .8rem;
    margin: 0rem;
    font-weight: 600;
    color: #222222;

    color: var(--unnamed-color-222222);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    opacity: 1;
}

.profileInfo {
    font-size: .8rem;
    margin: 0rem;
    color: #386CB5;
    cursor: pointer;
}

.profileForword {
    height: 9px;
    margin-left: 3px;
}

/* ----------Profiler Section-------- */

/* ----------Add New Academic Section-------- */
.addNewSectionMain {
    padding: .5rem 1rem 1rem 1rem;
    display: inline-flex;
    width: 100%;
    justify-content: space-between
}

.addNewSectionCol {
    width: 33.33%;
    padding: 1rem 0rem 0rem 0rem;
    float: center !important;
    text-align: center;
}

.progressScoreMainDiv {
    display: flex;
    justify-content: space-between;
}

.progressScoreSubDiv {
    display: flex;
    flex-direction: column;
}

.sectionSeprator {
    width: 1px;
    height: 40px;
    border-right: 2px solid #DDDDDD;
    align-self: center;
    margin-right: 1rem;
}

.addNewIcon {
    height: .8rem;
    margin-top: .2rem;
    padding-left: 10px;
}

.closeNewIcon {
    height: 1.2rem;
    padding-left: 10px;
}

.addButtonText {
    /* margin-left: .9rem;
    color: white;
    font-size: 1.2rem; */
    // margin-left: .5rem;
    color: white;
    font-size: .8rem;
    padding-right: 15px;
    font-weight: 600;
}

.divForScroll {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-top: 6px;
    // padding-bottom: 2px;
    scrollbar-width: 15px !important;
    height: 48px;
}

.divForScroll::-webkit-scrollbar {
    height: 4px;
    // width: 4px;
    // border-radius: 2px !important;
}

.addNewButton {
    width: 6rem;
    height: 2.4rem !important;
    border-radius: 0.5rem !important;
    padding: .6rem .7rem !important;
    font-size: .6rem !important;

    /* width: 12rem !important; */
    /* height: 3.3rem !important; */
    /* border-radius: 2rem !important; */
    /* padding: .8rem !important; */
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    /* background: #386CB5 0% 0% no-repeat padding-box !important; */
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    /* font-size: 1rem !important; */
}

/* ----------Add New Academic Section-------- */


// ------------Delete academic year Modal--------/
.deleteAca-modal-warning .modal-title {
    text-align: center;
    color: white;
    font: normal normal 600 18px/24px Open Sans;
}

.deleteAca-modal-warning .modal-content {
    border-radius: 8px;
    width: 360px;
    margin: auto;
    background-color: black;
}

.deleteAca-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 0px !important;
}

.deleteAca-modal-warning .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
}

.deleteAca-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal 600 14px/19px Open Sans !important;
    color: white;
    margin-bottom: 0;
}

.deleteAca-modal-warning .modal-footer {
    border: none;
    justify-content: center;
    padding-top: 0;
}

.deleteAca-modal-warning .cancel-btn {
    width: 101px;
    height: 38px;
    font: normal normal 600 14px/19px Open Sans;
}

.deleteAca-modal-warning .delete-btn {
    background: transparent linear-gradient(270deg, #EC4444 0%, #FA6060 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font: normal normal 600 14px/19px Open Sans;
    color: #FFFFFF;
    width: 101px;
    height: 38px;
}


// ------------Delete academic year Modal--------/

/* ----------Progress Bar Section-------- */
.progressBarDiv {
    // height: 80px;
    background-color: white !important;
    margin-right: auto;
    margin-left: auto;
    // margin-top: 15px;
    box-shadow: 0px 1px 3px #2222221A;
    border-radius: 0px 0px 8px 8px;
}

.progressSectionCol {
    padding: .5rem .4rem 0rem 0rem;
    width: 33.33%;
    display: grid;
    justify-content: end;

}

.progress {
    height: 10px !important;
    border-radius: 1.5rem !important;
    width: 280px;
    margin: .1rem 0rem .1rem 0rem;
    float: right;
}

.progressbarfirst {
    width: 0%;
    /* background-image: linear-gradient(45deg, #FD1010 25%, #F96017 50%); */
    background-image: linear-gradient(45deg, #FD1010 25%, #F96017 50%, #F6AD1E 75%, #40CC3B 100%);
    /* background: linear-gradient(45deg, #FD1010 30%, #F96017 50%,#F6AD1E 80%,#40CC3B 100%); */
    box-shadow: inset 0px -2px 5px #FD1010(0, 0, 0, 0.5);
    animation: change 10s linear infinite
}

.score_0 {
    color: #FD1010;
    margin-bottom: 0rem;
}

.score_500 {
    color: #40CC3B;
    margin-bottom: 0rem;
}

.progressScoreDiv {
    width: 280px;
    display: inline-flex;
}

.progressbarScoreText {
    font-size: .8rem;
    text-align: left;
    width: 100%;
}

.progressbarNoteText {
    font-size: .8rem;
    width: 50%;
    text-align: right !important;
}

.totalScore {
    font-size: .8rem;
    font-weight: 600;
    margin: 0rem;
    margin-bottom: .2rem
}

.addNoteColor {
    font-size: 1rem;
    color: #5086D2;
    cursor: pointer;
}

/* ----------Progress Bar Section-------- */




/* =========hide Div CSS================= */
.academicPaddingDiv {
    padding: 0rem 1rem 0rem 1rem;
}

.addNewAcademic {
    padding: 1rem 1rem 1rem 1rem;
    display: inline-flex;
    width: 100%;
    border-top: 1px solid #EEEEEE;
    opacity: 1;


}

.academicTextDiv {
    width: 50rem;
    font-weight: 600;
    text-align: left;
    padding: .2rem;
    position: relative;
}

.academicText {
    // letter-spacing: 0px;
    // color: #222222;
    text-align: left;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    margin: 0px !important;
}

.academicYearDiv {
    width: 100%;
    /* display:inline-flex */
}

.notSelectedYear {
    float: left;
    height: 2.3rem;
    width: 7rem;
    background: transparent 0% 0% no-repeat padding-box;
    // border: 1px solid #888888;
    border-radius: 8px;
    opacity: 1;
    padding: .4rem;
    margin: 0px .5rem 0rem .5rem;
    text-align: center;
    cursor: pointer;

    display: flex;
    // flex-direction: column;
    justify-content: center;

}

.popUp-notSelectedYear {
    float: left;
    height: 2.3rem;
    width: 6.4rem;
    padding: 0.6rem;
    margin: 0.313rem 0.313rem;
    text-align: center;
    border-radius: 7px;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EEEEEE;
    cursor: default;
}

.selectedYear {
    float: left;
    height: 2.3rem;
    width: 7rem;
    background: #669FED 0% 0% no-repeat padding-box;
    /* border: 0.5px solid #CCCCCC; */
    border-radius: 8px;
    opacity: 1;
    padding: 0.4rem;
    margin: 0rem 0.5rem 0rem 0.5rem;
    text-align: center;

}

.popUp-selectedYear {
    float: left;
    height: 2.3rem;
    width: 6.4rem;
    padding: 0.6rem;
    margin: 0.313rem 0.313rem;
    text-align: center;
    background: #386CB51A 0% 0% no-repeat padding-box;
    border: 1px solid #386CB5;
    border-radius: 7px;
    opacity: 1;
    cursor: default;

}

.notSelectedY {
    float: left;
    height: 2.3rem;
    width: 7rem;
    background: transparent 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    padding: 0.4rem;
    margin: 0px 0.5rem 0rem 0.5rem;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        margin-bottom: 0;
    }
    &:has(span) {
        p {
            font-weight: 600;
        }
    }
}

.notSelectedYearText {
    color: #00000080;
    opacity: 1;
    margin-bottom: 0px;
    // font-weight: 600;
    // color: #222222;
}


.popUp-notSelectedYearText {

    // text-align: left;
    font: normal normal normal 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #AAAAAA;
    opacity: 1;
    margin: 0px !important;
}

.selectedYearText {
    padding: 0px !important;
    color: #FFFFFF;
    opacity: 1;
    font-weight: 600;
}

.popUp-selectedYearText {
    padding: 0px !important;
    color: #FFFFFF;
    opacity: 1;
    font-weight: 600;
    font: normal normal 600 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #386CB5;
    opacity: 1;
}

.currentYearNotSelected {
    color: #000;
    font-weight: 600;
    // color: #222222;
    opacity: 1;
    margin-bottom: 0px !important;
}

.current_year {
    color: #386CB5;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    margin-top: -14px;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* =========hide Div CSS================= */




/* ============AcademicEditSection========== */
.academicYearsMainSection {
    margin: 1rem 0rem 1rem 0rem;
}

.academicEditSection {

    display: inline-flex;
    width: 100%;
}

.academicYearDiv {
    width: 600%;
    text-align: left;
    // margin-bottom: 1.3rem;
    overflow-x: auto;
    white-space: nowrap;
    overflow: hidden;
}

.academicYearSubDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
}

.classListSeprator {
    width: 1px;
    height: 40px;
    border-right: 2px solid #DDDDDD;
    align-self: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.academicEditDiv {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 10px;
}

.yearsText {
    font-size: 1rem;
    font-weight: 600;
    color: #222222;
    opacity: 1;
    padding-left: 16px;
}

.editDivBlack {
    display: inline-flex;
    color: #222222;
    margin: 0rem 1rem 0rem 1rem;
    cursor: pointer;
    font-size: .9rem
}

.deleteDivRed {
    display: inline-flex;
    color: #FF4444;
    cursor: pointer;
    font-size: .9rem
}

.editIcon {
    font-size: 1rem;
}

.iconText {
    font-size: .8rem;
    margin-left: 1px;
    font-weight: bold;
}

/* ============AcademicEditSection========== */

@media only screen and (min-width: 970px) and (max-width: 1030px) {
    .progress {
        width: 280px !important;
    }

    .progressScoreDiv {
        width: 280px !important;

    }

    .profileDiv {
        width: 300px !important;

    }

    .addNewSectionCol {
        text-align: center;
        padding: 0px !important;
    }

    .addNewButton {
        width: 6rem;
        height: 2.4rem !important;
        border-radius: 2rem !important;
        padding: .6rem !important;

        font-size: .6rem !important;
    }

    .addNewIcon {
        height: .8rem;
    }

    .addButtonText {
        margin-left: .5rem;
        color: white;
        font-size: .8rem;
        padding-right: 15px;
        font-weight: 600;
    }



}

@media only screen and (min-width: 701px) and (max-width: 970px) {
    .academicMainContainer {
        padding: 0rem !important;
    }

    .academicEditSection {
        padding: 0rem .5rem 0rem .5rem;
    }

    .addNewSectionMain {
        padding: 1rem .3rem 0rem .3rem;
    }

    .progress {
        width: 250px !important;
    }

    .progressScoreDiv {
        width: 250px !important;

    }

    .profileDiv {
        width: 250px !important;

    }

    .addNewSectionCol {
        text-align: center;
        padding: 0px !important;
    }

    .addNewIcon {

        height: .8rem;
    }

    .progressbarNoteText {
        font-size: .8rem;
    }

    .totalScore {
        font-size: .8rem;
    }

    .addNoteColor {
        font-size: .8rem;
    }

    .profileNameDiv {
        padding: .5rem 0rem 0rem .5rem;
    }

    .profileImage {
        width: 3rem;
        margin-top: 0rem;
    }

    .profileName {
        font-size: .8rem;
    }

    .profileInfo {
        font-size: .8rem;
    }

    .imageDummyDivProfile {
        margin-top: 0rem;
    }



    /* -----------------PopOverCss--------- */




}

@media only screen and (min-width: 470px) and (max-width: 700px) {
    .academicMainContainer {
        padding: 0rem !important;
    }


    .addNewSectionMain {
        padding: .5rem 1rem 0rem 1rem;
        display: block;
    }

    .profileSectionCol {
        padding: 0rem 0rem 0rem .4rem;
        display: initial;
        float: none !important;
    }

    .profileDiv {
        margin: 1rem !important;
    }

    .addNewSectionCol {
        width: 100%;
        padding: 0px !important;
        display: initial;
        float: none !important;
        margin-top: 2rem;
    }

    .buttonDiv {
        margin: 25px;
        width: 100%;
    }

    .progressSectionCol {
        padding: .5rem .4rem 0rem 0rem;
        width: 100%;
        display: grid;
        display: initial;
        float: none !important;

        margin-top: 2rem;
    }

    .progress {
        float: none !important;
        display: inline-flex !important;
        /* width: 100%; */
    }


    .academicEditSection {
        padding: 0rem .5rem 0rem .5rem;
    }

    .progressSectionCol {
        padding: .5rem .4rem 0rem 0rem;
        width: 100%;
        display: grid;
        display: initial;
        float: none !important;

        margin-top: 2rem;
    }

    .progress {
        float: none !important;
        display: inline-flex !important;
        width: 100%;
    }

    .progressScoreDiv {
        width: 100%;
    }

    .imageDummyDivProfile {
        margin-top: 0rem;
    }







}

@media only screen and (min-width: 250px) and (max-width: 469px) {
    .academicMainContainer {
        padding: 0rem !important;
    }

    .addNewAcademic {
        display: block;
    }

    .imageDummyDivProfile {
        margin-top: 0rem;
    }


    .addNewSectionMain {
        padding: .5rem 1rem 0rem 1rem;
        display: block;
    }

    .profileSectionCol {
        padding: 0rem 0rem 0rem .4rem;
        width: 100%;
        display: initial;
        float: none !important;
    }

    .profileDiv {
        margin: 1rem !important;
        width: 100%;
    }

    .addNewSectionCol {
        width: 100%;
        padding: .5rem 0rem 0rem 0rem;
        display: initial;
        float: none !important;
        margin-top: 2rem;
    }

    .buttonDiv {
        margin: 25px;
        width: 100%;
    }

    .progressSectionCol {
        padding: .5rem .4rem 0rem 0rem;
        width: 100%;
        display: grid;
        display: initial;
        float: none !important;

        margin-top: 2rem;
    }

    .progress {
        float: none !important;
        display: inline-flex !important;
        width: 100%;
    }

    .progressScoreDiv {
        width: 100%;
    }

    .academicYearDiv {
        text-align: left;
        display: inline-flex;
        overflow: scroll;
        overflow-y: scroll;
        width: calc(100vw - 100px);
    }

    .notSelectedYear {
        min-width: 100px;
    }

    .selectedYear {
        min-width: 100px;
    }


    /* .academicYearDiv{
        display: block;
    } */
    .academicEditSection {
        padding: 0rem .5rem 0rem .5rem;
    }


}

/* @media only screen and (min-width: 270px) and (max-width: 670px) {


} */
.academicPopOver .bs-popover-auto[x-placement^=left]>.arrow::after,
.bs-popover-left>.arrow::after {
    border-left-color: #fff !important;
}

.academicPopOver {

    /* ------------PopOver Header--------- */
    .popover {
        width: 20rem;
        padding: 0rem 0rem 1rem 0rem !important;
        border-radius: 11px !important;
        width: 15.3rem !important;
        margin-left: 41px;
        //  background: transparent !important;
        //  border: none !important;

    }

    .popover-header {
        height: 2.5rem !important;
        font-size: 1rem !important;
        background: transparent linear-gradient(180deg, #639AE6 0%, #3D71BB 100%) 0% 0% no-repeat padding-box;
        color: white;
        text-align: center;
        border-top-left-radius: calc(.6rem - 1px) !important;
        border-top-right-radius: calc(.6rem - 1px) !important;
    }



    .popover-body {
        padding: 0rem !important;
    }

    .editInputDiv {
        text-align: center;
        // border-bottom: 1px solid #EEEEEE;

    }

    .editYearInputType {
        width: 15rem !important;
    }

    .notSelectedYearPopover {
        float: left;
        height: 2.3rem;
        width: 6.4rem;
        opacity: 1;
        padding: 0.6rem;
        margin: 0.3rem 0.3rem 0.3rem 0.3rem;
        text-align: center;
        cursor: pointer;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EEEEEE;
        border-radius: 7px;
    }

    .selectedYearPopover {
        float: left;
        height: 2.3rem;
        width: 6.4rem;
        opacity: 1;
        padding: .6rem;
        margin: .3rem .3rem .3rem .3rem;
        text-align: center;
        border: 1px solid var(--unnamed-color-386cb5);
        background: #386CB51A 0% 0% no-repeat padding-box;
        border: 1px solid #386CB5;
        border-radius: 7px;
    }

    .notSelectedYearTextPopover {
        // color: #AAAAAA;
        // font-size: .8rem;

        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-aaaaaa);
        font: normal normal normal 12px/17px Open Sans;
        letter-spacing: 0px;
        color: #AAAAAA;
        opacity: 1;
    }

    .selectedYearTextPopover {
        // color: #CCCCCC;
        // font-size: .8rem;
        padding: 0px !important;
        color: #FFFFFF;
        opacity: 1;
        font-weight: 600;
        font: normal normal 600 12px / 17px Open Sans;
        letter-spacing: 0px;
        color: #386CB5;
        opacity: 1;
    }

    .popoverList {
        // height: 208px;
        height: 146px;
        width: 244px;
        overflow: hidden;
        overflow-y: scroll;
        padding: 0.6rem .6rem;
        grid-template-columns: repeat(1, 0fr);
        flex-wrap: wrap;
        display: flex;
        overflow: hidden;
    }

    .updateButton {
        width: 7rem;
        height: 2rem;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        border-radius: 7px !important;
        margin: .6rem 1rem 0rem 0rem;



    }

    .updateButtonText {
        margin: -.3rem;
    }

    .editYearInputUpdateType {
        width: 92% !important;
        height: 2.4rem;
        margin-top: .5rem;
        padding-left: .4rem;
        background: #F4F6FA;
        margin-bottom: .2rem;
        border: .1rem solid #ccc;
        border-radius: .3rem;
        outline: none;
        font-size: 12px;
        // font-style: italic;
    }

    ::-webkit-input-placeholder {
        /* Chrome, Safari, Opera */
        color: #ccc !important;
    }

    .afterDate {
        color: black;
        border: .1rem solid black;
    }

    .inputMaskUpdateParentDiv {
        display: inline-flex;
        width: 100%;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: .3rem;
    }

    .inputMaskUpdateDiv {
        width: 100%;
        padding: 0rem .3rem 0rem .3rem;
    }

    .errormessageUpdate {
        color: #d80f0f !important;
        float: left !important;
        font-size: 12px !important;
        margin-bottom: 1px !important;
    }

}
.schltchr {
    display: none !important;
}
/* ------------PopOver Header--------- */