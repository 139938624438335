.addMainDiv {
    border-radius: 0px 6px 6px 0px;
    border-radius: 0px 6px 6px 0px;
    height: 70px !important;
    padding: 9px !important;

    .profile {
        text-align: left;
        padding-left: 20px;
        .img-div {
            .imgGoal {
                width: 3rem;
                height: 3rem;
                background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
                border-radius: 50rem;
                float: left;
                margin-right: 1rem;
            }
            .studentProfileImageDiv {
                .imageDummyRosterProfiles {
                    width: 3rem;
                    height: 3rem;
                    background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat
                        padding-box;
                    box-shadow: 1px 2px 3px #0000001a;
                    border-radius: 50rem;
                    margin-top: 0rem;
                    text-align: center;
                    margin-left: 0rem;
                    opacity: 0.8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .rosterProfileImageText {
                        padding: 0.8rem 0.8rem 0.8rem 0.8rem !important;
                        color: white;
                        margin: 0px !important;
                        text-transform: uppercase;
                        cursor: default;
                    }
                }
            }
            .profile-img {
                width: 3rem !important;
                height: 3rem !important;
                border-radius: 50% !important;
            }
        }
        .studentProfileImageDiv {
            width: 4rem;
            float: left;
        }
        .details {
            .name {
                font: normal normal 600 1rem/1.375rem Open Sans;
                color: #222222;
            }
            .mail {
                font: normal normal normal 0.875rem/1.188rem Open Sans;
                color: #777777;
            }
        }
    }
    .action {
        margin: auto;
        white-space: nowrap;
        text-align: right;
        .color-btn {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            .btn-gray {
                background: #777777 0% 0% no-repeat padding-box !important;
            }
            .btn-red {
                background: #ff4444 0% 0% no-repeat padding-box !important;
            }
            .btn-green {
                background: #23bf1d 0% 0% no-repeat padding-box !important;
            }
            .btn-yellow {
                background: #fab700 0% 0% no-repeat padding-box !important;
            }
            .btn-blue {
                background: #386cb5 0% 0% no-repeat padding-box !important;
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .assessmentMainContainer .addMainDiv {
        height: 125px !important;
        .profile {
            display: block;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .action {
            display: contents;
        }
    }
}
@media only screen and (min-width: 1279px) and (max-width: 1360px) {
    .assessmentMainContainer .addMainDiv {
        .action {
            padding-left: 0px;
        }
    }
}
.FAaddassesmentmail {
    max-width: 100% !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (max-width: 1260px) {
        max-width: 95% !important;
    }
}
