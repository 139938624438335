.classAttendanceContainer {
    background: #f0f0f0;
    height: 100%;
    // overflow-x: hidden;
    padding: 0rem 2.5rem 1rem 2.5rem;
    position : sticky;
    top : 0;
    .noneStudents {
        color: #aaaaaa;
        font: normal normal 600 20px/14px Open Sans;
        line-height: inherit;
        padding-top: 240px;
    }

    .ClassAttendanceMainDiv {
        width: 100%;
        height: 80px;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        border-radius: 0px 0px 8px 8px;

        .classAttendaceDetailsMain {
            display: flex;
            align-items: center;
            cursor: pointer;

            .attClassBackBtnDiv {
                display: flex;
                background: #eeeeee;
                cursor: pointer;
            }

            .attClassBackIcon {
                font-size: 1.3rem;
                padding-top: 3px;
            }

            .attClassBactText {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }

        }

        .buttonMarginTop {
            display: flex;
            align-items: center;
            width: 370px;
            justify-content: end;

            .editAttBtn {
                width: 5.3rem !important;
                height: 2.5rem !important;
                border-radius: 8px !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;
            }

            .cancleBtn {
                width: 6.25rem !important;
                height: 2.5rem !important;
                border-radius: 0.5rem !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5, #669fed) 0 0 no-repeat padding-box;
                box-shadow: 0 1px 4px rgba(34, 34, 34, .10196078431372549) !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: .6rem !important;
                margin: 0.1rem 0.3rem;
                cursor: pointer;

                .cancleBtnText {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                    font-weight: 600;
                }
            }

            .saveBtnDiv {
                width: 6.25rem !important;
                height: 2.5rem !important;
                border-radius: 0.5rem !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5, #669fed) 0 0 no-repeat padding-box;
                box-shadow: 0 1px 4px rgba(34, 34, 34, .10196078431372549) !important;
                display: inline-flex !important;
                justify-content: space-around !important;
                font-size: .6rem !important;
                margin: 0.1rem 0.3rem;
                cursor: pointer;

                .saveBtnText {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                    font-weight: 600;
                }
            }

            .reportBtnDiv {
                width: 110px !important;
                height: 40px !important;
                border-radius: 0.5rem !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;

                .reportIcon {
                    width: 0.9rem;
                    margin-top: 0.4rem;
                }

                .reportText {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                    font-weight: 600;
                }
            }

            .viewAttDiv {
                width: 10rem !important;
                height: 2.5rem !important;
                border-radius: 0.5rem !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;
            }

            .pencilAttIcon {
                width: 1rem;
                margin-top: 0.2rem;
            }

            .viewAttIcon {
                width: 0.9rem;
                margin-top: 0.4rem;
            }

            .viewAttText {
                color: white;
                font-size: 0.9rem;
                margin-left: 0.5rem;
                font-weight: 600;
            }

            .displayFlexInSeletAtt {
                padding-right: 10px;
                display: flex;

                .undoSelect {
                    width: 2.5rem;
                    height: 2.5rem;
                    background-color: #ea3737;
                    border-radius: 10rem;
                    opacity: 1;
                    margin-right: 1rem;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    .btn-text {
                        font-size: 14px;
                        color: white;
                        font-weight: 600;
                        margin-top: 15px;
                        margin-left: 5px;
                    }
                }

                .confirmSlectedDiv {
                    width: 6.25rem;
                    height: 2.5rem;
                    background-color: #ea3737;
                    border-radius: 0.5rem;
                    opacity: 1;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    .confirmIcon {
                        color: white;
                        font-size: 1.4rem;
                        margin-bottom: 5px;
                    }

                    .btn-text {
                        font-size: 14px;
                        color: white;
                        font-weight: 600;
                        margin-top: 15px;
                    }
                }



            }
        }

        .attClassDetailTextDiv2 {
            margin-left: 180px;
            padding: 0.4rem 0rem 0rem 0.3rem;

            .attClassName {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
                color: #386cb5;
            }

            .displayFlexClassRoster {
                display: flex;
                cursor: pointer;
                width: max-content;

                .calIcon {
                    align-self: center;
                    cursor: default;
                    cursor: not-allowed;
                    margin-bottom: 9px;
                }

                .classForIcon {
                    color: #487FCC;
                    margin-bottom: 15px;
                    margin-left: -8px;
                }

                .attClassGrade {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 5px 0px 0px 0px;
                    cursor: pointer;
                }

                .headingForEdited {
                    display: inline-flex;
                    flex-wrap: wrap;
                    cursor: default;
                    align-items: center;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                    }

                    .head {
                        color: #386cb5;
                        text-transform: capitalize;
                        font: normal normal 600 14px/14px Open Sans;
                        margin: 0px;
                    }
                }

                .attClassGrades {
                    color: #888888;
                    margin-top: 1px;
                    margin-left: 1px;
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: left;
                    cursor: default;
                }
            }
        }

        .attClassDetailTextDiv {
            margin-left: 1rem;
            border-left: 1px solid #eeeeee;
            padding: 0.3rem 0rem 0rem 1rem;

            .attClassName {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
                color: #386cb5;
            }

            .displayFlexClassRoster {
                display: flex;
                width: max-content;
                padding-top: 9px;

                .calIcon {
                    align-self: center;
                    cursor: default;
                    cursor: not-allowed;
                    margin-bottom: 9px;
                }

                .attAcademicYearText {
                    color: #487fcc;
                    // margin-bottom: 15px;
                    margin-right: 10px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    cursor: pointer;
                }
                .classForIcon {
                    color: #487FCC;
                    margin-bottom: 15px;
                    margin-left: -8px;
                }

                .attText {
                    color: #222;
                    font-size: 1.25rem;
                    font-weight: 600;
                    text-align: left;
                    cursor: default;
                    margin-bottom: 5px;
                    position: relative;
                    bottom: 4px;
                }

                .headingForEdited {
                    display: inline-flex;
                    flex-wrap: wrap;
                    cursor: default;
                    align-items: center;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                    }

                    .head {
                        color: #386cb5;
                        text-transform: capitalize;
                        font: normal normal 600 14px/14px Open Sans;
                        margin: 0px;
                    }
                }

                .attClassGrades {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 1px 0px 0px 5px;
                    cursor: default;
                    font-weight: 600;
                }
            }
        }
    }

    .attClassTextDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        align-self: center;

        .classNameTitle {
            margin: 0px;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
        }

        .classNameHead {
            font-size: 14px;
            color: #386cb5;
            text-transform: capitalize;
            font: normal normal 600 14px/14px Open Sans;
            margin: 0px;
            margin-left: 2px;
        }

        .dateTitle {
            color: #888888;
            margin: 0rem 0.5rem 0rem 0rem;
            font-size: 0.8rem;
            text-align: left;
            padding: 1px 0px 0px 5px;
            cursor: default;
            font-weight: 600;
        }
    }

    .viewAttTitleDiv {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 0px 0px 8px 8px;

        .classAttendaceDetailsMain {
            display: flex;
            flex: 0;
            align-items: "center";
            cursor: pointer;

            .attClassBackBtnDiv {
                display: flex;
                background: #eeeeee;
                cursor: pointer;
            }

            .attClassBackIcon {
                font-size: 1.3rem;
                padding-top: 3px;
            }

            .attClassBactText {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }
        }

        .attClassDetailTextDiv {
            margin-left: 1rem;
            flex: 0.6;
            border-left: 1px solid #eeeeee;
            padding: 0.3rem 0rem 0rem 1rem;

            .firstDetailsDiv {
                display: grid;
            }

            .headingTitles {
                display: inline-flex;
                flex-wrap: wrap;
                padding-bottom: 10px;

                .attTitleName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    display: flex;
                    padding-right: 5px;
                    font: normal normal 600 14px/14px Open Sans;
                }

                .head {
                    color: #386cb5;
                    text-transform: capitalize;
                    padding-right: 5px;
                    font: normal normal 600 14px/14px Open Sans;
                    margin: 0px;
                }
            }

            .displayFlexClassRoster {
                display: flex;
                cursor: pointer;

                .calIcon {
                    align-self: center;
                    cursor: default;
                    cursor: not-allowed;
                    margin-bottom: 9px;
                }

                .attClassGrade {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 5px 0px 0px 0px;
                    cursor: pointer;
                }
            }
        }

        .attClassDetailTextDivTwo {
            margin-left: 1rem;
            flex: 0.4 1;
            padding: 0.3rem 0rem 0rem 1rem;

            .firstDetailsDiv {
                display: grid;
            }

            .headingTitles {
                display: inline-flex;
                flex-wrap: wrap;
                padding-bottom: 10px;
                width: max-content;
                .attTitleName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    display: flex;
                    padding-right: 5px;
                    font: normal normal 600 14px/14px Open Sans;
                }

                .head {
                    color: #386cb5;
                    text-transform: capitalize;
                    padding-right: 5px;
                    font: normal normal 600 14px/14px Open Sans;
                    margin: 0px;
                }
            }

            .displayFlexClassRoster {
                display: flex;
                cursor: pointer;

                .calIcon {
                    align-self: center;
                    cursor: default;
                    cursor: not-allowed;
                    margin-bottom: 9px;
                }

                .attClassGrade {
                    color: #888888;
                    margin: 0rem 0.5rem 0rem 0rem;
                    font-size: 0.8rem;
                    text-align: left;
                    padding: 5px 0px 0px 0px;
                    cursor: pointer;
                }
            }
        }

        .classOptionGet {
            width: 6.875rem !important;
            height: 2.5rem !important;
            border-radius: 0.5rem !important;
            padding: 0.6rem !important;
            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 4px #2222221a !important;
            display: flex !important;
            justify-content: space-evenly !important;
            align-items: center;
            font-size: 0.6rem !important;
            margin: 0.1rem 0.3rem 0.1rem 0.3rem;
            cursor: pointer;
        }
    }
.paddingLeftRight3 {
    padding: 0 3px;
}
    .classAttParentContainer {
        display: flex;

        .classAttCardDiv {
            width: 75%;
            height: 5.6rem;
            margin: 1rem 0rem 0rem 0rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0rem 0.2rem 0.5rem #0000001a;
            border-radius: 0.5rem;
            opacity: 1;
            padding: 0.1rem 0.3rem 0.1rem 0.3rem;

            /* --------ClassAttCard-------- */

            /* ---------Class Att Info Cards------------ */
            .classAttDetailsCard {
                width: 100%;
                height: 4.8rem;
                display: inline-flex;
                padding: 0.4rem 0rem 0rem 0rem;
                position: relative;

                .classAttProfileImageDiv {
                    width: 4rem;
                    padding: 5px 0px 5px 15px;

                    .studentAttProfileImage {
                        width: 3.75rem;
                        height: 3.75rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 1px 2px 3px #0000001a;
                        border-radius: 50rem;
                    }

                    .DummyClassAttProfileDiv {
                        width: 4rem;
                        height: 4rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 1px 2px 3px #0000001a;
                        border-radius: 50rem;
                        text-align: center;
                    }

                    .imageDummyDivProfileAtt {
                        width: 4rem;
                        height: 4rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 1px 2px 3px #0000001a;
                        border-radius: 50rem;
                        text-align: center;
                        opacity: 0.8;

                        .profileImageAttDummyText {
                            color: white;
                            padding-top: 30%;
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                    }

                    .profileImageDummyAttText {
                        color: white;
                        padding-top: 30%;
                        text-transform: uppercase;
                    }

                    .profileClassAttDummyText {
                        color: white;
                        padding-top: 30%;
                        text-transform: uppercase;
                    }
                }

                .classAttTextInfoDiv {
                    flex: 1;
                    text-align: left;
                    padding: 12px 0px 0px 24px;
                    min-width: 235px;

                    .classAttName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        color: #222222;
                    }

                    .classAttEmail {
                        margin: 0px;
                        font-size: 0.9rem;
                        color: #888888;
                    }
                }

                .emojiCommentDiv {
                    padding: 22px 7px 0px 0px;
                    cursor: pointer;

                    .commentDiv {
                        .commentData-style {
                            .attComment-img {
                                width: 30px;
                                height: 20px;
                            }
                        }
                    }
                }

                .emojiCommentsDiv {
                    padding: 12px 7px 0px 0px;
                    cursor: default;

                    .commentDiv {
                        .commentData-style {
                            .attComment-img {
                                width: 30px;
                                height: 20px;
                                margin-bottom: 10px;
                            }

                            .commentsDataRange {
                                font: normal normal 600 12px/14px Open Sans;
                                color: #386cb5;
                            }
                        }
                    }
                }

                .emojiDiv {
                    flex: 0.1;
                    display: inline-flex;
                    align-items: center;
                    // padding-top: 15px;

                    .emojiIcons {
                        height: 60px;
                        cursor: pointer;
                        width: 70px;
                    }

                    .emojiIconsFair {
                        height: 60px;
                        cursor: pointer;
                        width: 70px;
                        padding-right: 5px;
                    }

                    .vertical-line {
                        display: inline-block;
                        border-left: 2px solid #d5cfcf;
                        height: 45px;
                        padding-right: 5px;
                        margin-bottom: 10px;
                    }
                }

                .emojiRangeDiv {
                    flex: 0.1;
                    display: inline-flex;
                    align-items: center;
                    padding-top: 0px;

                    .emojiIcons {
                        height: 60px;
                        cursor: pointer;
                        width: 70px;
                    }

                    .emojiIconsFair {
                        height: 60px;
                        cursor: pointer;
                        width: 70px;
                        padding-right: 5px;
                    }

                    .vertical-line {
                        display: inline-block;
                        border-left: 2px solid #d5cfcf;
                        height: 45px;
                        padding-right: 5px;
                        margin-bottom: 10px;
                    }

                    .digitsRangeHappy {
                        font: normal normal 600 13px/14px Open Sans;
                        color: rgb(60, 255, 0);
                        padding-top: 5px;
                    }

                    .digitsRangeFair {
                        font: normal normal 600 13px/14px Open Sans;
                        color: #fde432;
                        padding-top: 5px;
                    }

                    .digitsRangeSad {
                        font: normal normal 600 13px/14px Open Sans;
                        color: #f7830b;
                        padding-top: 5px;
                    }

                    .digitsRangeAbsent {
                        font: normal normal 600 13px/14px Open Sans;
                        color: red;
                        padding-top: 5px;
                    }
                }

                .showViewAttEmoji {
                    cursor: default !important;
                }
            }
        }

        .showParticularComtBox {
            width: 24%;
            height: 5.6rem !important;
            overflow-y: scroll;
            overflow-x: hidden;
            height: auto;
            margin: 1rem 0rem 0rem 0rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0rem 0.2rem 0.5rem #0000001a;
            border-radius: 0.5rem;
            opacity: 1;
            margin-left: 1%;
            word-wrap: break-word;
            .showPartiCmtText {
                font: normal normal 600 13px/14px Open Sans;
                text-align: left;
                margin-left: 10px;
                margin-top: 10px;
                height: 5.6rem !important;
            }

            .showNonePartiCmtText {
                color: #777777;
                font: normal normal 600 13px/14px Open Sans;
                text-align: left;
                margin-left: 10px;
                margin-top: 10px;
            }
        }

        .showParticularEditComtBox {
            width: 24%;
            height: 5rem;
            margin: 1rem 0rem 0rem 0rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0rem 0.2rem 0.5rem #0000001a;
            border-radius: 0.5rem;
            opacity: 1;
            margin-left: 1%;

            .inputBox {
                width: 100%;
                height: 5.6rem;
                background: #f4f6fa 0% 0% no-repeat padding-box;
                border: 0.75px solid #cccccc;
                border-radius: 5px;
                float: left;
                margin-left: 0px;
                color: #685f5f;
                font: normal normal 600 13px/14px Open Sans;
            }

            .showPartiCmtText {
                font: normal normal 600 13px/14px Open Sans;
            }

            .showNonePartiCmtText {
                color: #777777;
                font: normal normal 600 13px/14px Open Sans;
            }
        }

        .showAttScoreBox {
            min-height: 5.9rem;
            margin: 1rem 0rem 0.8rem 0.8rem;
            border-radius: 0.5rem;
            padding: 10px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #cccccc;
            min-width: 120px;
            max-width: 120px;

            .showPartiCmtText {
                font: normal normal 600 13px/14px Open Sans;
            }

            .showNonePartiCmtText {
                color: #aaaaaa;
                font: normal normal 600 13px/14px Open Sans;
            }

            .showScoreText {
                color: #386cb5;
                font: normal normal 600 20px/25px Open Sans;
            }
        }
    }

    .classAttRangeParentContainer {
        display: flex;

        .classAttCardRangeDiv {
            width: 100%;
            height: 5.8rem;
            margin: 1rem 0rem 0.8rem 0rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0rem 0.2rem 0.5rem #0000001a;
            border-radius: 0.5rem;
            opacity: 1;
            padding: 0.1rem 0.3rem 0.1rem 0.3rem;

            /* --------ClassAttCard-------- */

            /* ---------Class Att Info Cards------------ */
            .classAttDetailsCard {
                width: 100%;
                height: 4.8rem;
                display: inline-flex;
                padding: 0.4rem 0rem 0rem 0rem;
                position: relative;

                .classAttProfileImageDiv {
                    width: 4rem;
                    padding: 5px 0px 5px 15px;

                    .studentAttProfileImage {
                        width: 3.75rem;
                        height: 3.75rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 1px 2px 3px #0000001a;
                        border-radius: 50rem;
                    }

                    .DummyClassAttProfileDiv {
                        width: 4rem;
                        height: 4rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 1px 2px 3px #0000001a;
                        border-radius: 50rem;
                        text-align: center;
                    }

                    .imageDummyDivProfileAtt {
                        width: 4rem;
                        height: 4rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 1px 2px 3px #0000001a;
                        border-radius: 50rem;
                        text-align: center;
                        opacity: 0.8;

                        .profileImageAttDummyText {
                            color: white;
                            padding-top: 30%;
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                    }

                    .profileImageDummyAttText {
                        color: white;
                        padding-top: 30%;
                        text-transform: uppercase;
                    }

                    .profileClassAttDummyText {
                        color: white;
                        padding-top: 30%;
                        text-transform: uppercase;
                    }
                }

                .classAttTextInfoDiv {
                    flex: 1;
                    text-align: left;
                    padding: 12px 0px 0px 24px;
                    min-width: 235px;

                    .classAttName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        color: #222222;
                    }

                    .classAttEmail {
                        margin: 0px;
                        font-size: 0.9rem;
                        color: #888888;
                    }
                }

                .emojiCommentDiv {
                    padding: 22px 7px 0px 0px;
                    cursor: pointer;

                    .commentDiv {
                        .commentData-style {
                            .attComment-img {
                                width: 30px;
                                height: 20px;
                            }
                        }
                    }
                }

                .emojiCommentsDiv {
                    padding: 12px 7px 0px 0px;
                    cursor: default;

                    .commentDiv {
                        .commentData-style {
                            .attComment-img {
                                width: 30px;
                                height: 20px;
                                margin-bottom: 10px;
                            }

                            .commentsDataRange {
                                font: normal normal 600 12px/14px Open Sans;
                                color: #386cb5;
                            }
                        }
                    }
                }

                .emojiDiv {
                    flex: 0.1;
                    display: inline-flex;
                    align-items: center;
                    padding-top: 15px;

                    .emojiIcons {
                        height: 60px;
                        cursor: pointer;
                        width: 70px;
                    }

                    .emojiIconsFair {
                        height: 60px;
                        cursor: pointer;
                        width: 70px;
                        padding-right: 5px;
                    }

                    .vertical-line {
                        display: inline-block;
                        border-left: 2px solid #d5cfcf;
                        height: 45px;
                        padding-right: 5px;
                        margin-bottom: 10px;
                    }
                }

                .emojiRangeDiv {
                    flex: 0.1;
                    display: inline-flex;
                    align-items: center;
                    padding-top: 0px;

                    .emojiIcons {
                        height: 60px;
                        cursor: pointer;
                        width: 70px;
                    }

                    .emojiIconsFair {
                        height: 60px;
                        cursor: pointer;
                        width: 70px;
                        padding-right: 5px;
                    }

                    .vertical-line {
                        display: inline-block;
                        border-left: 2px solid #d5cfcf;
                        height: 45px;
                        padding-right: 5px;
                        margin-bottom: 10px;
                    }

                    .digitsRangeHappy {
                        font: normal normal 600 13px/14px Open Sans;
                        color: rgb(60, 255, 0);
                        padding-top: 5px;
                    }

                    .digitsRangeFair {
                        font: normal normal 600 13px/14px Open Sans;
                        color: #fde432;
                        padding-top: 5px;
                    }

                    .digitsRangeSad {
                        font: normal normal 600 13px/14px Open Sans;
                        color: #f7830b;
                        padding-top: 5px;
                    }

                    .digitsRangeAbsent {
                        font: normal normal 600 13px/14px Open Sans;
                        color: red;
                        padding-top: 5px;
                    }
                }

                .showViewAttEmoji {
                    cursor: default !important;
                }
            }
        }

        .showParticularComtBox {
            width: 24%;
            // height: 5.6rem;
            height: auto;
            margin: 1rem 0rem 0rem 0rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0rem 0.2rem 0.5rem #0000001a;
            border-radius: 0.5rem;
            opacity: 1;
            margin-left: 1%;
word-wrap: break-word;
            .showPartiCmtText {
                font: normal normal 600 13px/14px Open Sans;
                text-align: left;
                margin-left: 10px;
                margin-top: 10px;
            }

            .showNonePartiCmtText {
                color: #777777;
                font: normal normal 600 13px/14px Open Sans;
            }
        }

        .showAttScoreBox {
            min-height: 5.9rem;
            margin: 1rem 0rem 0.8rem 0.8rem;
            border-radius: 0.5rem;
            padding: 10px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #cccccc;
            min-width: 120px;
            max-width: 120px;
            display: flex;
            justify-content: center;
            align-items: center;

            .showPartiCmtText {
                font: normal normal 600 13px/14px Open Sans;
            }

            .showNonePartiCmtText {
                color: #aaaaaa;
                font: normal normal 600 13px/14px Open Sans;
            }

            .showScoreText {
                color: #386cb5;
                font: normal normal 600 20px/25px Open Sans;
            }
        }
    }

    .dropdown-main-container {
        // font-family: sans-serif;
        // text-align: center;
        // width: 50px;
        // height: 50px;
        // position: relative;
        // top: 8px;
        // left: 50px;
        font-family: sans-serif;
        text-align: center;
        position: relative;
        flex: 0.6;
        top: 0px !important;
        left: 0px !important;

        /**
       * Main wrapper
       */
       .iconDiv {
            position: absolute;
            left: 9px;
            z-index: 2 !important;
            top: 5px;
            cursor: pointer;

            .searchFiltersIcon {
                cursor: pointer;
                color: #aaaaaa;
                margin-right: 10px;
            }
        }

       .select-search {
        width: 240px !important;
        position: relative;
        font-family: "Nunito Sans", sans-serif;
        box-sizing: border-box;
        bottom: 5px !important;
        cursor: pointer;
    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {
        box-sizing: inherit;
    }

    .select-search__value {
        position: relative;
    }

    .select-search__value::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
        cursor: pointer;
    }

    .select-search__input {
        margin-top: 5px;
        display: block;
        height: 40px;
        width: 240px;
        padding: 0 40px 0 30px;
        background: #fff;
        border: 1px solid #dddddd;
        box-shadow: 0px 0px 3px #88c3fae3;
        border-radius: 8px;
        outline: none !important;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        color: rgba(56, 108, 181, 1);
        text-align: left;
        text-overflow: ellipsis;
        line-height: 36px;
        -webkit-appearance: none;
        opacity: 1;
    }

    .select-search__input::placeholder {
        font: italic normal normal 13px/18px Open Sans;
        letter-spacing: 0px;
        color: #c6b6b6 !important;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    .select-search__input:not([readonly]):focus {
        cursor: initial;
    }

    .select-search__select {
        max-height: 150px !important;
        box-shadow: 10px 10px 12px #ec090929;
        border: 1px solid #dddddd;
    }

    .select-search__options {
        list-style: none;
        padding: 0px 5px;
        background: #fff;
        cursor: pointer;
    }

    .select-search__row {
        border-bottom: 1px solid #dddddd;
        border-width: 0.15px;
    }

    .select-search__row:last-child {
        border-bottom: none;
    }

    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 36px;
        width: 100%;
        background: #fff;
        border: none;
        outline: none;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        color: #fff;
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        color: #fff;
    }

    .select-search__group-header {
        text-transform: uppercase;
        background: #fff;
    }

    .select-search.is-disabled {
        opacity: 0.5;
    }

    .select-search.is-disabled .select-search__input {
        font-weight: 600;
    }

    .select-search.is-loading .select-search__value::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
        letter-spacing: 0px;
        font-weight: 600;
    }

    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
        transform: rotate(45deg);
        border-right: 3px solid #000;
        border-bottom: 3px solid #000;
        pointer-events: none;
    }

    .select-search.has-focus .select-search__value::after {
        display: none;
    }

    .select-search.has-focus .select-search__input:hover {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .select-search.has-focus .select-search__input {
        border-bottom: none;
        box-shadow: 0px 0px 3px #00000029;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-color: none;
        background: #f4f6fa 0% 0% no-repeat padding-box;
    }

    .select-search--multiple .select-search__input {
        cursor: initial;
    }

    .select-search--multiple .select-search__input {
        border-radius: 3px 3px 0 0;
    }

    .select-search--multiple:not(.select-search--search) .select-search__input {
        cursor: default;
    }

    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: 40px !important;
        right: 0;
        left: 0;
        border-radius: 8px;
        overflow: auto;
        max-height: 360px;
        background: #fff;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        box-shadow: none;
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: auto;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
        background: #fff;
    }

    .select-search__not-found {
        height: auto;
        padding: 8px;
        text-align: center;
        color: rgb(244, 245, 247);
    }

    }
}

.backBttn {
    background: transparent linear-gradient(270deg, #4479c4 0%, #669fed 100%) 0% 0% no-repeat padding-box;
}

.eventNone {
    pointer-events: none;
}

.attendance-popContainer {
    left: 60px !important;
    top: -62px !important;
    min-height: 78px !important;
    border-radius: 0 !important;
    padding: 5px !important;
    box-shadow: 0px 0px 15px #22222233;
    border: white !important;
    width: 240px !important;

    .popover-inner {
        box-shadow: 0px 0px 15px #22222233;
        border: white !important;
        width: 245px !important;
        background-color: white;
        border-radius: 5px;

        .popUpContainer {
            box-shadow: #0d0d0d1a 1px 0.35rem 3.475rem 14px;

            .bs-popover-auto[x-placement^="left"]>.arrow::after,
            .bs-popover-left>.arrow::after {
                border-left-color: #fff !important;
            }

            .titleCloseDiv {
                display: flex;

                .addTitle {
                    flex: 1 1;
                    padding-top: 3px;
                    margin-bottom: 0px !important;
                    font: normal normal 600 14px/17px Open Sans;
                    color: #0f0e0e;
                }

                .closeIcon {
                    float: right;
                    cursor: pointer;
                }
            }

            .textBoxBtnDiv {
                .input-box {
                    width: 222px;
                    height: 160px;
                    background: #f4f6fa 0% 0% no-repeat padding-box;
                    border: 0.75px solid #cccccc;
                    border-radius: 5px;
                    float: left;
                    margin-top: 15px;
                    margin-left: 0px;
                    color: #685f5f;
                    font: normal normal 600 13px/14px Open Sans;
                }

                .saveBtn {
                    width: 70px;
                    height: 28px;
                    background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
                    border-radius: 14px;
                    margin-top: 15px;
                    margin-left: 74px;
                    font: normal normal 600 12px/14px Open Sans;
                    color: #ffffff;
                }

                .saveBtn:active,
                .saveBtn:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
}

.att-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.att-modal-warning .modal-content {
    border-radius: 8px;
    width: 302px;
    margin: auto;
    background-color: #fff !important;
}

.att-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}

.att-modal-warning .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;

    .filterMainDiv {
        display: flex;
        flex-wrap: wrap;
        float: right;

        .listAllClassAttFilter {
            width: 5.28rem;
            height: 1.35rem;
            border: 0.5px solid #aaaaaa;
            cursor: pointer;
            border-radius: 16px;
            margin: 0.15rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .listAllClassFilterAttText {
                font-size: 0.8rem;
                color: #aaaaaa;
                padding: 1rem 0rem 0rem 0.1rem;
            }
        }

        .myClassFilterAttTodayDiv {
            width: 5.28rem;
            height: 1.35rem;
            border: 0.5px solid #aaaaaa;
            border-radius: 16px;
            margin: 0.15rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            .listAllClassFilterAttText {
                font-size: 0.8rem;
                color: #aaaaaa;
                padding: 1rem 0rem 0rem 0.1rem;
            }
        }

        .myClassFilterAttDiv {
            width: 5.28rem;
            height: 1.35rem;
            border: 0.5px solid #aaaaaa;
            border-radius: 16px;
            margin: 0.15rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            .listAllClassFilterAttText {
                font-size: 0.8rem;
                color: #aaaaaa;
                padding: 1rem 0rem 0rem 0.1rem;
            }
        }

        .selectedDivClr {
            border: 0.5px solid #639be7 !important;
        }

        .selectedTextClr {
            color: #639be7 !important;
        }
    }
}

.att-modal-warning .dateBlock {
    padding: 10px 20px 10px 20px;

    .dateSubBlock {
        border: 1px solid #dddddd;
        background: #f4f6fa;
        border-radius: 5px;
        padding: 12px;
        display: flex;
        justify-content: space-between;

        .react-datepicker-wrapper {
            cursor: pointer;
        }

        .dayDateTextDiv {
            width: 250px;

            .dayDateText {
                margin-bottom: 0px !important;
                font: normal normal 600 16px/24px Open Sans;
            }
        }

        .displayFlexAttClassRoster {
            display: flex;
            cursor: pointer;
            padding-bottom: 2px;
            padding-right: 5px;

            .calIcon {
                align-self: center;
                cursor: default;
                cursor: not-allowed;
            }

            .attClassGrade {
                color: #888888;
                margin: 0rem 0.5rem 0rem 0rem;
                font-size: 0.8rem;
                text-align: left;
                padding: 3px 0px 0px 3px;
                cursor: pointer;
            }
        }

        .calMargin {
            margin: 4px 0px 4px 0px;
        }
    }

    .dateSubBlocks {
        border: 1px solid #dddddd;
        background: #f4f6fa;
        border-radius: 5px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .react-datepicker-wrapper {
            cursor: pointer;
        }

        .dayDateTextDiv {
            width: 250px;

            .dayDateText {
                margin-bottom: 0px !important;
                font: normal normal 600 16px/24px Open Sans;
            }

        }

        .calIcon {
            align-self: center;
            cursor: not-allowed;
        }

        .displayFlexAttClassRoster {
            display: flex;
            cursor: pointer;
            padding-bottom: 2px;
            padding-right: 5px;

            .calIcon {
                align-self: center;
                cursor: not-allowed;
            }

            .attClassGrade {
                color: #888888;
                margin: 0rem 0.5rem 0rem 0rem;
                font-size: 0.8rem;
                text-align: left;
                padding: 3px 0px 0px 3px;
                cursor: pointer;
            }
        }

        .calMargin {
            margin: 4px 0px 4px 0px;
        }
    }
}

.att-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;

}

.att-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}

.att-modal-warning .getReport-btn {
    width: 120px;
    height: 38px;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 14px/19px Open Sans;
}

.att-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal 600 14px/19px Open Sans;
    color: #080808 !important;
    width: 101px;
    height: 38px;
}

.viewPdf-modal-warning .modal-content {
    width: 350px;
    height: 270px;
    border-radius: 8px;
    margin: auto;
    background-color: #fff !important;
}

.viewPdf-modal-warning .modal-content .modal-header {
    border: none;
    display: block;
}

.viewPdf-modal-warning .modal-content .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
    border: none;
    align-items: center;
    display: block;
}

.viewPdf-modal-warning .modal-content .modal-body {
    display: flex;
    justify-content: center;
    padding: 0px !important;
}

.viewPdf-modal-warning .modal-content .modal-body .pdfBlock {
    width: 100px;
    height: 130px;
    border: 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5px;
    padding-top: 10px;
    cursor: pointer;
}

.viewPdf-modal-warning .modal-content .modal-body .pdfBlock .reportIcon {
    align-self: center;
    margin-top: 10px;
    margin-left: 10px;

}
.viewPdf-modal-warning .modal-content .modal-body .pdfBlock .reportName {
    text-align: center;
    padding-top: 10px;
    font: normal normal 600 15px/24px Open Sans;

}

.viewPdf-modal-warning .modal-content .modal-body .csvBlock {
    width: 100px;
    height: 130px;
    border: 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 5px;
    padding-top: 10px;
    cursor: pointer;

}

.viewPdf-modal-warning .modal-content .modal-body .csvBlock .reportIcon {
    align-self: center;
    margin-top: 10px;
    margin-left: 10px;
}

.viewPdf-modal-warning .modal-content .modal-body .csvBlock .reportName {
    text-align: center;
    padding-top: 10px;
    font: normal normal 600 15px/24px Open Sans;
}

.viewPdf-modal-warning .modal-content .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-top: 0px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}
.viewPdf-modal-warning .modal-content .modal-footer .getReportBtn {
    width: 120px;
    height: 50px;
    align-self: center;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 14px/19px Open Sans;
}

.dropdownDiv {
    display: flex;
    flex-direction: row;
    width: 480px;
    justify-content: right;
    padding-left: 0px;
    ;

    .dropdown-main-container2 {
        // font-family: sans-serif;
        // text-align: center;
        // width: 50px;
        // height: 50px;
        // position: relative;
        // top: 8px;
        // left: 50px;
        font-family: sans-serif;
        text-align: center;
        position: relative;
        flex: 0.6;
        top: 0px !important;
        left: 0px !important;

        /**
       * Main wrapper
       */
       .iconDiv {
            position: absolute;
            left: 9px;
            z-index: 2 !important;
            top: 14px;
            cursor: pointer;

            .searchFiltersIcon {
                cursor: pointer;
                color: #aaaaaa;
                margin-right: 10px;
            }
        }

       .select-search {
        width: 240px !important;
        position: relative;
        font-family: "Nunito Sans", sans-serif;
        box-sizing: border-box;
        bottom: 5px !important;
        cursor: pointer;
    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {
        box-sizing: inherit;
    }

    .select-search__value {
        position: relative;
    }

    .select-search__value::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
        cursor: pointer;
    }

    .select-search__input {
        margin-top: 10px;
        display: block;
        height: 40px;
        width: 240px;
        padding: 0 40px 0 30px;
        background: #fff;
        border: 1px solid #dddddd;
        box-shadow: 0px 0px 3px #88c3fae3;
        border-radius: 8px;
        outline: none !important;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        color: rgba(56, 108, 181, 1);
        text-align: left;
        text-overflow: ellipsis;
        line-height: 36px;
        -webkit-appearance: none;
        opacity: 1;
    }

    .select-search__input::placeholder {
        font: italic normal normal 13px/18px Open Sans;
        letter-spacing: 0px;
        color: #c6b6b6 !important;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    .select-search__input:not([readonly]):focus {
        cursor: initial;
    }

    .select-search__select {
        max-height: 150px !important;
        box-shadow: 10px 10px 12px #ec090929;
        border: 1px solid #dddddd;
    }

    .select-search__options {
        list-style: none;
        padding: 0px 5px;
        background: #fff;
        cursor: pointer;
    }

    .select-search__row {
        border-bottom: 1px solid #dddddd;
        border-width: 0.15px;
    }

    .select-search__row:last-child {
        border-bottom: none;
    }

    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 36px;
        width: 100%;
        background: #fff;
        border: none;
        outline: none;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        color: #fff;
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        color: #fff;
    }

    .select-search__group-header {
        text-transform: uppercase;
        background: #fff;
    }

    .select-search.is-disabled {
        opacity: 0.5;
    }
    .select-search.is-disabled .select-search__input {
        font-weight: 600;
    }

    .select-search.is-loading .select-search__value::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
        letter-spacing: 0px;
        font-weight: 600;
    }

    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
        transform: rotate(45deg);
        border-right: 3px solid #000;
        border-bottom: 3px solid #000;
        pointer-events: none;
    }

    .select-search.has-focus .select-search__value::after {
        display: none;
    }

    .select-search.has-focus .select-search__input:hover {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .select-search.has-focus .select-search__input {
        border-bottom: none;
        box-shadow: 0px 0px 3px #00000029;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-color: none;
        background: #f4f6fa 0% 0% no-repeat padding-box;
    }

    .select-search--multiple .select-search__input {
        cursor: initial;
    }

    .select-search--multiple .select-search__input {
        border-radius: 3px 3px 0 0;
    }

    .select-search--multiple:not(.select-search--search) .select-search__input {
        cursor: default;
    }

    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: 40px !important;
        right: 0;
        left: 0;
        border-radius: 8px;
        overflow: auto;
        max-height: 360px;
        background: #fff;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        box-shadow: none;
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: auto;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
        background: #fff;
    }

    .select-search__not-found {
        height: auto;
        padding: 8px;
        text-align: center;
        color: rgb(244, 245, 247);
    }

    }
}

.updateDateDiv {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    // margin-left: 670px;
}

.commentCardDiv {
    width: 24% !important;
    height: 5.6rem !important;
    margin: 1rem 0rem 0rem 0rem !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    // box-shadow: 0rem 0.2rem 0.5rem #0000001a !important;
    border-radius: 0.5rem !important;
    opacity: 1 !important;
    margin-left: 1% !important;
}

.commentTextBox {
    width: 100% !important;
    height: 90px !important;
    background: #F4F6FA 0% 0% no-repeat padding-box !important;
    font: normal normal 600 13px/14px Open Sans !important;
}

@media screen and (min-width: 1275px) and (max-width: 1480px) {
    .classAttendanceContainer .ClassAttendanceMainDiv .attClassDetailTextDiv2 {
        margin-left: 10px;
        padding: 0.4rem 0rem 0rem 0.3rem;
    }
}

@media only screen and (min-width: 520px) and (max-width: 890px) {
    .classAttendanceContainer {
        padding: 0rem 0.5rem 1rem 0.5rem;
    }

    .ClassAttendanceMainDiv {
        flex-wrap: wrap;
        justify-content: center;
    }

}

@media only screen and (min-width: 250px) and (max-width: 852px) {
    .classAttendanceContainer {
        padding: 0rem 0.5rem 1rem 0.5rem;

        .classAttCardDiv {
            height: 14.8rem !important;

            .classAttDetailsCard {
                flex-wrap: wrap;

                .emojiCommentDiv {
                    padding-right: 5px;
                }
            }
        }
    }

    .ClassAttendanceMainDiv {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media only screen and (min-width: 250px) and (max-width: 375px) {
    .classAttParentContainer {
        flex-wrap: wrap;
    }

    .classAttendanceContainer {
        padding: 0rem 0.5rem 1rem 0.5rem;

        .classAttCardDiv {
            height: 14rem !important;

            .classAttDetailsCard {
                flex-wrap: wrap;

                .emojiCommentDiv {
                    padding-right: 5px;
                }
            }
        }
    }

    .ClassAttendanceMainDiv {
        flex-wrap: wrap;
        justify-content: center;
    }

    .buttonMarginTop {
        margin-top: 1rem;
    }
}

@media only screen and (min-width: 539px) and (max-width: 837px) {
    .classAttendanceContainer {
        padding: 0rem 0.5rem 1rem 0.5rem;

        .classAttCardRangeDiv {
            height: 10.8rem !important;
            flex-wrap: wrap;

            .classAttDetailsCard {
                flex-wrap: wrap;

                .emojiCommentDiv {
                    padding-right: 5px;
                }
            }
        }
    }
}

@media only screen and (min-width: 406px) and (max-width: 538px) {
    .classAttendanceContainer {
        padding: 0rem 0.5rem 1rem 0.5rem;

        .classAttRangeParentContainer {
            flex-wrap: wrap;

            .classAttCardRangeDiv {
                height: 10.8rem !important;
                flex-wrap: wrap;

                .classAttDetailsCard {
                    flex-wrap: wrap;

                    .emojiCommentDiv {
                        padding-right: 5px;
                    }
                }
            }

            .showAttScoreBox {
                margin: 0.3rem 0rem 0.8rem 0rem;
            }
        }
    }
}

@media only screen and (min-width: 357px) and (max-width: 406px) {
    .classAttendanceContainer {
        padding: 0rem 0.5rem 1rem 0.5rem;

        .classAttRangeParentContainer {
            flex-wrap: wrap;

            .classAttCardRangeDiv {
                height: 16.8rem !important;
                flex-wrap: wrap;

                .classAttDetailsCard {
                    flex-wrap: wrap;

                    .emojiRangeDiv {
                        padding-left: 0px;
                    }

                    .emojiCommentDiv {
                        padding-right: 5px;
                    }
                }
            }

            .showAttScoreBox {
                margin: 0.3rem 0rem 0.8rem 0rem;
            }
        }
    }
}

@media only screen and (min-width: 250px) and (max-width: 357px) {
    .classAttendanceContainer {
        padding: 0rem 0.5rem 1rem 0.5rem;

        .classAttRangeParentContainer {
            flex-wrap: wrap;

            .classAttCardRangeDiv {
                height: 33rem !important;
                flex-wrap: wrap;

                .classAttDetailsCard {
                    flex-wrap: wrap;

                    .emojiCommentDiv {
                        padding-right: 5px;
                    }

                    .emojiRangeDiv {
                        flex-wrap: wrap;
                        padding-left: 100px;

                        .emojiIcons {
                            height: 75px;
                        }

                        .emojiIconsFair {
                            height: 75px;
                        }
                    }
                }

                .emojiCommentsDiv {
                    width: 100%;
                }
            }

            .showAttScoreBox {
                margin: 0.3rem 0rem 0.8rem 0rem;
            }
        }
    }
}
.firstDetailsDivatt {
    width: 224px;
    border-radius: 1px solid gray;
    border-right: 1px solid #eeeeee;
    text-align: right;
    padding-right: 19px;

}


.happyy {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-10)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-80ce4f);
text-align: center;
font: normal normal 600 13px/14px Open Sans;
letter-spacing: 0px;
color: #80CE4F;
opacity: 1;
margin-top: 3px;
}

.neutral {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-10)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-ffd307);
text-align: center;
font: normal normal 600 13px/14px Open Sans;
letter-spacing: 0px;
color: #FFD307;
opacity: 1;
margin-top: 3px;
}

.sad {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-10)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ff9c17);
    text-align: center;
    font: normal normal 600 13px/14px Open Sans;
    letter-spacing: 0px;
    color: #FF9C17;
    opacity: 1;
margin-top: 3px;

}

.none {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-10)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-f05f5f);
text-align: center;
font: normal normal 600 13px/14px Open Sans;
letter-spacing: 0px;
color: #F05F5F;
opacity: 1;
margin-top: 3px;
}

.noDataFound {
    position: relative;
    top: 13rem;
    font-size: larger;
}