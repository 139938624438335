.cropper-wrapper{
    border-radius: 25px;
    .btnDiv{
        margin-top: 15px;
        .cancelBtn{
            text-transform: inherit;
            margin-right: 15px;
            border-radius: 20px;
            width: 90px;
            background-color: red;
            color: white;
            outline: none;
        }
        .saveBtn{
            text-transform: inherit;
            border-radius: 20px;
            width: 90px;
            background-color: green;
            border-radius: 20px;
            margin-left: 15px;
            color: white;
            outline: none;
            border-radius: 20px;
        }
    }
    .zoomIcon{
        color: green;
    }
}