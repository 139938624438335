body {
    background-image: linear-gradient(to bottom, #ecf3fc, #fafafa);
  }
  
  .RosterMainContainer-RD {
    background: #f0f0f0;
    height: 100%;
    padding: 0rem 3rem 0rem 3rem;
  }
  
  .positionRelative-RD {
    position: relative;
    width: 10rem;
  }
  
  .vertical-center-roster {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .noRoster-RD {
    height: 100vh;
  }
  
  /* ----------Dummy Roster Image------- */
  
  .imageDummyRosterProfile-RD {
    width: 3.75rem;
    height: 3.75rem;
    background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001a;
    border-radius: 50rem;
    margin-top: 0rem;
    text-align: center;
    margin-left: 0.75rem;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rosterProfileImageText-RD {
    color: white;
    margin: 0px !important;
    text-transform: uppercase;
    cursor: default;
    // font-weight: 600;
  }
  
  /* ----------Dummy Image Section-------*/
  
  /* ----------RosterYear Div--------- */
  
  .rosterYearMainDiv-RD {
    width: 100%;
    height: 80px;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0px 0px 8px 8px;
  }
  
  .rosterYearBackDiv-RD {
    display: flex;
    width: 5rem;
    height: 3rem;
    cursor: pointer;
    border-right: 2px solid rgba(0, 0, 0, 0.1);
    align-items: center;
  }
  
  .attRosterYearDiv-RD {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: center;
    align-items: baseline;
    padding-top: 6px;
  }
  
  .attClassGrade-RD {
    color: #487fcc;
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    cursor: pointer;
  }
  
  .attClassGrade2-RD {
    color: #222222;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    cursor: default;
  }
  
  .rosterForIcon-RD {
    color: #487fcc;
    position: relative;
    top: 6px;
  }
  
  .RosterYearText-RD {
    margin: 0px;
    float: left;
    width: 160px;
    text-align: left;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
      var(--unnamed-font-size-16) / var(--unnamed-line-spacing-22)
      var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-222222);
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 0.4;
  }
  
  .RosterYearSortNameText-RD {
    margin: 0px;
    font-size: 0.9rem;
    font-weight: 600;
    width: 5rem;
    padding-top: 8px;
    margin-left: 10px;
  }
  
  .addNewRoster-RD {
    width: 120px !important;
    height: 45px !important;
    border-radius: 0.5rem !important;
    padding: 0.6rem !important;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0.1rem 0.2rem 0.1rem 0.2rem;
  }
  
  .importRoster-RD {
    width: 120px !important;
    height: 45px !important;
    border-radius: 0.5rem !important;
    padding: 0.6rem !important;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0.1rem 0.2rem 0.1rem 1rem;
  }
  
  .addNewRosterIcon-RD {
    width: 0.9rem;
    margin-top: 0.1rem;
  }
  
  .btnContainer-RD {
    width: 5rem;
    height: 2.4rem;
    display: flex;
    border: 0.75px solid #dddddd;
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;
    margin: 0px 20px;
  }
  
  .gridViewBtn-RD {
    width: 50% !important;
    height: 100% !important;
    padding: 0.6rem !important;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    cursor: pointer;
  }
  
  .gridViewBtnIcon-RD {
    width: 1.188rem;
    height: 1rem;
  }
  
  .listViewBtn-RD {
    width: 50% !important;
    height: 100% !important;
    padding: 0.6rem !important;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    cursor: pointer;
  }
  
  .listViewBtnIcon-RD {
    width: 1.188rem;
    height: 1rem;
  }
  
  .buttonText-RD {
    color: white;
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }
  
  .LoadButtonText-RD {
    color: white;
    font-size: 0.8rem;
    margin-left: 0.3rem;
    font-weight: 600;
  }
  
  .demoDiv {
    float: right;
    justify-content: end;
  }
  
  /* ----------RosterYear Div--------- */
  
  /* ----------Total student Div--------- */
  .totalStudentMainDiv {
    width: 100%;
    min-width: fit-content;
    background: #ffff;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    flex-flow: row;
  
    .filterSortByDiv {
      display: flex;
      border-right: 2px solid rgba(0, 0, 0, 0.25);
      width: 9.5%;
      align-items: center;
  
      .totalStudentsDiv-RD {
        .RosterYearSortNameText-RD {
          margin: 0px;
          font-size: 0.9rem;
          font-weight: 600;
          width: 5rem;
          padding-top: 8px;
          margin-left: 10px;
        }
      }
    }
  }
  
  .totalStudentDiv-RD {
    position: relative;
    width: 10rem;
  }
  
  .totalStudentText {
    margin: 0px;
    font-size: 0.9rem;
    font-weight: 600;
    width: 10rem;
    margin-top: 0.5rem;
  }
  
  .rosterfilterDiv-RD {
    display: flex;
  }
  
  .rosterfilterDiv2-RD {
    display: flex;
    border-right: 2px solid rgba(0, 0, 0, 0.1);
    padding-right: 15px;
    margin-right: 15px;
    align-items: baseline;
  }
  
  .searchFilter-RD {
    width: 18.75rem;
    height: 2.25rem;
    border: 0.75px solid #dddddd;
    border-radius: 18px;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    margin-top: 2px;
    background: #f4f6fa 0% 0% no-repeat padding-box;
  }
  
  .searchFilterIcon-RD {
    width: 14px;
    height: 14px;
    cursor: pointer;
    color: #386cb5;
    margin-right: 10px;
    margin-top: 2px;
  }
  
  .searchFilterInputBox-RD {
    margin: 0;
    font-style: italic;
    font-size: 12px;
    color: #222222;
    opacity: 2 !important;
    width: 70%;
    height: 100%;
    background: transparent 0% 0% no-repeat padding-box;
  
    .MuiInputBase-input {
      font: inherit;
      font-size: 13px !important;
      color: currentColor;
      width: 100%;
      border: 0;
      height: 0.8876em !important;
      margin: 0;
      display: block;
      padding-top: 9px !important;
      min-width: 0;
      background: none;
      box-sizing: content-box;
      animation-name: mui-auto-fill-cancel;
      letter-spacing: inherit;
      animation-duration: 10ms;
      -webkit-tap-highlight-color: transparent;
    }
  }
  
  .listAll-RD {
    width: 4.375rem;
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .listAllActiveBlueBtn-RD {
    width: 4.375rem;
    height: 2rem;
    background-color: #386bb5 !important;
    border: 0.5px solid #386bb5 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .firstName-RD {
    width: 5.625rem !important;
    height: 2rem !important;
    border: solid 1px #ccc;
    border-radius: 8px !important;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .firstNameBlueActiveBtn-RD {
    width: 5.625rem !important;
    height: 2rem !important;
    background-color: #386bb5 !important;
    border: 0.5px solid #386bb5 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .lastName-RD {
    width: 5.625rem !important;
    height: 2rem !important;
    border: solid 1px #ccc;
    border-radius: 8px !important;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .lastNameBlueActiveBtn-RD {
    width: 5.625rem !important;
    height: 2rem !important;
    background-color: #386bb5 !important;
    border: 0.5px solid #386bb5 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .listAllText-RD {
    font-size: 0.8rem;
    color: #000;
    padding: 1rem 0rem 0rem 0.1rem;
    cursor: pointer !important;
  }
  
  .listAllActiveText-RD {
    font-size: 0.8rem;
    color: #fff;
    padding: 1rem 0rem 0rem 0.1rem;
    cursor: pointer;
  }
  
  .myRosterDiv-RD {
    width: 4.5rem !important;
    height: 2rem !important;
    border: 1px solid #ccc;
    border-radius: 8px !important;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .myRosterDivBlueActiveBtn-RD {
    width: 4.5rem !important;
    height: 2rem;
    background-color: #386bb5 !important;
    border: 0.5px solid #386bb5 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .checkBoxfilter {
    color: #386cb5;
    cursor: pointer;
    font-size: 21px;
    margin-right: 0.2rem;
  }
  
  .unCheckBoxfilter {
    cursor: pointer;
    font-size: 21px;
    margin-right: 0.2rem;
  }
  
  .sharedRosterDiv-RD {
    width: 6.875rem;
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .sharedRosterDivBlueActiveBtn-RD {
    width: 6.875rem;
    height: 2rem;
    background-color: #386bb5 !important;
    border: 0.5px solid #386bb5 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .totalStudentSearchDiv-RD {
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
  }
  
  .totalStudentSearchDiv-RD .searchInput {
    border: none !important;
    width: 100%;
  }
  
  .totalStudentSearchDiv-RD .closeIconSearch-RD {
    width: 0.5rem;
    cursor: pointer;
    margin: 1rem 0.5rem 0rem 0rem;
  }
  
  .totalStudentSearchDiv-RD .searchIconDiv-RD {
    padding: 0rem 0.5rem 0rem 0.5rem !important;
  }
  
  .totalStudentSearchDiv-RD .searchStudentdiv-RD {
    width: 92% !important;
    height: 29px !important;
    margin-top: 0.5rem !important;
    padding-left: 0rem !important;
    background: transparent;
    margin-bottom: 0.5rem !important;
    border: none !important;
    border-radius: 0.3rem !important;
    font-style: italic !important;
    display: inline-flex;
    float: left;
  }
  
  .totalStudentSearchDiv-RD input.middle:focus {
    outline-width: 0 !important;
  }
  
  .totalStudentSearchDiv-RD .searchIcon-RD {
    color: gray !important;
  }
  
  .totalStudentSearchDiv-RD .searchStudent-RD {
    background: transparent !important;
    border: none !important;
    width: 100% !important;
  }
  
  .totalStudentSearchDiv-RD input {
    outline: none;
  }
  
  /* ----------Total student Div--------- */
  
  .panels-div-RD {
    margin-top: 15px;
  
    .addGroupPanel {
      font: normal normal 600 14px/19px Open Sans;
      color: #222222;
      margin: 35px 0 0;
      text-align: -webkit-center;
  
      .mt--28 {
        margin-top: -28px;
        width: 150px;
        background: #f0f0f0;
        cursor: pointer;
      }
  
      hr {
        border-top: 1px solid #aaaaaa;
      }
  
      .addGroupIcon {
        margin-right: 6px;
      }
  
      label {
        cursor: pointer;
      }
    }
  
    .MuiExpansionPanelSummary-content {
      background: #aaaaaa;
      height: 1px;
    }
  
    .MuiExpansionPanelSummary-root .Mui-expanded {
      min-height: 1px;
    }
  
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
  
    .MuiExpansionPanel-root.Mui-expanded {
      margin: 0;
    }
  
    .MuiCollapse-container {
      background: #f0f0f0;
    }
  
    .MuiExpansionPanelSummary-root {
      background-color: #f0f0f0;
      min-height: 48px !important;
    }
  
    .MuiExpansionPanel-root:before {
      display: none;
    }
  
    .confirm-text {
      font: normal normal normal 16px/22px Open Sans;
    }
  
    .MuiTypography-body1-RD {
      width: 100%;
      text-align: -webkit-center;
  
      .title {
        margin-top: -10px;
        background: #f0f0f0;
        width: 150px;
        font: normal normal 600 14px/19px Open Sans;
        color: #222222;
  
        .groupName {
          width: 160px;
          margin-top: -19px;
          padding: 8px;
        }
      }
  
      .w-166 {
        width: 165px;
      }
  
      .action {
        float: right;
        margin-top: -24px;
        background: #f0f0f0;
  
        img {
          margin-left: 12px;
        }
      }
  
      .actionCircle {
        float: right;
        margin-top: -40px;
        background: #f0f0f0;
  
        img {
          margin-left: 0px !important;
        }
      }
    }
  
    .MuiExpansionPanelSummary-expandIcon {
      position: absolute;
      left: 0;
      background: #f0f0f0;
    }
  
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        border-radius: 20px;
        color: white;
        width: 18px;
        height: 18px;
        background: #aaaaaa 0% 0% no-repeat padding-box;
      }
    }
  
    .MuiPaper-root {
      .MuiButtonBase-root {
        .MuiAccordionSummary-expandIcon {
          position: absolute;
          left: 0;
        }
  
        background: #f0f0f0;
  
        .MuiAccordionSummary-content {
          background: #aaaaaa;
          height: 1px;
        }
      }
    }
  
    .MuiAccordion-root:before {
      background-color: #f0f0f0;
    }
  
    .detail-section-RD {
      background: #f0f0f0;
      display: block;
      overflow-y: scroll;
      height: 255px !important;
  
      .row {
        width: 100%;
      }
    }
  
    .StudentProfiles-RD {
      border-radius: 0px 6px 6px 0px;
      padding: 7.5px 7.5px;
  
      .card-body {
        padding: 10px;
        min-height: 90px;
        max-height: 90px;
      }
  
      .closeMediaIcon {
        position: absolute;
        background: red;
        border-radius: 20px;
        right: -6px;
        top: -7px;
        cursor: pointer;
      }
  
      .profile-RD {
        text-align: left;
  
        .withImg-RD {
          width: 3.125rem;
          height: 3.125rem;
          box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
          border-radius: 50rem;
        }
  
        .img-RD {
          width: 3.125rem;
          height: 3.125rem;
          background: transparent
            linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat
            padding-box;
          box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
          border-radius: 50rem;
          float: left;
          margin-right: 1rem;
          display: flex;
          justify-content: center;
          margin: 10px 10px auto;
  
          .profile-img {
            width: 3.125rem !important;
            height: 3.125rem !important;
            border-radius: 50% !important;
          }
        }
  
        .details-RD {
          // width: 115%;
          max-width: 100% !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
  
          [data-title] {
            font-size: 0.7rem;
            cursor: pointer;
            margin-bottom: 0px !important;
            font: normal normal normal 0.875rem/1.188rem Open Sans;
          }
  
          [data-title]:hover::before {
            content: attr(data-title);
            bottom: -40px;
            padding: 5px;
            background: #000;
            color: #fff;
            font-size: 0.7rem;
            word-wrap: break-word;
            width: 220px;
          }
  
          .name-RD {
            font: normal normal 600 1rem/1.375rem Open Sans;
            color: #222222;
            text-overflow: ellipsis !important;
            max-width: 100% !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
  
          .mail-RD {
            font: normal normal normal 0.875rem/1.188rem Open Sans;
            color: #777777;
          }
        }
      }
    }
  
    .StudentProfiles2-RD {
      border-radius: 0px 6px 6px 0px;
      padding: 7.5px 7.5px;
  
      .card-body {
        padding: 10px;
        min-height: 90px;
        max-height: 90px;
      }
  
      .closeMediaIcon {
        position: absolute;
        background: red;
        border-radius: 20px;
        right: -6px;
        top: -7px;
        cursor: pointer;
      }
  
      .profile-RD {
        text-align: left;
  
        .withImg-RD {
          width: 3.125rem;
          height: 3.125rem;
          box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
          border-radius: 50rem;
        }
  
        .img-RD {
          width: 3.125rem;
          height: 3.125rem;
          background: transparent
            linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat
            padding-box;
          box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
          border-radius: 50rem;
          float: left;
          margin-right: 1rem;
          display: flex;
          justify-content: center;
          margin: 10px 10px auto;
  
          .profile-img {
            width: 3.125rem !important;
            height: 3.125rem !important;
            border-radius: 50% !important;
          }
        }
  
        .details-RD {
          // width: 115%;
          max-width: 100% !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
  
          [data-title] {
            font-size: 0.7rem;
            cursor: pointer;
            margin-bottom: 0px !important;
            font: normal normal normal 0.875rem/1.188rem Open Sans;
          }
  
          [data-title]:hover::before {
            content: attr(data-title);
            bottom: -40px;
            padding: 5px;
            background: #000;
            color: #fff;
            font-size: 0.7rem;
            word-wrap: break-word;
            width: 220px;
          }
  
          .name-RD {
            font: normal normal 600 1rem/1.375rem Open Sans;
            color: #222222;
            max-width: 100% !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
  
          .mail-RD {
            font: normal normal normal 0.875rem/1.188rem Open Sans;
            color: #777777;
          }
        }
      }
    }
  
    .actionBtn {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      padding: 0;
      margin-right: 10px;
  
      img {
        margin-top: -2px;
        margin-left: 0px !important;
      }
    }
  
    .cancel {
      background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0%
        0% no-repeat padding-box;
    }
  
    .save {
      background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0%
        0% no-repeat padding-box;
    }
  
    .marginGroup-RD {
      margin: 10px 75px auto;
    }
  }
  
  .StudentProfiles-RD {
    width: 103.5%;
    margin-left: -22px;
    margin-top: 1rem;
    padding-left: 22px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .studentCardMain-RD {
    width: 100%;
    height: 5rem;
    margin-bottom: 1.25rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.2rem 0.5rem #0000001a;
    border-radius: 0.5rem;
    opacity: 1;
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
  }
  
  .studentCardMain2-RD {
    width: 98.5%;
    height: 5rem;
    margin: 0rem 1.5rem 1.25rem 0rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0rem 0.2rem 0.5rem #0000001a;
    box-shadow: 0px 1px 3px #2222221a;
    border-radius: 0.5rem;
    opacity: 1;
  }
  
  .studentDetailsCard-RD {
    width: 100%;
    height: 5.3rem;
    display: inline-flex;
    padding: 0.5rem 0rem 0rem 0rem;
    position: relative;
    bottom: 30px;
  }
  
  .studentDetailsCard2-RD {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 30px;
  }
  
  .studentDetailSubDiv-RD {
    display: flex;
    flex-direction: row;
    width: 40%;
    align-items: baseline;
  
    .studentEmail-RD {
      margin: 0px;
      font-size: 14px !important;
      max-width: 100% !important;
      text-overflow: ellipsis !important;
      max-width: 100% !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  
  .studentDetailSubDiv2 {
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: baseline;
    justify-content: end;
  }
  
  .studentProfileDiv-RD {
    display: flex;
    flex-direction: row;
    width: 55%;
    align-items: center;
  }
  
  .studentProfileImageDiv-RD {
    width: 4rem;
  }
  
  .studentProfileImage-RD {
    width: 3.75rem;
    height: 3.75rem;
    background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001a;
    border-radius: 50rem;
    margin-left: 0.75rem;
  }
  
  .studentTextInfoDiv-RD {
    text-align: left;
    padding-top: 7px;
    padding-left: 15px;
  
    [data-title] {
      font-size: 0.7rem;
      position: relative;
      cursor: pointer;
      margin-bottom: 0px !important;
      text-overflow: ellipsis !important;
    }
  
    [data-title]:hover::before {
      content: attr(data-title);
      position: absolute;
      bottom: -40px;
      padding: 5px;
      background: #000;
      color: #fff;
      font-size: 0.7rem;
      word-wrap: break-word;
      width: 220px;
    }
  
    .studentEmailDiv2-RD {
      display: flex;
      align-items: end;
      padding-bottom: 2px;
      text-overflow: ellipsis;
    }
  
    .studentEmail-RD {
      margin: 0px;
      font-size: 14px !important;
      text-overflow: ellipsis;
    }
  }
  
  .studentTextInfoDiv2-RD {
    text-align: left;
    padding-left: 15px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  
    [data-title] {
      font-size: 0.7rem;
      position: relative;
      cursor: pointer;
      margin-bottom: 0px !important;
    }
  
    [data-title]:hover::before {
      content: attr(data-title);
      position: absolute;
      bottom: -40px;
      padding: 5px;
      background: #000;
      color: #fff;
      font-size: 0.7rem;
      word-wrap: break-word;
      width: 220px;
    }
  
    .studentEmailDiv2-RD {
      display: flex;
      align-items: end;
      padding-bottom: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
  
      .studentEmail-RD {
        margin: 0px;
        font-size: 14px !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }
    }
  
    .studentName-RD {
      margin: 0px;
      font-size: 1rem;
      font-weight: 600;
      color: #222222;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 95%;
    }
  }
  
  .classSharedViewOnlyDiv-RD {
    width: 8rem;
    height: 1.2rem;
    border: 0.5px solid #4479c4;
    border-radius: 16px;
    margin: 0.2rem 0rem 0rem 0rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background: #588fdc1a 0% 0% no-repeat padding-box;
  }
  
  .classViewOnlyText-RD {
    margin-top: 0.1rem;
    font-size: 0.6rem;
    color: #4479c4;
  }
  
  .classViewOnlyDiv-RD {
    width: 4rem;
    height: 1.2rem;
    border: 0.5px solid #4479c4;
    border-radius: 16px;
    margin: 0.2rem 0rem 0rem 0rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background: #588fdc1a 0% 0% no-repeat padding-box;
  }
  
  .studentName-RD {
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    color: #222222;
  }
  
  .viewOnlyButton {
    width: 4rem;
    height: 1rem;
    border: 0.5px solid #4479c4;
    border-radius: 16px;
    margin: 0.2rem 0rem 0rem 0rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background: #588fdc1a 0% 0% no-repeat padding-box;
  }
  
  .viewOnlyText {
    margin: 0px;
    font-size: 0.6rem;
    color: #4479c4;
  }
  
  .studentMenuIcon-RD {
    position: absolute;
    top: 24px;
    right: 0rem;
    font-size: 1.5rem;
    cursor: pointer;
    background: transparent;
  }
  
  /* ---------Student Details Cards------------ */
  
  /* ---------Student options Cards------------ */
  .studentOptionDiv-RD {
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .studentOptionDiv2-RD {
    padding: 0rem 0.5rem 0rem 0rem;
    display: flex;
    justify-content: space-between;
  }
  
  .optionsDiv-RD {
    width: 4rem;
    height: 3.7rem;
    margin-left: 0.9rem;
    padding-right: 0.45rem !important;
    padding-top: 0.6rem;
    cursor: pointer;
    border-right: 2px solid rgba(0, 0, 0, 0.15);
  }
  
  .optionsDiv2-RD {
    width: 4rem;
    height: 3.7rem;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
  }
  
  .tapToAddText-RD {
    color: #aaaaaa;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 70px;
  }
  
  .addAcademicImage-RD {
    width: 5rem;
    cursor: pointer;
  }
  
  .optionBtnDiv-RD {
    width: 3.9rem;
    height: 3.8rem;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
  }
  
  .attOptionBtn-RD {
    margin: 6px;
    background: transparent linear-gradient(270deg, #ad54d1 0%, #cb7aeb 100%) 0%
      0% no-repeat padding-box;
  }
  
  .noteOptionBtn-RD {
    margin: 6px;
    background: transparent linear-gradient(270deg, #747beb 0%, #989eff 100%) 0%
      0% no-repeat padding-box;
  }
  
  .classOptionBtn-RD {
    margin: 6px;
    background: transparent linear-gradient(270deg, #ffb239 0%, #ffc76e 100%) 0%
      0% no-repeat padding-box;
  }
  
  .lessonPlanOptionBtn-RD {
    margin: 6px;
    background: transparent linear-gradient(286deg, #f48329 0%, #f99a2a 100%) 0%
      0% no-repeat padding-box;
  }
  
  .rubricOptionBtn-RD {
    margin: 6px;
    background: transparent linear-gradient(270deg, #c94276 0%, #dd5d93 100%) 0%
      0% no-repeat padding-box;
  }
  
  .gradebookOptionBtn-RD {
    margin: 6px;
    background: transparent linear-gradient(270deg, #08b596 0%, #27d3b4 100%) 0%
      0% no-repeat padding-box;
  }
  
  .shareOptionBtn {
    margin: 6px;
    background: transparent linear-gradient(270deg, #4479c4 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
  }
  
  .profileOptionBtn-RD {
    margin: 6px;
    border: 1px solid #4e84d0 !important;
  }
  
  .deleteOptionBtn-RD {
    margin: 6px;
    border: 1px solid #ff4444 !important;
  }
  
  .classOptionDiv {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #ffb239 0%, #ffc76e 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
  }
  
  .noteOptionDiv {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #747beb 0%, #989eff 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
  }
  
  .gradebookOptionDiv {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #08b596 0%, #27d3b4 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
  }
  
  .shareOptionDiv-RD {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #4479c4 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
  }
  
  .optionIcons-RD {
    width: 1.2rem;
    color: red;
  }
  
  .optionText-RD {
    font-size: 0.6rem;
    font-weight: 600;
    margin-top: 0.3rem;
    color: #ffffff;
  }
  
  .disShareOptionDiv-RD {
    width: 3.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #4479c4 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: default !important;
    opacity: 0.5 !important;
  }
  
  .optionGreadeBookIcons-RD {
    width: 1.8rem;
  }
  
  .optionGreadeBookText {
    color: white;
    font-size: 0.6rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }
  
  /* ---------Student options Cards------------ */
  
  /* ---------Student Delete options Cards------------ */
  .deleteStudentCard-RD {
    width: 15.2rem;
    height: 9.2rem;
    margin: 0.8rem 0rem 0.5rem 0rem;
    background: #000 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.2rem 0.5rem #0000001a;
    border-radius: 0.5rem;
    float: left;
    padding: 1rem 1rem 1rem 1rem;
    text-align: -webkit-center;
    opacity: 0.9;
  }
  
  .deleteText-RD {
    color: #ffffff;
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .deleteWorningText-RD {
    color: #ffffff;
    font-weight: 600;
    font-size: 0.7rem;
  }
  
  .deleteButtonsDiv-RD {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0rem 2rem 0rem 2rem;
  }
  
  .cancelButtonText-RD {
    color: #ffffff;
    font-weight: 600;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    cursor: pointer;
  }
  
  .deleteButton-RD {
    width: 5rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #ec4444 0%, #fa6060 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0.1rem 0rem 0.1rem 0rem;
    border-color: #ec4444 !important;
  }
  
  .deleteButtonText-RD {
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
  }
  
  /* ---------Student Delete options Cards------------ */
  
  /* -----------------------------------Side Menu--------------------------- */
  
  /* ----- MODAL STYLE ----- */
  
  .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 103%;
    -webkit-transform: translate3d(0%, 0, 0) !important;
    -ms-transform: translate3d(0%, 0, 0) !important;
    -o-transform: translate3d(0%, 0, 0) !important;
    transform: translate3d(0%, 0, 0) !important;
  }
  
  .modal.right .modal-content {
    height: calc(100% - 90px);
    overflow-y: auto;
  }
  
  .modal.right .modal-body {
    padding: 0rem;
    background: #fff;
  }
  
  .modal.right.fade .modal-dialog {
    right: -350px;
    top: 106px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    transition: opacity 0.3s linear, right 0.3s ease-out !important;
  }
  
  .modal.right .modal-dialog {
    width: 422px;
    right: -47px;
  }
  
  .close {
    opacity: none !important;
  }
  
  .modal-header .close {
    opacity: none !important;
  }
  
  .modal.right.fade.show .modal-dialog {
    right: 0;
  }
  
  /* ----- MODAL STYLE ----- */
  
  /* --------------Add New Student Modal--------- */
  .ClassRosterModal {
    .modal-content {
      border-radius: 0;
      border: none;
    }
  
    .modal-title {
      font-size: 13px;
      font-weight: 600;
    }
  
    .modal-header {
      border-bottom-color: #eeeeee !important;
      background-color: #ffff !important;
      height: 52px !important;
    }
  
    .modal-header .close {
      padding: 0.5rem 1rem;
      margin: -1rem 0rem -1rem auto;
      opacity: 1 !important;
      color: #000 !important;
      margin-top: -5px !important;
      outline: none;
    }
  
    .cancelText {
      font-size: 0.8rem !important;
      color: #000 !important;
    }
  
    .closeIconInModal {
      width: 0.6rem;
      margin-top: -3px;
    }
  
    .modal-content {
      border-radius: 0;
      border: none;
    }
  
    .RosterImageMainDiv {
      width: 100%;
      height: 8rem;
      background-color: #f5f5f5 !important;
      text-align: -webkit-center;
      padding-top: 1.5rem;
      border-radius: 6px;
    }
  
    .RosterImageMainSelectedDiv {
      width: 100%;
      height: 9rem;
      background-color: #f5f5f5 !important;
      text-align: -webkit-center;
      padding-top: 1rem;
      background-size: cover;
      background-position: center;
    }
  
    .RosterImageWhenSelect {
      width: 100%;
      height: 100%;
      border-radius: 10rem;
    }
  
    .addStudentImageDiv {
      width: 5rem;
      height: 5rem;
      border-radius: 10rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #dddddd;
    }
  
    .cameraIconCss {
      width: 2rem;
      margin-top: 1.5rem;
      cursor: pointer;
    }
  
    .loaderIconCss {
      width: 4.5rem;
      margin-top: 1.5rem;
    }
  
    .RosterImageMainSelectedDiv .changeButton {
      width: 4.2rem;
      height: 1.9rem;
      background: #000000 !important;
      border-radius: 1.5rem !important;
      margin-top: 0.5rem;
      opacity: 0.7;
      cursor: pointer !important;
      display: inline-grid;
    }
  
    .RosterImageMainSelectedDiv .changeButtonText {
      color: white;
      font-size: 10px;
      font-weight: 600;
      cursor: pointer !important;
      margin-top: 0.5px !important;
    }
  
    .loaderIconCssAlreadySelected {
      width: 2.5rem;
    }
  
    .allInputMainDiv {
      padding: 0rem 1.5rem 1rem 1.5rem;
    }
  
    .studentAddInputDiv {
      width: 100%;
      margin: 1.5rem 1rem 0rem 0rem;
    }
  
    .studentInputLableInfo {
      color: #000;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0rem;
      text-align: left;
    }
  
    .allInputMainDiv .MuiOutlinedInput-input {
      height: 32px !important;
      padding-top: 7px;
      padding-bottom: 7px;
      font: normal normal 600 14px/20px Open Sans;
      color: #222222;
      margin-bottom: 0px !important;
    }
  
    .errormessageRegister {
      color: #d80f0f !important;
      float: left !important;
      font-size: 13px !important;
      margin-bottom: 1px !important;
      font-weight: 600;
      margin-top: 4px;
      line-height: 10px;
      padding-left: 2px;
    }
  
    .studentAddInput {
      border: 0.75px solid #dddddd;
      box-sizing: border-box;
      border-radius: 0.3rem;
      height: 3rem;
      background: #f4f6fa;
      width: 100%;
      margin-bottom: 0px !important;
    }
  
    .modalButtonClass {
      width: 100%;
      background: #ffff;
      justify-content: space-between;
      display: flex;
      padding: 1rem 0rem 1rem 0rem;
    }
  
    .saveButton {
      width: 5rem !important;
      height: 2.4rem !important;
      border-radius: 2rem !important;
      background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 0px 1px 4px #2222221a !important;
      display: inline-flex !important;
      justify-content: center !important;
      font-size: 0.6rem !important;
      margin: 0.1rem 0rem 0.1rem 0rem;
    }
  
    .saveText {
      color: white;
      font-size: 1rem;
    }
  
    .addAnotherButton {
      width: 12rem !important;
      height: 2.4rem !important;
      border-radius: 2rem !important;
      background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 0px 1px 4px #2222221a !important;
      display: inline-flex !important;
      justify-content: center !important;
      font-size: 0.6rem !important;
      margin: 0.1rem 0rem 0.1rem 0rem;
    }
  
    .addRosterModalButtonClass {
      .saveButton {
        border-radius: 8px !important;
      }
  
      .addAnotherButton {
        border-radius: 8px !important;
      }
    }
  }
  
  /* --------------Add New Student Modal--------- */
  
  /* --------------Upload Modal style--------- */
  .uploadDocHeaderDiv {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    opacity: 1 !important;
    color: #000 !important;
    margin-top: -2px !important;
    outline: none;
  }
  
  .uploadNodeDiv {
    text-align: left;
    background: #f5f5f5;
    padding: 1rem;
    width: 100%;
    position: absolute;
    bottom: 0rem;
  }
  
  .uploadNoteText {
    margin: 0rem;
    font-size: 0.8rem;
  }
  
  .checkbozCircle {
    height: 1rem;
    width: 1rem;
    border-radius: 10rem;
    border: 0.1rem solid #dddddd;
    text-align: -webkit-center;
    padding-top: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  
  .checkbozCircleSelected {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 10rem;
    background-color: #386cb5;
  }
  
  .selectMainDivforUpload {
    display: flex;
    padding: 1rem 0rem 0rem 0rem;
    width: 100%;
    justify-content: center;
  }
  
  .selectOptionInTag {
    width: 8.7rem;
    height: 9rem;
    border: 1px solid #dddddd;
    text-align: -webkit-center;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 8px;
  }
  
  .selectoptionUploadImage {
    width: 2.7rem;
    margin: 0.7rem 0rem 0.6rem 0rem;
  }
  
  .selectoptionUploadImageforDrop {
    width: 2.3rem;
    margin: 0.7rem 0rem 0.7rem 0rem;
  }
  
  .autharizedButton {
    width: 4.5rem !important;
    height: 1.4rem !important;
    border-radius: 2rem !important;
    padding: 0.2rem !important;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0.6rem 0rem 0.1rem 0rem;
  }
  
  .autharizedText {
    color: white;
    font-size: 0.65rem;
  }
  
  .logoutButton {
    width: 4.5rem !important;
    height: 1.4rem !important;
    border-radius: 2rem !important;
    padding: 0.2rem !important;
    background: transparent !important;
    border: none !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: -0.3rem 0rem 0.1rem 0rem;
  }
  
  .logoutButton:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  
  .logoutText {
    color: #ec4444;
    font-size: 0.65rem;
    font-weight: 600;
  }
  
  .loginEmailText {
    color: #222222;
    font-size: 0.65rem;
    font-weight: bold;
    margin: 0rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .selectTagText {
    color: #222222;
    font-size: 0.85rem;
    margin: 0rem;
    font-weight: 600;
  }
  
  .importRosterButton {
    width: 4.5rem !important;
    height: 1.7rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin-top: -8px;
  }
  
  .importRosterText-RD {
    color: white;
    font-size: 1rem;
    margin-top: -6px;
  }
  
  /* --------------Upload Modal style--------- */
  
  /* -----------------------------------Side Menu--------------------------- */
  
  /* ----------------PopOver------------------- */
  .popoverDelete {
    .popoverOptions {
      display: inline-flex;
      // border-bottom: 1px solid gray;
      padding: 0.5rem;
    }
  
    /* ----------- PopOver---------- */
  
    /* ----------------PopOver------------------- */
    .editpopOver {
      display: inline-flex;
      color: #000;
      cursor: pointer;
      padding: 0rem 0rem 0.5rem 0rem;
    }
  
    .editTextPopOver {
      font-size: 0.8rem;
    }
  
    .deletePopOver {
      display: inline-flex;
      color: #ff4444;
      cursor: pointer;
      padding: 0.5rem 0rem 0rem 0rem;
    }
  
    .deleteTextPopOver {
      font-size: 0.9rem;
    }
  
    .bs-popover-auto[x-placement^="left"] > .arrow::after,
    .bs-popover-left > .arrow::after {
      border-left-color: #fff !important;
    }
  
    .popover {
      width: 19rem !important;
      border-radius: 11px !important;
      height: 15rem;
      padding: 0.2rem !important;
    }
  
    .editRosterIcon {
      font-size: 1.3rem;
    }
  
    .disDeletePopOver {
      display: inline-flex;
      color: #ff4444;
      cursor: default !important;
      padding: 0.5rem 0rem 0rem 0rem;
      opacity: 0.5;
    }
  }
  
  /* ----------------PopOver------------------- */
  
  /* ------ */
  .academicPopOver-RD {
    /* ------------PopOver Header--------- */
    .popover {
      width: 20rem;
      padding: 0rem 0rem 0rem 0rem !important;
      border-radius: 11px !important;
      width: 16rem !important;
    }
  
    .popover-header {
      height: 2.5rem !important;
      font-size: 1rem !important;
      background: transparent linear-gradient(180deg, #639ae6 0%, #3d71bb 100%) 0%
        0% no-repeat padding-box;
      color: white;
      text-align: center;
      border-top-left-radius: calc(0.6rem - 1px) !important;
      border-top-right-radius: calc(0.6rem - 1px) !important;
    }
  
    .popover-body {
      padding: 0rem !important;
    }
  
    .editInputDiv-RD {
      text-align: center;
    }
  
    .editYearInputType {
      width: 15rem !important;
    }
  
    .notSelectedYearPopover {
      float: left;
      height: 2rem;
      width: 6.5rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #888888;
      border-radius: 8px;
      opacity: 1;
      padding: 0.4rem;
      margin: 0.3rem 0.3rem 0.3rem 0.3rem;
      text-align: center;
      cursor: pointer;
    }
  
    .selectedYearPopover {
      float: left;
      height: 2rem;
      width: 6.5rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.5px solid #cccccc;
      border-radius: 8px;
      opacity: 1;
      padding: 0.3rem;
      margin: 0.3rem 0.3rem 0.3rem 0.3rem;
      text-align: center;
    }
  
    .notSelectedYearTextPopover {
      color: #222222;
      font-size: 0.8rem;
    }
  
    .selectedYearTextPopover {
      color: #cccccc;
      font-size: 0.8rem;
    }
  
    .popoverList {
      height: 208px;
      overflow: hidden;
      overflow-y: scroll;
      padding: 0rem 0.6rem;
    }
  
    .updateButton {
      width: 7rem;
      height: 2rem;
      background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
        0% no-repeat padding-box;
      border-radius: 1.5rem !important;
      margin: 0.6rem 1rem 0rem 0rem;
    }
  
    .updateButtonText {
      margin: -0.3rem;
    }
  
    .editYearInputUpdateType {
      width: 92% !important;
      height: 2.4rem;
      margin-top: 0.5rem;
      padding-left: 0.4rem;
      background: #f4f6fa;
      margin-bottom: 0.2rem;
      border: 0.1rem solid #ccc;
      border-radius: 0.3rem;
      outline: none;
      font-size: 12px;
      font-style: italic;
    }
  
    ::-webkit-input-placeholder {
      /* Chrome, Safari, Opera */
      color: #ccc !important;
    }
  
    .afterDate {
      color: black;
      border: 0.1rem solid black;
    }
  
    .inputMaskUpdateParentDiv-RD {
      display: inline-flex;
      width: 100%;
      padding-bottom: 0.3rem;
    }
  
    .inputMaskUpdateDiv-RD {
      width: 100%;
      // padding: 0rem .6rem 0rem .3rem;
    }
  
    .errormessageUpdate {
      color: #d80f0f !important;
      float: left !important;
      font-size: 12px !important;
      margin-bottom: 1px !important;
    }
  }
  
  /* ------ */
  
  .delete-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
  }
  
  .delete-modal-warning .modal-content {
    border-radius: 8px;
    width: 360px;
    margin: auto;
    background-color: #fff !important;
  }
  
  .delete-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
  }
  
  .delete-modal-warning .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
  }
  
  .delete-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
  }
  
  .delete-modal-warning .modal-footer {
    border: none;
    justify-content: center;
    padding-top: 0;
  }
  
  .delete-modal-warning .cancel-btn {
    width: 101px;
    height: 38px;
    font: normal normal 600 14px/19px Open Sans;
  }
  
  .delete-modal-warning .delete-btn {
    background: transparent linear-gradient(270deg, #ec4444 0%, #fa6060 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;
    font: normal normal 600 14px/19px Open Sans;
    color: #ffffff;
    width: 101px;
    height: 38px;
  }
  
  @media only screen and (min-width: 250px) and (max-width: 590px) {
    .RosterMainContainer-RD {
      padding: 0rem 0rem 0rem 0rem;
    }
  
    .rosterfilterDiv-RD {
      width: 100%;
    }
  
    .rosterYearMainDiv-RD {
      padding: 0.5rem 0.1rem 0.5rem 0.1rem;
    }
  
    .totalStudentMainDiv {
      padding: 0.2rem 0.1rem 0.2rem 0.1rem;
    }
  }
  
  @media only screen and (min-width: 250px) and (max-width: 900px) {
    .modal.right.fade .modal-dialog {
      top: 123px;
    }
  }
  
  .studentCardResponsiveMain {
    width: 100%;
    height: 10.5rem;
    margin: 0rem 0rem 2rem 0rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.2rem 0.5rem #0000001a;
    border-radius: 0.5rem;
    opacity: 1;
    float: left;
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
  }
  
  .loadMoreRoster-RD {
    width: 8rem !important;
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: 0.6rem !important;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0.1rem 0.2rem 0.1rem 0.2rem;
  }
  
  .select-DriveFile-modal {
    margin-top: 3rem;
  }
  
  .select-DriveFile-modal .modal-title {
    font: normal normal 600 1rem/1.375rem Open Sans !important;
  }
  
  .select-DriveFile-modal .modal-content {
    height: 35rem !important;
  }
  
  .select-DriveFile-modal .importDriveLoader {
    height: 100px;
    width: 190px;
    margin-top: 30%;
  }
  
  .select-DriveFile-modal .file_listDiv {
    width: 100%;
    height: 40px;
    margin-top: 13px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #eeeeee;
  }
  
  .select-DriveFile-modal .modal-header {
    padding: 0.8rem 1rem 0;
    border-bottom: none;
    border-radius: 0.5rem;
  }
  
  .select-DriveFile-modal .modal-list-body {
    height: 25rem !important;
    overflow: auto !important;
  }
  
  .select-DriveFile-modal textarea {
    font: normal normal normal 0.875rem/1.188rem Open Sans;
  }
  
  .select-DriveFile-modal .selectedText {
    color: #41cc3b;
    font-weight: 600;
  }
  
  .select-DriveFile-modal .unSelectedText {
    font-weight: 600;
    cursor: pointer;
  }
  
  .select-DriveFile-modal .modal-footer {
    justify-content: center;
    border-top: none;
    padding-top: 0;
  }
  
  .select-DriveFile-modal .modal-footer button {
    width: 5rem;
    height: 5rem;
    border-radius: 1.25rem;
    padding: 0;
  }
  
  .select-DriveFile-modal .modal-footer button img {
    margin-top: -0.1875rem;
    margin-left: 0.0625rem;
  }
  
  .select-DriveFile-modal .modal-content {
    border-radius: 0.5rem;
    background: #fff !important;
  }
  
  .select-DriveFile-modal .modalcancleTagButton {
    width: 2.3rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #ec4444 0%, #fa6060 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    border-color: #ec4444 !important;
  }
  
  .select-DriveFile-modal .modalSaveTagButton {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0rem;
    border-color: #23bf1d !important;
  }
  
  .select-DriveFile-modal .checkBoxSelect {
    color: #386cb5;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-right: 0.2rem;
  }
  
  .select-DriveFile-modal .unCheckBoxSelect {
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-right: 0.2rem;
  }
  
  .select-DriveFile-modal .selectoptionUploadFolder {
    width: 2rem;
    margin: 0rem 0.5rem 0rem 0rem;
  }
  
  .select-DriveFile-modal .fileName {
    margin-top: 0.6rem;
  }
  
  .select-DriveFile-modal .driveBackIcon {
    font-size: 1.3rem;
    cursor: pointer;
  }
  
  .select-DriveFile-modal .cursor {
    cursor: pointer;
  }
  
  .select-DriveFile-modal .inline-Path {
    display: inline-flex;
    height: 30px;
  }
  
  .select-DriveFile-modal .selectFileCursor {
    display: inline-flex !important;
    cursor: pointer !important;
  }
  
  ul.breadcrumb {
    padding: 0px 0px;
    margin: 0px;
    list-style: none;
    background-color: transparent;
  }
  
  ul.breadcrumb li {
    display: inline;
    font-size: 14px;
    font-weight: 600;
  }
  
  ul.breadcrumb li + li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
  }
  
  ul.breadcrumb li a {
    color: #386cb5;
    text-decoration: none;
  }
  
  ul.breadcrumb li a:hover {
    color: #669fed;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .rst-refresh-section-RD {
    min-height: 3.75rem;
  
    .card-body {
      padding: 1rem;
    }
  }
  
  .rst-filterRow-RD {
    justify-content: space-between;
  }
  
  .rst-desc-search-col-RD {
    float: left;
    display: flex;
    max-width: 700px !important;
  }
  
  .rst-total-student-RD {
    width: 165px;
    text-align: left;
    float: left !important;
    padding-top: 8px;
    border-right: 2px solid rgba(0, 0, 0, 0.25);
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
      var(--unnamed-font-size-16) / var(--unnamed-line-spacing-22)
      var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-222222);
    text-align: left;
    font: normal normal 600 15px/22px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 0.4;
  }
  
  .rst-sort-by-col-RD {
    display: flex;
    float: right;
    direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  
  @media screen and (min-width: 1275px) and (max-width: 1470px) {
    .searchSearchStylediv {
      width: 92%;
    }
  
    .rst-desc-search-col-RD {
      max-width: 40% !important;
    }
  
    .totalNoteSearchDiv {
      width: 63%;
    }
  
    .desc-search-col {
      max-width: 420px !important;
    }
  
    .rst-sort-by-col-RD {
      min-width: 60% !important;
    }
  
    .note-dashboard-dropdown-container {
      right: 35px;
    }
  
    .rst-total-student-RD {
      width: 260px;
    }
  
    .searchFilterInputBox-RD {
      margin: 0;
      font-style: italic;
      font-size: 12px;
      color: #222222;
      opacity: 2 !important;
      width: 72%;
      height: 100%;
      background: transparent 0% 0% no-repeat padding-box;
  
      .MuiInputBase-input {
        font: inherit;
        font-size: 11px !important;
        color: currentColor;
        width: 100%;
        border: 0;
        height: 0.8876em !important;
        margin: 0;
        display: block;
        padding-top: 9px !important;
        min-width: 0;
        background: none;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        letter-spacing: inherit;
        animation-duration: 10ms;
        -webkit-tap-highlight-color: transparent;
      }
    }
  
    .studentEmailDiv2-RD {
      display: flex;
      align-items: end;
      padding-bottom: 2px;
      text-overflow: ellipsis;
      width: 165px;
      word-wrap: break-word;
    }
  }
  
  @media screen and (min-width: 1300px) and (max-width: 1350px) {
    .searchSearchStylediv {
      width: 92%;
    }
  
    .rst-desc-search-col-RD {
      max-width: 40% !important;
    }
  
    .totalNoteSearchDiv {
      width: 63%;
    }
  
    .desc-search-col {
      max-width: 420px !important;
    }
  
    .rst-sort-by-col-RD {
      min-width: 60% !important;
    }
  
    .note-dashboard-dropdown-container {
      right: 35px;
    }
  
    .btnContainer-RD {
      width: 9.8rem;
      height: 2.4rem;
      display: flex;
      border: 0.75px solid #dddddd;
      border-radius: 5px 5px 5px 5px;
      overflow: hidden;
      margin: 0px 15px 0px 15px;
    }
  }
  
  @media screen and (min-width: 1536px) and (max-width: 1745.45px) {
    .StudentProfiles-RD {
      width: 103%;
      margin-left: -22px;
      margin-top: 1rem;
      padding-left: 27px;
      padding-right: 3px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  
    .rst-total-student-RD {
      width: 280px;
    }
  
    .rst-desc-search-col-RD {
      float: left;
      display: flex;
      max-width: 580px !important;
    }
  
    .searchFilter-RD {
      width: 29.75rem;
      height: 2.25rem;
      border: 0.75px solid #ddd;
      border-radius: 18px;
      float: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      margin-top: 2px;
      background: #f4f6fa 0 0 no-repeat padding-box;
    }
  
    .btnContainer-RD {
      width: 8rem;
      height: 2.4rem;
      display: flex;
      border: 0.75px solid #dddddd;
      border-radius: 5px 5px 5px 5px;
      overflow: hidden;
      margin: 0 15px;
    }
  
    .myRosterDiv-RD {
      width: 4.5rem !important;
      height: 2rem !important;
      border: 1px solid #ccc;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .myRosterDivBlueActiveBtn-RD {
      width: 4.5rem !important;
      height: 2rem;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .studentTextInfoDiv-RD {
      text-align: left;
      padding-top: 7px;
      padding-left: 15px;
      max-width: 65% !important;
  
      .studentName-RD {
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        color: #222222;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
  
      .studentEmail-RD {
        margin: 0px;
        font-size: 14px !important;
        max-width: 100% !important;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  
    .studentEmailDiv2-RD {
      display: flex;
      align-items: end;
      padding-bottom: 2px;
      text-overflow: ellipsis;
      width: 230px;
      word-wrap: break-word;
    }
  }
  
  @media only screen and (min-width: 1275px) and (max-width: 1350px) {
    .rst-desc-search-col-RD {
      max-width: 40% !important;
    }
  
    .rst-total-student-RD {
      width: 345px;
    }
  
    .btnContainer-RD {
      width: 9.2rem;
      height: 2.4rem;
      display: flex;
      border: 0.75px solid #dddddd;
      border-radius: 5px 5px 5px 5px;
      overflow: hidden;
      margin: 0px 15px;
    }
  
    .searchFilter-RD {
      width: 70%;
      height: 2.25rem;
      border: 0.75px solid #dddddd;
      border-radius: 18px;
      float: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      background: #f4f6fa 0% 0% no-repeat padding-box;
    }
  
    .totalStudentMainDiv {
      width: 100%;
      min-width: fit-content;
      background: #ffff;
      display: flex;
      padding: 1rem 1rem 1rem 1rem;
      margin-top: 1rem;
      border-radius: 0.5rem;
      flex-flow: row;
  
      .filterSortByDiv {
        display: flex;
        border-right: 2px solid rgba(0, 0, 0, 0.25);
        width: 14%;
        align-items: center;
      }
  
      .RosterYearSortNameText-RD {
        margin: 0px;
        font-size: 0.9rem;
        font-weight: 600;
        width: 4rem;
        padding-top: 8px;
        margin-left: 10px;
      }
  
      .rosterfilterDiv2-RD {
        display: flex;
        border-right: 2px solid rgba(0, 0, 0, 0.25);
        padding-right: 6px;
        margin-right: 6px;
      }
    }
  
    .searchFilterIcon-RD {
      width: 14px;
      height: 14px;
      cursor: pointer;
      color: #386cb5;
      margin-right: 5px;
      margin-left: -2px;
      margin-top: 2px;
    }
  
    .studentTextInfoDiv-RD {
      text-align: left;
      padding-top: 7px;
      padding-left: 15px;
      max-width: 62% !important;
  
      .studentName-RD {
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        color: #222222;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
  
      .studentEmail-RD {
        margin: 0px;
        font-size: 14px !important;
        max-width: 100% !important;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  
    .firstName-RD {
      width: 5rem !important;
      height: 2rem !important;
      border: 0.5px solid #222222;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .firstNameBlueActiveBtn-RD {
      width: 5rem !important;
      height: 2rem !important;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .lastName-RD {
      width: 5rem !important;
      height: 2rem !important;
      border: 0.5px solid #222222;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .lastNameBlueActiveBtn-RD {
      width: 5rem !important;
      height: 2rem !important;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .listAll-RD {
      width: 3.375rem;
      height: 2rem;
      border: 0.5px solid #222222;
      border-radius: 8px;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .listAllActiveBlueBtn-RD {
      width: 3.375rem;
      height: 2rem;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .myRosterDiv-RD {
      width: 4rem !important;
      height: 2rem !important;
      border: 1px solid #ccc;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .myRosterDivBlueActiveBtn-RD {
      width: 4rem !important;
      height: 2rem !important;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .sharedRosterDiv-RD {
      width: 5.625rem;
      height: 2rem;
      border: 0.5px solid #222222;
      border-radius: 8px;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .sharedRosterDivBlueActiveBtn-RD {
      width: 5.625rem;
      height: 2rem;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .listAllText-RD {
      font-size: 11px !important;
      color: #000;
      padding: 1rem 0rem 0rem 0.1rem;
      cursor: pointer;
    }
  
    .listAllActiveText-RD {
      font-size: 11px !important;
      color: #fff;
      padding: 1rem 0rem 0rem 0.1rem;
      cursor: pointer;
    }
  
    .studentEmail-RD {
      margin: 0px;
      font-size: 14px !important;
      text-overflow: ellipsis !important;
      overflow: hidden;
    }
  
    .studentDetailSubDiv-RD {
      display: flex;
      flex-direction: row;
      width: 35%;
      align-items: baseline;
    }
  }
  
  @media only screen and (min-width: 1351px) and (max-width: 1469px) {
    .rst-total-student-RD {
      width: 300px;
    }
  
    .btnContainer-RD {
      width: 8.75rem;
      height: 2.4rem;
      display: flex;
      border: 0.75px solid #dddddd;
      border-radius: 5px 5px 5px 5px;
      overflow: hidden;
      margin: 0 15px;
    }
  
    .searchFilter-RD {
      width: 68%;
      height: 2.25rem;
      border: 0.75px solid #dddddd;
      border-radius: 18px;
      float: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      background: #f4f6fa 0% 0% no-repeat padding-box;
    }
  
    .searchFilterInputBox-RD {
      margin: 0;
      font-style: italic;
      font-size: 12px;
      color: #222222;
      opacity: 2 !important;
      width: 70%;
      height: 100%;
      background: transparent 0% 0% no-repeat padding-box;
  
      .MuiInputBase-input {
        font: inherit;
        font-size: 13px !important;
        color: currentColor;
        width: 100%;
        border: 0;
        height: 0.8876em !important;
        margin: 0;
        display: block;
        padding-top: 9px !important;
        min-width: 0;
        background: none;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        letter-spacing: inherit;
        animation-duration: 10ms;
        -webkit-tap-highlight-color: transparent;
      }
    }
  
    .studentTextInfoDiv-RD {
      text-align: left;
      padding-top: 7px;
      padding-left: 15px;
      max-width: 63% !important;
  
      .studentName-RD {
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        color: #222222;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
  
      .studentEmail-RD {
        margin: 0px;
        font-size: 14px !important;
        max-width: 100% !important;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  
    .firstName-RD {
      width: 5rem !important;
      height: 2rem !important;
      border: 0.5px solid #222222;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .firstNameBlueActiveBtn-RD {
      width: 5rem !important;
      height: 2rem !important;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .lastName-RD {
      width: 5rem !important;
      height: 2rem !important;
      border: 1px solid #ccc;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .lastNameBlueActiveBtn-RD {
      width: 5rem !important;
      height: 2rem !important;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .listAll-RD {
      width: 4.375rem;
      height: 2rem;
      border: 1px solid #ccc;
      border-radius: 8px;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .listAllActiveBlueBtn-RD {
      width: 4.375rem;
      height: 2rem;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .myRosterDiv-RD {
      width: 4.5rem !important;
      height: 2rem !important;
      border: 1px solid #ccc;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .myRosterDivBlueActiveBtn-RD {
      width: 4.5rem !important;
      height: 2rem !important;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .sharedRosterDiv-RD {
      width: 5.625rem;
      height: 2rem;
      border: 1px solid #ccc;
      border-radius: 8px;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .sharedRosterDivBlueActiveBtn-RD {
      width: 5.625rem;
      height: 2rem;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .listAllText-RD {
      font-size: 11px !important;
      color: #000;
      padding: 1rem 0rem 0rem 0.1rem;
      cursor: pointer;
    }
  
    .listAllActiveText-RD {
      font-size: 11px !important;
      color: #fff;
      padding: 1rem 0rem 0rem 0.1rem;
      cursor: pointer;
    }
  
    .StudentProfiles-RD {
      width: 103.3%;
      margin-left: -22px;
      margin-top: 1rem;
      // padding-left: 22px;
      padding-left: 27px;
      padding-right: 3px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  
  @media only screen and (min-width: 1400px) and (max-width: 1469px) {
    .btnContainer-RD {
      width: 8.75rem;
      height: 2.4rem;
      display: flex;
      border: 0.75px solid #dddddd;
      border-radius: 5px 5px 5px 5px;
      overflow: hidden;
      margin: 0 20px;
    }
  }
  
  @media screen and (min-width: 1470px) and (max-width: 1536px) {
    .rst-desc-search-col-RD {
      max-width: 530px !important;
    }
  
    .rst-sort-by-col-RD {
      min-width: 651px !important;
    }
  
    .rst-total-student-RD {
      width: 350px;
    }
  
    .btnContainer-RD {
      width: 9.75rem;
      height: 2.4rem;
      display: flex;
      border: 0.75px solid #ddd;
      border-radius: 5px 5px 5px 5px;
      overflow: hidden;
      margin: 0 15px;
    }
  
    .searchFilter-RD {
      width: 30.75rem !important;
      height: 2.25rem;
      border: 0.75px solid #ddd;
      border-radius: 18px;
      float: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      margin-top: 2px;
      background: #f4f6fa 0 0 no-repeat padding-box;
    }
  
    .myRosterDivBlueActiveBtn-RD {
      width: 4.5rem !important;
      height: 2rem;
      background-color: #386bb5 !important;
      border: 0.5px solid #386bb5 !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .myRosterDiv-RD {
      width: 4.5rem !important;
      height: 2rem !important;
      border: 1px solid #ccc;
      border-radius: 8px !important;
      margin: 0.3rem;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .studentTextInfoDiv-RD {
      text-align: left;
      padding-top: 7px;
      padding-left: 15px;
      max-width: 65% !important;
  
      .studentName-RD {
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        color: #222222;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
  
      .studentEmail-RD {
        margin: 0px;
        font-size: 14px !important;
        max-width: 100% !important;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  
    .StudentProfiles-RD {
      width: 103.2%;
      margin-left: -22px;
      margin-top: 1rem;
      // padding-left: 22px;
      padding-left: 27px;
      padding-right: 3px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  
    .studentEmailDiv2-RD {
      display: flex;
      align-items: end;
      padding-bottom: 2px;
      text-overflow: ellipsis;
      width: 230px;
      word-wrap: break-word;
    }
  }
  
  @media screen and (min-width: 1745px) and (max-width: 1921px) {
    .StudentProfiles-RD {
      width: 102.8%;
      margin-left: -22px;
      margin-top: 1rem;
      // padding-left: 22px;
      padding-left: 27px;
      padding-right: 3px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  
    .studentTextInfoDiv-RD {
      text-align: left;
      padding-top: 7px;
      padding-left: 15px;
      max-width: 70% !important;
  
      .studentName-RD {
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
        color: #222222;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
  
      .studentEmail-RD {
        margin: 0px;
        font-size: 14px !important;
        max-width: 100% !important;
        text-overflow: ellipsis !important;
        max-width: 100% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  
    .studentEmailDiv2-RD {
      display: flex;
      align-items: end;
      padding-bottom: 2px;
      text-overflow: ellipsis;
      width: 45%;
      word-wrap: break-word;
    }
  }
  
  .schltchroption {
    display: none !important;
  }
  
  .lmb {
    border-radius: 9px !important;
  }
  .importdrivebtn {
    width: 100px !important;
    height: 38px !important;
    font-size: 1rem !important;
    border-radius: 8px!important;
  }
  
  .groupRosterPopover {
    background-color: #fff;
    z-index: 999;
    position: absolute;
    border : 1px solid rgba(0,0,0,.2);
    border-radius: 11px;
    top: 0;
    left: 45px;
    .studentOptionDiv-RD {
      padding-bottom: 0.5rem;
    }
    .arrow {
      width: 15px;
      height: 15px;
      border: 1px solid rgba(0,0,0,.2);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 10px;
      left: -8px;
      z-index: 10;
      // border-radius: 10px;  
    }
    .arrow-cover {
      top: 10px;
      left: -7px;
      width: 15px;
      height: 15px;
      border: 3px solid transparent;
      position: absolute;
      background: white;
      z-index: 30;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      // border-radius: 10px;
    }
    // &::before {
    //     // content: "";
    //     // position: absolute;
    //     // left: -10px;
    //     // top: 33px;
    //     // transform: translateY(-50%);
    //     // border-top: 10px solid transparent;
    //     // border-bottom: 10px solid transparent;
    //     // border-left: 10px solid #fff; /* adjust color and size as needed */
    //     // border-right: 10px solid red; /* Arrow border color set to red */
    //     content: "";
    //     position: absolute;
    //     right: -20px; /* Adjusted the right position to increase space for the arrow */
    //     top: 50%;
    //     transform: translateY(-50%);
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     border-left: 10px solid #fff; /* Arrow background color set to white */
    //     border-right: 10px solid red; /* Arrow border color set to red */
    // }
    .optionBtnDiv-RD {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        margin-bottom: 0;
      }
    }
    .shareOptionDiv-RD {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        margin-bottom: 0;
      }
    }
  }