body {
    background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);

}
/* .background{
   background-image: url('../../../Assets/LoginModule/back.png') !important;
   background-size: cover;
   background-position: center;
} */
.ForgetMainContainer {
   height: 100%;
   overflow: hidden;
   background-image: url('../../../Assets/LoginModule/background.png') !important;
   background-size: contain;
   background-position: center;
   display: flex;
}
.ForgetSubContainer {
   width: 50%;
   height: 100%;
   align-items: center;
   justify-content: center;
   display: flex;
   flex-direction: column;
   .img-div {
       align-self: center;
       max-width: 350px;
   }
}
.ForgetCardDiv {
   width: 500px;
   height: 360px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 6px #00000029;
   border-radius: 20px;
   opacity: 1;
   padding: 3.31rem 2.5rem 3.31rem 2.5rem;
   .ForgetTextDiv {
       padding: 0px;
       align-items: left;
       text-align: left;
   }
   .ForgetText {
       font-size: 24px;
       color: #000000;
       font-weight: 700;
       text-transform: uppercase;
       text-align: left;
   }
   .ProcessText {
       text-align: left;
       font: normal normal normal 18px Open Sans;
       letter-spacing: 0px;
       color: #000000;
       opacity: 0.5;
   }
}
.ForgetInputDiv {
   width: 420px;
   height: 48px;
   border: 1px solid var(--unnamed-color-dddddd);
   background: #F7FAFF 0% 0% no-repeat padding-box;
   border: 1px solid #DDDDDD;
   border-radius: 6px;
   opacity: 1;
}
.RegistrationBtnDiv {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: baseline;
   margin-top: 2rem;
   .RegistrationBtn {
       width: 160px;
       height: 40px;
       display: flex;
       align-items: center;
       justify-content: center;
       background: #FEC006 0% 0% no-repeat padding-box;
       box-shadow: 0px 1px 10px #0000001A;
       border-radius: 6px;
       opacity: 1;
   }
   .RegistrationBtnText {
       font-size: 16px;
       font-weight: 600;
       color: #FFFFFF;
       text-transform: uppercase;
       opacity: 1;
   }
}
.bottomDiv {
   display: flex;
   flex-direction: column;
   margin-top: 1rem;
   .text-1 {
       text-align: center;
       font: normal normal normal 14px/19px Open Sans;
       letter-spacing: 0px;
       color: #000000;
       margin-bottom: 0px;
   }
   .text-2 {
       text-align: center;
       font: normal normal 600 14px/19px Open Sans;
       letter-spacing: 0px;
       color: #000000;
       margin-top: 0px;
   }
}
.mTop20{
   margin-top: 1rem;
}
.mTop40{
   margin-top: 4rem;
}
.fLeft{
   float: left;
}
.fRight{
   float: right;
}
.width300{
   width: 300px;
}
.checkBoxColorLogin{
   color:#33D152;
   cursor: pointer;

}


/* ==========main Card Login ==========*/
.logincard {
   width: 25rem;
   border-radius: 1rem;
   padding: 0rem 3rem 2rem 3rem;
   margin: auto;
   text-align: center;
   margin-top: 3rem;
   border-color: #BBBBBB;
   background: transparent;
}

.mainIcon{
   max-width: 260px;
}

.proceedTextDiv {
   padding: 0px;
   align-items: left;
   text-align: left;
   margin-top: 40px;
}
.proceedText{
   margin: 0;
   font-weight: 500;
}
.loginInput p {
   margin: 0;
   font-size: 17px;
}

.login_text {
   font-size: 1.3rem;
   color: #386cb5;
   font-weight: 700;
   text-transform: uppercase;
   text-align: left;
   padding-bottom: 0.5px;
   /* border-bottom-style: solid;
   border-bottom-width: 0.2rem;
   width: -moz-fit-content;
   width: fit-content; */
}


/* ==========Input Div ==========*/
.inputLableDiv{
   margin-top: 15px;
   text-align: left;
}
.labelTextLogin{
   margin: 0;
   font-weight: 600;
}

.inputIcon {
   width: 1.2rem;
   height: 1.1rem;
   color:#909294;
}

.inputTypeLogin{
   border: 00.75px solid #DDDDDD;
   box-sizing: border-box;
   border-radius: 0.5rem;
   width: 19rem;
   height: 3rem;
   padding-left: 2.2rem;
}

.inputTypeDivlLogin {
   margin-top: .5rem;
   position: relative;
}

.imageICon{
   position: absolute;
   left: 0.5rem;
   top: 0.7rem;
}
.eyeIcon{
   position: absolute;
   right: 1rem;
   top: 0.8rem;
   cursor: pointer;

}
/* ==========Input Div ==========*/

/* ==========Keep me Login Div ==========*/
.checkIcon {
   width: 1rem;
   height: 1rem;
   margin-right: 0.2rem;
   margin-top: -.2rem;
}
.keepMeTextLogin {
   font-size: 0.9rem;
   margin-left: .2rem;
   margin-top: .2rem;
}
.Textforgot {
   font-size: 0.9rem;
   color: #0974DD;
   font-weight: 600;
   cursor: pointer;
}
/* ==========Keep me Login Div ==========*/


/* ==========Button Div ==========*/

.RetrivePassBtn Button {
   border-radius: 0.375rem;
   height: 3.125rem;
   padding: .5rem;
   width: 100%;
   background-image: linear-gradient(to left , #386cb5, #669FED);
   font-size: 1rem;
   font-weight: 600;
   margin-top: 2.5rem;
}
.buttonTop{
   margin-top: 10px;
}

.SigninBtn {
   padding: .8rem 0 0 0;
}
.registerText {
   font-size: 0.9rem;
   color: #000000;
   font-weight: 600;

}
.registerTextColour{

}
/* ==========Button Div ==========*/


@media only screen and (min-width: 250px) and (max-width: 420px) {

   .logincard {
       padding: 2rem 4rem 2rem 3rem;
   }
   .eyeIcon{
       right: .5rem;
   }

   /* ==========main Card Login ==========*/





/* ==========Input Div ==========*/

}





