.ClassNoteAddGroupSCSS {
  .CNAG-classNoteAddGroupkMainContainer {
    background: #f0f0f0;
    height: 100%;
    padding: 0rem 3rem 0rem 3rem;
    // overflow-x: hidden;

    a:focus,
    .btn:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    .back-btn {
      font: normal normal 600 1rem/1.375rem Open Sans;
      background-color: #eeeeee !important;
      border-radius: 0.25rem;
      width: 100%;
      text-align: left !important;
      color: #222222 !important;
      border: none !important;
      font-weight: 600 !important;
      height: 3.125rem;
      width: 317px;
      margin-right: 15px;

      .fa-chevron-left {
        margin-right: 0.625rem;
      }
    }

    @media only screen and (max-width: 1200px) {
      .back-btn {
        width: 214px;
        font-size: 14px;
        white-space: nowrap;
      }
    }

    .assessment-btn {
      font: normal normal 600 13px/16px Open Sans !important;
      background: transparent linear-gradient(270deg, #ffb239 0%, #ffc76e 100%)
        0% 0% no-repeat padding-box;
      border-radius: 0.5rem;
      border: none !important;
      width: 100%;
      text-align: center;
      color: #ffffff;
      min-height: 48px;

      .actionIcons {
        width: 1.2rem;
        height: 1.2rem;
        float: left;
        margin-right: 0.55rem;
        margin-top: 5px;
        margin-left: 18px;
      }

      @media only screen and (max-width: 1730px) {
        .actionIcons {
          margin-left: 8px;
        }
      }

      @media only screen and (max-width: 1565px) {
        .actionIcons {
          margin-left: 2px;
          margin-right: 4px;
        }
      }
    }

    @media only screen and (max-width: 1450px) {
      .assessment-btn {
        font-size: 12px !important;
      }
    }

    .note-btn {
      font: normal normal 600 13px/18px Open Sans !important;
      background: transparent linear-gradient(270deg, #747beb 0%, #989eff 100%)
        0% 0% no-repeat padding-box;
      border-radius: 0.5rem;
      border: none !important;
      width: 100%;
      text-align: center;
      color: #ffffff;
      min-height: 48px;

      .actionIcons {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.75rem;
      }
    }

    .CNAG-main-section {
      .header {
        min-height: 5rem;

        .card-body {
          padding: 16px 16px;
        }

        .CNAG-saveCloseBtn {
          float: right;
          margin-left: 15px;
          padding: 0;
        }
      }

      .CNAG-header-title {
        text-align: left;
        font: normal normal 600 1rem/38px Open Sans;
        color: #222222;
        display: inline-flex;

        .CNAG-border-right {
          width: 350px;
          display: inline-flex;

          .calIcon {
            cursor: pointer;
          }
        }

        @media only screen and (max-width: 1200px) {
          .CNAG-border-right {
            width: 224px;
          }
        }

        .year {
          margin: auto 0;
          margin-left: 10px;
          white-space: nowrap;

          .text-italic {
            font-style: italic;
          }
        }

        .sub-title {
          font: normal normal normal 12px/17px Open Sans;
          margin-left: 15px;
          color: #777777;
        }
      }

      .CNAG-slider {
        text-align: left;
        font: normal normal 600 1rem/32px Open Sans;
        color: #222222;
        display: inline-flex;
        height: 50px;

        .mt--18 {
          margin-top: -19px;
        }

        .mt-18 {
          padding-top: 18px !important;
        }

        .CNAG-left {
          width: 104px;
          margin: 0;
          margin-right: 12px;

          .CNAG-suggestion {
            font: var(--unnamed-font-style-italic) normal
              var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
              var(--unnamed-line-spacing-17)
              var(--unnamed-font-family-open-sans);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-777777);
            text-align: left;
            font: italic normal normal 12px/17px Open Sans;
            letter-spacing: 0px;
            color: #777777;
            opacity: 1;
          }
        }

        .CNAG-right {
          margin: 0;
          display: flex;
          overflow: auto;
          width: calc(100vw - 310px);
          overflow: hidden;
         
          height: 55px;
        //   ::-webkit-scrollbar {
        //     width: 2px;
        //     height: 2px;
        //     border-radius: 2px !important;
        //     // position : 'relative';
        //     // top: 5px;
        //     display: block !important;
         
        //  }
          &:hover {
            overflow-x: scroll;
            overflow-y: hidden;
            
          }
          // width: 125%;
          // padding-top: 20px;
          .CNAG-student-profile {
            font: normal normal normal 12px/17px Open Sans;
            letter-spacing: 0px;
            color: #222222;
            opacity: 1;
            width: 70px;
            text-align: left;
            display: inline-table;
            margin-bottom: 25px;
            // padding-right: 10px;
            // padding-left: 10px;
            position: relative;

            label {
              margin-bottom: 0;
            }

            .img-div {
              width: 3.125rem;
              height: 3.125rem;
              background: transparent
                linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0%
                no-repeat padding-box;
              margin: auto;
              // margin-bottom: 8px;
              box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
              border-radius: 50rem;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              top: 0px;
              position: relative;

              img {
                width: 3.125rem;
                height: 3.125rem;
                border-radius: 50rem;
              }

              .imgTag {
                font: normal normal normal 23px/32px Open Sans;
                color: #ffffff;
                margin-top: -9px;
                display: block;
              }
            }
          }
        }
        .CNAG-right ::-webkit-scrollbar {
          width: 2px;
          height: 2px;
          border-radius: 2px !important;
          position : 'relative';
          top: 5px;
          display: block !important;
       
       }
       .CNAG-right::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border-radius: 2px !important;
        position : 'relative';
        top: 5px;
        // display: block !important;
        width: 8px;
      }
      
      .CNAG-right::-webkit-scrollbar-thumb {
        background-color: #888;
      }
      }

      @media only screen and (max-width: 1300px) {
        .CNAG-right {
          width: calc(100vw - 190px) !important;
        }
      }

      .CNAG-student-section {
        margin-top: 11px;
        border-bottom: 1px solid #dddddd;
        opacity: 1;

        .CNAG-changedCard {
          padding: 9px 1.25rem;
          padding-bottom: 20px;
          // background-color: #F5F7FB;
          // position: relative;
          // display: flex;
          // flex-direction: column;
          // min-width: 0;
          // word-wrap: break-word;
          // background-clip: border-box;
          // border: 0px solid #F5F7FB;
          // border-radius: 0px;
        }

        .CNAG-tickMarkImg {
          background: transparent
            linear-gradient(325deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat
            padding-box;
          width: 16px !important;
          height: 16px !important;
          border-radius: 20px;
          padding: 4px;
          
    /* transform: translate(33px, 18px); */
    // margin-top: -1px;
    // margin-left: 5px;
    // position: absolute;
    position: absolute;
    z-index: 1;
    transform: translate(56px, 3px);
    // margin-top: -15px;
    // margin-left: -12px;
    position: absolute;
    left: -56px;

        }

        .CNAG-notickMarkImg {
          background: transparent linear-gradient(341deg, #ffffff 36%, #ffff 0%)
            0% 50% no-repeat padding-box;
          width: 16px !important;
          height: 16px !important;
          border-radius: 20px;
          padding: 4px;
          transform: translate(25px, 18px);
          margin-top: -15px;
          margin-left: -12px;
          position: absolute;
        }

        .groupMarkImg {
          background: transparent
            linear-gradient(341deg, #ffffff 36%, #edf0f3 0%) 0% 50% no-repeat
            padding-box;
          width: 16px !important;
          height: 16px !important;
          border-radius: 20px;
          transform: translate(25px, 18px);
        }
      }

      @media only screen and (max-width: 767px) {
        .CNAG-classNoteAddGroupkMainContainer {
          padding: 0;
        }

        .CNAG-main-section {
          padding: 0.9375rem !important;
        }

        .CNAG-header-title {
          font: normal normal 600 0.875rem/1.188rem Open Sans !important;
          margin-bottom: 10px;
        }

        .addButton {
          width: auto;
          font-size: 14px !important;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      .CNAG-classNoteAddGroupkMainContainer {
        padding: 0;
      }

      .CNAG-main-section {
        // margin-top: 15px;
        padding: 0 !important;
      }

      .filterSubDiv {
        margin-top: 6px;
      }
    }

    @media only screen and (max-width: 520px) {
      .CNAG-main-section {
        padding: 0 !important;
      }

      .filterDiv {
        float: left;
      }

      .filterSubDiv {
        width: fit-content;
        margin-top: 10px;
      }
    }
  }

  .mt-0 {
    margin-top: 0px !important;
  }

  .pt-0 {
    padding-top: 0px !important;
  }

  .pointer {
    cursor: pointer;
    position: absolute
  }

  .CNAG-changedCardFontCenter {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .noneStudents {
      font-weight: 600;
      opacity: 0.4;
      display: flex;
      // font-size: 1.5rem;
      font: normal normal 600 20px/14px Open Sans;
    }

  }

  .CNAG-DetailTextDiv {
    border-left: 2px solid #eee;
    margin-left: 15px;
    // cursor: pointer
  }

  .CNAG-DisplayFlex {
    display: flex;
    cursor: pointer;
    margin-left: 15px;

    p {
      margin: 0px !important;
      padding: 0px 0px 0px 0px !important;
      font-size: 16px;
    }

    i {
      color: #487fcc;
      font-size: 24px;
      padding-top: 7px;
      cursor: pointer;
    }
  }

  .CNAG-currentYear {
    color: #487fcc;
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
  }

  .CNAG-stdNoteGrade2 {
    color: #222222;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    cursor: default;
  }

  .CNAG-dropdownMainContainer {
    font-family: sans-serif;
    text-align: center;
    position: relative;
    flex: 0.6;
    margin-left: 18%;

    .iconDiv {
      position: absolute;
      left: 9px;
      z-index: 2;
      top: 2px;

      .CNAG-searchFiltersIcon {
        cursor: pointer;
        color: #aaaaaa;
        margin-right: 10px;
        margin-top: 4px;
      }
    }

    .select-search {
      width: 240px !important;
      position: relative;
      font-family: "Nunito Sans", sans-serif;
      box-sizing: border-box;
      bottom: 5px !important;
      cursor: pointer;
    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {
      box-sizing: inherit;
    }

    .select-search__value {
      position: relative;
    }

    .select-search__value::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: calc(50% - 9px);
      right: 19px;
      width: 11px;
      height: 11px;
      cursor: pointer;
    }

    .select-search__input {
      margin-top: 5px;
      display: block;
      height: 40px;
      width: 240px;
      padding: 0 40px 0 30px;
      background: #fff;
      border: 1px solid #dddddd;
      // box-shadow: 0px 0px 3px #88c3fae3;
      border-radius: 8px;
      outline: none !important;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      color: rgba(56, 108, 181, 1);
      text-align: left;
      text-overflow: ellipsis;
      line-height: 36px;
      -webkit-appearance: none;
      opacity: 1;
      box-shadow: 0px 0px 3px #00000029;
      border: 0.75px solid #dddddd;
      border-radius: 8px;
      opacity: 1;
    }

    .select-search__input::placeholder {
      font: italic normal normal 13px/18px Open Sans;
      letter-spacing: 0px;
      color: #c6b6b6 !important;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    .select-search__input:not([readonly]):focus {
      cursor: initial;
    }

    .select-search__select {
      max-height: 150px !important;
      box-shadow: 10px 10px 12px #ec090929;
      border: 1px solid #dddddd;
    }

    .select-search__options {
      list-style: none;
      padding: 0px 5px;
      background: #fff;
      cursor: pointer;
    }

    .select-search__row {
      border-bottom: 1px solid #dddddd;
      border-width: 0.15px;
    }

    .select-search__row:last-child {
      border-bottom: none;
    }

    .select-search__option,
    .select-search__not-found {
      display: block;
      height: 36px;
      width: 100%;
      background: #fff;
      border: none;
      outline: none;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .select-search--multiple .select-search__option {
      height: 48px;
    }

    .select-search__option.is-selected {
      color: #fff;
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
      color: #fff;
    }

    .select-search__group-header {
      text-transform: uppercase;
      background: #fff;
    }

    .select-search.is-disabled {
      opacity: 0.5;
    }

    .select-search.is-loading .select-search__value::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
      background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
      cursor: pointer;
      letter-spacing: 0px;
      font-weight: 600;
      text-align: left;
    font: normal normal 600 13px/18px Open Sans;
    letter-spacing: 0px;
    color: #386CB5;
    opacity: 1;
      
    }

    .select-search--multiple {
      border-radius: 3px;
      overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple)
      .select-search__value::after {
      transform: rotate(45deg);
      border-right: 3px solid #000;
      border-bottom: 3px solid #000;
      pointer-events: none;
    }

    .select-search.has-focus .select-search__value::after {
      display: none;
    }

    .select-search.has-focus .select-search__input:hover {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .select-search.has-focus .select-search__input {
      border-bottom: none;
      box-shadow: 0px 0px 3px #00000029;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-color: none;
      background: #f4f6fa 0% 0% no-repeat padding-box;
    }

    .select-search--multiple .select-search__input {
      cursor: initial;
    }

    .select-search--multiple .select-search__input {
      border-radius: 3px 3px 0 0;
    }

    .select-search--multiple:not(.select-search--search) .select-search__input {
      cursor: default;
    }

    .select-search:not(.select-search--multiple) .select-search__select {
      position: absolute;
      z-index: 2;
      top: 40px !important;
      right: 0;
      left: 0;
      border-radius: 8px;
      overflow: auto;
      max-height: 360px;
      background: #fff;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      box-shadow: none;
    }

    .select-search--multiple .select-search__select {
      position: relative;
      overflow: auto;
      max-height: auto;
      border-top: 1px solid #eee;
      border-radius: 0 0 3px 3px;
      background: #fff;
    }

    .select-search__not-found {
      height: auto;
      padding: 8px;
      text-align: center;
      color: rgb(244, 245, 247);
    }
  }

  .CNAG-add-note-img {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.5rem;
    margin-top: -0.1875rem;
  }
  .CNAG-add-note-disable {
    width: 6.875rem;
    height: 2.5rem;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box !important;
    border-radius: 0.5rem !important;
    float: right;
    background: transparent
      linear-gradient(
        270deg,
        var(--unnamed-color-386cb5) 0%,
        var(--unnamed-color-669fed) 100%
      )
      0% 0% no-repeat padding-box;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    opacity: 0.65!important;
    color: #ffffff;
  }
  .CNAG-add-note {
    width: 6.875rem;
    height: 2.5rem;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box !important;
    border-radius: 0.5rem !important;
    float: right;
    background: transparent
      linear-gradient(
        270deg,
        var(--unnamed-color-386cb5) 0%,
        var(--unnamed-color-669fed) 100%
      )
      0% 0% no-repeat padding-box;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    color: #ffffff;
  }

  .CNAG-searchBar {
    padding-top: 4px;
  }

  .CNAG-rosterProfileImageText {
    // padding: 12px 0px 0px 0px;
    color: white;
    margin: 0px !important;
    text-transform: uppercase;
    cursor: default;
  }

  .CNAG-Popover {
    width: 500px !important;
    border-radius: 20px !important;
    height: 500px !important;
  }

  @media only screen and (max-width: 1300px) {
    .filterSubDiv {
      float: left;
    }

    .artifactsNote {
      margin-bottom: unset;
    }
  }

  @media only screen and (max-width: 1220px) {
    .CNAG-classNoteAddGroupkMainContainer {
      padding: 0rem 1rem;
    }
  }

  @media screen and (min-width: 1275px) and (max-width: 1300px) {
    .CNAG-classNoteAddGroupkMainContainer {
      background: #f0f0f0;
      height: 100%;
      padding: 0rem 3rem 0rem 3rem;
      overflow-x: hidden;
    }

    .CNAG-left {
      width: 126px !important;
      margin: 0 !important;
      margin-right: 11px !important;

      .CNAG-suggestion {
        font: var(--unnamed-font-style-italic) normal
          var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
          var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-777777);
        text-align: left;
        font: italic normal normal 11px/17px Open Sans;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
      }
    }
  }
}

.popover {
  display: flex;
  justify-content: center;
}

.hideModal {
  display: none !important;
}

.CNAG-student-popover {
  background-color: white;
  width: 228px !important;
  // height: 157px !important;
  border-radius: 10px !important;
  // overflow: auto;

  .CNAG-popover-header {
    opacity: 1;
    border-radius: 10px 10px 0px 0px;
    background-color: white;

    .header-line {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .popover-body {
    padding: 0px 8px 0px 8px;

    .CNAG-checkBoxDiv {
      .CNAG-popoverList {
        padding-top: 4px;
        padding-bottom: 4px;
        border-bottom: 1px solid #dddddd;
        opacity: 1;
      }

      .checkBoxfilters {
        font-size: 23px;
        color: #386cb5;
        cursor: pointer;
        margin-right: 0.2rem;
      }

      .CNAG-popoverListText {
        font: normal normal 500 12px/17px Open Sans;
        padding-top: 3px;
        padding-left: 8px;
        display: inline-block;
        width: 180px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }

  .student-popup-action-btn {
    font: normal normal 600 12px/17px Open Sans;
    color: #386cb5 !important;
    padding: 2px !important;
    font-size: 12px !important;
    border: none;
  }

  .student-popup-text {
    font: normal normal 600 13px/18px Open Sans;
    color: black;
    text-align: left;
    padding-right: 95px;
  }

  .arrow::after {
    // border-bottom-color: #6199e4 !important;
  }

  .arrow::before {
    top: 0;
    // border-width: 0 .5rem .5rem .5rem;
    // border-bottom-color: #6199e4 !important;
  }

  .popover-header::before {
    // border-bottom: 1px solid #6199e4 !important;
  }
}
.radcircle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  /* border: 1px solid rgb(0, 0, 0); */
  /* transform: translate(56px, 3px); */
  transform: translate(7px, -2px);

  opacity: 0.4;
  cursor: pointer;
  font-size: initial;
  position: relative;
  z-index: 6;
}

