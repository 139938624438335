.viewRubricsMainContainer {
    .viewRubricsContainer {
        background: #f0f0f0;
        height: 100%;
        overflow-x: hidden;
        padding: 0rem 2.5rem 1rem 2.5rem;

        .addRubricsMainDiv {
            width: 100%;
            justify-content: space-between;
            display: flex;
            padding: 1rem 1rem 1rem 1rem;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 4px #2222221A;
            border-radius: 0px 0px 8px 8px;
            opacity: 1;

            .RubricsDetailsMain {
                display: flex;
                align-items: center;
                flex: 2;

                .ClassBackRubDiv {
                    // display: flex;
                    // padding: 0.8rem;
                    // background: #eeeeee;
                    // width: 15rem;
                    // height: 3.1rem;
                    // cursor: pointer;

                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    padding: 0.8rem;
                    background: transparent linear-gradient(90deg, #669fed 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                    border-radius: 6px;
                    opacity: 1;
                }

                .lessonBackIcon {
                    // font-size: 1.3rem;
                    // padding-top: 2px;

                    font-size: 1.6rem;
                    padding-top: 2px;
                    text-align: left;
                    font: normal normal normal 16px/19px Font Awesome 5 Free;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    opacity: 1;
                }

                .lessonClassBactText {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                }

                .RubricSeprator {
                    width: 1px;
                    height: 40px;
                    border-left: 2px solid #ddd;
                    align-self: center;
                    margin-left: 20px;
                    margin-right: 20px;
                }

                .attClassDetailTextDiv {
                    // margin-left: 1rem;
                    flex: 1.6;
                    // border-left: 1px solid #eeeeee;
                    padding: 0.3rem 0rem 0rem 0rem;

                    .firstDetailsDiv {
                        display: grid;
                    }

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        padding-right: 5px;
                        font: normal normal 600 14px/14px Open Sans;
                    }

                    .head {
                        color: #386cb5;
                        text-transform: capitalize;
                        // padding-right: 5px;
                        text-align: left;
                        font: normal normal 600 14px/14px Open Sans;
                        margin: 0px;
                    }

                    // }
                    .displayFlexClassRoster {
                        display: flex;
                        cursor: pointer;

                        .calIcon {
                            align-self: center;
                            cursor: default;
                            cursor: not-allowed;
                        }

                        .attClassGrade {
                            color: #888888;
                            margin: 0rem 0.5rem 0rem 0rem;
                            font-size: 0.9rem;
                            text-align: left;
                            padding: 8px 0px 0px 0px;
                            cursor: default;
                            font-weight: 600;
                        }

                        .topicHead {
                            color: #070707;
                            margin: 0rem 0.5rem 0rem 0rem;
                            font-size: 0.9rem;
                            text-align: left;
                            padding: 8px 0px 0px 0px;
                            cursor: default;
                            font-weight: 600;
                        }

                        .topicTextName {
                            color: #2e4aeb;
                            margin: 0rem 0.5rem 0rem 0rem;
                            font-size: 0.9rem;
                            text-align: left;
                            padding: 8px 0px 0px 0px;
                            cursor: default;
                            font-weight: 600;
                        }

                        .topicDate {
                            color: #888888;
                            margin: 0rem 0.5rem 0rem 0rem;
                            font-size: 0.9rem;
                            text-align: left;
                            padding: 8px 0px 0px 0px;
                            cursor: default;
                            font-weight: 600;
                        }
                    }
                }
            }

            .displayFlexInSeletAtt {
                padding-right: 12px;
                display: flex;
                align-items: center;

                .undoSelects {
                    width: 6.5rem;
                    height: 2.5rem;
                    background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10rem;
                    opacity: 1;
                    margin-right: 1rem;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .iconText {
                        padding: 1rem 0rem 0rem 0rem;
                        color: white;
                        font-size: 0.9rem;
                    }
                }

                .opacityFadeUndo {
                    opacity: 0.3;
                    cursor: default;
                    pointer-events: none;
                }

                .confirmSlectDiv {
                    width: 6.5rem;
                    height: 2.5rem;
                    background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10rem;
                    // opacity: 0.4;
                    cursor: pointer;
                    justify-content: center;
                    display: flex;
                    align-items: center;

                    .confirmedIcon {
                        color: white;
                        font-size: 1.2rem;
                    }

                    .iconText {
                        color: white;
                        font-size: 0.9rem;
                        margin: 0px 0px 0px 5px !important;
                    }
                }

                .opacityFade {
                    opacity: 0.4;
                    pointer-events: none;
                }
            }

            .buttonMarginTop {
                display: flex;
                align-items: center;

                .addNewLesson {
                    width: 11rem !important;
                    height: 2.5rem !important;
                    border-radius: 2rem !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                }

                .addNewLessonFade {
                    width: 11rem !important;
                    height: 2.5rem !important;
                    border-radius: 2rem !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #a7e9a5 0%, #a7e9a5 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                    pointer-events: none;
                }

                .editBtnAsses {
                    width: 11.5rem !important;
                    height: 2.5rem !important;
                    border-radius: 8px !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 1.3rem 0.1rem 0.3rem;

                    .viewIconDwnload {
                        margin-top: 4px
                    }
                }

                .editFade {
                    width: 11rem !important;
                    height: 2.5rem !important;
                    border-radius: 2rem !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #709cda 0%, #94a7c2 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                }

                .pencilIcon {
                    width: 1.1rem;
                    margin-top: 0.2rem;
                }

                .lessonIcons {
                    width: 1.1rem;
                    margin-top: 0.4rem;
                }

                .viewAttText {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                }
            }

            .lessonDetailTextDiv {
                margin-left: 1rem;
                border-left: 1px solid #eeeeee;
                padding: 0.3rem 0rem 0rem 1rem;

                .lessonClassName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                }

                .displayFlexClassLesson {
                    display: flex;
                    cursor: pointer;

                    .calIcon {
                        align-self: center;
                        cursor: default;
                        cursor: not-allowed;
                    }

                    .lessonClassGrade {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 5px 0px 0px 5px;
                        cursor: default;
                    }

                    .headingForEdited {
                        display: inline-flex;
                        flex-wrap: wrap;
                        cursor: default;
                        align-items: center;

                        .attTitleName {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            text-align: left;
                            display: flex;
                            padding-right: 5px;
                            font: normal normal 600 14px/14px Open Sans;
                        }

                        .head {
                            color: #487FCC;
                            text-transform: capitalize;
                            font: normal normal 600 14px/14px Open Sans;
                            margin: 0px;
                        }
                    }

                    .attClassGrades {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 1px 0px 0px 5px;
                        cursor: default;
                        font-weight: 600;
                    }
                }
            }
        }


        .table-Fix-Size {
            height: 66.4vh;
            background: none !important;
            overflow: auto;
        }

        .table-rubrics {
            display: flex;
            flex-direction: column;
            margin: 15px 0px 0px 0px;

            .noGradebookDataText {
                color: #aaaaaa;
                font-size: 2rem;
                font-weight: 600;
                margin-top: 10%;
            }

            .table-rubrics-table {
                display: flex;
                flex-direction: row;
                max-height: auto;
            }

            .table-rubrics {
                flex-direction: row;
                display: flex;
            }

            .table-Average-section {
                display: flex;
                overflow: hidden;
                overflow-y: scroll;
                overflow-x: auto;
                width: calc(87vw - 220px);

                .data-title {
                    background: #386cb51c;
                    border: 1px #cccccc;
                    border-style: none solid solid solid;
                    width: 122.5px;
                }
            }

            .table-left-section-Average {
                width: 315px;
                background-color: transparent;
                border: none;
                border-bottom: none;
            }

            .table-left-section {
                flex: 0.2;
                background-color: white;
                border: 1px solid #cccccc;
                margin-right: 15px;
                box-shadow: 6px 6px 9px #2222221a !important;
                min-height: 401px;
                height: 66.4vh;
                overflow: auto;
                border-radius: 7px;

                .header-title {
                    height: 50px !important;
                    background: #eeeeee;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    label {
                        font: italic normal normal 12px/17px Open Sans;
                        color: #777777;
                        margin: 12px;
                        text-align: left;
                        font-weight: 600;
                    }
                }

                .profile {
                    text-align: left;
                    // margin: auto;
                    display: flex;
                    // margin-left: 12px;
                    width: 100%;
                    align-items: center;
                    padding: 10px;

                    .profileDummyRosterProfile {
                        width: 3.3rem;
                        height: 3.3rem;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 1px 2px 3px #0000001a;
                        border-radius: 50rem;
                        margin-top: 0rem;
                        text-align: center;
                        opacity: 0.8;

                        .rosterProfileImageText {
                            color: white;
                            padding-top: 28%;
                            text-transform: uppercase;
                        }
                    }

                    .opaqueProf {
                        opacity: 0.3 !important;
                        pointer-events: none;
                        cursor: default !important
                    }

                    .profile-img {
                        width: 50px;
                        height: 50px;
                        background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                        border-radius: 50rem;
                        // cursor: pointer;
                    }

                    .nameLable {
                        font: normal normal 600 13px/18px Open Sans;
                        color: #222222;
                        font-weight: 600;
                        flex: 0.9;
                        padding-left: 8px;
                        // margin: auto;
                        // margin-left: 8px;
                        // min-width: 124px;
                    }

                    .emojiIconsRub {
                        display: flex;
                        align-items: center;
                        flex: 0.2;

                        // margin: 0px 10px;
                        // margin-right: 10px;
                        // padding-right: 10px;
                        .avgScoresText {
                            margin: 0rem 0.5rem 0rem 0rem;
                            font-size: 0.9rem;
                            text-align: left;
                            cursor: default;
                            font-weight: 600;
                            // cursor: pointer;
                        }



                        .begin {
                            color: #f05f5f;
                        }

                        .develope {
                            color: #ffd307;
                        }

                        .accomplish {
                            color: #ff9c17;
                        }

                        .exemplar {
                            color: #80ce4f;
                        }
                    }
                }
            }

            .tableRightSection {
                width: calc(80vw - 220px);
                flex: 0.8;
                min-height: 401px;
                height: 66.4vh;
                overflow: auto;

                .NoneSelected {
                    padding-top: 200px;
                    color: #aaaaaa;
                    font: normal normal 600 24px/22px Open Sans !important;
                }

                .rubricsAssesTabContainer {
                    background: white;
                    border-top-left-radius: 7px;
                    border-top-right-radius: 7px;

                    .assessmentMainDiv {
                        display: flex;
                        border-bottom: 2px solid #dddddd;

                        .assessmentHeaderDiv {
                            // flex: 0.2;
                            // min-width: 190px;
                            // width: 195px;
                            display: flex;
                            padding: 5px;
                            justify-content: center;
                            align-items: center;
                            border-right: 2px solid #dddddd;
                            flex: 0.7;

                            .tableTitles {
                                margin: 0px;
                                color: #aaaaaa;
                                font: normal normal 600 16px/22px Open Sans !important;
                            }
                        }

                        .assessmentNumbersTitle {
                            display: flex;
                            // width: 100%;
                            // flex: 0.42;
                            // width: 430px;
                            flex: 1.63;
                            align-items: center;
                            padding: 15px 15px 15px 15px;

                            .beginning {
                                background: #f05f5f;
                                height: 35px;
                                display: flex;
                                align-items: center;
                                border-top-left-radius: 20px;
                                border-bottom-left-radius: 20px;
                                flex: 0.31;
                                justify-content: center;
                            }

                            .developing {
                                background: #ffd307;
                                height: 35px;
                                display: flex;
                                align-items: center;
                                flex: 0.31;
                                justify-content: center;
                            }

                            .accomplished {
                                background: #ff9c17;
                                height: 35px;
                                display: flex;
                                align-items: center;
                                flex: 0.22;
                                justify-content: center;
                            }

                            .exemplary {
                                background: #80ce4f;
                                height: 35px;
                                display: flex;
                                align-items: center;
                                border-top-right-radius: 20px;
                                border-bottom-right-radius: 20px;
                                flex: 0.22;
                                justify-content: center;
                            }

                            .nameTexts {
                                margin: 0px;
                                color: white;
                                font: normal normal 600 12px/22px Open Sans !important;
                            }
                        }

                        .verticalLines {
                            display: flex;
                            align-items: center;

                            .verticalLine {
                                border-left: 2px solid #DDDDDD;
                                height: 40px;
                                display: flex;
                                align-items: center;
                                margin: 13px;
                            }
                        }

                        .commentsTitle {
                            flex: 0.9;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .cmtTitleText {
                                margin: 0px;
                                color: #aaaaaa;
                                font: normal normal 600 16px/22px Open Sans !important;
                            }

                            .saveBtn {
                                width: 100px;
                                height: 28px;
                                background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
                                border-radius: 14px;
                                // margin-top: 5px;
                                margin-left: 15px;
                                font: normal normal 600 12px/14px Open Sans;
                                color: #ffffff;
                                margin-right: 15px;
                            }

                            .opacityBtn {
                                opacity: 0.3 !important;
                            }
                        }
                    }

                    .assessmentMainDataDiv {

                        // display: flex;
                        // height: 80px;
                        // border-bottom: 2px solid #dddddd;
                        .mainAssessDiv {
                            display: flex;
                            border-bottom: 2px solid #dddddd;

                            .criteriaTitle {
                                min-width: 314px;
                                // width: 195px;
                                border-right: 2px solid #dddddd;
                                flex: 0.7 !important;
                                // flex: 0.169;
                                // flex: 0.515 1;
                                justify-content: center;
                                align-items: center;
                                display: flex;
                                padding: 5px;

                                // max-width: 253px;
                                // min-width: 253px;
                                .criteriaText {
                                    margin: 0px;
                                    color: #050505;
                                    font: normal normal 600 13px/18px Open Sans;
                                }
                            }

                            .criNumbers {
                                // flex: 0.42;
                                // width: 430px;
                                display: block !important;
                                padding: 15px;
                                flex: 1.63 !important;
                                align-items: center;
                                display: flex;

                                // flex: 1.4;
                                .numbersDiv {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 100%;
                                    cursor: default !important;

                                    .numDiv {
                                        margin: 0px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        cursor: default !important;

                                        .beging {
                                            background-color: #f05f5f !important;
                                        }

                                        .develope {
                                            background-color: #ffd307 !important;
                                        }

                                        .accomplish {
                                            background-color: #ff9c17 !important;
                                        }

                                        .exemp {
                                            background-color: #80ce4f !important;
                                        }

                                        .criteriaNumbers {
                                            margin: 0px;
                                            font-weight: 600;
                                            background: #eaeaea;
                                            width: 28px;
                                            height: 28px;
                                            border-radius: 20px;
                                            padding-top: 2px;
                                            cursor: default !important;
                                        }
                                    }
                                }

                                .commentsSection {
                                    display: flex;
                                    margin-top: 5px;

                                    .noCmt {
                                        color: #aaaaaa;
                                        font: italic 600 13px/23px Open Sans !important;
                                        margin: 0px;
                                        align-self: flex-start;
                                        position: relative;
                                        top: 8px
                                    }

                                    .commentsSectionSpan {
                                        margin-top: 5px;
                                        display: flex;

                                        .cmtTitle {
                                            color: #767171;
                                            font: normal 600 13px/23px Open Sans !important;
                                            margin: 0px;
                                            align-self: flex-start;
                                            padding-right: 5px;
                                        }

                                        .cmtText {
                                            color: #aaaaaa;
                                            font: normal 600 13px/23px Open Sans !important;
                                            margin: 0px;
                                            align-self: flex-start;
                                            width:800px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                            text-align: left;
                                        }
                                    }
                                }

                                .CommentDiv {
                                    display: flex;

                                    .commentText {
                                        margin: 0px;
                                        padding-top: 10px;
                                        color: #777777;
                                        justify-content: flex-start;
                                    }
                                }
                            }

                            .verticalLines {
                                display: flex;
                                align-items: center;

                                .verticalLine {
                                    border-left: 2px solid #DDDDDD;
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                    margin: 13px;
                                }
                            }

                            .rationalCommentTextDiv {
                                display: flex;
                                //   justify-content: space-between;
                                padding: 10px;
                                width: 239px;
                                // flex: 0.41;
                                flex: 0.98;
                                // width: 370px;
                                align-items: center;

                                .rationalCommentText {
                                    width: 80%;
                                    border: none;
                                    border: 1px solid #dddddd;
                                    height: 40px;
                                    padding-left: 10px;
                                    // flex: .79;
                                    background: #f4f6fa;
                                    border-radius: 5px;
                                    color: black;
                                    font-size: 0.88rem;
                                    font-weight: 600;
                                    width: 350px;
                                }

                                .saveBtn {
                                    width: 100px;
                                    height: 28px;
                                    background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
                                    border-radius: 14px;
                                    // margin-top: 5px;
                                    margin-left: 15px;
                                    font: normal normal 600 12px/14px Open Sans;
                                    color: #ffffff;
                                }

                                // .editScoreBtn {
                                //     width: 100px;
                                //     height: 28px;
                                //     background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
                                //     border-radius: 14px;
                                //     // margin-top: 5px;
                                //     margin-left: 15px;
                                //     font: normal normal 600 12px/14px Open Sans;
                                //     color: #ffffff;
                                // }
                                .opacityBtn {
                                    opacity: 0.3 !important;
                                }

                                // .saveBtnWhenComment {
                                //     width: 70px;
                                //     height: 28px;
                                //     background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
                                //     border-radius: 14px;
                                //     margin-top: 20px;
                                //     margin-left: 30px;
                                //     font: normal normal 600 12px/14px Open Sans;
                                //     color: #ffffff;
                                // }

                                .saveBtn:active,
                                .saveBtn:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }

                            input::placeholder {
                                font-size: 0.95rem;
                                font-weight: 200;
                                color: rgb(206, 198, 198);
                            }

                            input[type="text"]:focus,
                            textarea:focus {
                                outline: none;
                            }
                        }
                    }
                }
            }

            .forNoneData {
                align-items: center;
            }

            .headerStyle {
                height: 80px;
                border-bottom: 1px solid #cccccc;
                display: flex;
                cursor: pointer;
            }

            .selectedProfile {
                background: #e3ebf6;
            }

            .avg-row-header {
                background: #386cb51c;
                border: 1px #cccccc;
                border-style: none none solid solid;
                height: 50px;
                margin: auto;
                font: normal normal 600 16px/22px Open Sans !important;
                text-align: left;
                padding: 12px 20px;
            }
        }
    }


    .PreviewAttContainer {
        background: #f0f0f0;
        height: 100%;
        overflow-x: hidden;
        padding: 0rem 2.5rem 1rem 2.5rem;

        .ClassAttendanceMainDiv {
            width: 100%;
            justify-content: space-between;
            display: flex;
            padding: 1rem 1rem 1rem 1rem;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 4px #2222221A;
            border-radius: 0px 0px 8px 8px;
            opacity: 1;

            .classAttendaceDetailsMain {
                display: flex;
                align-items: center;

                .rubClassBackDiv {
                    // display: flex;
                    // background: #eeeeee;
                    // width: 13rem;
                    // height: 3.1rem;
                    // cursor: pointer;
                    // padding: 0.8rem;

                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    padding: 0.8rem;
                    background: transparent linear-gradient(90deg, #669fed 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                    border-radius: 6px;
                    opacity: 1;
                }

                .attClassBackIcon {
                    font-size: 1.3rem;
                    padding-top: 2px;
                    color: white;
                }

                .attClassBactText {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                }
            }

            .buttonMarginTop {
                display: flex;
                align-items: center;

                .editAttBtn {
                    width: 5.3rem !important;
                    height: 2.5rem !important;
                    border-radius: 2rem !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                }

                .opacityBtn {
                    opacity: 0.4;
                    cursor: default;
                }

                .viewAttPreDiv {
                    width: 6rem !important;
                    height: 2.5rem !important;
                    border-radius: 8px !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.8rem 0.1rem 0.3rem;

                    .viewAttIcons {
                        width: 1rem;
                        margin-top: 0.3rem;
                    }
                }

                .downloadPreDiv {
                    width: 7.5rem !important;
                    height: 2.5rem !important;
                    border-radius: 8px !important;
                    padding: 0.6rem !important;
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 4px #2222221a !important;
                    display: inline-flex !important;
                    justify-content: center !important;
                    font-size: 0.6rem !important;
                    margin: 0.1rem 0.3rem 0.1rem 0.3rem;

                    .viewAttIcons {
                        width: 1rem;
                        margin-top: 0.3rem;
                    }
                }

                .pencilAttIcon {
                    width: 1rem;
                    margin-top: 0.2rem;
                }

                .viewAttIcon {
                    width: 1rem;
                    margin-top: 0.2rem;
                }

                .viewAttText {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                }

                .displayFlexInSeletAtt {
                    padding-right: 20px;
                    display: flex;

                    .undoSelect {
                        width: 2.5rem;
                        height: 2.5rem;
                        background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
                        border-radius: 10rem;
                        opacity: 1;
                        margin-right: 1rem;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .RubricSeprator {
                width: 1px;
                height: 40px;
                border-left: 2px solid #ddd;
                align-self: center;
                margin-left: 20px;
                margin-right: 20px;
            }

            .attClassDetailTextDiv {
                // margin-left: 1rem;
                // border-left: 1px solid #eeeeee;
                padding: 0rem;

                .attClassName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    color: #386cb5;
                }

                .displayFlexClassRoster {
                    display: flex;
                    cursor: pointer;

                    .calIcon {
                        align-self: center;
                        cursor: default;
                        cursor: not-allowed;
                    }

                    .attClassGrade {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 5px 0px 0px 5px;
                        cursor: default;
                    }

                    .headingForEdited {
                        display: inline-flex;
                        flex-wrap: wrap;
                        cursor: default;
                        align-items: center;

                        .attTitleName {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            text-align: left;
                            display: flex;
                            padding-right: 5px;
                            font: normal normal 600 14px/14px Open Sans;
                        }

                        .head {
                            color: #386cb5;
                            text-transform: capitalize;
                            font: normal normal 600 14px/14px Open Sans;
                            margin: 0px;
                        }
                    }

                    .attClassPreGrades {
                        color: #888888;
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.8rem;
                        text-align: left;
                        padding: 3px 0px 0px 11px;
                        cursor: default;
                        font-weight: 600;
                        font-style: italic;
                    }
                }
            }
        }

        .showFlex {
            display: flex;
        }

        .reportMailDiv {
            .noneStudents {
                color: #aaaaaa;
                font: normal normal 600 20px/14px Open Sans;
                line-height: inherit;
                padding-top: 140px;
            }

            .reportPreView {
                margin-top: 20px;

                .main {
                    height: 400px !important;

                    .react-pdf__Document {
                        height: 352px;
                        overflow: scroll;

                        .react-pdf__Page {
                            .react-pdf__Page__canvas {
                                display: inline !important;
                                // width: 550px !important;
                                // height: 841px !important;
                                box-shadow: 22px 7px 26px #2222221a !important;
                            }
                        }
                    }

                    .pagec {
                        font: normal normal 600 14px/28px Open Sans;
                    }

                    .buttonc {
                        .prePage {
                            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                            margin: 0px 10px 0px 5px;
                            border-radius: 8px;
                            color: white;
                            border: none;
                            width: 88px;
                            height: 33px;
                            cursor: pointer;
                            outline: none;
                        }

                        .nextPage {
                            background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                            margin: 0px 10px 0px 5px;
                            border-radius: 8px;
                            color: white;
                            border: none;
                            width: 88px;
                            height: 33px;
                            cursor: pointer;
                            outline: none;
                        }
                    }
                }
            }

            .emailComposer {
                width: 500px;
                height: 407px;
                background-color: white;
                margin: 20px 0px 0px 20px;
                border-radius: 8px;
                box-shadow: 0px 1px 4px #2222221a !important;
                font: normal normal 600 14px/28px Open Sans;

                .titleDiv {
                    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                    align-items: center;
                    display: flex;
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                    color: white;
                    height: 40px;

                    .composeTitle {
                        padding-left: 18px;
                        margin-bottom: 0px !important;
                    }
                }

                .emailForm {
                    padding: 0px 18px 0px 18px;

                    .errormessageRegisters {
                        color: #d80f0f !important;
                        float: left !important;
                        font-size: 13px !important;
                        margin-bottom: 1px !important;
                        font-weight: 600;
                        margin-top: -1px;
                        line-height: 10px;
                        padding-left: 2px;
                    }

                    .field {
                        display: table;
                        border-bottom: 1px solid #cccccc;
                        padding: 8px 0px 8px 0px;
                        width: 100%;

                        .mailDiv {
                            border: none;
                            outline: none;
                            width: 100%;
                        }

                        .messageText {
                            border: none;
                            outline: none;
                            width: 100%;
                            height: 193px;
                        }

                        .subjectText {
                            border: none;
                            outline: none;
                            width: 100%;
                            height: 30px;
                        }
                    }
                }

                .actions {
                    padding: 10px 16px;
                    display: flex;
                    background: #f2f2f2;
                    justify-content: flex-end;

                    .emailBtn {
                        width: 6rem !important;
                        height: 2.5rem !important;
                        border-radius: 8px !important;
                        padding: 0.6rem !important;
                        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                        box-shadow: 0px 1px 4px #2222221a !important;
                        display: inline-flex !important;
                        justify-content: center !important;
                        font-size: 0.6rem !important;
                        margin: 0.1rem 0.3rem 0.1rem 0.3rem;

                        .viewAttIcons {
                            width: 1rem;
                            margin-top: 0.3rem;
                        }

                        .viewAttText {
                            color: white;
                            font-size: 0.9rem;
                            margin-left: 0.5rem;
                        }
                    }

                    .cancelBtn {
                        background: none;
                        outline: none;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 539px) and (max-width: 768px) {
    .reportMailDiv {
        .reportPreView {
            margin-top: 20px;

            .main {
                height: 400px !important;

                .react-pdf__Document {
                    height: 352px;
                    overflow: scroll;

                    .react-pdf__Page {
                        .react-pdf__Page__canvas {
                            display: inline !important;
                            width: 349px !important;
                            height: 841px !important;
                            box-shadow: 22px 7px 26px #2222221a !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .reportMailDiv {
        .reportPreView {
            margin-top: 20px;

            .main {
                height: 400px !important;

                .react-pdf__Document {
                    height: 352px;
                    overflow: scroll;

                    .react-pdf__Page {
                        .react-pdf__Page__canvas {
                            display: inline !important;
                            width: 420px !important;
                            height: 841px !important;
                            box-shadow: 22px 7px 26px #2222221a !important;
                        }
                    }
                }
            }
        }
    }
}