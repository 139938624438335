.blogContainer{
    justify-content: center;
    padding: 30px 0px 30px 0px;
    
    .blogHeading{
        text-align: center;
        font: normal normal normal 35px/40px Roboto;
        letter-spacing: 0.8px;
        color: #222222;
    }
    .blogMainCard{
        width: 100%;
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .blogCards{
        width: 350px;
        height: 360px;
        background: #F0F3F7 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 3px #0000001A;
        border-radius: 2px;
        border: none;
        margin-left: 25px;
        margin-top: 25px;
        cursor: pointer;
    }
    .blogCardImage{
        width: 100%;
        height: 180px;
        // background: transparent url('img/1.png') 0% 0% no-repeat padding-box;
        border-radius: 2px 2px 0px 0px;
        opacity: 1;
    }
    .blogCardTitle{
        color: var(--unnamed-color-222222);
        text-align: left;
        font: normal normal bold 17px/20px Raleway;
        letter-spacing: 0.34px;
        color: #222222;
        max-height: 40px;
    overflow: hidden;
    }
    .blogCardDiscription{
        color: var(--unnamed-color-222222);
        text-align: left;
        font: normal normal normal 15px/20px Roboto;
        letter-spacing: 0.3px;
        color: #222222;
        max-height: 60px;
    overflow: hidden;
    }
    .blogCardTime{
        text-align: left;
        font: normal normal normal 14px/19px Roboto;
        letter-spacing: 0.28px;
        color: #666666;
        opacity: 1;
    }
    .blogLoadMore{
        width: 100px;
        height: 30px;
        background: var(--unnamed-color-386cb5) 0% 0% no-repeat padding-box;
        background: #386CB5 0% 0% no-repeat padding-box;
        border-radius: 6px;
        margin-top: 20px;
    }
    .blogMoreText{
        text-align: center;
        font-size: 10px;
        letter-spacing: 0.36px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        margin: 0px;
        font-weight: bold;
    }
    .blogDetailsCardImage {
        width: 80%;
        height: 500px; 
        border-radius: 2px 2px 0px 0px;
        opacity: 1;
        margin-bottom: 20px;
    }
    
    @media only screen and (min-width: 851px) and (max-width: 1250px) {
        padding: 30px 0px 30px 0px;
        .blogCards{
            width: 350px;
        }
    
           
    }
    @media only screen and (min-width: 551px) and (max-width: 850px) {
        padding: 30px 0px 30px 0px;
        .blogCards{
            width: 300px;
        } 
        .blogDetailsCardImage {
            width: 100%;
            height: 300px; 
        }
           
    }
    @media only screen and (min-width: 251px) and (max-width: 550px) {
        padding: 30px 40px 30px 40px;
        .blogCards{
            width: 100%;
        margin-left: 0px;

        }
        .blogDetailsCardImage {
            width: 100%;
            height: 300px; 
        }
       
           
    }
}