.rubricsAssesTabContainer {
    background: white;
    border-radius: 7px;

    .assessmentMainDiv {
        display: flex;
        border-bottom: 2px solid #dddddd;

        .assessmentHeaderDiv {
            display: flex;
            padding: 5px;
            justify-content: center;
            align-items: center;
            border-right: 2px solid #dddddd;
            flex: 0.37;

            .tableTitles {
                margin: 0px;
                color: #aaaaaa;
                font: normal normal 600 16px/22px Open Sans !important;
            }
        }

        .assessmentNumbersTitle {
            display: flex;
            flex: 0.79;
            align-items: center;
            padding: 15px 0px 15px 15px;

            .beginning {
                background: #f05f5f;
                height: 35px;
                display: flex;
                align-items: center;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                flex: 0.31;
                justify-content: center;
            }

            .developing {
                background: #ffd307;
                height: 35px;
                display: flex;
                align-items: center;
                flex: 0.31;
                justify-content: center;
            }

            .accomplished {
                background: #ff9c17;
                height: 35px;
                display: flex;
                align-items: center;
                flex: 0.22;
                justify-content: center;
            }

            .exemplary {
                background: #80ce4f;
                height: 35px;
                display: flex;
                align-items: center;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                flex: 0.22;
                justify-content: center;
            }

            .nameTexts {
                margin: 0px;
                color: white;
                font: normal normal 600 12px/22px Open Sans !important;
            }
        }

        .verticalLines {
            display: flex;
            align-items: center;

            .verticalLine {
                border-left: 2px solid #dddddd;
                height: 40px;
                display: flex;
                align-items: center;
                margin: 13px;
            }
        }

        .commentsTitle {
            flex: 0.51;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .cmtTitleText {
                margin: 0px;
                color: #aaaaaa;
                font: normal normal 600 16px/22px Open Sans !important;
            }

            .saveBtn {
                width: 100px;
                height: 28px;
                background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
                border-radius: 8px;
                margin-left: 15px;
                font: normal normal 600 12px/14px Open Sans;
                color: #ffffff;
                margin-right: 15px;
            }

            .opacityBtn {
                opacity: 0.3 !important;
                pointer-events: none;
            }
        }
    }

    .assessmentMainDataDiv {
        .mainAssessDiv {
            display: flex;
            border-bottom: 2px solid #dddddd;
            width: 100%;

            .criteriaTitle {
                border-right: 2px solid #dddddd;
                flex: 0.384;
                justify-content: center;
                align-items: center;
                display: flex;
                padding: 5px;

                .criteriaText {
                    margin: 0px;
                    color: #050505;
                    font: normal normal 600 13px/18px Open Sans;
                }
            }

            .criNumbers {
                display: block !important;
                padding: 15px 0px 15px 15px;
                align-items: center;
                display: flex;
                flex: 0.82;
                overflow: scroll;
                .numbersDiv {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .numDiv {
                        margin: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .beging {
                            background-color: #f05f5f !important;
                            color: white;
                        }

                        .develope {
                            background-color: #ffd307 !important;
                            color: white;
                        }

                        .accomplish {
                            background-color: #ff9c17 !important;
                            color: white;
                        }

                        .exemp {
                            background-color: #80ce4f !important;
                            color: white;
                        }

                        .criteriaNumbers {
                            margin: 0px;
                            font-weight: 600;
                            background: #eaeaea;
                            width: 28px;
                            height: 28px;
                            border-radius: 20px;
                            padding-top: 2px;
                            cursor: pointer;
                        }
                    }
                }

                .commentsSection {
                    margin-top: 5px;

                    .commentsSectionSpan {
                        margin-top: 5px;
                    }
                }

                .CommentDiv {
                    display: flex;

                    .commentText {
                        margin: 0px;
                        padding-top: 10px;
                        color: #777777;
                        justify-content: flex-start;
                    }
                }
            }

            .verticalLines {
                display: flex;
                align-items: center;

                .verticalLine {
                    border-left: 2px solid #dddddd;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    margin: 13px;
                }
            }

            .rationalCommentTextDiv {
                display: flex;
                padding: 10px 10px 10px 0px;
                flex: 0.5148;
                align-items: center;

                .rationalCommentText {
                    width: 80%;
                    border: none;
                    border: 1px solid #dddddd;
                    height: 40px;
                    padding-left: 10px;
                    background: #f4f6fa;
                    border-radius: 5px;
                    color: black;
                    font-size: 0.88rem;
                    font-weight: 600;
                    width: 100%;
                }

                .saveBtn {
                    width: 100px;
                    height: 28px;
                    background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
                    border-radius: 8px;
                    margin-left: 15px;
                    font: normal normal 600 12px/14px Open Sans;
                    color: #ffffff;
                }

                .opacityBtn {
                    opacity: 0.3 !important;
                }

                .saveBtn:active,
                .saveBtn:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            input::placeholder {
                font-size: 0.95rem;
                font-weight: 200;
                color: rgb(206, 198, 198);
            }

            input[type="text"]:focus,
            textarea:focus {
                outline: none;
            }
        }

        .mainAssessDiv:last-child {
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
        }
    }

    .dfltCursor {
        cursor: default !important;
    }
}

.avgScoreDiv {
    margin: 0px 25px 0px 25px;
    height: 40px;
    background: white;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .avgText {
        margin: 0px 15px;
        color: #aaaaaa;
        flex: 0.97;
        display: flex;
        font: normal normal 600 14px/22px Open Sans !important;
    }

    .avgTextScore {
        margin: 0px;
        color: #111010;
        flex: 0.18 1;
        display: flex;
        justify-content: flex-start;
        font: normal normal 600 15px/22px Open Sans !important;
        padding-left: 5px;
    }

    .saveBtn {
        margin: 0px 5px 0px 0px;
        flex: 0.09;
        width: 70px;
        height: 28px;
        background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        font: normal normal 600 12px/14px Open Sans;
        color: #ffffff;
    }

    .editScoreBtn {
        width: 100px;
        height: 28px;
        background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        margin-left: 15px;
        font: normal normal 600 12px/14px Open Sans;
        color: #ffffff !important;
    }

    .opacityBtn {
        opacity: 0.3 !important;
        cursor: default !important;
    }
}

.rubrics-scores-input-popover {
    left: 60px !important;
    top: -62px !important;
    min-height: 78px !important;
    border-radius: 0 !important;
    padding: 5px !important;
    box-shadow: 0px 0px 15px #22222233;
    border: white !important;
    width: 240px !important;

    .popover-body {
        min-height: 68px !important;

        .closeIcon {
            float: right;
            cursor: pointer;
        }

        .input-box {
            width: 80px;
            height: 38px;
            background: #f4f6fa 0% 0% no-repeat padding-box;
            border: 0.75px solid #cccccc;
            border-radius: 5px;
            float: left;
            margin-top: 15px;
            margin-left: 0px;
            text-align: center;
        }

        .addCommentBtn {
            margin-top: 14px;
        }

        .addCommentBtn:active,
        .addCommentBtn:focus {
            outline: none;
            box-shadow: none;
        }

        .saveBtn {
            width: 70px;
            height: 28px;
            background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
            border-radius: 8px;
            margin-top: 5px;
            margin-left: 63px;
            font: normal normal 600 12px/14px Open Sans;
            color: #ffffff;
        }

        .saveBtnWhenComment {
            width: 70px;
            height: 28px;
            background: transparent linear-gradient(90deg, #669fec 0%, #3e72bc 100%) 0% 0% no-repeat padding-box;
            border-radius: 8px;
            margin-top: 20px;
            margin-left: 30px;
            font: normal normal 600 12px/14px Open Sans;
            color: #ffffff;
        }

        .saveBtn:active,
        .saveBtn:focus {
            outline: none;
            box-shadow: none;
        }

        .score-color-popover-positioned {
            margin-top: -25px;
            margin-left: 15px;

            .arrow {
                display: block !important;
                left: 77px !important;
            }
        }

        textarea {
            width: 200px;
            margin: auto;
            margin-bottom: 15px;
            border-radius: 5px;
            background: #f4f6fa 0% 0% no-repeat padding-box;
            border: 0.75px solid #cccccc;
            margin-top: 15px;
            font: normal normal normal 12px/17px Open Sans;
            color: #222222;
        }
    }
}