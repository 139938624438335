.assessmentMainContainer {
    background: #f0f0f0;
    height: 100%;
    padding: 0rem 3rem 0rem 3rem;

    a:focus,
    .btn:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .left-section {
        padding-right: 0.4375rem !important;

        .back-btn {
            background-color: #eeeeee !important;
            border-radius: 0.25rem;
            width: 100%;
            text-align: left !important;
            color: #222222 !important;
            border: none !important;
            font-weight: 600 !important;
            font: normal normal 600 1rem/1.375rem Open Sans;
            height: 3.125rem;

            .fa-chevron-left {
                margin-right: 0.625rem;
            }
        }
        .action-btn {
            border-radius: 0.5rem;
            border: none !important;
            width: 100%;
            text-align: center;
            font: normal normal 600 0.875rem/1.188rem Open Sans !important;
            letter-spacing: 0;
            color: #ffffff;
            min-height: 2.813rem;

            .noteOptionIcons {
                width: 1.2rem;
                height: 1.2rem;
                margin-right: 0.55rem;
            }

            @media only screen and (max-width: 1500px) {
                .noteOptionIcons {
                    width: 1.125rem;
                    height: 1.125rem;
                    margin-right: 0.5rem;
                }
            }

            @media only screen and (max-width: 1220px) {
                .noteOptionIcons {
                    width: 1.375rem;
                    height: 1.375rem;
                    margin-right: 0.3125rem;
                }
            }

            @media only screen and (max-width: 767px) {
                .noteOptionIcons {
                    margin-right: 0.75rem;
                }
            }
        }
        .class-btn {
            background: transparent linear-gradient(270deg, #ffb239 0%, #ffc76e 100%) 0% 0% no-repeat padding-box;
        }
        .gradebook-btn {
            background: transparent linear-gradient(270deg, #08b596 0%, #27d3b4 100%) 0% 0% no-repeat padding-box;
        }
        .attchement-card {
            font: normal normal 600 16px/22px Open Sans;
            color: #a7a7a7;
            text-align: left;
            margin: 0px !important;
        }
        .tagsTitle {
            padding: 16px 0px 16px 16px;
            border-bottom: 1px solid #f4f4f4;
            .cardTitle {
                .attchement-card {
                    font: normal normal 600 16px/22px Open Sans;
                    color: #a7a7a7;
                    text-align: left;
                    margin: 0px !important;
                }
            }
        }

        @media only screen and (max-width: 1500px) {
            .gradebook-btn {
                font: normal normal 600 0.8125rem/1.125rem Open Sans !important;
                padding: 0.375rem !important;
            }
        }

        @media only screen and (max-width: 767px) {
            .class-btn,
            .gradebook-btn {
                min-height: 2.813rem !important;
            }
        }

        .single-attchement {
            text-align: left;
            padding: 15px;
            border-bottom: 1px solid #f4f4f4;
            .title {
                font: normal normal 600 0.875rem/1.188rem Open Sans !important;
                color: #222222;
                margin: 0px;
            }

            .action {
                float: right;

                .img {
                    width: 0.875rem;
                    height: 0.875rem;
                }
                .edit-btn {
                    padding: 7px 9px;
                    background: #ffffff !important;
                    border: none !important;
                    font: normal normal normal 0.75rem/1.125rem Open Sans !important;
                    color: #222 !important;
                    margin-right: 10px;
                    .img {
                        width: 0.875rem;
                        height: 0.875rem;
                    }
                }
                .trash-btn {
                    padding: 7px 9px;
                    background: #ffffff !important;
                    border: none !important;
                    font: normal normal normal 0.75rem/1.125rem Open Sans !important;
                    color: #ff4444 !important;
                    .img {
                        width: 0.875rem;
                        height: 0.98rem;
                    }
                }
            }
        }
    }

    .right-section {
        padding-left: 0.4375rem !important;
        .header {
            height: 4.7rem;

            .card-body {
                padding: 17px !important;
                justify-content: space-between;
            }

            .title {
                text-align: left;
                font: normal normal 600 1rem/1.375rem Open Sans;
                color: #222222;
                margin: auto;
            }

            .actionBtn {
                width: 100px;
                height: 40px;
                border-radius: 8px;
                padding: 0;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    margin-top: -2px;
                }
            }

            .cancel {
                background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
            }

            .save {
                background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
            }

            @media only screen and (max-width: 991px) {
                .card-body {
                    padding: 12px !important;
                }
            }
        }

        @media only screen and (max-width: 991px) {
            .header {
                height: unset;
            }
        }

        .sub-header {
            min-height: 3.75rem;

            .card-body {
                padding: 12px;
            }

            .title {
                display: flex;
                text-align: left;
                font: normal normal normal 14px/19px Open Sans;
                color: #222222;
                margin-left: 10px !important;
                //css for date picker
                .react-datepicker-wrapper {
                    margin-top: 9px !important;
                }

                .react-datepicker__header {
                    background-color: #ffffff;
                }

                .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
                    border-bottom-color: #ffffff;
                }
                //css for date picker

                .calendarIcon {
                    margin: auto 0;
                }

                .date {
                    margin: auto 0;
                    margin-right: 18px;
                }

                .calendarIcon {
                    margin-right: 10px;
                }

                .thumbIcon {
                    position: absolute;
                    right: 7%;
                    top: 8px;
                }

                .input {
                    width: 86%;
                    height: 40px;
                    background: #f4f6fa 0% 0% no-repeat padding-box;
                    border: 0.75px solid #dddddd;
                    border-radius: 5px;
                    font: normal normal normal 13px/18px Open Sans;
                    color: #bbbbbb;
                    margin: auto 0;
                    margin-right: 10px;
                }

                @media only screen and (max-width: 1220px) {
                    .input {
                        width: 80%;
                    }
                }

                @media only screen and (max-width: 991px) {
                    .input {
                        width: 70%;
                    }
                    .thumbIcon {
                        right: 14%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 520px) {
        .left-section {
            padding: 0 !important;
        }
        .right-section {
            padding: 0 !important;
        }
    }

    @media only screen and (max-width: 767px) {
        .left-section {
            padding: 0.9375rem !important;
        }
        .right-section {
            padding: 0.9375rem !important;
        }
    }
}

@media only screen and (max-width: 1300px) {
    .assessmentMainContainer .card-body {
        padding: 1rem;
    }
}

@media only screen and (max-width: 1220px) {
    .assessmentMainContainer {
        padding: 0rem 1rem;
    }
}
