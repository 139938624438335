body {
    background-image: linear-gradient(to bottom, #ecf3fc, #fafafa);
}

.classDashBoardContainer {
    background: #f0f0f0;
    height: 100%;
    // overflow-x: hidden;
    padding: 0rem 2.5rem 1rem 2.5rem;
}

.textLeft {
    text-align: left;
    // width: 337px;
}

.textRight {
    text-align: right;
}

.addClassDashboardImage {
    width: 5rem;
    cursor: pointer;
}
.noClass-CD {
    height: 100vh;
    // height: 300px;
}
.loadMoreClass {
    width: 9rem !important;
    height: 2.8rem !important;
    border-radius: 8px !important;
    padding: 0.6rem !important;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0.1rem 1rem 0.1rem 1rem;
}

.LoadClassButtonText {
    color: white;
    font-size: 1rem;
    margin-left: 0.5rem;
    font-weight: 600;
}

.loaderIconLoadMoreClass {
    width: 3.5rem;
}

.display-inline {
    display: flex;
}

.vertical-center-class {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* ----------Class Year Div--------- */
.academicBackDiv {
    display: flex;
    padding: 0.8rem;
    background: #eeeeee;
    width: 19rem;
    height: 3.1rem;
    cursor: pointer;
}

.classYearMainDiv {
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
}

.classYearText {
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.addNewClass {
    width: 7rem !important;
    height: 2.5rem !important;
    border-radius: 2rem !important;
    padding: 0.6rem !important;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0.1rem 0.2rem 0.1rem 0.2rem;
}

.addNewClassIcon {
    width: 0.9rem;
    margin-top: 0.2rem;
}

.buttonClassText {
    color: white;
    font-size: 0.9rem;
    margin-left: 0.5rem;
}

.tapandHoldText {
    font: italic normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #aaaaaa;
    opacity: 1;
    margin: 1rem;
}

/* ----------Class Year Div--------- */

/* ----------Total Class Div--------- */
.totalClassMainDiv {
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, .125)
}

.searchClassFilter-RD {
    margin-left: 20px;
}

.w-120 {
    width: 120px !important
}

.totalClassDiv {
    width: 13rem;
    text-align: left;
    position: relative;
}

.classFilterDiv {
    opacity: 0.4;
}

.totalClassText {
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    width: 10rem;
}

.listAllClassFilter {
    width: 5.3rem;
    height: 1.75rem;
    border: 0.5px solid #222222;
    border-radius: 16px;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.listAllClassFilterText {
    font-size: 0.8rem;
    color: #000;
    padding: 1rem 0rem 0rem 0.1rem;
}

.myClassFilterDiv {
    width: 6.8rem;
    height: 1.75rem;
    border: 0.5px solid #222222;
    border-radius: 16px;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkBoxClassfilter {
    color: #386cb5;
    cursor: pointer;
    font-size: 21px;
    margin-right: 0.2rem;
}

.unCheckBoxClassfilter {
    color: #000;
    cursor: pointer;
    font-size: 21px;
    margin-right: 0.2rem;
}

.sharedClassDiv {
    width: 8.3rem;
    height: 1.75rem;
    border: 0.5px solid #222222;
    border-radius: 16px;
    margin: 0.3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ----------Total Class Div--------- */

.classCardMainDiv {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
}

.classCardDiv {
    width: 19.95rem;
    // height: 16.3rem;
    margin: 0rem 0.4rem 0.8rem 0.4rem;
    /* min-width: 335px !important;  */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.2rem 0.5rem #0000001a;
    border-radius: 0.5rem;
    opacity: 1;
    /* float: left;  */
    padding: 0.1rem 0.5rem 0.1rem 0.4rem;
    height: 100px;
}
.classListingCardDiv:hover {
    .iconhoverListing {
        display: contents;
        color: #000;
        cursor: pointer !important;
        // padding-bottom: 12px;
    }   
}

/* ---------Class Details Cards------------ */
.classDetailsCard {
    width: 100%;
    // border-bottom: 1px solid #dddddd;
    height: 6.5rem;
    justify-content: space-between;
    padding: 0.4rem 0.2rem 0rem 0.2rem;
    justify-content: space-between;
    display: flex;
}

.calssDetailsCardBorderBottomNone {
    border-bottom: none;
    height: auto;
}

.className {
    margin-bottom: 0.6rem;
    font-weight: 600;
    /* width: 10rem; */
    color: #386cb5;
    overflow: hidden;
    text-overflow: ellipsis;
}

.classGradeAndSectionText {
    color: #888888;
    margin: 0rem;
    font-size: 0.75rem;
}

.classViewOnlyDiv {
    width: 4rem;
    height: 1.2rem;
    border: 0.5px solid #4479c4;
    border-radius: 16px;
    margin: 0.2rem 0rem 0rem 0rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background: #588fdc1a 0% 0% no-repeat padding-box;
}

.classSharedViewOnlyDiv {
    width: 8rem;
    height: 1.2rem;
    border: 0.5px solid #4479c4;
    border-radius: 16px;
    margin: 0.2rem 0rem 0rem 0rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background: #588fdc1a 0% 0% no-repeat padding-box;
    position: relative;
    left: 9.5rem;
    top: -4.5rem;
}

.classViewOnlyText {
    margin-top: 0.1rem;
    font-size: 0.6rem;
    color: #4479c4;
}

.classMenuIcon {
    font-size: 1.5rem;
    cursor: pointer;
}

.groupActiveIcon {
    width: 1.5rem;
    cursor: pointer;
}

.disabledGroupActiveIcon {
    width: 1.5rem;
    cursor: default;
    opacity: 0.7;
}

/* ---------Class Details Cards------------ */

/* ---------Class options Cards------------ */
.classAllOptionDiv {
    padding: 0.4rem 0rem 0rem 0rem;

    // display:inline-flex
    .optionDivs {
        display: flex;
        padding: 4px 0px 5px;
        justify-content: space-between;
    }
}

.classOptionRoster {
    width: 5.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #4F84EA 0%, #82A9F4 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
}

.opacityProp {
    opacity: 0.5;
    cursor: default;
}

.classOptionStandard {
    width: 5.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #747beb 0%, #989eff 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
}

// .disableClassOptionStandard {
//     width: 5.9rem;
//     height: 3.7rem;
//     background: transparent linear-gradient(270deg, #747beb 0%, #989eff 100%) 0% 0% no-repeat padding-box;
//     border-radius: 8px;
//     margin: 0rem 0.1rem 0rem 0.1rem;
//     padding-top: 0.6rem;
//     cursor: default;
//     opacity: 0.5;
// }

.classOptionLesson {
    width: 5.9rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #F48329 0%, #F99A2A 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
}

// .disableClassOptionLesson {
//     width: 5.9rem;
//     height: 3.7rem;
//     background: transparent linear-gradient(270deg, #08b596 0%, #27d3b4 100%) 0% 0% no-repeat padding-box;
//     border-radius: 8px;
//     margin: 0rem 0rem 0rem 0.1rem;
//     padding-top: 0.6rem;
//     cursor: default;
//     opacity: 0.5;
// }

.classOptionTag {
    width: 4.1rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #FFBB02 0%, #FFD650 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
}

.classOptionassesment {
    width: 4.1rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #FFBC53 0%, #FFD650 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
}


.classOptionShare {
    width: 4.1rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #4479c4 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
}

.classOptionAtt {
    width: 4.5rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #ad54d1 0%, #cb7aeb 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
}

// .disableClassOptionAtt {
//     width: 4.5rem;
//     height: 3.7rem;
//     background: transparent linear-gradient(270deg, #ad54d1 0%, #cb7aeb 100%) 0% 0% no-repeat padding-box;
//     border-radius: 8px;
//     margin: 0rem 0.1rem 0rem 0.1rem;
//     padding-top: 0.6rem;
//     cursor: default;
//     opacity: 0.5;
// }

.classOptionRubrics {
    width: 4.5rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #C94276 0%, #DD5D93 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: pointer;
}

// .disableClassOptionRubrics {
//     width: 4.5rem;
//     height: 3.7rem;
//     background: transparent linear-gradient(270deg, #C94276 0%, #DD5D93 100%) 0% 0% no-repeat padding-box;
//     border-radius: 8px;
//     margin: 0rem 0.1rem 0rem 0.1rem;
//     padding-top: 0.6rem;
//     cursor: default;
//     opacity: 0.5;
// }

.disabledClassOptionShare {
    width: 4.1rem;
    height: 3.7rem;
    background: transparent linear-gradient(270deg, #4479c4 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0rem 0.1rem 0rem 0.1rem;
    padding-top: 0.6rem;
    cursor: default;
    opacity: 0.5;
}

.classOptionIcons {
    width: 1.2rem;
}

.classOptionText {
    color: white;
    font-size: 0.7rem;
    font-weight: 600;
    margin-top: 0.3rem;
}

.classOptionStandardIcons {
    width: 2.1rem;
}

/* ---------Class options Cards------------ */
.popoverClassDelete {

    /* ----------------PopOver------------------- */
    .popoverOptions {
        display: inline-flex;
        border-bottom: 1px solid gray;
        padding: 0.5rem;
    }

    /* ----------- PopOver---------- */

    /* ----------------PopOver------------------- */
    .editClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        border-bottom: 1px solid #dddddd;
        padding: 0rem 0rem 0.5rem 0rem;
        width: 90%;
    }

    .disabledEditClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        border-bottom: 1px solid #dddddd;
        padding: 0rem 0rem 0.5rem 0rem;
        width: 90%;
        opacity: 0.5;
        cursor: default;
    }

    .bs-popover-auto[x-placement^="left"]>.arrow::after,
    .bs-popover-left>.arrow::after {
        border-left-color: #fff !important;
    }

    .editTextClassPopOver {
        font-size: 0.9rem;
        margin-left: 0.1rem;
    }

    .deleteClassPopOver {
        display: inline-flex;
        color: #ff4444;
        cursor: pointer;
        padding: 0.5rem 0rem 0rem 0rem;
    }

    .disabledDeleteClassPopOver {
        display: inline-flex;
        color: #ff4444;
        cursor: pointer;
        padding: 0.5rem 0rem 0rem 0rem;
        opacity: 0.5;
        cursor: default;
    }

    .deleteTextClassPopOver {
        font-size: 0.9rem;
        margin-left: 0.1rem;
    }

    .editClassIcon {
        font-size: 1.3rem;
    }

    .popover {
        width: 6rem !important;
        padding: 0rem !important;
        height: 5rem;
        border-radius: 11px !important;
    }
}

/* ----------------PopOver------------------- */

/* ---------Student Delete options Cards------------ */
.deleteClassCard {
    width: 18rem;
    height: 9.5rem;
    margin: 0rem 0.8rem 0.8rem 0rem;
    background: #000 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.2rem 0.5rem #0000001a;
    border-radius: 0.5rem;
    float: left;
    padding: 1rem 1rem 1rem 1rem;
    text-align: -webkit-center;
    /* position: absolute; */
    opacity: 0.9;
}

.deleteClassText {
    color: #ffffff;
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.deleteWorningClassText {
    color: #ffffff;
    font-weight: 600;
    font-size: 0.7rem;
}

.deleteButtonsClassDiv {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0rem 3rem 0rem 3rem;
}

.cancelButtonClassText {
    color: #ffffff;
    font-weight: 600;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    cursor: pointer;
}

.deleteButtonClass {
    width: 5rem !important;
    height: 2.3rem !important;
    border-radius: 2rem !important;
    background: transparent linear-gradient(270deg, #ec4444 0%, #fa6060 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221a !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 0.6rem !important;
    margin: 0.1rem 0rem 0.1rem 0rem;
    border-color: #ec4444 !important;
}

.deleteButtonClassText {
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
}

/* ---------Student Delete options Cards------------ */

/* --------------Add New Student Modal--------- */
.addOfClass {
    .modal-content {
        border-radius: 0;
        border: none;
    }

    .modal-title {
        font-size: 13px;
        font-weight: 600;
    }

    .modal-header {
        border-bottom-color: #eeeeee !important;
        background-color: #ffff !important;
        height: 50px !important;
    }

    .modal-header .close {
        padding: 0.3rem 1rem !important;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        margin-top: -6px;
        outline: none !important;
    }

    .cancelClassText {
        font-size: 0.8rem !important;
        // opacity: 1 !important;
        position: relative;
        bottom: 7px;
        color: #000 !important;
        background: #EEEEEE 0% 0% no-repeat padding-box;
    }

    .closeIconInClassModal {
        width: 0.6rem;
        margin-top: -0.2rem;
    }

    .allInputClassMainDiv {
        // padding: 1rem 2rem 1rem 2rem;
        padding: 1rem;
    }

    .classAddInputDiv {
        width: 100%;
        // margin: 1.5rem 1rem 0rem 0rem;
    }

    .allInputClassMainDiv .MuiOutlinedInput-input {
        height: 32px !important;
        padding-top: 7px;
        padding-bottom: 7px;
        font: normal normal 600 14px/20px Open Sans;
        color: #222222;
        margin-bottom: 0px !important;
    }

    .classInputLableInfo {
        color: #000;
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 0rem;
        text-align: left;
    }

    .classAddInput {
        // border: 0.75px solid #dddddd;
        border:0.5px solid #eee;
        box-sizing: border-box;
        border-radius: 0.3rem;
        /* width: 38rem; */
        height: 3rem;
        // padding-left: .5rem;
        background: #f4f6fa;
        width: 100%;
    }

    .modalButtonClassPage {
        width: 100%;
        background: #ffff;
        // padding: 1rem 0rem 1rem 0rem;
        margin-top: 1rem;
    }

    .saveButtonClass {
        // width: 6.3rem !important;
        // height: 2.3rem !important;
        // border-radius: 2rem !important;
        width: 80px;
        height: 40px;
        border-radius: 8px;
        background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221a !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: 0.6rem !important;
        margin: 0.1rem 0rem 0.1rem 0rem;
    }

    .saveTextClass {
        color: white;
        font-size: 1rem;
    }
}

/* --------------Add New Student Modal--------- */

@media only screen and (min-width: 490px) and (max-width: 790px) {
    .tapandHoldText {
        display: none;
    }
}

@media only screen and (min-width: 250px) and (max-width: 490px) {
    .classDashBoardContainer {
        padding: 0rem 0rem 0rem 0rem;
    }

    .classYearText {
        margin: 0px;
        font-size: 0.8rem;
    }

    .academicBackDiv {
        width: 10rem;
    }

    .academicBackIcon {
        font-size: 1.3rem;
    }

    .tapandHoldText {
        display: none;
    }

    .totalClassMainDiv {
        padding: 1rem 0rem 1rem 0.5rem;
    }

    .totalClassText {
        font-size: 0.8rem;
    }
}

.schltchraddclass {
    display: none !important;
}

.schltchrclassOption {
    width: 5.9rem;
}


.cmncls {
    width: 4.5rem !important;
    padding-left: 0px !important;
}

.editcls {
    background: white;
    border: 1px solid #386CB5;
    
}
.editclstext {
    color: #386CB5;
}



.iconhoverListing:hover {
    // visibility: visible !important;
    // display: block !important;
    // color: #000 !important;
    // cursor: pointer !important;
    // padding-bottom: 0!important;
}
.iconhoverListing:target {
    // visibility: visible !important;
    display: block !important;
    color: #000 !important;
    cursor: pointer !important;
}
.iconhoverListing {
    
    color: white;
    padding-bottom: 0;
}

.schltchrcls {
    display: none !important;
}


.rst-total-student-RDD {
    width: 148px;
    text-align: left;
    float: left !important;
    padding-top: 8px;
    border-right: 2px solid rgba(0, 0, 0, 0.25);
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-222222);
    text-align: left;
    font: normal normal 600 15px/22px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 0.4;
}
@media screen and (min-width: 1275px) and (max-width: 1470px) {

    .rst-total-student-RDD {
        width: 260px;
    }

}


@media screen and (min-width: 1536px) and (max-width: 1745.45px) {
    .rst-total-student-RDD {
        width: 213px;
    }


}

@media only screen and (min-width: 1275px) and (max-width: 1350px) {


    .rst-total-student-RDD {
        width: 345px;
    }



}

@media only screen and (min-width: 1351px) and (max-width: 1469px) {
    .rst-total-student-RDD {
        width: 161px;
    }


}
@media screen and (min-width: 1470px) and (max-width: 1536px) {

    .rst-total-student-RDD {
        width: 223px;
    }


}