.vertical-timeline-element {
  margin: 1.5em 0;
}

.vertical-timeline {
  width: 100% !important;
  max-width: unset;
  white-space: nowrap;
}

.vertical-timeline::before {
  width: 0.125rem;
  top: -0.625rem;
  background: #386CB5 !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: -0.4375rem;
  box-shadow: none;
  margin-top: 1.5rem;
}

.vertical-timeline--two-columns .vertical-timeline-element-content {
  width: 48%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0 0.0625rem 0.25rem #00000029;
  // min-height: 7.5rem;
  padding: 0.625rem 0.9375rem;
  padding-bottom: 0.4rem;
}
.studentnotetimelinemodalContent {
  .modal-content {
    width: 460px!important;
  }
}
.createNewNoteBtn {
  background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box!important;
  &:hover {
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box!important;
  }
}

.createNewNoteBtnGotIt {
  &:hover {
    background-color: #fff!important;
  }
}
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  left: 106%;
  text-align: left;
  font: normal normal normal 0.875rem/1.188rem Open Sans;
  letter-spacing: 0;
  color: #386CB5;
  font-weight: 600 !important;
  top: 0.625rem;
}

.vertical-timeline--two-columns .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
  right: 107%;
}

.historyIcon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 4px;
}

.timeline-time {
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  letter-spacing: 0;
  color: #BD47E8;
  margin-right: 0.5rem;
}

.no-reminder {
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  letter-spacing: 0;
  color: #AAAAAA;
  margin-right: 0.5rem;
}

.greyButton {
  margin-left: 0px !important;
}

.timeline-section .fa-star-o {
  font-size: 1.125rem;
}

.timeline-section .tag {
  width: 2.5rem;
  height: 1.625rem;
  background: transparent linear-gradient(180deg, #FFE996 0%, #F8DB6E 100%) 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  margin: 0.625rem 0.5rem 0 0;
  display: inline-block;
  text-align: center;
}

.timeline-section .tag .tag-text {
  text-align: center;
  font: normal normal normal 0.8125rem/1.125rem Open Sans !important;
  letter-spacing: 0;
  color: #222222;
  padding: 0.4375rem;
  text-transform: uppercase;
}

.timeline-section .attached-tag {
  width: 1.875rem;
  height: 1.625rem;
  background: #DDDDDD 0% 0% no-repeat padding-box;
  ;
  border-radius: 0.25rem;
  margin: 0.625rem 0.5rem 0 0;
  display: inline-block;
  text-align: center;
}

.level-text-b {
  // font: normal normal bold 0.8125rem/1.125rem Open Sans;
  // color: #222222;
  font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #222222;
  white-space: break-spaces;
  text-align: left;
  padding-right: 10px;
}

.level-text-n {
  font: normal normal normal 0.8125rem/1.125rem Open Sans;
  color: #222222;
}

.sort-timeline-section .vertical-divider {
  border-left: 0.0625rem solid #CCC;
  margin: 0 0.5rem;
  height: 1.375rem !important;
  display: inline-block;
  margin-top: 0.3125rem;
}

.divider hr {
  border: 0.0625rem solid #DDDDDD;
  width: 100%;
  margin-top: .7rem;
  margin-bottom: 0.125rem;
  margin-bottom: 0.5rem
}

.level-score-section {
  display: flex;
  line-height: 1.813rem;
}

.timeline-btn-img {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.3rem;
  margin-top: -3px;
  color: #ec9191 !important;
}

.disabledEditTimeline-btn-img {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.3rem;
  margin-top: -3px;
  color: #ec9191 !important;
  filter: brightness(4.5);
}

.edit-btn {
  background: transparent !important;
  border: none !important;
  font: normal normal normal 0.75rem/1.125rem Open Sans !important;
  color: #222 !important;
  padding: 6px 0px !important;

}
.edit-btn-text {
  text-transform: capitalize !important;
}

.disabledEdit-btn {
  cursor: default !important;
  background: transparent !important;
  border: none !important;
  font: normal normal normal 0.75rem/1.125rem Open Sans !important;
  color: #a79c9c !important;
}

.trash-btn {
  background: transparent !important;
  border: none !important;
  font: normal normal normal 0.75rem/1.125rem Open Sans !important;
  color: #FF4444 !important;
  padding: 6px 0px !important;
}

.disabledTrash-btn {
  cursor: default !important;
  background: transparent !important;
  border: none !important;
  font: normal normal normal 0.75rem/1.125rem Open Sans !important;
  color: #FF4444 !important;
  filter: saturate(551%) contrast(347%) brightness(100%) invert(138%) sepia(50%) hue-rotate(130deg);
}

.add-mili-note-btn {
  width: 10.63rem;
  height: 2.125rem;
  color: #fff !important;
  background: #386bb5 0% 0% no-repeat padding-box !important;
  border-radius: 0.3125rem;
  opacity: 1;
  // font: normal normal bold 0.75rem/1.063rem Open Sans !important;
}
.add-mili-note-btn-font {
  font-size: 13px!important;
  font-weight: normal !important;
}

.disabledAdd-mili-note-btn {
  width: 10.63rem;
  height: 2.125rem;
  cursor: default !important;
  background: transparent linear-gradient(270deg, #aec7ea 0%, #b6cae6 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 0.3125rem;
  opacity: 1;
  font: normal normal bold 0.75rem/1.063rem Open Sans !important;
  color: #fff!important;
}


.delete-modal-warning .modal-title {
  text-align: center;
  font: normal normal 600 18px/24px Open Sans;
}

.delete-modal-warning .modal-content {
  border-radius: 8px;
  width: 360px;
  margin: auto;
  background-color: #fff !important;
}

.delete-modal-warning .modal-content .modal-header {
  justify-content: center;
  border: none;
  border-radius: 8px;
}

.delete-modal-warning .modal-content .modal-body {
  text-align: center;
  font: normal normal 600 14px/19px Open Sans;
}

.delete-modal-warning .modal-content .modal-body .confirm-text {
  font: normal normal normal 14px/19px Open Sans;
  margin-bottom: 0;
}

.delete-modal-warning .modal-footer {
  border: none;
  justify-content: center;
  padding-top: 0;
}

.delete-modal-warning .cancel-btn {
  width: 101px;
  height: 38px;
  font: normal normal 600 14px/19px Open Sans;
}

.delete-modal-warning .delete-btn {
  background: transparent linear-gradient(270deg, #EC4444 0%, #FA6060 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font: normal normal 600 14px/19px Open Sans;
  color: #FFFFFF;
  width: 101px;
  height: 38px;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  background: #386CB5
}

.vertical-timeline-element-icon {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.disabledStarSelected {
  cursor: default !important;
  color: gold;
  filter: brightness(4.5)
}

.disabledStarUnSelected {
  cursor: default !important;
  color: #AAAAAA;
  filter: brightness(4.5)
}

.starSelected {
  cursor: pointer;
  color: #ffa700;
}

.starUnSelected {
  cursor: pointer;
  color: #AAAAAA;
  font-size: larger !important;
}

.load-more .add-mili-note-btn {
  display: flex;
  color: #fff !important;
  font-size: 0.8rem;
  align-items: center;
  position: relative;
  bottom: 11px;
}
.timeline-custom-scrollbarWidth {
  overflow-x: scroll;
  overflow-y: hidden;
  // &:hover {
  //   // overflow-y: scroll;
    
  //   white-space: nowrap;
  //   ::-webkit-scrollbar {
  //      width: 0px;
  //      height: 0px;
  //      border-radius: 2px !important;
  //      display: none !important;

  //   }
  }
.timeline-custom-scrollbarWidth::-webkit-scrollbar {
    display: none !important;
}
//   &:hover {
//     margin-right: 20px;
//     overflow-x: scroll;
//     overflow-y: hidden;
//     width: 100%;
//   }
//   ::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
//     border-radius: 2px !important;
//     display: none !important;

//  }
// }
.add-load-more-short {
  display: inline-flex !important;
  width: 122px !important;
  margin-top: -14px;
  margin-left: 4px;
}

.add-load-more-short i {
  margin-top: -2px;
  margin-right: 6px;
}

.add-load-more-short span {
  line-height: 20px;
}

.vertical-timeline.vertical-timeline--two-columns {
  margin-top: 0.625rem;
}

.GroupText {
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  letter-spacing: 0;
  color: #FF6A6A;
  margin-right: 0.5rem;
}

.redButton {
  margin-right: 10px !important;
}

@media only screen and (max-width: 1169px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-icon {
    margin-left: 3.75rem;
    margin-top: 1.063rem;
  }

  .sort-timeline-div .vertical-timeline-element-content {
    margin-left: 5.5rem !important;
  }

  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 89%;
  }

  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -5.438rem;
    position: absolute;
    white-space: normal;
    width: 3.125rem;
    text-align: right;
    margin-top: 0.625rem;
  }

  .sort-miscellaneous-div {
    text-align: left;
  }
}

@media only screen and (max-width: 1075px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 88%;
  }

}

@media only screen and (max-width: 1020px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 86%;
  }

  .sort-timeline-div .vertical-timeline::before {
    left: 4.063rem;
    top: 0rem !important;
  }
}

@media only screen and (max-width: 991px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 84%;
  }
}

@media only screen and (max-width: 900px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 82%;
  }
}

@media only screen and (max-width: 835px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 81%;
  }
}

@media only screen and (max-width: 767px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 86%;
  }

  .sort-timeline-div .vertical-timeline::before {
    left: 4.063rem;
    top: 0rem !important;
  }

  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-icon {
    margin-left: 1.563rem;
    margin-top: 1.063rem;
    left: 2.188rem;
  }
}

@media only screen and (max-width: 700px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 84%;
  }
}

@media only screen and (max-width: 592px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 81%;
  }

  .sort-timeline-div .vertical-timeline-element-content {
    margin-left: 5.313rem !important;
  }
}

@media only screen and (max-width: 510px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 78%;
  }
}

@media only screen and (max-width: 465px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 75%;
  }
}

@media only screen and (max-width: 410px) {
  .sort-timeline-div .vertical-timeline--two-columns .vertical-timeline-element-content {
    width: 74%;
  }
}

.grpnewicon{
  white-space: nowrap;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 75px;
    height: 30px;
    
    
    
    border: 0.75px solid var(--unnamed-color-ff6a6a);
    background: #FF6A6A1A 0% 0% no-repeat padding-box;
    border: 0.75px solid #FF6A6A;
    border-radius: 8px;
    opacity: 1;
   margin-right: 8px;

    p {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-ff6a6a);
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #FF6A6A;
opacity: 1;
    }
}

