.attendance-select-class-main-container {
    background: #f0f0f0;
    height: 100%;
    overflow-x: hidden;
    padding: 0rem 2.5rem 1rem 2.5rem;

    .header-main-container {
        width: 100%;
        height: 80px;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        border-radius: 0px 0px 8px 8px;

        .header-breadcrumb-main-container {
            display: flex;
            align-items: center;
            width: 30%;

            .header-breadcrumb-back-btn-div {
                display: flex;
                background: transparent;
                cursor: pointer;
                align-items: center;

                .header-breadcrumb-back-btn-img {
                    width: 60px;
                    height: 40px;
                    align-self: center;
                    cursor: pointer;
                    // cursor: not-allowed;
                    // margin-bottom: 10px;
                }
            }

            .header-breadcrumb-inner-div {
                margin-left: 1rem;
                border-left: 1px solid #eeeeee;
                padding: 0.3rem 0rem 0rem 1rem;

                .breadcrumb-inner-flex-div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: default;
                    width: max-content;
                    padding-top: 9px;

                    .breadcrumb-academic-year-text {
                        color: #487FCC;
                        margin-bottom: 15px;
                        margin-right: 10px;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        cursor: pointer;
                    }

                    .breadcrumb-arrow-icon {
                        color: #487FCC;
                        margin-bottom: 15px;
                        margin-left: -8px;
                    }

                    .breadcrumb-attendance-text {
                        color: #222222;
                        font-size: 1.25rem;
                        font-weight: 600;
                        text-align: left;
                        cursor: default;
                    }
                }

            }
        }

        .header-dropdown-main-container {
            width: 30%;

            .dropdown-search-icon {
                width: 14px;
                height: 14px;
                align-self: center;
                position: absolute;
                top: 10px !important;
                right: 27px !important;
                z-index: 2 !important;
            }

            .dropdown-main-container {
                // font-family: sans-serif;
                // text-align: center;
                // width: 50px;
                // height: 50px;
                // position: relative;
                // top: 8px;
                // left: 50px;
                font-family: sans-serif;
                text-align: center;
                position: relative;
                flex: 0.6;
                top: 0px !important;
                left: 0px !important;

                /**
               * Main wrapper
               */
               .iconDiv {
                    position: absolute;
                    left: 9px;
                    z-index: 2 !important;
                    top: 3px;
                    cursor: pointer;

                    .searchFiltersIcon {
                        cursor: pointer;
                        color: #aaaaaa;
                        margin-right: 10px;
                    }
                }

               .select-search {
                width: 240px !important;
                position: relative;
                font-family: "Nunito Sans", sans-serif;
                box-sizing: border-box;
                bottom: 5px !important;
                cursor: pointer;
            }

            .select-search *,
            .select-search *::after,
            .select-search *::before {
                box-sizing: inherit;
            }

            .select-search__value {
                position: relative;
            }

            .select-search__value::after {
                content: "";
                display: inline-block;
                position: absolute;
                top: calc(50% - 9px);
                right: 19px;
                width: 11px;
                height: 11px;
                cursor: pointer;
            }

            .select-search__input {
                margin-top: 10px;
                display: block;
                height: 40px;
                width: 240px;
                padding: 0 40px 0 30px;
                background: #fff;
                border: 1px solid #dddddd;
                box-shadow: 0px 0px 3px #88c3fae3;
                border-radius: 8px;
                outline: none !important;
                font-family: "Noto Sans", sans-serif;
                font-size: 14px;
                color: rgba(56, 108, 181, 1);
                text-align: left;
                text-overflow: ellipsis;
                line-height: 36px;
                -webkit-appearance: none;
                opacity: 1;
            }

            .select-search__input::placeholder {
                font: italic normal normal 13px/18px Open Sans;
                letter-spacing: 0px;
                color: #c6b6b6 !important;
            }

            .select-search__input::-webkit-search-decoration,
            .select-search__input::-webkit-search-cancel-button,
            .select-search__input::-webkit-search-results-button,
            .select-search__input::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }

            .select-search__input:not([readonly]):focus {
                cursor: initial;
            }

            .select-search__select {
                max-height: 150px !important;
                box-shadow: 10px 10px 12px #ec090929;
                border: 1px solid #dddddd;
            }

            .select-search__options {
                list-style: none;
                padding: 0px 5px;
                background: #fff;
                cursor: pointer;
            }

            .select-search__row {
                border-bottom: 1px solid #dddddd;
                border-width: 0.15px;
            }

            .select-search__row:last-child {
                border-bottom: none;
            }

            .select-search__option,
            .select-search__not-found {
                display: block;
                height: 36px;
                width: 100%;
                background: #fff;
                border: none;
                outline: none;
                font-family: "Noto Sans", sans-serif;
                font-size: 14px;
                text-align: left;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .select-search--multiple .select-search__option {
                height: 48px;
            }

            .select-search__option.is-selected {
                color: #fff;
            }

            .select-search__option.is-highlighted.is-selected,
            .select-search__option.is-selected:hover {
                color: #fff;
            }

            .select-search__group-header {
                text-transform: uppercase;
                background: #fff;
            }

            .select-search.is-disabled {
                opacity: 0.5;
            }

            .select-search.is-loading .select-search__value::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
                background-size: 11px;
            }

            .select-search:not(.is-disabled) .select-search__input {
                cursor: pointer;
                letter-spacing: 0px;
                font-weight: 600;
            }

            .select-search--multiple {
                border-radius: 3px;
                overflow: hidden;
            }

            .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
                transform: rotate(45deg);
                border-right: 3px solid #000;
                border-bottom: 3px solid #000;
                pointer-events: none;
            }

            .select-search.has-focus .select-search__value::after {
                display: none;
            }

            .select-search.has-focus .select-search__input:hover {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }

            .select-search.has-focus .select-search__input {
                border-bottom: none;
                box-shadow: 0px 0px 3px #00000029;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-color: none;
                background: #f4f6fa 0% 0% no-repeat padding-box;
            }

            .select-search--multiple .select-search__input {
                cursor: initial;
            }

            .select-search--multiple .select-search__input {
                border-radius: 3px 3px 0 0;
            }

            .select-search--multiple:not(.select-search--search) .select-search__input {
                cursor: default;
            }

            .select-search:not(.select-search--multiple) .select-search__select {
                position: absolute;
                z-index: 2;
                top: 40px !important;
                right: 0;
                left: 0;
                border-radius: 8px;
                overflow: auto;
                max-height: 360px;
                background: #fff;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                box-shadow: none;
            }

            .select-search--multiple .select-search__select {
                position: relative;
                overflow: auto;
                max-height: auto;
                border-top: 1px solid #eee;
                border-radius: 0 0 3px 3px;
                background: #fff;
            }

            .select-search__not-found {
                height: auto;
                padding: 8px;
                text-align: center;
                color: rgb(244, 245, 247);
            }

            }
        }

        .header-view-report-main-container {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: end;

            .report-btn-container {
                width: 6.25rem !important;
                height: 2.5rem !important;
                border-radius: 0.5rem !important;
                padding: 0.6rem !important;
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 4px #2222221a !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;

                .report-btn-icon {
                    width: 0.9rem;
                    margin-top: 0.4rem;
                }

                .report-btn-text {
                    color: white;
                    font-size: 0.9rem;
                    margin-left: 0.5rem;
                    font-weight: 600;
                }
            }
        }
    }

    .body-main-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-top: 150px;

        .arrow-icon {
            width: 14px;
            height: 24px;
            align-self: center;
        }

        .body-text-div {
            width: 200px;
            align-self: center;

            .body-text {
                align-self: center;
                font-size: 20px;
                text-align: center;
                letter-spacing: 0px;
                color: #AAAAAA;
                opacity: 1;
            }
        }
    }
}