.dataUsageContainer {
    justify-content: center;
    padding: 20px 150px 50px 150px;
    .cardOfPlan {
      width: 100%;
      height: 300px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 46px #00000029;
      border: none;
      padding: 40px;
      border-radius: 8px;
    }
    .planHeading {
      // text-align: left;
      // font: normal normal 600 16px/22px Open Sans;
      // letter-spacing: 0px;
      // color: #AAAAAA;
      // margin-bottom:30px;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #363636;
      text-transform: uppercase;
    }
    .planIconDiv {
      width: 100px;
      height: 100px;
      transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
      background: transparent linear-gradient(45deg, #669eec 0%, #3e73bc 100%) 0%
        0% no-repeat padding-box;
      box-shadow: 0px 1px 3px #00000029;
      border: 3px solid #fefefe;
      opacity: 1;
    }
    .planDetails {
      display: inline-flex;
      float: left;
    }
    .planType {
      text-align: left;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #222222;
      text-transform: uppercase;
      margin-bottom: 10px;
      opacity: 1;
    }
    .professionalPara {
      font-size: normal normal 600 16px/22px Open Sans;
      color: #1aa6b4;
      text-transform: uppercase;
      font-weight: 600;
    }
    .premiumPara {
      font-size: normal normal 600 16px/22px Open Sans;
      color: #e83861;
      text-transform: uppercase;
      font-weight: 600;
    }
    .personalPlanPara {
      font-size: normal normal 600 16px/22px Open Sans;
      color: #f18f1a;
      text-transform: uppercase;
      font-weight: 600;
    }
    .planPrice {
      text-align: left;
      font: normal normal normal 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #386cb5;
    }
    .planDetailsPoint {
      color: var(--unnamed-color-222222);
      text-align: left;
      font: normal normal normal 14px/15px Open Sans;
      letter-spacing: 0px;
      color: #222222;
      font-weight: bold;
      position: relative;
      bottom: 4px;
  
      .benefitsText {
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #363636;
        text-transform: uppercase;
      }
    }
    .dataPlans {
      // position: relative;
      // line-height: 1.5em;
      font: normal normal normal 15px/26px Open Sans;
      text-align: left;
      font: normal normal normal 15px/26px Open Sans;
      letter-spacing: 0px;
      color: #222222;
    }
    .benefitsDiv {
      margin-top: 0px;
    }
    .upgardePlanCol {
      position: relative;
    }
    .upgradePlanButton {
      width: 150px;
      height: 40px;
      background: transparent linear-gradient(90deg, #669eec 0%, #3e73bc 100%) 0%
        0% no-repeat padding-box;
      // border-radius: 20px;
      // position: absolute;
      // top: 50%;
    }
    .mTop20 {
      margin-top: 20px;
    }
    .mTop30 {
      margin-top: 30px;
    }
    .cardOfProgressBar {
      // width: 100%;
      max-width: 975px;
      min-height: 300px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #f5f7fb 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 46px #00000029;
      border: none;
      padding: 40px;
      // margin-top: 20px;
      border-radius: 8px;
      margin: 20px auto 0 auto;
    }
    .progressBarHeading {
      margin-top: 10px;
      margin-bottom: 5px;
      text-align: left;
      font: normal normal normal 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #222222;
      font-weight: bold;
    }
    .progressBarValue {
      text-align: right;
      font: normal normal normal 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #222222;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .progress {
      height: 6px !important;
      border-radius: 1.5rem !important;
      width: 100% !important;
      margin: 0.1rem 0rem 0.1rem 0rem;
      float: right;
    }
    .progressbarfirst {
      width: 0%;
      background: transparent linear-gradient(90deg, #659deb 0%, #3e73bc 100%) 0%
        0% no-repeat padding-box;
      border-radius: 3px;
      box-shadow: inset 0px -2px 5px #FD1010(0, 0, 0, 0.5);
      animation: change 10s linear infinite;
    }
    .progressSpaceBtwDiv {
      justify-content: space-between;
      display: inline-flex;
      padding: 0px;
    }
    .noteCount {
      text-align: left;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #222222;
    }
    .dataAvailable {
      text-align: right;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #222222;
    }
    @media only screen and (min-width: 751px) and (max-width: 950px) {
      padding: 20px 20px 50px 20px;
      .planDetails {
        position: none;
      }
      .upgradePlanButton {
        right: 1px;
      }
      .planDetails {
        display: block;
        float: center;
      }
    }
    @media only screen and (min-width: 980px) and (max-width: 1280px) {
      padding: 20px 20px 50px 20px;
    }
  
    @media only screen and (min-width: 251px) and (max-width: 980px) {
      padding: 20px 20px 50px 20px;
  
      .upgardePlanCol {
        position: none;
      }
      .upgradePlanButton {
        position: relative;
      }
      .benefitsDiv {
        margin-top: 30px;
      }
    }
    @media only screen and (min-width: 251px) and (max-width: 768px) {
      .dataPlans {
        position: relative;
        line-height: 0.8em;
      }
    }
  }
  .leftUpgradeBg {
    background-color: #f9f9f9;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  