.score-card {
  background-color: transparent !important;
  border: none !important;
}
.score-card .card-header {
  height: 48px;
  background: #669FED 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #2222221A;
  border-radius: 8px !important;
}
.score-card .card-header {
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  color: #FFFFFF;
  margin-bottom: 0;
}
.score-card .btn {
  padding: 0;
  font: normal normal normal 14px/19px Open Sans;
  color: #FFFFFF;
}
.score-card .btn img {
  margin-top: -2px;
}
.score-card .update-btn {
  background: white !important;
  color: black !important;
  padding: 1px 10px !important;
  font-size: 12px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}
.score-card .card-body {
  margin: 0px 10px;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.score-card .checkBoxfilter {
  margin: auto;
  float: left;
  margin-top: 15px;
  margin-left: 30px;
}
.score-card .checkbox-lable {
  margin-left: 65px;
}
.score-card .heading {
  height: 19px;
  color: var(--unnamed-color-222222);
  font: normal normal 600 14px/19px Open Sans;
  color: #222222;
}
.score-card label {
  margin-right: 20px;
}
.score-card .sub-heading {
  height: 17px;
  text-align: left;
  font: italic normal normal 12px/20px Open Sans;
  color: #888888;
}
.score-card .score-section {
  margin: auto;
  white-space: nowrap;
  font: normal normal normal 12px/17px Open Sans;
  text-align: right;
}
.score-card .score-section .score {
  width: 80px;
  float: right;
  text-align: center;
  font: normal normal normal 14px/19px Open Sans;
}
.score-card .score-div {
  float: right;
  border-left: 1px solid #CCCCCC;
  padding-left: 18px;
}



@media only screen and (max-width: 840px) {
  .score-card .checkBoxfilter {
    margin-left: 5px;
    margin-right: 10px;
  }
  .score-card .form-check {
    width: 25px;
  }
  .score-card .checkbox-lable {
    margin-left: 0;
  }
}