.bs-example{
    /* background: transparent linear-gradient( to bottom, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box !important; */
    background: #222222 !important;
    /* padding: 0.5rem 5rem .5rem 6rem !important; */
    padding: 0.5rem 7rem .5rem 7rem !important;
    height: 70px;
}
.bg-light {
    background-color: #222222 !important;
}
.navbar-light .navbar-nav .nav-link{
    color: white !important;
}
.mainLogo{
    height: 2.375rem;
    width: 9.438rem;
    cursor: pointer;
}
.menuIcon{
    margin: .5rem;
}
.notification{
    /* padding-right: 0px !important; */
    /* padding: 0.6rem; */
    /* border-left: 1px solid white; */
    width: 2.25rem;
    height: 2.25rem;
    margin-left: 0.6rem;
    background-color: #FFFFFF !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}
.badges {
    position: absolute;
    top: 8px;
    right: -10px;
    padding: 5px 5px 3px 5px;;
    border-radius: 88%;
    background-color: red !important;
    color: white!important;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
  }
  .badgeValue{
    font-size: 14px;
    margin-top: -5px;
}
.fontletter{
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font-family: Roboto;
    letter-spacing: 0.72px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}
.navbar-light .navbar-toggler {
    color: white !important;
    border-color: white !important;
}
.navbar-light .navbar-nav .nav-link{
    cursor: pointer;
}

.notificationIcon{
    width: 36px;
    height: 36px;
    color: #386CB5;
    cursor: pointer;
}
.displayFlex{
    display: inline-flex;
    align-items: center;
}
.dropIcon{
    margin-right: .5rem;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    margin-left: 11px;
}
.dropListText{
    margin-left: 1rem;
    margin: .1rem;
    /* font-weight: bold; */
    width: 42px;
    height: 19px;
    
    
    
    /* font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-open-sans); */
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-222222);
    text-align: left;
    /* font: normal normal normal 14px/19px Open Sans; */
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    display: flex;
    justify-content: center;
    font-size: 15px;
}
.dropdown-item.active, .dropdown-item:active{
    outline: none;
    background-color: #386CB5 !important;
}
.arrowIcon{
    margin-left: 8px;
    margin-bottom: 6px;
}
.navbar{
    padding: 0rem !important;
}
.dropdown-menu.dropdown-menu-right.show::before {
    content: "";
    display: block;
    position: absolute;
    top: -17px;
    /* left: 70%;
    transform: translateX(-70%); */
    right : 11px;
    border: 10px solid transparent;
    border-bottom: 10px solid #fff;
    z-index: 2;
}
.logoutTextColor {
    color: #ff0000;
}
.dropdownDividerCustom {
    width: 130px;
    margin-left: 10px!important;
}
.customDropdownHover:hover {
    background-color: #fff!important;
}
.customDropDownContainer {
    border-radius: 8px!important;
    box-shadow: 0px 2px 6px #0000001a;
    border: none !important;
    right: -5px!important;
}
@media only screen and (min-width: 771px) and (max-width: 920px) {
    .bs-example{
        padding: 1rem 2.5rem 1rem 2.5rem !important;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.3rem;
        padding-left: 0.3rem;
    }
}

@media only screen and (min-width: 695px) and (max-width: 970px) {

    .bs-example{
        padding: 0rem 1rem 0rem 1rem !important;
    }
    .navbar{
        padding: 0.5rem 0rem !important;
    }
    .notification{
        border: none !important;
        border-left: 0rem solid white ;
        margin-left: 0rem !important;
    }
}
@media only screen and (min-width: 200px) and (max-width: 694px) {
    .bs-example{
        padding: 1rem 1rem 1rem 1rem !important;
    }
    .notification{
        border: none !important;
        border-left: 0rem solid white ;
        margin-left: 0rem !important;
    }
}
