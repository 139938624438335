.StudentProfiles {
    .red {
        border-right: 6px solid #ff4444;
    }

    .green {
        border-right: 6px solid #23bf1d;
    }

    .yellow {
        border-right: 6px solid #fab700;
    }

    .blue {
        border-right: 6px solid #386cb5;
    }

    .gray {
        border-right: 6px solid #777777;
    }
}
.profileBox {
    .mainDiv {
        border-radius: 0px 6px 6px 0px;
        border-radius: 0px 6px 6px 0px;
        height: 70px !important;
        padding: 9px !important;
        .profile {
            text-align: left;
            padding-left: 20px;
            .img {
                width: 3rem;
                height: 3rem;
                background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
                border-radius: 50rem;
                float: left;
                margin-right: 1rem;

                .profile-img {
                    width: 3rem !important;
                    height: 3rem !important;
                    border-radius: 50% !important;
                }
            }

            .details {
                .name {
                    font: normal normal 600 1rem/1.375rem Open Sans;
                    color: #222222;
                }
                .mail {
                    font: normal normal normal 0.875rem/1.188rem Open Sans;
                    color: #777777;
                }
            }
        }
    }
}
.FAstudentprofilesmail {
    max-width: 100% !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @media screen and (max-width: 1260px) {
        max-width: 95% !important;
    }
}