.lpTeacherShareContainer {
    background: #f0f0f0;
    height: 100%;
    overflow-x: hidden;
    /* ------------Heading Css------- */
    .shareLessonPlanMainDiv {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .shareLessonPlanDetailsMain {
            display: flex;
            align-items: center;
            .ClassBackDiv {
                display: flex;
                padding: 0.8rem;
                background: #eeeeee;
                width: 13rem;
                height: 3.1rem;
                cursor: pointer;
            }
            .lessonBackIcon {
                font-size: 1.3rem;
                padding-top: 3px;
            }
            .lessonClassBactText {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }
            .lessonDetailTextDiv {
                margin-left: 1rem;
                border-left: 1px solid #eeeeee;
                padding: 0.3rem 0rem 0rem 1rem;
                .lessonClassName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                }
            }
        }
    }

    .mainHeading {
        width: 100%;
        background: #ffff;
        display: flex;
        padding: 1rem 1rem 1rem 1rem;
        align-items: center;
        .checkDiv {
            display: flex;
        }
        .headingSchoolName {
            font-size: 1rem;
            font-weight: 600;
            margin: 0rem;
        }
        .headingSelectTeacher {
            font-size: 0.8rem;
            font-weight: 600;
            color: #aaaaaa;
            margin: 0rem;
        }
        .displayFlexInSeleTeacher {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 0.7;
            .undoSelect {
                width: 2.5rem;
                height: 2.5rem;
                background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
                border-radius: 10rem;
                opacity: 1;
                margin-right: 1rem;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                .CrossAndConfirmIcon{
                    color: white;
                    padding-top: 7px;
                }
            }
            .confirmSlectDiv {
                width: 2.5rem;
                height: 2.5rem;
                background: transparent linear-gradient(270deg, #23bf1d 0%, #5fdb5a 100%) 0% 0% no-repeat padding-box;
                border-radius: 10rem;
                cursor: pointer;
                .CrossAndConfirmIcon{
                    color: white;
                    padding-top: 7px;
                }
            }
            .opacityFade {
                opacity: 0.4;
                cursor: default;
                pointer-events: none;
            }
        }
    }
    /* ------------Heading Css------- */

    /* ------------Teacher Card Css------- */
    .teacherMainDiv {
        width: 22rem !important;
        height: 5rem !important;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #0000001a;
        border-radius: 1rem;
        opacity: 1;
        display: inline-flex;
        padding: 0.5rem;
        margin: 0.85rem 1rem 0.25rem 0rem;
        min-width: 270px !important;
        opacity: 1;
        position: relative;
        align-items: center;
        .teacherProfileImageDiv {
            width: 3.5rem;
            height: 3.4rem;
            color: red;
            background: #386cb5;
            border-radius: 50%;
        }
        .teacherDummyDivProfile {
            width: 5rem;
            height: 5rem;
            background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
            box-shadow: 1px 2px 3px #0000001a;
            border-radius: 50rem;
            margin-top: 0rem;
            text-align: center;
            margin-left: 0.5rem;
        }
        .teacherImageDummyText {
            color: white;
            padding-top: 30%;
            text-transform: uppercase;
        }
        .teacherShareProfileImage {
            width: 3.125rem;
            height: 3.125rem;
            background: transparent linear-gradient(180deg, #647183 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
            box-shadow: 1px 2px 3px #0000001a;
            border-radius: 50rem;
        }
        .teacherShareTextInfoDiv {
            text-align: left;
            margin: 1rem;
        }
        .teacherShareName {
            margin: 2px;
            font-size: 1rem;
            font-weight: 600;
            width: 10rem;
        }
        .teacherShareEmail {
            margin: 0px;
            font-size: 0.9rem;
        }
        .teacherShareCheckBoxDiv {
            position: absolute;
            right: 0.7rem;
            top: 1.5rem;
            font-size: 1.5rem;
            cursor: pointer;
        }
        .checkBoxColorTeacherShare {
            color: #386cb5;
            cursor: pointer;
        }
    }
}
.publishTeachers-modal-popUp .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}
.publishTeachers-modal-popUp .modal-content {
    border-radius: 8px;
    width: 282px;
    margin: auto;
    background-color: #fff !important;
}
.publishTeachers-modal-popUp .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
}
.publishTeachers-modal-popUp .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
    display: flex;
    width: 100%;
    .bothDivMain {
        width: 100%;
        justify-content: space-between;
        .imgTextDiv {
            border-radius: 5px;
            padding: 20px 10px 10px;
            cursor: pointer;
            .textDiv {
                font: normal normal 600 14px/19px Open Sans;
                margin: 10px 0px 0px;
                color: black;
            }
            .teacherTextDiv {
                font: normal normal 600 14px/19px Open Sans;
                margin: 0px 0px 40px;
                color: #386CB5;
            }
            .okBtn{
                background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
                border-radius: 20px;
                width: 105px;
                height: 40px;
            }
        }
    }
}
.publishTeachers-modal-popUp .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}
