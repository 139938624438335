.popUp-modal-warning {
    .modal-title {
        text-align: center;
        color:rgb(0, 0, 0);
        font: normal normal 600 18px/24px Open Sans;
        }
        .modal-content {
        border-radius: 8px;
        width: 360px;
        margin: auto;
        background-color: rgb(255, 255, 255);
        }
        .modal-content .modal-header {
        justify-content: center;
        border: none;
        border-radius: 0px !important;
        }
        .modal-content .modal-body {
        text-align: center;
        font: normal normal 600 14px/19px Open Sans;
        }
        .modal-content .modal-body .confirm-text {
        font: normal normal 600 14px/19px Open Sans !important;
        color:rgb(0, 0, 0);
        margin-bottom: 0;
        }
        .modal-footer {
        border: none;
        justify-content: center;
        padding-top: 0;
        }
        .cancelUpgrade-btn {
        width: 101px;
        height: 38px;
        font: normal normal 600 14px/19px Open Sans;
        }
        .showNext-btn {
        background: transparent linear-gradient(270deg, #5e6ada 0%, #5478db 100%) 0% 0% no-repeat padding-box;
        font: normal normal 600 14px/19px Open Sans;
        width: 118px;
        height: 38px;
    }
}