.timeline-section .card-body {
  padding-left: 0.625rem;
  padding-right: 0;

  .tab-content {
    margin-left: 75px !important;
  }
}

.vertical-timeline {
  width: 88% !important;
  max-width: unset;
  white-space: nowrap;
}

.verticalTimelineExpanded {
  .vertical-timeline-element--work {
    margin-left: -47px !important;

    .vertical-timeline-element-icon {
      position: absolute;
      background: #386cb5;
      width: 0.75rem;
      height: 0.75rem;
      box-shadow: none;
      border-radius: 50%;
      left: 3.777rem;
      top: 1rem;
    }

    .vertical-timeline-element-content {
      margin-bottom: 15px !important;
      margin-left: 91px !important;
    }

    .vertical-timeline::before {
      width: 0.125rem;
      background: #386cb5 !important;
      top: -0.625rem;
      left: 4.063rem;
    }
  }
}

.vertical-timeline--one-column .vertical-timeline-element-icon {
  width: 0.75rem;
  height: 0.75rem;
  left: 2.188rem;
  margin-left: 1.563rem;
  box-shadow: none;
  margin-top: 1.125rem;
  background: #386cb5 !important;
}

.vertical-timeline--one-column .vertical-timeline-element-content {
  width: 91%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0.0625rem 0.25rem #00000029;
  min-height: 7.5rem;
  padding: 0.625rem;
  margin-left: 5.75rem;
}

.vertical-timeline--one-column .vertical-timeline-element-content .vertical-timeline-element-date {
  text-align: right;
  font: normal normal normal 0.875rem/1.188rem Open Sans;
  letter-spacing: 0;
  color: #386cb5;
  font-weight: 600 !important;
  left: -6.875rem;
  top: -0.5625rem;
  position: absolute;
  width: 4.688rem;
  white-space: normal;
  padding: 23px 0px 0px 0px;
}

.vertical-timeline--one-column .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
  right: 107%;
}

.historyIcon {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 4px;
}

.no-reminder {
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  letter-spacing: 0;
  color: #AAAAAA;
  margin-right: 0.5rem;
}

.greyButton {
  margin-left: 0px !important;
  bottom: 2px;
}

.pinkButton {
  margin-top: 0px !important;
  bottom: 2px;
}

.timeline-time {
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  letter-spacing: 0;
  color: #BD47E8;
  margin-right: 0.5rem;
}

.loaderGroupIcon {
  width: 6rem;
  margin-top: 0rem;
  margin: 1rem 0rem 1rem 0rem;
}

.timeline-section .fa-star-o {
  font-size: 1.125rem;
}

.timeline-section .tag {
  width: 2.5rem;
  height: 1.625rem;
  background: transparent linear-gradient(180deg, #ffe996 0%, #f8db6e 100%) 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  margin: 0.625rem 0.5rem 0 0;
  display: inline-block;
  text-align: center;
}

.timeline-section .tag .tag-text {
  text-align: center;
  font: normal normal normal 0.8125rem/1.125rem Open Sans !important;
  letter-spacing: 0;
  color: #222222;
  padding: 0.4375rem;
  text-transform: uppercase;
}

.timeline-section .attached-tag {
  width: 1.875rem;
  height: 1.625rem;
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  margin: 0.625rem 0.5rem 0 0;
  display: inline-block;
  text-align: center;
}

.level-text-b {
  // font: normal normal bold 0.8125rem/1.125rem Open Sans;
  // color: #222222;
  font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #222222;
}

.level-text-n {
  font: normal normal normal 0.8125rem/1.125rem Open Sans;
  color: #222222;
}

.expanded-timeline-section .vertical-divider {
  border-left: 0.0625rem solid #ccc;
  margin: 0 0.5rem;
  height: 0.6875rem !important;
  display: inline-block;
  margin-top: 0.3125rem;
}

.attachment-text {
  font: normal normal 600 0.875rem/1.188rem Open Sans;
  color: #222222;
  white-space: break-spaces;
}
.attachment-text-misc {
  font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #222222;
}
.timeline-section audio {
  background: #f1f3f4;
  border-radius: 0.375rem;
  width: 250px;
  margin-right: 10px;
  height: 20px;
  margin-bottom: 8px;
  outline: none !important;
}

.audio-title {
  margin-top: 3px !important;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #222222;
  padding: 4px 20px;
}

.remove-text {
  font: italic normal normal 0.8125rem/1.125rem Open Sans;
  color: #aaaaaa;
}

.divider hr {
  border: 0.0625rem solid #dddddd;
  width: 100%;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.level-score-section {
  display: flex;
  line-height: 1.813rem;
}

.timeline-btn-img {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.3rem;
  margin-top: -3px;
}

.disabledEditTime-btn-img {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.3rem;
  margin-top: -3px;
  filter: brightness(4.5);
  margin-left: 7px;
}

.power-points .timeline-btn-img {
  margin-left: 8px;
}

.power-points .btn {
  padding: 0;
}

.timeline-btn-div {
  padding: 6px 0px !important;
}

.GroupText-SNETL {
  font: normal normal normal 0.75rem/1.063rem Open Sans;
  letter-spacing: 0;
  color: #FF6A6A;
  margin-right: 0.5rem;
}

.redButton-SNETL {
  margin-right: 10px !important;
  margin-top: 0px !important;
  bottom: 2px;
}

.GrpIcon-SNETL {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 4px;
}

.edit-btn-SNETL {
  background: transparent !important;
  // border: none !important;
  font: normal normal normal 0.75rem/1.125rem Open Sans !important;
  color: #222 !important;
  // padding: 6px 0px !important;
  // margin-right: -10px !important;
  // margin-left: 10px !important;

  :active {
    background-color: transparent !important;
    color: white !important;
  }
}

.edit-btn-text-SNETL {
  text-transform: capitalize !important;
}

.trash-btn-SNETL {
  background: transparent !important;
  // border: none !important;
  font: normal normal normal 0.75rem/1.125rem Open Sans !important;
  // color: #FFFFFF !important;
  color : #FF4444 !important;
  // margin-left: 18px !important;

  :active {
    background-color: transparent !important;
    color: #FF4444 !important;
  }
}

.disabledTrash-btn-SNETL {
  cursor: default !important;
  background: transparent !important;
  border: none !important;
  font: normal normal normal 0.75rem/1.125rem Open Sans !important;
  color: #FF4444 !important;
  filter: saturate(551%) contrast(347%) brightness(100%) invert(138%) sepia(50%) hue-rotate(130deg);
}

.add-mili-note-btn {
  width: 10.63rem;
  height: 2.125rem;
  background: #386bb5 0% 0% no-repeat padding-box !important;
  border-radius: 0.3125rem;
  opacity: 1;
  // font: normal normal bold 0.65rem/1.063rem Open Sans !important;
}
.add-mili-note-btn-font-expand {
  font-size: 11px!important;
  font-weight: normal!important;
}

.add-load-more {
  width: 122px;
  margin-top: -14px;
  margin-left: 4px;
}

.add-load-more i {
  margin-top: -2px;
  margin-right: 6px;
}

.add-load-more span {
  line-height: 20px;
}

.miscellaneous-div {
  text-align: left;
}

.miscellaneous-div button {
  margin-left: 7.1rem;  
  border-color: #386bb5;
}

.miscellaneous-div .btn-element-content-arrow {
  content: "";
  position: absolute;
  top: 1.85rem;
  border: 0.4375rem solid transparent;
  border-right: 0.4375rem solid #386bb5;
  left: 111px;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  margin-left: 1%;
  text-align: center;
  font: normal normal normal 11px/24px Open Sans;
  letter-spacing: 0;
  color: #386CB5;
  font-weight: 600 !important;
  top: 0.1rem;
  position: absolute;
  width: 6.425rem;
  white-space: normal;
  left: -106px;
}

@media screen and (min-width: 1280px) and (max-width: 1920px) {

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -14%;
  }
}

.miscellaneous-div .btn-element-content-disabledArrow {
  content: "";
  position: absolute;
  top: 1.85rem;
  border: 0.4375rem solid transparent;
  border-right: 0.4375rem solid #aec7ea;
  left: 5.55rem;
}

.btn-element-icon {
  position: absolute;
  background: #386cb5;
  width: 0.75rem;
  height: 0.75rem;
  box-shadow: none;
  border-radius: 50%;
  left: 52px !important;
  top: 2rem;
}

.timeline-attchements {
  margin: 0 !important;
}

.timeline-attchements .note {
  width: 3.75rem;
  height: 3.75rem;
  background-color: #00000059;
  margin-right: 1%;
  margin-bottom: 1%;
  background-size: cover;
}

.timeline-attchements .blackLayer {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.timeline-attchements .img-div {
  position: absolute;
  padding: 1rem 1.313rem;
  background-color: #0000002e;
}

.timeline-attchements .sort-play {
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
}

.usersGroupIcon {
  margin-left: 10px;
}

.reminder-div {
  background-color: "#BD47E81A";
  font: normal normal normal 13px/18px Open Sans;
  margin-top: -17px;
  margin-bottom: -13px;
  padding: 3px 6px !important;
}

.reminder-time {
  font: normal normal normal 13px/18px Open Sans;
}

.power-points {
  margin: 0 !important;
  color: #87c41f;
  font: normal normal 600 13px/24px Open Sans !important;
  text-align: right;
}

.playIcon {
  cursor: pointer;
}

.play-video-modal .modal-footer {
  justify-content: center;
  border: none !important;
}

.play-video-modal .modal-dialog {
  justify-content: center !important;
  display: flex;
}

.play-video-modal .modal-header {
  justify-content: flex-end;
  border: none !important;
  padding: 0px !important;

  .delete-btn {
    background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;
    padding: 0;
  }
}

.play-video-modal .modal-footer button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0;
}

.play-video-modal .modal-content {
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0) !important;
}

.play-video-modal .modal-footer .btn-danger {
  background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0% 0% no-repeat padding-box;
}

.play-video-modal .modal-footer button img {
  margin-top: -0.1875rem;
  margin-left: 0.0625rem;
}

.play-video-modal .modal-body video {
  width: 100%;
}

.playIcon {
  cursor: pointer;
}

.starSelected {
  cursor: pointer;
  color: #ffa700;
}

.starUnSelected {
  cursor: pointer;
  color: #AAAAAA;
  font-size: larger !important;
}

.powerPoint {
  color: #80CE4F;
}

.audio-style {
  float: left;
  margin-bottom: 10px;
  background: #f1f3f4;
  box-shadow: 0 0 0.1875rem #0000004a;
  border-radius: 4px;
  margin-right: 10px;
}

#assign-popover-box {
  background: black;
  padding-bottom: 0 !important;
  max-width: 151px;
  min-width: 101px;
  width: 100% !important;
}

#assign-popover-box .arrow::after {
  border-right-color: #000;
}

#assign-popover-box .popover-body {
  color: white;
}

@media only screen and (max-width: 1920px) {

  .btn-element-icon {
    left: 96px !important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -12.5%;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 1880px){

  .btn-element-icon {
    left: 96px !important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -12.5%;
  }
}

@media only screen and (max-width: 1745.45px) {

  .btn-element-icon {
    left: 93px !important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -13.5%;
  }
}

@media only screen and (max-width: 1536px) {

  .btn-element-icon {
    left: 83px !important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -16%;
  }
  .miscellaneous-div button {
    margin-left: 6rem;
    
  }
  .btn-element-content-arrow {
    left: 93px!important;
  }
}

@media only screen and (max-width: 1680px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 90%;
  }
}

@media only screen and (max-width: 1480px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 89%;
  }

  .btn-element-icon {
    left: 80px !important;
  }
  .miscellaneous-div button {
    margin-left: 5.8rem;
    
  }
  .btn-element-content-arrow {
    left: 89px!important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -16.8%;
  }
}

@media only screen and (max-width: 1380px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 87%;
  }

  .btn-element-icon {
    left: 49px !important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -16%;
  }
}

@media only screen and (max-width: 1300px) {
  .miscellaneous-div .btn-element-content-arrow {
    top: 1.5rem;
  }

  .btn-element-icon {
    top: 1.625rem;
    left: 75px !important;
  }
  .miscellaneous-div button {
    margin-left: 5.6rem;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -18%;
  }

  .miscellaneous-div .btn-element-content-arrow {
    left: 70px;
  }
}

@media only screen and (max-width: 822px) {
  .timeline-section .card-body {
    .tab-content {
      margin-left: 0px !important;
    }
  }

  .miscellaneous-div {
    margin-left: 13px !important;
  }

  .miscellaneous-div .btn-element-content-arrow {
    top: 1.5rem;
    left: 3.28rem !important;
  }

  .btn-element-icon {
    left: 1.65rem !important;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -25% !important;
  }
}

@media only screen and (max-width: 1060px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 85%;
  }
}

@media only screen and (max-width: 991px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 83%;
  }
}

@media only screen and (max-width: 900px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 81%;
  }
}

@media only screen and (max-width: 820px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 84%;
  }
}

@media only screen and (max-width: 665px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 82%;
  }
}

@media only screen and (max-width: 595px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 80%;
  }
}

@media only screen and (max-width: 540px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 78%;
  }

  .miscellaneous-div button {
    margin-left: 0;
  }

  .btn-element-content-arrow {
    display: none;
  }

  .btn-element-icon {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 76%;
  }
}

@media only screen and (max-width: 420px) {
  .vertical-timeline--one-column .vertical-timeline-element-content {
    width: 70%;
  }
}

@media only screen and (min-width: 1921px) {
  .btn-element-icon {
    left: 96px!important;
  }
  // .miscellaneous-div button {
  //   margin-left: 9rem;
  // }
  // .btn-element-content-arrow {
  //   left: 140px;
  // }
}
@media only screen and (min-width: 2000px) and (max-width: 2800px){
  .btn-element-icon {
    left: 96px!important;
  }
  .btn-element-content-arrow {
    left: 111px!important;
  }
  .miscellaneous-div button {
    margin-left: 7.1rem;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    left: -131px;
  }
}