.termsContainer{
    justify-content: center;
    padding: 20px 150px 0px 150px;
    .cardOfTerms{
        width: 100%;
        min-height: 500px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A;
        padding: 40px;
    }
    .TermsHeading{
        text-align: center;
        font: normal normal normal 35px/40px Roboto;
        letter-spacing: 0.8px;
        color: #222222;
        // font-family: 'Kaushan Script', cursive;
    }
    .questionText{
        text-align: left;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0em;
        line-height: 26px;
        margin: 0 20px 25px;
    }
    .descriptionText{
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0em;
        line-height: 26px;
        margin: 0 20px 25px;
        text-align: justify;
    }
    @media only screen and (min-width: 851px) and (max-width: 1250px) {
        padding: 20px 40px 0px 40px;
    
           
    }
    @media only screen and (min-width: 251px) and (max-width: 850px) {
        padding: 20px 00px 0px 00px;
    
           
    }
}