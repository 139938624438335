.timeline-section-div {
    margin: 0px -7.5px !important;
  }
  .timeline-section-div .adv-note-div {
    padding: 0px 7.5px !important;
  }
  .adv-note {
    background: transparent linear-gradient(180deg, #FFE996 0%, #F8DB6E 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 0 0.0625rem 0.25rem #2222221A;
    margin-bottom: 0.9375rem;
  }
  .adv-note .card-header {
    padding: 0.5rem;
    text-align: left;
    line-height: 0.875rem;
    background-color: transparent;
    border-bottom: 0.0625rem solid #F0D469;
  }
  .adv-note .card-header .header-text {
    font: normal normal 600 0.75rem/1.063rem Open Sans;
    color: #222222;
  }
  .adv-note .card-header button {
    float: right;
    background: transparent;
    border: none;
    padding: 0;
    line-height: 1.125rem;
    padding-right: 0.125rem;
  }
  .adv-note .card-body {
    padding: 0.625rem;
    min-height: 8.75rem;
    height: 170px;
    overflow: auto;
  }
  .adv-note .card-text {
    margin: 0;
    text-align: left;
    font: normal normal normal 0.875rem/1.25rem Open Sans;
    color: #222222;
    white-space: normal;
  }
  .adv-note .btn-primary:not(:disabled):not(.disabled).active,
  .adv-note .btn-primary:not(:disabled):not(.disabled):active, 
  .adv-note .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .adv-note .btn-primary.focus, .adv-note .btn-primary:focus {
    box-shadow: none;
  }