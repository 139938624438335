body {
    background-image: linear-gradient(to bottom, #ecf3fc, #FAFAFA);
}

.classRosterSelectContainer {
    background: #F0F0F0;
    padding: 0rem 2.5rem 1rem 2.5rem;
    height: 100%;
    overflow-x: hidden;
}

.displayFlexInSeletRoster {
    display: flex;
}

/* ---------Details Div------- */
.ClassRosterSelectMainDiv {
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;
}

.classRosterSelectDetailsMain {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.classRosterSelectBackDiv {
    display: flex;
    padding: .8rem;
    background: #EEEEEE;
    width: 19rem;
    height: 3.1rem;
    cursor: pointer;
}

.rosterClassSelectBackText {
    margin: 0px;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.rosterClassAllSelectDiv {
    margin-left: 1rem;
    padding: 0.55rem 0rem 0rem 0rem;
    display: flex;
    min-width: 14%;
}

.checkBoxSelectRoster {
    color: #386cb5;
    cursor: pointer;
    padding-top: 0.1rem;
}

.allRosterSelectBlock {
    margin: 0px;
    font-size: .8rem;
    font-weight: 600;
    text-align: left;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    margin-right: 1rem;
}

.allRosterSelectNote {
    text-align: left;
    font: italic normal normal 14px/18px Open Sans;
    letter-spacing: 0px;
    color: #777777;
}


/* -----Class Selected Roster Close------ */
.croseSlectedDiv {
    width: 2.5rem;
    height: 2.5rem;
    background: transparent linear-gradient(270deg, #EA3737 0%, #F05F5F 100%) 0% 0% no-repeat padding-box;
    border-radius: 10rem;
    opacity: 1;
    margin-right: 1rem;
    margin-top: 0.2rem;
    cursor: pointer;

    .CrossAndConfirmIcon {
        color: white;
        padding-top: 7px;
    }
}

.confirmSlectedDiv {
    width: 2.5rem;
    height: 2.5rem;
    background: transparent linear-gradient(270deg, #23BF1D 0%, #5FDB5A 100%) 0% 0% no-repeat padding-box;
    cursor: pointer;
    border-radius: 10rem;
    opacity: 1;
    margin-right: 1rem;
    margin-top: 0.2rem;

    .CrossAndConfirmIcon {
        color: white;
        padding-top: 7px;
    }
}

.confirmIcon {
    padding: .45rem 0rem 0rem 0rem;
    color: white;
    font-size: 1.4rem;
}

/* -----Class Selected Roster Close------ */

/* ---------Details Div------- */

/* --------ClassRosterCard-------- */
.classRosterSelectCardMainDiv {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.classRosterSelectCardDiv {
    margin: 0rem .8rem .8rem 0rem;
    box-shadow: 0rem 0.2rem 0.5rem #0000001A;
    padding: .6rem .3rem .1rem .3rem;
    width: 22rem;
    height: 5rem;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    position: relative;
    display: flex;
}

.classRosterDeselectCardDiv {
    margin: 0rem .8rem .8rem 0rem;
    box-shadow: 0rem 0.2rem 0.5rem #0000001A;
    padding: 1rem .3rem .1rem .3rem;
    width: 22rem;
    height: 6rem;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    position: relative;
    opacity: .6;
    display: flex;
}

/* --------ClassRosterCard-------- */

.selectRosterImageDiv {
    width: 5rem;
}

.selectRosterProfileDummy {
    width: 3.8rem;
    height: 3.8rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    margin-top: 0rem;
    text-align: center;
    margin-left: .4rem;
}

.selectRosterProfileImage {
    width: 3.8rem;
    height: 3.8rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
}

.selectRosterTextInfoDiv {
    text-align: left;
    margin: .3rem 0rem 0rem 0rem;
}

.profileSelectDummyText {
    color: white;
    padding-top: 28%;
    text-transform: uppercase;
    font-weight: 600;
}

.selectRosterName {
    margin: 2px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 12rem;
    text-align: left;
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #222222;
}

.selectRosterEmail {
    margin: 0px;
    font-size: .9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 12rem;
}

.selectRosterCheckBoxDiv {
    position: absolute;
    right: 1rem;
    top: 1.6rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.searchButtonFilter {
    width: 3rem;
    height: 2.5rem !important;
    border-radius: 8px !important;
    padding: .6rem !important;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: 1rem !important;
    margin: .3rem .8rem .3rem .3rem;
}

.buttonMarginTop .searchFilterIcon {
    cursor: pointer;
    color: #fff;
    width: 1rem;
    margin-top: .1rem;
}

.sortByDiv {
    display: flex;
    width: 23rem;
    align-items: center;
    margin-left: 1.5rem;


    .totalStudentsDiv {
        position: relative;
        width: 4rem;
        margin-top: -3.5px;

        .RosterYearSortNameText {
            margin: 0px;
            font-size: .9rem;
            font-weight: 600;
            width: 10rem;
            padding-top: 0px;
            margin-left: -30px;

        }
    }
}

.classRosterfirstName {
    width: 7.3rem;
    height: 1.75rem;
    border: 0.5px solid #222222;
    border-radius: 16px;
    margin: .3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.classRosterlastName {
    width: 6.7rem;
    height: 1.75rem;
    border: 0.5px solid #222222;
    border-radius: 16px;
    margin: .3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myRosterDiv {
    width: 6.3rem;
    height: 1.75rem;
    border: 0.5px solid #222222;
    border-radius: 16px;
    margin: .3rem;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkBoxfilter {
    color: #386cb5;
    cursor: pointer;
    font-size: 21px;
    margin-right: .2rem;
}

.unCheckBoxfilter {

    cursor: pointer;
    font-size: 21px;
    margin-right: .2rem;
}

.listAllText {
    font-size: .8rem;
    color: #000;
    padding: 1rem 0rem 0rem .1rem;
    cursor: default;
}

.datePickerDiv .datePicker {
    width: 100% !important;
    height: 36px !important;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    opacity: 1;
    padding: 0.2rem 0.8rem;
    background: #F4F6FA 0% 0% no-repeat padding-box !important;
    border: 0.75px solid #DDDDDD !important;
}

.datePicker span {
    margin-top: 0.1rem;
    margin-left: 0.6rem;
    cursor: default;
}

.datePicker img {
    cursor: pointer;
}

.datePickerDiv {
    display: flex;
    width: 12%;
    min-width: 12%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

}


.closeIconSearchDiv {
    margin: 0rem 0rem 0rem 5rem;
    padding-left: 20px;
}

.closeIconSearchBorderDiv {
    display: flex;
    justify-content: end;
    width: 15%;
    min-width: 11%;
}

.classRosterDetailTextDiv {
    margin-left: 1rem;
    border-left: 1px solid #eeeeee;
    padding: .3rem 0rem 0rem 1rem;
}

.classRosterClassName {
    margin: 0px;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
}

.classRosterClassName span {
    margin: 0px;
    text-align: left;
    margin-top: 0.7rem;
    position: relative;
    padding: 0rem 0rem 0rem 0.5rem;
}

.totalClassRosterSearchDiv .searchStudentdiv {
    width: 95% !important;
    height: 42px !important;
    margin-top: 0.1rem !important;
    padding-left: 0rem !important;
    margin-bottom: 0.1rem !important;
    font-style: italic !important;
    display: inline-flex;
    float: left;
    background: #F4F6FA 0% 0% no-repeat padding-box !important;
    border: 0.75px solid #DDDDDD !important;
    border-radius: 8px !important;
    opacity: 1;
}

.totalClassRosterSearchDiv .searchStudentdiv input {
    cursor: pointer;
}

.totalClassRosterSearchDiv input.middle:focus {
    outline-width: 0 !important;
}

.searchStudentStylediv {
    width: 32%;
    border-right: 2px solid #dac7c7;
    padding-left: 1.5rem;
    border-left: 2px solid #dac7c7;
}

.totalClassRosterSearchDiv {
    width: 100%;
    background: #ffff;
    display: flex;
    padding: .5rem .5rem .5rem .5rem;
    margin-top: 1rem;
    border-radius: .5rem;
}

.totalClassRosterSearchDiv .searchInput {
    border: none !important;
    width: 100%;
}

.totalClassRosterSearchDiv .closeIconSearch {
    width: 1rem;
    cursor: pointer;
    margin: 0.9rem .5rem 0rem 0rem;
}

.totalClassRosterSearchDiv .searchIconDiv {
    padding: 0rem .5rem 0rem .5rem !important;
}


.totalClassRosterSearchDiv input.middle:focus {
    outline-width: 0 !important;
}

.totalClassRosterSearchDiv .searchIcon {
    color: gray !important;
}

.totalClassRosterSearchDiv .searchStudent {
    background: transparent !important;
    border: none !important;
    width: 100% !important;
}

.totalClassRosterSearchDiv input {
    outline: none;
}

@media only screen and (min-width: 250px) and (max-width: 1439px) {
    .searchStudentStylediv {
        padding-right: 0.5rem;
    }
}

@media only screen and (min-width: 250px) and (max-width: 780px) {
    .classRosterSelectCardDiv {
        width: 20rem;
    }

    .selectRosterProfileImage {
        width: 3.5rem;
        height: 3.5rem;
    }

    .selectRosterProfileDummy {
        width: 3.5rem;
        height: 3.5rem;
    }
}

@media only screen and (min-width: 250px) and (max-width: 520px) {
    .classRosterSelectContainer {
        padding: 0rem 0rem 0rem 0rem;

    }

    .ClassRosterSelectMainDiv {
        display: block;

    }

    .displayFlexInSeletRoster {
        justify-content: center;
        margin-top: 1rem;
    }

    .classRosterSelectDetailsMain {
        display: block;
    }

    .classRosterSelectBackDiv {
        width: 100%;
    }
}