.academicYearsMainContainer {
    padding: 1rem 1rem 1rem 1rem;
    width: 100%;
    height: 36.5rem;
    background: white;
    border-radius: .5rem;
}

.addTextBox {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    align-self: center;
    padding-top: 10px;
    min-width: 100px !important;
}

.addTextBox2 {
    // width: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding-top: 12px;
    min-width: 100px !important;
}
.addClassModalHeaderTitle {
    font: normal normal 600 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
}

.errormessageRegister {
    color: #d80f0f !important;
    float: left !important;
    font-size: 13px !important;
    margin-bottom: 1px !important;
    font-weight: 600;
    margin-top: 4px;
    line-height: 10px;
    padding-left: 2px;
}

.addTextSubBox {
    display: flex;
    flex-direction: row;
    width: max-content;
    cursor: pointer;
    margin-top: 10px;
}

.addTextTitle {
    font-weight: 600;
    font-size: 1rem;
    color: #222222;
    opacity: 1;
    margin-bottom: 0rem;
}

.addTextSubTitle {
    font-size: 0.875rem;
    color: #386CB5;
    font-style: italic;
}

.addTextSubTitleImage {
    padding-left: 5px;
    height: 9px;
    margin-left: 3px;
    position: relative;
    top: 7px;
}

/* ----------Dummy Image Section-------*/

.imageDummyDivProfileInRoster {
    width: 3.125rem;
    height: 3.125rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    text-align: center;
    cursor: pointer;
}

.profileImageDummyTextRoster {
    color: white;
    padding-top: 27%;
    text-transform: uppercase;
    // font-weight: 600;
}

/* ----------Dummy Image Section-------*/

/* ------------------Roster Css-------- */
.addRosterSpanText {
    font-weight: 600;
    font-size: 1.2rem;
    font: Italic 1rem/1.3rem Open Sans;
    letter-spacing: 0px;
    color: #AAAAAA;
    opacity: 1;
}

.addRosterListDiv {
    width: 58rem;
    height: 5rem;
    padding: 1rem 1rem 1rem 1rem;
    display: inline-flex;
    border: 1px solid #EEEEEE;
    background: #F5F5F599;
    border-radius: 8px;
    align-items: flex-start;
    margin-top: 1rem;
}

.addRosterImageDiv {
    margin: 0rem 1.5rem 0rem 0rem;
    cursor: pointer;
}

.imageDummyDiv {
    width: 3.125rem;
    height: 3.125rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    cursor: pointer;
}

.reosterImageDummyText {
    padding: 1.3rem 1.3rem 1rem 1.1rem;
    color: white;
    text-transform: uppercase;
}

.addRosterImage {
    width: 3.125rem;
    cursor: pointer;
}

.noRosterDiv {
    height: 6rem;
    text-align: center;
    padding-top: 32px;
}

.noRosterText {
    font-weight: 600;
    font-size: 1rem;
    color: #888888;
    cursor: pointer
}

.rosterListDiv {
    display: flex;
    width: calc(100vw - 350px);
    align-items: center;
    align-self: center;
}

.rosterListCard {
    width: 17rem !important;
    height: 5rem !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000001A;
    border-radius: 1rem;
    opacity: 1;
    display: inline-flex;
    padding: .5rem;
    margin: 0rem 1rem 1rem 0rem;
    min-width: 270px !important;
    opacity: 1;

}

.rosterViewAllButton {
    width: 4.5rem;
    height: 1.5rem;
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    border-radius: 1.5rem !important;
    position: absolute;
    right: 0;
    top: .1rem;
}

.rosterButtonViewText {
    margin: -.3rem;
    font-size: .8rem;
}

.rosterProfileDiv {
    width: 50px;
    margin-right: 30px;

    .example {
        border-radius: 8px;
        height: 35px;
        padding: 5px 8px;
    }
}

.rosterNameInfoDiv {
    padding: .2rem .2rem .1rem .1rem;
    width: 100%;
}

.reosterProfileNameText {
    font-size: .9rem;
    margin: 0rem;
    font-weight: 600;
    color: #222222;
    overflow: hidden;
    width: 180px;
    white-space: nowrap;

}

.rosterProfileInfoDiv {
    display: inline-flex;
    margin-top: 1rem;
    justify-content: space-between;
    width: 100%;
}

.rosterProfileInfoText {
    font-size: .8rem;
    margin: 0rem;
    color: #386CB5;
    cursor: pointer;
    font-weight: 600;

}

/* ------------------Roster Css-------- */
/* ------------------Attendance/Notes Section Css-------- */
.bottomButtonsMainDiv {
    width: 100%;
    margin-top: 1.5rem;
    grid-template-columns: repeat(6, 0fr);
    flex-wrap: wrap;
    display: flex;
}

.buttonsMainBox {
    width: 13.75rem;
    height: 6rem;
    min-width: 13.75rem !important;
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
    padding: 10px;
}

.btnMainDiv {
    width: 100%;
    display: flex;

}

.btnSubDiv {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.btnSubDiv2 {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.bottomIcon {
    width: 48px;
    height: 48px;
    margin-top: -5px;
    margin-left: -5px;
}

.disBottomIcon {
    width: 38px;
    height: 38px;
}

.bottomBtn {
    width: 26px;
    height: 36px;
    margin-bottom: 10px;
}

.bottomBoxText {
    font-size: 14px;
    color: #222222;
    opacity: 1;
    font-weight: bold;
    margin-top: 10px;
}

.bottomBoxDisText {
    font-size: 14px;
    color: #AAAAAA;
    opacity: 1;
    font-weight: bold;
    margin-top: 10px;
}

.bottomBoxDisText {
    font-size: 14px;
    color: #AAAAAA;
    opacity: 1;
    font-weight: bold;
    margin-top: 10px;
}

.attendanceBtn {
    background: #FBF0FF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #B45DD7 !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    cursor: pointer;
}

.disAttendanceBtn {
    background: #F1F1F1 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #AAAAAA !important;
    border-radius: 8px;
    opacity: 1;
    cursor: pointer;
}

.rubricsDashBtn {
    background: #FFF2F7 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #E04F89;
    border-radius: 8px !important;
    opacity: 1 !important;
    cursor: pointer;
}

.formAssesmentBtn {
    background: #FFF4E2 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #FFBC53 !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    cursor: pointer;
}

.disFormAssesmentBtn {
    background: #F1F1F1 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #AAAAAA !important;
    border-radius: 8px !important;
    opacity: 1 !important;
}

.gradeBkBtn {
    background: #E5FAF4 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #11BE9F !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    cursor: pointer;
}

.disGradeBkBtn {
    background: #F1F1F1 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #AAAAAA !important;
    border-radius: 8px !important;
    opacity: 1 !important;
}

.notesBtn {
    background: #F0F1FD 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000026;
    border: 0.75px solid #7A81F7;
    border-radius: 8px;
    opacity: 1;
    cursor: pointer;
}

.disNotesBtn {
    background: #F1F1F1 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #AAAAAA !important;
    border-radius: 8px;
    opacity: 1;
    cursor: pointer;
}

.grpNotesBtn {
    background: var(--unnamed-color-fae5e5) 0% 0% no-repeat padding-box;
    border: 0.75px solid var(--unnamed-color-ff6a6a);
    background: #FAE5E5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000026;
    border: 0.75px solid #FF6A6A;
    border-radius: 8px;
    opacity: 1;
    cursor: pointer;
}

.disgrpNotesBtn {
    background: #F1F1F1 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 10px #00000026 !important;
    border: 0.75px solid #AAAAAA !important;
    border-radius: 8px;
    opacity: 1;
    cursor: pointer;
}

.bottomBoxDisText {
    font-size: 14px;
    color: #AAAAAA;
    opacity: 1;
    font-weight: bold;
    margin-top: 10px;
}

/* ------------------Class Css-------- */
.cursor-pointer {
    cursor: pointer;
}

.addClassImage {
    // width: 14.0rem;
    // height: 3.266rem;
}

.addClassText {
    color: #386CB5;
    font-size: 0.813rem;
    font-style: italic;
    align-self: center;
    margin-top: 1rem;
}

.addRosterText {
    color: #386CB5;
    font-size: 0.813rem;
    font-style: italic;
    align-self: center;
    margin-top: 1rem;
}

.noClassDiv {
    border-bottom: 1px solid #EEEEEE;
    height: 12rem;
    text-align: center;
    padding-top: 75px;
}

.noClassText {
    font-weight: 600;
    font-size: 1rem;
    color: #888888;
    cursor: pointer;

}

.classListDiv {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    width: calc(100vw - 350px);
    align-self: center;
    align-items: center;
    padding-top: 0.8rem;
}

.academicListSeprator {
    width: 1px;
    height: 50px;
    border-right: 2px solid #DDDDDD;
    align-self: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.academicListSeprator2 {
    width: 1px;
    height: 50px;
    border-right: 2px solid #DDDDDD;
    align-self: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.academicListSeprator3 {
    height: 20px;
    border-right: 2px solid #AAAAAA;
    align-self: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
}


.lineSeprator {
    width: 100%;
    height: 1px;
    border-bottom: 2px solid #DDDDDD;
    margin-top: 1.5rem;
}

.addClassListDiv {
    // width: 94.5%;
    width: 87%;
    height: auto;
    min-height: 5rem;
    max-width: 101.5rem !important;
    display: flex;
    padding: 0rem 1rem 0rem 1rem;
    border: 1px solid #EEEEEE;
    background: #F5F5F599;
    border-radius: 8px;
    align-items: flex-start;
    margin-top: 1rem;

}

.addClassImageDiv {
    margin: 0rem 1.5rem 0rem 0rem;
    cursor: pointer;
    align-items: center !important;
    justify-content: center;
    align-self: center;
}

.classListCard {
    // width: 15.6rem;
    width: 15%;
    height: 3.566rem;
    margin: 0rem 1rem 1rem 1px;
    min-width: 14rem !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    // box-shadow: 0px 2px 6px #0000001a;
    border-radius: 0.5rem;
    opacity: 1;
    padding-inline: 0.5rem;
    cursor: pointer;
    outline: 1px solid #DDDDDD;
}

.classDetailsDiv {
    justify-content: space-between;
    display: inline-flex;
    width: 100%;
    padding: .6rem;
    height: 3.566rem;
    align-items: flex-start;
    padding-top: 1.1rem;

}

.classNameTitle {
    margin-bottom: .3rem;
    font-weight: 600;
    font-size: .9rem;
    color: #222222;
}

.classOtherDetails {
    font-weight: 600;
    color: #888888;
    font-size: .8rem;
}

.classOtherDiv {
    display: inline-flex;
    width: 100%;
    margin: .2rem 0rem .5rem 0rem;

}

/* ------------Class Note Div---------- */
.classNoteDiv {
    width: 6.25rem;
    height: 3.8rem;
    background: transparent linear-gradient(270deg, #747BEB 0%, #989EFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    position: relative;
}

.borderRowNote {
    border-right: 1px solid #DDDDDD;
    padding: 0rem .6rem 0rem 0rem;

}

.NoteDivText {
    color: white;
    width: 100%;
    padding: .5rem .3rem 0rem 0rem;
}

.noteNumberText {
    margin: 0rem;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
}

.noteText {
    margin: 0rem;
    text-align: center;
    font-size: .8rem;

}

/* ------------Class Note Div---------- */


/* ------------Class Formative Div---------- */
.classFormativeDiv {
    width: 6.25rem;
    height: 3.8rem;
    background: transparent linear-gradient(270deg, #FFB239 0%, #FFC76E 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    position: relative;
}

.borderRowFormative {
    border-right: 1px solid #DDDDDD;
    padding: 0rem .6rem 0rem .6rem;
}

.formativeDivText {
    color: white;
    width: 100%;
    padding: .5rem .1rem 0rem 0rem
}

.formativeNumberText {
    margin: 0rem;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
}

.formativeText {
    margin: 0rem;
    text-align: center;
    font-size: .65rem;
    margin-top: -.1rem;

}

/* ------------Class Formative Div---------- */


/* ------------Class GradeBook Div---------- */
.classGradeBookDiv {
    width: 6.25rem;
    height: 3.8rem;
    background: transparent linear-gradient(270deg, #08B596 0%, #27D3B4 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    position: relative;
}

.withoutBorderRow {
    padding: 0rem .6rem 0rem .6rem;
}

.gradeDivText {
    color: white;
    width: 100%;
    padding: .5rem .3rem 0rem 0rem;

}

.gradeNumberText {
    margin: 0rem;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
}

.gradeText {
    margin: 0rem;
    text-align: center;
    font-size: .8rem;

}

/* ------------Class GradeBook Div---------- */

.forwordArrow {
    top: 1.3rem;
    font-size: 1.2rem;
    right: 0rem;
    position: absolute;
    color: white;
}



/* ------------------Class Css-------- */
@media only screen and (min-width: 1280px) and (max-width: 1806px) {
    .academicListSeprator {
        width: 1px;
        height: 50px;
        border-right: 2px solid #DDDDDD;
        align-self: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media only screen and (min-width: 250px) and (max-width: 950px) {

    .addTextTitle {
        font-size: 1rem;
    }

    /* ------------------------Roster List-------- */
    .addRosterImage {
        width: 3.125rem;
    }

    .addRosterImageDiv {
        width: 4rem;
    }

    .addRosterSpanText {
        font-size: .8rem;
    }

    .addRosterListDiv {
        padding: 0rem 0rem 1rem 0rem;
    }

    .rosterListDiv {
        width: calc(100vw - 180px);
        overflow: scroll;
    }

    /* ------------------------Roster List--------- */

    /* ------------------------Class List----------- */
    .addClassListDiv {
        padding: 0rem 0rem 1rem 0rem;
    }

    .addClassImageDiv {
        width: 4rem;

    }

    .addClassImage {
        // width: 15.0rem;
        // height: 4.166rem;
    }

    .classListDiv {
        width: calc(100vw - 180px);
    }

    .classListCard {
        // min-width: 363px !important;
    }

    .classDetailsDiv {
        height: 3.566rem;
    }

    .classNameTitle {
        font-size: .9rem;
        margin-bottom: .3rem;
    }

    .classOtherDetails {
        font-size: .8rem;
    }


    .classOtherDiv {
        margin: .2rem 0rem .5rem .1rem;
    }


    .NoteDivText {
        padding: .5rem .3rem 0rem 0rem;
    }

    .noteNumberText {
        font-size: 1rem;
    }

    .noteText {
        font-size: .8rem;

    }


    .formativeDivText {
        padding: .5rem .1rem 0rem 0rem
    }

    .formativeNumberText {
        font-size: 1rem;
    }

    .gradeDivText {
        padding: .5rem .3rem 0rem 0rem;
    }

    .gradeNumberText {
        font-size: 1rem;
    }

    .gradeText {
        font-size: .8rem;

    }

    .forwordArrow {
        top: 1.3rem;
        font-size: 1.2rem;
    }



    /* ------------------------Class List---------- */

}

@media only screen and (min-width: 700px) and (max-width: 1280px) {
   .academicYearsMainContainer {
        height : auto
   }
  }

  
  .modalNW {
    justify-content: space-evenly !important;
  }
  .saveButtonlessOpac {
    opacity: 0.45 !important;
  }
  .ClassRosterModalAY {

    .modal-content {
        border-radius: 0;
        border: none;
    }

    .modal-title {
        font-size: 13px;
        font-weight: 600;
    }


    .modal-header {
        border-bottom-color: #EEEEEE !important;
        background-color: #ffff !important;
        height: 65px !important;
        align-items: center;
    }

    .modal-header .close {
        padding: .5rem 1rem;
        margin: -1rem 0rem -1rem auto;
        opacity: 1 !important;
        color: #000 !important;
        margin-top: -17px !important;
        outline: none;
    }

    .cancelText {
        font-size: .8rem !important;
        color: #000 !important;
    }

    .closeIconInModal {
        width: .6rem;
        // margin-top: -3px;
    }


    .modal-content {
        border-radius: 0;
        border: none;
    }

    .RosterImageMainDiv {
        width: 100%;
        height: 8rem;
        background-color: #F5F5F5 !important;
        text-align: -webkit-center;
        padding-top: 1.5rem;
        border-radius: 6px;
    }

    .RosterImageMainSelectedDiv {
        width: 100%;
        height: 9rem;
        background-color: #F5F5F5 !important;
        text-align: -webkit-center;
        padding-top: 1rem;
        background-size: cover;
        background-position: center;
    }

    .RosterImageWhenSelect {
        width: 100%;
        height: 100%;
        border-radius: 10rem;
    }

    .addStudentImageDiv {
        width: 5rem;
        height: 5rem;
        border-radius: 10rem;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DDDDDD;
    }

    .cameraIconCss {
        width: 2rem;
        margin-top: 1.5rem;
        cursor: pointer;
    }

    .loaderIconCss {
        width: 4.5rem;
        margin-top: 1.5rem;
    }

    .RosterImageMainSelectedDiv .changeButton {
        width: 4.2rem;
        height: 1.9rem;
        background: #000000 !important;
        border-radius: 1.5rem !important;
        margin-top: .5rem;
        opacity: .7;
        cursor: pointer !important;
        display: inline-grid;
    }

    .RosterImageMainSelectedDiv .changeButtonText {
        color: white;
        font-size: 10px;
        font-weight: 600;
        cursor: pointer !important;
        margin-top: .5px !important;

    }

    .loaderIconCssAlreadySelected {
        width: 2.5rem;
    }

    .allInputMainDiv {
        padding: 0rem 1.5rem 1rem 1.5rem;
    }

    .studentAddInputDiv {
        width: 100%;
        margin: 1.5rem 1rem 0rem 0rem
    }

    .studentInputLableInfo {
        color: #000;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0rem;
        text-align: left;
    }

    .allInputMainDiv .MuiOutlinedInput-input {
        height: 32px !important;
        padding-top: 7px;
        padding-bottom: 7px;
        font: normal normal 600 14px/20px Open Sans;
        color: #222222;
        margin-bottom: 0px !important;
    }

    .errormessageRegister {
        color: #d80f0f !important;
        float: left !important;
        font-size: 13px !important;
        margin-bottom: 1px !important;
        font-weight: 600;
        margin-top: 4px;
        line-height: 10px;
        padding-left: 2px;
    }

    .studentAddInput {
        border: 0.75px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: .3rem;
        height: 3rem;
        background: #F4F6FA;
        width: 100%;
        margin-bottom: 0px !important;
    }

    .modalButtonClass {
        width: 100%;
        background: #ffff;
        justify-content: space-between;
        display: flex;
        padding: 2rem 0rem 1rem 0rem;

    }


    .saveButton {
        width: 5rem !important;
        height: 2.4rem !important;
        border-radius: 2rem !important;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: .6rem !important;
        margin: .1rem 0rem .1rem 0rem;
    }

    .saveText {
        color: white;
        font-size: 1rem;

    }

    .addAnotherButton {
        width: 12rem !important;
        height: 2.4rem !important;
        border-radius: 2rem !important;
        background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #2222221A !important;
        display: inline-flex !important;
        justify-content: center !important;
        font-size: .6rem !important;
        margin: .1rem 0rem .1rem 0rem;
    }

    .addRosterModalButtonClass {
        .saveButton {
            border-radius: 8px !important;
        }

        .addAnotherButton {
            border-radius: 8px !important;
        }
    }
}