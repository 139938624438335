.CaroContainer {
  border: 12px solid #e9e9e9;
  .caroWraper {
    background-size: 466px 314px !important;
    box-shadow: none !important;
    height: 400px;
    .caroSubContainer {
      transform: translateX(calc(-100% - 20px)) !important;
      .caroSlotBox {
        background-size: 466px 314px !important;
        flex-basis: 100% !important;
        height: 300px;
      }
      .caroSlotBox div {
        height: 399px;
        // background-size: 692px 398px !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  button {
    background-color: #386cb5 !important;
    border: none !important;
  }
  .caroSubContainerTransform {
    transform: none !important;
    .caroSlotBox {
      background-size: 466px 314px !important;
      flex-basis: 100% !important;
      height: 300px;
    }
    .caroSlotBox div {
      height: 399px;
    //   background-size: 692px 398px !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    }
  }
}
