.sort-by-col-CND {
    justify-content: flex-start;
    padding-left: 0px !important;
}


.Button-div-2-CND {
    border: none;
    text-align: left;
font: normal normal normal 14px/19px Open Sans;
letter-spacing: 0px;
color: #222222;
opacity: 1;
padding: 1rem 0rem 0rem .1rem;
}