.importLessonContainer {
    background: #f0f0f0;
    height: 100%;
    overflow-x: hidden;
    padding: 0rem 2.5rem 1rem 2.5rem;
    .importLessonPlanMainDiv {
        width: 100%;
        background: #ffff;
        display: flex;
        padding: 0.3rem;
        flex-wrap: wrap;
        box-shadow: 5px 4px 10px #2222221a !important;
        .importLessonPlanDetailsMainTwo {
            display: flex;
            align-items: center;
            flex: 0.6;
            .lessonDetailTextDiv {
                margin-left: 1rem;
                padding: 0.3rem 0rem 0rem 1rem;
                .lessonClassName {
                    margin: 0px;
                    font-size: 1.08rem;
                    font-weight: 600;
                    text-align: left;
                    color: #386cb5;
                }
            }
        }
        .importLessonPlanDetailsMain {
            display: flex;
            align-items: center;
            flex: 0.6;
            .ClassBackDiv {
                display: flex;
                padding: 0.8rem;
                width: 13rem;
                height: 3.1rem;
                cursor: pointer;
            }
            .lessonDetailTextDiv {
                margin-left: 1rem;
                padding: 0.3rem 0rem 0rem 1rem;
                .lessonClassName {
                    margin: 0px;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    color: #386cb5;
                }
            }
            .closeIconInModal {
                width: 11px;
margin-top: 7px;
height: 11px;
margin-right: 0.2rem;
            }
            .lessonClassBactTextDiv {
                height: 40px;
    border-left: solid 2px #eee;
    position: relative;
    bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 15px;
            }
            .lessonClassBactText {
                margin: 0px;
                font-size: 1rem;
                font-weight: 600;
                text-align: left;
            }
        }
        .importButtonMarginTop {
            display: flex;
            align-items: center;
            .addNewLesson {
                background: none !important;
                display: inline-flex !important;
                justify-content: center !important;
                font-size: 0.6rem !important;
                margin: 0.1rem 0.3rem 0.1rem 0.3rem;
                outline: none;
                .lessonBackIcons {
                    font-size: 1.8rem;
                    padding-top: 3px;
                    color: #386cb5;
                }
            }
            .viewAttTexts {
                color: black;
                font-size: 1rem;
                font-weight: 600;
                margin-top: 4px;
            }
            .viewAttText {
                color: black;
                margin-left: 0.5rem;
                font-size: 1rem;
                font-weight: 600;
            }
        }
    }
    .searchDiv {
        display: flex;
        margin-top: 15px;
        padding-bottom: 18px;
        border-bottom: 1px solid #cccccc;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 3px #0000001A;
border-radius: 8px;
opacity: 1;
padding-right: 0;
        .totalPlanSearchDiv {
            width: 80%;
            // background: #ffff;
            justify-content: space-between;
            display: flex;
            padding: 0rem 0.5rem 0rem 0.5rem;
            border-radius: 0.5rem;
            // box-shadow: 5px 4px 10px #2222221a !important;
            margin-right: 10px;
            .searchIcon {
                color: #d1c5c5 !important;
            }
            .searchStudent {
                background: transparent !important;
                border: none !important;
                width: 100% !important;
            }
            .searchInput {
                border: none !important;
                width: 100%;
            }
            .closeIconSearch {
                width: 1rem;
                cursor: pointer;
                margin: 1rem 0.5rem 0rem 0rem;
            }
            .searchIconDiv {
                padding: 0rem 0.5rem 0rem 0.5rem !important;
            }
            .searchStudentdiv {
                width: 92% !important;
                height: 21px !important;
                margin-top: 0.5rem !important;
                padding-left: 0rem !important;
                background: transparent;
                margin-bottom: 0.5rem !important;
                border: none !important;
                border-radius: 0.3rem !important;
                font-style: italic !important;
                display: inline-flex;
                float: left;
            }
            .searchStudentdiv input::placeholder {
                font-size: 0.95rem;
                font-weight: 200;
                color: rgb(206, 198, 198);
            }
        }
        .dropdownDiv {
            display: flex;
            align-items: center;
            margin-right: 10px;
            // box-shadow: 5px 4px 10px #2222221a !important;
            justify-content: flex-start;
            width: auto;
            top: 180px;
left: 244px;
height: 40px;
/* UI Properties */
border: 0.75px solid var(--unnamed-color-dddddd);
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 3px #00000029;
border: 0.75px solid #DDDDDD;
border-radius: 8px;
opacity: 1;
            .dropDiv {
                .dropbtn {
                    background-color: white;
                    color: black;
                    font-weight: 800;
                    font-size: 0.95rem;
                    border-radius: 0.5rem;
                    max-width: 185px;
                    min-width: 150px;
                    .arrowBlackIcon {
                        padding-left: 10px;
                        margin-bottom: 2px;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                    }
                    .arrowBlackIcon svg {
                        padding-left: 10px;
                        margin-bottom: 2px;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                    }
                }
                .dropdown-menu {
                    min-width: 50px !important;
                    border-radius: 0.5rem;
                    .dropdown-item {
                        padding: 0.25rem 1.79rem !important;
                        font-size: 0.95rem;
                    }
                    .dropdown-divider {
                        margin: 0.1rem 0 !important;
                        border-top: 2px solid #e9ecef !important;
                    }
                }
                .dropbtn svg {
                    fill: black;
                }
                .dropbtn i {
                    padding-left: 10px;
                }
            }
        }
        .searchBtnDiv {
            display: flex;
            align-items: center;
            .searchLesson {
                background-color: #386cb5;
                border-radius: 0.5rem;
                width: 100px;
            }
        }
        .totalPlanSearchDiv input.middle:focus {
            outline-width: 0 !important;
        }
        .totalPlanSearchDiv input {
            outline: none;
        }
    }
    .classTopicDivMain {
        display: flex;
        margin-top: 18px;
        margin-bottom: 18px;
        .nameLinediv {
            display: flex;
            flex: 1;
            .titleName {
                display: flex;
                width: 124px;
                display: flex;
                margin-bottom: 0px !important;
                font-weight: 800;
                display: flex;
                align-items: center;
            }
            .hrLine {
                width: 90%;
                margin-right: 20px;
                border-top-color: #777777;
                margin-left: 10px;
                margin-top: 20px;
                border-width: 2px;
                opacity : 0.2
            }
        }
        .dropdownsDiv {
            display: flex;
            .filterTitle {
                color: #777777;
                display: flex;
                align-items: center;
                font-size: 0.95rem;
                margin: 0px 10px;
                font-weight: 600;
            }
            .classDrpDwn {
                padding-right: 20px;
                .dropClassBtn {
                    background-color: white;
                    color: black;
                    font-weight: 800;
                    font-size: 0.95rem;
                    min-width: 135px;
                    border-radius: 0.5rem;
                    // box-shadow: 5px 4px 10px #2222221a !important;
                    top: 250px;
left: 1406px;
width: 140px;
height: 36px;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 3px #00000029;
border-radius: 6px;
opacity: 1;
                    .arrowBlackIcon {
                        padding-left: 10px;
                        margin-bottom: 2px;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                    }
                    .arrowBlackIcon svg {
                        padding-left: 10px;
                        margin-bottom: 2px;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                    }
                }
                .dropdown-menu {
                    min-width: 45px !important;
                    border-radius: 0.5rem;
                    overflow: scroll !important;
                    max-height: 250px !important;
                    width: 137px;
                    max-width: 140px;
                    .dropdown-item {
                        padding: 0.25rem 0.5rem !important;
                        font-size: 0.95rem;
                    }
                    .dropdown-divider {
                        margin: 0.1rem 0 !important;
                        border-top: 2px solid #e9ecef !important;
                    }
                }
                .dropbtn svg {
                    fill: black;
                }
                .dropbtn i {
                    padding-left: 10px;
                }
            }
            .topicDrpDwn {
                .dropClassBtn {
                    background-color: white;
                    color: black;
                    font-weight: 800;
                    font-size: 0.95rem;
                    min-width: 135px;
                    border-radius: 0.5rem;
                    // box-shadow: 5px 4px 10px #2222221a !important;
                    top: 250px;
left: 1406px;
width: 140px;
height: 36px;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 3px #00000029;
border-radius: 6px;
opacity: 1;
                    .arrowBlackIcon {
                        padding-left: 10px;
                        margin-bottom: 2px;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                    }
                    .arrowBlackIcon svg {
                        padding-left: 10px;
                        margin-bottom: 2px;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
                    }
                }
                .dropdown-menu {
                    min-width: 45px !important;
                    border-radius: 0.5rem;
                    transform: translate3d(0px, 37px, 0px) !important;
                    overflow: scroll !important;
                    max-height: 250px !important;
                    max-width: 140px;
                    .dropdown-item {
                        padding: 0.25rem 1.4rem !important;
                        font-size: 0.95rem;
                    }
                    .dropdown-divider {
                        margin: 0.1rem 0 !important;
                        border-top: 2px solid #e9ecef !important;
                    }
                }
                .dropbtn svg {
                    fill: black;
                }
                .dropbtn i {
                    padding-left: 10px;
                }
            }
        }
    }
    .classCardDivMain {
        flex-wrap: wrap;
        display: flex;
        .classCardsDiv {
            width: 22.6rem;
            height: 9rem;
            margin: 0rem 1.1rem 1.1rem 0rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0rem 0.2rem 0.5rem #0000001a;
            border-radius: 0.5rem;
            opacity: 1;
            padding: 0.1rem 0.5rem 0.1rem 0.4rem;
            .classDetailsCard {
                width: 100%;
                border-bottom: 1px solid #dddddd;
                height: 5.8rem;
                justify-content: space-between;
                padding: 0.4rem 0rem 0rem 0.4rem;
                justify-content: space-between;
                display: flex;
                .textLeft {
                    text-align: left;
                    .classGradeAndSectionText {
                        color: black;
                        margin: 0rem;
                        font-size: 0.85rem;
                        text-transform: capitalize;
                    }
                    .headingTitles {
                        display: inline-flex;
                        flex-wrap: wrap;
                        flex: 1.3 1;
                        justify-content: flex-start;
                        align-items: end;
                        .attTitleName {
                            margin: 0px;
                            font-size: 1rem;
                            font-weight: 600;
                            padding-right: 5px;
                            font: normal normal 600 13px/13px Open Sans;
                            color: #777777;
                        }
                        .head {
                            color: #9d9797;
                            text-transform: capitalize;
                            padding-right: 5px;
                            font: normal normal 600 13px/13px Open Sans;
                            margin: 0px;
                            .middleDot {
                                height: 5px;
                                width: 5px;
                                background-color: #bbb;
                                border-radius: 50%;
                                display: inline-block;
                                margin: 0px 4px 2px;
                            }
                        }
                    }
                    .lessonTitle {
                        margin-bottom: 0.3rem;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-transform: capitalize;
                    }
                    .lessonPlan {
                        border: 1px solid #4479c4;
                        border-radius: 15px;
                        width: 70px;
                        margin: 6px 0px 6px 0px;
                        .statusTextPlan {
                            margin: 0rem;
                            font-size: 0.85rem;
                            text-align: center;
                            color: #4479c4;
                            text-transform: capitalize;
                        }
                    }
                    .lessonCurrent {
                        border: 1px solid #4479c4;
                        border-radius: 15px;
                        width: 110px;
                        margin: 6px 0px 6px 0px;
                        .statusTextPlan {
                            margin: 0rem;
                            font-size: 0.85rem;
                            text-align: center;
                            color: #4479c4;
                            text-transform: capitalize;
                        }
                    }
                    .lessonEval {
                        border: 1px solid #23bf1d;
                        border-radius: 15px;
                        width: 83px;
                        margin: 6px 0px 6px 0px;
                        .statusTextEval {
                            color: #23bf1d;
                            margin: 0rem;
                            font-size: 0.85rem;
                            text-align: center;
                            text-transform: capitalize;
                        }
                    }
                    .lessonExe {
                        border: 1px solid #ff9c17;
                        border-radius: 15px;
                        width: 83px;
                        margin: 6px 0px 6px 0px;
                        .statusTextExe {
                            color: #ff9c17;
                            margin: 0rem;
                            font-size: 0.85rem;
                            text-align: center;
                            text-transform: capitalize;
                        }
                    }
                }
            }
            .classTimeline {
                display: flex;
                align-items: center;
                padding: 11px 2px 10px 2px;
                .headingTitles {
                    display: inline-flex;
                    flex-wrap: wrap;
                    flex: 1.3 1;
                    padding-left: 5px;
                    justify-content: flex-start;
                    align-items: end;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        padding-right: 5px;
                        font: normal normal 600 13px/13px Open Sans;
                    }
                    .head {
                        color: black;
                        text-transform: capitalize;
                        padding-right: 5px;
                        font: normal normal 13px/13px Open Sans;
                        margin: 0px;
                    }
                }
                .headingTitlesRight {
                    display: inline-flex;
                    flex-wrap: wrap;
                    flex: 1.3 1;
                    padding-right: 5px;
                    justify-content: flex-end;
                    align-items: center;

                    .attTitleName {
                        margin: 0px;
                        font-size: 1rem;
                        font-weight: 600;
                        padding-right: 5px;
                        font: normal normal 600 13px/13px Open Sans;
                    }
                    .ratingDivImg {
                        position: relative;
                        text-align: center;
                    }
                    .overImgText {
                        position: absolute;
                        top: 5px;
                        left: 12.5px;
                        color: white;
                        font-size: 0.9rem;
                        font-weight: 800;
                    }
                    .head {
                        color: #9d9797;
                        text-transform: capitalize;
                        padding-right: 5px;
                        font: normal normal 600 13px/13px Open Sans;
                        margin: 0px;
                    }
                }
            }
        }
    }
    .opacityFade {
        opacity: 0.5;
        cursor: default !important;
    }
}
.popoverLessonPlan {
    /* ----------------PopOver------------------- */
    .popoverOptions {
        display: inline-flex;
        border-bottom: 1px solid gray;
        padding: 0.5rem;
    }
    /* ----------- PopOver---------- */

    /* ----------------PopOver------------------- */
    .editClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        border-bottom: 1px solid #dddddd;
        padding: 0.5rem 0rem 0.5rem 0rem;
        width: 115%;
        .viewLpIcons {
            width: 1rem;
            height: 1.3rem;
            margin-right: 0.3rem;
        }
    }
    .disabledEditClasspopOver {
        display: inline-flex;
        color: #000;
        cursor: pointer;
        border-bottom: 1px solid #dddddd;
        padding: 0rem 0rem 0.5rem 0rem;
        width: 90%;
        opacity: 0.5;
        cursor: default;
    }
    .bs-popover-auto[x-placement^="left"] > .arrow::after,
    .bs-popover-left > .arrow::after {
        border-left-color: #fff !important;
    }
    .editTextClassPopOver {
        font-size: 0.9rem;
        margin-left: 0.1rem;
    }
    .deleteClassPopOver {
        display: inline-flex;
        color: #ff4444;
        cursor: pointer;
        padding: 0.5rem 0rem 0rem 0rem;
    }
    .disabledDeleteClassPopOver {
        display: inline-flex;
        color: #ff4444;
        cursor: pointer;
        padding: 0.5rem 0rem 0rem 0rem;
        opacity: 0.5;
        cursor: default;
    }
    .deleteTextClassPopOver {
        font-size: 0.9rem;
        margin-left: 0.1rem;
    }
    .editClassIcon {
        font-size: 1.3rem;
    }
    .popover {
        width: 7rem !important;
        padding: 0rem !important;
        height: 10.5rem;
        border-radius: 11px !important;
    }
}
.lessonCancelX {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    bottom: 8px;
    margin-right: 15px;
    color: #fff;
background: transparent linear-gradient(90deg, #669FED 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
border-radius: 6px;
}