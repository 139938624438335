.br-8 {
  border-radius: 0.5rem !important;
}

.mt-15 {
  margin-top: 0.9375rem;
}

.mb-15 {
  margin-bottom: 0.9375rem;
}

.noAttachementText {
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 600;
}

.noteMainContainer {
  background: #f0f0f0;
  height: 100%;
  // padding: 0rem 3rem 0rem 3rem;
}
.pr {
  position: relative;
}
.b-6 {
  bottom : 5px
}
.mb-13 {
  margin-bottom: 15px;
}
.noteMainContainer a:focus,
.noteMainContainer .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.modal-backdrop.show {
  opacity: 0.75 !important;
}

.modal-dialog {
  justify-content: center !important;
  display: flex;
}

.left-section {
  padding-right: 0.4375rem !important;
}

.right-section {
  padding-left: 0.4375rem !important;
}

.back-btn {
  background-color: #eeeeee !important;
  border-radius: 0.25rem;
  width: 100%;
  text-align: left !important;
  color: #222222 !important;
  border: none !important;
  font-weight: 600 !important;
  font: normal normal 600 1rem/1.375rem Open Sans;
  height: 3.125rem;
}

.class-btn {
  background: transparent linear-gradient(270deg, #ffb239 0%, #ffc76e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0.5rem;
  opacity: 1;
  border: none !important;
  width: 100%;
  text-align: center;
  font: normal normal 600 0.875rem/1.188rem Open Sans !important;
  letter-spacing: 0;
  color: #ffffff;
  min-height: 2.813rem;
}

.noRoster {
  .selfAddMisc {
    width: 10.63rem;
    height: 2.125rem;
    background: #386bb5 0% 0% no-repeat padding-box !important;
    border-radius: 0.3125rem;
    opacity: 1;
    font: normal normal bold 0.75rem/1.063rem Open Sans !important;
    border: none;
    color: white;
    // display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .selfAddMisc-dis {
    width: 10.63rem;
    height: 2.125rem;
    cursor: default !important;
    background: transparent linear-gradient(270deg, #aec7ea 0%, #b6cae6 100%) 0%
    0% no-repeat padding-box !important;
    border-radius: 0.3125rem;
    opacity: 1;
    font: normal normal bold 0.75rem/1.063rem Open Sans !important;
    border: none;
    color: white;
    // display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
}

.gradebook-btn {
  background: transparent linear-gradient(270deg, #08b596 0%, #27d3b4 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0.5rem;
  opacity: 1;
  border: none !important;
  width: 100%;
  text-align: center;
  font: normal normal 600 0.875rem/1.188rem Open Sans !important;
  letter-spacing: 0;
  color: #ffffff;
  min-height: 2.813rem;
}

.noteOptionIcons {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.55rem;
}

.back-btn .fa-chevron-left {
  margin-right: 0.625rem;
}

.profile-card {
  text-align: left;
}

.profile-card-attachments {
  margin-top: 1.25rem;
  text-align: left;
}

.profile-card-img {
  width: 3.125rem;
  height: 3.125rem;
  background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
  border-radius: 50rem;
  float: left;
  margin-right: 1rem;
  margin-bottom: 6px;
}

.profile-img {
  width: 3.125rem !important;
  height: 3.125rem !important;
  border-radius: 50% !important;
}

/* ----------Dummy Image Section------- */

.noteImageDummyText {
  color: white;
  padding-top: 27%;
  text-transform: uppercase;
  text-align: center;
}

/* ----------Dummy Image Section-------*/
.profile-name {
  font: normal normal 600 1rem/1.375rem Open Sans;
  color: #222222;
  position: relative;
    top: 13px;

}

.profile-mail {
  font: normal normal normal 12px/16px Open Sans;
  color: #777777;
}

.note-header {
  height: 5rem;
}

.note-header-title {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  color: #222222;
  margin: auto;
}

.note-header-total-std {
  text-align: left;
  margin: 8px 0px 0px 0px;
  text-align: left;
}

.note-header-total-std-title {
  text-align: left;
  margin: auto;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-16) / var(--unnamed-line-spacing-22)
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #222222;
  opacity: 0.4;
}

.grp-button .blueButton {
  background-color: #487fcc;
  color: #ffffff;
}

.grp-button .whiteButton {
  background-color: #ffffff;
  color: #000000;
}

.seperator {
  padding-left: 1.25rem;
  margin-left: 1.25rem;
  border-left: 2px solid #dddddd;
  height: 30px !important;
}

.grp-button {
  border: 0.75px solid var(--unnamed-color-dddddd);
  border: 0.75px solid #dddddd;
  border-radius: 5px;
  opacity: 1;
}

.add-note {
  width: 6.875rem;
  height: 2.813rem;
  background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 0.5rem !important;
  float: right;
  background: transparent
    linear-gradient(
      270deg,
      var(--unnamed-color-386cb5) 0%,
      var(--unnamed-color-669fed) 100%
    )
    0% 0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  color: #ffffff;
}

.disabledAdd-note {
  width: 6.875rem;
  height: 2.813rem;
  background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 0.5rem !important;
  float: right;
  // background: transparent
  //   linear-gradient(
  //     270deg,
  //     var(--unnamed-color-386cb5) 0%,
  //     var(--unnamed-color-669fed) 100%
  //   )
  //   0% 0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0%
    0% no-repeat padding-box;
  // opacity: 1;
  color: #ffffff;
  // width: 10rem;
  // height: 2.5rem;
  cursor: default !important;
  background: transparent linear-gradient(270deg, #aec7ea 0%, #b6cae6 100%) 0%
    0% no-repeat padding-box !important;
  // border-radius: 1.25rem !important;
  // float: right;
}

.add-note-img {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.5rem;
  margin-top: 1px;
  margin-top: -1px;
width: 14px;
height: 14px;
}

.classRoom-card-title {
  font: normal normal 600 0.875rem/1.188rem Open Sans !important;
  color: #222222;
  text-align: left;
}

.blueTimeLineTxt {
  width: 38%;
  padding-left: 12px;
  // padding-top: 4.5px;
  color: #fff;
  background-color: #487fcc;
  border-color: #487fcc;
  border-radius: 5px 0px 0px 5px;
  font: normal normal normal 14px/19px Open Sans;
  cursor: pointer;
}

.blueExpandedTxt {
  width: 62%;
  padding-left: 15px;
  // padding-top: 4.5px;
  color: #fff;
  background-color: #487fcc;
  border-color: #487fcc;
  border-radius: 0px 5px 5px 0px;
  font: normal normal normal 14px/19px Open Sans;
  cursor: pointer;
}

.whiteTimeLineTxt {
  width: 38%;
  padding-left: 12px;
  // padding-top: 4.5px;
  color: #222222;
  background-color: #ffffff;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  font-weight: 400;
  font: normal normal normal 14px/19px Open Sans;
  opacity: 1;
}

.whiteExpandedTxt {
  width: 62%;
  padding-left: 15px;
  // padding-top: 4.5px;
  color: #222222;
  background-color: #fff;
  border-radius: 0px 5px 5px 0px;
  font: normal normal normal 14px/19px Open Sans;
  cursor: pointer;
}

.section-tabs {
  display: flex;
  margin: 0 !important;
  height: 2.125rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0.0625rem 0.25rem #00000029;
  border: 0.75px solid #dddddd;
  border-radius: 5px;
  opacity: 1;
}

.section-tabs .nav-link {
  width: 50%;
  border: none !important;
  padding: 0.469rem 0.3125rem 0.3125rem 0.3125rem;
  margin: 0rem !important;
  text-align: center !important;
  font: normal normal normal 0.75rem/1.063rem Open Sans !important;
  color: #222222 !important;
}

.section-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
}

.section-tabs .active {
  background: #487fcc 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  padding: 0.469rem 0.3125rem 0.3125rem 0.3125rem;
  margin-left: 0rem !important;
  width: 50%;
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  border-radius: 5px !important;
  opacity: 1;
}

.profile-card .tab-content {
  min-height: 52.5vh;
}

.filter-section {
  height: 70px;
  min-height: 3.875rem;
  margin-bottom: 1.25rem;
}

.filter-section .card-body {
  padding: 0.625rem 1.25rem;
}

.UpperFilterDiv {
  align-self: center;
  margin: 0px 0px 3px 0px;
}

.filterDiv {
  float: right;
  display: flex;
  flex-direction: inherit;
  padding-left: 20px;
  border-left: 2px solid #dddddd;
}

.filterSubDiv {
  height: 2rem;
  border: 0.5px solid #cccccc;
  border-radius: 8px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0.625rem;
  padding: 0 0.5rem;
  cursor: pointer;
  margin-top: 0!important;
}

.blueActiveButton {
  height: 2rem;
  border: 0.5px solid #386bb5;
  background-color: #386bb5;
  color: #ffffff !important;
  border-radius: 8px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0.625rem;
  padding: 0 0.5rem;
  cursor: pointer;
}

.blueActiveButton .filterText {
  font-size: 0.8125rem;
  color: #ffffff !important;
}

.filterMiscel {
  margin: auto 0rem !important;
}

.filterSubDiv .filterText {
  font-size: 0.8125rem;
  color: #000;
  margin: auto 0.375rem;
}

.timeline-section {
  background-color: transparent !important;
  border: none !important;
}

.audio-section {
  white-space: normal;
}

.timeline-section .card-body {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.timeline-section .section-tabs {
  width: 12.75rem;
  position: absolute;
  right: 80.25rem;
  top: -87px;
}

.showAllBtn {
  background-color: #386bb5 !important;
  height: 32px;
  font-size: 0.8125rem;
  color: #000;
  border-radius: 8px;
  margin-right: 0px !important;
}

.showAllDisBtn {
  background-color: #ffffff !important;
  height: 32px;
  font-size: 0.8125rem;
  color: #000;
  border-radius: 8px;
  margin-right: 0px !important;
}

.showAllBtn .btn {
  font-size: normal normal normal 12px/17px Open Sans;
  color: #000;
  border-radius: 8px;
  margin-right: 0px !important;
}

.filterDiv .btn {
  font-size: 0.8rem;
  border-radius: 8px;
}

.st-note-header-select {
  margin: auto;
  padding-top: 4px;
}

.st-note-header-select .form-group {
  margin-bottom: 0;
  width: 13.75rem;
  margin: auto;
}

.ViewBtn {
  border: 0.75px solid var(--unnamed-color-dddddd);
  border-radius: 5px;
  opacity: 1;

  .section-tabs {
    display: flex;
    width: 210px;
    margin: 0 !important;
    height: 2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #dddddd;
    border-radius: 5px;
    opacity: 1;
  }
}

.selectedBlueBtn {
  background-color: #487fcc !important;
  color: #fff !important;
}

.selectedWhiteBtn {
  background-color: #fff !important;
  color: #000 !important;
}

.st-note-header-select .form-group select {
  font: normal normal normal 0.875rem/1.188rem Open Sans;
  letter-spacing: 0;
  color: #222222;
}

.st-note-header-select .styled-select select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

.st-note-header-select .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.classroom-attchements {
  margin-top: 0.9375rem;
  text-align: center;
}

.classroom-attchements .note {
  width: 3.75rem;
  height: 3.75rem;
  background-color: #00000059;
  display: inline-block;
  margin: 2% 3%;
  background-size: cover;
  cursor: pointer;
}

.classroom-attchements .noteDoc {
  width: 3.75rem;
  height: 3.75rem;
  display: inline-block;
  margin: 2% 3%;
  background-size: cover;
  cursor: pointer;
}

.classroom-attchements .blackLayer {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.classroom-attchements .img-div {
  position: relative;
  padding: 1.1rem 1.313rem;
  background-color: #0000002e;
}

.classroom-attchements .sort-play {
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
}

.add-miscellaneous-modal {
  margin-top: 5rem;
  .add-misc-close-btn {
    border-radius: 8px!important;
  }
}

.add-miscellaneous-modal .modal-title {
  font: normal normal 600 1rem/1.375rem Open Sans !important;
}

.add-miscellaneous-modal .modal-header {
  padding: 0.8rem 1rem 0;
  border-bottom: none;
  border-radius: 0.5rem;
}

.add-miscellaneous-modal textarea {
  font: normal normal normal 0.875rem/1.188rem Open Sans;
  font-weight: bold;;
}

.add-miscellaneous-modal textarea::-webkit-input-placeholder {
  font-weight: normal;
  
}
.add-miscellaneous-modal textarea::-moz-placeholder {
  font-weight: normal;
  
}
.add-miscellaneous-modal textarea::-ms-input-placeholder {
  font-weight: normal;
  
}

.add-miscellaneous-modal .modal-footer {
  justify-content: center;
  border-top: none;
  padding-top: 0;
}

.add-miscellaneous-modal .modal-footer button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0;
}

.add-miscellaneous-modal .modal-footer button img {
  margin-top: -0.1875rem;
  margin-left: 0.0625rem;
}

.add-miscellaneous-modal .modal-content {
  background-color: #ffffff !important;
}

.play-video-modal .modal-content {
  border: none !important;
  background-color: #fff0 !important;
  width: 750px;
  height: 550px !important;
}

.plays-video-modal .modal-dialog {
  width: 750px !important;
  justify-content: center !important;
  display: flex;
}

.plays-video-modal .modal-header {
  border: none !important;
  padding: 0px !important;

  .delete-btn {
    background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0%
      0% no-repeat padding-box;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;
    padding: 0;
    border: none !important;
  }
}

.play-video-modal .modal-footer {
  border: none !important;
}

.plays-video-modal .modal-content {
  border: none !important;
  background-color: #fff0 !important;
  width: 750px;
  height: 550px !important;
}

.plays-video-modal .modal-footer {
  border: none !important;
}

.imageArti {
  position: relative;
  width: 100%;
}

.badgeDot {
  top: -11px;
  left: -3px;
  position: absolute;
  top: -2px;
  padding: 5px 5px 3px 5px;
  border-radius: 88%;
  background-color: #ffc107 !important;
  color: white !important;
  height: 11px;
  width: 12px;
  border: 1px solid white;
}

.play-video-modal .modal-header {
  border: none !important;
  justify-content: flex-end;

  .delete-btn {
    background: transparent linear-gradient(270deg, #ea3737 0%, #f05f5f 100%) 0%
      0% no-repeat padding-box;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;
    padding: 0;
    border: none !important;
  }
}

.mediaHeader {
  .mediaHeaderBtns {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .mediaArti {
      font-size: 15px;
      font-weight: 800;
      align-self: center;
      color: white;

      .starSelected {
        cursor: pointer;
        color: #ffa700;
        font-size: 21px;
        display: inline-flex;
        padding-left: 8px;
      }

      .starUnSelected {
        cursor: pointer;
        font-size: 23px;
        display: inline-flex;
        padding-left: 8px;
      }
    }

    .docArti {
      font-size: 15px;
      font-weight: 800;
      align-self: center;
      color: white;

      .starSelected {
        cursor: pointer;
        color: #ffa700;
        font-size: 21px;
        display: inline-flex;
        padding-left: 8px;
      }

      .starUnSelected {
        cursor: pointer;
        font-size: 21px;
        display: inline-flex;
        padding-left: 8px;
      }
    }

    .close {
      padding: 0.5rem 1rem !important;
      margin: -1rem -1rem -1rem auto;
      opacity: 1 !important;
      color: #000 !important;
      outline: none !important;

      .closeIconInModal {
        width: 0.8rem;
        margin-top: 0.3rem;
      }
    }
  }
}

@media only screen and (max-width: 1620px) {
  .classroom-attchements .note {
    margin: 1% 2%;
  }
}

@media only screen and (max-width: 1500px) {
  .filterSubDiv {
    height: 1.9rem;
    margin: 0.4rem !important;
  }

  .filterSubDiv .filterText {
    font-size: 0.75rem;
    color: #000;
    margin: auto 0.125rem;
  }

  .noteOptionIcons {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
  }

  .gradebook-btn {
    font: normal normal 600 0.8125rem/1.125rem Open Sans !important;
    padding: 0.375rem !important;
  }
}

@media only screen and (max-width: 1310px) {
  .filterSubDiv {
    float: left;
  }

  .artifactsNote {
    margin-bottom: 0.625rem;
    margin: 0.4rem !important;
  }
}

@media only screen and (max-width: 1300px) {
  .filterSubDiv {
    float: right;
  }

  .noteMainContainer .card-body {
    padding: 0.8rem;
  }
}

@media only screen and (max-width: 1280px) {
  .classroom-attchements .card-body {
    padding: 0.8rem;
  }
}

@media only screen and (max-width: 1220px) {
  .noteOptionIcons {
    width: 1.375rem;
    height: 1.375rem;
    margin-right: 0.3125rem;
  }

  .noteMainContainer {
    padding: 0rem 1rem;
  }

  .st-note-header-select .form-group {
    width: unset;
  }
}

@media only screen and (max-width: 767px) {
  .noteOptionIcons {
    margin-right: 0.75rem;
  }

  .class-btn,
  .gradebook-btn {
    min-height: 2.813rem !important;
  }

  .note-header {
    height: 4.063rem;
  }

  .left-section {
    padding: 0.9375rem !important;
  }

  .right-section {
    padding: 0.9375rem !important;
  }

  .filterSubDiv {
    margin-bottom: 0;
    margin-top: 0.625rem;
  }
}

@media only screen and (max-width: 520px) {
  .left-section {
    padding: 0 !important;
  }

  .right-section {
    padding: 0 !important;
  }
}

@media screen and (min-width: 676px) {
  .plays-video-modal .modal-dialog {
    max-width: 750px;
    justify-content: center !important;
    display: flex;
  }
}

.noteMainContainer .headerBar {
  width: 100%;
  height: 80px;
  background: #ffff;
  justify-content: space-between;
  display: flex;
  margin-bottom: 20px;
  padding: 1rem 1.25rem 1rem 0rem;
  border-radius: 0px 0px 8px 8px;
}

.noteMainContainer .note-header-title {
  display: flex;

  font: normal normal 600 0.875rem/1.188rem Open Sans !important;
}

.stdNoteBackDiv {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  color: #222222;
  cursor: pointer;
}

.stdNoteDetailTextDiv {
  border-left: 2px solid #eee;
  margin-left: 15px;
}

.stdNoteDisplayFlex {
  display: flex;
  cursor: pointer;
  margin-top: 9px;
  margin-left: 15px;

  p {
    margin: 0px !important;
    padding: 2px 0px 0px 0px !important;
    font-size: 16px;
    cursor: pointer;
  }

  i {
    color: #487fcc;
    font-size: 24px;
    cursor: pointer;
  }
}

.stdNoteGrade2 {
  color: #222222;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: left;
  cursor: default;
}

.currentYear {
  color: #487fcc;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  cursor: default;
}

.addNoteBtn {
  padding-top: 2px;
}

@media only screen and (max-width: 450px) {
  .action-div img {
    width: 4.0625rem !important;
  }
}

@media only screen and (max-width: 81.25rem) {
  .action-div {
    margin: auto;
  }
}

@media only screen and (max-width: 991px) {
  .action-div img {
    width: 4.6875rem !important;
  }
}

@media (min-width: 1439px) and (max-width: 1600px) {
  .timeline-section .section-tabs {
    width: 12.75rem;
    position: absolute;
    right: 50.25rem;
    top: -87px;
  }
}

@media (min-width: 1536px) and (max-width: 1745.45px) {
  .timeline-section .section-tabs {
    width: 12.75rem;
    position: absolute;
    right: 56.25rem;
    top: -87px;
  }
}

@media only screen and (width: 1745.45px) {
  .timeline-section .section-tabs {
    width: 12.75rem;
    position: absolute;
    right: 69.25rem;
    top: -87px;
  }
}

.dropdownMainContainer {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  flex: 0.6;
  .dropdown-search-icon-CND {
    width: 14px;
    height: 14px;
    align-self: center;
    position: absolute;
    top: 9px !important;
    // right: 27px !important;
    z-index: 2 !important;
    left: 9px;
  }

  .iconDiv {
    position: absolute;
    left: 9px;
    z-index: 2;
    top: 2px;

    .searchFiltersIcon {
      cursor: pointer;
      color: #aaaaaa;
      margin-right: 10px;
    }
  }

  .select-search {
    width: 240px !important;
    position: relative;
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
    bottom: 5px !important;
    cursor: pointer;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  .select-search__value {
    position: relative;
  }

  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
    cursor: pointer;
  }

  .select-search__input {
    margin-top: 5px;
    display: block;
    height: 40px;
    width: 240px;
    padding: 0 40px 0 30px;
    background: #fff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 3px #88c3fae3;
    border-radius: 8px;
    outline: none !important;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    color: rgba(56, 108, 181, 1);
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    opacity: 1;

    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 0.75px solid var(--unnamed-color-dddddd);
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border: 0.75px solid #dddddd;
    border-radius: 8px;
    opacity: 1;
    letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-386cb5);
text-align: left;
font: normal normal 600 13px/18px Open Sans;
letter-spacing: 0px;
color: #386CB5;
opacity: 1;
font-size: 16px;
  }

  .select-search__input::placeholder {
    font: italic normal normal 13px/18px Open Sans;
    letter-spacing: 0px;
    color: #c6b6b6 !important;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  .select-search__select {
    max-height: 150px !important;
    box-shadow: 10px 10px 12px #ec090929;
    border: 1px solid #dddddd;
  }

  .select-search__options {
    list-style: none;
    padding: 0px 5px;
    background: #fff;
    cursor: pointer;
  }

  .select-search__row {
    border-bottom: 1px solid #dddddd;
    border-width: 0.15px;
  }

  .select-search__row:last-child {
    border-bottom: none;
  }

  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .select-search--multiple .select-search__option {
    height: 48px;
  }

  .select-search__option.is-selected {
    color: rgb(5, 5, 5);
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    color: rgb(1, 1, 1);
  }

  .select-search__group-header {
    text-transform: uppercase;
    background: #fff;
  }

  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
    letter-spacing: 0px;
    font-weight: 600;
  }

  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 3px solid #000;
    border-bottom: 3px solid #000;
    pointer-events: none;
  }

  .select-search.has-focus .select-search__value::after {
    display: none;
  }

  .select-search.has-focus .select-search__input:hover {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .select-search.has-focus .select-search__input {
    border-bottom: none;
    box-shadow: 0px 0px 3px #00000029;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-color: none;
    background: #f4f6fa 0% 0% no-repeat padding-box;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 40px !important;
    right: 0;
    left: 0;
    border-radius: 8px;
    overflow: auto;
    max-height: 360px;
    background: #fff;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    box-shadow: none;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: auto;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
    background: #fff;
  }

  .select-search__not-found {
    height: auto;
    padding: 8px;
    text-align: center;
    color: rgb(244, 245, 247);
  }
}

@media only screen and (min-width: 1280px) {
  .dropdownMainContainer {
    font-family: sans-serif;
    text-align: center;
    position: relative;
    flex: 0.6;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 1536px) {
  .dropdownMainContainer {
    font-family: sans-serif;
    text-align: center;
    position: relative;
    flex: 0.6;
    margin-left: -50px;
  }
}

@media only screen and (min-width: 1745.45px) {
  .dropdownMainContainer {
    font-family: sans-serif;
    text-align: center;
    position: relative;
    flex: 0.6;
    margin-left: -55px;
  }
}

@media only screen and (min-width: 1920px) {
  .dropdownMainContainer {
    font-family: sans-serif;
    text-align: center;
    position: relative;
    flex: 0.6;
    margin-left: -65px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1300px) {
  .st-note-header-select {
    padding-top: 0px;
  }
}
// .container {
  @media  screen and (min-width: 1200px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1694px!important;
}
  }

// }