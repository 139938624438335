.rubricsContainer {
    .table-Fix-Size {
        height: 75.1vh;
        background: none !important;
        overflow: auto;
    }

    .table-rubrics {
        display: flex;
        flex-direction: column;
        margin: 15px 0px 0px 0px;
        overflow: hidden;

        .noGradebookDataText {
            color: #aaaaaa;
            font-size: 2rem;
            font-weight: 600;
            margin-top: 10%;
        }

        .table-rubrics-table {
            display: flex;
            flex-direction: row;
            max-height: auto;
        }

        .table-rubrics {
            flex-direction: row;
            display: flex;
        }

        .table-Average-section {
            display: flex;
            overflow: hidden;
            overflow-y: scroll;
            overflow-x: auto;
            width: calc(87vw - 220px);

            .data-title {
                background: #386cb51c;
                border: 1px #cccccc;
                border-style: none solid solid solid;
                width: 122.5px;
            }
        }

        .table-left-section-Average {
            width: 315px;
            background-color: transparent;
            border: none;
            border-bottom: none;
        }

        .table-left-section {
            flex: 0.2;
            background-color: white;
            border: 1px solid #cccccc;
            margin-right: 15px;
            box-shadow: 6px 6px 9px #2222221a !important;
            min-height: 401px;
            height: 73.3vh;
            overflow: auto;
            border-radius: 7px;

            .header-title {
                height: 50px !important;
                background: #eeeeee;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;

                label {
                    font: italic normal normal 12px/17px Open Sans;
                    color: #777777;
                    margin: 12px;
                    text-align: left;
                    font-weight: 600;
                }
            }

            .profile {
                text-align: left;
                margin: auto;
                display: flex;
                width: 100%;
                padding-left: 10px;

                .profileDummyRosterProfile {
                    width: 3.3rem;
                    height: 3.3rem;
                    background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 1px 2px 3px #0000001a;
                    border-radius: 50rem;
                    margin-top: 0rem;
                    text-align: center;
                    opacity: 0.8;
                    cursor: pointer;

                    .rosterProfileImageText {
                        color: white;
                        padding-top: 28%;
                        text-transform: uppercase;
                    }
                }

                .opaqueProf {
                    opacity: 0.3 !important;
                    pointer-events: none;
                    cursor: default !important;
                }

                .profile-img {
                    width: 50px;
                    height: 50px;
                    background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                    border-radius: 50rem;
                    cursor: pointer;
                }

                .nameLable {
                    font: normal normal 600 13px/18px Open Sans;
                    color: #222222;
                    font-weight: 600;
                    margin: auto;
                    margin-left: 8px;
                    cursor: default !important;
                    pointer-events: none;
                }

                .emojiIconsRub {
                    display: flex;
                    align-items: center;
                    margin: 0px 10px;
                    cursor: pointer;

                    .avgScoresText {
                        margin: 0rem 0.5rem 0rem 0rem;
                        font-size: 0.9rem;
                        text-align: left;
                        cursor: default;
                        font-weight: 600;
                        // cursor: pointer;
                    }

                    .begin {
                        color: #f05f5f;
                    }

                    .develope {
                        color: #ffd307;
                    }

                    .accomplish {
                        color: #ff9c17;
                    }

                    .exemplar {
                        color: #80ce4f;
                    }
                }
            }
        }

        .tableRightSection {
            flex: 0.8;
            width: calc(80vw - 220px);
            min-height: 401px;
            height: 66.4vh;
            overflow: auto;

            .NoneSelected {
                padding-top: 200px;
                color: #aaaaaa;
                font: normal normal 600 24px/22px Open Sans !important;
            }
        }

        .forNoneData {
            align-items: center;
        }

        .headerStyle {
            height: 78px;
            border-bottom: 1px solid #cccccc;
            display: flex;
            // cursor: pointer;
        }

        .selectedProfile {
            background: #e3ebf6;
        }

        .avg-row-header {
            background: #386cb51c;
            border: 1px #cccccc;
            border-style: none none solid solid;
            height: 50px;
            margin: auto;
            font: normal normal 600 16px/22px Open Sans !important;
            text-align: left;
            padding: 12px 20px;
        }
    }
}

.rubAbsent-modal-warning .modal-title {
    text-align: center;
    font: normal normal 600 18px/24px Open Sans;
}

.rubAbsent-modal-warning .modal-content {
    border-radius: 8px;
    width: 302px;
    margin: auto;
    background-color: #fff !important;
}

.rubAbsent-modal-warning .modal-content .modal-header {
    justify-content: center;
    border: none;
    border-radius: 8px;
    padding: 20px 20px 0px 20px !important;
}

.rubAbsent-modal-warning .modal-content .modal-body {
    text-align: center;
    font: normal normal 600 14px/19px Open Sans;
}

.rubAbsent-modal-warning .dateBlock {
    padding: 10px 20px 10px 20px;
}

.rubAbsent-modal-warning .modal-content .modal-body .confirm-text {
    font: normal normal normal 14px/19px Open Sans;
    margin-bottom: 0;
}

.rubAbsent-modal-warning .modal-footer {
    border: none;
    justify-content: center;
}

.rubAbsent-modal-warning .getReport-btn {
    width: 101px;
    height: 38px;
    color: white;
    border-radius: 8px;
    background: transparent linear-gradient(270deg, #386cb5 0%, #669fed 100%) 0% 0% no-repeat padding-box;
    font: normal normal 600 14px/19px Open Sans;
}

.rubAbsent-modal-warning .cancelAtt-btn {
    background-color: #ffffff !important;
    font: normal normal 600 14px/19px Open Sans;
    color: #080808 !important;
    width: 101px;
    height: 38px;
}