body {
  margin: 0;
  /* font-family: 'Roboto', sans-serif !important; */
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 300;
}

code {
  font-family: 'Open Sans', sans-serif !important;

}






