.assessmentCoreStandardPopover{ 
    .popover{
    width: 45rem !important;
    padding: 0rem 0rem 1rem 0rem !important;
    border-radius:11px !important;
    height: 22rem !important;
    border:none !important
    }
  
  .tagDiv{
    max-height: 21rem;
    overflow: auto;
  }
   .selectTagDiv{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: .4rem;
    border-bottom:  1px solid #EEEEEE;
    padding-bottom: 5px;
    cursor: pointer;
  }
  .selectTagText{
    text-align: left;
    font: normal normal normal 15px/24px Open Sans;
    letter-spacing: 0.3px;
    color: #222222;
    opacity: 1;
  }
}