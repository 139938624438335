.editRatingContainer {
    overflow: scroll;
    min-height: 600px;
    .planTitleDiv {
        width: 100%;
        min-height: 85px;
        display: flex;
        background-color: white;
        border-radius: 20px;
        margin-top: 10px;
        .algnDivs {
            flex: 0.5;
            padding: 10px;
            .planTitle {
                flex: 0.45;
                padding: 10px;
                display: flex;
                .headTitlesPlan {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                }
                .headTitles {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    // font-weight: 600;
                    text-align: left;
                    padding-left: 5px;
                }
                .headTitlesSpan {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    // font-weight: 600;
                    text-align: left;
                    padding-left: 5px;
                    display: flex;
                }
                .headTitlesRate {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                    padding-left: 5px;
                }
                .greenRate {
                    color: green;
                }
                .yellowRate {
                    color: #f9dc0efa;
                }
                .redRate {
                    color: red;
                }
            }
        }
    }
    .ratingsAddDiv {
        width: 100%;
        min-height: 72px;
        display: flex;
        // background-color: white;
        // border-radius: 20px;
        margin-top: 10px;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 15px;
        .ratingTitleEditDIv {
            display: flex;
            padding: 0px 10px;
            width: 100%;
            .importTitleDiv {
                display: flex;
                padding: 10px;
                flex: 1 1;
                align-items: center;
                .checBoxDiv {
                    padding-top: 5px;
                }
                .importText {
                    margin: 0px !important;
                    font-size: 0.85rem;
                    font-weight: 600;
                }
            }
            .editRatings {
                padding: 10px;
                align-items: center;
                display: flex;
                .importText {
                    margin: 0px 8px 0px 0px !important;
                    font-size: 0.85rem;
                    font-weight: 600;
                }
                .ratingComp {
                    span {
                        padding-left: 2px;
                    }
                }
            }
        }
    }
    .allSubDivs {
        height: 256px;
        // overflow: scroll;
        .rationalDiv {
            padding-top: 10px;
            .rationalTitleBox {
                background-color: #669fed;
                height: 42px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                .rationalTitle {
                    padding-left: 10px;
                    margin-bottom: 0px;
                    color: white;
                    font-size: 0.98rem;
                    font-weight: 600;
                }
                .backendQuestionCaps {
                    text-transform: uppercase;
                }
            }

            .rationalInputBox {
                margin: 0rem 0.9rem;
                //   width: 100%;
                border: none;
                border-radius: 10px;
                min-height: 70px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                padding-left: 10px;
                background-color: white;
                display: flex;
                outline: none;
                .audio-sec {
                    .picture-video-sec {
                        .answerText {
                            display: flex;
                            margin-bottom: 0px !important;
                            margin-top: 7px;
                            padding-left: 10px;
                        }
                    }
                    .audit-sec {
                        .answerText {
                            display: flex;
                            margin-bottom: 0px !important;
                            margin-top: 7px;
                            padding-left: 10px;
                        }
                    }
                }

                .rationalTextData {
                    text-align: left;
                    margin: 0px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    text-align: left;
                    outline: none;
                }
                .rationalInputText {
                    width: 100%;
                    border: none;
                    border-radius: 10px;
                    min-height: 70px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    padding-left: 10px;
                    outline: none;
                    .answerText {
                        display: flex;
                        margin-bottom: 0px !important;
                    }
                }
                textarea::placeholder {
                    font-style: italic;
                    font-size: 0.95rem;
                }
            }
        }
        .rationalDivUrls {
            padding-top: 10px;
            .rationalTitleBox {
                background-color: #669fed;
                height: 42px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                display: flex;
                justify-content: space-between;
                .rationalTitle {
                    padding-left: 10px;
                    margin-bottom: 0px;
                    color: white;
                    font-size: 0.98rem;
                    font-weight: 600;
                }
                .rationalSubTitle {
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-bottom: 0px;
                    color: white;
                    font-size: 0.88rem;
                    font-weight: 600;
                    cursor: pointer;
                }
            }

            .rationalInputBox {
                margin: 0rem 0.9rem;
                height: auto;
                padding: 10px;
                background-color: white;
                border-radius: 10px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                .rationalInputTextDiv {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;

                    .rationalInputText {
                        width: 80%;
                        border: none;
                        border: 1px solid #dddddd;
                        height: 30px;
                        padding-left: 10px;

                        background: #f4f6fa;
                        border-radius: 5px;
                        color: black;
                        font-size: 0.88rem;
                        font-weight: 600;
                        .answerText {
                            display: flex;
                            margin-bottom: 0px !important;
                        }
                    }
                }
                input::placeholder {
                    font-size: 0.95rem;
                    font-weight: 200;
                    color: rgb(206, 198, 198);
                }

                input[type="text"]:focus,
                textarea:focus {
                    outline: none;
                }
            }
        }
        .rationalDiv:last-child {
            margin-bottom: 10px !important;
        }
    }
}
@media only screen and (min-width: 357px) and (max-width: 820px) {
    .editRatingContainer {
        .planningContainer {
            .planTitleDiv {
                flex-wrap: wrap;
            }
        }
    }
}
@media only screen and (min-width: 357px) and (max-width: 577px) {
    .editRatingContainer {
        .planningContainer {
            .planScheduleDiv {
                flex-wrap: wrap;
            }
        }
    }
}
