.viewStudentNoteCardPopover {
    .popover {
        top: 18px!important
    }
}
.StudentProfiles-CNSP {
    padding-left: 7.5px;
    padding-right: 7.5px;
    padding-bottom: 15px;

    .colClass-CNSP {
        padding-right: 7.5px !important;
        padding-left: 7.5px !important;
    }


    .mb-12 {
        margin-bottom: 12px;
        cursor: default;
    }

    .mainDiv-CNSP {
        border-radius: 0px 6px 6px 0px;
        padding: 1rem 1.25rem 1rem 1.25rem !important;
        height: 170px;
        max-width: 410px;

        .profile-CNSP {
            text-align: left;

            .img-CNSP {
                width: 50px;
                height: 50px;
                background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
                border-radius: 50rem;
                float: left;
                margin-right: 1rem;

                .profile-img {
                    width: 3.125rem !important;
                    height: 3.125rem !important;
                    border-radius: 50% !important;
                }
            }

            .studentProfileImageDiv-CNSP {
                width: 3.75rem;
                float: left !important;
            }

            .imageDummyRosterProfile-CNSP {
                width: 3.125rem;
                height: 3.125rem;
                background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
                box-shadow: 1px 2px 3px #0000001A;
                border-radius: 50rem;
                margin-top: 0rem;
                text-align: center;
                margin-left: 0rem;
                opacity: .8;
                display: flex;
                justify-content: center;
                align-items: center;

                .rosterProfileImageText-CNSP {
                    color: white;
                    // margin: 0px 0px 4px 0px !important;
                    text-transform: uppercase;
                    cursor: default;
                }
            }

            .details {
                .name {

                    .std-name-CNSP {
                        font: normal normal 600 14px/22px Open Sans !important;
                        color: #222222 !important;
                    }

                    .std-name-small-CNSP {
                        font: normal normal 600 12px/22px Open Sans !important;
                        color: #222222 !important;
                    }

                    .checBoxDiv {
                        float: right;
                    }

                    .checkBoxfilter {
                        color: #386cb5;
                        cursor: default;
                        font-size: 21px;
                    }

                    .showMore i {
                        float: right;
                        margin-top: 5px;
                        font-size: 24px;
                        font-weight: 100;
                        display: block;
                    }
                }

                .note {
                    margin-top: 12px;
                    font: normal normal 600 12px/17px Open Sans;
                    color: #777777;

                    .left {
                        font: normal normal normal 12px/17px Open Sans;
                        color: #386CB5;
                        cursor: default;

                    }

                    .right {
                        margin-left: 6px;
                        font: normal normal normal 12px/17px Open Sans;
                        color: #888888;
                        font-weight: 600;
                    }
                }
            }

            .score {
                .left {
                    font: normal normal normal 12px/17px Open Sans;
                    color: #222222;
                    text-align: left;
                    letter-spacing: 0px;
                    color: #222222;
                    opacity: 1;
                    text-overflow: ellipsis;

                }

                .right {
                    float: right;
                    padding-top: 5px;
                    font: normal normal 600 12px/17px Open Sans;
                    color: #222222;
                    text-overflow: ellipsis;
                }
            }

            .text {

                hr {
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                }

                font: normal normal normal 13px/18px Open Sans;
                color: #777777;
                height: 52px;
                overflow: hidden;
                overflow-y: hidden;
            }
        }
    }

    #Total {
        color: black !important;
    }

    .img-div-CNSP {
        width: 3.75rem;
        float: left !important;

        .showMore i {
            float: right;
            margin-top: 5px;
            font-size: 24px;
            font-weight: 100;
            display: block;
        }
    }

    .mainDiv-CNSP :hover {
        .details .name .showMore {
            display: block;
        }

    }

    .showMore {
        position: relative;
        top: -25px;
        display: none;
        left: 10px;
        cursor: pointer;
    }

  


    @media screen and (min-width: 1275px) and (max-width: 1369px) {

        .mainDiv-CNSP {
            border-radius: 0px 6px 6px 0px;
            padding: 1rem 1.25rem 1rem 1.25rem !important;

            .profile-CNSP {
                text-align: left;

                .img-CNSP {
                    width: 50px;
                    height: 50px;
                    background: transparent linear-gradient(180deg, #639be7 0%, #386cb5 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0.0625rem 0.125rem 0.1875rem #0000001a;
                    border-radius: 50rem;
                    float: left;
                    margin-right: 1rem;

                    .profile-img {
                        width: 3.125rem !important;
                        height: 3.125rem !important;
                        border-radius: 50% !important;
                    }


                }

                .studentProfileImageDiv-CNSP {
                    width: 3.75rem;
                    float: left !important;
                }

                .imageDummyRosterProfile-CNSP {
                    width: 3.125rem;
                    height: 3.125rem;
                    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 1px 2px 3px #0000001A;
                    border-radius: 50rem;
                    margin-top: 0rem;
                    text-align: center;
                    margin-left: 0rem;
                    opacity: .8;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .rosterProfileImageText-CNSP {
                        color: white;
                        // margin: 0px 0px 4px 0px !important;
                        text-transform: uppercase;
                        cursor: default;
                    }
                }

                .details {
                    .name {
                        font: normal normal 600 14px/22px Open Sans !important;
                        color: #222222 !important;

                        .checBoxDiv {
                            float: right;
                        }

                        .checkBoxfilter {
                            color: #386cb5;
                            cursor: default;
                            font-size: 21px;
                        }

                    }

                    .note {
                        margin-top: 12px;
                        font: normal normal 600 12px/17px Open Sans;
                        color: #777777;

                        .left {
                            font: normal normal normal 10px/16px Open Sans;
                            color: #386CB5;
                            cursor: default;

                        }

                        .right {
                            margin-left: 6px;
                            font: normal normal normal 9px/16px Open Sans;
                            color: #888888;
                            font-weight: 600;

                        }
                    }
                }

                .score {
                    .left {
                        font: normal normal normal 10px/16px Open Sans !important;
                        color: #222222;
                        text-align: left;
                        letter-spacing: 0px;
                        color: #222222;
                        opacity: 1;
                        text-overflow: ellipsis;

                    }

                    .right {
                        float: right;
                        padding-top: 5px;
                        font: normal normal normal 10px/16px Open Sans !important;
                        color: #222222;
                        text-overflow: ellipsis;
                    }
                }

                .text {

                    hr {
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                    }

                    font: normal normal normal 13px/18px Open Sans;
                    color: #777777;
                    height: 52px;
                    overflow: hidden;
                    overflow-y: hidden;
                }
            }
        }
    }
}

.outerTooltipdiv {
    display: flex;
    direction: row;
    height: 20px;
    cursor: pointer;
}

.tooltipIconDiv {
    height: 24px;
    margin: 0px 5px 0px 0px !important;
    padding: 0px !important;
    cursor: pointer;

    img {
        cursor: pointer;
        padding-bottom: 4px;
    }
}

.tooltipTextDiv {
    height: 24px;
    margin: 0px !important;
    padding: 0px !important;
    cursor: pointer !important;

    p {
        font-size: 12px;
        margin: 0px !important;
        padding: 0px !important;
        cursor: pointer !important;

    }
}
.cursor-pointer {
    cursor: pointer!important;
}