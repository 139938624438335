.br-8 {
  border-radius: 0.5rem !important;
}
.mt-15 {
  margin-top: 0.9375rem;
}
.mb-15 {
  margin-bottom: 0.9375rem;
}
.pr-7-5 {
  padding-right: 0.4375rem !important;
}
.pl-7-5 {
  padding-left: 0.4375rem !important;
}
.none-italic-style {
  font-style: normal !important;
  font-weight: 600 !important;
  margin-top: 7px;
  border: none !important;
  padding: 0 !important;
}
.none-italic-style:focus {
  box-shadow: none !important;
}
.studentGradeBookMainContainer {
  background: #f0f0f0;
  height: 100%;
  padding: 0rem 4.05rem 0 4.05rem;
}
.studentGradeBookMainContainer a:focus,
.studentGradeBookMainContainer .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.studentGradeBookMainContainer .profile-details-col {
  text-align: left;
}
.studentGradeBookMainContainer .profile-header-title {
  font: normal normal 600 0.875rem/1.188rem Open Sans !important;
  margin: auto 0;
}
.studentGradeBookMainContainer .noteDate {
  font: normal normal normal 0.875rem/1.188rem Open Sans !important;
}
.studentGradeBookMainContainer .profile-card-img {
  margin-top: -4px;
}
.studentGradeBookMainContainer .profile-header .card-body {
  padding: 8px 20px !important;
}
.studentGradeBookMainContainer .studentOptionDiv .classOptionDiv {
  width: 3.9rem;
  height: 3.7rem;
  background: transparent linear-gradient(270deg, #ffb239 0%, #ffc76e 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin: 0rem 0.1rem 0rem 0.1rem;
  padding-top: 0.6rem;
  cursor: pointer;
  .optionIcons {
    width: 1.2rem;
  }
  .optionText {
    color: #fff;
    font-size: 0.6rem;
    font-weight: 600;
    margin-top: 0.3rem;
  }
}
.studentGradeBookMainContainer .studentOptionDiv .noteOptionDiv {
  width: 3.9rem;
  height: 3.7rem;
  background: transparent linear-gradient(270deg, #747beb, #989eff) 0 0 no-repeat padding-box;
  border-radius: 8px;
  margin: 0rem 0.1rem 0rem 0.1rem;
  padding-top: 0.6rem;
  cursor: pointer;
  .optionIcons {
    width: 1.2rem;
  }
  .optionText {
    color: #fff;
    font-size: 0.6rem;
    font-weight: 600;
    margin-top: 0.3rem;
  }
}
.studentGradeBookMainContainer .studentOptionDiv .shareOptionDiv {
  width: 3.9rem;
  height: 3.7rem;
  background: transparent linear-gradient(270deg, #4479c4, #669fed) 0 0 no-repeat padding-box;
  border-radius: 8px;
  margin: 0rem 0.1rem 0rem 0.1rem;
  padding-top: 0.6rem;
  cursor: pointer;
  .optionIcons {
    width: 1.2rem;
  }
  .optionText {
    color: #fff;
    font-size: 0.6rem;
    font-weight: 600;
    margin-top: 0.3rem;
  }
}
.studentGradeBookMainContainer .studentOptionDiv {
  padding: 0;
  float: right;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
}
.add-grade {
  text-decoration: underline;
  font: normal normal 600 12px/17px Open Sans !important;
  color: #386cb5 !important;
  width: 80px;
  height: 35px;
}
.add-comment {
  height: 20px;
  text-decoration: underline;
  font: normal normal normal 12px/20px Open Sans !important;
  color: #386cb5 !important;
}
.score-color-popover-positioned {
  max-width: 182px !important;
  padding: 5px !important;
  box-shadow: 0px 1px 4px #0000001a;
  border: none !important;
  left: -48px !important;
  border-radius: 10px !important;
}
.score-color-popover-positioned .arrow {
  left: 100px !important;
}

.color-btn {
  width: 24px;
  height: 24px;
  border-radius: 20px !important;
  border: none !important;
  margin: 0 5px;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.color-btn-selected {
  width: 24px;
  height: 24px;
  border-radius: 20px !important;
  border: 1px solid blue !important;
  margin: 0 5px;
  justify-content: center !important;
  align-items: center !important;
  display: inline-flex !important;
}
.btn-gray {
  background: #777777 0% 0% no-repeat padding-box !important;
}
.btn-red {
  background: #ff4444 0% 0% no-repeat padding-box !important;
}
.btn-green {
  background: #23bf1d 0% 0% no-repeat padding-box !important;
}
.btn-yellow {
  background: #fab700 0% 0% no-repeat padding-box !important;
}
.btn-blue {
  background: #386cb5 0% 0% no-repeat padding-box !important;
}

.input-gray {
  background: #77777721 0% 0% no-repeat padding-box !important;
  border: 1px solid #777777 !important;
  color: #777777 !important;
}
.input-red {
  background: #ff44441a 0% 0% no-repeat padding-box !important;
  border: 1px solid #ff4444 !important;
  color: #ff4444 !important;
}
.input-green {
  background: #23bf1d0d 0% 0% no-repeat padding-box !important;
  border: 1px solid #23bf1d !important;
  color: #23bf1d !important;
}
.input-yellow {
  background: #fab70017 0% 0% no-repeat padding-box !important;
  border: 1px solid #fab700 !important;
  color: #fab700 !important;
}
.input-blue {
  background: #386cb51c 0% 0% no-repeat padding-box !important;
  border: 1px solid #386cb5 !important;
  color: #386cb5 !important;
}

.avg-panel {
  width: 102%;
  bottom: 0;
  margin-bottom: 0;
}
.avg-panel .card-body {
  height: 58px;
  padding: 10px 25px;
}
.avg-panel .left-p-sec {
  margin: auto;
}
.avg-panel .left-p-sec p {
  font: italic normal normal 13px/20px Open Sans;
  color: #888888;
  margin-bottom: 0;
  white-space: nowrap;
}
.avg-panel .avg {
  font: normal normal 600 22px/30px Open Sans;
  margin-top: 4px;
}
.avg-panel .avg-title {
  font: normal normal normal 14px/19px Open Sans;
  width: 53px;
  display: inline-block;
  text-align: left;
  margin-left: 15px;
}
.avg-panel .avg-sec {
  display: inline-flex;
}

.studentGradeBookMainContainer .overFlowClass {
  overflow-y: scroll;
  height: 483px;
}

@media only screen and (max-width: 1300px) {
  .studentGradeBookMainContainer .card-body {
    padding: 0.8rem;
  }
}

@media only screen and (max-width: 1220px) {
  .studentGradeBookMainContainer {
    padding: 0rem 1rem;
  }
  .avg-panel {
    width: 100%;
  }
}

@media only screen and (max-width: 596px) {
  .studentGradeBookMainContainer .profile-header-title {
    max-width: 100% !important;
  }
  .studentGradeBookMainContainer .profile-header-action {
    max-width: 100% !important;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 541px) {
  .studentGradeBookMainContainer .score-section label {
    margin-right: 10px;
    margin-left: 15px;
  }
  .score-div {
    border: none !important;
  }
}
