.footer{
    width: 100%;
    // height: 200px;
    background-color: #212121;
    color: #DDDDDD !important;
    padding: 0px 100px 0px 100px;
    position: relative;
    bottom: -10vh;
    
    .fontColor{
        color: #DDDDDD !important;

    }
    .footerOptionRow{
        border-bottom: .1px solid #DDDDDD;
        width: 100%;
    padding: 0px 0px 15px 0px;

    }
    .downloadTitle{
        margin-bottom: 0px !important;
        display: flex;
        align-self: center;
        padding-right: 10px;
        font-size: 14px;
        font-family: 'Roboto';
        text-align: center;
// font: normal normal medium 14px/19px Roboto  !important;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
    }

    .footerOptions{
        margin: 7px;
        display: flex;
        cursor: pointer;
    }
    .downloadOptions{
        margin: 7px;
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
    }
    .copyright{
        text-align: left;
        font: normal normal normal 11px/15px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.68;
        margin: 7px;
        display: flex;
        cursor: pointer;
    }
    .footerErrowIcon{
        color: #fff;
        top: 8px !important;
        // margin-top: 8px !important;
    }

    .footerStayConnected{
        color: #fff !important;
        font-size: 18px;
        font-weight: bold;
    }
    .footerSocialIcon{
        margin: 5px;
        cursor: pointer;
        width: 18px;
        height: 21px;
    }
    .InputType{
        border-bottom: 0.25px solid #DDDDDD ;
        height:45px ;
        padding-left: 10px ;
        padding-top: 10px;
        color: #DDDDDD !important;
        background: transparent;
        width: 55%;
        background-color: #2c2c2c;
        border-top-color: #2c2c2c;
        border-left-color: #2c2c2c;
        border-right-color: #2c2c2c;
        border-right: azure;
        padding-top: 10px;
        margin-top: -3px;
    }
    .MuiOutlinedInput-input{
        padding: 0px !important;
    }
    .MuiOutlinedInput-root{
      color:#DDDDDD   
    }
    .subscribeButton{
        background-color: #386CB5;
        color: #DDDDDD;
        height: 2.7rem;
        width: 8rem;
        margin-left: 10px;
        cursor: pointer;

    }
    ::placeholder {
        color: #DDDDDD !important;
        opacity: 1;  
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: #DDDDDD !important;
      }
    
@media only screen and (min-width: 1025px) and (max-width: 1300px) {

   padding: 15px 15px 5px 15px;
     // .subscribeButton{
    //     width: 100%;
    //     margin-left: 10px;
    // }
    
}
@media only screen and (min-width: 671px) and (max-width: 1024px) {

    padding: 15px 15px 5px 15px;
    .InputType{
        width: 50%;
    }
     
 }
@media only screen and (min-width: 471px) and (max-width: 670px) {

    padding: 15px 10px 5px 10px;
     
 }
@media only screen and (min-width: 270px) and (max-width: 470px) {

    padding: 15px 10px 5px 10px;
    .subscribeButton{
        margin-left: 0px;
    }
    .InputType{
        width: 100%;
    }
    
    // .InputType{
    //     width: 100%;
    // }
    // .MuiOutlinedInput-input{
    //     padding: 0px !important;
    // }
    // .subscribeButton{
    //     width: 100%;
    //     margin-left: 10px;
    // }
}
}
@media only screen and (min-width: 1300px) and (max-width: 1499px) {
    .footer {
        position: relative;
        bottom: -28vh;
    }
}