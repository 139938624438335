body {
    background-image: linear-gradient(to bottom , #ecf3fc, #FAFAFA);
}
.RosterShareContainer {
   background: #F0F0F0 ;
   height: 100%;
   overflow-x: hidden;
   
}
/* ------------Heading Css------- */
.mainHeading{
    width: 100%;
    background: #ffff;
    justify-content: space-between;
    display: flex;
    padding: 1rem 1rem 1rem 1rem;

}
.headingSchoolName{
   font-size: 1rem;
   font-weight: 600;
   margin: 0rem;
}
.headingSelectTeacher{
    font-size: .8rem;
    font-weight: 600;
    color:#AAAAAA;
   margin: 0rem;

}
.openPermissionModalButton{
    width: 10rem !important;
    height: 2rem !important;
    border-radius: 2rem !important;
    /* padding: .6rem !important; */
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    /* margin: 1rem 0rem 1rem 0rem; */
}
.checkPermissionText {
    color: white;
    font-size: .7rem;
    font-weight: 600;
   
}
/* ------------Heading Css------- */



/* ------------Teacher Card Css------- */
.teacherMainDiv{
    padding: 1rem 1rem 1rem 1rem; 
    display: inline;
}
.teacherCard{
    height: 7rem;
    width:24rem;
    background-color: #fff;
    border-radius: 1rem;
    display: inline-flex;
    padding: 1rem 0rem 0rem 0rem;
    margin: 1rem 0rem 1rem 0rem;
    position: relative;
}
.teacherProfileImageDiv{
    width: 6rem;
}
.teacherDummyDivProfile{
    width: 5rem;
    height: 5rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    margin-top: 0rem;
    text-align: center;
    margin-left: .5rem;
}
.teacherImageDummyText{
    color: white;
    padding-top: 30%;
    text-transform: uppercase;
}
.teacherShareProfileImage {
    width: 5rem;
    height: 5rem;
    background: transparent linear-gradient(180deg, #639BE7 0%, #386CB5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 1px 2px 3px #0000001A;
    border-radius: 50rem;
    /* margin-left: .4rem; */
}
.teacherShareTextInfoDiv{
    text-align: left;
    margin: 1rem;
}
.teacherShareName{
    margin: 2px;
    font-size: 1rem;
    font-weight: 600;
    width: 10rem;
}
.teacherShareEmail{
    margin: 0px;
    font-size: .9rem;
}
.teacherShareCheckBoxDiv{
    position: absolute;
    right: 2rem;
    top:2.7rem;
    font-size: 1.5rem;
    cursor: pointer;
}
.checkBoxColorTeacherShare{
    color:#386cb5;
    cursor: pointer;
    
}
.checkbozCircleTeacherShare{
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 10rem;
    border:.1rem solid #DDDDDD;
    text-align: -webkit-center;
    padding-top: .2rem;
    cursor: pointer;
}
.checkbozCircleSelectedTeacherShare{
    height: .7rem;
    width: .7rem;
    border-radius: 10rem;
    background-color: #386CB5;
}
/* ------------Teacher Card  Css------- */



/* ------------Modal---------- */
.permissionHeaderDiv{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
.closeIconPermissionModal{
    width: .8rem;
    margin-top: -.2rem;
}
.permissionText{
    color:#222222;
    font-weight: 600;
}
.selectMainDivforShareTeacher{
    display: grid;
    padding: 1rem 0rem 0rem 0rem;
    width: 100%;
    justify-content: center;
}
.selectOptionInTeacherShareType{
    width: 10rem;
    border: 1px solid #DDDDDD;
    text-align: -webkit-center;
    padding: .5rem;
    margin: .5rem;
}
.selectoptionShareTeacherImage{
    width: 3rem;
    margin: 1rem 0rem 1rem 0rem;
}
.selectOptionShareTeacherText{
    color: #222222;
    font-size: .8rem;
    margin: 0rem;
}
.sendPermissionButton{
    width: 8rem !important;
    height: 2.4rem !important;
    border-radius: 2rem !important;
    /* padding: .6rem !important; */
    background: transparent linear-gradient(270deg, #386CB5 0%, #669FED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #2222221A !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .6rem !important;
    margin: 1rem 0rem 1rem 0rem;
}
.sendPermissionText {
    color: white;
    font-size: 1rem;
   
}

@media only screen and (min-width: 250px) and (max-width: 790px) {
    .teacherCard{
        height: 6rem;
    width: 19rem;
    }
    .teacherShareProfileImage {
        width: 4rem;
        height: 4rem;
    }
    .teacherShareTextInfoDiv {
        text-align: left;
        margin: .4rem;
    }
    .teacherProfileImageDiv {
        width: 5rem;
    }
    .teacherShareCheckBoxDiv {
        top: 2.1rem;
    }
    .teacherShareName {
        font-size: .9rem;
    }
    .teacherShareEmail {
        font-size: .7rem;
    }
}
